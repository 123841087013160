import React from "react";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "../mobiles/mobiles.module.css";
import AddnewMAssigntComp from "./component/AddnewMAssigntComp";

const AddNewAssigment = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <AddnewMAssigntComp />
      </div>
    </div>
  );
};

export default AddNewAssigment;
