import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import { FaTrash } from "react-icons/fa";
import Select from "react-select";
import calender from "../../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { InvoiceStyles, InvoiceStylesVendorName } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import cancel from "../../../../../assets/cancell.png";
import style from "./EditExpence.module.css";
import moment from "moment";
import axios from "axios";
import { useQuery, useMutation } from "react-query";
import {
  createExpense,
  getVendorName,
  getMonth,
  getExpenseCategory,
  getYear,
  getExpenseId
} from "../../../../../Utils/axios/expenceApi.js";
const EditExpence = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {

  const [lineItemArray, setLineItemArray] = useState([1]);
  const [validate, setValidate] = React.useState(false);
  const {
    data: ExpenseDataById,
    isLoading,
    isError,
  } = useQuery(["getExpenseId"], () => getExpenseId(prevData?.idData));


  const [formData, setFormData] = useState({
    copyOfExpense: ExpenseDataById?.copyOfExpense,
    gstApplicable:ExpenseDataById?.gstApplicable,
    categoryName: ExpenseDataById?.categoryName,
    invoice: ExpenseDataById?.invoice,
    dateOfExpense: ExpenseDataById?.dateOfExpense,
    month: ExpenseDataById?.expenseMonth,
    amountToBePaid: ExpenseDataById?.amountToBePaid,
    paymentDueOn: ExpenseDataById?.paymentDueOn,
    year: ExpenseDataById?.expenseYear,
    title: ExpenseDataById?.title,
    baseAmount: ExpenseDataById?.baseAmount,
    totalBill: ExpenseDataById?.totalBilled,
    vendor: ExpenseDataById?.vendorNameById,
    baseAmount: ExpenseDataById?.baseAmount,
    id: prevData?.idData,
    cgst: ExpenseDataById?.cgst,
    igst: ExpenseDataById?.igst,
    sgst: ExpenseDataById?.sgst,
    gst: ExpenseDataById?.gst,
    lineItemDetails: ExpenseDataById?.lineItemDetails,
  });

  useEffect(() => {
    setFormData({
      copyOfExpense: ExpenseDataById?.copyOfExpense,
      gstApplicable:ExpenseDataById?.gstApplicable,
      category: ExpenseDataById?.categoryName,
      invoice: ExpenseDataById?.invoice,
      dateOfExpense: ExpenseDataById?.dateOfExpense,
      month: ExpenseDataById?.expenseMonth,
      amountToBePaid: ExpenseDataById?.amountToBePaid,
      paymentDueOn: ExpenseDataById?.paymentDueOn,
      year: ExpenseDataById?.expenseYear,
      title: ExpenseDataById?.title,
      baseAmount: ExpenseDataById?.baseAmount,
      totalBill: ExpenseDataById?.totalBilled,
      vendor: ExpenseDataById?.vendorNameById,
      id: prevData?.idData,
      cgst: ExpenseDataById?.cgst,
      igst: ExpenseDataById?.igst,
      sgst: ExpenseDataById?.sgst,
      gst: ExpenseDataById?.gst,
      lineItemDetails: ExpenseDataById?.lineItemDetails,
    })
  }, [ExpenseDataById])

  const { data: vendorData } = useQuery(["getVendorName"], getVendorName);
  const venderOption = vendorData?.map((e) => ({
    value: e.id,
    label: e.vendorName,
  }));
  const { data: monthData } = useQuery(["getMonth"], getMonth);
  const monthOption = monthData?.map((e) => ({
    value: e.id,
    label: e.month,
  }));
  const { data: expenseCategoryData } = useQuery(["getExpenseCategory"], getExpenseCategory);
  const expenseCategoryOption = expenseCategoryData?.map((e) => ({
    value: e.id,
    label: e.expenseCategory,
  }));



  const mutation = useMutation(createExpense, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      // setPrevData({ ...prevData, clientData: res });
      // setNextScreen("Confirm3_noheader");
      // setDisabled(false);
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });

  const radioButton=()=>{
    if(formData?.gstApplicable){
      if(formData?.igst &&
        formData?.sgst &&
        formData?.cgst &&
        formData?.gst ){
    return true
        }
        else{
          return false
        }
    }else{
      return true
    }
  }


  const EditInvoiceHandler = () => {
    if (
      radioButton() &&
      formData?.totalBill &&
      formData?.baseAmount &&
      formData?.amountToBePaid &&
      formData?.paymentDueOn &&
      formData?.vendor &&
      formData?.category &&
      formData?.invoice &&
      formData?.dateOfExpense &&
      formData?.month &&
      formData?.year &&
      formData?.title
    ) {
      setValidate(false);
      if (typeof formData.vendor === 'string') {
        const VendorId = venderOption.filter((item) =>
          formData.vendor == item.label
        )
        formData.vendor = VendorId[0].value
      }
      if (typeof formData.category === 'string') {
        const CategoryId = expenseCategoryOption.filter((item) =>
          formData.category == item.label
        )
        formData.category = CategoryId[0].value
      }
      mutation.mutate(formData);
      setNextScreen("expense");

    } else {
      setValidate(true);
    }
  };

  const data = { id: prevData.idData, ...formData }

  

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });



  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "38%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };
 
  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      {/*Copy Expence*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Copy Expense </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Expense?</div>
            <div className={style.radioitem}>
              <p className={style.values}>
              {ExpenseDataById?.copyOfExpense === true ? "Yes" : "NO" }
              </p>
            </div>
          </div>

        </div>
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Vendor Name<span style={{ color: "red" }}>*</span></p>
            <Select
              styles={InvoiceStylesVendorName}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              value={venderOption?.find(
                (e) => e.label === formData?.vendor
              )}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  vendor: e.value,
                });
              }}
              options={venderOption}
            />
          </div>

        </div>
      </InvoiceContainer>

      {/*Expence Details*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Expense Details</div>
        {/*row 1*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Category<span style={{ color: "red" }}>*</span></p>
            <Select
              styles={InvoiceStylesVendorName}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              value={expenseCategoryOption?.find(
                (e) => {
                  return e.label === formData?.category
                }
              )}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  category: e.value,
                });
              }}
              options={expenseCategoryOption}
            />
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        {/*row 2*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Invoice No<span style={{ color: "red" }}>*</span></p>
            <input
              value={formData?.invoice}
              className={style.bigInput}
              onChange={(e) =>
                setFormData({ ...formData, invoice: e?.target?.value })
              }
            />
            <p
              className={
                validate && !formData?.invoice
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Invoice No
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Date of Expense<span style={{ color: "red" }}>*</span></p>
            <div className={style.cu_field23Last}>
              <DatePicker
                placeholder=" DD MM YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  formData?.dateOfExpense
                    ? moment(formData?.dateOfExpense).toDate()
                    : null
                }
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    dateOfExpense: moment(date).format(),
                  });
                }}
                dateFormat="dd MMM yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={
                  formData?.dateOfExpense ? (
                    <CustomInputCross properties="dateOfExpense" />
                  ) : (
                    <CustomInputClg />
                  )
                }
              />
            </div>
          </div>
          <div className={style.col3}>
            <p className={style.label}>Expense Month<span style={{ color: "red" }}>*</span></p>
            <Select
              styles={InvoiceStyles}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              // value={monthOption?.find(
              //   (e) => e.label === formData?.month
              // )}
              value={
                {
                  value: formData?.value,
                  label: formData?.month,
                }
              }
              onChange={(e) => {
                setFormData({
                  ...formData,
                  month: e.label,
                  // mandatorySkill_id: "",
                });
              }}
              options={monthOption}
            />
          </div>
          <div className={style.col4}>
            <p className={style.label}>Expense Year<span style={{ color: "red" }}>*</span></p>
            <input
              value={formData?.year}
              className={style.bigInput}
              onChange={(e) =>
                setFormData({ ...formData, year: e?.target?.value })
              }
            />
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Title<span style={{ color: "red" }}>*</span></p>
            <input
              className={style.bigInput}
              value={formData?.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e?.target?.value })
              }
            />
          </div>
        </div>
        <hr></hr>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>GST Applicable</div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="Yes1"
                name="radio-group"
                onChange={() =>
                  setFormData({ ...formData, gstApplicable: true })
                }
              />
              <label for="Yes1">Yes</label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="no1"
                name="radio-group"
                onChange={() =>
                  setFormData({ ...formData, gstApplicable: false })
                }
              />
               <label for="no1">NO</label>
            </div>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        {formData.gstApplicable &&
          <div className={style.GstList}>
            <div className={style.col12}>
              <p className={style.label}>CGST<span style={{ color: "red" }}>*</span></p>
              <input
                value={formData?.cgst}
                className={style.GstInput}
                onChange={(e) =>
                  setFormData({ ...formData, cgst: e?.target?.value })
                }
              />
              <p
              className={
                validate && !formData?.cgst
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please write CGST
            </p>
            </div>
            <div className={style.col12}>
              <p className={style.label}>SGST<span style={{ color: "red" }}>*</span></p>
              <input
                value={formData?.sgst}
                className={style.GstInput}
                onChange={(e) =>
                  setFormData({ ...formData, sgst: e?.target?.value })
                }
              />
              <p
              className={
                validate && !formData?.sgst
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please write SGST
            </p>
            </div>
            <div className={style.col12}>
              <p className={style.label}>IGST<span style={{ color: "red" }}>*</span></p>
              <input
                value={formData?.igst}
                className={style.GstInput}
                onChange={(e) =>
                  setFormData({ ...formData, igst: e?.target?.value })
                }
              />
              <p
              className={
                validate && !formData?.igst
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please write IGST
            </p>
            </div>
            <div className={style.col12}>
              <p className={style.label}>GST(TOTAL)<span style={{ color: "red" }}>*</span></p>
              <input
                value={formData.gst}
                className={style.GstInput}
                onChange={(e) =>
                  setFormData({ ...formData, gst: e?.target?.value })
                }
              />
              <p
              className={
                validate && !formData?.gst
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please write GST
            </p>
            </div>
          </div>}
        <div className={style.GstList}>
          <div className={style.col12}>
            <p className={style.label}>Base Amount<span style={{ color: "red" }}>*</span></p>
            <input
              value={formData.baseAmount}
              className={style.GstInput}
              onChange={(e) =>
                setFormData({ ...formData, baseAmount: e?.target?.value })
              }
            />
            <p
              className={
                validate && !formData?.baseAmount
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please write IGST
            </p>
          </div>
          <div className={style.col12}>
            <p className={style.label}>Total Billed<span style={{ color: "red" }}>*</span></p>
            <input
              value={formData.totalBill}
              className={style.GstInput}
              onChange={(e) =>
                setFormData({ ...formData, totalBill: e?.target?.value })
              }
            />
            <p
              className={
                validate && !formData?.totalBill
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please write Total Billed
            </p>
          </div>
          <div className={style.col12}>
            <p className={style.label}>Amount to be paid<span style={{ color: "red" }}>*</span></p>
            <input
              value={formData?.amountToBePaid}
              className={style.GstInput}
              onChange={(e) =>
                setFormData({ ...formData, amountToBePaid: e?.target?.value })
              }
            />
            <p
              className={
                validate && !formData?.amountToBePaid
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please write Amount To ne paid
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>Payment Due On<span style={{ color: "red" }}>*</span> </p>
            <div className={style.cu_field23Last}>
              <DatePicker
                placeholder=" DD MM YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  formData?.paymentDueOn
                    ? moment(formData?.paymentDueOn).toDate()
                    : null
                }
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    paymentDueOn: moment(date).format(),
                  });
                }}
                dateFormat="dd MMM yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={
                  formData?.paymentDueOn ? (
                    <CustomInputCross properties="paymentDueOn" />
                  ) : (
                    <CustomInputClg />
                  )
                }
              />

            </div>
            <p
              className={
                validate && !formData?.paymentDueOn
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please write Payment Due On
            </p>
          </div>
        </div>
      </InvoiceContainer>
      {/* Line Item Details Container */}
      <InvoiceContainer>
        <div className={style.copy_expence}>Line Item Details</div>
        {/*row 1*/}
        {formData?.lineItemDetails?.map((item, index) => {
          return (
            <>
              <div className={style.Container} key={index}>
                <div className={style.col12_item}>
                  <p className={style.label_item}>Candidate Name*</p>
                  <input
                    type="text"
                    className={style.smallInput}
                    onChange={(e) => {
                      // save workexperince.company for index
                      let temp = [...formData?.lineItemDetails];
                      temp[index].candidateName = e?.target?.value;
                      setFormData({ ...formData, lineItemDetails: temp });
                    }}
                    value={formData?.lineItemDetails[index]?.candidateName}
                  />
                  <p
                    className={
                      validate && !item?.candidateName
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please write Candidate Name
                  </p>
                </div>
                <div className={style.col2_item}>
                  <p className={style.label_item}>Expense Month<span style={{ color: "red" }}>*</span></p>
                  <Select
                    styles={InvoiceStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={monthOption?.find(
                      (e) => e.label === formData?.lineItemDetails[index].expenseMonth
                    )}
                    onChange={(e) => {
                      let temp = [...formData?.lineItemDetails];
                      temp[index].expenseMonth = e?.label;
                      setFormData({
                        ...formData,
                        lineItemDetails: temp,
                      });
                    }}
                    options={monthOption}
                  />
                  <p
                    className={
                      validate && !item?.expenseMonth
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please write Expense Month
                  </p>
                </div>
                <div className={style.col3_item}>
                  <p className={style.label_item}>Expense Year<span style={{ color: "red" }}>*</span></p>
                  <input
                    type="number"
                    className={style.smallInput}
                    onChange={(e) => {
                      let temp = [...formData?.lineItemDetails];
                      temp[index].expenseYear = e?.target?.value;
                      setFormData({ ...formData, lineItemDetails: temp });
                    }}
                    value={formData?.lineItemDetails[index]?.expenseYear}
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                      (e.keyCode === 38 ||
                        e.keyCode === 40 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.prevent
                    }
                  />
                  <p
                    className={
                      validate && !item?.expenseYear
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please write Expense Year
                  </p>
                </div>
                <div className={style.col4_item}>
                  <p className={style.label_item}>Billing Rate<span style={{ color: "red" }}>*</span></p>
                  <input
                    type="number"
                    className={style.smallInput}
                    onChange={(e) => {

                      let temp = [...formData?.lineItemDetails];
                      temp[index].billingRate = e?.target?.value;
                      setFormData({ ...formData, lineItemDetails: temp });
                    }}
                    value={formData?.lineItemDetails[index]?.billingRate}
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                      (e.keyCode === 38 ||
                        e.keyCode === 40 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.prevent
                    }
                  />
                  <p
                    className={
                      validate && !item?.billingRate
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please write Billing Rate
                  </p>
                </div>
                <div className={style.col5_item}>
                  <p className={style.label_item}>Working Days<span style={{ color: "red" }}>*</span></p>
                  <input
                    type="number"
                    className={style.smallInput}
                    onChange={(e) => {

                      let temp = [...formData?.lineItemDetails];
                      temp[index].workingDays = e?.target?.value;
                      setFormData({ ...formData, lineItemDetails: temp });
                    }}
                    value={formData?.lineItemDetails[index]?.workingDays}
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                      (e.keyCode === 38 ||
                        e.keyCode === 40 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.prevent
                    }
                  />
                  <p
                    className={
                      validate && !item?.workingDays
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please write Working Days
                  </p>
                </div>
                <div className={style.col6_item}>
                  <p className={style.label_item}>Max Working Days<span style={{ color: "red" }}>*</span></p>
                  <input
                    type="number"
                    className={style.smallInput}
                    onChange={(e) => {

                      let temp = [...formData?.lineItemDetails];
                      temp[index].maxWorkingDays = e?.target?.value;
                      setFormData({ ...formData, lineItemDetails: temp });
                    }}
                    value={formData?.lineItemDetails[index]?.maxWorkingDays}
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                      (e.keyCode === 38 ||
                        e.keyCode === 40 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.prevent
                    }
                  />
                  <p
                    className={
                      validate && !item?.maxWorkingDays
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please write Max Working Days
                  </p>
                </div>
                <div className={style.col7_item}>
                  <p className={style.label_item}>Base Amount<span style={{ color: "red" }}>*</span></p>
                  <input
                    type="number"
                    className={style.smallInput}
                    onChange={(e) => {
                      let temp = [...formData?.lineItemDetails];
                      temp[index].baseAmount = e?.target?.value;
                      setFormData({ ...formData, lineItemDetails: temp });
                    }}
                    value={formData?.lineItemDetails[index]?.baseAmount}
                    min="0"
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) =>
                      (e.keyCode === 38 ||
                        e.keyCode === 40 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.preventDefault()
                    }
                    onKeyPress={(e) =>
                      ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                        e.which > 57 ||
                        e.key === "e" ||
                        e.key === "-") &&
                      e.prevent
                    }
                  />
                  <p
                    className={
                      validate && !item?.baseAmount
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please write Base Amount
                  </p>
                </div>
                <div className={style.col8_item}>
                  {formData?.lineItemDetails?.length > 1 && (
                    <>
                      <FaTrash
                        className={style.remove_icon}
                        size={20}
                        onClick={(e) => {
                          setLineItemArray(
                            lineItemArray.filter((_, i) => {
                              return i !== index;
                            })
                          );
                          setFormData({
                            ...formData,
                            lineItemDetails: formData?.lineItemDetails?.filter(
                              (_, i) => i !== index
                            ),
                          });
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </>
          );
        })}

        <div className={style.lastBtn_item}>
          <InvoiceButton
            onClick={() => {
              // push new value 2 in array
              setLineItemArray([
                ...lineItemArray,
                Math.floor(Math.random() * 100),
              ]);
              setFormData({
                ...formData,
                lineItemDetails: [
                  ...formData?.lineItemDetails,
                  {
                    baseAmount: "",
                    billingRate: "",
                    candidateName: "",
                    expenseMonth: "",
                    expenseYear: "",
                    id: prevData?.idData,
                    maxWorkingDays: "",
                    workingDays: ""
                  },
                ],
              });
            }}
          >
            Add
          </InvoiceButton>
        </div>
      </InvoiceContainer>
      <div className={style.lastBtn}>
        <InvoiceButton
          type={"cancel"}
          onClick={() => {
            setNextScreen("viewExpense");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "viewExpense",
              page3: null,
              page4: null,
              active: "viewExpense",
            }));
          }}
        >
          Cancel
        </InvoiceButton>
        <InvoiceButton
          onClick={() => {
            EditInvoiceHandler();
            setNextScreen("viewExpense");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "viewExpense",
              page3: null,
              page4: null,
              active: "viewExpense",
            }));

          }}
        >
          Update
        </InvoiceButton>
      </div>
    </>
  );
};

export default EditExpence;
