import React from "react";
import { Link } from "react-router-dom";
import EditIcon from "../../../assets/editIcon.png";
import { useHistory } from "react-router-dom";
import { textAlign } from "@mui/system";
import Moment from "moment";
import style from "./ProjectDetails.module.css";

const TableRow = (props) => {
  const history = useHistory();
  return (
    <tr
      style={{
        borderBottom: "2px solid #F0F0F0",
        textAlign: "start",
        fontSize:"13px"
      }}
    >
      <Link
        to={`/Projectdescription?id=${props.id}`}
        className={style.backColor}
      >
        <td
          style={{
            padding: "10px 0px",
            textTransform: "capitalize",
            fontWeight: "bolder",
            textDecorationLine: "underline",
          
          }}
        >
          {props.name}
        </td>
      </Link>
      <td >{props.projectName}</td>
      <td style={{ width: "15%" }}>{props.empName}</td>
      <td style={{ width: "12%" }}>
        {Moment(props.startDate).format("DD MMM YY")}
      </td>
      <td style={{ width: "17%" }}>{props.typeOfProject}</td>
      {/* <td style={{ width: "15%" }}>{props?.statusTypeName}</td> */}
      {props?.statusTypeName === "HOLD" ? (
        <td style={{ width: "14%" }}>
          {props?.statusTypeName}
          {Moment(props.holdDate).format("DD MMM YY")}
        </td>
      ) : props?.statusTypeName === "CLOSED" ? (
        <td style={{ width: "14%" }}>
          {props?.statusTypeName}
          {Moment(props.enddate).format("DD MMM YY")}
        </td>
      ) : (
        <td style={{ width: "14%" }}>{props?.statusTypeName}</td>
      )}
    </tr>
  );
};

export default TableRow;

{
  /* <button style={{ color: state === "active" ? "#F00" : "#00F" }}> */
}
