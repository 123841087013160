/* Component Imports */
// using React syntax and built-in methods/components, and useState hook for state management
import React, { useState } from "react";
// import FiCheck icon as a component
import { FiCheck } from "react-icons/fi";
// import a customizable checkbox from react-custom-checkbox
import Checkbox from "react-custom-checkbox";

/* Style Imports */
// import modular CSS styles for this component
import styles from "./CustomCheckbox.module.css";

const CustomCheckbox = (props) => {
    const [checked, setChecked] = useState(props.checked);

    /**
     * handle changes to the value of checked and modify in FilterForm through props
     * @param {boolean} value the truth value of whether checkbox is selected
     */
    const onCheckChange = (value) => {
        setChecked(value);
        props.onChange(props.type, props.label);
    };

    /**
     * handle clearing of the checkbox based on input from FilterForm clear button
     */
    const onCheckClear = () => {
        setChecked(false);
    };

    // send individual checkbox clearing functions in array form to parent component FilterForm for global clearing
    props.onClear.push(onCheckClear);
    

    return (
        <Checkbox
            containerClassName={styles.checkbox}
            icon={
                <div className={styles.checkboxContainer}>
                    <FiCheck color="white" size={14} />
                </div>
            }
            name={props.type}
            onChange={onCheckChange}
            checked={checked}
            borderColor={checked ? "#41cbb6" : "#707070"}
            size={14}
            style={{ overflow: "hidden", cursor: "pointer" }}
            label={props.label}
            labelStyle={{ marginLeft: "0.5em", color: props.color }}
        />
    );
};

export default CustomCheckbox;
