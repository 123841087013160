import React from "react";
import { Container, Row, Col, Button, Card, Table } from "react-bootstrap";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import laptop from "../../../../../assets/laptop.png";
import "./RepaireJob.css";

const ary = [
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
  },
];

const RepaireJobComp = () => {
  return (
    <>
      <div className="main">
        <Container>
          <Row>
            <Col sm={12} className="hed"></Col>
          </Row>
        </Container>
        <Card className="_card">
          <Container>
            <Row>
              <Col sm={2}>
                <img className="mt-0 mb-1" src={laptop} alt="laptop_img" />
              </Col>
              <Col>
                <Container className="mb-3 mt-0">
                  <Row>
                    <Col>
                      <label> Serial Number</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="123456789"
                      ></input>
                    </Col>
                    <Col>
                      <label>Brand</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="DELL"
                      ></input>
                    </Col>
                    <Col>
                      <label>RAM</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="16GB"
                      ></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-2">
                      <label> MAC Address</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="123456789jhkj"
                      ></input>
                    </Col>
                    <Col className="mt-2">
                      <label>Type</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="kuch bhi"
                      ></input>
                    </Col>
                    <Col className="mt-4">
                      <label className="mt-1">
                        View Complete Details <ArrowRightAltIcon />
                      </label>
                      <br />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Card>
        <Container>
          <Row>
            <Col sm={12} className="hed">
              Issue Details
            </Col>
          </Row>
        </Container>
        <Card className="_card">
          <Container>
            <Row className="mb-3">
              <Col sm={2}>
                <label> Title</label> <br />
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="123456789"
                ></input>
              </Col>
              <Col sm={2}>
                <label>Logged On</label> <br />
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="DELL"
                ></input>
              </Col>
              <Col sm={2}>
                <label>Priority</label> <br />
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="16GB"
                ></input>
              </Col>
              <Col sm={3}>
                <label> Brief</label> <br />
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="123456789jhkj"
                ></input>
              </Col>
              <Col sm={2}>
                <label> Logged By</label> <br />
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="123456789jhkj"
                ></input>
              </Col>
            </Row>
          </Container>
        </Card>
        <Container>
          <Row>
            <Col sm={12} className="hed">
              Repair Jobs
            </Col>
          </Row>
        </Container>
        <div className="tableborderrep">
          <Container className="mt-0 mb-5">
            {/* <Card className="_card"> */}
            <Table responsive className="tabl">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Maintenaned On</th>
                  <th>Maintenaned By</th>
                  <th>Cost</th>
                  <th>Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ary.map((ar) => (
                  <tr className="tabledata">
                    <td className="tabletaskId">{ar.a}</td>
                    <td>{ar.b}</td>
                    <td>{ar.c}</td>
                    <td>{ar.d}</td>
                    <td>{ar.e}</td>
                    <td className="tableaction">
                      {/* <img
                  onClick={() => history.push("/viewTask")}
                  src={pencilEditButtonImg}
                />
                <img src={rubbishBinImg} /> */}
                      <EditIcon color="primary" />
                      <DeleteIcon color="primary" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* </Card> */}
          </Container>
        </div>
      </div>
    </>
  );
};

export default RepaireJobComp;
