import React, { useState, useEffect } from "react";
import style from "./EditCommentsComponent.module.css";
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import cookie from "react-cookies";
import Moment from 'moment';
import Calendar from 'react-calendar';
import { useHistory } from "react-router-dom";
import constants from "../../../../../Utils/constants";

const currentDate = Moment().toDate();
const EditCommentsComponent = (props) => {
  console.log('componenteditdata', props)
  const history = useHistory();

  const [commentData, setCommentData] = useState(props.commentData);
  const [newData, setnewData] = useState(props.taskData);
  const [updatedComment, setupdatedComment] = useState(props.commentData.taskComment);
  const [updatedCommentId, setupdatedCommentId] = useState(props.commentData.taskCommentId);
  const [dateState, setDateState] = useState(props.commentData.taskModifiedDate);
  const [showCal, setShowCal] = useState(false);
  console.log('updatedCommentId', updatedCommentId)
  console.log('updatedComment', updatedComment)

  const changeDate = (e) => {
    setDateState(e)
    setShowCal(false)
  }

  const getHeaders = () => {
    const tokenData = JSON.parse(cookie.load('token'))
    return tokenData;
  }

  const url2 = constants.apiBaseUrl2;



  const handleUpdate = (e) => {
    e.preventDefault();
    console.log('commentData', commentData);
    const data1 = {
      // "taskCommentId": props.commentData.taskCommentId,
      "taskCommentId": updatedCommentId,
      "updatedComment": updatedComment
    }
    console.log('data1', data1);


    axios.post(url2 + '/editTaskComment', data1,
      {
        headers
          : {
          "Access-Control-Allow-Origin": "*",
          'Authorization': ` ${cookie.load('token')}`,
        employeeId: localStorage.getItem("employeeId")

        }
      }
    )

      .then((res) => {
        console.log('responsedata', res.data)

        if (res.data.responseData.taskCommentUpdated == true) {
          history.goBack(-1)
        }
      })
      .catch((err) => {
        console.log(err)
        return err;
      })
  }

  // useEffect(() => {
  // // console.log("hello1234")

  // },[])


  return (
    <div className={style.rightSection}>
      <div className={style.container}>
        <span>Edit Comment</span>
        <div className={style.createTask}>
          {/* {showErr &&
              <div className={style.req_msg}><p>* All fields are mandatory.</p></div>
            } */}
          <form id="create_task_id" className={style.gridContainer}>
            <label className={style.titleLabel}>
              Reported Date:
            </label>

            <label className={style.valueTitle}>
              <p>{Moment(dateState).format("DD MMM (ddd)")}</p>
              {/* <input name="task_title" type="text" id="tast_title" /> */}
            </label>
            <label className={style.userLabel}>
              Username:
            </label>
            <label className={style.valueUser}>
              <p>
                {commentData.name}
              </p>
              {/* <input name="plannedEnd" type="text" id="plannedEnd" value={Moment(dateState).format("DD MMM (ddd)")}/> */}
            </label>
            <label className={style.descLabel}>
              Comment Description:
            </label>

            <label className={style.areaLabel}>
              {/* <textarea name="comment" type="number" id="comment"
                      value={updatedComment} onChange={e => setupdatedComment(e.target.value)}
                      /> */}
              {/* <div className={style.scrollAdd}> */}
              <div className={style["text-area-container"]}>
                <textarea className={style.taskdescription} onChange={e => setupdatedComment(e.target.value)}
                  value={updatedComment} name="task_description" type="number" id="task_description" rows={7}
                  // className={style.scrollAdd}
                />
              </div>
              {/* </div> */}
            </label>

          </form>
        </div>
        <button className={style.submitButton} onClick={handleUpdate}>
          Update
        </button>
        <button className={style.backButton}
          // onClick={() => history.push("/taskTracker/TaskAssignedByMeComponent")}
          onClick={() => history.goBack(-1)}
        > Back</button>
      </div>
    </div>
  );
};

export default EditCommentsComponent;
