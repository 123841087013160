import axios from "axios";
import React, { useState, useEffect } from "react";
import Style from "./projectInfo.module.css";
import cookie from "react-cookies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import calendar from "../../../../assets/calendar.png";
import calendar from "../../../../../assets/Grievance portal/dateldpi.png";
// import calendar from "../../../../../../../../assets/Grievance portal/dateldpi.png";
import { useHistory, useLocation } from "react-router-dom";
import constants from "../../../../../Utils/constants";
import Moment from "moment";
import { customStyles } from "../../../Clients/component/style";
import Select from "react-select";
import DatePicker from "react-datepicker";


export default function ProjectInfo() {
  const[employee,seEmployeeList]=useState([])
  const CustomInput = React.forwardRef((props, ref) => {

    return (
      <div onClick={props.onClick}>
        <label ref={ref} style={{ fontSize: "15px", fontWeight: 500 }}>
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "13%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const [projectData, setProjectData] = useState([]);
  const [state, setState] = useState({
    projectDescription: "",
    name: "",
    catogaryproject: "",
    startDate: "",
    clientId: 0,
    projectId: 0,
    empId:0,
    empName:""
  });

  
  const [startDate, setStartDate] = useState("");
  const [DateError, setDateError] = useState("");

  const datHandlet = (date) => {
    setStartDate(date);
    {
      formErrors.startDate = "";
    }
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setState({...state,empId: e.value });
          setState({...state,empName:e.label});
          break;
        default:
      }
    };
  }


 
  const query = useQuery();
  const id = query.get("id");

  const history = useHistory();
  const [date, setDate] = useState("");
  const [clientData, setClientData] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [ProjectTyepe, setProjectType] = useState([]);
  const [expiry_date, setExpiry_dat] = useState(new Date());
  const [required, setRequired] = useState(false);
 

  const initialValue = {
    clientName: "",
    projectName: "",
    startDate: "",
    TypeOfProject: "",
    Description: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disable, setDisable] = useState(false);

  const baseUrl = constants.apiBaseUrlResource;
  const url = constants.apiBaseUrl;
  const getClientData = () => {
    axios
      .get(baseUrl + "/getClientFilterData?Status=Active", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setClientData(res.data.responseData.clientFilterDataResponse);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployeeList = () => {
    axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        seEmployeeList(res.data.responseData.employeeList);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const getProjectDetails = (id) => {
    axios
      .get(baseUrl + `/getProjectDetails?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setProjectData(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/ProjectDetails");
    }, 2000);
  };

  const getemployee = employee?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const onSubmit = (e) => {
    e.preventDefault();

    if (!startDate || !state?.empId) {
      setRequired(true);
    } else {
      setRequired(false);
    }
  
    const data = {
      projectDescription: state.projectDescription.trim(),
      name: state.name.trim(),
      catogaryproject: state.catogaryproject,
      startDate: Moment(startDate).format("YYYY-MM-DD hh:mm"),
      clientId: state.clientId,
      empId:state?.empId
    };
    setFormErrors(validate(formValue));
    setIsSubmit(true);
    axios
      .post(baseUrl + "/createProject", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus.statusCode == 1) {
          return;
        } else {
          setDisable(true);
          notify();
          handleRedirect();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit)
      console.log(formValue);
  }, [formErrors]);
  const getApplicationType = () => {
    axios
      .get(baseUrl + "/getApplications", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setApplicationList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectType = () => {
    axios
      .get(baseUrl + "/getProjectTypes", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setProjectType(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClientData();
    getApplicationType();
    getProjectType();
    getEmployeeList()
    getProjectDetails(id);
  }, []);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    if (e.target.name === "fname") {
      {
        formErrors.projectName = "";
      }
      setState({
        ...state,
        name: e.target.value,
      });
    } else if (e.target.name === "description") {
      {
        formErrors.Description = "";
      }
      setState({
        ...state,
        projectDescription: e.target.value,
      });
    } else if (e.target.name === "projectType") {
      {
        formErrors.TypeOfProject = "";
      }
      setState({
        ...state,
        catogaryproject: e.target.value,
      });
    } else if (e.target.name === "clientData") {
      {
        formErrors.clientName = "";
      }
      setState({
        ...state,
        clientId: e.target.value,
      });
    }
  };

  const validate = (value) => {
    const error = {};
    if (!value.clientData) {
      error.clientName = "Please Select Client";
    }
    if (!value.fname?.trim().length) {
      error.projectName = " Please Enter Project Name";
    }
    if (!value.projectType) {
      error.TypeOfProject = "Please Select Type of Project";
    }
    if (!value.description) {
      error.Description = " Please Enter Project Description";
    }
    return error;
  };

  function notify() {
    toast("Data Submitted Successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  }

  const CustomInput1 = () => {
    return (
      <span>
        <img
          // src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className={Style.container}>
          <div className={Style.colum}>
            <p className={Style.clients}>
              Clients <span style={{ color: "red" }}>*</span>
            </p>
            <select
              onChange={onInputChange}
              name="clientData"
              className={Style.input}
            >
              <option hidden value="">
                Select Client
              </option>
              {clientData?.map((val) => {
                return <option value={val.clientid}>{val?.name}</option>;
              })}
            </select>
            <p className={Style.error}>{formErrors.clientName}</p>
            {/* {required ? <p style={{ color: "red" }}>Please Enter Client Name </p> : null} */}
          </div>
          <div className={Style.colum}>
            <p className={Style.clients}>
              Project Name<span style={{ color: "red" }}>*</span>
            </p>
            <input
              autoComplete="off"
              className={Style.input}
              type="text"
              id="fname"
              name="fname"
              defaultValue={projectData?.clientName}
              onChange={onInputChange}
              style={{ backgroundColor: "#F5F6FA" }}
            />
            <p className={Style.error}>{formErrors.projectName}</p>
          </div>
          <div className={Style.colum}>
            <p className={Style.clients}>
              PM/PC <span style={{ color: "red" }}>*</span>
            </p>
            <Select
                styles={customStyles}
                classNamePrefix={"create_position"}
                placeholder="Search By Client Name"
                components={{
                  DropdownIndicator: () =>null,
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setState({
                    ...state,
                    empId: e.value,
                  });
                }}
                options={getemployee}
              />
            {/* <Select
                   styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Search By Client Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    state?.empName
                      ? {
                        value: state?.empId,
                        label: state?.empName,
                      }
                      : null
                  }
                  onChange={(e) => { onSearchHandler(e, "Code")}}
                  options={getemployee}
                /> */}


            {required && !state.empId? <p className={Style.error}>Please Select PM/PC</p>:null}
          </div>
        </div>
        <div className={Style.container1}>
          <div className={Style.colum}>
            <p className={Style.clients}>
              Project Start Date <span style={{ color: "red" }}>*</span>
            </p>
            <div className={Style.date}>
              <DatePicker
                dateFormat="dd MMM yy"
                // minDate={new Date()}
                selected={startDate}
                startDate={null}
                onChange={(date) => {
                  setStartDate(date);
                 
                }}
                disabledKeyboardNavigation
                defaultValue={null}
                customInput={<CustomInput />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
            {required && !startDate? (
              <p className={Style.error}>Please Select Start Date</p>
            ) : null}
          </div>
          <div className={Style.colum}>
            <p className={Style.clients} style={{ marginTop: "0.4em" }}>
              Type of Project <span style={{ color: "red" }}>*</span>
            </p>
            <select
              className={Style.input}
              name="projectType"
              onChange={onInputChange}
              style={{ backgroundColor: "#F5F6FA" }}
            >
              {" "}
              <option hidden value={""}>
                Select Type of Project
              </option>
              {ProjectTyepe.map((val) => {
                return <option value={val?.id}>{val?.name}</option>;
              })}
            </select>
            <p className={Style.error}>{formErrors.TypeOfProject}</p>
          </div>
          <div className={Style.colum}></div>
        </div>
        <div className={Style.container2}>
          <p className={Style.clients} style={{ marginTop: "0.4em" }}>
            Project Description 
          </p>

          <textarea
            autoComplete="off"
            onChange={onInputChange}
            className={Style.text}
            name="description"
            style={{ backgroundColor: "#F5F6FA" }}
          ></textarea>
        </div>

        <div className={Style.inputbutton}>
          <button
            type="submit"
            value="Submit"
            className={Style.submitBtn}
            disabled={disable}
          >
            Submit
          </button>
        </div>
      </form>

      <ToastContainer />
    </>
  );
}
