import React from "react";
import Header from "../../header/Header";
import SideBar from "../../sidebar/SideBar";
import style from "./report.module.css";
import { getReportOne } from "../../../../Utils/axios/umsApi";
import { useQuery } from "react-query";
import { width } from "@mui/system";
const Report = () => {
  const { data, isLoading, isError } = useQuery(["getReportOne"], () =>
    getReportOne()
  );
  return (
    <>
      {/*<Header />*/}
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.report}>Report</div>
          <div className={style.UsersList}>
            <div
              style={{
                display: "flex",
              }}
            >
              <div className={style.td}>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#EAF7FF",
                  }}
                  className={style.countdata1}
                >
                  Date
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#FFFFEA",
                  }}
                  className={style.countdata2}
                >
                  Total
                  <br />
                  Count
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#FFEAEA",
                  }}
                  className={style.countdata}
                >
                  Partner
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#FFEAEA",
                  }}
                  className={style.countdata}
                >
                  Employee
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#FFEAEA",
                  }}
                  className={style.countdata}
                >
                  Contract
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#FFEAEA",
                  }}
                  className={style.countdata}
                >
                  Intern
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#FFEAEA",
                  }}
                  className={style.countdata}
                >
                  Freelance
                </p>
                <div
                  className={style.delevery}
                  style={{
                    fontWeight: "700",
                    backgroundColor: "#EAFFEE",
                  }}
                >
                  {data
                    ?.slice(0, 1)[0]
                    ?.functionalAreaCountResponseModel?.map((e) => {
                      return (
                        <p
                          style={{
                            fontWeight: "700",
                            backgroundColor: "#EAFFEE",
                          }}
                          className={style.countdata}
                        >
                          {e?.areaName}
                        </p>
                      );
                    })}
                </div>
                <div
                  className={style.delevery}
                  style={{
                    fontWeight: "700",
                    backgroundColor: "rgb(181 211 236)",
                  }}
                >
                  {data
                    ?.slice(0, 1)[0]
                    ?.deliveryTechCountResponseModel?.map((e) => {
                      return (
                        <p
                          className={style.countdata}
                          style={{
                            fontWeight: "700",
                            backgroundColor: "rgb(181 211 236)",
                          }}
                        >
                          {e?.techName}
                        </p>
                      );
                    })}
                </div>
              </div>
            </div>
            <div>
              {isLoading && <div className={style.nodata}>Loading...</div>}
              {isError && (
                <div className={style.nodata}>Something went wrong...</div>
              )}
              {!isLoading && !isError && (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {data?.length > 0 ? (
                    data?.map((e) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div className={style.td}>
                            <p className={style.countdata1}>{e?.month}</p>
                            <p className={style.countdata2}>{e?.totalCount}</p>
                            <p className={style.countdata}>
                              {e?.typeCountResponseModel?.partner}
                            </p>
                            <p className={style.countdata}>
                              {e?.typeCountResponseModel?.employee}
                            </p>
                            <p className={style.countdata}>
                              {e?.typeCountResponseModel?.contract}
                            </p>
                            <p className={style.countdata}>
                              {e?.typeCountResponseModel?.intern}
                            </p>
                            <p className={style.countdata}>
                              {e?.typeCountResponseModel?.freelance}
                            </p>
                            <div className={style.delevery}>
                              {e?.functionalAreaCountResponseModel?.map((e) => {
                                return (
                                  <p className={style.countdata}>
                                    {e?.areaCount}
                                  </p>
                                );
                              })}
                            </div>
                            <div className={style.delevery}>
                              {e?.deliveryTechCountResponseModel?.map((e) => {
                                return (
                                  <p className={style.countdata}>
                                    {e?.techCount}
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className={style.nodata}>No Data</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
