import React, { useEffect, useState } from "react";
import style from "./Client.module.css";
import { Link } from "react-router-dom";
import milestone from "../../../../src/assets/milestone.png";
import { Table } from "react-bootstrap";
import TableRow from "../Clients/component/TableRow";
import SideBar from "./component/SideBar";
import loaderImg from "../../../assets/loader.gif";
import cookie from "react-cookies";
import searchIcon from "../../../assets/Icon-search.png";
import constants from "../../../Utils/constants";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import { customSearch } from "./component/style";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import Pagination from "../CommonComponent/Pagination";
import Filter from "../components/Filter";
import rightTickPRM from "../../../assets/rightTickPRM.png";

export default function Clients() {
  const baseUrl = constants.apiBaseUrlResource;
  const [clientData, setClientData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalClient, settotalClient] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [status, setStatus] = useState([]);
  const [client, setClient] = useState([]);
  const [filterName, setFilterName] = useState({});
  const [allData, setAllData] = useState({
    status: status,
    clientId: [],
  });
  const limit = 10;

  const [clearData] = useState({
    status: [],
    clientId: [],
  });

  const [showFilter, setShowFilter] = useState(false);
  const [applied, setApplied] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [clientFilterList, setClientFilterList] = useState([]);

  const getClientData = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length == 0 ? clearData : allData;

    setIsLoaded(true);
    axios
      .post(
        baseUrl + "/getClient?" + "limit=" + limit + "&page=" + currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        console.log({ res });
        setIsLoaded(false);
        setClientData(res.data.responseData);
        setpageCount(res.data.responseData.totalClient / 10);
        settotalClient(res.data.responseData.totalClient);
      })
      .catch((err) => {
        setIsLoaded(false);
        console.log(err);
      });
  };

  const getClientForFilter = () => {
    axios
      .get(baseUrl + "/getClientForFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
        status: status,
      })
      .then((res) => {
        console.log("data", res.data.responseData);
        setClientFilterList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, clientId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
    }
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setCurrentPage(1)
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            status: [],
            // clientId: [],
          }
        : null;
    });
    getClientData(clearData, 1);
    setStatus([]);
    setShowFilter(false);
    setApplied(false);
  };

  const handleApplied = (arg) => {
    if (status.length !== 0 || client.length !== 0) {
      setApplied(true);
    }
    setShowFilter(false);
    getClientData(allData, 1);
  };

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const getPositionCodeForFilterOption = clientFilterList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  useEffect(() => {
    getClientData(allData, currentPage);
    getClientForFilter();
  }, [currentPage, allData]);

  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", width: "25%", fontWeight: "700" }}
              >
                Clients
              </div>
              <div style={{ width: "31%", paddingLeft: "14%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img className={style.appliedcheck} src={rightTickPRM} />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>
              <div
                className={style.s1}
                style={{ width: "24%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.clientId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        clientId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Client Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    filterName?.positionCode
                      ? {
                          value: allData?.clientId,
                          label: filterName?.positionCode,
                        }
                      : null
                  }
                  onChange={(e) => {
                    onSearchHandler(e, "Code");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  options={getPositionCodeForFilterOption}
                />
              </div>
              <div className={style.btn_add}>
                <Link to="/createClient" style={{ textDecoration: "none" }}>
                  <button className={style.create_btn}>
                    <span className={style.plus}>+</span>
                    <p>Create Client</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setShowFilter(false);
              closeFilter()
            }}
            className={style.original_table}
            style={{ backgroundColor: "white", height: "27rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : clientData?.clients?.length>0? (
              <Table borderless size="sm" style={{ fontSize: "13px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "25px" }}>Client Name</th>
                    <th style={{ padding: "25px" }}>Client Domain</th>
                    <th style={{ padding: "25px" }}>Status</th>
                    {/* <th style={{ padding: "25px" }}>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {clientData?.clients.map((val) => {
                    return (
                      <TableRow
                        id={val.clientid}
                        name={val.name}
                        status={val.statusTypeName}
                        domain={val.domain}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : <div className={style.noData}>
                  <img src={milestone} />
                  <h3 className={style.noData_header}>
                    No data available
                  </h3>
                </div>}
          </div>
          {totalClient > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          setstatus={setStatus}
          type={"client"}
          clientList={clientFilterList}
          setClient={setClient}
        />
      )}
    </>
  );
}
