/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import style from "./EditIssueComp.module.css";
import { customStyles } from "../../../commonComponent/style";
import Select from "react-select";
import cookie from "react-cookies";
import axios from "axios";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import { useHistory, useLocation } from "react-router";
import ClearIcon from "@mui/icons-material/Clear";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { Toastify, ToastifyError } from "../../../../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const EditissueComp = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const source = axios.CancelToken.source();
  const query = useQuery();
  const id = query.get("id");
  const [formData, setFormData] = useState({});
  const [fileName, setFileName] = useState("");
  const [subCategary, setCategary] = useState();
  const [categary, setcategary] = useState();
  const [validate, setValidate] = useState(false);
  const [file, setFile] = useState(null);
  const [disablebtn, setDisableBtn] = useState(false);
  console.log("test", formData?.categoryId);
  // handleRedirect after edit Issue
  const handleReDirect = () => {
    setTimeout(() => {
      history.goBack(-1);
    }, 2000);
  };
  // cancel handler
  const cancelHandler = () => {
    history.goBack(-1);
  };
  // url
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;

  categary?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  const getAllCategaryOption = categary?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getAllsubCategaryOption = subCategary?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getCategary = () => {
    axios
      .get(url + "/getCategoryList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
        cancelToken: source.token,
      })
      .then((res) => {
        console.log(res);
        setcategary(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubCategary = (id) => {
    axios
      .get(url + `/getSubCategoryListByCategoryId?categoryId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
        cancelToken: source.token,
      })
      .then((res) => {
        console.log(res);
        setCategary(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const history = useHistory();
  const CustomInput = () => {
    return (
      <span>
        <img
          alt="dropdownIcon"
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
    }
  };

  async function handleFileUpload(file) {
    setFile(file);
    setFileName(file.name);
    const formDataa = new FormData();
    formDataa.append("file", file);

    try {
      const response = await axios.post(url + "/uploadFile", formDataa);
      if (response.status === 200) {
        // setFile(null);
        // setFileName("");
        setFormData({ ...formData, attachment: response.data.responseData });
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }

  async function handleFileDeletion() {
    setFileName("");
    try {
      const response = await axios.put(
        url + `/deleteUploadFile?url=${formData?.attachment}`
      );
      if (response.status === 200) {
        setFormData({ ...formData, attachment: "" });
        setFile(null);
        setFileName("");
      } else {
        // handle error
      }
    } catch (error) {
      // handle error
    }
  }
  //get Issue by Id
  const getIssueById = () => {
    axios
      .get(url + `/getIssueById?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        setFormData(res.data.responseData);
        res.data.responseData?.categoryId !== 8 &&
          getSubCategary(res.data.responseData.categoryId);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getIssueById();
    getCategary();
    return () => {
      console.log("hello");
      source.cancel("Component is unmounted");
    };
  }, []);

  // cancel Button Handler

  const formHAndler = (e) => {
    e.preventDefault();
    if (
      !formData?.description?.trim() ||
      (formData?.categoryId === 8
        ? formData?.subCategoryId
        : !formData?.subCategoryId)
    ) {
      setValidate(true);
      return true;
    }
    const data = {
      categoryId: formData?.categoryId,
      description: formData?.description,
      loggedDate: formData?.loggedDate,
      raisedBy: formData?.raisedBy,
      id: formData?.id,
      subCategoryId: formData?.subCategoryId,
      attachment: formData?.attachment,
    };
    if (!formData?.description?.trim()) {
      // setValidate(true);
      return true;
    }
    axios
      .post(url + "/createIssue", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          ToastifyError(res.message);
        } else {
          Toastify("Issue Edit Successfully");
          handleReDirect();
          setDisableBtn(true);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  return (
    <>
      <ToastContainer />
      <div className={style.main}>
        <p className={style.paragraph}>Edit Issue</p>
        <div>
          <div className={style.card}>
            <p className={style.title}>Issue Details</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Category<span className={style.mandatory}>*</span>
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={getAllCategaryOption}
                  value={
                    formData?.categoryId
                      ? {
                          value: formData?.categoryId,
                          label: formData?.categoryName,
                        }
                      : null
                  }
                  onChange={(e) => {
                    
                      e.value !== 8 ? getSubCategary(e?.value) : null;
                    ;
                    setFormData({
                      ...formData,
                      categoryId: e.value,
                      categoryName: e.label,
                      subCategoryId: 0,
                    });
                  }}
                ></Select>
              </div>
              <div className={style.cu_col2}>
                {formData?.categoryId === 8 ? null : (
                  <div>
                    <p className={style.cu_label}>
                      Sub Category<span className={style.mandatory}>*</span>
                    </p>
                    <Select
                      styles={customStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      options={getAllsubCategaryOption}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          subCategoryId: e.value,
                          subCategoryName: e.label,
                        });
                      }}
                      value={
                        formData?.subCategoryId
                          ? {
                              value: formData?.subCategoryId,
                              label: formData?.subCategoryName,
                            }
                          : null
                      }
                    />
                    <p
                      className={
                        validate && !formData?.subCategoryId
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Sub Category
                    </p>
                  </div>
                )}
              </div>
              <div className={style.cu_col3}></div>
            </div>

            <div className={style.cu_col1} style={{ marginLeft: "21px" }}>
              <p className={style.cu_label}>
                Description<span className={style.mandatory}>*</span>
              </p>
              <textarea
                name="postContent"
                rows={2}
                defaultValue={formData?.description}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    description: e.target.value,
                  });
                }}
                onKeyUp={(e) => {
                  if (e.ctrlKey && (e.key === "c" || e.key === "x" || e.key === 'Backspace')) {
                    setFormData({
                      ...formData,
                      description: e.target.value,
                    });
                  }
                }}
                style={{ outline: "none", width: "98%", paddingLeft: "5px" }}
              />

              <p
                className={
                  validate && !formData?.description?.trim()
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Description
              </p>
            </div>
            <p className={style.cu_label1}>Attachment</p>
            <div className={style.chooseFile}>
              {formData?.attachment ? (
                <div className={style.rmpdf} style={{ paddingTop: "3px" }}>
                  {
                    <>
                      {checkfileType(formData?.attachment?.split(".")[1])}&nbsp;
                      <span>{formData?.attachment?.split("@")[1]}</span>
                    </>
                  }
                  <ClearIcon
                    onClick={handleFileDeletion}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*, .pdf, .doc, .docx"
                  className={style.upload_resume}
                  // onChange={(e) => {
                  //   handleFileUpload(e.target.files[0]);
                  // }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const fileSize = file.size / 1024 / 1024; // File size in MB
                      if (fileSize <= 10) {
                        handleFileUpload(file);
                      } else {
                        ToastifyError("File size exceeds the limit of 10MB.");
                        e.target.value = null; // Reset the file input value
                        return;
                      }
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div className={style.buttonDiv}>
            <button
              className={style.cancelbtn}
              onClick={() => {
                cancelHandler();
              }}
            >
              Cancel
            </button>
            <button
              className={style.submitbtn}
              disabled={disablebtn}
              onClick={formHAndler}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
