import React, { useState } from "react";
import style from "./QuestionBySkillsCom.module.css";
import { customStyles } from "../../CommonComponent/style";
import Select from "react-select";
import axios from "axios";
import cookie from "react-cookies";
import dropdownIcon from "../../../../assets/downIcon.png";
import { useEffect } from "react";
import constants from "../../../../Utils/constants";
import { useHistory } from "react-router";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";

export const QuestionBySkillsCom = () => {
  const [skillListing, setSkillListing] = useState([]);
  const [clientDropDownOpen, setClientDropdownOpen] = useState(true);

  const [formData, setFormData] = useState({
    empId: localStorage.getItem("employeeId"),
    technologyId: 0,
  });
  const history = useHistory();

  if (formData?.technologyId) {
    history.push({
      pathname: "/ViewBySkills",
      state: formData,
    });
  }

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  // base URL
  const baseUrl = constants.apiBaseUrlResource;
  const url = process.env.REACT_APP_QUESTIONNAIRE_URL;
  // client listing api ointegrate
  const getClientListing = () => {
    axios
      .get(url + "/getSkillForDashboard", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setSkillListing(res.data.responseData);
      })
      .catch((err) => {});
  };
  // technology listing api ointegrate

  const getSkillListing = skillListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  useEffect(() => {
    getClientListing();
  }, []);

  // raisedToList?.sort((a, b) =>
  //   a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  // );
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="amg"
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };
  return (
    <>
      <div className={style.main}>
        <div>
          <div className={style.card}>
            <div className={style.Cu_row1}>
              {!formData?.expRangeId ? (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>Select Skill</p>
                  <Select
                    styles={customStyles}
                    menuIsOpen={clientDropDownOpen}
                    onMenuClose={() => setClientDropdownOpen(false)}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={getSkillListing}
                    onChange={(e) => {
                      handleChange("technologyId", e?.value);
                    }}
                  ></Select>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
