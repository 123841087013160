import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./EmployeeFeedback.module.css";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import dropdownIcon from "../../../../assets/arrowdownIcon.png";
import calender from "../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { FaTimes } from "react-icons/fa";
import moment from "moment";
import cancel from "../../../../assets/cancell.png";
import CustomAutocomplete from "./CustomAutocomplete";
import {
  CustomInput2,
  customStyles,
  customStyles2,
  customStylesERP,
  customStylesERP2,
} from "../../utils/style";
import StarRating from "../../rating/StartRating";
import OverallRating from "./commonRating/OverallRating";
import OverallRating2 from "./commonRating/OverallRating2";
import OverallRating3 from "./commonRating/OverallRating3";
import BreadCrum from "../../breadcrums/BreadCrum";
import { useMutation, useQuery } from "react-query";
import {
  getSkills,
  addSkill,
  uploadResume,
  deleteResume,
} from "../../../../Utils/axios/api";
import {
  getReviewSourcType,
  getReviewType,
  getEmployeeList,
  getEmployeeInformation,
  getAllClientInterviewer,
  // getSkills,
  getClientForFilter,
  getAllActiveProject,
  getFoxMatrixInterviewers,
  createPerformanceFileType,
  createPerformanceReviewRating,
  createPerformanceReviewSubjectiveType,
  createPerformanceReviewTest,
} from "../../utils/api";
function EmployeeFeedback({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
  fieldName,
}) {
  const [validate, setValidate] = React.useState(false);
  const [formData, setFormData] = React.useState({
    employeId: prevData?.empId != "" ? prevData?.empId : "",
    // employeeName: "",
    feedbackTypeId: "",
    reviewDate: "",
    reviewSourceTypeId: "",
    reviewTypeId: "",
    projectId: "",
    // reviewedById: "",
  });

  const [formDataRating, setFormDataRating] = useState({
    overallFeedback: "",
    feedbackSkills: [],
    overallRatingValue: "",
    statusId: "",
  });
  const [formDataTest, setFormDataTest] = useState({
    testSkills: [],
    overallRatingValue: "",
  });
  const [formDataSubjective, setFormDataSubjective] = useState({
    subjective: "",
    overallRatingValue: "",
    statusId: "",
  });
  const [formDataFile, setFormDataFile] = useState({
    file: "",
    overallRatingValue: "",
    statusId: "",
  });
  const [testSkillData, setTestSkillData] = useState([]);
  const [doc, setDoc] = useState("");

  const { data: getReviewData } = useQuery(
    ["getReviewType", formData?.reviewSourceTypeId],
    () => getReviewType(Number(formData?.reviewSourceTypeId))
  );

  const { data: getClientForFilterList } = useQuery(
    ["getClientForFilter"],
    getClientForFilter
  );

  const { data: getAllClientInterviewers } = useQuery(
    ["getAllClientInterviewer"],
    getAllClientInterviewer
  );

  const { data: getFoxMatrixInterviewersList } = useQuery(
    ["getFoxMatrixInterviewers"],
    getFoxMatrixInterviewers
  );

  const { data: getEmployeeLists } = useQuery(
    ["getEmployeeList"],
    getEmployeeList
  );

  const { data: getEmployeeList2 } = useQuery(
    ["getEmployeeInformation"],
    getEmployeeInformation
  );

  const { data: getResourceTypes } = useQuery(
    ["getReviewSourcType"],
    getReviewSourcType
  );

  const { data: getAllActiveProjectList } = useQuery(
    ["getAllActiveProject"],
    getAllActiveProject
  );

  const { data: getSkillList } = useQuery(["getSkills"], getSkills);

  const getReviewSourcTypeOption = getResourceTypes?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getReviewTypesOption =
    getReviewData?.length > 0
      ? typeof getReviewData !== "string" &&
        getReviewData?.map((i) => ({
          value: i?.id,
          label: i?.reviewType,
        }))
      : null;

  const getClientListOption = getClientForFilterList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getAllClientInterviewersOption = getAllClientInterviewers?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getFoxMatrixInterviewersListOption = getFoxMatrixInterviewersList?.map(
    (i) => ({
      value: i.id,
      label: i.name,
    })
  );
  const getAllActiveProjectListOption = getAllActiveProjectList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getEmployeeListOption =
    getEmployeeLists?.employeeList?.length > 1 &&
    getEmployeeLists?.employeeList?.map((i) => ({
      value: i?.id,
      label: i?.name,
    }));

  const getEmployeeListOption2 =
    getEmployeeList2?.length > 1 &&
    getEmployeeList2?.map((i) => ({
      value: i?.id,
      label: i?.name + " (" + i?.employeeCode + ") ",
    }));

  const getEmployeeListOption1 =
    getEmployeeLists?.employeeList?.length > 1 &&
    getEmployeeLists?.employeeList?.map((i) => ({
      value: i?.id,
      label: i?.name,
    }));

  const getSkillListOption = getSkillList?.map((i) => ({
    value: i.skillId,
    label: i.coreSkillName,
  }));

  const onlineOption = [
    {
      label: "Eval Ground",
      value: 1,
    },
  ];

  const uploadDocMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setFormDataFile({ ...formDataFile, file: data.toString() });
    },
  });

  useEffect(() => {
    setDoc(formDataFile?.file);
  }, [formDataFile?.file]);

  useEffect(() => {
    if (getReviewTypesOption?.length === 1 && !formData?.reviewTypeId) {
      setFormData({
        ...formData,
        reviewTypeId: getReviewTypesOption[0]?.value,
      });
    }
  }, [getReviewTypesOption?.length === 1 && !formData?.reviewTypeId]);

  const mutationSubjective = useMutation(
    createPerformanceReviewSubjectiveType,
    {
      onMutate: () => {
        // setDisabled(true);
      },
      onSuccess: (res) => {
        setNextScreen("EmployeePerformanceReview");
        setPrevData((prevData) => ({
          ...prevData,
          sidebar: "EmployeePerformanceReview",
          page: "EmployeePerformanceReview",
          page1: "EmployeePerformanceReview",
          page2: null,
          page3: null,
          active: "EmployeePerformanceReview",
        }));
      },
      onError: (error) => {
        // setDisabled(false);
        // setShowError(true);
      },
    }
  );
  const mutationFileType = useMutation(createPerformanceFileType, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      setNextScreen("EmployeePerformanceReview");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeePerformanceReview",
        page1: "EmployeePerformanceReview",
        page2: null,
        page3: null,
        active: "EmployeePerformanceReview",
      }));
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });
  const mutationReviewRating = useMutation(createPerformanceReviewRating, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      setNextScreen("EmployeePerformanceReview");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeePerformanceReview",
        page1: "EmployeePerformanceReview",
        page2: null,
        page3: null,
        active: "EmployeePerformanceReview",
      }));
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });
  const mutationReviewTest = useMutation(createPerformanceReviewTest, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      setNextScreen("EmployeePerformanceReview");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeePerformanceReview",
        page1: "EmployeePerformanceReview",
        page2: null,
        page3: null,
        active: "EmployeePerformanceReview",
      }));
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });

  const onfeedbackTypeIdOne = () => {
    if (
      formDataRating?.overallFeedback?.trim() &&
      formDataRating?.statusId &&
      formData?.employeId &&
      formData?.reviewDate &&
      formData?.reviewTypeId
    ) {
      let data = {
        employeId: Number(formData?.employeId),
        employeeName:
          getEmployeeListOption2 &&
          getEmployeeListOption2?.find(
            (obj) => obj.value == formData?.employeId
          )?.label,
        feedbackTypeId: formData?.feedbackTypeId,
        reviewDate: formData?.reviewDate,
        projectId: formData?.projectId,
        reviewSourceTypeId: formData?.reviewSourceTypeId,
        reviewTypeId: formData?.reviewTypeId,
        // reviewedById: formData?.reviewedById,
        ...(formData?.reviewSourceTypeId == 4 ||
        formData?.reviewSourceTypeId == 2
          ? {}
          : {
              reviewedById: formData?.reviewedById,
            }),
        ...(formData?.reviewSourceTypeId == 2 && {
          clientId: formData?.clientId,
        }),
        ...(formData?.reviewSourceTypeId == 2
          ? {
              reviewedByName: formData?.reviewedByName?.trim(),
            }
          : null),
        ...(formData?.reviewSourceTypeId == 4 && {
          onlinePlatformId: formData?.onlinePlatformId,
        }),
        ...formDataRating,
      };
      setValidate(false);
      mutationReviewRating.mutate(data);
    } else {
      setValidate(true);
    }
  };

  const onfeedbackTypeIdTwo = () => {
    if (formData?.employeId && formData?.reviewDate && formData?.reviewTypeId) {
      let data = {
        employeId: formData?.employeId,
        employeeName:
          getEmployeeListOption2 &&
          getEmployeeListOption2?.find(
            (obj) => obj.value == formData?.employeId
          )?.label,
        feedbackTypeId: formData?.feedbackTypeId,
        reviewDate: formData?.reviewDate,
        projectId: formData?.projectId,
        reviewSourceTypeId: formData?.reviewSourceTypeId,
        reviewTypeId: formData?.reviewTypeId,
        // reviewedById: formData?.reviewedById,
        ...(formData?.reviewSourceTypeId == 4 ||
        formData?.reviewSourceTypeId == 2
          ? {}
          : {
              reviewedById: formData?.reviewedById,
            }),
        ...(formData?.reviewSourceTypeId == 2 && {
          clientId: formData?.clientId,
        }),
        ...(formData?.reviewSourceTypeId == 2
          ? {
              reviewedByName: formData?.reviewedByName?.trim(),
            }
          : null),
        ...(formData?.reviewSourceTypeId == 4 && {
          onlinePlatformId: formData?.onlinePlatformId,
        }),
        ...formDataTest,
      };

      setValidate(false);
      mutationReviewTest.mutate(data);
    } else {
      setValidate(true);
    }
  };
  const onfeedbackTypeIdThree = () => {
    if (formDataFile?.statusId && formData?.employeId && formData?.reviewDate) {
      let data = {
        employeId: Number(formData?.employeId),
        employeeName:
          getEmployeeListOption2 &&
          getEmployeeListOption2?.find(
            (obj) => obj.value == formData?.employeId
          )?.label,
        feedbackTypeId: formData?.feedbackTypeId,
        reviewDate: formData?.reviewDate,
        projectId: formData?.projectId,
        reviewSourceTypeId: formData?.reviewSourceTypeId,
        reviewTypeId: formData?.reviewTypeId,
        ...(formData?.reviewSourceTypeId == 4 ||
        formData?.reviewSourceTypeId == 2
          ? {}
          : {
              reviewedById: formData?.reviewedById,
            }),
        ...(formData?.reviewSourceTypeId == 2 && {
          clientId: formData?.clientId,
        }),
        ...(formData?.reviewSourceTypeId == 2
          ? {
              reviewedByName: formData?.reviewedByName?.trim(),
            }
          : null),
        ...(formData?.reviewSourceTypeId == 4 && {
          onlinePlatformId: formData?.onlinePlatformId,
        }),
        ...formDataFile,
      };
      setValidate(false);
      mutationFileType.mutate(data);
    } else {
      setValidate(true);
    }
  };
  const onfeedbackTypeIdFour = () => {
    if (
      formDataSubjective?.subjective?.trim() &&
      formDataSubjective?.statusId
    ) {
      let data = {
        employeId: formData?.employeId,
        employeeName:
          getEmployeeListOption2 &&
          getEmployeeListOption2?.find(
            (obj) => obj.value == formData?.employeId
          )?.label,
        feedbackTypeId: formData?.feedbackTypeId,
        reviewDate: formData?.reviewDate,
        projectId: formData?.projectId,
        reviewSourceTypeId: formData?.reviewSourceTypeId,
        reviewTypeId: formData?.reviewTypeId,
        // reviewedById: formData?.reviewedById,
        ...(formData?.reviewSourceTypeId == 4 ||
        formData?.reviewSourceTypeId == 2
          ? {}
          : {
              reviewedById: formData?.reviewedById,
            }),
        ...(formData?.reviewSourceTypeId == 2 && {
          clientId: formData?.clientId,
        }),
        ...(formData?.reviewSourceTypeId == 2
          ? {
              reviewedByName: formData?.reviewedByName?.trim(),
            }
          : null),
        ...(formData?.reviewSourceTypeId == 4 && {
          onlinePlatformId: formData?.onlinePlatformId,
        }),
        ...formDataSubjective,
      };
      setValidate(false);
      mutationSubjective.mutate(data);
    } else {
      setValidate(true);
    }
  };

  const onCreateHandler = () => {
    if (
      formData?.employeId &&
      formData?.reviewDate &&
      formData?.reviewTypeId &&
      formData?.feedbackTypeId &&
      ((formData?.reviewSourceTypeId == 2 &&
        formData?.reviewSourceTypeId &&
        formData?.clientId) ||
        (formData?.reviewSourceTypeId == 4 &&
          formData?.reviewSourceTypeId &&
          formData?.onlinePlatformId) ||
        ((formData?.reviewSourceTypeId != 4 ||
          formData?.reviewSourceTypeId != 2) &&
          formData?.reviewTypeId &&
          formData?.reviewedById))
    ) {
      //FileType
      if (formData?.feedbackTypeId == 3) {
        onfeedbackTypeIdThree();
      }
      //Rating type
      else if (formData?.feedbackTypeId == 1) {
        onfeedbackTypeIdOne();
      }
      //test Type
      else if (formData?.feedbackTypeId == 2) {
        onfeedbackTypeIdTwo();
      }
      //Subjective Type
      else if (formData?.feedbackTypeId == 4) {
        onfeedbackTypeIdFour();
      }
    } else {
      setValidate(true);
    }
  };

  const mutationAdd = useMutation(addSkill, {
    onSuccess: (res) => {
      let selected = formData?.testSkills;
      selected.pop();
      selected = [...selected, { value: res?.id, label: res?.skillName }];
      let items = {};
      selected?.forEach((element) => {
        if (!items[element?.value]) {
          items[element?.value] = element;
        }
      });
      let newData = Object.values(items);
      // const newSelect = selected?.map((item) => {
      //   if (item?.label == res?.preferredSkillName) {
      //     return { value: res?.id, label: res?.preferredSkillName };
      //   } else {
      //     return item;
      //   }
      // });

      setFormData({
        ...formData,
        testSkills: newData,
      });
    },
  });
  const mutationRating = useMutation(addSkill, {
    onSuccess: (res) => {
      let selected = formDataRating?.feedbackSkills;
      selected.push({ value: res?.id, label: res?.skillName });
      setFormDataRating({
        ...formDataRating,
        feedbackSkills: selected,
      });
    },
  });

  const isSameUser = (a, b) => a?.value === b?.value && a?.label === b?.label;
  const handleCross = (left, right, compareFunction) => {
    if (!left) {
      return;
    }
    return left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );
  };

  const handleSkill = (e) => {
    setFormDataRating({
      ...formDataRating,
      feedbackSkills: e,
    });
    setTestSkillData(e);
    let prevSelect = handleCross(formDataRating?.feedbackSkills, e, isSameUser);
    if (prevSelect?.length > 0) {
      return;
    }
    if (
      getSkillListOption?.some((item) => item?.label == e[e.length - 1]?.label)
    ) {
    } else {
      if (e?.length > 0) {
        setTimeout(() => {
          mutationRating.mutate({
            skill: e[e.length - 1]?.label,
          });
        }, 1000);
      }
    }
  };

  const handleSelect1 = (e) => {
    setFormDataTest({
      ...formDataTest,
      testSkills: e,
    });

    let prevSelect = handleCross(formDataTest?.testSkills, e, isSameUser);
    if (prevSelect?.length > 0) {
      return;
    }
    if (
      getSkillListOption?.some((item) => item?.label == e[e.length - 1]?.label)
    ) {
    } else {
      if (e?.length > 0) {
        setTimeout(() => {
          mutationAdd.mutate({
            skill: e[e.length - 1]?.label,
          });
        }, 1000);
      }
    }
  };

  const mainOption = () => {
    if (formData?.reviewSourceTypeId == 1) {
      return getFoxMatrixInterviewersListOption;
    } else if (formData?.reviewSourceTypeId == 2) {
      return getAllClientInterviewersOption;
      // return getEmployeeListOption1;
    } else {
      return getEmployeeListOption1;
    }
  };

  // get OverAllScore
  const getOverAllScore = formDataTest?.testSkills?.map((item) =>
    Number(item?.scoreObtained)
  );
  const totalOverAllScore = getOverAllScore?.reduce((acc, currentValue) => {
    if (currentValue) {
      return acc + currentValue;
    } else {
      return acc;
    }
  }, 0);

  //get maximum score
  const getMaxAllScore = formDataTest?.testSkills?.map((item) =>
    Number(item?.maxScore)
  );
  const totalMaxAllScore = getMaxAllScore?.reduce((acc, currentValue) => {
    if (currentValue) {
      return acc + currentValue;
    } else {
      return acc;
    }
  }, 0);

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          alt="calender"
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          alt="cancel"
          width="10px"
          height="10px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="dropdownIcon"
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  const CustomInput2 = () => {
    return formData?.projectId == "" || formData?.projectId == null ? (
      <>
        <span>
          <img
            src={dropdownIcon}
            alt="dropdownIcon"
            style={{ position: "absolute", top: "32%", right: "3%" }}
            height="10"
          />
        </span>
      </>
    ) : null;
  };

  const handleSelectOption = (option) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      employeId: option.value,
    }));
  };

  const handleClear = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      employeId: "",
    }));
  };

  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      ></BreadCrum>
      {/* first */}
      <div className={style.card}>
        <p className={style.title}>Employee Details</p>
        <div className={style.Cu_row1}>
          <div className={style.cu_col1}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Employee Name <span className={style.mand}>*</span>
            </p>
            {prevData?.empId === undefined ? (
              <>
                <CustomAutocomplete
                  options={getEmployeeListOption2}
                  handleSelectOption={handleSelectOption}
                  handleClear={handleClear}
                />
              </>
            ) : (
              <>
                <input
                  className={style.input}
                  readOnly
                  value={
                    getEmployeeListOption2 &&
                    getEmployeeListOption2?.find(
                      (obj) => obj.value == formData?.employeId
                    )?.label
                      ? getEmployeeListOption2 &&
                        getEmployeeListOption2?.find(
                          (obj) => obj.value == formData?.employeId
                        )?.label
                      : ""
                  }
                />
              </>
            )}
            {/* <Select
              styles={customStylesERP}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              options={getEmployeeListOption}
              onClick={(e) => setFormData({ ...formData, employeId: e?.label })}
              isDisabled={prevData?.empId === undefined ? false : true}
              value={
                getEmployeeListOption &&
                getEmployeeListOption?.find(
                  (obj) => obj.label === prevData?.empId
                )
              }
            /> */}
            <p
              className={
                validate && !formData?.employeId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Employee Name
            </p>
          </div>
          <div className={style.cu_col2}>
            {/* <p className={style.cu_label}>Employee Name</p>
            <input
              className={style.input}
              readOnly
              value={
                getEmployeeListOption &&
                getEmployeeListOption?.find(
                  (obj) => obj.label === formData?.employeId
                )?.value
                  ? getEmployeeListOption &&
                    getEmployeeListOption?.find(
                      (obj) => obj.label === formData?.employeId
                    )?.value
                  : ""
              }
            /> */}
          </div>
        </div>
      </div>
      {/* second */}
      <div className={style.card}>
        <p className={style.title}>Reviewer</p>
        <div className={style.Cu_row2}>
          <div className={style.cu_col1}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Review Date <span className={style.mand}>*</span>
            </p>
            <div className={style.cu_field23Last}>
              <DatePicker
                placeholder=" DD MM YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  formData?.reviewDate
                    ? moment(formData?.reviewDate).toDate()
                    : null
                }
                maxDate={moment().toDate()}
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    reviewDate: moment(date).toISOString(),
                  });
                }}
                dateFormat="dd MMM yy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={
                  formData?.reviewDate ? (
                    <CustomInputCross properties="reviewDate" />
                  ) : (
                    <CustomInputClg />
                  )
                }
              />
            </div>
            <p
              className={
                validate && !formData?.reviewDate
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Reviewed Date
            </p>
          </div>
          <div className={style.cu_col1}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Review Source <span className={style.mand}>*</span>
            </p>
            <Select
              styles={customStylesERP}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              options={getReviewSourcTypeOption}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  reviewSourceTypeId: e?.value,
                  reviewTypeId: "",
                  reviewedById: "",
                  clientId: "",
                  reviewedByName: "",
                  onlinePlatformId: "",
                  projectId: "",
                });
                // setFormDataSubjective({
                //   ...formDataSubjective,
                //   statusId: "",
                // });
                // setFormDataRating({
                //   ...formDataRating,
                //   statusId: "",
                // });
                // setFormDataFile({
                //   ...formDataFile,
                //   statusId: "",
                // });
              }}
            />
            <p
              className={
                validate && !formData?.reviewSourceTypeId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Review Source
            </p>
          </div>
          <div className={style.cu_col1}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Review Type <span className={style.mand}>*</span>
            </p>
            <Select
              styles={customStylesERP}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              options={getReviewTypesOption}
              onChange={(e) =>
                setFormData({ ...formData, reviewTypeId: e?.value })
              }
              value={
                getReviewTypesOption?.length === 1
                  ? getReviewTypesOption[0]
                  : formData?.reviewTypeId
                  ? getReviewTypesOption.find(
                      (e) => e.value == formData?.reviewTypeId
                    )
                  : null
              }
              // value={
              //   formData?.reviewTypeId
              //     ? getReviewTypesOption.find(
              //         (e) => e.value == formData?.reviewTypeId
              //       )
              //     : null
              // }
            />

            <p
              className={
                validate && !formData?.reviewTypeId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Review Type
            </p>
          </div>
          <div className={style.cu_col1}>
            {formData?.reviewSourceTypeId == 2 ? (
              <>
                <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
                  Client Name <span className={style.mand}>*</span>
                </p>
                <Select
                  styles={customStylesERP}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={getClientListOption}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      clientId: e?.value,
                    })
                  }
                />
                <p
                  className={
                    validate && !formData?.clientId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Client Name
                </p>
              </>
            ) : null}
            {formData?.reviewSourceTypeId == 4 ? (
              <>
                <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
                  Online Platform <span className={style.mand}>*</span>
                </p>
                <Select
                  styles={customStylesERP}
                  classNamePrefix={"create_feedback"}
                  menuPlacement="bottom"
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      onlinePlatformId: e?.value,
                    })
                  }
                  options={onlineOption}
                  value={
                    formData?.onlinePlatformId
                      ? onlineOption.find(
                          (e) => e.value == formData?.onlinePlatformId
                        )
                      : null
                  }
                />
                <p
                  className={
                    validate && !formData?.onlinePlatformId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Online Platform
                </p>
              </>
            ) : null}
            {formData?.reviewSourceTypeId == 2 ||
            formData?.reviewSourceTypeId == 4 ? null : (
              <>
                <>
                  <p
                    className={style.cu_label}
                    style={{ paddingBottom: "5px" }}
                  >
                    Reviewed By <span className={style.mand}>*</span>
                  </p>
                  <Select
                    styles={customStylesERP}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={mainOption()}
                    onChange={(e) =>
                      setFormData({ ...formData, reviewedById: e?.value })
                    }
                    value={
                      formData?.reviewedById
                        ? mainOption().find(
                            (e) => e.value == formData?.reviewedById
                          )
                        : null
                    }
                  />
                  <p
                    className={
                      validate && !formData?.reviewedById
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Reviewed By
                  </p>
                </>
              </>
            )}
          </div>
        </div>
        <div className={style.Cu_row2}>
          <div className={style.cu_col1} style={{ marginTop: "-1%" }}>
            <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
              Project Name
            </p>
            <Select
              styles={customStylesERP2}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput2(),
                IndicatorSeparator: () => null,
                // CrossIcon: () => CustomCross(),
              }}
              options={getAllActiveProjectListOption}
              onChange={(e) =>
                setFormData({ ...formData, projectId: e?.value })
              }
              isClearable={true}
              value={
                formData?.projectId
                  ? getAllActiveProjectListOption.find(
                      (e) => e.value == formData?.projectId
                    )
                  : null
              }
            />
            {/* <p
              className={
                validate && !formData?.projectId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Project Name
            </p> */}
          </div>
          <div className={style.cu_col1} style={{ marginTop: "-1%" }}>
            {formData?.reviewSourceTypeId == 2 ? (
              <>
                <p className={style.cu_label} style={{ paddingBottom: "5px" }}>
                  Reviewed By
                </p>
                <input
                  type="text"
                  className={style.input}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      reviewedByName: e?.target?.value,
                    })
                  }
                />
                {/* <Select
                  styles={customStylesERP}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={mainOption()}
                  onChange={(e) =>
                    setFormData({ ...formData, reviewedById: e?.value })
                  }
                  value={
                    formData?.reviewedById
                      ? mainOption().find(
                          (e) => e.value == formData?.reviewedById
                        )
                      : null
                  }
                /> */}
                {/* <p
                  className={
                    validate && !formData?.reviewedByName?.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Reviewed By
                </p> */}
              </>
            ) : null}
          </div>
          <div className={style.cu_col1}></div>
          <div className={style.cu_col1}></div>
        </div>
      </div>
      {/* third */}
      <div className={style.card}>
        <p className={style.title}>Feedback</p>
        <div className={style.Cu_row3}>
          <div className={style.cu_col1}>
            <p className={style.cu_label}>
              Feedback Type <span className={style.mand}>*</span>
            </p>
            <Select
              styles={customStyles}
              classNamePrefix={"create_feedback"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                setFormData({ ...formData, feedbackTypeId: e?.value });
              }}
              menuPlacement="top"
              options={[
                { value: 1, label: "Rating " },
                { value: 2, label: "Test" },
                { value: 3, label: "File Upload" },
                { value: 4, label: "Subjective" },
              ]}
            ></Select>
            <p
              className={
                validate && !formData?.feedbackTypeId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Feedback Type
            </p>
          </div>
        </div>

        {/* dynamic */}
        {/* file upload */}
        {formData?.feedbackTypeId === 3 && (
          <div className={style.Cu_feedbackType}>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Upload Feedback</p>
              <div className={style.Cu_row1}>
                {doc ? (
                  <div className={style.Cu_resume}>
                    <p className={style.rmpdf}>{doc.split("@")[1]}</p>
                    <p
                      onClick={() => {
                        setFormDataFile({ ...formDataFile, file: "" });
                        setDoc(null);
                        deleteResume(formDataFile?.file);
                      }}
                      className={style.rmpdfcross}
                    >
                      x
                    </p>
                  </div>
                ) : (
                  <input
                    placeholder={`${
                      formDataFile?.file
                        ? formDataFile?.file
                        : "no file selected"
                    }`}
                    type="file"
                    className={style.upload_resume}
                    onChange={(e) => {
                      uploadDocMutation.mutate(e.target.files[0]);
                    }}
                  />
                )}
              </div>
            </div>

            <div className={style.radio} style={{ marginTop: "-14px" }}>
              <div>Overall Rating :</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating1"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataFile({
                      ...formDataFile,
                      statusIdRating: 1,
                      overallRatingValue: "",
                    })
                  }
                />
                <label for="rating1">1 to 3</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating2"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataFile({
                      ...formDataFile,
                      statusIdRating: 2,
                      overallRatingValue: "",
                    })
                  }
                />
                <label for="rating2">1 to 5</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating3"
                  name="radio-group1"
                  onChange={() => {
                    setFormDataFile({
                      ...formDataFile,
                      statusIdRating: 3,
                      overallRatingValue: "",
                    });
                  }}
                />
                <label for="rating3">1 to 10</label>
              </div>
            </div>
            <div>
              {formDataFile?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={formDataFile?.overallRatingValue}
                      setFormDataSubjective={setFormDataFile}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataFile?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={formDataFile?.overallRatingValue}
                      setFormDataSubjective={setFormDataFile}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataFile?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={formDataFile?.overallRatingValue}
                      setFormDataSubjective={setFormDataFile}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className={style.radio}>
              <div style={{ display: "block" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    Status <span className={style.mand}>*</span> :
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test2"
                      name="radio-group"
                      onChange={() =>
                        setFormDataFile({ ...formDataFile, statusId: 1 })
                      }
                    />
                    <label for="test2">Shortlisted</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test3"
                      name="radio-group"
                      onChange={() =>
                        setFormDataFile({ ...formDataFile, statusId: 2 })
                      }
                    />
                    <label for="test3">Rejected</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test4"
                      name="radio-group"
                      onChange={() =>
                        setFormDataFile({ ...formDataFile, statusId: 3 })
                      }
                    />
                    <label for="test4">Hold</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test5"
                      name="radio-group"
                      onChange={() =>
                        setFormDataFile({ ...formDataFile, statusId: 4 })
                      }
                    />
                    <label for="test5">Not Applicable</label>
                  </div>
                </div>
                <p
                  className={
                    validate && !formDataFile?.statusId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Status
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Rating */}
        {formData?.feedbackTypeId === 1 && (
          <div className={style.Cu_feedbackType}>
            <div className={style.Cu_col1}>
              <p className={style.cu_label}>Skill</p>
              <div style={{ marginLeft: "-0.7rem" }}>
                <CreatableSelect
                  styles={customStyles2}
                  classNamePrefix={"create_feedback"}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  value={formDataRating?.feedbackSkills}
                  options={getSkillListOption}
                  formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                  onChange={(e) => handleSkill(e)}
                  menuPlacement="top"
                />
              </div>
            </div>

            {formDataRating?.feedbackSkills?.map((item, index) => {
              return (
                <div className={style.feedback}>
                  <p key={index} className={style.cu_label}>
                    {item?.label}
                  </p>
                  <StarRating
                    feedbackRating={formDataRating?.feedbackSkills}
                    setFeedbackRating={setFormDataRating}
                    item={item}
                    // setFormData={setFormData}
                    // formData={formData}
                  />
                </div>
              );
            })}
            <div className={style.Cu_col2}>
              <p className={style.cu_label}>
                Overall Feedback <span className={style.mand}>*</span>
              </p>
              <textarea
                className={style.textarea}
                onChange={(e) =>
                  setFormDataRating({
                    ...formDataRating,
                    overallFeedback: e?.target?.value,
                  })
                }
              />
              <p
                className={
                  validate && !formDataRating?.overallFeedback?.trim()
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Overall Feedback
              </p>
            </div>

            <div className={style.radio} style={{ marginTop: "-14px" }}>
              <div>Overall Rating :</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating1"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataRating({
                      ...formDataRating,
                      statusIdRating: 1,
                      overallRatingValue: "",
                    })
                  }
                />
                <label for="rating1">1 to 3</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating2"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataRating({
                      ...formDataRating,
                      statusIdRating: 2,
                      overallRatingValue: "",
                    })
                  }
                />
                <label for="rating2">1 to 5</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating3"
                  name="radio-group1"
                  onChange={() => {
                    setFormDataRating({
                      ...formDataRating,
                      statusIdRating: 3,
                      overallRatingValue: "",
                    });
                  }}
                />
                <label for="rating3">1 to 10</label>
              </div>
            </div>
            <div>
              {formDataRating?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={formDataRating?.overallRatingValue}
                      setFormDataSubjective={setFormDataRating}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataRating?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={formDataRating?.overallRatingValue}
                      setFormDataSubjective={setFormDataRating}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataRating?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={formDataRating?.overallRatingValue}
                      setFormDataSubjective={setFormDataRating}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className={style.radio}>
              <div style={{ display: "block" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    Status <span className={style.mand}>*</span> :
                  </div>

                  <div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id="test2"
                        name="radio-group"
                        onChange={() =>
                          setFormDataRating({
                            ...formDataRating,
                            statusId: 1,
                          })
                        }
                      />
                      <label for="test2">Shortlisted</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id="test3"
                        name="radio-group"
                        onChange={() =>
                          setFormDataRating({
                            ...formDataRating,
                            statusId: 2,
                          })
                        }
                      />
                      <label for="test3">Rejected</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id="test4"
                        name="radio-group"
                        onChange={() =>
                          setFormDataRating({
                            ...formDataRating,
                            statusId: 3,
                          })
                        }
                      />
                      <label for="test4">Hold</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id="test5"
                        name="radio-group"
                        onChange={() =>
                          setFormDataRating({
                            ...formDataRating,
                            statusId: 4,
                          })
                        }
                      />
                      <label for="test5">Not Applicable</label>
                    </div>
                  </div>
                </div>
                <p
                  className={
                    validate && !formDataRating?.statusId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Status
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Test */}
        {formData?.feedbackTypeId === 2 && (
          <div className={style.Cu_feedbackType}>
            <p className={style.cu_label}>Add Skill</p>
            <div className={style.Cu_testCol1}>
              <CreatableSelect
                styles={customStyles2}
                classNamePrefix={"create_feedback"}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isMulti
                value={formDataTest?.testSkills}
                options={getSkillListOption}
                onChange={(e) => handleSelect1(e)}
                formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                // onChange={(e) => {
                //   setFormData({ ...formData, addSkill: e?.value });
                // }}
                menuPlacement="top"
              />
              {/* <button className={style.btn2}>+ Add Section</button> */}
            </div>
            <div className={style.h}>
              <label className={style.h_name}>Skills</label>
              <div className={style.h1}>
                <label className={style.h_name}>Score Obtained</label>
                <label className={style.h_name} style={{ marginRight: "2%" }}>
                  Maximum Score
                </label>
              </div>
            </div>

            <div className={style.Cu_testCol2}>
              {formDataTest?.testSkills?.map((item, index) => {
                return (
                  <>
                    <div key={index} className={style.h}>
                      <label>{item.label}</label>
                      <div className={style.h1}>
                        <input
                          name="scoreObtained"
                          className={style.cp_field}
                          min={0}
                          max={100}
                          type="number"
                          onChange={(e) => {
                            let temp = [...formDataTest?.testSkills];
                            temp[index].scoreObtained = e?.target?.value;
                            setFormDataTest({
                              ...formDataTest,
                              testSkills: temp,
                            });
                          }}
                          value={formDataTest?.testSkills[index]?.scoreObtained}
                        />
                        <input
                          name="maxScore"
                          className={style.cp_field}
                          min={0}
                          max={100}
                          type="number"
                          onChange={(e) => {
                            let temp = [...formDataTest?.testSkills];
                            temp[index].maxScore = e?.target?.value;
                            setFormDataTest({
                              ...formDataTest,
                              testSkills: temp,
                            });
                          }}
                          value={formDataTest?.testSkills[index]?.maxScore}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className={style.h}>
              <label>Overall Score</label>
              <div className={style.h1}>
                <input
                  className={style.cp_field}
                  readOnly
                  min={0}
                  max={100}
                  type="number"
                  value={totalOverAllScore}
                />
                <input
                  className={style.cp_field}
                  readOnly
                  min={0}
                  max={100}
                  type="number"
                  value={totalMaxAllScore}
                />
              </div>
            </div>
            <div className={style.radio} style={{ marginTop: "-14px" }}>
              <div>Overall Rating :</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating1"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataTest({
                      ...formDataTest,
                      statusIdRating: 1,
                      overallRatingValue: "",
                    })
                  }
                />
                <label for="rating1">1 to 3</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating2"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataTest({
                      ...formDataTest,
                      statusIdRating: 2,
                      overallRatingValue: "",
                    })
                  }
                />
                <label for="rating2">1 to 5</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating3"
                  name="radio-group1"
                  onChange={() => {
                    setFormDataTest({
                      ...formDataTest,
                      statusIdRating: 3,
                      overallRatingValue: "",
                    });
                  }}
                />
                <label for="rating3">1 to 10</label>
              </div>
            </div>

            <div>
              {formDataTest?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={formDataTest?.overallRatingValue}
                      setFormDataSubjective={setFormDataTest}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataTest?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={formDataTest?.overallRatingValue}
                      setFormDataSubjective={setFormDataTest}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataTest?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={formDataTest?.overallRatingValue}
                      setFormDataSubjective={setFormDataTest}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div>
              {formDataSubjective?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataSubjective?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataSubjective?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        )}

        {/* Subjective */}
        {formData?.feedbackTypeId === 4 && (
          <div className={style.Cu_feedbackType}>
            <div className={style.Cu_col1} style={{ marginTop: "-14px" }}>
              <p className={style.cu_label} style={{ paddingBottom: "0px" }}>
                Subjective Feedback <span className={style.mand}>*</span>
              </p>
              <textarea
                className={style.textarea}
                onChange={(e) =>
                  setFormDataSubjective({
                    ...formDataSubjective,
                    subjective: e?.target?.value,
                  })
                }
              />
              <p
                className={
                  validate && !formDataSubjective?.subjective?.trim()
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Subjective Feedback
              </p>
            </div>

            <div className={style.radio} style={{ marginTop: "-14px" }}>
              <div>Overall Rating :</div>

              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating1"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusIdRating: 1,
                      overallRatingValue: "",
                    })
                  }
                />
                <label for="rating1">1 to 3</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating2"
                  name="radio-group1"
                  onChange={() =>
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusIdRating: 2,
                      overallRatingValue: "",
                    })
                  }
                />
                <label for="rating2">1 to 5</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="rating3"
                  name="radio-group1"
                  onChange={() => {
                    setFormDataSubjective({
                      ...formDataSubjective,
                      statusIdRating: 3,
                      overallRatingValue: "",
                    });
                  }}
                />
                <label for="rating3">1 to 10</label>
              </div>
            </div>

            <div>
              {formDataSubjective?.statusIdRating == 1 ? (
                <>
                  <div>
                    <OverallRating
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataSubjective?.statusIdRating == 2 ? (
                <>
                  <div>
                    <OverallRating2
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
              {formDataSubjective?.statusIdRating == 3 ? (
                <>
                  <div>
                    <OverallRating3
                      formDataSubjective={
                        formDataSubjective?.overallRatingValue
                      }
                      setFormDataSubjective={setFormDataSubjective}
                      // item={item}
                    />
                  </div>
                </>
              ) : null}
            </div>

            <div className={style.radio}>
              <div style={{ display: "block" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    Status <span className={style.mand}>*</span> :
                  </div>

                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test2"
                      name="radio-group"
                      onChange={() =>
                        setFormDataSubjective({
                          ...formDataSubjective,
                          statusId: 5,
                        })
                      }
                    />
                    <label for="test2">PIP</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test3"
                      name="radio-group"
                      onChange={() =>
                        setFormDataSubjective({
                          ...formDataSubjective,
                          statusId: 6,
                        })
                      }
                    />
                    <label for="test3">Reward</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test4"
                      name="radio-group"
                      onChange={() =>
                        setFormDataSubjective({
                          ...formDataSubjective,
                          statusId: 7,
                        })
                      }
                    />
                    <label for="test4">Appreciation</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test5"
                      name="radio-group"
                      onChange={() =>
                        setFormDataSubjective({
                          ...formDataSubjective,
                          statusId: 8,
                        })
                      }
                    />
                    <label for="test5">Feedback</label>
                  </div>
                  <div className={style.radioitem}>
                    <input
                      type="radio"
                      id="test22"
                      name="radio-group"
                      onChange={() =>
                        setFormDataSubjective({
                          ...formDataSubjective,
                          statusId: 4,
                        })
                      }
                    />
                    <label for="test22">Not Applicable</label>
                  </div>
                </div>
                <p
                  className={
                    validate && !formDataSubjective?.statusId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Status
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* button */}
      <div className={style.button}>
        {prevData?.empId != undefined ? (
          <button
            className={style.btn1}
            onClick={() => {
              setNextScreen("EmployeeFeedbackList");
              setPrevData((prevData) => ({
                ...prevData,
                sidebar: "EmployeePerformanceReview",
                page: "EmployeePerformanceReview",
                page1: "EmployeePerformanceReview",
                page2: "EmployeeFeedbackList",
                page3: null,
                active: "EmployeeFeedbackList",
              }));
            }}
          >
            Cancel
          </button>
        ) : null}

        {prevData?.empId == undefined ? (
          <button
            className={style.btn1}
            onClick={() => {
              setNextScreen("EmployeePerformanceReview");
              setPrevData((prevData) => ({
                ...prevData,
                sidebar: "EmployeePerformanceReview",
                page: "EmployeePerformanceReview",
                page1: "EmployeePerformanceReview",
                page2: null,
                page3: null,
                active: "EmployeePerformanceReview",
              }));
            }}
          >
            Cancel
          </button>
        ) : null}

        {prevData?.performanceReviewId != undefined ? (
          <button
            className={style.btn1}
            onClick={() => {
              setNextScreen("viewFeedback");
              setPrevData((prevData) => ({
                ...prevData,
                sidebar: "EmployeePerformanceReview",
                page: "EmployeePerformanceReview",
                page1: "EmployeePerformanceReview",
                page2: "EmployeeFeedbackList",
                page3: "viewFeedback",
                active: "viewFeedback",
              }));
            }}
          >
            Cancel
          </button>
        ) : null}

        <button
          className={style.btn2}
          onClick={() => {
            onCreateHandler();
          }}
        >
          Submit Feedback
        </button>
      </div>
    </>
  );
}

export default EmployeeFeedback;
