import React, { useState, useEffect, useRef } from "react";
import style from "./MyTaskDetails.module.css";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import pencilEditButtonImg from "../../../../assets/pencil-edit-button.png";
import rubbishBinImg from "../../../../assets/rubbish-bin.png";
import { useHistory } from "react-router-dom";
import Pagination from "../../commonComponent/Pagination";
import rubbishBinImg2 from "../../../../assets/rubbish-bin (2).png";
import pencil from "../../../../assets/pencil-edit-button (2).png";
import { set, size } from "lodash";
import ViewTaskComponent from "../../ViewTask/component/ViewTaskComponent";
import Moment from "moment";
import axios from "axios";
import cookie from "react-cookies";
import TableScrollbar from "react-table-scrollbar";
import constants from "../../../../Utils/constants";
import ConfirmPopup from "../../taskAssignedByMe/component/ConfirmPopup";

const currentDate = Moment().toDate();
const arr = [
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit amet, consectetur adipisci",
  },
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit amet, co consectetur adipisci",
  },
  {
    a: "20 Jul (Fri) | 11:00",
    b: "Vrinda Sharma",
    c: "Lorem ipsum dolor sit ametamet, consectetur adipisci",
  },
];

const MyTaskDetails = (props) => {
  const commentText = useRef(null);
  const [dateState, setDateState] = useState(currentDate);
  const taskId = props?.history.location.state?.task?.taskId;
  const [data, setData] = useState(null);
  const [commentHistory, setcommentHistory] = useState([]);
  const [showEdit, setshowEdit] = useState(false);
  const [editComment, seteditComment] = useState(false);
  const[myTaskDetail,setMyTaskDetailByID]=useState()
  const [pageCount, setpageCount] = useState(0);
  const [newData, setnewData] = useState(props?.history.location.state.task);
  console.log({ newData });
  const [isdeleted, setIsdeleted] = useState(false);
  const [commentToEdit, setcommentToEdit] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showRes, setshowRes] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmCommentModal, setConfirmCommentModal] = useState(false);
  const [taskCommentId, setTaskCommentId] = useState();
  const [undoo, setundoo] = useState("");
  const [returnPage, setreturnPage] = useState(
    props?.history.location.state.activePage
  );
  const profile = cookie.load("profile");

  const limit = 3;

  const hideAlert = () => {
    setTimeout(() => {
      setshowRes(false);
    }, 3000);
  };

  const handleCommentEdit1 = (comment) => {
    setcommentToEdit(comment);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const EditComment = () => {
    history.push("./taskTracker/Models/EditComment");
  };

  const url2 = constants.apiBaseUrl2;

  const deleteTaskComment = () => {
    axios
      .delete(url2 + `/deleteTaskComment?taskCommentId=${taskCommentId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setTaskCommentId(undefined);
        handleConfirmCommentPopupClose();
        pageCount > 0 && currentPage > 1 && commentHistory.length === 1
          ? setCurrentPage(currentPage - 1)
          : setCurrentPage(currentPage);
        handleapidata();
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const handleTaskEdit = (comment) => {
    history.push({
      pathname: "/EditComments",
      state: {
        comment: comment,
        newData: newData,
        activePage: 2,
      },
    });
  };

  const directEdit2 = (data2) => {
    // if (returnPage == 1) {
    //   history.push("/viewTask", data2);
    // } else {
    history.push({
      pathname: "/EditTask",
      state: {
        task: data2,
        activePage: 3,
      },
    });
    // }
  };

  const deleteData = () => {
    // setIsdeleted(true);
    setreturnPage(1);
    // const d1 = setInterval(() => {
    axios
      .delete(url2 + `/deleteTask?taskId=${taskId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        // handleConfirmPopupClose()
        history.push("/taskTracker/TaskAssignedByMeComponent");
      })
      .catch((err) => {
        console.log(err);
        return err;
      });

    // }, 10000);
    // setundoo(d1);
  };

  const postData = (e) => {
    setData(e.target.value);
  };

  var dateTime1 = Moment(dateState).format("YYYY-MM-DD hh:mm");

  const Data = {
    localDateTime: dateTime1,
    taskId: taskId,
    comment: data,
  };

  const AddComment = () => {
    axios
      .post(url2 + "/addTaskComment", Data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
        } else {
          setShowErr(false);
          commentText.current.value = "";
          setData("");
          setshowRes(true);
          hideAlert();
        }
        handleapidata();
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const redirectHistory = () => {
    history.push({
      pathname: "/taskHistory",
      state: {
        task: newData,
        activePage: returnPage,
      },
    });
  };

  const ClosedTask = (closed) => {
    const data = {
      taskId: newData.taskId,
      title: newData.title,
      description: newData.description,
      planedEndDate: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: newData.assignedTo,
      priority: newData.priority,
      status: closed,
      localDateTime: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
    };
    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          console.log(res.data.responseStatus, "res.data.responseStatus");
          setShowErr(true);
          history.push({
            pathname: "/taskTracker/ClosedByMeComponent",
          });
        } else {
          history.push({
            pathname: "/taskTracker/ClosedByMeComponent",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const MarkDone = (done) => {
    const data = {
      taskId: newData.taskId,
      title: newData.title,
      description: newData.description,
      planedEndDate: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: newData.assignedTo,
      priority: newData.priority,
      status: "Done",
      localDateTime: Moment(newData.planedEndDate).format("YYYY-MM-DD hh:mm"),
    };
    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          console.log(res.data.responseStatus, "res.data.responseStatus");
          setShowErr(true);
          history.push({
            pathname: "/taskTracker",
          });
        } else {
          history.push({
            pathname: "/taskTracker",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const getSingleDetailsById = () => {
    console.log("hi")
    axios
      .get(url2 + `/getTaskDetailsByTaskId?taskId=${243}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setMyTaskDetailByID(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleapidata = () => {
    console.log("hi")
    const commentList = axios
      .get(
        url2 +
          "/getTaskComment?limit=" +
          limit +
          "&page=" +
          currentPage +
          "&taskId=" +
          taskId,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        }
      )
      .then((res) => {
        const responseData = res.data.responseData;
        setcommentHistory(responseData.comments);
        setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
      })
      .catch((err) => {
        return err;
      });
  };

 
  const history = useHistory();
  useEffect(() => {
    handleapidata();
    getSingleDetailsById()
  }, []);

  function handleConfirmPopupClose() {
    setConfirmModal(false);
  }
  function handleConfirmPopupOpen() {
    setConfirmModal(true);
  }
  function handleConfirmCommentPopupClose() {
    setConfirmCommentModal(false);
  }
  function handleConfirmCommentPopupOpen(commentId) {
    setTaskCommentId(commentId);
    setConfirmCommentModal(true);
  }
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <div className={style.rightSection}>
          <div className={style.taskAssigned}>
            <div style={{ padding: "16px" }}>
              <div className={style.container}>
                <div className={style.subcontainer}>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Task Title</h5>
                    <p style={{ marginRight: "25px" }}>
                      {isdeleted ? "" : newData?.title}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Creation Date</h5>
                    <p>{isdeleted ? "" : newData?.creationDate}</p>
                    {/* <p>{Moment(newData.planedEndDate).format("DD MMM (ddd)")}</p> */}
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "50%" }}
                  >
                    <h5>Task Description</h5>
                    <p>{isdeleted ? "" : newData?.description}</p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Planned End Date</h5>
                    <p>
                      {isdeleted
                        ? ""
                        : Moment(newData?.planedEndDate).format("DD MMM (ddd)")}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>{returnPage == 1 ? "Assigned By" : "Assigned To"}</h5>
                    <p>
                      {isdeleted
                        ? ""
                        : newData.name
                        ? newData.name
                        : newData?.assignedToName}
                    </p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Priority</h5>
                    <p>{isdeleted ? "" : newData?.priority}</p>
                  </div>
                  <div
                    className={style.subcontainerinner}
                    style={{ width: "25%" }}
                  >
                    <h5>Status</h5>
                    <p
                      className={
                        newData?.status == "Assigned"
                          ? style.asigned_color
                          : newData?.status == "Done"
                          ? style.done_color
                          : newData?.status == "Onhold"
                          ? style.onhold_color
                          : style.Closed_color
                      }
                    >
                      {newData?.status === "Closed"
                        ? "Closed"
                        : newData?.status === "Onhold"
                        ? "On Hold"
                        : newData?.status}
                      {/* {newData.status==="Inprogress"?"In Progress":newData.status} */}
                    </p>
                  </div>
                </div>

                <div className={style.rr} style={{ textAlign: "right" }}>
                  {newData.status == "Done" ? null : (
                    <button
                      onClick={() => MarkDone("Done")}
                      className={style.TaskDone}
                    >
                      Mark Task as Done
                    </button>
                  )}

                  {localStorage.getItem("employeeId") == newData.assignedBy ? (
                    <button
                      onClick={() => ClosedTask("Closed")}
                      className={style.closed}
                    >
                      Close Task
                    </button>
                  ) : null}

                  <button
                    onClick={() => redirectHistory()}
                    className={style.taskHistory}
                  >
                    View Task History
                  </button>
                  {returnPage != 1 && (
                    <button onClick={deleteData} className={style.delete}>
                      Delete
                    </button>
                  )}
                  {isdeleted && (
                    <button
                      className={style.edit}
                      onClick={() => {
                        setIsdeleted(false);
                        setreturnPage(props.history.location.state.activePage);
                        clearInterval(undoo);
                      }}
                    >
                      Undo
                    </button>
                  )}
                  <button
                    onClick={() => directEdit2(newData)}
                    className={style.edit}
                  >
                    Edit
                  </button>
                </div>
                <br />
              </div>
              <div
                style={{ marginTop: "10px", marginBottom: "10px" }}
                id="add_commentid"
              >
                <h5 className={style.comment}>Add Comment</h5> <br />
                <div className={style["text-area-container"]}>
                  <textarea
                    onChange={postData}
                    name="textArea"
                    rows={3}
                    ref={commentText}
                    // className={style.scrollAdd}
                  />
                </div>
                {showErr && (
                  <div className={style.req_msg}>
                    <p>* Task comment can not be empty.</p>
                  </div>
                )}
                <button onClick={AddComment} className={style.addComment}>
                  Add Comment
                </button>
              </div>

              <div className={style.tabl}>
                <span
                  style={{ fontWeight: "bold", fontSize: "18px" }}
                  className="comment2"
                >
                  Comment History
                </span>
                {commentHistory?.length > 0 ? (
                  <div>
                    {/* <TableScrollbar rows={2}> */}
                    <table className={style.table}>
                      <thead>
                        <tr>
                          <th className={style.taskid} style={{ width: "19%" }}>
                            Reported Date
                          </th>
                          <th
                            className={style.taskTitle}
                            style={{ width: "25%" }}
                          >
                            Username
                          </th>
                          <th
                            className={style.taskdesc}
                            style={{ width: "51%" }}
                          >
                            Comment
                          </th>
                          <th
                            className={style.taskAssigne}
                            style={{ width: "10%" }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      {/* <form className={style.formtable}> */}
                      <tbody>
                        {commentHistory &&
                          commentHistory.map((comment1, index1) => {
                            return (
                              <tr className={style.tabledata} key={index1}>
                                {editComment && (
                                  <EditComment
                                    taskComment={commentToEdit}
                                    newData={newData}
                                  />
                                )}
                                <td className={style.tabletaskId}>
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      width: "15%",
                                      padding: "10px 10px 10px 8px",
                                    }}
                                  >
                                    {Moment(comment1.taskModifiedDate).format(
                                      "DD MMM (ddd)"
                                    )}
                                  </span>
                                </td>
                                <td
                                  className={style.tabletaskTitle}
                                  style={{ width: "25%" }}
                                >
                                  <span
                                    style={{
                                      color: "#65A15E",
                                      fontStyle: "italic",
                                      textDecorationLine: "underline",
                                      fontSize: "16px",
                                      width: "25%",
                                      padding: "10px 10px 10px 8px",
                                    }}
                                  >
                                    {comment1.name}
                                  </span>
                                </td>
                                <td>
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      width: "65%",
                                      padding: "10px 10px 10px 0px",
                                    }}
                                  >
                                    {comment1.taskComment}
                                  </span>
                                </td>
                                {/* <td style={{ }} >

                        <img style={{ cursor: "pointer" }}
                          onClick={() => handleTaskEdit(comment1)}

                          src={pencilEditButtonImg}
                        />
                        <img onClick={ () => deleteTaskComment(comment1.taskCommentId) } style={{ paddingLeft: "10px", cursor:"pointer" }} src={rubbishBinImg}/>
                      </td> */}

                                <td
                                  style={{
                                    width: "10%",
                                    padding: "10px 10px 10px 13px",
                                  }}
                                >
                                  {comment1.name === profile.name ? (
                                    <div>
                                      <img
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleTaskEdit(comment1)}
                                        src={pencilEditButtonImg}
                                      />
                                      <img
                                        onClick={() =>
                                          handleConfirmCommentPopupOpen(
                                            comment1.taskCommentId
                                          )
                                        }
                                        style={{
                                          paddingLeft: "11px",
                                          cursor: "pointer",
                                        }}
                                        src={rubbishBinImg}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <img
                                        style={{ cursor: "pointer" }}
                                        // onClick={() => handleTaskEdit(comment1)}
                                        src={pencil}
                                      />
                                      <img
                                        // onClick={ () => deleteTaskComment(comment1.taskCommentId) }
                                        style={{
                                          paddingLeft: "11px",
                                          cursor: "pointer",
                                        }}
                                        src={rubbishBinImg2}
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                      {/* </form> */}
                    </table>
                    {/* </TableScrollbar> */}

                    {commentHistory.length > 0 && pageCount > 0 && (
                      <div
                        className={style.paginationmobileContainer}
                        style={{ textAlign: "right" }}
                      >
                        <div className={style.Pagin}>
                          <Pagination
                            pagesCount={pageCount}
                            pageSize={limit}
                            onPageChange={handlePageChange}
                            currentPage={currentPage}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={style.table}>No Data Available</div>
                )}
              </div>
              <div className={style.mobileContainer}>
                {arr.map((ar, indexar) => {
                  return (
                    <div
                      key={indexar}
                      style={{
                        display: "block",
                        position: "relative",
                        borderBottom: "1px solid #707070",
                        marginBottom: "10px",
                        paddingBottom: "10px",
                      }}
                    >
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Reported Date :
                        </span>
                        {ar.a}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Username :
                        </span>
                        {ar.b}
                      </p>
                      <p>
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            paddingTop: "3px",
                          }}
                        >
                          Comment :
                        </span>
                        {ar.c}
                      </p>
                      <div style={{ position: "absolute", top: 0, right: 0 }}>
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={handleCommentEdit1}
                          src={pencilEditButtonImg}
                        />
                        <img
                          style={{ paddingLeft: "10px" }}
                          src={rubbishBinImg}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={deleteData}>
              Yes
            </button>
            <button className={style.noBtn} onClick={handleConfirmPopupClose}>
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
      <ConfirmPopup open={confirmCommentModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={deleteTaskComment}>
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={handleConfirmCommentPopupClose}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </div>
  );
};

export default MyTaskDetails;
