import axios from "axios";
import React, { useState, useEffect } from "react";
import Style from "./ApplicationInformation.module.css";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import constants from "../../../../../Utils/constants";
import { Row } from "react-bootstrap";


export default function ApplicationInformation(props) {
  const [skillList, setSkillList] = useState([]);
  const [technologyListEnum, setTechnologyListEnum] = useState("");
  const [application, setApplication] = useState("");
  const [typeStatus, setTypeStatus] = useState("");
  const[disable,setDisable]=useState(false)
  const[error,setError]=useState(false)


  useEffect(() => {
    setTechnologyListEnum(props?.applicationDetail?.skillId);
    setApplication(props?.applicationDetail?.application);
    setTypeStatus(props?.applicationDetail?.typeStatus)
  }, [props?.applicationDetail])

  

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/ProjectDetails");
    }, 2000);
  };



  const getSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      }
      )
      .then((res) => {
        console.log(res.data.responseData)
        setSkillList(res.data.responseData)

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if(!technologyListEnum || !application?.trim().length || !typeStatus){
      setError(true)
      return false;
     }
    if (props.applicationDetail?.typeStatus) {
      const data = {
        skillId: technologyListEnum,
        application: application.trim(),
        typeStatus: typeStatus,
        projectId: props.id,
        applicationid: props.applicationDetail.applicationId
      };
      // data.applicationid = props.applicationDetail.applicationId
      axios
        .put(baseUrl + "/editAllApplication", data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          console.log("res", res);
          notify();
          handleRedirect();
          setDisable(true)
        })
        .catch((err) => {
          console.log(err);
        });

      // "application": "string",
      // "projectId": 0,
      // "skillId": 0,
      // "typeStatus": "Web"

    } else {
      const data = {
        skillId: technologyListEnum,
        application: application.trim(),
        typeStatus: typeStatus,
        projectId: props.id,
        // applicationid:props.applicationDetail.applicationId
      };
      axios
        .post(baseUrl + "/addApplications", data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        })
        .then((res) => {
          console.log("res", res);
          notify();
          handleRedirect();
          setDisable(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }




  useEffect(() => {
    getSkills()

  }, []);



  const type = [
    { name: "Web", id: "Web" },
    { name: "Mobile", id: "Mobile" },
    { name: "Frontend", id: "Frontend" },
    { name: "Backend", id: "Backend" },
    { name: "CMS", id: "CMS" }
  ]

  const notify = () =>
    toast("Data Submitted Successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  return (
    <>
      <div className={Style.projectresource_mainsection}>
        <div className={Style.maindiv}>
        <p className={Style.span2}>Application Information</p>
          <div
            className={Style.maindivContent}
          >
          </div>
          <form>
            <Row>
              <div className={Style.proSecton} style={{ padding: "6px", width: "33%", maxWidth: "100%" }}>
                <label>Application<span style={{ color: "red" }}>*</span> </label>
                <br />
                <input
                  autoComplete="off"
                  className={Style.input}
                  type="text"
                  id="fname"
                  name="application"
                  defaultValue={props.applicationDetail.application}
                  onChange={(e) => setApplication(e.target.value)}
                  style={{
                    padding: "10px",
                    width: "90%",
                    height: "35px",
                    marginTop: "10px",
                    border: "none",
                    outline: "none",
                    backgroundColor: "#F5F6FA",
                  }}
                />
                  {error && !application?.trim().length ? <p className={Style.error}>Please Enter Application</p>:null}
              </div>
              <div className={Style.proSecton} style={{ padding: "6px", width: "33%", maxWidth: "100%" }}>
                <label>Technology <span style={{ color: "red" }}>*</span></label>
                <br />
                {/* setApplication */}
                <select value={technologyListEnum} onChange={(e) => setTechnologyListEnum(e.target.value)} name="technology" className={Style.input}>
                  <option hidden>Select Technology</option>
                  {skillList.map((val) => {
                    return <option value={val.id}>{val?.name}</option>;
                  })}
                </select>
                {error && !technologyListEnum ? <p className={Style.error}>Please Select Technology</p>:null}
              </div>
              <div className={Style.proSecton} style={{ padding: "6px", width: "33%", maxWidth: "100%" }}>
                <label>Type <span style={{ color: "red" }}>*</span> </label>
                <br />
                <select value={typeStatus} onChange={(e) => setTypeStatus(e.target.value)} name="type" className={Style.input}>
                  <option hidden>Select Type</option>
                  {type.map((val) => {
                    return <option value={val.id}>{val?.name}</option>;
                  })}
                </select>
                {error && !typeStatus ? <p className={Style.error}>Please Select Type</p>:null}
              </div>
            </Row>
          </form>
          <br />
        </div>
      </div>
      <div className={Style.inputbutton}>
        <button type="submit" onClick={onSubmit} style={{ marginTop: "-8px", height: "40px", width: "100px", borderRadius: "10px" }} disabled={disable}>Submit</button>
      </div>
    </>
  );

}

