import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../EditPartner/EditPartner.module.css";
import { useMutation, useQuery } from "react-query";
import {
  getViewPartner,
  createPartner,
} from "../../../../Utils/axios/PortalManagement";
import { useState } from "react";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";
import {
  getCityList,
  getStateList,
} from "../../../../Utils/axios/PortalManagement";
import Select from "react-select";
import { InvoiceStyles4 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import axios from "axios";
import calender from "../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import cancel from "../../../../assets/cancell.png";
import moment from "moment";
const EditPartner = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const { data: PartnerDataById } = useQuery(
    ["getViewPartner1"],
    () => getViewPartner(prevData?.partnerId),
    {}
  );

  const [validate, setValidate] = React.useState(false);

  const [formData, setFormData] = useState({
    partnerName: PartnerDataById?.partnerName,
    gstin: PartnerDataById?.gstin,
    addressLine1: PartnerDataById?.addressLine1,
    addressLine2: PartnerDataById?.addressLine2,
    country: PartnerDataById?.country,
    cityId: PartnerDataById?.cityId,
    stateId: PartnerDataById?.stateId,
    pinCode: PartnerDataById?.pinCode,
    linkedinLink:PartnerDataById?.linkedinLink,
    primaryContactName: PartnerDataById?.primaryContactName,
    primaryContectEmail: PartnerDataById?.primaryContectEmail,
    primaryMobileNumber: PartnerDataById?.primaryMobileNumber,
    seconderyContactName: PartnerDataById?.seconderyContactName,
    seconderyContectEmail: PartnerDataById?.seconderyContectEmail,
    seconderyMobileNumber: PartnerDataById?.seconderyMobileNumber,
    id: prevData?.partnerId,
    empId: Number(localStorage.getItem("employeeId")),
    paymentDueDate: PartnerDataById?.paymentDueDate,
    companySize: PartnerDataById?.companySize,
  });

  const mutation = useMutation(createPartner, {});

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const HandlerEditPartner = () => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (
      formData?.partnerName.trim() !== "" &&
      formData?.gstin.trim() !== "" &&
      formData?.addressLine1.trim() !== "" &&
      formData?.addressLine2.trim() !== "" &&
      formData?.country.trim() !== "" &&
      formData?.primaryContactName.trim() !== "" &&
      formData?.pinCode.trim() !== "" &&
      formData?.primaryContectEmail !== "" &&
      formData.primaryMobileNumber.length >= 10 &&
      isValidEmail(formData?.primaryContectEmail) &&
      formData?.primaryMobileNumber !== ""
    ) {
      setValidate(false);
      mutation.mutate(formData);
      setNextScreen("ViewPartner");
    } else {
      setValidate(true);
    }
  };

  const { data: GetCityTypeData } = useQuery(["getCityList"], getCityList);
  const GetCityDataOption = GetCityTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: GetStateTypeData } = useQuery(["getStateList"], getStateList);

  const GetStateDataOption = GetStateTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          alt="cancel"
          width="10px"
          height="10px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          alt="calender"
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });
  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginLeft: "25px" }}>Edit Partner</h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label}>
                Partner Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.partnerName}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Partner Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                GSTIN <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.gstin}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gstin: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.gstin.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter GSTIN
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>Company Size</p>
              <input
                type="text"
                value={formData?.companySize}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companySize: e.target.value,
                  });
                }}
              />
              {/* <p
                className={
                  validate && !formData?.gstin.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter GSTIN
              </p>*/}
            </Col>

            <Col md={4}>
              <p className={classes.labelStatus} style={{ marginTop: "10px" }}>
                Address 1 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.addressLine1}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine1: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine1.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 1
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Address 2 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.addressLine2}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine2: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine2.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 2
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Payment Due Date</p>
              <div className={classes.cu_field23Last}>
                <DatePicker
                  placeholder=" DD MM YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={
                    formData?.paymentDueDate
                      ? moment(formData?.paymentDueDate).toDate()
                      : null
                  }
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      paymentDueDate: moment(date).toISOString(),
                    });
                  }}
                  dateFormat="dd MMM yy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  customInput={
                    formData?.paymentDueDate ? (
                      <CustomInputCross properties="paymentDueDate" />
                    ) : (
                      <CustomInputClg />
                    )
                  }
                />
              </div>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>
                City <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetCityDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={GetCityDataOption?.find(
                  (e) => e.value === formData?.cityId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cityId: e?.value,
                  });
                }}
              />
            </Col>
            <Col md={3}>
              <p className={classes.label1}>
                State <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetStateDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={GetStateDataOption?.find(
                  (e) => e.value === formData?.stateId
                )}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    stateId: e?.value,
                  });
                }}
              />
            </Col>
            <Col md={3}>
              <p className={classes.label1}>
                Country <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.country}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    country: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.country.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Country
              </p>
            </Col>

            <Col md={3}>
              <p className={classes.label1}>
                Pin Code <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.pinCode}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pinCode: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.pinCode.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Pin Code
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
         LinkedIn Url
              </p>
              <input
                type="text"
                value={formData?.linkedinLink}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    linkedinLink: e.target.value,
                  });
                }}
              />
            
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.primaryContactName}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContactName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.primaryContactName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Email ID <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                value={formData?.primaryContectEmail}
                className={classes.smallInput}
                onChange={(e) => {
                  const enteredEmail = e.target.value;
                  const isValidEmail = enteredEmail.includes("@"); // Check for "@" symbol
                  setFormData({
                    ...formData,
                    primaryContectEmail: enteredEmail,
                    isValidEmail: isValidEmail,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (!formData?.primaryContectEmail ||
                    !isValidEmail(formData?.primaryContectEmail))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {validate &&
                (!formData?.primaryContectEmail ||
                  !isValidEmail(formData?.primaryContectEmail))
                  ? "Please Enter a Valid Email"
                  : "Please Enter Name"}
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </p>
              <input
                value={formData?.primaryMobileNumber}
                type="number"
                className={classes.smallInput}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  setFormData({
                    ...formData,
                    primaryMobileNumber: inputVal,
                  });
                }}
                maxLength={10}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
              <p
                className={
                  validate &&
                  (!formData?.primaryMobileNumber ||
                    formData.primaryMobileNumber.length < 10)
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {formData?.primaryMobileNumber &&
                formData.primaryMobileNumber.length < 10
                  ? "Mobile number should have at least 10 digits"
                  : "Please Enter Mobile Number"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.SecondaryContact}>Secondary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Name</p>
              <input
                type="text"
                value={formData?.seconderyContactName}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContactName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Email ID</p>
              <input
                value={formData?.seconderyContectEmail}
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContectEmail: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Mobile Number</p>
              <input
                type="text"
                value={formData?.seconderyMobileNumber}
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyMobileNumber: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>
      <button className={classes.buttonAdd} onClick={HandlerEditPartner}>
        Update
      </button>
    </>
  );
};
export default EditPartner;
