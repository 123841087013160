import React, { useState } from "react";
import ModalView from "../components/ModalView";
import CheckBox from "../components/CheckBox";
import style from "./Filter.module.css";

const Filter = (props) => {
  const [newStatus, setNewStatus] = useState(props.filterData.status);
  const [newAllocation, setNewAllocation] = useState(
    props.filterData.bandwidths
  );

  const [newAction1, setNewAction] = useState(props.filterData.action);
  const [newAction, setNewAction1] = useState(props.filterData.action);
  const [poolAction, setPoolAction] = useState(props.filterData.statusId);
  const [experienceRange, setExperienceRange] = useState(props.filterData.experienceRange)
  const [gender, setGender] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(1);


  const handleChange = event => {

    if (event.target.checked) {
      props.filterData.experienceRange = event.target.value
      
    } else {
      props.filterData.experienceRange = null
      
    }
    setIsSubscribed(current => !current);
  };


  function onChangeValue(event) {
    setGender(event.target.value);
    setExperienceRange(event.target.value)

    console.log(props.filterData.experienceRange = event.target.value, "c");
  }

  const [status, setStatus] = useState([
    { id: 2, name: "Inactive", isChecked: false },
    { id: " ", name: "All", isChecked: false },
  ]);

  const [action, setAction] = useState([
    { id: 1, name: "Allocated", isChecked: false },
    { id: 2, name: "Allocated (Soft)", isChecked: false },
    { id: 3, name: "Inactive", isChecked: false },
    { id: 4, name: "Exited", isChecked: false },
    { id: 5, name: "Resigned", isChecked: false },
    { id: "", name: "All", isChecked: false },
  ]);

  const[SalaryRange]=useState([
{id:1,name:"Very High", isChecked:false},
{id:2,name:"High", isChecked:false},
{id:3,name:"Normal", isChecked:false},
]
  )

  const [onsitestatus, setOnsitestatus] = useState([
    { id: 2, name: "Inactive", isChecked: false },
    { id: 3, name: "Exited", isChecked: false },
    { id: 5, name: "Resigned", isChecked: false },
    { id: "", name: "All", isChecked: false },
  ]);
  const [employeeType] = useState([
    { id: 2, name: "Partner", isChecked: false },]);
  const [poolStatus, setPoolstatus] = useState([
    // { id: 1, name: "Active", isChecked: false },
    { id: 2, name: "Inactive", isChecked: false },
    { id: 3, name: "Resigned", isChecked: false },
    { id: " ", name: "All", isChecked: false },
  ]);

  const [projectStatus, setstatus] = useState([
    // { id: 1, name: "Active", isChecked: false },
    { id: 2, name: "Hold", isChecked: false },
    { id: 3, name: "Closed", isChecked: false },
    { id: "", name: "All", isChecked: false },
  ]);
  const [allocation, setAllocation] = useState([
    { id: "OneFourth", name: "25%", isChecked: false },
    { id: "Half", name: "50%", isChecked: false },
    { id: "ThreeFourth", name: "75%", isChecked: false },
    { id: "Full", name: "100%", isChecked: false },
  ]);

  const [userStatus, setUserStatus] = useState([
    { id: 2, name: "Inactive", isChecked: false },
    { id: 3, name: "Resigned", isChecked: false },
    { id: 4, name: "All", isChecked: false },
  ]);

  const [userType, setUserType] = useState([
    { id: 1, name: "employee", isChecked: false },
    { id: 2, name: "partner", isChecked: false },
  ]);

  const [experience, setExperience] = useState([
    { id: 1, name: "0-1", isChecked: false },
    { id: 2, name: "1-2", isChecked: false },
    { id: 3, name: "2-5", isChecked: false },
    { id: 4, name: "5+", isChecked: false },
  ])

  const data = { isChecked: false };
  let list = props?.clientList;
  for (let i = 0; i < list?.length; i++) {
    list[i] = Object.assign(list[i], data);
  }
  const [clientData, setClientData] = useState(list);

  let list2 = props?.clientListProject;
  for (let i = 0; i < list2?.length; i++) {
    list2[i] = Object.assign(list2[i], data);
  }
  const [projectClient, setProjectClient] = useState(list2);

  let list3 = props?.projectList;
  for (let i = 0; i < list3?.length; i++) {
    list3[i] = Object.assign(list3[i], data);
  }
  const [projectList, setProjectList] = useState(list3);

  let list4 = props?.projectCategoryList;
  for (let i = 0; i < list4?.length; i++) {
    list4[i] = Object.assign(list4[i], data);
  }
  const [projectCategory, setProjectCategory] = useState(list4);

  let list5 = props?.employeeId;
  for (let i = 0; i < list5?.length; i++) {
    list5[i] = Object.assign(list5[i], data);
  }

  const [offShoreEmp, setOffShoreEmp] = useState(list5);

  let list6 = props?.clientListing;
  for (let i = 0; i < list6?.length; i++) {
    list6[i] = Object.assign(list6[i], data);
  }
  const [onSiteClient, setOnSiteClient] = useState(list6);

  let list7 = props?.empListing;
  for (let i = 0; i < list7?.length; i++) {
    list7[i] = Object.assign(list7[i], data);
  }
  const [onSiteEmp, setOnSiteEmp] = useState(list7);

  let list8 = props?.offshoreSkills;
  for (let i = 0; i < list8?.length; i++) {
    list8[i] = Object.assign(list8[i], data);
  }
  const [empSkills, setEmpSkills] = useState(list8);

  let list9 = props?.onsiteSkills;
  for (let i = 0; i < list9?.length; i++) {
    list9[i] = Object.assign(list9[i], data);
  }
  const [onSiteEmpSkills, setOnSiteEmpSkills] = useState(list9);

  const styles = {
    radioWhite: {
      border: "10px solid #90DDD0",
    },
    radioPink: {
      border: "10px solid #EF959D",
    },
    radioRed: {
      border: "10px solid #90DDD0",
    }
  };


  return (
    <ModalView>
      {/* <div className={style.container}> */}
      <div
        className={
          props?.type === "client"
            ? style.container1
            : props?.type === "offShore"
              ? style.container2
              : props?.type === "onSite"
                ? style.container3
                : style.container
        }
      >
        <div
          className={
            props?.type === "client"
              ? style.subContainer1
              : props?.type === "offShore"
                ? style.subContainer2
                : props?.type === "onSite"
                  ? style.subContainer3
                  : style.subContainer
          }
        >
          <div className={style.mobileRes}>
            <span
              style={{
                marginLeft: "35%",
                color: "#65A15E",
                fontSize: "18px",
                fontWeight: "500",
                textDecorationLine: "underline",
              }}
            >
              Filters
            </span>
            <img style={{ width: "27px" }} onClick={props.closeFilter} />
          </div>
          {props?.type === "client" ? (
            <>
              {/* <div style={{ border: '1px solid #00000033' }}>
                                <header>
                                    Client Name
                                </header>
                                <ul className={style.addScroll}>
                                    {clientData ? clientData.map((userList, index2) => {
                                        return <CheckBox filterdata1={props.filterData} userList={userList} value={props.filterData.clientId}
                                            change={props.setClient} key={index2} type="clientName" />;
                                    }) : null}
                                </ul>
                            </div> */}
              <div style={{ border: "1px solid #00000033" }}>
                <header>Status</header>
                <ul>
                  {status.map((userList, index3) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={newStatus}
                        change={props.setstatus}
                        key={index3}
                        type="clientStatus"
                      />
                    );
                  })}
                </ul>
              </div>
            </>
          ) : props?.type === "project" ? (
            <>
              {/* <div style={{ border: '1px solid #00000033' }}>
                                    <header>
                                        Client Name
                                    </header>
                                    <ul className={style.addScroll}>
                                        {projectClient ? projectClient.map((userList, index2) => {
                                            return <CheckBox filterdata1={props.filterData} userList={userList} value={props.filterData.clientId}
                                                change={props.setClient} key={index2} type="projectClientName" />;
                                        }) : null}
                                    </ul>
                                </div>
                                <div style={{ border: '1px solid #00000033' }}>
                                    <header>
                                        Project Name
                                    </header>
                                    <ul className={style.addScroll}>
                                        {projectList ? projectList.map((userList, index2) => {
                                            return <CheckBox filterdata1={props.filterData} userList={userList} value={props.filterData.projectId}
                                                change={props.setClient} key={index2} type="projectId" />;
                                        }) : null}
                                    </ul>
                                </div> */}
              <div style={{ border: "1px solid #00000033" }}>
                <header>Type of Project</header>
                <ul className={style.addScroll}>
                  {projectCategory
                    ? projectCategory.map((userList, index2) => {
                      return (
                        <CheckBox
                          filterdata1={props.filterData}
                          userList={userList}
                          value={props.filterData.catogaryprojectId}
                          change={props.setClient}
                          key={index2}
                          type="projectCategory"
                        />
                      );
                    })
                    : null}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033" }}>
                <header>Status</header>
                <ul className={style.addScroll}>
                  {projectStatus
                    ? projectStatus.map((userList, index2) => {
                      return (
                        <CheckBox
                          filterdata1={props.filterData}
                          userList={userList}
                          value={props.filterData.statusTypeId}
                          change={props.setClient}
                          key={index2}
                          type="projectId"
                        />
                      );
                    })
                    : null}
                </ul>
              </div>
            </>
          ) : props?.type === "offShore" ? (
            <>
              {/* <div style={{ border: '1px solid #00000033' }}>
                                        <header>
                                            Employee Name
                                        </header>
                                        <ul className={style.addScroll}>
                                            {offShoreEmp ? offShoreEmp.map((userList, index2) => {
                                                return <CheckBox filterdata1={props.filterData} userList={userList} value={props.filterData.empId}
                                                    change={props.setEmployeeId} key={index2} type="empOffshore" />;
                                            }) : null}
                                        </ul>
                                    </div> */}
              <div style={{ border: "1px solid #00000033" }}>
                <header>Technology</header>
                <ul className={style.addScroll}>
                  {empSkills
                    ? empSkills.map((userList, index2) => {
                      return (
                        <CheckBox
                          filterdata1={props.filterData}
                          userList={userList}
                          value={props.filterData.skillId}
                          change={props.setSkills}
                          key={index2}
                          type="offShoreSkill"
                        />
                      );
                    })
                    : null}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033" }}>
                <header>Project</header>
                <ul className={style.addScroll}>
                  {projectList
                    ? projectList.map((userList, index2) => {
                      return (
                        <CheckBox
                          filterdata1={props.filterData}
                          userList={userList}
                          value={props.filterData.projectId}
                          change={props.setProject}
                          key={index2}
                          type="offShoreProject"
                        />
                      );
                    })
                    : null}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033",width:"12%"  }}>
                <header>% Allocation</header>
                <ul>
                  {allocation.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={newAllocation}
                        change={props.setBandwidths}
                        key={index2}
                        type="offShoreAllocation"
                      />
                    );
                  })}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033" ,width:"12%"}}>
                <header>Employee Type</header>
                <ul className={style.addScroll}>
                  {employeeType.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={props.filterData.partners}
                        change={props.setAction}
                        key={index2}
                        type="offShoreEmptype"
                      />
                    );
                  })}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033",width:"15%" }} >
                <header>Salary Range</header>
                <ul>
                  {SalaryRange?.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={props.filterData.salaryRange}
                        change={props.setAction}
                        key={index2}
                        type="OffshorSalaryRange"
                      />
                    );
                  })}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033",width:"18%" }}>
                <header>Status</header>
                <ul className={style.addScroll}>
                  {action.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={newAction}
                        change={props.setAction}
                        key={index2}
                        type="offShoreAction"
                      />
                    );
                  })}
                </ul>
              </div>
             
            </>
          ) : props?.type === "onSite" ? (
            <>
              {/* <div style={{ border: '1px solid #00000033' }}>
                                            <header>
                                                Client Name
                                            </header>
                                            <ul className={style.addScroll}>
                                                {onSiteClient ? onSiteClient.map((userList, index2) => {
                                                    return <CheckBox filterdata1={props.filterData} userList={userList} value={props.filterData.clientId}
                                                        change={props.setClientId} key={index2} type="onSiteClient" />;
                                                }) : null}
                                            </ul>
                                        </div>
                                        <div style={{ border: '1px solid #00000033' }}>
                                            <header>
                                                Employee Name
                                            </header>
                                            <ul className={style.addScroll}>
                                                {onSiteEmp ? onSiteEmp.map((userList, index2) => {
                                                    return <CheckBox filterdata1={props.filterData} userList={userList} value={props.filterData.employeeId}
                                                        change={props.setEmployeeId} key={index2} type="onSiteEmp" />;
                                                }) : null}
                                            </ul>
                                        </div> */}
                                        
              <div style={{ border: "1px solid #00000033" }}>
                <header>Technology</header>
                <ul className={style.addScroll}>
                  {onSiteEmpSkills
                    ? onSiteEmpSkills.map((userList, index2) => {
                      return (
                        <CheckBox
                          filterdata1={props.filterData}
                          userList={userList}
                          value={props.filterData.skill}
                          change={props.setSkills}
                          key={index2}
                          type="onSiteEmpSkills"
                        />
                      );
                    })
                    : null}
                </ul>
              </div>

              <div style={{ border: "1px solid #00000033" }}>
                <header>Salary Range</header>
                <ul>
                  {SalaryRange?.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={props.filterData.salaryRange}
                        change={props.setAction}
                        key={index2}
                        type="OnsiteSalaryRange"
                      />
                    );
                  })}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033" }}>
                <header>Employee Type </header>

                <ul>
                  {employeeType?.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={props.filterData.partners}
                        change={props.setAction}
                        key={index2}
                        type="employeeType"
                      />
                    );
                  })}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033" }}>
                <header>Status</header>

                <ul>
                  {onsitestatus?.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={newAction1}
                        change={props.setAction}
                        key={index2}
                        type="offShoreAction1"
                      />
                    );
                  })}
                </ul>
              </div>
              
            </>
          ) : props?.type === "pool" ? (
            <>
              <div style={{ border: "1px solid #00000033" }}>
                <header>Technology</header>
                <ul className={style.addScroll}>
                  {onSiteEmpSkills
                    ? onSiteEmpSkills.map((userList, index2) => {
                      return (
                        <CheckBox
                          filterdata1={props.filterData}
                          userList={userList}
                          value={props.filterData.skillId}
                          change={props.setSkills}
                          key={index2}
                          type="poolTechnology"
                        />
                      );
                    })
                    : null}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033" }}>
                <header>Experience Range</header>
                <ul className={style.addScroll}>
                 
                {experience?.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={props.filterData.experienceRange}
                        change={props.setAction}
                        key={index2}
                        type="poolExpRange"
                      />
                    );
                  })}
                  

                  {/* <div onChange={onChangeValue}>
     <p> <input type="checkbox" value="1" 
      style={{
                      visibility: "visible",
                      height: "10px",
                      width: "10px",
                      cursor: "pointer",
                    }}name="gender" className={style.one} checked={experienceRange === "1"}/> 0-1 </p>
      <p><input type="radio" value="2" name="gender" className={style.one} checked={experienceRange === "2"}/> 1-2</p>
      <p><input type="radio" value="3" name="gender" className={style.one} checked={experienceRange === "3"} /> 2-5 </p>
     <p> <input type="radio" value="4" name="gender"  className={style.one} checked={experienceRange === "4"} /> 5+</p>
    </div> */}

                </ul>
              </div>
              <div style={{ border: "1px solid #00000033" }}>
                <header>Salary Range</header>
                <ul>
                  {SalaryRange?.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={props.filterData.salaryRange}
                        change={props.setAction}
                        key={index2}
                        type="poolSalaryRange"
                      />
                    );
                  })}
                </ul>
              </div>
              <div style={{ border: "1px solid #00000033" }}>
                <header>Status</header>
                <ul>
                  {poolStatus?.map((userList, index2) => {
                    return (
                      <CheckBox
                        filterdata1={props.filterData}
                        userList={userList}
                        value={poolAction}
                        change={props.setAction}
                        key={index2}
                        type="poolStatus"
                      />
                    );
                  })}
                </ul>
              </div>
             
            </>
          ) :
            props?.type === "userFilter" ? (
              <>
                <div style={{ border: "1px solid #00000033" }}>
                  <header>Type</header>
                  <ul className={style.addScroll}>
                    {userType
                      ? userType.map((userList, index2) => {
                        return (
                          <CheckBox
                            filterdata1={props.filterData}
                            userList={userList}
                            value={props.filterData.skillId}
                            change={props.setSkills}
                            key={index2}
                            type="poolTechnology"
                          />
                        );
                      })
                      : null}
                  </ul>
                </div>
                <div style={{ border: "1px solid #00000033" }}>
                  <header>Status</header>
                  <ul>
                    {userStatus?.map((userList, index2) => {
                      return (
                        <CheckBox
                          filterdata1={props.filterData}
                          userList={userList}
                          value={poolAction}
                          change={props.setAction}
                          key={index2}
                          type="userStatus"
                        />
                      );
                    })}
                  </ul>
                </div>
              </>
            ) : (
              ""
            )}
        </div>
        {/* //userFilterColor */}
        <div className={style.buttonSection}>
          <button onClick={props.closeFilter} className={props.userFilterColor ? style.clearAll1 : style.clearAll}>
            Clear All
          </button>
          <button
            onClick={props.handleApplied.bind(this)}
            className={props.userFilterColor ? style.apply1 : style.apply}
          >
            Apply
          </button>
        </div>
      </div>
    </ModalView>
  );
};

export default Filter;
