import React from "react";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "../tablet.module.css";
import IssueTabletComp from "./component/IssueTabletComp";

const IssueTablet = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <IssueTabletComp />
      </div>
    </div>
  );
};

export default IssueTablet;
