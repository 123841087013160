import React, { useState } from "react";
import style from "./createuser.module.css";
import Select from "react-select";
import dropdownIcon from "../../../../assets/arrowdownIcon.png";
import calendarIcon from "../../../../assets/calendarIcon.png";
import DatePicker from "react-datepicker";
import SideBar from "../../sidebar/SideBar";
import CreatableSelect from "react-select/creatable";
import { toast, ToastContainer } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import calender from "../../../../assets/calblue.png";
import cancel from "../../../../assets/cancell.png";
import "react-toastify/dist/ReactToastify.css";
import {
  createUser,
  getRole,
  getAllTechnology,
  getAllType,
  getSalaryRange,
  getAllFunctionalArea,
  getAllPartnerList,
  addSkill,
} from "../../../../Utils/axios/umsApi";
import { getAcademicsByEmail } from "../../../../Utils/axios/api";
import {
  uploadResume,
  deleteResume,
  getAllDegrees,
  addDegree,
  getCandidateById,
  getWorkExperienceByEmail,
} from "../../../../Utils/axios/api";
import moment from "moment";

import { useQuery, useMutation } from "react-query";
import { useEffect } from "react";
import { ImageProfileView } from "../commonComponent/ImageProfileView";

const CreateUser = () => {
  const [checked, setChecked] = React.useState(false);
  const [totalExpYear, setTotalExpYear] = useState("");
  const [totalExpMonths, setTotalExpMonths] = useState("");
  const [relevantExpYear, setRelevantExpYear] = useState("");
  const [relevantExpMonths, setRelevantExpMonths] = useState("");
  const [userName, setUsername] = useState("");
  const [disable, setDisabled] = useState(true);
  const [emailValidation, setEmailValidation] = useState("Please Enter Email");
  const [validateEmail, setValidateEmail] = useState(false);
  const [disableSub, setDisabledSub] = useState(false);
  const [totalEx, setTotalEx] = useState("Please Enter Total Experience");
  const [validatetotalEx, setValidatetotalEx] = useState(false);
  const [validatetotalRe, setValidatetotalRe] = useState(false);
  const [totalRe, setTotalRe] = useState("Please Enter Relevant Experience");
  const [emptype, setEmpType] = useState(null);
  const [resumeName, setResumeName] = useState("");
  const [acadamicsArray, setAcadamicsArray] = useState([1, 2]);
  const [showImageProfile, setShowImageProfile] = useState(false);
  const [workExperienceArray, setWorkExperienceArray] = useState([1]);

  const [formData, setFormData] = useState({
    type: "",
    code: "",
    name: "",
    email: "",
    number: "",
    employeeRole: "",
    careerStartDate: "",
    dateOfJoining: "",
    relevantExperience: 0,
    technology: "",
    totalExperience: 0,
    empId: localStorage.getItem("employeeId"),
    bond: false,
    educationRequestModels: [
      {
        board: "",
        degree: "",
        grade: null,
        passingYear: 0,
        gradeStatus: "",
      },
      {
        board: "",
        degree: "",
        grade: null,
        passingYear: 0,
        gradeStatus: "",
      },
    ],
    workExpRequestModels: [
      {
        companyName: "",
        reasonForChange: "",
        role: "",
        endDate: null,
        startDate: null,
        present: 0,
      },
    ],
  });

  const location = useLocation();
  const candidateId = location?.state?.candidateId;
  const dropJoinDate = location?.state?.dropJoinDate;
  const { data } = useQuery(["getCandidateById"], () =>
    getCandidateById(candidateId)
  );
  // console.log({ data });
  useEffect(() => {
    if (data) {
      setFormData({
        ...formData,
        personalEmail: data?.email,
        number: data?.mobileNo,
        careerStartDate:
          data?.workExperiences[data?.workExperiences?.length - 1]?.startDate,
        dateOfJoining: dropJoinDate,
        technology: data?.coreSkillId,
        educationRequestModels: data?.candidateAcademics,
        partnerId: data?.partnerId,
        partnerName: data?.partnerName,
        type: data?.isPartnerResource ? 2 : "",
        workExpRequestModels: data?.workExperiences?.map((e, index) => {
          if (e.presentlyWorking === true) {
            return { ...e, present: index + 1 };
          } else {
            return { ...e, present: 0 };
          }
        }),
      });
      setUsername(data?.name);
      setTotalExpYear(data?.totalExperienceYear);
      setTotalExpMonths(data?.totalExperienceMonth);
      setRelevantExpMonths(data?.relevantExperienceMonth);
      setRelevantExpYear(data?.relevantExperienceYear);
      setAcadamicsArray([...Array(data?.candidateAcademics?.length).keys()]);
      setChecked(data?.isPartnerResource);
    }

    console.log(
      "Neerak",
      data,
      formData,
      data?.workExperiences[data?.workExperiences?.length - 1]?.startDate
    );
  }, [data]);

  const { data: getCoreSkillData } = useQuery(
    ["getAllTechnology"],
    getAllTechnology
  );
  console.log({ formData });
  const academicsMutation = useMutation(getAcademicsByEmail, {
    onSuccess: (res) => {
      let newData = res.map((e) => {
        return { ...e, degree: { value: e.degreeId, label: e.degree } };
      });
      setFormData((prevData) => ({
        ...prevData,
        educationRequestModels: newData,
      }));
      let arr = [];
      for (let i = 0; i < res?.length; i++) {
        arr.push(i);
      }
      setAcadamicsArray(arr);
    },
  });
  const experienceMutation = useMutation(getWorkExperienceByEmail, {
    onSuccess: (res) => {
      let newData = res?.map((e, index) => {
        if (e.presentlyWorking === true) {
          return { ...e, present: index + 1 };
        } else {
          return { ...e, present: 0 };
        }
      });
      setFormData((prev) => ({
        ...prev,
        workExpRequestModels: newData,
      }));
    },
  });

  const mutationDegree = useMutation(addDegree, {
    onSuccess: (res) => {
      let academics = formData?.educationRequestModels;
      for (let i = 0; i < academics.length; i++) {
        if (academics[i].degree.label == res?.name) {
          let obj = {
            label: res?.name,
            value: res?.id,
          };
          academics[i].degree = obj;
          break;
        }
      }
    },
  });
  const CustomInputClgPY = React.forwardRef((props, ref) => {
    let temp = [...formData?.educationRequestModels];
    return (
      <>
        {temp[props?.index]?.passingYear ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              width="15px"
              height="15px"
              alt="cancel"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].passingYear = null;
                setFormData({
                  ...formData,
                  educationRequestModels: temp,
                });
              }}
            />
          </div>
        ) : (
          <div onClick={props.onClick}>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const { data: getAllTypeData } = useQuery(["getAllType"], getAllType);
  console.log({ getAllTypeData });
  const getAllTypeOption = getAllTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const { data: getAllDegreesData } = useQuery(
    ["getAllDegrees"],
    getAllDegrees
  );
  const getAllDegreesOption = getAllDegreesData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const { data: getSalaryRangeData } = useQuery(
    ["getSalaryRange"],
    getSalaryRange,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: getAllPartnerListData } = useQuery(
    ["getAllPartnerList"],
    getAllPartnerList,
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: getAllFunctionalData } = useQuery(
    ["getAllFunctionalArea"],
    getAllFunctionalArea,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getSalaryRangeOption = getSalaryRangeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const getFunctionalOption = getAllFunctionalData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  let index = getAllTypeOption?.findIndex((e) => e.label === "Partner");

  getAllTypeOption?.splice(index, 1);

  const handleChange = () => {
    setChecked(!checked);
    setFormData({
      ...formData,
      type: null,
      partnerId: null,
      partnerName: null,
    });
  };
  useEffect(() => {
    setResumeName(formData?.resumeUrl);
  }, [formData?.resumeUrl]);
  formData.totalExperience = Number(totalExpYear * 12) + Number(totalExpMonths);
  formData.relevantExperience =
    Number(relevantExpYear * 12) + Number(relevantExpMonths);
  formData.name = userName;

  const history = useHistory();
  const [validate, setValidate] = useState(false);

  const { data: role } = useQuery(["getRole"], () => getRole());

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="dropdownIcon"
          style={{
            position: "absolute",
            top: "32%",
            right: "6%",
            width: "18px",
          }}
        />
      </span>
    );
  };
  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{ padding: "8px", fontWeight: 600, cursor: "pointer" }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "30%", zIndex: "0" }}
          src={calendarIcon}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label ref={ref} style={{ padding: "8px", fontWeight: 600 }}>
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          alt="cancel"
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "30%",
            zIndex: "0",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });

  const getpartnerlist = getAllPartnerListData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getRoleList = role?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getTechnologyList = getCoreSkillData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push("/CreateCanfirmation", { id });
    }, 0);
  };
  const uploadResumeMutation = useMutation(uploadResume, {
    onSuccess: (data) => {
      setFormData({ ...formData, resumeUrl: data.toString() });
    },
  });
  const mutation = useMutation(createUser, {
    onSuccess: (res) => {
      setDisabledSub(false);
      if (res?.responseStatus?.statusCode == 1) {
        notify1(res?.responseData?.message);
      } else {
        // notifySuccess();

        handleRedirect(res.responseData.id);
      }
    },
    onError: (res) => {
      setDisabledSub(false);
    },
  });
  const checkPartner = () => {
    if (disable) {
      return false;
    } else if (formData?.partnerId) {
      return false;
    } else {
      return true;
    }
  };
  const checkbond = () => {
    if (formData?.bond && !formData?.bondDate) {
      return true;
    } else {
      return false;
    }
  };
  const removeFieldFromAcademics = (data) => {
    const newData = data?.filter((e) => e?.degreeId && e?.passingYear !== "");
    return newData;
  };
  const removeFieldFromArray = (data) => {
    const newData = data?.filter((e) => e?.companyName !== "");
    return newData;
  };
  const handleImage = (e) => {
    uploadResumeMutation.mutate(e.target.files[0]);
  };
  console.log({ formData });
  const onCreateHandler = () => {
    let filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (formData.email == "") {
      setEmailValidation("Please Enter Email ");
      setValidateEmail(true);
    } else if (!filter.test(formData.email)) {
      setEmailValidation("Please Enter Valid Email ");
      setValidateEmail(true);
    } else {
      setValidateEmail(false);
    }
    if (totalExpYear === "" || totalExpMonths === "") {
      setTotalEx("Please Enter Total Experience");
      setValidatetotalEx(true);
    } else if (parseInt(totalExpMonths) > 12) {
      setTotalEx("Please Enter Valid Total Experience");
      setValidatetotalEx(true);
    } else {
      setValidatetotalEx(false);
    }
    if (relevantExpYear === "" || relevantExpMonths === "") {
      setTotalRe("Please Enter Relevant Experience");
      setValidatetotalRe(true);
    } else if (parseInt(relevantExpMonths) > 12) {
      setTotalRe("Please Enter Valid Relevant Experience");
      setValidatetotalRe(true);
    } else {
      setValidatetotalRe(false);
    }

    if (
      !formData.type ||
      !formData.code?.trim() ||
      !formData.name?.trim() ||
      !filter.test(formData.email) ||
      // formData?.number.length < 10 ||
      // formData?.number.length > 14 ||
      // formData?.number == "" ||
      formData.email == "" ||
      !formData.careerStartDate ||
      !formData.dateOfJoining ||
      !(formData.relevantExperience !== "") ||
      !(relevantExpYear !== "") ||
      !(relevantExpMonths !== "") ||
      !(relevantExpYear !== "") ||
      !(relevantExpMonths !== "") ||
      !(formData.totalExperience !== "") ||
      checkPartner() ||
      checkbond()
    ) {
      setValidate(true);
    } else {
      setDisabledSub(true);
      let newFormData = {
        ...formData,
        additionalSkills: formData?.additionalSkills?.map((e) => e.id),
        educationRequestModels: removeFieldFromAcademics(
          formData?.educationRequestModels
        )?.map((e) => ({
          board: e?.board,
          degreeId:
            typeof e?.degreeId === "object" ? e?.degreeId?.value : e?.degreeId,
          grade: e?.grade,
          gradeStatus: e?.gradeStatus,
          passingYear: e?.passingYear,
        })),
        uploadImageUrl: formData?.resumeUrl,
        linkedInUrl: formData?.linkedInUrl,
        workExpRequestModels: removeFieldFromArray(
          formData?.workExpRequestModels
        ),
      };
      mutation.mutate(newFormData);
    }

    // notifySuccess();
    // handleRedirect();
  };
  const customStyles = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "8px 1.5% 0px 0%",
      minHeight: "38px",
      fontWeight: 500,
      // width:"90%",
      outline: "none",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(78 206 199)",
      },
      backgroundColor: state.isSelected ? "rgb(78 206 199)" : "white",
    }),
    clearIndicator: (base) => ({
      ...base,
      display: "none",
    }),

    placeholder: () => ({
      display: "none",
    }),
  };

  const customStylesForType = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "8px 1.5% 0px 0%",
      height: "38px",
      fontWeight: 500,
      // width:"90%",
      outline: "none",
    }),

    placeholder: () => ({
      display: "none",
    }),
  };

  const notify1 = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const addSkillMutation = useMutation(addSkill, {
    onSuccess: (res) => {
      let selected = formData?.additionalSkills;
      selected.pop();

      selected = [
        ...selected,
        {
          id: res?.additionalSkillId,
          skillName: res?.additionalSkillName,
        },
      ];
      let items = {};
      selected?.forEach((element) => {
        if (!items[element?.id]) {
          items[element?.id] = element;
        }
      });
      let newData = Object.values(items);
      setFormData((prevData) => ({
        ...prevData,
        additionalSkills: newData,
      }));
    },
  });
  const isSameUser = (a, b) => a.id === b?.value && a?.skillName === b?.label;
  const handleCross = (left, right, compareFunction) => {
    if (!left) {
      return;
    }
    return left.filter(
      (leftValue) =>
        !right.some((rightValue) => compareFunction(leftValue, rightValue))
    );
  };
  const handleSelect = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      additionalSkills: e.map((i) => ({
        id: i.value,
        skillName: i?.label,
      })),
    }));
    let prevSelect = handleCross(formData?.additionalSkills, e, isSameUser);

    if (prevSelect?.length > 0) {
      return;
    }
    if (
      getTechnologyList?.some((item) => item.label == e[e.length - 1]?.label)
    ) {
    } else {
      if (e?.length > 0) {
        setTimeout(() => {
          addSkillMutation.mutate({
            skill: e[e.length - 1]?.label,
          });
        }, 1000);
      }
    }
  };
  const bondOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const options = [
    { value: 1, label: "CGPA" },
    { value: 2, label: "Percentage" },
  ];
  const CustomInputClgWXS = React.forwardRef((props, ref) => {
    let temp = [...formData?.workExpRequestModels];
    return (
      <>
        {temp[props?.index]?.startDate ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              alt="cancel"
              width="15px"
              height="15px"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].startDate = null;
                setFormData({
                  ...formData,
                  workExpRequestModels: temp,
                });
              }}
            />
          </div>
        ) : (
          <div onClick={props.onClick}>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  const CustomInputClgWXEND = React.forwardRef((props, ref) => {
    let temp = [...formData?.workExpRequestModels];
    return (
      <>
        {temp[props?.index]?.endDate ? (
          <div>
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={cancel}
              width="15px"
              alt="cancel"
              height="15px"
              style={{
                position: "absolute",
                right: "5%",
                top: "16%",
                cursor: "pointer",
              }}
              onClick={() => {
                temp[props?.index].endDate = null;
                setFormData({
                  ...formData,
                  workExpRequestModels: temp,
                });
              }}
            />
          </div>
        ) : (
          <div
            onClick={
              temp[props?.index]?.present === props?.index + 1
                ? null
                : props.onClick
            }
          >
            <label
              ref={ref}
              style={{
                cursor: "pointer",
              }}
            >
              {props.value || props.placeholder}
            </label>
            <img
              src={calender}
              alt="calender"
              style={{
                position: "absolute",
                right: "5%",
                top: "13%",
                cursor: "pointer",
              }}
            />
          </div>
        )}
      </>
    );
  });
  return (
    <>
      <ToastContainer />
      {/*<Header />*/}

      <div className={style.main_user_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.breadscrumb} style={{ padding: "0px" }}>
            <div>
              <p className={style.creatUser}>Create User</p>
            </div>
          </div>
          <div className={style.createposition}>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p
                  className={style.cu_label}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    type="checkbox"
                    id="demoCheckbox"
                    name="checkbox"
                    value="1"
                    checked={checked}
                    onChange={(e) => handleChange()}
                    style={{
                      visibility: "visible",
                      height: "20px",
                      width: "25px",
                      cursor: "pointer",
                    }}
                  />
                  <label
                    for="demoCheckbox"
                    className={style.cu_label}
                    style={{ color: "black" }}
                  >
                    Is Partner Resource? <span style={{ color: "red" }}>*</span>
                  </label>
                </p>
                {!checked ? (
                  <Select
                    styles={customStylesForType}
                    classNamePrefix={"create_user"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        type: e.value,
                        partnerId: null,
                        partnerName: null,
                      });
                      setEmpType(e.label);
                    }}
                    value={{
                      label: formData?.type,
                      label: emptype,
                    }}
                    options={getAllTypeOption}
                  />
                ) : (
                  <Select
                    styles={customStylesForType}
                    classNamePrefix={"create_user"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    // value={
                    //   formData?.partnerId
                    //     ? getpartnerlist?.find(
                    //         (e) => e?.partnerId === formData?.partnerId
                    //       )
                    //     : null
                    // }
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        type: getAllTypeData?.find((e) => e.name === "Partner")
                          .id,
                        partnerId: e.value,
                        partnerName: e.label,
                      });
                      setEmpType(null);
                    }}
                    options={getpartnerlist}
                    value={{
                      label: formData?.partnerName,
                      value: formData?.partnerId,
                    }}
                  />
                )}
                {!checked && (
                  <p
                    className={
                      validate && !formData?.type
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Employee Type
                  </p>
                )}
                {checked && (
                  <p
                    className={
                      validate && !formData?.partnerId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Partner Resourse
                  </p>
                )}
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>
                  Code
                  <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  defaultValue={formData?.code?.trim()}
                  className={style.cu_field2}
                  // placeholder="Search by User Name"
                  type={"text"}
                  min={0}
                  onChange={(e) =>
                    setFormData({ ...formData, code: e.target.value })
                  }
                ></input>
                <p
                  className={
                    validate && !formData?.code?.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Code
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Name
                  <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  defaultValue={formData?.name?.trim()}
                  className={style.cu_field2}
                  value={formData?.name}
                  // placeholder="Search by User Name"
                  onChange={(e) =>
                    // setFormData({...formData, name:e.target.value}),
                    setUsername(e.target.value)
                  }
                ></input>
                <p
                  className={
                    validate && !formData?.name?.trim()
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Name
                </p>
              </div>
            </div>
            {/* Second */}
            <div className={style.Cu_row1} style={{ marginTop: "2px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Email
                  <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  defaultValue={formData?.email}
                  className={style.cu_field2}
                  type="email"
                  onChange={
                    (e) => setFormData({ ...formData, email: e.target.value })
                    //
                  }
                  onKeyDown={() => setValidateEmail(false)}
                ></input>
                <p
                  className={
                    validateEmail ? style.errorText : style.errorTextHidden
                  }
                >
                  {emailValidation}
                </p>
              </div>
              <div className={style.cu_col2} style={{ marginLeft: "11px" }}>
                <p className={style.cu_label}>
                  Personal Email
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="email"
                    value={formData?.personalEmail}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        personalEmail: e.target.value,
                      })
                    }
                  ></input>
                </div>
                {/* <p
                  className={
                    validateNumber ? style.errorText : style.errorTextHidden
                  }
                >
                  {numerror}
                </p> */}
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Mobile Number
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    defaultValue={formData?.phoneNumber}
                    className={style.cu_field2}
                    type={"number"}
                    value={formData?.number}
                    onChange={(e) =>
                      setFormData({ ...formData, number: e.target.value })
                    }
                    // onKeyDown={() => setNumError("")}
                  ></input>
                </div>
              </div>
            </div>
            {/* Three */}
            <div className={style.Cu_row1} style={{ marginTop: "2px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Career Start Date
                  <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yyyy"
                    // minDate={new Date()}
                    selected={
                      formData.careerStartDate
                        ? moment(formData?.careerStartDate)?.toDate()
                        : null
                    }
                    onChange={(date) =>
                      setFormData({ ...formData, careerStartDate: date })
                    }
                    defaultValue={null}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={<CustomInputClg />}
                    style={{ backgroundColor: "#F5F6FA" }}
                  />
                </div>
                <p
                  className={
                    validate && !formData?.careerStartDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Career Start Date
                </p>
              </div>
              <div className={style.cu_col2} style={{ marginLeft: "11px" }}>
                <p className={style.cu_label}>
                  Total Experience
                  <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    type="number"
                    min={0}
                    className={style.cu_row_field2}
                    value={totalExpYear}
                    placeholder="Year"
                    // onChange={(e) =>
                    //   setFormData({...formData, totalExperience: e.target.value })
                    // }
                    onChange={(e) => setTotalExpYear(e.target.value)}
                    onKeyDown={() => setValidatetotalEx(false)}
                  ></input>

                  <input
                    type="number"
                    min={0}
                    max={12}
                    className={style.cu_row_field2}
                    placeholder="Months"
                    value={totalExpMonths}
                    onChange={(e) => setTotalExpMonths(e.target.value)}
                    onKeyDown={() => setValidatetotalEx(false)}
                  ></input>
                </div>

                <p
                  className={
                    validatetotalEx ? style.errorText : style.errorTextHidden
                  }
                >
                  {totalEx}
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>
                  Relevant Experience
                  <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <div className={style.cu_row2_col2}>
                  <input
                    type="number"
                    className={style.cu_row_field2}
                    placeholder="Year"
                    value={relevantExpYear}
                    onChange={(e) => setRelevantExpYear(e.target.value)}
                    min={0}
                    onKeyDown={() => setValidatetotalRe(false)}
                  ></input>
                  <input
                    type="number"
                    min={0}
                    max={12}
                    value={relevantExpMonths}
                    onChange={(e) => setRelevantExpMonths(e.target.value)}
                    className={style.cu_row_field2}
                    placeholder="Months"
                    onKeyDown={() => setValidatetotalRe(false)}
                  ></input>
                </div>
                {/*monthdError && relevantExpMonths > 11 && (
                  <p className={style.errorText}>Please enter valid months</p>
                )*/}
                <p
                  className={
                    validatetotalRe ? style.errorText : style.errorTextHidden
                  }
                >
                  {totalRe}
                </p>
              </div>
            </div>
            {/* Four */}
            <div className={style.Cu_row1} style={{ marginTop: "2px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>
                  Date of Joining
                  <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    // minDate={new Date()}
                    selected={
                      formData.dateOfJoining
                        ? moment(formData.dateOfJoining)?.toDate()
                        : null
                    }
                    // startDate={null}
                    // onChange={(date) => setDateOfJoining(date)}
                    onChange={(date) =>
                      setFormData({ ...formData, dateOfJoining: date })
                    }
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    defaultValue={null}
                    customInput={<CustomInputClg />}
                    style={{ backgroundColor: "#F5F6FA" }}
                  />
                </div>
                <p
                  className={
                    validate && !formData?.dateOfJoining
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Date of Joining
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Technology</p>
                <Select
                  styles={customStyles}
                  menuPlacement="top"
                  classNamePrefix={"create_position"}
                  value={
                    formData?.technology
                      ? getTechnologyList?.find(
                          (e) => e?.value === formData?.technology
                        )
                      : null
                  }
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, technology: e.value })
                  }
                  options={getTechnologyList}
                />
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Additional Skill</p>
                <CreatableSelect
                  menuPlacement="top"
                  isMulti
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => handleSelect(e)}
                  options={getTechnologyList}
                  formatCreateLabel={(userInput) => `+ Add "${userInput}"`}
                />
              </div>
            </div>
            {/* Five */}

            <div className={style.Cu_row1} style={{ marginTop: "2px" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Designation</p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  // selected={formData?.employeeRole.slice(0,10)}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      employeeRole: e.value,
                    });
                  }}
                  options={getRoleList}
                />
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>
                  Salary Range
                  {/* <span style={{ color: "red" }}>*</span> */}
                </p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, salaryRangeId: e.value })
                  }
                  options={getSalaryRangeOption}
                />
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Functional Area</p>
                <Select
                  menuPlacement="top"
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    setFormData({ ...formData, functionalAreaId: e.value })
                  }
                  options={getFunctionalOption}
                />
              </div>
            </div>
            {/* Six */}

            <div className={style.Cu_row1} style={{ marginTop: "1.7rem" }}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Bond</p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    if (!e.value) {
                      setFormData({
                        ...formData,
                        bondDate: "",
                        bond: e.value,
                      });
                    } else {
                      setFormData({
                        ...formData,
                        bond: e.value,
                      });
                    }
                  }}
                  options={bondOption}
                />
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Bond End Date</p>
                <div
                  className={style.csd}
                  style={!formData?.bond ? { background: "#8f8b8b69" } : null}
                >
                  <DatePicker
                    dateFormat="dd MMM yy"
                    selected={formData.bondDate}
                    onChange={(date) =>
                      setFormData({ ...formData, bondDate: date })
                    }
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    defaultValue={null}
                    customInput={
                      formData?.bondDate ? (
                        <CustomInputCross properties="bondDate" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                    disabled={!formData?.bond}
                    style={{ backgroundColor: "red" }}
                  />
                </div>
                <p
                  className={
                    formData?.bond && validate && !formData?.bondDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Date of Joining
                </p>
              </div>
              <div className={style.cu_col3}>
                <div className={style.Cu_row1}>
                  <div style={{ marginTop: "0.5rem" }}>
                    {!resumeName && (
                      <span className={style.cu_label}>
                        Upload Profile Image
                      </span>
                    )}
                    {resumeName ? (
                      <>
                        <div className={style.imageupload}>
                          <label for="file-input">
                            <img
                              id="image"
                              src={resumeName}
                              alt="resumeName"
                              style={{
                                borderRadius: "50%",
                                width: "4.5rem",
                                height: "4.5rem",
                                border: "1px solid black",
                                cursor: "pointer",
                              }}
                              onClick={() => setShowImageProfile(true)}
                            />
                          </label>
                          <p
                            onClick={() => {
                              setFormData({ ...formData, resumeUrl: "" });
                              setResumeName(null);
                              deleteResume(formData?.resumeUrl);
                            }}
                            className={style.rmpdfcross}
                          >
                            x
                          </p>
                        </div>
                      </>
                    ) : (
                      <input
                        id="file-input"
                        className={style.upload_resume}
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          handleImage(e);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <ImageProfileView
                show={showImageProfile}
                image={resumeName}
                okaybtn={() => setShowImageProfile(false)}
              ></ImageProfileView>
            </div>
            {/* seven */}

            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Date of Birth</p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    selected={formData.dob}
                    onChange={(date) => setFormData({ ...formData, dob: date })}
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    defaultValue={null}
                    customInput={
                      formData?.dob ? (
                        <CustomInputCross properties="dob" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                    style={{ backgroundColor: "red" }}
                  />
                </div>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Marriage Anniversary</p>
                <div className={style.csd}>
                  <DatePicker
                    dateFormat="dd MMM yy"
                    selected={formData.marriageAnniversary}
                    onChange={(date) =>
                      setFormData({ ...formData, marriageAnniversary: date })
                    }
                    disabledKeyboardNavigation
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    defaultValue={null}
                    customInput={
                      formData?.marriageAnniversary ? (
                        <CustomInputCross properties="marriageAnniversary" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>LinkedIn URL</p>
                <div className={style.cu_row2_col2}>
                  <input
                    className={style.cu_field2}
                    type="email"
                    value={formData?.linkedInUrl}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        linkedInUrl: e.target.value,
                      })
                    }
                  ></input>
                </div>
              </div>
            </div>
            {/*Note*/}
            <div className={style.Cu_notes} style={{ marginTop: "1rem" }}>
              <p className={style.cu_label}>Notes</p>
              <div>
                <textarea
                  className={style.notes}
                  type="textarea"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      note: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            {/* Academic */}
          </div>
          {/*work Experience*/}
          <div className={style.createposition}>
            <p className={style.title}>Work Experience</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Company Name</p>
              </div>
              <div className={style.cu_col2_to} style={{ flex: "0 0 23%" }}>
                {
                  <div className={style.multiinput}>
                    <div className={style.cu_label}>From</div>
                    <div
                      className={style.cu_label}
                      style={{ paddingRight: "34%" }}
                    >
                      To
                    </div>
                  </div>
                }
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Role</p>
              </div>

              <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                <p className={style.cu_label}>Reason For Change</p>
              </div>
            </div>
            {formData?.workExpRequestModels?.map((item, index) => {
              return (
                <div className={style.workExperiences} key={index}>
                  {
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`present${index}`}
                        name={`present`}
                        value={index}
                        checked={
                          formData?.workExpRequestModels?.[index].present ===
                          index + 1
                        }
                        onChange={() => {
                          let temp = [...formData?.workExpRequestModels];
                          temp = temp.map((e) => ({ ...e, present: 0 }));
                          temp[index].present = index + 1;
                          temp[index].endDate = null;
                          setFormData({
                            ...formData,
                            workExpRequestModels: temp,
                          });
                        }}
                      />
                      <label
                        for={`present${index}`}
                        style={{ fontSize: "1rem", color: "#000000" }}
                      >
                        Presently working here
                      </label>
                    </div>
                  }
                  {formData?.workExpRequestModels?.length > 1 && (
                    <button
                      className={style.btn_remove}
                      onClick={(e) => {
                        setWorkExperienceArray(
                          workExperienceArray.filter((_, i) => {
                            return i !== index;
                          })
                        );
                        setFormData({
                          ...formData,
                          workExpRequestModels:
                            formData?.workExpRequestModels?.filter(
                              (_, i) => i !== index
                            ),
                        });
                      }}
                    >
                      ❌
                    </button>
                  )}
                  <div className={style.Cu_row1}>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <input
                        className={style.bigInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.workExpRequestModels];
                          temp[index].companyName = e?.target?.value;
                          setFormData({
                            ...formData,
                            workExpRequestModels: temp,
                          });
                        }}
                        value={
                          formData?.workExpRequestModels[index]?.companyName
                        }
                      />
                    </div>
                    <div
                      className={style.cu_col2_to}
                      style={{ flex: "0 0 23%" }}
                    >
                      <div className={style.multiinput}>
                        <div>
                          <div
                            className={style.cu_field23}
                            style={{ marginTop: "0.25rem" }}
                          >
                            <DatePicker
                              placeholder="MM YYYY"
                              peekNextMonth
                              showMonthYearPicker
                              // showMonthDropdown
                              // showYearDropdown
                              // dropdownMode="select"
                              selected={
                                formData?.workExpRequestModels[index]?.startDate
                                  ? moment(
                                      formData?.workExpRequestModels[index]
                                        ?.startDate
                                    ).toDate()
                                  : null
                              }
                              onChange={(date) => {
                                let temp = [...formData?.workExpRequestModels];
                                temp[index].startDate = moment(date).format();
                                setFormData({
                                  ...formData,
                                  workExpRequestModels: temp,
                                });
                              }}
                              dateFormat="MMM yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              clearButton
                              customInput={<CustomInputClgWXS index={index} />}
                            />
                          </div>
                        </div>
                        <div>
                          <div
                            className={style.cu_field23}
                            style={
                              formData?.workExpRequestModels[index]?.present ===
                              index + 1
                                ? {
                                    marginTop: "0.25rem",
                                    backgroundColor: "#b2a6a66b",
                                    flex: "0 0 8%",
                                  }
                                : { marginTop: "0.25rem", flex: "0 0 5%" }
                            }
                          >
                            <DatePicker
                              // showMonthYearPicker
                              placeholder=" DD MM YYYY"
                              peekNextMonth
                              showMonthYearPicker
                              // showMonthDropdown
                              // showYearDropdown
                              // dropdownMode="select"
                              selected={
                                formData?.workExpRequestModels[index]?.endDate
                                  ? moment(
                                      formData?.workExpRequestModels[index]
                                        ?.endDate
                                    ).toDate()
                                  : null
                              }
                              onChange={(date) => {
                                let temp = [...formData?.workExpRequestModels];
                                temp[index].endDate = moment(date).format();
                                setFormData({
                                  ...formData,
                                  workExpRequestModels: temp,
                                });
                              }}
                              dateFormat="MMM yyyy"
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              customInput={
                                <CustomInputClgWXEND index={index} />
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {/* validation start month is less than end month */}
                    </div>
                    <div className={style.cu_col3} style={{ flex: "0 0 23%" }}>
                      <input
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...formData?.workExpRequestModels];
                          temp[index].role = e?.target?.value;
                          setFormData({
                            ...formData,
                            workExpRequestModels: temp,
                          });
                        }}
                        value={formData?.workExpRequestModels[index]?.role}
                      ></input>
                    </div>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <textarea
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...formData?.workExpRequestModels];
                          temp[index].reasonForChange = e?.target?.value;
                          setFormData({
                            ...formData,
                            workExpRequestModels: temp,
                          });
                        }}
                        value={
                          formData?.workExpRequestModels[index]?.reasonForChange
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <div
              className={style.workExpButton}
              style={{ marginTop: "1.3rem", gap: "1rem" }}
            >
              <button
                className={style.btn_add}
                onClick={() => {
                  // push new value 2 in array
                  setWorkExperienceArray([
                    ...workExperienceArray,
                    Math.floor(Math.random() * 100),
                  ]);
                  setFormData({
                    ...formData,
                    workExpRequestModels: [
                      ...formData?.workExpRequestModels,
                      {
                        companyName: "",
                        reasonForChange: "",
                        role: "",
                        endDate: null,
                        startDate: null,
                        present: 0,
                      },
                    ],
                  });
                }}
              >
                Add
              </button>
              <button
                className={style.btn_add}
                onClick={() =>
                  formData?.personalEmail
                    ? experienceMutation.mutate({
                        emailId: formData?.personalEmail,
                      })
                    : notify1("Please Enter Personal Email")
                }
              >
                Autofill
              </button>
            </div>
          </div>
          <div className={style.createposition}>
            <p className={style.title}>Academics</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1} style={{ flex: "0 0 26%" }}>
                <p className={style.cu_label}>Degree</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 30%" }}>
                <p className={style.cu_label}>Board of Education/University</p>
              </div>
              <div className={style.cu_col2_to} style={{ flex: "0 0 10%" }}>
                <p className={style.cu_label}>Passing Year</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 27%" }}>
                <p className={style.cu_label}>Grade </p>
              </div>
            </div>

            {acadamicsArray?.map((item, index) => {
              return (
                <div className={style.candidateAcademics}>
                  {acadamicsArray?.length > 1 && (
                    <button
                      className={style.btn_remove}
                      style={{ top: "-30%" }}
                      onClick={() =>
                        // remove last value from array
                        {
                          let temp = [...formData?.educationRequestModels];
                          temp.splice(index, 1);
                          setFormData((prevData) => ({
                            ...prevData,
                            educationRequestModels: temp,
                          }));
                          acadamicsArray.splice(index, 1);
                        }
                      }
                    >
                      ❌
                    </button>
                  )}

                  <div
                    className={style.Cu_row1}
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <div className={style.cu_col1} style={{ flex: "0 0 26%" }}>
                      <CreatableSelect
                        styles={customStyles}
                        classNamePrefix={"create_position"}
                        options={getAllDegreesOption}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        value={getAllDegreesOption?.find(
                          (e) =>
                            e?.value ===
                            formData?.educationRequestModels[index]?.degreeId
                        )}
                        onChange={(e) => {
                          let temp = [...formData?.educationRequestModels];

                          temp[index].degreeId = e;
                          setFormData({
                            ...formData,
                            educationRequestModels: temp,
                          });
                          let indexs = getAllDegreesOption.some(
                            (item) => item.label === e.label
                          );
                          !indexs && mutationDegree.mutate({ degree: e.label });
                        }}
                        formatCreateLabel={(userInput) =>
                          `+ Add "${userInput}"`
                        }
                      ></CreatableSelect>
                    </div>
                    <div
                      className={style.cu_col3}
                      style={{ flex: "0 0 30%", marginTop: "0.5rem" }}
                    >
                      <input
                        name="boardEdu"
                        className={style.bigInput}
                        onChange={(e) => {
                          let temp = [...formData?.educationRequestModels];
                          temp[index].board = e?.target?.value;
                          setFormData({
                            ...formData,
                            educationRequestModels: temp,
                          });
                        }}
                        value={formData?.educationRequestModels[index]?.board}
                      />
                    </div>
                    <div
                      className={style.cu_col2_ac}
                      style={{ flex: "0 0 10%" }}
                    >
                      <div>
                        <div
                          className={style.cu_fieldAC}
                          style={{ marginTop: "0.5rem" }}
                        >
                          <DatePicker
                            placeholder="YYYY"
                            selected={
                              formData?.educationRequestModels[index]
                                ?.passingYear
                                ? moment(
                                    formData?.educationRequestModels[index]
                                      ?.passingYear
                                  ).toDate()
                                : null
                            }
                            dateFormat="yyyy"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            // showYearPicker
                            // showYearDropdown
                            showYearPicker
                            dropdownMode="select"
                            onChange={(date) => {
                              let temp = [...formData?.educationRequestModels];
                              temp[index].passingYear =
                                moment(date).format("YYYY");
                              setFormData({
                                ...formData,
                                educationRequestModels: temp,
                              });
                            }}
                            customInput={<CustomInputClgPY index={index} />}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={style.cu_col3}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flex: "0 0 27%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <Select
                          styles={customStyles}
                          classNamePrefix={"create_position"}
                          options={options}
                          components={{
                            DropdownIndicator: () => CustomInput(),
                            IndicatorSeparator: () => null,
                          }}
                          value={options?.find(
                            (e) =>
                              formData?.educationRequestModels[index]
                                ?.gradeStatus === e?.label
                          )}
                          onChange={(e) => {
                            let temp = [...formData?.educationRequestModels];

                            temp[index].gradeStatus = e.label;
                            setFormData({
                              ...formData,
                              educationRequestModels: temp,
                            });
                          }}
                        ></Select>
                      </div>
                      {formData?.educationRequestModels[index]?.gradeStatus ===
                        "CGPA" && (
                        <div style={{ width: "40%" }}>
                          <input
                            name="grade"
                            type="number"
                            min={0}
                            max={100}
                            style={{ marginTop: "0.5rem" }}
                            value={
                              formData?.educationRequestModels[index]?.grade
                            }
                            onChange={(e) => {
                              let temp = [...formData?.educationRequestModels];
                              temp[index].grade = parseFloat(e?.target?.value);
                              setFormData({
                                ...formData,
                                educationRequestModels: temp,
                              });
                            }}
                            className={style.cu_field233}
                          />
                          <p
                            className={
                              formData?.educationRequestModels[index]?.grade >
                              10
                                ? style.errorText
                                : style.errorTextHidden
                            }
                            style={{ fontSize: "10px" }}
                          >
                            CGPA should be less than 10
                          </p>
                        </div>
                      )}
                      {formData?.educationRequestModels[index]?.gradeStatus ===
                        "Percentage" && (
                        <div style={{ width: "40%" }}>
                          <input
                            name="grade"
                            type="number"
                            min={0}
                            max={100}
                            style={{ marginTop: "0.5rem" }}
                            value={
                              formData?.educationRequestModels[index]?.grade
                            }
                            onChange={(e) => {
                              let temp = [...formData?.educationRequestModels];
                              temp[index].grade = parseFloat(e?.target?.value);
                              setFormData({
                                ...formData,
                                educationRequestModels: temp,
                              });
                            }}
                            className={style.cu_field233}
                          />
                          <p
                            className={
                              formData?.educationRequestModels[index]?.grade >
                              100
                                ? style.errorText
                                : style.errorTextHidden
                            }
                            style={{ fontSize: "10px" }}
                          >
                            % should be less than 100
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              className={style.workExpButton}
              style={{ marginTop: "1.3rem", gap: "1rem" }}
            >
              <button
                className={style.btn_add}
                onClick={
                  () => {
                    setAcadamicsArray([
                      ...acadamicsArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData({
                      ...formData,
                      educationRequestModels: [
                        ...formData?.educationRequestModels,
                        {
                          degree: "",
                          grade: null,
                          board: "",
                        },
                      ],
                    });
                  }
                  // push new value 2 in array
                }
              >
                Add
              </button>
              <button
                className={style.btn_add}
                onClick={() =>
                  formData?.personalEmail
                    ? academicsMutation.mutate({
                        emailId: formData?.personalEmail,
                      })
                    : notify1("Please Enter Personal Email")
                }
              >
                Autofill
              </button>
            </div>
          </div>
          <div className={style.cu_button}>
            <button
              style={
                disableSub
                  ? {
                      padding: "0px 10px",
                      background: "gray",
                      color: "#fff",
                      border: "gray",
                      height: "37px",
                      width: "100px",
                      margin: "5px",
                      // marginTop: "15px",
                      // borderRadius: "5px",
                      cursor: "pointer",
                    }
                  : {
                      padding: "0px 10px",
                      background: "#289e98",
                      color: "#fff",
                      border: "1px solid #4fdf4f",
                      height: "37px",
                      width: "100px",
                      // margin: "5px",
                      // marginTop: "15px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }
              }
              disabled={disableSub}
              onClick={onCreateHandler}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUser;
