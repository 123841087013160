import React from "react";
import style from "./customTooltip.module.css";
const ATSButton = ({ children, onClick, styles, status }) => {
  return (
    <>
      <button onClick={onClick} style={styles} className={style.atsButton}>
        {children}
      </button>
    </>
  );
};

export default ATSButton;
