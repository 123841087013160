export const customStyles = {
  control: (style, state) => ({
    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "4px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "100%",

    cursor: "pointer",
  }),
  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgb(252, 187, 8)",
    },
    backgroundColor: state.isSelected ? "rgb(252, 187, 8)" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
  }),
};
export const customStylesForTaskTracker = {
  
  menu: (style) => ({
    ...style,
    width:100,
  }),
  
  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "90%",
    cursor: "pointer",
  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "#65a15e",
    },
    backgroundColor: state.isSelected ? "#65a15e" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "90%",
  }),
  valueContainer: (provided)=>({
    ...provided,
position:"absolute"
  })
};

export const customStylesForRMS = {
  
  menu: (style) => ({
    ...style,
    width:100,
  }),
  
  control: (style, state) => ({

    ...style,
    boxShadow: "0px 3px 9px #00000029",
    borderRadius: "9px",
    background: state.isDisabled ? "#999" : "f5f6fa",
    backgroundColor:
      // change color when disabled
      state.isDisabled ? "#999" : "#f5f6fa",
    opacity: state.isDisabled ? "0.2" : "1",
    border: "none",
    // minWidth: "inherit",
    margin: "5px 1.5% 0px 0%",
    height: "2.3rem",
    outline: "none",
    width: "100%",
    cursor: "pointer",
  }),

  placeholder: () => ({
    display: "none",
  }),
  clearIndicator: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgb(252, 187, 8)",
    },
    backgroundColor: state.isSelected ? "rgb(252, 187, 8)" : "white",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "999",
    width: "100%",
  }),
  valueContainer: (provided)=>({
    ...provided,
position:"absolute"
  })
};

export const customSearch = {
    control: () => ({
      margin: "0 10px",
      borderRadius: "9px",
      padding: "0 20px 0 0px",
      border: "1px solid rgba(0, 0, 0, 0.192)",
    }),
    placeholder: () => ({
      // display: "none",
      
      marginTop: "-30px",
      color: "#6666",
    }),
    //  option color on hover green
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "rgb(252, 187, 8)",
      },
      backgroundColor: state.isSelected ? "rgb(252, 187, 8)" : "white",
    }),
    // dropdown width
    menu: (provided) => ({
      ...provided,
      width: "92%",
      marginLeft: "0.6rem",
    }),
  };