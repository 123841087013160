import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { commentAPI } from "../api";

const initialState = { comments: {}, status: "idle", error: null };

export const fetchComments = createAsyncThunk("comment/fetchComments", async () => {
    const response = await commentAPI.viewAllComments(1);
    return response.data.rData;
});

export const commentSlice = createSlice({
    name: "comment",
    initialState,
    reducers: {
        addComment: (state, action) => {
            if (state.comments[action.payload.queryId] !== undefined)
                state.comments[action.payload.queryId].unshift({ noteId: action.payload.noteId, viewData: { ...action.payload.viewData } });
            else state.comments[action.payload.queryId] = [{ noteId: action.payload.noteId, viewData: { ...action.payload.viewData } }];
        },
        updateComment: (state, action) => {
            const noteIndex = state[action.payload.queryId].findIndex((elem) => elem.noteId === action.payload.noteId);
            state[action.payload.queryId][noteIndex].viewData = { ...state[action.payload.queryId][noteIndex].viewData, ...action.payload.viewData };
        },
        deleteComment: (state, action) => {
            const noteIndex = state[action.payload.queryId].findIndex((elem) => elem.noteId === action.payload.noteId);
            state[action.payload.queryId].splice(noteIndex, 1);
        },
    },
});

export const selectAllComments = (state) => state.comment.comments;
export const selectCommentsyByQueryId = (state, queryId) => state.query.queries.find((elem) => elem._id === queryId).comments.map((elem) => state.comment.comments[elem]);

export const { addComment, updateComment, deleteComment } = commentSlice.actions;
export default commentSlice.reducer;
