import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import cx from "classnames";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Sidebar from "../../../commonComponent/SideBar";
import Header from "../../../commonComponent/Header";
import styles from "./Confirm.module.css";
import SettingsIcon from "@mui/icons-material/Settings";
import ExtensionIcon from "@mui/icons-material/Extension";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import HomeIcon from "@mui/icons-material/Home";
import SecurityIcon from "@mui/icons-material/Security";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import DvrIcon from "@mui/icons-material/Dvr";
import "react-datepicker/dist/react-datepicker.css";
import SuccessModal from "../../../tablets/component/Modal/SuccessModal";

const Confirm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [department, setDepartment] = useState("");
  const [category, setCategory] = useState("");
  const [plannedEndDate, setPlannedEndDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");
  const [show, setShow] = useState(true);
  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const departmentAssigneeMapping = {
    "Lorem ipsum": "Robert Percival",
    "Ipsum dolor": "Silena Rogers",
    "Dolor sit": "Karl Benz",
    "Sit amet": "Netra Mohan",
    "Amet lorem": "Sashikant Mishra",
  };
  const handleDepartment = (event) => {
    setDepartment(event.target.value);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  const handlePlannedEndDate = (date) => {
    setPlannedEndDate(date);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleNotes = (event) => {
    setNotes(event.target.value);
  };

  return (
    <div className={styles.maincontainer}>
      <Header />
      <div className={styles.mainSection}>
        <Sidebar />
        <div className={styles.container}>
          <div className={cx(styles.rowFlex, styles.spaceBetween)}>
            <div
              className={cx(
                styles.font12,
                styles.fontSemibold,
                styles.colorBlack,
                styles._bredscrum
              )}
            ></div>
          </div>

          <form>
            <div className={styles.buttonRow}>
              <div>
                <button
                  // onClick={() => history.push("/mobile/addmobile")}
                  className={cx(
                    styles.button1,
                    styles.font12,
                    styles.fontMedium,
                    styles.colorWhite,
                    styles._backBTN
                  )}
                >
                  Back
                </button>
              </div>
              <div>
                <button
                  // onClick={() => history.push("/mobile/protection")}
                  className={cx(
                    styles.button,
                    styles.font12,
                    styles.fontMedium,
                    styles.colorWhite
                  )}
                  style={{ cursor: "pointer" }}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className={styles.formContainer}>
              <Container>
                <p style={{ display: "flex" }}>
                  <SettingsIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    General
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Serial Number
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Brand
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Model
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "2%" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      MAC Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <hr className={styles.section} />
                <p style={{ display: "flex", marginTop: "2%" }}>
                  <ExtensionIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    Specifications
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Processor
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      RAM
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Screen Size
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>

                {/* <hr className={styles.section} /> */}
                <hr className={styles.section} />
                <p style={{ display: "flex", marginTop: "2%" }}>
                  <LocalAtmIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    Purchase
                  </span>
                </p>
                <div style={{ display: "flex", marginTop: "2%" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Invoice Number
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Vendor
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Purchase Date
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <hr className={styles.section} />
                <p style={{ display: "flex" }}>
                  <HealthAndSafetyIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    Anti-Virus
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Company
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Start Date
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      End Date
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "2%" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Status
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <hr className={styles.section} />
                <p style={{ display: "flex", marginTop: "2%" }}>
                  <AddModeratorIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    ADP(Accidental Damage Protection)
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Warrantly Period{" "}
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Start Date
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      End Date
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "2%" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Status
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <hr className={styles.section} />
                <p style={{ display: "flex", marginTop: "2%" }}>
                  <HomeIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    Insurance
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Status{" "}
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Expiry
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Vendor
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <hr className={styles.section} />
                <p style={{ display: "flex", marginTop: "2%" }}>
                  <SecurityIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    Warrantly
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Warrantly Period{" "}
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Start Date
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      End Date
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <hr className={styles.section} />
                <p style={{ display: "flex" }}>
                  <LocalAtmIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    Other
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Serial Number
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Device
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Brand
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "2%" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Model
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      status
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Active
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
              </Container>
              {/* </Card> */}
            </div>
          </form>
        </div>
      </div>
      <SuccessModal
        show={show}
        handleShow={handleShow}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Confirm;
