import { useState } from "react";
import style from "./Star.module.css";

const StarRating = ({ feedbackRating, setFeedbackRating, item }) => {
  const [stars, setStars] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [rating, setRating] = useState(null);
  const [hoverd, setHoverd] = useState(0);

  const changeRating = (newRating, key) => {
    setRating(newRating);
    let array = feedbackRating?.filter((item) => {
      return item.fieldName !== key && item.feedbackName !== key;
    });

    array &&
      array.push({
        feedbackName:
          newRating === 11 ? "NS" : newRating === 12 ? "NE" : newRating,
        fieldName: item?.fieldName,
      });
    setFeedbackRating(array);
  };

  const hoverRating = (rating) => {
    setHoverd(rating);
  };

  const circle = (data) => {
    return (
      <div className={style.circleDesign}>
        {data === 11 ? "NS" : data === 12 ? "NE" : data}
      </div>
    );
  };
  const circleClick = (data) => {
    return (
      <div className={style.circleClick}>
        {data === 11 ? "NS" : data === 12 ? "NE" : data}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          width: "32rem",
          display: "flex",
          justifyContent: "space-between",
          padding: "0.3rem 0px",
        }}
      >
        {stars.map((star) => {
          return (
            <span
              style={{
                cursor: "pointer",
                color: "green",
                width: "50rem",
              }}
              onClick={() => {
                changeRating(star, item?.fieldName);
              }}
              onMouseEnter={() => {
                hoverRating(star);
              }}
              onMouseLeave={() => {
                hoverRating(0);
              }}
            >
              {rating !== star ? circle(star) : circleClick(star)}
            </span>
          );
        })}
      </div>
      <p className={style.skillRating}>
        {rating > 10
          ? (rating == 11 && "NS") || (rating == 12 && "NE")
          : rating == null
          ? ""
          : rating < 4
          ? "Poor"
          : rating < 6
          ? "Average"
          : rating < 8
          ? "Good"
          : "Excellent"}
      </p>
    </>
  );
};

export default StarRating;
