import React from "react";
import { Col, Container, Row, Modal, Form } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../ViewPartner/ViewPartner.module.css";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  getViewPartner,
  getOverAllPartnerRating,
  addPartnerRating,
  validateEmployeeAccess,
} from "../../../../Utils/axios/PortalManagement";
import { useMutation, useQuery } from "react-query";
import { useEffect } from "react";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";
import { Toastify, ToastifyError } from "../../../../App.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
const ViewPartner = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPag,
}) => {
  const [show, setShow] = useState(false);
  const [RatingChange, setRatingChange] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    note: "",
    partnerId: prevData?.partnerId,
    partnerRatingId: "",
    reviewerId: Number(localStorage.getItem("employeeId")),
  });

  const { data: PartnerDataById, refetch } = useQuery(
    ["getViewPartner1"],
    () => getViewPartner(prevData?.partnerId),
    {
      onSuccess: (res) => {
        setTimeout(() => {
          refetch();
        }, 1000);
        setPrevData((prevData) => ({
          ...prevData,
          status: res?.statusId,
          specializationExistId: res?.specializationExist,
        }));
      },
    }
  );
  const {
    data: OverAllPartnerRating,
    isLoading,
    isError,
    refetch: RatingRefetch,
  } = useQuery(
    ["getOverAllPartnerRating"],
    () => getOverAllPartnerRating(prevData?.partnerId),
    {}
  );

  const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(localStorage.getItem("employeeId"))),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const getVData =
    getValidateEmp &&
    getValidateEmp?.map((i) => ({
      label: i?.elementCode,
      value: i?.empId,
    }));
  useEffect(() => {
    refetch();
  }, [prevData?.partnerId]);
  const handlerRating = () => {
    setShow(true);
  };

  const data = [
    {
      id: 1,
      Rating: "1",
    },
    {
      id: 2,
      Rating: "2",
    },
    {
      id: 3,
      Rating: "3",
    },
    {
      id: 4,
      Rating: "4",
    },
    {
      id: 5,
      Rating: "5",
    },
  ];
  const HandleRatingColor = (id) => {
    setFormData({
      ...formData,
      partnerRatingId: id,
    });
  };

  const handleRatingChange = () => {
    setRatingChange(!RatingChange);
  };

  const mutation = useMutation(addPartnerRating, {
    onSuccess: (res) => {
      RatingRefetch();
      setFormData((prevValue) => ({
        note: "",
        partnerId: prevData?.partnerId,
        partnerRatingId: "",
        reviewerId: Number(localStorage.getItem("employeeId")),
      }));
    },
  });

  const handleRatingDone = () => {
    if (
      formData?.partnerRatingId === 1 ||
      formData?.partnerRatingId === 2 ||
      formData?.partnerRatingId === 3 ||
      formData?.partnerRatingId === 4 ||
      formData?.partnerRatingId === 5
    ) {
      setRatingChange(false);
      setShow(false);
      mutation.mutate(formData);
    } else {
      ToastifyError("Please Select Rating");
    }
  };
  const handleClose = () => setShow(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <ToastContainer />
      <Container>
        <div>
          <BreadCrum
            prevData={prevData}
            setPrevData={setPrevData}
            setNextScreen={setNextScreen}
          ></BreadCrum>
        </div>
        {OverAllPartnerRating?.currentRating ? (
          <div className={classes.currentRating}>
            <h6>
              <span className={classes.currentRatingView}>Current Rating</span>
              <span
                className={classes.circle}
                onClick={toggleOpen}
                style={{ cursor: "pointer" }}
              >
                {OverAllPartnerRating?.currentRating}
              </span>
            </h6>
          </div>
        ) : (
          ""
        )}

        <InvoiceContainer>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Company Size </p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.companySize}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Address 1</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.addressLine1}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Address 2</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.addressLine2}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Country</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.country}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>City</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>State</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.state}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>PinCode</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.pinCode}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Payment Due Date</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.paymentDueDate &&
                  moment(PartnerDataById?.paymentDueDate).format("DD MMM YY")}
              </p>
            </Col>
            <Col md={6}>
              <p className={classes.label1}>LinkedIn Url</p>
              <a
                href={PartnerDataById?.linkedinLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {" "}
                <p style={{textDecoration:"underLine",color:"blue",paddingLeft:"12px"}}>{PartnerDataById?.linkedinLink}</p>
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContactName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContectEmail}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryMobileNumber}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Secondary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContactName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContectEmail}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyMobileNumber}
              </p>
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>

      <div className={classes.lastBtn_item}>
        {getVData?.some((e) => {
          return e?.label === "867I3038N27051PP";
        }) && (
          <InvoiceButton
            onClick={() => {
              setNextScreen("OnBoarding");
              setPrevData((prevData) => ({
                ...prevData,
                page3: "OnBoarding",
                page2: "ViewPartner",
                active: "OnBoarding",
                OnBoardId: prevData?.partnerId,
              }));
            }}
          >
            On Boarding
          </InvoiceButton>
        )}

        {getVData?.some((e) => {
          return e?.label === "737Y7815S82601NR";
        }) && (
          <InvoiceButton
            onClick={() => {
              setNextScreen("Specialization");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "Specialization",
                page1: "ViewPartner",
                page3: null,
                page4: null,
                active: "Specialization",
              }));
            }}
          >
            Specialisation
          </InvoiceButton>
        )}

        {getVData?.some((e) => {
          return e?.label === "951Z7737U32178WJ";
        }) && (
          <InvoiceButton
            onClick={() => {
              setNextScreen("Agreement");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "Agreement",
                page1: "ViewPartner",
                page3: null,
                page4: null,
                active: "Agreement",
              }));
            }}
          >
            Agreement
          </InvoiceButton>
        )}

        {getVData?.some((e) => {
          return e?.label === "655D2978V59219OP";
        }) && (
          <InvoiceButton
            // type={"cancel"}
            onClick={() => {
              setNextScreen("TDS");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "TDS",
                page1: "ViewPartner",
                page3: null,
                page4: null,
                active: "TDS",
                TDSID: prevData?.partnerId,
              }));
            }}
          >
            TDS
          </InvoiceButton>
        )}

        {getVData?.some((e) => {
          return e?.label === "559J3261U54243TU";
        }) && (
          <InvoiceButton
            onClick={() => {
              handlerRating();
            }}
          >
            Add Rating
          </InvoiceButton>
        )}

        {getVData?.some((e) => {
          return e?.label === "131D1775O71917QR";
        }) && (
          <InvoiceButton
            onClick={() => {
              setNextScreen("EditPartner");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "EditPartner",
                page1: "ViewPartner",
                page3: null,
                page4: null,
                active: "EditPartner",
              }));
            }}
          >
            Edit
          </InvoiceButton>
        )}
      </div>
      {isOpen ? (
        <Container>
          <InvoiceContainer>
            <div className={classes.table}>
              <div className={classes.th}>
                <p className={classes.tableContent}>Rating Date</p>
                <p className={classes.tableContent}>ReViewer Name</p>
                <p className={classes.tableContent}>Rating</p>
                <p className={classes.tableContent}>Notes</p>
              </div>
              <div>
                {isLoading && <p className={classes.no_data}>Loading .....</p>}
                {!isLoading && !isError ? (
                  OverAllPartnerRating?.getAllList?.length > 0 ? (
                    typeof OverAllPartnerRating?.getAllList !== "string" &&
                    OverAllPartnerRating?.getAllList?.map((item, index) => {
                      console.log("item", item);
                      return (
                        <div className={classes.td} key={item?.positionId}>
                          <p className={classes.tableContent}>
                            {moment(item?.ratingDate).format("DD MMM YY")}
                          </p>
                          <p>{item?.reviewerName}</p>
                          <p>{item?.rating}</p>
                          <p>{item?.note}</p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={classes.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </div>
          </InvoiceContainer>
        </Container>
      ) : (
        ""
      )}

      <Modal show={show} centered size="md" onHide={handleClose}>
        <h6 className={classes.TDSRate}>Rating</h6>
        <div className={classes.OverallRating}>
          <p className={classes.RatingOverall}>Overall Rating:</p>
          <p className={classes.ratingValue}>
            {data.map((elem, index) => {
              return (
                <div onClick={() => HandleRatingColor(elem?.id)}>
                  <div
                    key={index}
                    className={
                      formData?.partnerRatingId === index + 1
                        ? classes.circle1
                        : classes.circle2
                    }
                  >
                    {elem.Rating}
                  </div>
                </div>
              );
            })}
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <p className={classes.RatingOverall1}>Skill Rating(1-5):</p>
          <p className={classes.SkillRating}>
            1- Poor, 2- Average, 3- Good, 4- Very Good, 5- Excellent
          </p>
        </div>
        {RatingChange ? (
          <div>
            <p className={classes.addNote}>Add Note</p>
            <Form.Control
              as="textarea"
              rows={7}
              className={classes.RatingTextArea}
              style={{ width: "95%" }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  note: e.target.value,
                });
              }}
            />
          </div>
        ) : (
          ""
        )}

        <div>
          <button
            className={classes.buttonAdd4}
            onClick={() => {
              handleRatingDone();
            }}
          >
            Done
          </button>
          <button
            className={classes.buttonAdd4}
            onClick={() => {
              handleRatingChange();
            }}
          >
            Add Note
          </button>
        </div>

        {/* <style type="text/css">
          {`
      .modal-content {
        width: 73%;
      }
    `}
        </style> */}
      </Modal>
    </>
  );
};
export default ViewPartner;
