import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import Header from "../../../Grievence Portal/Components/Header/Header"
import Sidebar from "../../../Grievence Portal/Components/Sidebar/Sidebar"
import styles from "./Dashboard.module.css";

import { ReactComponent as SpeechBubble } from "../../../../assets/Grievance portal/icon-speech-bubble.svg";
import { ReactComponent as Plus } from "../../../../assets/Grievance portal/icon-plus.svg";
import { ReactComponent as Choice } from "../../../../assets/Grievance portal/icon-choice.svg";

export default function Dashboard() {
    return (
        <div className={styles.maincontainer}>
            <Header />
            <div className={styles.mainSection}>

                <Sidebar />
                <div className={styles.container} >
                    <div>
                        <span className={styles.font14}>{">"} Dashboard</span>
                    </div>
                    <div className={cx(styles.rowFlex, styles.spaceBetween)}>
                        <div className={cx(styles.font21, styles.fontSemibold, styles.colorBlack)}> Dashboard </div>
                    </div>
                    <div className={cx(styles.boxes)}>
                        <Link to="/view-received-tickets" className={styles.box}>
                            <div className={styles.circle}>
                                <Choice fill="#ffffff" />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <span className={cx(styles.font48, styles.fontSemibold, styles.colorShamrock)}>20</span>
                                <span className={cx(styles.font30, styles.fontSemibold)}>
                                    Tickets Assigned to me
                                </span>
                            </div>
                        </Link>
                        <Link to="/view-sent-tickets" className={styles.box}>
                            <div className={styles.circle}>
                                <SpeechBubble fill="#ffffff" />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <span className={cx(styles.font48, styles.fontSemibold, styles.colorShamrock)}>15</span>
                                <span className={cx(styles.font30, styles.fontSemibold, styles.colorBlack)} style={{ opacity: "0.6", color: "#000000" }}>
                                    Tickets Raised by me
                                </span>
                            </div>
                        </Link>
                        <Link to="/Grievance-Portal/RaiseTicket" className={styles.box}>
                            <div className={styles.circle}>
                                <Plus fill="#ffffff" />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <span className={cx(styles.font30, styles.fontSemibold, styles.colorBlack)} style={{ opacity: "0.6", color: "#41CBB6" }}>
                                    Raise A Ticket
                                </span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
