import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal, Button, Form } from "react-bootstrap";
import style from "../ViewTeamTimeSheet/ViewTeamTimeSheet.module.css";
import { useMutation, useQuery } from "react-query";
import PlBtn from "../../CommonComponent/Button/PlBtn";
import {
  plapproved,
  plapproved1,
  ViewTeamTimeSheetBYID
} from "../../../../Utils/axios/NewTimeSheetApi";
import axios from "axios";
import moment from "moment";
const ViewTeamTimeSheet = ({ prevData, setPrevData ,setNextScreen}) => {
  const [viewData, setViewData] = useState();
  const [TextData, setTextData] = useState();
  const apiKey = process.env.REACT_APP_NEW_TIMESHEET_URL;

  // }
  useEffect(() => {
    const url = `${apiKey}/view-timesheet-by-id?timesheetId=${prevData?.timeId}`;
    axios
      .get(url)
      .then((res) => {
        setViewData(res?.data?.responseData);
      })
      .catch((err) => {});
  }, []);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const { mutate: plapprovedID } = useMutation(plapproved, {
    onSuccess: (res) => {
      console.log("res", res);
      setPrevData((prevData) => ({
        ...prevData,
        message: res?.message,
      }));
    },
  });


  const handleApproved = () => {
    setShow(false);
    plapprovedID(prevData?.timeId);
    setNextScreen("TeamTimeSheets")
  };

  const handleApprovedCancle = () => {
    setShow(false);
  };
  const { mutate: plapprovedData } = useMutation(plapproved1);

  const handleNotApproved = () => {
    setShow1(false);
    plapprovedData({ timeid: prevData?.timeId, Textid: TextData });
    setNextScreen("TeamTimeSheets") 
  };

  const handleNotApprovedCancel = () => {
    setShow1(false);
  };

  const handleShow = () => setShow(true);

  const handleShow1 = () => setShow1(true);
  console.log("text", TextData);

  return (
    <>
      <Container>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <span className={style.ViewTeamTask}>
              View TeamTimeSheet Details
            </span>
          </Col>
        </Row>
      </Container>

      <div className={style.DetailTeamView}>
        <p className={style.ViewTeamEmployeeDetails}>Task Details</p>
        <Container fluid style={{ paddingBottom: "20px" }}>
          <Row>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Project Name</h6>
              <p>{viewData?.projectName}</p>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Task Start Date</h6>
              {moment(viewData?.taskDate).format("DD MMM YY")}
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Time Spent(In Hr/Min)</h6>
              <p>{viewData?.timeSpent}</p>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Task Type</h6>
              <p>{viewData?.taskType}</p>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Task Summary</h6>
              <p>{viewData?.taskSummary}</p>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>API Name</h6>
              <p>{viewData?.apiname}</p>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Screen Name</h6>
              <p>{viewData?.screenName}</p>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Defect Number</h6>
              <p>{viewData?.defectNumber}</p>
            </Col>{" "}
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Feature Name</h6>
              <p>{viewData?.featureName}</p>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>PL Approved</h6>
              <p>{viewData?.plApproved}</p>
            </Col>
            <Col md={3} style={{ marginTop: "15px" }}>
              <h6>Remark</h6>
              <p>{viewData?.remarks}</p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={style.PlBtn}>
        <PlBtn
          disabled={prevData?.plApproved === "Approved" ? true : false}
          onClick={handleShow}
        >
          Approve
        </PlBtn>

        <PlBtn
          type={"cancel"}
          disabled={prevData?.plApproved === "Not Approved" ? true : false}
          onClick={handleShow1}
        >
          Not Approved
        </PlBtn>
      </div>
      <Modal show={show1}>
        <h6 style={{ margin: "20px 10px", fontSize: "18px" }}>Remark/Reason</h6>
        <Form.Control
          as="textarea"
          rows={3}
          style={{ width: "95%", marginLeft: "12px" }}
          onChange={(e) => {
            setTextData(e.target.value);
          }}
        />
        <button className={style.buttonAdd} onClick={handleNotApproved}>
          OK
        </button>
        <button
          className={style.buttonCancle}
          onClick={handleNotApprovedCancel}
        >
          Cancel
        </button>
      </Modal>

      <Modal show={show}>
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to approve.
        </h6>
        <button className={style.buttonAdd} onClick={handleApproved}>
          Yes
        </button>
        <button className={style.buttonCancle} onClick={handleApprovedCancle}>
          No
        </button>
      </Modal>
    </>
  );
};
export default ViewTeamTimeSheet;
