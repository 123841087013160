import React from "react";
import { Route, Redirect } from "react-router-dom";
import cookie from "react-cookies";

export function ProtectedRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        cookie.load("token") ? children : <Redirect to="/" />
      }

      //  (localStorage.getItem("token") ? children : <Redirect to="/" />)}
    />
  );
}
