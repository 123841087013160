import React from "react";
import cx from "classnames";

import { ReactComponent as ErrorIcon } from "../../../../assets/Grievance portal/icon-error-404.svg";

import styles from "./Error.module.css";

const Error = (props) => {
    return (
        <div className={styles.container}>
            <div className={cx(styles.escalateView, styles.colFlex, styles.centered)}>
                <ErrorIcon style={{ padding: "0.5cem", width: "8em" }} fill="#cbc9c9" />
                <span className={cx(styles.font21, styles.fontSemibold, styles.colorOceanGray)}>{props.message}</span>
            </div>
        </div>
    );
};

export default Error;
