import React, { useState } from "react";
import cx from "classnames";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import styles from "./Protection.module.css";
import HomeIcon from "@mui/icons-material/Home";
import SecurityIcon from "@mui/icons-material/Security";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import "react-datepicker/dist/react-datepicker.css";

const Protection = () => {
  return (
    <div>
      <Container>
        <p style={{ display: "flex" }}>
          <HealthAndSafetyIcon color="disabled" fontSize="large" />
          <span
            className={cx(
              styles.font116,
              styles.fontMedium,
              styles.colorBlack,
              styles.labelOpacity,
              styles.heading
            )}
          >
            {" "}
            Anti-Virus
          </span>
        </p>
        <div style={{ display: "flex" }}>
          <div>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              {" "}
              Company
            </label>{" "}
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
          <div className={styles.values}>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              Start Date
            </label>{" "}
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
          <div className={styles.values}>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              End Date
            </label>{" "}
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "2%" }}>
          <div>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              Status
            </label>{" "}
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
        </div>
        <hr className={styles.section} />
        <p style={{ display: "flex", marginTop: "2%" }}>
          <AddModeratorIcon color="disabled" fontSize="large" />
          <span
            className={cx(
              styles.font116,
              styles.fontMedium,
              styles.colorBlack,
              styles.labelOpacity,
              styles.heading
            )}
          >
            {" "}
            ADP(Accidental Damage Protection)
          </span>
        </p>
        <div style={{ display: "flex" }}>
          <div>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              Warrantly Period{" "}
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
          <div className={styles.values}>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              {" "}
              Start Date
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
          <div className={styles.values}>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              {" "}
              End Date
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
        </div>
        <div style={{ display: "flex", marginTop: "2%" }}>
          <div>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              {" "}
              Status
            </label>{" "}
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
        </div>
        <hr className={styles.section} />
        <p style={{ display: "flex", marginTop: "2%" }}>
          <HomeIcon color="disabled" fontSize="large" />
          <span
            className={cx(
              styles.font116,
              styles.fontMedium,
              styles.colorBlack,
              styles.labelOpacity,
              styles.heading
            )}
          >
            {" "}
            Insurance
          </span>
        </p>
        <div style={{ display: "flex" }}>
          <div>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              Status{" "}
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
          <div className={styles.values}>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              {" "}
              Expiry
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
          <div className={styles.values}>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              {" "}
              Vendor
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
        </div>
        <hr className={styles.section} />
        <p style={{ display: "flex", marginTop: "2%" }}>
          <SecurityIcon color="disabled" fontSize="large" />
          <span
            className={cx(
              styles.font116,
              styles.fontMedium,
              styles.colorBlack,
              styles.labelOpacity,
              styles.heading
            )}
          >
            {" "}
            Warrantly
          </span>
        </p>
        <div style={{ display: "flex" }}>
          <div>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              Warrantly Period{" "}
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
          <div className={styles.values}>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              {" "}
              Start Date
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
          <div className={styles.values}>
            <label
              className={cx(
                styles.font12,
                styles.fontMedium,
                styles.colorBlack,
                styles.labelOpacity
              )}
            >
              {" "}
              End Date
            </label>
            <br />
            <input
              type="text"
              id="fname"
              name="fname"
              className={styles._input}
            ></input>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Protection;
