import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "../../../Clients/component/SideBar";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import style from "./PoolRecordData.module.css";
import SubjectIcon from '@mui/icons-material/Subject';
import AppsIcon from '@mui/icons-material/Apps';
import { Tooltip } from "@material-ui/core";
import constants from "../../../../../Utils/constants";
import PoolReportData from "../PoolReportData/PoolReportData";

function PoolRecordData(props) {
  const history = useHistory();
  const[loading,setLoading]=useState(true);
  const[pagehandle,setpagehandle]=useState(1)
  const [selectedProjectId, setSelectedProjectid] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [projectData, setProjectData] = useState();
  const [clientListing, setClientListing] = useState();
  const [employee, setEmployee] = useState();
  const [Allselected, setAllselected] = useState(true);
  const [listSelected, setListSelect] = useState(props.location.pathname==="/Pool"?true:false);
  const baseUrl = constants.apiBaseUrlResource;
  const url = process.env.REACT_APP_UMS_APP_URL;

  const [selectedValue, setSelectedValue] = useState("Pool");
  // radio button handler
  function handleOptionChange(event) {
    setSelectedValue(event.target.value);
  }

  function getAllOffshoreData(clientId, projectId) {
    axios
      .get(
        baseUrl +
          `/getAllPoolDataByTechnologyFromClientOrProjectId?clientId=${clientId}&projectId=${projectId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setEmployee(response.data.responseData);
        // Do something with the API response data
      })
      .catch((error) => {
        console.error(error);
        // Handle any API errors
      });
  }

  useEffect(() => {
    axios
      .get(baseUrl + "/getAllActiveProjectsOfPool", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setProjectData(res.data.responseData);
        // getAllOffshoreData(selectedClient, res.data.responseData[0].id);
        // setSelectedProjectid(res.data.responseData[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(baseUrl + "/getAllActiveClientsOfPool", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // project handler
  const handleProjectClick = (projectid) => {
    setSelectedProjectid(projectid);
    setpagehandle(2)
    setSelectedClient("");
    if(projectid===""){
        setAllselected(false)
    }
    
  };

  // client Handler

  const handleClientClick = (clientId) => {
    setSelectedClient(clientId);
    setpagehandle(2)
    setSelectedProjectid("");
if(clientId===""){
    setAllselected(true)
}
  };

  useEffect(() => {
    getAllOffshoreData(selectedClient, selectedProjectId);
  }, [selectedProjectId, selectedClient]);

  useEffect(() => {
    if(selectedValue!=="Pool"){
      ReportHandler();
    }
  }, [selectedValue]);

  // count total project and client
  let total1 = 0;
  projectData?.forEach((project) => {
    total1 += project?.count;
  });
  let total2 = 0;
  clientListing?.forEach((project) => {
    total2 += project?.count;
  });

  const ListingHandler = () => {
    setListSelect(true);
  };

  const ReportHandler1 = () => {
    setListSelect(false);
  };  

    // Report Handler
    const ReportHandler = () => {
      console.log(selectedValue); // add this line to check the value of selectedValue
      switch (selectedValue) {
        case "Pool":
          history.push("/PoolRecord");
          break;
        case "OffShore":
          history.push("/OffShoreRecord");
          break;
        case "OnSite":
          history.push("/OnSiteRecord");
          break;
        default:
          // Code to handle any other location not covered by the previous cases
          break;
      }
    };
  return (
    <>
      {/* <Header /> */}
      <div className={style.main_client_body}>
        <SideBar />
        {loading?<p className={style.loadingP}>Loading...</p>:
        <div className={style.secondDiv}>
          <div className={style.iconset}>
            <p style={{ margin: 0 ,width:"20%"}}>
              Pool Resources
            </p>
            <label>
              <input
                type="radio"
                name="location"
                value="OffShore"
                checked={selectedValue === "OffShore"}
                onChange={handleOptionChange}
              />
              Offshore
            </label>
            <label>
              <input
                type="radio"
                name="location"
                value="OnSite"
                checked={selectedValue === "OnSite"}
                onChange={handleOptionChange}
              />
              Onsite
            </label>

            <label>
              <input
                type="radio"
                name="location"
                value="Pool"
                checked={selectedValue === "Pool"}
                onChange={handleOptionChange}
              />
              Pool
            </label>
            <div>
            <Tooltip title="Report" placement="bottom">
              <AppsIcon className={listSelected?style.Icon:style.Icon1} onClick={ReportHandler1} />
              </Tooltip>{" "}
              <Tooltip title="Listing" placement="bottom">
              <SubjectIcon
                className={listSelected?style.Icon1:style.Icon}
                onClick={() => ListingHandler()}
              /></Tooltip>
            </div>
          </div>
          <p className={style.projectHeading}>Project List</p>
          {projectData ? (
            <div className={style.buttonContainer}>
              <button
                className={
                  "" === selectedProjectId && ""===selectedClient ? style.button : style.activeButton
                }
                onClick={() => handleProjectClick("")}
              >
                ALL
                <span
                  className={
                    "" === selectedProjectId && ""===selectedClient ? style.count1 : style.count2
                  }
                >
                  {total1}
                </span>
              </button>
              {projectData?.map((project) => (
                <button
                  key={project.id}
                  // style={{ width: `${project.name.length}em` }}
                  className={
                    project.id === selectedProjectId
                      ? style.button
                      : style.activeButton
                  }
                  onClick={() => handleProjectClick(project.id)}
                >
                  {project.name}
                  <span
                    className={
                      project.id === selectedProjectId
                        ? style.count1
                        : style.count2
                    }
                  >
                    {project?.count}
                  </span>
                </button>
              ))}
            </div>
          ) : (
            <p className={style.noRecordPara}>No Project Found</p>
          )}
          <p className={style.projectHeading}>Client List</p>
          {employee ? (
            <div className={style.buttonContainer}>
              <button
                className={
                  "" === selectedClient && ""===selectedProjectId ? style.button : style.activeButton
                }
                onClick={() => handleProjectClick("")}
              >
                ALL
                <span
                  className={
                    "" === selectedClient && ""===selectedProjectId ? style.count1 : style.count2
                  }
                >
                  {total2}
                </span>
              </button>
              {clientListing?.map((client) => (
                <button
                  key={client.id}
                  className={
                    client.id === selectedClient
                      ? style.button
                      : style.activeButton
                  }
                  onClick={() => handleClientClick(client.id)}
                >
                  {client.name}
                  <span
                    className={
                      client.id === selectedClient ? style.count1 : style.count2
                    }
                  >
                    {client?.count}
                  </span>
                </button>
              ))}
            </div>
          ) : (
            <p className={style.noRecordPara}>No Client Found</p>
          )}

          { listSelected? (<PoolReportData  client={selectedClient} project={selectedProjectId} page={pagehandle} /> ):(<div style={{ display: "flex", flexWrap: "wrap" }}>
            {employee &&
              employee.map((data) => {
                return (
                  <div style={{ width:"99%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className={style.projectHeading}>{data?.techName}</p>
                      <span className={style.count} style={{ flexShrink: 0 }}>
                        {data?.details?.length}
                      </span>
                    </div>
                    <div key={data.techName} className={style.div}>
                      {data?.details && (
                        <table>
                          <thead className={style.thead}>
                            <tr style={{ fontSize: "12px" }}>
                              <th className={style.th}>Name</th>
                              <th className={style.th}>Exp</th>
                              <th className={style.th}>Salary Range</th>
                              
                                <th className={style.th}>Project/Client Name</th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            {data?.details?.map((emp) => (
                              <tr
                                key={emp.name}
                                style={{ borderBottom: "1px solid #ebe5e5",height:"2.3rem" }}
                              >
                                <td className={style.td1}>{emp.empName}</td>
                                <td className={style.td2}>
                                  {~~(emp.experience / 12)} Y -{" "}
                                  {emp.experience % 12} M
                                </td>
                                <td
                                className={style.td3}
                                  style={
                                    emp.salaryRange === "Very High"
                                      ? { color: "red" }
                                      : null
                                  }
                                >
                                  {emp.salaryRange}
                                </td>
                                <td className={style.th}>
                                  <div>
                                    {
                                    emp.projects.length !== 0 ? (
                                      <p>
                                        {emp.projects[0].projectName}{" "}
                                        <span style={{ color: "rgb(252, 106, 8)",paddingLeft:"15px",paddingRight:"5px" }}>O</span>
                                      </p>
                                    ) : 
                                      emp.clientName !== null ? (
                                      <p>
                                        {emp.clientName}{" "}
                                        <span style={{ color: "blue",paddingLeft:"15px",paddingRight:"5px"}}>C</span>
                                      </p>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>)}
         
        </div>}
      </div>
    </>
  );
}

export default PoolRecordData;
