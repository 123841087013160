import React from "react";
import { Link } from "react-router-dom";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "../../../../../assets/editIcon.png"
// import EditIcon from "../../../assets/editIcon.png"
import { useHistory } from "react-router-dom";
import { textAlign } from "@mui/system";
import Moment from 'moment';

//{props.allocation=="Half"?<td style={{width:"12%",textAlign:"center"}}>50% </td>:props.allocation=="ThreeFourth"?<td style={{width:"12%",textAlign:"center"}}>75% </td>:props.allocation=="OneFourth"?<td style={{width:"12%",textAlign:"center"}}>25% </td>:<td style={{width:"12%",textAlign:"center"}}>100% </td>}
const TableRow = (props) => {
  const history = useHistory();
  return (
    <tr style={{ borderBottom: "2px solid #F0F0F0", textAlign: "start" }}>
      
        <td style={{ padding: "7px 0px", textTransform: "capitalize",fontWeight:"bolder",color:"rgb(252, 187, 8)" ,width:"15%"}}>
        {Moment(props.modifiedDate).format("DD MMM YY")}
        </td>
      <td style={{ width: "20%" }}>{props.changeBy}</td>
      <td style={{ width: "20%" }}>{props.changeReqBy}</td>
      <td style={{ width: "20%" }}>{props.fields}</td>
      <td style={{ width: "25%" }}>{props.changedDescription.replace(/null/g, "")}</td>
      
    </tr>
  );
};

export default TableRow;

{
  /* <button style={{ color: state === "active" ? "#F00" : "#00F" }}> */
}
