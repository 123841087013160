import React, { useEffect, useState } from "react";
import style from "./ProjectDetails.module.css";
import { Table } from "react-bootstrap";
import TableRow from "./TableRow";
import SideBar from "../Clients/component/SideBar";
import cookie from "react-cookies";
import constants from "../../../Utils/constants";
import FilterListIcon from "@mui/icons-material/FilterList";
import axios from "axios";
import { Link } from "react-router-dom";
import rightTickPRM from "../../../assets/rightTickPRM.png";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import Pagination from "../CommonComponent/Pagination";
import Filter from "../components/Filter";
import milestone from "../../../assets/milestone.png";
import loaderImg from "../../../assets/loader.gif";

export default function ProjectDetails() {
  const baseUrl = constants.apiBaseUrlResource;
  const [projectData, setProjectsList] = useState([]);
  const [projectCategoryForFilter, setProjectCategoryForFilter] = useState([]);
  const [projectForFilter, setProjectForFilter] = useState([]);
  const [clientForFilter, setClientForFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProject, settotalProject] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [applied, setApplied] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [projectCategory, setProjectCategory] = useState([]);
  const [client, setClient] = useState([]);
  const [project, setProject] = useState();
  const [filterName, setFilterName] = useState({});
  const [allData, setAllData] = useState({
    catogaryprojectId: [],
    clientId: [],
    statusTypeId: [],
    projectId: [],
    searchName: "",
  });

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const [FilterData] = useState({
    catogaryprojectId: [],
    clientId: [],
    projectId: [],
    statusTypeId: [],
  });

  projectForFilter?.push(...clientForFilter);

  const getClientForFilter = () => {
    axios
      .get(baseUrl + "/getClientDataForFilterOfProject", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log("data", res.data.responseData);
        setClientForFilter(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectTypeForFilter = () => {
    axios
      .get(baseUrl + "/getProjectTypes", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setProjectCategoryForFilter(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectForFilter = () => {
    axios
      .get(baseUrl + "/getProjectforFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log("data", res.data.responseData);
        setProjectForFilter(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectData = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length === 0 ? clearData : allData;
    setIsLoaded(true);
    axios
      .post(
        baseUrl + "/getProjects?" + "limit=" + limit + "&page=" + currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setProjectsList(res.data.responseData);
        settotalProject(res.data.responseData.totalproject);
        setpageCount(res.data.responseData.totalproject / 10);
      })
      .catch((err) => {
        setIsLoaded(false);
        console.log(err);
      });
  };

  const limit = 10;
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const onSearchHandler = (e, option) => {
    console.log(e.target.value, "ee");
    if (e || e === null) {
      switch (option) {
        case "Name":
          setAllData({ ...allData, searchName: e.target.value });
          setFilterName({ ...filterName, positionCode: e.target.value });
          break;
        default:
      }
    }
  };

  const closeFilter = () => {
    setCurrentPage(1)
    setAllData((preValue) => {
      return {
        ...preValue,
        catogaryprojectId: [],
        clientId: [],
        projectId: [],
        statusTypeId: [],
      };
    });
    getProjectData(FilterData, 1);
    setProjectCategory([]);
    setClient([]);
    setProject([]);
    setShowFilter(false);
    setApplied(false);
  };

  const handleApplied = (arg) => {
    if (
      projectCategory?.length !== 0 ||
      client?.length !== 0 
      // project?.length !== 0
    ) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1);
    getProjectData(allData, 1);
  };

  useEffect(() => {
    getProjectData(allData, currentPage);
    getProjectTypeForFilter();
    getClientForFilter();
    getProjectForFilter();
  }, [currentPage, allData]);

  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />
        {/* Table Div Content */}
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
           
            </div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "700", width: "25%" }}
              >
                Projects
              </div>
              <div style={{ width: "31%", paddingLeft: "14%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img className={style.appliedcheck} src={rightTickPRM} />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>
              <div
                className={style.s1}
                style={{ width: "27%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.searchName?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        searchName: "",
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <input
                  placeholder="Client/Project Name"
                  value={allData.searchName}
                  onChange={(e) => {
                    onSearchHandler(e, "Name");
                    setCurrentPage(1);
                    setShowFilter(false);
                  }}
                  className={style.coreSkillInput}
                />
              </div>
              <div className={style.btn_add}>
                <Link to="/projects" style={{ textDecoration: "none" }}>
                  <button className={style.create_btn}>
                    <span className={style.plus}>+</span>Create Project
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.original_table}
            style={{ backgroundColor: "white", minHeight: "27.5rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : projectData.projects?.length>0 ? (
              <Table borderless size="sm" style={{ fontSize: "12px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "150px" }}>
                    <th style={{ padding: "25px" }}>Client Name</th>
                    <th style={{ padding: "25px" }}>Project Name</th>
                    <th style={{ padding: "25px" }}>PM/PC</th>
                    <th style={{ padding: "25px" }}>Start Date</th>
                    <th style={{ padding: "25px" }}>Type of Project</th>
                    <th style={{ padding: "25px" }}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {projectData?.projects.map((val) => {
                    return (
                      <TableRow
                        id={val.projectid}
                        name={val.clientName}
                        projectName={val.projectName}
                        startDate={val.startdate}
                        typeOfProject={val.catogaryprojectName}
                        statusTypeName={val.statusTypeName}
                        empName={val.empName}
                        holdDate={val.holdDate}
                        enddate={val.enddate}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : <div className={style.noData}>
                 <img src={milestone} alt="milestone" />
                  <h3 className={style.noData_header}>
                    No data available
                  </h3>
                </div>}
            
                
              
          </div>
          {/* <Link to="/clientdescription">Click</Link> */}
          {totalProject > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"project"}
          clientListProject={clientForFilter}
          projectList={projectForFilter}
          projectCategoryList={projectCategoryForFilter}
          setProject={setProject}
          setClient={setClient}
          setProjectCategory={setProjectCategory}
        />
      )}
    </>
  );
}
