import React, { useEffect, useState } from "react";
import SideBar from "../component/SideBar";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./Clientdescription.module.css";
import constants from "../../../../Utils/constants";
import ConfirmPopup from "./ConfirmPopup";


const Clientdescription = () => {
  const [clientData, setClientData] = useState([]);
  const[disable,setDisable]=useState(false)
  const [confirmModal, setConfirmModal] = useState(false);
  const baseUrl = constants.apiBaseUrlResource;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }


  function handleConfirmPopupClose() {
    setConfirmModal(false);
  }
  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();

  const editClient = () => {
    history.push(`/EditClient?id=${id}`)
  }
  
  const deleteClientPopup=()=>{
    setConfirmModal(true)
  }
  const deleteClient = (id) => {
    setConfirmModal(true)
    axios
      .delete(baseUrl + `/deleteClient?clientid=${id}`,{
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
        // clientID: id,
        // status: updateStatus,
      })
      .then((res) => {
        console.log("Change Status", res)
        // getClientData();
        notify1();
        handleRedirect();
        setDisable(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatus = (id, status) => {
    let updateStatus = "";
    if (status === "In_Active") {
      updateStatus = "Active"
    } else {
      updateStatus = status
    }
    const responseData = {
      "clientID": id,
      "status": updateStatus,
    }
    console.log({ responseData })
    axios
      .post(baseUrl + "/changeClientStatus", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
        clientID: id,
        statusTypeId:1,
      })
      .then((res) => {
        console.log("Change Status", res)
        // getClientData();
        notify();
        handleRedirect();
        setDisable(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatuss = (id, status) => {
    console.log({id,status})
    let updateStatus = "";
    
   if (status === "Active") {
      updateStatus = "In_Active"
    } else {
      updateStatus = status
    }
    const responseData = {
      "clientID": id,
      // "status": updateStatus,
    }
    axios
      .post(baseUrl + "/changeClientStatus", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
        clientID: id,
        statusTypeId:clientData?.statusId==1?2:1
      
      })
      .then((res) => {
        console.log("Change Status", res)
        notify();
        handleRedirect();
        setDisable(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getClientDetails = (id) => {
    axios
      .get(baseUrl + `/getClientDetails?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data.responseData)
        setClientData(res.data.responseData)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getClientDetails(id)
  }, []);


  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/clients");
    }, 2000);
  };

  const notify = () =>
    toast("Status Changed Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

    const notify1 = () =>
    toast("Client deleted successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.center_div}>
          <div className={style.breadscrumb} style={{ padding: "10px 0px" }}>
            <span  style={{fontWeight:"700"}}>
              View <span className={style.arrow}> </span>Client
              <span className={style.arrow}> {">"} </span>
              <span className={style.recent_tab} style={{fontWeight:"700"}}>
                {clientData?.name}
              </span>
            </span>
          </div>

          <div className={style.description_box}>
            <div className="container">
              <div className="row">
                <div className="col-3" style={{width:"40%"}}>
                  <h6>Client Name</h6>
                  <p style={{textTransform: "capitalize" }}>
                    {clientData?.name}
                  </p>
                </div>
                <div className="col-3" style={{marginLeft:"35px"}}>
                  <h6>Client Domain</h6>
                  <p style={{ textTransform: "capitalize" }}>
                    {clientData?.domain}
                  </p>
                </div>
              </div>    
            </div>
          </div>
          <div className={style.container}>
          {clientData?.status==="Active"?
          <button
              className={style.inActive_btn}
              onClick={()=>changeStatuss(clientData.clientid,"Active")}
              disabled={disable}>Mark as Inactive</button> :
          <button
              className={style.active_btn}
              onClick={()=>changeStatus(clientData.clientid,"In_Active")}
              disabled={disable} >Mark as Active</button>}
             <button
              className={style.delete_btn}
              onClick={()=>deleteClientPopup()}
              disabled={disable}>Delete</button>
            <button
              className={style.edit_btn}
              onClick={editClient}
              disabled={disable}
            >Edit</button>
          </div>
        </div>
        <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to delete?
          </h5>
          <div className={style.btnCont}>
            <button className={style.yesBtn}
            onClick={()=>deleteClient(clientData.clientid)}
             >
              Yes
            </button>
            <button className={style.noBtn} onClick={handleConfirmPopupClose}>
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    
        <ToastContainer />
      </div>
    </>
  );
};

export default Clientdescription;
