import React from 'react';
import style from './Header.module.css';


const Header = () => {

  return (
    <div>
    
    </div>

  );
}

export default Header;