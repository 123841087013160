import axios from "axios";
import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import Sidebar from "../../../Clients/component/SideBar";
import Style from "./EditProject.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import calendar from "../../../../../assets/Grievance portal/dateldpi.png";
import constants from "../../../../../Utils/constants";
import { customStyles } from "../../../Clients/component/style";

export default function EditProject() {
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref} style={{ fontSize: "15px", fontWeight: 500 }}>
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "13%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const [projectData, setProjectData] = useState([]);
  const[employee,seEmployeeList]=useState([])
  

  const [state, setState] = useState({
    projectDescription: "",
    name: "",
    catogaryproject: 0,
    startDate: "",
    clientId: 0,
    projectId: 0,
    clientName: "",
    typeOfProject: "",
    pmpc:"",
    empId:0
  });


  const initialValue = {
    clientName: "",
    projectName: "",
    startDate: "",
    TypeOfProject: "",
    Description: "",
  };
  const [formValue, setFormValue] = useState(initialValue);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disable, setDisable] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const id = query.get("id");

  const history = useHistory();

  const [clientData, setClientData] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [ProjectTyepe, setProjectType] = useState([]);
  const [proRequired, setProRequired] = useState(false);

  const baseUrl = constants.apiBaseUrlResource;
  const url = constants.apiBaseUrl;
  const getEmployeeList = () => {
    axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        seEmployeeList(res.data.responseData.employeeList);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };


  const getClientData = () => {
    axios
      .get(baseUrl + "/getClientFilterData?Status=Active", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setClientData(res.data.responseData.clientFilterDataResponse);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectDetails = (id) => {
    axios
      .get(baseUrl + `/getProjectDetails?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setProjectData(res.data.responseData);
        console.log(res.data.responseData);
        setState({
          projectDescription: res.data.responseData.projectdescription,
          name: res.data.responseData.name,
          catogaryproject: res.data.responseData.catogaryprojectId,
          startDate: res.data.responseData.startdate,
          clientId: res.data.responseData.clientid,
          projectId: res.data.responseData.projectId,
          clientName: res.data.responseData.clientName,
          typeOfProject: res.data.responseData.typeofproject,
          pmpc:res.data.responseData.pmpc,
          empId:res.data.responseData.pmId
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log({ state });
  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/ProjectDetails");
    }, 2000);
  };

  const updateProject = (e) => {
    e.preventDefault();
    if (state.name) {
      setProRequired(false);
    } else {
      setProRequired(true);
    }

    setFormErrors(validate(formValue));
    setIsSubmit(true);

    const data = {
      catogaryproject: state.catogaryproject,
      name: state.name.trim(),
      projectDescription: state.projectDescription.trim(),
      projectId: state.projectId,
      empId:state.empId,
      startDate: Moment(state.startDate).format("YYYY-MM-DD hh:mm"),
      clientId: state.clientId,
    };
    axios
      .put(baseUrl + "/editProject", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus.statusCode === 1) {
          return;
        } else {
          setDisable(true);
          notify();
          handleRedirect();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getApplicationType = () => {
    axios
      .get(baseUrl + "/getApplications", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setApplicationList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log(formErrors)
    if (Object.keys(formErrors).length === 0 && isSubmit)
      console.log(formValue);
      
  }, [formErrors]);

  const getProjectType = () => {
    axios
      .get(baseUrl + "/getProjectTypes", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setProjectType(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClientData();
    getApplicationType();
    getProjectType();
    getEmployeeList();
    getProjectDetails(id);
  }, []);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });

    if (e.target.name === "fname") {
      setProRequired(false);
      setState({
        ...state,
        name: e.target.value,
      });
    } else if (e.target.name === "description") {
      setState({
        ...state,
        projectDescription: e.target.value,
      });
    } else if (e.target.name === "projectType") {
      setState({
        ...state,
        catogaryproject: e.target.value,
      });
    } else if (e.target.name === "clientData") {
      setState({
        ...state,
        clientId: e.target.value,
      });
    } else if (e.target.name === "date") {
      setState({
        ...state,
        startDate: e.target.value,
      });
    }
  };

  const changeDate = (date) => {
    setState({
      ...state,
      startDate: Moment(date).format("D MMM YY"),
    });
  };

  const validate = (value) => {
    const error = {};
    if (!value.clientData) {
      error.clientName = "Please Select Client ";
    }
    if (!value.fname) {
      error.projectName = "Please Enter Project Name";
    }
    if (!value.date) {
      error.startDate = "Please Select Start Date";
    }
    if (!value.projectType) {
      error.TypeOfProject = "Please Select Type of Project";
    }

    return error;
  };

  const getemployee = employee?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const notify = () =>
    toast("Data Submitted Successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  return (
    <>

      <div className={Style.projectresource_mainSection}>
        <Sidebar />
        {/* tHIS IS Sidebar */}
        <div className={Style.projectresource_mainsection}>
          <div
            style={{
              padding: "23px 0px",
              fontWeight: "700",
              marginTop: "-27px",
            }}
          >
            {" "}
            Edit Project
          </div>
          {/* main contet */}
          <div className={Style.maindiv}>
            <div
              className={Style.maindivContent}
              // style={{ borderBottom: "2px solid #F5F6FA " }}
            >
              <div>
                <p className={Style.span1}>Project Information</p>
              </div>
            </div>

            <form onSubmit={updateProject}>
              <div className={Style.container} style={{ marginTop: "2em" }}>
                <div className={Style.colum}>
                  <p className={Style.clients}>
                    Clients <span style={{ color: "red" }}>*</span>
                  </p>
                  <select
                    onChange={onInputChange}
                    name="clientData"
                    className={Style.input}
                    required
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please Enter Clietn Name")
                    }
                  >
                    <option hidden>{state.clientName}</option>
                    {clientData.map((val) => {
                      return <option value={val.clientid}>{val?.name}</option>;
                    })}
                  </select>
                </div>
                <div className={Style.colum}>
                  <p className={Style.clients}>
                    Project Name <span style={{ color: "red" }}>*</span>
                  </p>
                  <input
                    autoComplete="off"
                    className={Style.input}
                    type="text"
                    id="fname"
                    name="fname"
                    value={state.name}
                    onChange={onInputChange}
                    // required
                    // onInvalid={(e)=>e.target.setCustomValidity('Please Enter Project Name')}
                  />
                  {proRequired ? (
                    <p className={Style.error}>Please Enter Project Name</p>
                  ) : null}
                </div>

                <div className={Style.colum}>
                  <p className={Style.clients}>
                    PM/PC <span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                styles={customStyles}
                classNamePrefix={"create_position"}
                placeholder="Search By Client Name"
                value={
                  getemployee?.find(
                    (i) => i.label === state.pmpc
                  )
                }
                components={{
                  DropdownIndicator: () =>null,
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setState({
                    ...state,
                    empId: e.value,
                    pmpc:e.label
                  });
                }}
                options={getemployee}
              />
                </div>
              </div>
              <div className={Style.container1}>
                <div className={Style.colum}>
                  <p className={Style.clients}>
                    Project Start Date <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={Style.date}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      // minDate={new Date()}
                      value={Moment(state.startDate).format("D MMM YY")}
                      startDate={null}
                      onChange={(date) => changeDate(date)}
                      disabledKeyboardNavigation
                      defaultValue={null}
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
                <div className={Style.colum}>
                  <p className={Style.clients} style={{ marginTop: "0.4em" }}>
                    Type of Project <span style={{ color: "red" }}>*</span>
                  </p>
                  <select
                    className={Style.input}
                    name="projectType"
                    onChange={onInputChange}
                    required
                    onInvalid={(e) =>
                      e.target.setCustomValidity("Please Enter Type of Project")
                    }
                  >
                    <option hidden>{state.typeOfProject}</option>
                    {ProjectTyepe.map((val) => {
                      return <option value={val?.id}>{val?.name}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className={Style.container2}>
                <p className={Style.clients} style={{ marginTop: "0.4em" }}>
                  Project Description{" "}
                </p>

                <textarea
                  autoComplete="off"
                  onChange={onInputChange}
                  className={Style.text}
                  value={state.projectDescription}
                  name="description"
                  style={{ backgroundColor: "#F5F6FA" }}
                  // required
                  // onInvalid={(e)=>e.target.setCustomValidity(' Please Enter Project Description')}
                ></textarea>
              </div>
              <div className={Style.inputbutton}>
                <button
                  type="submit"
                  className={Style.submitBtn}
                  disabled={disable}
                >
                  Update
                </button>
              </div>
            </form>

            <br />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
