import axios from "axios";
import cookie from "react-cookies";
const apiKey = process.env.REACT_APP_EPR_APP_URL;
const apiKey1 = process.env.REACT_APP_EMPLOYEEONBOARDING_APP_URL;
const apiKey2 = process.env.REACT_APP_RESOURCEMASTER_APP_URL;
const apiKey3 = process.env.REACT_APP_UMS_APP_URL;
const apiKey4 = process.env.REACT_APP_VALIDATE_URL;
let token = cookie.load("token");
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const PerformanceReviewList = async (
  limit,
  page,
  empId,
  empName,
  empLeadId,
  functionalFieldId,
  reportStatus
) => {
  if (empName === "" && empId === 0 && !empLeadId && !functionalFieldId) {
    const response = await axios.get(
      `${apiKey}/getPerformanceReview?limit=${limit}&page=${page}&reportStatus=${reportStatus}`
    );
    return response.data.responseData;
  } else if (functionalFieldId) {
    const response = await axios.get(
      `${apiKey}/getPerformanceReview?limit=${limit}&page=${page}&functionalFieldId=${functionalFieldId}&reportStatus=${reportStatus}`
    );
    return response.data.responseData;
  } else if (empLeadId) {
    const response = await axios.get(
      `${apiKey}/getPerformanceReview?limit=${limit}&page=${page}&empLeadId=${empLeadId}&reportStatus=${reportStatus}`
    );
    return response.data.responseData;
  } else {
    const response = await axios.get(
      `${apiKey}/getPerformanceReview?limit=${limit}&page=${page}&empId=${empId}&empName=${empName}&reportStatus=${reportStatus}`
    );
    return response.data.responseData;
  }
};

export const PerformanceReviewList2 = async () => {
  const response = await axios.get(
    `${apiKey}/get-performance-report`
  );
  return response.data.responseData;
};

export const getReviewType = async (id) => {
  const response = await axios.get(`${apiKey}/getReview?id=${id}`);
  return response.data.responseData;
};

export const getReviewSourcType = async () => {
  const response = await axios.get(`${apiKey}/getReviewSourceType`);
  return response.data.responseData;
};

export const getEmployeeList = async () => {
  const response = await axios.get(`${apiKey1}/getEmployeeList`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};
export const getEmployeeInformation = async () => {
  const response = await axios.get(`${apiKey}/get-active-employee-details`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};

export const createPerformanceFileType = async (data) => {
  const response = await axios.post(
    `${apiKey}/createPerformanceFileType`,
    data
  );
  return response.data.responseData;
};

export const createPerformanceReviewRating = async (data) => {
  const response = await axios.post(
    `${apiKey}/createPerformanceReviewRating`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: localStorage.getItem("employeeId"),
      },
    }
  );
  return response.data;
};

export const createPerformanceReviewSubjectiveType = async (data) => {
  const response = await axios.post(
    `${apiKey}/createPerformanceReviewSubjectiveType`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: localStorage.getItem("employeeId"),
      },
    }
  );
  return response.data;
};

export const createPerformanceReviewTest = async (data) => {
  const response = await axios.post(
    `${apiKey}/createPerformanceReviewTest`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: localStorage.getItem("employeeId"),
      },
    }
  );
  return response.data;
};

export const getSkills = async () => {
  const response = await axios.get(`${apiKey}/getSkills`);
  return response.data.responseData;
};

export const getClientForFilter = async () => {
  const response = await axios.get(`${apiKey2}/getClientForFilter`);
  return response.data.responseData;
};

export const getFoxMatrixInterviewers = async () => {
  const response = await axios.get(`${apiKey}/getFoxMatrixInterviewers`);
  return response.data.responseData;
};

export const getEmployeePerformanceByUserId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeePerformanceByUserId?userId=${id}`
  );
  return response.data.responseData;
};

export const getPerformanceReviewById = async (id) => {
  const response = await axios.get(
    `${apiKey}/getPerformanceReviewById?id=${id}`
  );
  return response.data.responseData;
};

export const getEmployeeDetailsByEmployeeId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeeDetailsByEmployeeId?empId=${id}`
  );
  return response.data.responseData;
};

export const viewFeedback = async (id) => {
  const response = await axios.get(
    `${apiKey}/viewFeedback?performnaceFeedbackId=${id}`
  );
  return response.data.responseData;
};

export const editPerformanceReviewSubjectiveType = async (data) => {
  const response = await axios.post(
    `${apiKey}/editPerformanceReviewSubjectiveType`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: localStorage.getItem("employeeId"),
      },
    }
  );
  return response.data;
};

export const editPerformanceReviewFileType = async (data) => {
  const response = await axios.post(
    `${apiKey}/editPerformanceReviewFileType`,
    data
  );
  return response.data.responseData;
};

export const updatePerformanceReviewRating = async (data) => {
  const response = await axios.post(
    `${apiKey}/updatePerformanceReviewRating`,
    data
  );
  return response.data.responseData;
};

export const getAllClientInterviewer = async () => {
  const response = await axios.get(`${apiKey}/get-all-client-interviewer`);
  return response.data.responseData;
};

export const deletePerformanceReview = async ({ performanceReviewId }) => {
  const response = await axios.put(
    `${apiKey}/deletePerformanceReview?performanceReviewId=${performanceReviewId}`
  );
  return response.data;
};

export const getAllActiveProject = async () => {
  const response = await axios.get(`${apiKey2}/getAllActiveProject`);
  return response.data.responseData;
};

export const getProjectLeads = async () => {
  const response = await axios.get(`${apiKey}/get-project-leads`);
  return response.data.responseData;
};
export const getDepartment = async () => {
  const response = await axios.get(`${apiKey3}/getAllFunctionalArea`);
  return response.data.responseData;
};

export const validateEmployeeAccess = async (id) => {
  const response = await axios.get(
    `${apiKey4}/validate-employee-access?empId=${id}`
  );
  return response.data.responseData;
};
