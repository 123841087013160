import React from "react";
import style from "./Checkbox.module.css";

const CheckBox = (props) => {
  function removeA(arr) {
    var what,
      a = arguments,
      L = a?.length,
      ax;
    while (L > 1 && arr?.length) {
      what = a[--L];
      while ((ax = arr?.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  }

  const handleCheckbox = (arg) => {
    if (props.type === "clientStatus") {
      props.filterdata1.status.includes(arg) === false
        ? props.filterdata1.status.push(arg)
        : removeA(props.filterdata1.status, arg);
      props.change(props.value);
    } else if (props.type === "clientName") {
      props.filterdata1.clientId.includes(arg) === false
        ? props.filterdata1.clientId.push(arg)
        : removeA(props.filterdata1.clientId, arg);
      props.change(props.value);
    } else if (props.type === "projectClientName") {
      props.filterdata1.clientId.includes(arg) === false
        ? props.filterdata1.clientId.push(arg)
        : removeA(props.filterdata1.clientId, arg);
      props.change(props.value);
    } else if (props.type === "projectId") {
      props.filterdata1.statusTypeId.includes(arg) === false
        ? props.filterdata1.statusTypeId.push(arg)
        : removeA(props.filterdata1.statusTypeId, arg);
      props.change(props.value);
    } else if (props.type === "projectCategory") {
      props.filterdata1.catogaryprojectId.includes(arg) === false
        ? props.filterdata1.catogaryprojectId.push(arg)
        : removeA(props.filterdata1.catogaryprojectId, arg);
      props.change(props.value);
    } else if (props.type === "empOffshore") {
      props.filterdata1.empId.includes(arg) === false
        ? props.filterdata1.empId.push(arg)
        : removeA(props.filterdata1.empId, arg);
      props.change(props.value);
    } else if (props.type === "offShoreSkill") {
      props.filterdata1.skillId.includes(arg) === false
        ? props.filterdata1.skillId.push(arg)
        : removeA(props.filterdata1.skillId, arg);
      props.change(props.value);
    } else if (props.type === "offShoreProject") {
      props.filterdata1.projectId.includes(arg) === false
        ? props.filterdata1.projectId.push(arg)
        : removeA(props.filterdata1.projectId, arg);
      props.change(props.value);
    } else if (props.type === "offShoreAllocation") {
      props.filterdata1.bandwidths.includes(arg) === false
        ? props.filterdata1.bandwidths.push(arg)
        : removeA(props.filterdata1.bandwidths, arg);
      props.change(props.value);
    } else if (props.type === "offShoreAction1") {
      props.filterdata1?.action.includes(arg) === false
        ? props.filterdata1?.action.push(arg)
        : removeA(props.filterdata1.action, arg);
      props.change(props.value);
    } else if (props.type === "offShoreAction") {
      props.filterdata1?.action.includes(arg) === false
        ? props.filterdata1?.action.push(arg)
        : removeA(props.filterdata1?.action, arg);
      props.change(props.value);
    } else if (props.type === "poolStatus") {
      props.filterdata1.statusId.includes(arg) === false
        ? props.filterdata1.statusId.push(arg)
        : removeA(props.filterdata1.statusId, arg);
      props.change(props.value);
    } else if (props.type === "onSiteClient") {
      props.filterdata1.clientId.includes(arg) === false
        ? props.filterdata1.clientId.push(arg)
        : removeA(props.filterdata1.clientId, arg);
      props.change(props.value);
    } else if (props.type === "onSiteEmp") {
      props.filterdata1.employeeId.includes(arg) === false
        ? props.filterdata1.employeeId.push(arg)
        : removeA(props.filterdata1.employeeId, arg);
      props.change(props.value);
    } else if (props.type === "employeeType") {
      props.filterdata1.partners.includes(arg) === false
        ? props.filterdata1.partners.push(arg)
        : removeA(props.filterdata1.partners, arg);
      props.change(props.value);
    } else if (props.type === "offShoreEmptype") {
      props.filterdata1.partners.includes(arg) === false
        ? props.filterdata1.partners.push(arg)
        : removeA(props.filterdata1.partners, arg);
      props.change(props.value);
    } else if (props.type === "onSiteEmpSkills") {
      props.filterdata1.skill.includes(arg) === false
        ? props.filterdata1.skill.push(arg)
        : removeA(props.filterdata1.skill, arg);
      props.change(props.value);
    } else if (props.type === "poolTechnology") {
      props.filterdata1.skillId.includes(arg) === false
        ? props.filterdata1.skillId.push(arg)
        : removeA(props.filterdata1.skillId, arg);
      props.change(props.value);
    } else if (props.type === "poolEmp") {
      props.filterdata1.empId.includes(arg) === false
        ? props.filterdata1.empId.push(arg)
        : removeA(props.filterdata1.empId, arg);
      props.change(props.value);
    } else if (props.type === "poolSalaryRange") {
      props.filterdata1.salaryRange.includes(arg) === false
        ? props.filterdata1.salaryRange.push(arg)
        : removeA(props.filterdata1.salaryRange, arg);
      props.change(props.value);
    } else if (props.type === "OffshorSalaryRange") {
      props.filterdata1.salaryRange.includes(arg) === false
        ? props.filterdata1.salaryRange.push(arg)
        : removeA(props.filterdata1.salaryRange, arg);
      props.change(props.value);
    } else if (props.type === "OnsiteSalaryRange") {
      props.filterdata1.salaryRange.includes(arg) === false
        ? props.filterdata1.salaryRange.push(arg)
        : removeA(props.filterdata1.salaryRange, arg);
      props.change(props.value);
    } else if (props.type === "poolExpRange") {
      props.filterdata1.experienceRange.includes(arg) === false
        ? props.filterdata1.experienceRange.push(arg)
        : removeA(props.filterdata1.experienceRange, arg);
      props.change(props.value);
    }
  };
  return (
    <li
      style={{
        paddingTop: "10px",
        fontSize: "18px",
        color:
          props.userList?.id === "Assigned"
            ? "#FCB902"
            : props.userList?.id === "Done"
            ? "#65A15E"
            : props.userList?.id === "Onhold"
            ? "#F94244"
            : props.userList?.id === "Inprogress"
            ? "#163bbe"
            : "#000000",
        opacity: ".7",
        fontWeight: "600",
      }}
    >
      <label className={style.main}>
        <input
          type="checkbox"
          className={style.one}
          defaultChecked={props?.value?.includes(props?.userList.id)}
          onClick={() => handleCheckbox(props.userList?.id)}
        />
        {props.userList?.name?.length > 23
          ? props.userList?.name.slice(0, 23) + "..."
          : props.userList?.name}
        <span className={style.geekmark}></span>
      </label>
    </li>
  );
};
export default CheckBox;
