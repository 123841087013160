import React from "react";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import AddTabletComponent from "./component/AddTabletComponent";
import style from "../tablet.module.css";

const AddLaptop = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <AddTabletComponent />
      </div>
    </div>
  );
};

export default AddLaptop;
