import React from "react";
import Header from "../../../commonComponent/Header";
import SideBar from "../../../commonComponent/SideBar";
import AddMobileComponent from "./component/AddMobileComponent";
import style from "../mobiles.module.css";

const AddMobile = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <AddMobileComponent />
      </div>
    </div>
  );
};

export default AddMobile;
