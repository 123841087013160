import React from "react";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import HealthMRecordComp from "./component/HealthMRecordComp";
import style from "../mobiles/mobiles.module.css";

const HealthRecod = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <HealthMRecordComp />
      </div>
    </div>
  );
};

export default HealthRecod;
