import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import style from "./ViewExpence.module.css";
import { getExpenseId } from "../../../../../Utils/axios/expenceApi.js";
import { useQuery } from "react-query";
import moment from "moment";
const ViewExpence = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {  
  const [formData, setFormData] = useState({
    lineItemDetails: [
      {
        candidateName: "",
        expenseMonth: "",
        expenseYear:"",
        billingRate: "",
        workingDays: "",
        maxWorkingDays: "",
        baseAmount: "",
      },
    ],
  });
    
  const {
    data: ExpenseDataById,
    isLoading,
    isError,
  } = useQuery(["getExpenseId"], () => getExpenseId(prevData.idData), {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval:1000,
  });



  

  


  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      {/*Copy Expence*/}

      <InvoiceContainer>
      <div className={style.copy_expence}>Copy Expense </div>
      <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Expense?</div>
            <div className={style.radioitem}>
              <p className={style.values}>
              {ExpenseDataById?.copyOfExpense === true ? "Yes" : "NO" }
              </p>
            </div>
          </div>  
        </div>
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Vendor Name</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.vendorNameById}</p>
          </div>
        </div>
      </InvoiceContainer>
      {/*Expence Details*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Expense Details</div>
        {/*row 1*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Category</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.categoryName}</p>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        {/*row 2*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Invoice No</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.invoice}</p> 
          </div>
          <div className={style.col2}>
            <p className={style.label}>Date of Expense</p>
            <p className={style.values}> {moment(ExpenseDataById && ExpenseDataById.dateOfExpense).format(
                "DD MMM YY"
              )}</p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>Expense Month</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.expenseMonth}</p>
          </div>
          <div className={style.col4}>
            <p className={style.label}>Expense Year</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.expenseYear}</p>
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Title</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.title}</p>
          </div>
          <div className={style.col22}>
            <p className={style.label}>Vendor Name</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.vendorNameById}</p>
          </div>
        </div>
        <div className={style.border}></div>
        <div className={style.Container}>
        <div className={style.col1}>
            <div className={style.heading}>GST Applicable</div>
            <div className={style.radioitem}>
              <p className={style.values}>
              {ExpenseDataById?.gstApplicable === true ? "Yes" : "NO" }
              </p>
            </div>
          </div>
        </div>
        <div className={style.Container}></div>
        <div className={style.Container}>         
          <div className={style.col2}>
          <p className={style.label}>CGST</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.cgst}</p>
          </div>
          <div className={style.col2}>
          <p className={style.label}>SGST</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.sgst}</p>
          </div>
          <div className={style.col3}>
          <p className={style.label}>IGST</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.igst}</p>
          </div>
          <div className={style.col4}>
          <p className={style.label}>GST (Total)</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.gst}</p>
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.col2}>
          <p className={style.label}>Base Amount</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.baseAmount}</p>
          </div>
          <div className={style.col2}>
          <p className={style.label}>Total Billed</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.totalBilled}</p>
          </div>
          <div className={style.col3}>
          <p className={style.label}>Amount to Be Paid</p>
            <p className={style.values}>{ExpenseDataById && ExpenseDataById.amountToBePaid}</p>
          </div>
          <div className={style.col4}>
          <p className={style.label}>Payment Due On</p>
            <p className={style.values}>{moment(ExpenseDataById && ExpenseDataById.paymentDueOn).format(
                "DD MMM YY"
              )}</p>
          </div>
        </div>
      </InvoiceContainer>
      {/* Line Item Details Container */}
      <InvoiceContainer>
        <div className={style.copy_expence}>Line Item Details</div>
        {/*row 1*/}
        {/* {formData?.lineItem?.map((item, index) => { */}
        { ExpenseDataById?.lineItemDetails.map((item,index) => {
          return (
            <>
              <div className={style.Container} key={index}>
                <div className={style.col12_item}>
                  <p className={style.label_item}>Candidate Name</p>
                  <p className={style.values}>{item.candidateName}</p>
                </div>
                <div className={style.col2_item}>
                  <p className={style.label_item}>Expense Month</p>
                  <p className={style.values}>{item.expenseMonth}</p>
                </div>
                <div className={style.col3_item}>
                  <p className={style.label_item}>Expence Year</p>
                  <p className={style.values}>{item.expenseYear}</p>
                </div>
                <div className={style.col4_item}>
                  <p className={style.label_item}>Billing Rate</p>
                  <p className={style.values}>{item.billingRate}</p>
                </div>
                <div className={style.col5_item}>
                  <p className={style.label_item}>Working Days</p>
                  <p className={style.values}>{item.workingDays}</p>
                </div>
                <div className={style.col6_item}>
                  <p className={style.label_item}>Max Working Days</p>
                  <p className={style.values}>{item.maxWorkingDays}</p>
                </div>
                <div className={style.col7_item}>
                  <p className={style.label_item}>Base Amount</p>
                  <p className={style.values}>{item.baseAmount}</p>
                </div>
              </div>
            </>
          );
        })}

        {/* })} */}
      </InvoiceContainer>
      <div className={style.lastBtn}>
        <InvoiceButton
          type={"cancel"}
          onClick={() => {
            setNextScreen("expense");
            setPrevData((prevData) => ({
              ...prevData,
              page2: null,
              page3: null,
              page4: null,
              active: "expense",
            }));
          }}
        >
          Back
        </InvoiceButton>

        <InvoiceButton
          onClick={() => {
            setNextScreen("editExpense");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "editExpense",
              page3: null,
              page4: null,
              active: "editExpense",
            }));
          }}
        >
          Edit
        </InvoiceButton>
      </div>
    </>
  );
};

export default ViewExpence;
