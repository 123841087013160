import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Modal } from "react-bootstrap";
import style from "../EditEmployeeTimeSheet/EditEmployeeTimeSheet.module.css";
import Select from "react-select";
import {
  InvoiceStyles,
  InvoiceStyles2,
  InvoiceStyles4,
} from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import AddIcon from "../../../../assets/Add_notes.png";

import {
  getTaskType,
  getTimeSpent,
  getAllActiveProject,
  EditTimeSheet,
  ViewTimeSheetByID,
} from "../../../../Utils/axios/NewTimeSheetApi";
import { useMutation, useQuery } from "react-query";
const EditEmployeeTimeSheet = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const { data: TimesheetDataById } = useQuery(["ViewTimeSheetByID"], () =>
    ViewTimeSheetByID(prevData?.timesheetID)
  );

  const [show1, setShow1] = useState(false);
  const [taskSummaryData, setTaskSummaryData] = useState();

  const [formData, setFormData] = useState({
    taskTypeId: TimesheetDataById?.taskTypeId,
    projectId: TimesheetDataById?.projectId,
    timeSpentId: TimesheetDataById?.timeSpentId,
    taskSummary: TimesheetDataById?.taskSummary,
    apiName: TimesheetDataById?.apiname,
    screenName: TimesheetDataById?.screenName,
    featureName: TimesheetDataById?.featureName,
    taskDate: prevData?.timesheetDate,
    taskStatusId: 0,
    defectNumber: TimesheetDataById?.defectNumber,
    timesheetId: TimesheetDataById?.timesheetId,
    employeeId: Number(localStorage.getItem("employeeId")),
  });

  useEffect(() => {
    setFormData((prevValue) => ({
      ...prevValue,
      taskTypeId: TimesheetDataById?.taskTypeId,
      projectId: TimesheetDataById?.projectId,
      timeSpentId: TimesheetDataById?.timeSpentId,
      taskSummary: TimesheetDataById?.taskSummary,
      apiName: TimesheetDataById?.apiname,
      screenName: TimesheetDataById?.screenName,
      featureName: TimesheetDataById?.featureName,
      taskDate: prevData?.timesheetDate,
      taskStatusId: 0,
      defectNumber: TimesheetDataById?.defectNumber,
      timesheetId: TimesheetDataById?.timesheetId,
      employeeId: Number(localStorage.getItem("employeeId")),
    }));
  }, [TimesheetDataById]);

  const [validate, setValidate] = React.useState(false);
  const { data: projectData } = useQuery(["getTaskType"], getTaskType);
  const TaskOption = projectData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getTimeSpentData } = useQuery(["getTimeSpent"], getTimeSpent);
  const TimeSpentData = getTimeSpentData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getAllProject } = useQuery(
    ["getAllActiveProject"],
    getAllActiveProject
  );
  const AllProject = getAllProject?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };
  const mutation1 = useMutation(EditTimeSheet, {});

  const updateHandler = () => {
    mutation1.mutate(formData);
  };

  const originalDate = new Date(prevData?.timesheetDate);
  const day = originalDate.getDate();
  const month = originalDate.toLocaleString("default", { month: "short" });
  const year = originalDate.getFullYear().toString().slice(-2);
  const convertedDate = `${day} ${month} ${year}`;

  const handleNotApproved = () => {
    setShow1(false);
  };

  const handleShow1 = (task) => {
    setShow1(true);
    setTaskSummaryData(task);
  };

  return (
    <>
      <Container>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <span className={style.timesheetdate}>Edit Task Details</span>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ marginTop: "1px", marginLeft: "2px" }}>
          <Col>
            <p className={style.datetime}>{convertedDate}</p>
          </Col>
        </Row>
      </Container>
      <InvoiceContainer>
        <Row>
          <Col md={3} style={{ marginLeft: "12px" }}>
            <p className={style.label_item}>
              Project Name<span style={{ color: "red" }}>*</span>
            </p>

            <Select
              styles={InvoiceStyles}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              value={AllProject?.find((e) => e.value === formData?.projectId)}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  projectId: e.value,
                });
              }}
              options={AllProject}
            />
          </Col>
          <Col>
            <p className={style.label_item}>
              Task Type<span style={{ color: "red" }}>*</span>
            </p>

            <Select
              styles={InvoiceStyles4}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              value={TaskOption?.find((e) => e.value === formData?.taskTypeId)}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  taskTypeId: e.value,
                });
              }}
              options={TaskOption}
            />
          </Col>

          {formData?.taskTypeId === 1 ? (
            <>
              <Col>
                <input
                  type="text"
                  value={formData?.apiName}
                  placeholder="API Name"
                  style={{ marginTop: "32px", marginLeft: "30px" }}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      apiName: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.apiName
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter API Name
                </p>
              </Col>

              <Col md={2}>
                <p
                  className={style.label_item}
                  style={{ transform: "translate(90px,0px)" }}
                >
                  Time Spent
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles2}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TimeSpentData?.find(
                    (e) => e.value === formData?.timeSpentId
                  )}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      timeSpentId: e.value,
                    });
                  }}
                  options={TimeSpentData}
                />
              </Col>
              <Col style={{ float: "right" }}>
                <img
                  src={AddIcon}
                  alt=""
                  style={{
                    width: "20px",
                    float: "right",
                    marginTop: "39px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShow1(formData?.taskSummary)}
                />
              </Col>
            </>
          ) : (
            ""
          )}

          {formData?.taskTypeId === 2 || formData?.taskTypeId === 9 ? (
            <>
              <Col>
                <input
                  type="text"
                  placeholder="Screen Name"
                  className={style.smallInput}
                  style={{ marginTop: "32px", marginLeft: "30px" }}
                  value={formData?.screenName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      screenName: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && formData?.screenName
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Screen Name
                </p>
              </Col>
              <Col md={2}>
                <p
                  className={style.label_item}
                  style={{ transform: "translate(90px,0px)" }}
                >
                  Time Spent
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles2}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TimeSpentData?.find(
                    (e) => e.value === formData?.timeSpentId
                  )}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      timeSpentId: e.value,
                    });
                  }}
                  options={TimeSpentData}
                />
              </Col>
              <Col style={{ float: "right" }}>
                <img
                  src={AddIcon}
                  alt=""
                  style={{
                    width: "20px",
                    float: "right",
                    marginTop: "39px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShow1(formData?.taskSummary)}
                />
              </Col>
            </>
          ) : (
            ""
          )}

          {formData?.taskTypeId === 3 ? (
            <>
              <Col>
                <input
                  type="text"
                  style={{ marginTop: "32px", marginLeft: "30px" }}
                  className={style.smallInput}
                  placeholder="Screen Name"
                  value={formData?.screenName}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      screenName: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && formData?.screenName
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Screen Name
                </p>
              </Col>
              <Col>
                <input
                  style={{ marginTop: "32px", marginLeft: "30px" }}
                  value={formData?.apiName}
                  placeholder="API Name"
                  type="text"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      apiName: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.apiName
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter API Name
                </p>
              </Col>
              <Col md={2} style={{ marginLeft: "15px" }}>
                <p className={style.label_item}>
                  Time Spent
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TimeSpentData?.find(
                    (e) => e.value === formData?.timeSpentId
                  )}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      timeSpentId: e.value,
                    });
                  }}
                  options={TimeSpentData}
                />
              </Col>

              <Col style={{ float: "right" }}>
                <img
                  src={AddIcon}
                  alt=""
                  style={{
                    width: "20px",
                    float: "right",
                    marginTop: "39px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShow1(formData?.taskSummary)}
                />
              </Col>
            </>
          ) : (
            ""
          )}

          {formData?.taskTypeId === 4 || formData?.taskTypeId === 10 ? (
            <>
              <Col>
                <input
                  type="text"
                  style={{ marginTop: "32px", marginLeft: "30px" }}
                  value={formData?.apiName}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      apiName: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.apiName
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter API Name
                </p>
              </Col>
              <Col md={2}>
                <p
                  className={style.label_item}
                  style={{ transform: "translate(90px,0px)" }}
                >
                  Time Spent
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles2}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TimeSpentData?.find(
                    (e) => e.value === formData?.timeSpentId
                  )}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      timeSpentId: e.value,
                    });
                  }}
                  options={TimeSpentData}
                />
              </Col>
              <Col style={{ float: "right" }}>
                <img
                  src={AddIcon}
                  alt=""
                  style={{
                    width: "20px",
                    float: "right",
                    marginTop: "39px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShow1(formData?.taskSummary)}
                />
              </Col>
            </>
          ) : (
            ""
          )}
          {formData?.taskTypeId === 5 ? (
            <>
              <Col>
                <input
                  style={{ marginTop: "32px", marginLeft: "30px" }}
                  value={formData?.defectNumber}
                  placeholder="Defect Number"
                  type="text"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      defectNumber: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.defectNumber
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Defect Number
                </p>
              </Col>
              <Col md={2}>
                <p
                  className={style.label_item}
                  style={{ transform: "translate(90px,0px)" }}
                >
                  Time Spent
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles2}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TimeSpentData?.find(
                    (e) => e.value === formData?.timeSpentId
                  )}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      timeSpentId: e.value,
                    });
                  }}
                  options={TimeSpentData}
                />
              </Col>
              <Col style={{ float: "right" }}>
                <img
                  src={AddIcon}
                  alt=""
                  style={{
                    width: "20px",
                    float: "right",
                    marginTop: "39px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShow1(formData?.taskSummary)}
                />
              </Col>
            </>
          ) : (
            ""
          )}

          {formData?.taskTypeId === 6 ? (
            <>
              <Col>
                <input
                  style={{ marginTop: "32px", marginLeft: "30px" }}
                  value={formData?.apiName}
                  type="text"
                  placeholder="Feature Name"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      apiName: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.apiName
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Feature Name
                </p>
              </Col>
              <Col md={2}>
                <p
                  className={style.label_item}
                  style={{ transform: "translate(90px,0px)" }}
                >
                  Time Spent
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles2}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TimeSpentData?.find(
                    (e) => e.value === formData?.timeSpentId
                  )}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      timeSpentId: e.value,
                    });
                  }}
                  options={TimeSpentData}
                />
              </Col>
              <Col style={{ float: "right" }}>
                <img
                  src={AddIcon}
                  alt=""
                  style={{
                    width: "20px",
                    float: "right",
                    marginTop: "39px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShow1(formData?.taskSummary)}
                />
              </Col>
            </>
          ) : (
            ""
          )}
          {formData?.taskTypeId === 7 ||
          formData?.taskTypeId === 8 ||
          formData?.taskTypeId === 11 ? (
            <>
              <Col>
                <input
                  style={{ marginTop: "32px", marginLeft: "30px" }}
                  value={formData?.featureName}
                  type="text"
                  className={style.smallInput}
                  placeholder=" Feature Name"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      featureName: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.featureName
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Feature Name
                </p>
              </Col>
              <Col md={2}>
                <p
                  className={style.label_item}
                  style={{ transform: "translate(90px,0px)" }}
                >
                  Time Spent
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles2}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TimeSpentData?.find(
                    (e) => e.value === formData?.timeSpentId
                  )}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      timeSpentId: e.value,
                    });
                  }}
                  options={TimeSpentData}
                />
              </Col>

              <Col style={{ float: "right" }}>
                <img
                  src={AddIcon}
                  alt=""
                  style={{
                    width: "20px",
                    float: "right",
                    marginTop: "39px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShow1(formData?.taskSummary)}
                />
              </Col>
            </>
          ) : (
            ""
          )}

          {formData?.taskTypeId === 12 ||
          formData?.taskTypeId === 13 ||
          formData?.taskTypeId === 14 ||
          formData?.taskTypeId === 15 ? (
            <>
              <Col></Col>
              <Col md={2}>
                <p
                  className={style.label_item}
                  style={{ transform: "translate(90px,0px)" }}
                >
                  Time Spent
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles2}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TimeSpentData?.find(
                    (e) => e.value === formData?.timeSpentId
                  )}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      timeSpentId: e.value,
                    });
                  }}
                  options={TimeSpentData}
                />
              </Col>

              <Col style={{ float: "right" }}>
                <img
                  src={AddIcon}
                  alt=""
                  style={{
                    width: "20px",
                    float: "right",
                    marginTop: "39px",
                    marginRight: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleShow1(formData?.taskSummary)}
                />
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
      </InvoiceContainer>
      <button
        className={style.buttonAdd}
        onClick={() => {
          setNextScreen("ViewTask");
          setPrevData((prevData) => ({
            ...prevData,
            page2: "ViewTask",
            page3: null,
            page4: null,
            active: "ViewTask",
            IdData: prevData?.timesheetId,
            formData: prevData?.formData,
          }));
          updateHandler();
        }}
      >
        Update
      </button>
      <button
        className={style.buttonCancle}
        onClick={() => {
          setNextScreen("ViewTask");
          setPrevData((prevData) => ({
            ...prevData,
            page2: "ViewTask",
            page3: null,
            page4: null,
            active: "ViewTask",
          }));
        }}
      >
        Cancel
      </button>

      <Modal show={show1} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Task Summary</h6>
        </Modal.Header>
        <Form.Control
          as="textarea"
          value={formData?.taskSummary}
          onChange={(e) => {
            setFormData({
              ...formData,
              taskSummary: e.target.value,
            });
          }}
          rows={2}
          style={{ width: "85%", marginLeft: "24px", marginTop: "10px" }}
        />

        <button className={style.buttonAdd3} onClick={handleNotApproved}>
          Ok
        </button>
      </Modal>
    </>
  );
};
export default EditEmployeeTimeSheet;
