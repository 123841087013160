import React from "react";
import style from "./SideBar.module.css";
import one from "../../../assets/businessman-gear-icon.png";
import oneG from "../../../assets/businessman-gear-icon_GREEN.png";
import { useHistory } from "react-router-dom";
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";
const SideBar = ({
  sidebarHandler,
  nextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
}) => {
  const history = useHistory();
  return (
    <div className={style.leftSection}>
      <div className={style.header}>
        <img
          alt="DashBoardImage"
          src={InnovationMLogo}
          className={style.dashboardImage}
          onClick={() => history.push("/dashboard")}
        />
      </div>
      {/* 0 */}
      {/* <button className={style.taskTracker}>
        <img src={ats} alt="img" height="40px" />
        <span>Dashbord</span>
      </button> */}

      {/* 1 */}
      <button
        onClick={() => {
          sidebarHandler("PartnerList");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "PartnerList",
            page: "PartnerList",
            page1: "PartnerList",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "PartnerList",
          });
        }}
        className={
          prevData?.sidebar === "PartnerList"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "PartnerList" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Partner Management</span>
        </div>
      </button>
      <button
        onClick={() => {
          sidebarHandler("report");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "report",
            page: "report",
            page1: "report",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "report",
          });
        }}
        className={
          prevData?.sidebar === "report" ? style.selected : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "report" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Report</span>
        </div>
      </button>
    </div>
  );
};

export default SideBar;
