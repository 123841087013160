import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import cookie from "react-cookies";
import axios from "axios";
import cx from "classnames";
import styles from "./ViewReceivedTickets.module.css";
import { FilterForm,} from "../../Components";
import { ReactComponent as InactiveFilterListIcon } from "../../../../assets/Grievance portal/icon-filter-list-inactive.svg";
import { ReactComponent as ActiveFilterListIcon } from "../../../../assets/Grievance portal/icon-filter-list-active.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/Grievance portal/icon-plus.svg";
import { ReactComponent as PageBackwardIcon } from "../../../../assets/Grievance portal/icon-arrow-left.svg";
import { ReactComponent as PageForwardIcon } from "../../../../assets/Grievance portal/icon-arrow-right.svg";
import Header from "../../../Grievence Portal/Components/Header/Header";
import Sidebar from "../../../Grievence Portal/Components/Sidebar/Sidebar";
import Constants from "../../../../Utils/constants";

const ViewReceivedTickets = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterData, setFilteredData] = useState([]);
  const [filterActive, setFilterActive] = useState(false);
  const [queryList, setQueryList] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = useRef();
 

  const handleSubmit = async (event) => {
    const url = Constants.apiBaseUrlGp;
    const data = {
      email: "hemant.kumari@innovationm.com",
    };
    axios
      .post(url + `/query/viewReceivedQueries`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setQueryList(res.data.data);
      });
  };

  // console.log('MAP',queryList.map((item,index) => item))
  useEffect(() => {
    handleSubmit();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    // handleapidata();
  };

  let content;

  content = (
    <React.Fragment>
      <div className={cx(styles.colFlex, styles.listTableContainer)}>
        <table className={cx(styles.listTable, styles.centered)}>
          <thead>
            <tr className={styles.headerRow}>
              <th
                className={cx(
                  styles.font18,
                  styles.fontMedium,
                  styles.colorBlack
                )}
                style={{
                  backgroundColor: "#F5F6FA",
                  borderRadius: "9px 0 0 9px",
                }}
              >
                Ticket ID
              </th>
              <th
                className={cx(
                  styles.font18,
                  styles.fontMedium,
                  styles.colorBlack
                )}
                style={{ backgroundColor: "#F5F6FA" }}
              >
                Category
              </th>
              <th
                className={cx(
                  styles.font18,
                  styles.fontMedium,
                  styles.colorBlack
                )}
                style={{ backgroundColor: "#F5F6FA" }}
              >
                Query Description
              </th>
              <th
                className={cx(
                  styles.font18,
                  styles.fontMedium,
                  styles.colorBlack
                )}
                style={{ backgroundColor: "#F5F6FA" }}
              >
                Requested By
              </th>
              <th
                className={cx(
                  styles.font18,
                  styles.fontMedium,
                  styles.colorBlack
                )}
                style={{ backgroundColor: "#F5F6FA" }}
              >
                Closing Date
              </th>
              <th
                className={cx(
                  styles.font18,
                  styles.fontMedium,
                  styles.colorBlack
                )}
                style={{
                  backgroundColor: "#F5F6FA",
                  borderRadius: "0 9px 9px 0",
                }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody style={{ justifyContent: "flex-start" }}>
            {queryList && queryList.length > 0
              ? queryList.map((item, index) => {
                  console.log("item", item._id);
                  return (
                    <tr className={styles.bodyRow} key={index}>
                      <td style={{ padding: "0.75em", color: "#41CBB6" }}>
                        <Link
                          // to={`/view-ticket/${item._id}`}
                          className={cx(
                            styles.font16,
                            styles.fontMedium,
                            styles.colorShamrock,
                            styles.fontItalic,
                            styles.fontUnderline
                          )}
                        >
                          {item._id}
                        </Link>
                      </td>
                      <td
                        className={cx(
                          styles.font16,
                          styles.fontRegular,
                          styles.colorBlack
                        )}
                        style={{
                          padding: "0.75em",
                          position: "relative",
                          left: "24px",
                        }}
                      >
                        {item.category}
                      </td>
                      <td
                        className={cx(
                          styles.font16,
                          styles.fontRegular,
                          styles.colorBlack
                        )}
                        style={{
                          padding: "0.75em",
                          position: "relative",
                          left: "24px",
                        }}
                      >
                        {item.description}
                      </td>
                      <td
                        className={cx(
                          styles.font16,
                          styles.fontRegular,
                          styles.colorBlack
                        )}
                        style={{
                          padding: "0.75em",
                          position: "relative",
                          left: "24px",
                        }}
                      >
                        {item.requestorName}
                      </td>
                      <td
                        className={cx(
                          styles.font16,
                          styles.fontRegular,
                          styles.colorBlack
                        )}
                        style={{
                          padding: "0.75em",
                          position: "relative",
                          left: "24px",
                        }}
                      >
                        {item.plannedEndDate}
                      </td>
                      {item.status === 0 ? (
                        <td
                          className={cx(
                            styles.font16,
                            styles.fontRegular,
                            styles.colorRed
                          )}
                          style={{
                            padding: "0.75em",
                            color: "red",
                            position: "relative",
                            left: "34px",
                          }}
                        >
                          Queue
                        </td>
                      ) : item.status === 1 ? (
                        <td
                          className={cx(
                            styles.font16,
                            styles.fontRegular,
                            styles.colorYellow
                          )}
                          style={{
                            padding: "0.75em",
                            color: "#FCB902",
                            position: "relative",
                            left: "34px",
                          }}
                        >
                          In-Progress
                        </td>
                      ) : (
                        <td
                          className={cx(
                            styles.font16,
                            styles.fontRegular,
                            styles.colorGreen
                          )}
                          style={{ padding: "0.75em" }}
                        >
                          Complete
                        </td>
                      )}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {/* <div
          className={cx(styles.listTable, styles.colFlex, styles.centered)}
          style={{ height: "33.5em" }}
        >
          <TicketIcon style={{ padding: "1em", width: "6em" }} fill="#cbc9c9" />
          <span
            className={cx(
              styles.font21,
              styles.fontSemibold,
              styles.colorOceanGray
            )}
          >
            No Tickets Assigned
          </span>
        </div> */}
      </div>
      <ReactPaginate
        ref={paginate}
        initialPage={1}
        forcePage={currentPage}
        previousLabel={<PageBackwardIcon fill="#41cbb6" />}
        previousClassName={cx(styles.rowFlex, styles.pageLink, styles.centered)}
        previousLinkClassName={cx(styles.rowFlex, styles.font12)}
        nextLabel={<PageForwardIcon fill="#41cbb6" />}
        nextClassName={cx(styles.rowFlex, styles.pageLink, styles.centered)}
        nextLinkClassName={cx(styles.rowFlex, styles.font12)}
        breakLabel={". . ."}
        breakClassName={styles.pageLink}
        breakLinkClassName={cx(
          styles.font12,
          styles.fontRegular,
          styles.colorBlack,
          styles.rowFlex,
          styles.centered
        )}
        pageCount={Math.ceil(10)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        // onPageChange={handlePageClick}
        containerClassName={cx(styles.rowFlex, styles.pageBar, styles.centered)}
        pageClassName={styles.pageLink}
        pageLinkClassName={cx(
          styles.pageLink,
          styles.font12,
          styles.fontRegular,
          styles.colorBlack,
          styles.rowFlex,
          styles.centered
        )}
        activeLinkClassName={styles.activePageLink}
        activeClassName="Active"
      />
    </React.Fragment>
  );

  return (
    <div className={styles.maincontainer}>
      <Header />
      <div className={styles.mainSection}>
        <Sidebar />
        <div className={styles.container}>
          <div>
            <span
              className={cx(
                styles.font14,
                styles.fontMedium,
                styles.colorBlack
              )}
            >
              Dashboard {">"}{" "}
            </span>
            <span
              className={cx(
                styles.font14,
                styles.fontSemibold,
                styles.colorShamrock
              )}
            >
              Tickets Assigned to me
            </span>
          </div>
          <div className={cx(styles.rowFlex, styles.spaceBetween)}>
            <div
              className={cx(
                styles.font21,
                styles.fontSemibold,
                styles.colorBlack
              )}
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "33%",
              }}
            >
              Tickets Assigned to me
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "34%",
              }}
            >
              <button
                className={cx(
                  styles.roundedButton,
                  styles.font16,
                  styles.fontMedium,
                  styles.colorShamrock
                )}
                onClick={() => setFilterOpen(!filterOpen)}
              >
                {filterActive ? (
                  <ActiveFilterListIcon
                    fill="#41cbb6"
                    className={styles.filterListIcon}
                  />
                ) : (
                  <InactiveFilterListIcon
                    fill="#41cbb6"
                    className={styles.filterListIcon}
                  />
                )}
                Filter
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "33%",
              }}
            >
              <Link
                to="/Grievance-Portal/RaiseTicket"
                className={cx(
                  styles.smoothButton,
                  styles.font16,
                  styles.fontMedium,
                  styles.colorWhite
                )}
                style={{ textDecoration: "none" }}
              >
                <PlusIcon fill="#ffffff" className={styles.plusIcon} /> Raise a
                Ticket
              </Link>
            </div>
          </div>
          {filterOpen ? (
            <FilterForm
              // requestorNames={ticketsData.map((x) => x.requestorName)}
              // categories={ticketsData.map((x) => x.category)}
              showRequestorName={true}
              onFilter={filterData}
            />
          ) : (
            <React.Fragment />
          )}
          {content}
        </div>
      </div>
    </div>
  );
};

export default ViewReceivedTickets;
