import React from "react";

import style from "./dashboard.module.css";
// import { useHistory } from "react-router-dom";
import SRC_ASSET_LAPTOP from "../../../assets/assetsmangement/laptop.png";

const Dashboard = (props) => {
  // const history = useHistory();

  return (
    <>
      <div className={style.mainContainer}>
        <span>{"> Dashboard"}</span>
        <br />
        <text>Dashboard</text>
      </div>
      <div className={style.boxesContainer}>
        {["Laptops", "Mobiles", "Tablets"].map((item, index) => (
          <div className={style.boxes}>
            <div className={style.imgCircle}>
              <img src={SRC_ASSET_LAPTOP} alt="img" />
            </div>
            <div className={style.bottomContainer}>
              <text>60</text>
              <br />
              <span>{item}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Dashboard;
