import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../CreatePartner/CreatePartner.module.css";
import { useMutation, useQuery } from "react-query";
import { createPartner } from "../../../../Utils/axios/PortalManagement";
import Select from "react-select";
import { InvoiceStyles4 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import { IoIosAddCircle } from "react-icons/io";
import calender from "../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import cancel from "../../../../assets/cancell.png";
import moment from "moment";

import {
  getCityList,
  getStateList,
  addViewCity,
} from "../../../../Utils/axios/PortalManagement";
const CreatePartner = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPag,
}) => {
  const [formData, setFormData] = useState({
    partnerName: "",
    gstin: "",
    addressLine1: "",
    addressLine2: "",
    country: "",
    cityId: "",
    stateId: "",
    pinCode: "",
    linkedinLink:"",
    primaryContactName: "",
    primaryContectEmail: "",
    primaryMobileNumber: "",
    seconderyContactName: "",
    seconderyContectEmail: "",
    seconderyMobileNumber: "",
    empId: Number(localStorage.getItem("employeeId")),
  });
  const mutation = useMutation(createPartner, {
    onSuccess: (res) => {
      setPrevData((prevData) => ({
        ...prevData,
        CreateId: res?.id,
      }));

      setNextScreen("ViewCreatePartner");
    },
  });
  const [validate, setValidate] = React.useState(false);
  const [validate1, setValidate1] = React.useState(false);

  const [show, setShow] = useState(false);
  const [AddCity, setAddCity] = useState("");

  const mutationCity = useMutation(addViewCity, {
    onSuccess: (res) => {
      refetch();
      console.log({ res });
    },
  });

  console.log("AddCity", AddCity);
  const handleAdd = () => {
    if (AddCity === "") {
      setValidate1(true);
    } else {
      mutationCity.mutate({ id: AddCity });
      setValidate1(false);
      setShow(false);
    }
  };

  const handleAddCancle = () => {
    setShow(false);
  };

  const handleShow = (expenseId) => {
    setShow(true);
  };

  const handleCreatePartner = () => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (
      formData?.partnerName.trim() &&
      formData?.gstin.trim() &&
      formData?.addressLine1.trim() &&
      formData?.addressLine2.trim() &&
      formData?.country.trim() &&
      formData?.cityId &&
      formData?.stateId &&
      formData?.pinCode.trim() &&
      formData?.primaryContactName &&
      isValidEmail(formData?.primaryContectEmail) &&
      formData?.primaryMobileNumber.trim() &&
      formData.primaryMobileNumber.length >= 10
    ) {
      setValidate(false);
      mutation.mutate(formData);
    } else {
      setValidate(true);
    }
  };
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const { data: GetCityTypeData, refetch } = useQuery(
    ["getCityList"],
    getCityList
  );
  GetCityTypeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const GetCityDataOption = GetCityTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: GetStateTypeData } = useQuery(["getStateList"], getStateList);
  GetStateTypeData?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const GetStateDataOption = GetStateTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          alt="cancel"
          width="10px"
          height="10px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          alt="calender"
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>
              Create Partner
            </h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={4}>
              <p className={classes.label}>
                Partner Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    partnerName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.partnerName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Partner Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
                GSTIN <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gstin: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.gstin.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter GSTIN
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>Company Size</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    companySize: e.target.value,
                  });
                }}
              />
              {/*<p
                className={
                  validate && !formData?.gstin.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter GSTIN
              </p>*/}
            </Col>

            <Col md={4}>
              <p className={classes.label}>
                Address 1 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine1: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine1.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 1
              </p>
            </Col>
            <Col md={4} style={{ marginTop: "-10px" }}>
              <p className={classes.label1}>
                Address 2 <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    addressLine2: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.addressLine2.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Address 2
              </p>
            </Col>
            <Col md={4} style={{ marginTop: "-8px" }}>
              <p className={classes.label1}>Payment Due Date</p>
              <div className={classes.cu_field23Last}>
                <DatePicker
                  placeholder=" DD MM YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={
                    formData?.paymentDueDate
                      ? moment(formData?.paymentDueDate).toDate()
                      : null
                  }
                  maxDate={moment().toDate()}
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      paymentDueDate: moment(date).toISOString(),
                    });
                  }}
                  dateFormat="dd MMM yy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  customInput={
                    formData?.paymentDueDate ? (
                      <CustomInputCross properties="paymentDueDate" />
                    ) : (
                      <CustomInputClg />
                    )
                  }
                />
              </div>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>
                City <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetCityDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    cityId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.cityId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter City
              </p>
            </Col>
            <Col md={1}>
              <IoIosAddCircle
                className={classes.remove_icon}
                size={30}
                onClick={() => {
                  handleShow();
                }}
              />
            </Col>
            <Col md={3}>
              <p className={classes.label1}>
                State <span style={{ color: "red" }}>*</span>
              </p>
              <Select
                options={GetStateDataOption}
                styles={InvoiceStyles4}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    stateId: e?.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.stateId
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter State
              </p>
            </Col>
            <Col md={2}>
              <p className={classes.label1}>
                Pincode <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    pinCode: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.pinCode.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter pincode
              </p>
            </Col>
            <Col md={2}>
              <p className={classes.label1}>
                Country <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    country: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.country.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Country
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label}>
              LinkedIn Url
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    linkedinLink: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Name <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContactName: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate && !formData?.primaryContactName.trim()
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                Please Enter Name
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Email ID <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    primaryContectEmail: e.target.value,
                  });
                }}
              />
              <p
                className={
                  validate &&
                  (!formData?.primaryContectEmail ||
                    !isValidEmail(formData?.primaryContectEmail))
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {validate &&
                (!formData?.primaryContectEmail ||
                  !isValidEmail(formData?.primaryContectEmail))
                  ? "Please Enter a Valid Email"
                  : "Please Enter Name"}
              </p>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>
                Mobile Number <span style={{ color: "red" }}>*</span>
              </p>
              <input
                type="number"
                className={classes.smallInput}
                maxLength={10}
                minLength={10}
                value={formData?.primaryMobileNumber}
                onChange={(e) => {
                  let inputVal = e.target.value;
                  if (inputVal.length > 10) {
                    inputVal = inputVal.slice(0, 10);
                  }
                  console.log({ inputVal });
                  setFormData({
                    ...formData,
                    primaryMobileNumber: inputVal,
                  });
                }}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) =>
                  (e.keyCode === 38 ||
                    e.keyCode === 40 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.preventDefault()
                }
                onKeyPress={(e) =>
                  ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                    e.which > 57 ||
                    e.key === "e" ||
                    e.key === "-") &&
                  e.prevent
                }
              />
              <p
                className={
                  validate &&
                  (!formData?.primaryMobileNumber ||
                    formData.primaryMobileNumber.length < 10)
                    ? classes.errorText
                    : classes.errorTextHidden
                }
              >
                {formData?.primaryMobileNumber &&
                formData.primaryMobileNumber.length < 10
                  ? "Mobile number should have at least 10 digits"
                  : "Please Enter Mobile Number"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.SecondaryContact}>Secondary Contact</h6>
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Name</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContactName: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Email ID</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyContectEmail: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={4}>
              <p className={classes.label1}>Mobile Number</p>
              <input
                type="text"
                className={classes.smallInput}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    seconderyMobileNumber: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>
      <button className={classes.buttonAdd} onClick={handleCreatePartner}>
        Submit
      </button>

      <style type="text/css">
        {`
      .modal-content {
        width: 80%;
      }
    `}
      </style>
      <Modal
        show={show}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "7px" }}>
          Do you want to add City ?
        </h6>
        <p className={classes.label_item1}>
          City Name <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={classes.smallInput1}
          onChange={(e) => setAddCity(e.target.value)}
        />
        <p
          className={
            validate1 && !AddCity
              ? classes.errorText1
              : classes.errorTextHidden1
          }
        >
          Please Enter City Name
        </p>
        <div className={classes.buttonText}>
          <button className={classes.buttonAdditem} onClick={handleAdd}>
            Yes
          </button>
          <button className={classes.buttonCancle} onClick={handleAddCancle}>
            No
          </button>
        </div>
      </Modal>
    </>
  );
};
export default CreatePartner;
