/* eslint-disable import/no-anonymous-default-export */
// import dotenv from 'dotenv'
export default {
  apiIpPort: process.env.REACT_APP_API_ENDPOINT,
  apiBaseUrl: process.env.REACT_APP_EMPLOYEEONBOARDING_APP_URL,
  apiBaseUrl2: process.env.REACT_APP_TASKTRACKER_APP_URL,
  apiBaseUrlGp: process.env.REACT_APP_GRIEVANCE_APP_URL,
  apiBaseUrlResource: process.env.REACT_APP_RESOURCEMASTER_APP_URL,
  // Rasik Client Id:
  // googleClientId:"966712598135-3e2nd0ti2o7vi6afgd3f3f2apgp6llj9.apps.googleusercontent.com",
  // Shikha Client Id:
  // googleClientId:"673234244387-oo9b2ahej2fhtgpoevi7hfo2pb1d9dip.apps.googleusercontent.com",
  // googleClientId: "143325708461-auukdqgh77auto3re0f73sc84t2dk84c.apps.googleusercontent.com"

  // Amar clientId
  // googleClientId: "1076720355728-b8dfhtubvj3ueogglpeqqs4kg64p247d.apps.googleusercontent.com"

  // apiIpPort: "http://65.1.177.11:8080",
  // apiBaseUrl: "/employee/v1",
  // apiBaseUrl2: "/tasktracker/taskTracker/v1",

  // QA
  // apiIpPort: "http://13.234.79.73:8080",
  // apiBaseUrl: "/foxmatrixapp/v1/api/",

  //production
  // apiIpPort:"https://services.foxmatrix.com",
  // apiBaseUrl:"/foxmatrixapp/v1/api/"

  //Staging
  // apiIpPort: "http://13.127.87.149:8080",
  // apiBaseUrl: "/foxmatrixapp/v1/api/",

  //  apiBaseUrl: "/tasktracker/taskTracker/v1/updateTask",
  //  apiBaseUrl: "/employee/v1/getEmployeeList",

  // company client
  //sadhvi client ID
  // googleClientId:
  //   "784589856430-6doef1vja61cm270fn6sd4fm1mddjbuu.apps.googleusercontent.com",

  // nufolks
  // googleClientId:
  //   "15093656597-i5m65d14f49lpg9vpiab39b7i3sjnrt2.apps.googleusercontent.com",

  // googleClientId:
  //   "15093656597-6l6272iof4fcdr6otd39up3e53i85chd.apps.googleusercontent.com",
  googleClientId:
    "784589856430-6doef1vja61cm270fn6sd4fm1mddjbuu.apps.googleusercontent.com",
};
export const employeeId = parseInt(localStorage.getItem("employeeId"))
// export const updateData = (data) => API.post("/tasktracker/taskTracker/v1/updateTask",data)
// export const postData = () => API.post("/tasktracker/taskTracker/v1/createTask")
