import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import cx from "classnames";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Sidebar from "../commonComponent/SideBar";
import Header from "../commonComponent/Header";
import styles from "./General.module.css";
import SettingsIcon from "@mui/icons-material/Settings";
import ExtensionIcon from "@mui/icons-material/Extension";
import DvrIcon from "@mui/icons-material/Dvr";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import cookie from "react-cookies";
const RaiseTicket = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [department, setDepartment] = useState("");
  const [category, setCategory] = useState("");
  const [plannedEndDate, setPlannedEndDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const departmentAssigneeMapping = {
    "Lorem ipsum": "Robert Percival",
    "Ipsum dolor": "Silena Rogers",
    "Dolor sit": "Karl Benz",
    "Sit amet": "Netra Mohan",
    "Amet lorem": "Sashikant Mishra",
  };
  const handleDepartment = (event) => {
    setDepartment(event.target.value);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  const handlePlannedEndDate = (date) => {
    setPlannedEndDate(date);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleNotes = (event) => {
    setNotes(event.target.value);
  };

  const updateData = () => {
    const data = {
      createdBy: "InnovationM",
      createdID: "12",
      updatedBy: "Developers",
      updatedID: "13",
      devicetype: "laptop",
      os: "windows",
      type: "designer",
      invoice: "2133121",
      general: "great",
      specs: "4GB RAM",
      software: [
        {
          name: "msoffice",
        },
      ],
      purchase: "TEsla",
      insurance: "active",
      warranty: "1yr",
      antivirus: "Avast",
      adp: "2yr",
      machinehealth: "Good",
      comments: "Systems is good",
    };

    axios
      .post(`http://localhost:3001/device`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log("respone", res.data.data);
        // setQueryList(res.data.data)
      });
  };

  useEffect(() => {
    updateData();
  }, []);

  return (
    <div className={styles.maincontainer}>
      <Header />
      <div className={styles.mainSection}>
        <Sidebar />
        <div className={styles.container}>
          <div className={cx(styles.rowFlex, styles.spaceBetween)}>
            <div
              className={cx(
                styles.font21,
                styles.fontSemibold,
                styles.colorBlack
              )}
            >
              {" "}
              Add Laptop{" "}
            </div>
          </div>

          <form>
            <div className={styles.formContainer}>
              {/* <Card> */}
              <Container>
                <div style={{ display: "flex" }}>
                  <div>
                    <p
                      className={cx(
                        styles.font14,
                        styles.fontMedium,
                        styles.colorHead
                      )}
                    >
                      General
                    </p>
                    <hr className={styles.head} />
                  </div>
                  <div className={styles.values}>
                    <p
                      className={cx(
                        styles.font14,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Purchase
                    </p>
                  </div>
                  <div className={styles.values}>
                    <p
                      className={cx(
                        styles.font14,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Protection
                    </p>
                  </div>
                  <div className={styles.values}>
                    <p
                      className={cx(
                        styles.font14,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Others
                    </p>
                  </div>
                </div>
                <hr className={styles.section1} />
                <p style={{ display: "flex", marginTop: "2%" }}>
                  <SettingsIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    General
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Serial Number
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Brand
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Model
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "2%" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      MAC Address
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      Type
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <hr className={styles.section} />
                <p style={{ display: "flex", marginTop: "2%" }}>
                  <ExtensionIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    Specifications
                  </span>
                </p>
                <div style={{ display: "flex" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Processor
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      RAM
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                  <div className={styles.values}>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Hard Disk
                    </label>
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "2%" }}>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Screen Size
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
                <hr className={styles.section} />
                <p style={{ display: "flex", marginTop: "2%" }}>
                  <DvrIcon color="disabled" fontSize="large" />
                  <span
                    className={cx(
                      styles.font116,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity,
                      styles.heading
                    )}
                  >
                    {" "}
                    Softwares
                  </span>
                </p>
                <div>
                  <div>
                    <label
                      className={cx(
                        styles.font12,
                        styles.fontMedium,
                        styles.colorBlack,
                        styles.labelOpacity
                      )}
                    >
                      {" "}
                      Software
                    </label>{" "}
                    <br />
                    <input
                      type="text"
                      id="fname"
                      name="fname"
                      className={styles.input}
                    ></input>
                  </div>
                </div>
              </Container>
              {/* </Card> */}
            </div>
            <div className={styles.buttonRow}>
              <div>
                <button
                  onClick={() => history.push("/ITassests/LaptopPurchase")}
                  className={cx(
                    styles.button,
                    styles.font12,
                    styles.fontMedium,
                    styles.colorWhite
                  )}
                  style={{ cursor: "pointer" }}
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RaiseTicket;
