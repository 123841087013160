import React, { useState } from "react";
import SideBar from "../../sidebar/SideBar";
import style from "./confirmation.module.css";
import {
  getConfirmationList,
  updateConfirmationComment,
} from "../../../../Utils/axios/umsApi";
import { useQuery } from "react-query";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { CgNotes } from "react-icons/cg";
import { toast, ToastContainer } from "react-toastify";
import ConfirmPopup from "../viewUser/ConfirmPopup";
import { useMutation } from "react-query";

const ConfirmationEmployee = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [empId, setEmpId] = useState(null);
  const history = useHistory();
  const { data, isLoading, isError, refetch } = useQuery(
    ["getConfirmationList", currentPage],
    () => getConfirmationList(currentPage)
  );
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push("/userDescription", { id });
    }, 0);
  };
  const mutation = useMutation(updateConfirmationComment, {
    onSuccess: (res) => {
      notifySuccess("Confirmation remark updated successfully");
      refetch();
    },
    onError: () => {
      notify("Something went wrong");
    },
  });
  const notifySuccess = (success) =>
    toast.success(success, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const notify = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  return (
    <>
      <ToastContainer />
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.Exit}>Upcoming Confirmation</div>
          <div className={style.UsersList}>
            <div className={style.th}>
              <p>Emp Code</p>
              <p>Name</p>
              <p>Duration</p>
              <p>Confirmation Date</p>
              <p>Actual Confirmation Date</p>
              <p></p>
            </div>
            {isLoading && <div className={style.no_data}> Loading...</div>}
            {isError && (
              <div className={style.no_data}>Something went wrong</div>
            )}
            {!isLoading && !isError ? (
              data?.getConfirmations?.length > 0 ? (
                data?.getConfirmations?.map((e, key) => {
                  return (
                    <div className={style.td} key={key}>
                      <p onClick={() => handleRedirect(e.empId)}>
                        {e?.empCode}
                      </p>
                      <p>{e?.empName}</p>
                      <p>{e?.duration}</p>
                      <p>
                        {e?.actualConfirmationDate &&
                          moment(e?.actualConfirmationDate).format("DD MMM YY")}
                      </p>
                      <p>
                        {e?.assignedConfirmationDate &&
                          moment(e?.assignedConfirmationDate).format(
                            "DD MMM YY"
                          )}
                      </p>
                      <p
                        onClick={() => {
                          setShowNotes(true);
                          setNotes(e?.remarks);
                          setEmpId(e?.empId);
                        }}
                      >
                        {e?.remarks && <CgNotes style={{ color: "#289e98" }} />}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className={style.no_data}>No Data</div>
              )
            ) : null}
          </div>
          <ConfirmPopup open={showNotes}>
            <div className={style.confirmPopup}>
              <div>
                <textarea
                  type="textarea"
                  className={style.textarea}
                  value={notes}
                  onChange={(e) => setNotes(e?.target.value)}
                />
              </div>
              <div style={{ textAlign: "center", marginTop: "1rem" }}>
                <button
                  className={style.delete_btn}
                  onClick={() => setShowNotes(false)}
                >
                  Cancel
                </button>
                <button
                  className={style.delete}
                  onClick={() => {
                    mutation.mutate({
                      empId: empId,
                      comments: notes,
                    });
                    setShowNotes(false);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </ConfirmPopup>
          <div style={{ marginRight: "29px" }}>
            <Pagination
              pagesCount={data?.count / 10}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              color="#289e98"
              color2="white"
            />
          </div>
        </div>
      </div>
      ;
    </>
  );
};
export default ConfirmationEmployee;
