import React, { useEffect, useState } from "react";
import axios from "axios";
import UseReducer from "./../../Utils/usereducer";
import style from "./SignIn.module.scss";
import { GoogleLogin } from "react-google-login";
import landingImage from "./../../assets/landingPage@2x.png";
import Logo from "./../../assets/innovationm-navbar-logo@2x copy.png";
import LogoDark from "./../../assets/innovationm-navbar-logo@2x.png";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import InnovationMLogo from "../../assets/Grievance portal/image-innovationm-logo@2x.png";
// import axiosFun from '../../Utils/axiosFun';
import post_req from "../../Utils/axiosFun";
import dotenv from "dotenv";
import { get } from "lodash";

import { useCookies } from "react-cookie";
import constants from "../../Utils/constants";

function SignIn() {
  let history = useHistory();

  useEffect(() => {
    if (cookie.load("token")) {
      window.location = "/dashboard";
    }
  });
  const [errormsg, setErrorMsg] = useState("");

  const responseGoogle = (response, error) => {
    console.log(response, error, window);

    if (!response) {
      alert("error");
    }

    localStorage.setItem("name", response.profileObj?.name);
    localStorage.setItem("email", response.profileObj?.email);
    localStorage.setItem("googleId", response.profileObj?.googleId);

    console.log("token", response?.headers);
    cookie.save("profile", response?.profileObj);
    cookie.save("googleId", response?.profileObj?.googleId);

    const url = constants.apiBaseUrl;

    const data = {
      email: localStorage.getItem("email"),
      name: localStorage.getItem("name"),
      token: localStorage.getItem("googleId"),
    };

    axios
      .post(url + "/signin", data)
      .then((res) => {
        console.log("signinapi", res);
        localStorage.setItem("employeeId", res.data.responseData.employeeId);
        if (res.status == 200) {
          if (res.data.responseData.signInSuccessful) {
            const resdataObj = {
              Authorization: `${res.headers["authorization"]}`,
            };
            cookie.save("token", resdataObj.Authorization);
            history.push("/dashboard");
            window.location.reload();
          }
          if (res.data.message) {
            setErrorMsg("User not Registered with application");
          }
        }
        return res;
      })
      .catch((err) => {
        history.push("/dashboard");
        console.log(err);
        return err;
      });
  };

  return (
    <div className={style.container}>
      <div className={style.image}>
        <img src={landingImage} alt="Login" />
      </div>
      <div className={style.content}>
        <img src={Logo} alt="logo" className={style.logo} />
        <img src={LogoDark} alt="logo" className={style.logoDark} />
        <img src={landingImage} alt="Login" className={style.landingImage} />
        <h1>IM Portal Apps</h1>
        <p className={style.subText}>
          Sign In using your Registered Google Account
        </p>

        <GoogleLogin
          clientId={constants.googleClientId}
          onSuccess={responseGoogle}
          // onFailure={()}
          cookiePolicy={"single_host_origin"}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className={style.googleButton}
            ></button>
          )}
        />
        <p className={style.errormsg}>&nbsp;{errormsg}</p>
      </div>
    </div>
  );
}

export default SignIn;
