import React from "react";
import { Link } from "react-router-dom";
// import DeleteIcon from "@mui/icons-material/Delete";
import style from "./Table.module.css";
import EditIcon from "../../../../assets/editIcon.png";
import { useHistory } from "react-router-dom";
import styledEngine from "@mui/styled-engine";

const TableRow = (props) => {
  const history = useHistory();
  return (
    <tr style={{ borderBottom: "2px solid #F0F0F0", textAlign: "start", fontSize:"11.5px"}}>

<Link
        to={`/clientdescription?id=${props.id}`}
        className={style.backColor}
      >
        <td
          style={{
            padding: "10px 0px",
            textTransform: "capitalize",
            fontWeight: "bolder",
            width: "35%",
            textDecorationLine: "underline",
          }}
        >
          {props.name}
        </td>
      </Link>
      <td style={{ width: "40%" }}>
        <p className={style.domain}>{props.domain}</p>
      </td>

      <td
        style={{
          width: "15%",
          color:
            props.status === "Inactive"?
               "red":"black"
             
        }}
      >
        {props.status}
      </td>
    </tr>
  );
};

export default TableRow;

{
  /* <button style={{ color: state === "active" ? "#F00" : "#00F" }}> */
}
