import React from "react";
import BreadCrum from "./../../../breadcrums/BreadCrum";
import style from "./reportCard.module.scss";
const ReportCard = ({ prevData, setPrevData, setNextScreen }) => {
  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      />

      <div className={style.dashboardOptions}>
        <div
          className={style.taskTracker}
          onClick={() => {
            setNextScreen("empanelmentReport");
            setPrevData((prevData) => ({
              ...prevData,
              page: "positionALL",
              sidebar: "report",
              page1: "report",
              page2: "empanelmentReport",
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              prevFilterData: null,
              prevFilter: null,
              active: "empanelmentReport",
              name: "",
            }));
          }}
        >
          <p className={style.optionsText}>Partner Empanelment Report </p>
        </div>
      </div>
    </>
  );
};

export default ReportCard;
