import axios from "axios";
const apiKey = process.env.REACT_APP_Expence_URL;
const url = process.env.REACT_APP_HRHELPDESK_APP_URL;
console.log(apiKey);
export const createExpense = async (data) => {
  console.log(data,"ds")
  const response = await axios.post(`${apiKey}/createExpense`, data);
  return response.data.responseData;
};
export const expenseList = async (
  limit,
  page,
  expenseMonth,
  categoryId,
  PaymentDateId,
  paymentStatus,
  vendorId,
  ) => {
    if (
      !expenseMonth &&
      !categoryId &&
      !PaymentDateId &&
      !paymentStatus &&
      !vendorId 
      
    ){
      const response = await axios.get(
        `${apiKey}/expenseList?limit=${limit}&page=${page}`
        );
      return response.data.responseData;
    }else{
      const response = await axios.get(
        `${apiKey}/expenseList?limit=${limit}&page=${page}&categoryId=${categoryId}&expenseMonth=${expenseMonth.toString()}&paymentStatus=${paymentStatus}&vendorId=${vendorId}`
      );
      return response.data.responseData;
    }
  
};
export const getVendorName = async () => {
  const response = await axios.get(`${apiKey}/getVendorName`);
  return response.data.responseData;
};

export const getMonth = async () => {
  const response = await axios.get(`${apiKey}/getMonth`); 
  return response.data.responseData;
};
export const getYear = async () => {
  const response = await axios.get(`${apiKey}/getYear`);
  return response.data.responseData;
};
export const getExpenseCategory = async (data) => {
  const response = await axios.get(`${apiKey}/getExpenseCategory`,data);
  return response.data.responseData;
};
export const createInvoice = async (data) => {
  console.log("datassss", data);
  const response = await axios.post(`${apiKey}/createInvoice`, data);
  return response.data.responseData;
};
export const getClientName = async () => {
  const response = await axios.get(`${apiKey}/getClientName`);
  return response.data.responseData;
};

export const invoiceListData = async (
  limit,
  page,
  filterData,
  AdvanceFilter,
  clientIdFilter,
  amountToBeReceivedFilter,
  paymentStatusFilter,
  paymentDateFilter
) => {
  console.log("vvvvv", filterData);
  if (
    !filterData &&
    !AdvanceFilter &&
    !clientIdFilter &&
    !amountToBeReceivedFilter &&
    !paymentStatusFilter &&
    !paymentDateFilter
  ) {
    const response = await axios.get(
      `${apiKey}/invoiceList?limit=${limit}&page=${page}`
    );
    return response.data.responseData;
  } else {
    const response = await axios.get(
      `${apiKey}/invoiceList?limit=${limit}&page=${page}&projectId=${filterData}&advanceStausId=${AdvanceFilter}&clientId=${clientIdFilter}&amountToBeReceived=${amountToBeReceivedFilter}&paymentStatus=${paymentStatusFilter}&paymentDate=${paymentDateFilter}`
    );
    return response.data.responseData;
  }
};
export const getServiceType = async () => {
  const response = await axios.get(`${apiKey}/getServiceType`);
  return response.data.responseData;
};

export const getCurrencyType = async () => {
  const response = await axios.get(`${apiKey}/getCurrency`);
  return response.data.responseData;
};

export const getAdvanceStatus = async () => {
  const response = await axios.get(`${apiKey}/getAdvanceStatus`);
  return response.data.responseData;
};
export const getExpenseId = async (id) => {
  const response = await axios.get(`${apiKey}/viewExpense/${id}`);
  return response.data.responseData;
};

export const getInvoiceId = async (id) => {
  const response = await axios.get(`${apiKey}/viewInvoice/${id}`);
  return response.data.responseData;
};

export const EditInvoiceId = async (data) => {
  const response = await axios.post(`${apiKey}/createInvoice`, data.formData);
  return response.data.responseData;
};

export const getAmountToBeReceived = async () => {
  const response = await axios.get(`${apiKey}/getAmountToBeReceived`);
  return response.data.responseData;
};

export const getPaymentStatus = async () => {
  const response = await axios.get(`${apiKey}/getPaymentStatus`);
  return response.data.responseData;
};

export const getPaymentDate = async () => {
  const response = await axios.get(`${apiKey}/getPaymentDate`);
  return response.data.responseData;
};

export const getcopyInvoice = async (id) => {
  console.log("idddd",id);
  const response = await axios.get(`${apiKey}/copyInvoice/${id}`);
  return response.data.responseData;
}
export const getcopyExpense = async (Id) => {
  const response = await axios.get(`${apiKey}/copyExpense/${Id}`);
  return response.data.responseData;
}
export const DeleteExpense = async (id) => {
  const response = await axios.put(
    `${apiKey}/deleteExpense/${id}`
  );
  return response?.data?.responseData;
};
export const DeleteInvoice = async (id) => {
  const response = await axios.put(
    `${apiKey}/deleteInvoice/${id}`
  );
  return response?.data?.responseData;
};


export const createvendor = async (data) => {
  const response = await axios.post(`${apiKey}/createvendor`, data);
  return response.data.responseData;
};


export const createcategory = async (data) => {
  const response = await axios.post(`${apiKey}/createcategory`, data);
  return response.data.responseData;
};

export const createClient = async (data) => {
  console.log("datassss", data);
  const response = await axios.post(`${apiKey}/createclient`, data);
  return response.data.responseData;
};


// hr help Desk
export const getUserAccess = async () => {
  const response = await axios.get(`${url}/getUserAccess?userMail=${localStorage.getItem("email")}`);
  return response.data.responseData?.roleName;
};