import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Nav,
  Navbar,
} from "react-bootstrap";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import laptop from "../../../../../assets/laptop.png";
import Select from "react-select";
import styles from "./AddnewAssignment.module.css";

const AddnewAssigntComp = () => {
  return (
    <>
      <div className="main">
        <Container>
          <Row>
            <Col sm={12} className="hed"></Col>
          </Row>
        </Container>
        <Card className="_card">
          <Container>
            <Row>
              <Col sm={2}>
                <img className="mt-0 mb-1" src={laptop} alt="laptop_img" />
              </Col>
              <Col>
                <Container className="mb-3 mt-0">
                  <Row>
                    <Col>
                      <label> Serial Number</label> <br />
                      <input
                        type="text"
                        className="_input"
                        id="fname"
                        name="fname"
                        placeholder="123456789"
                      ></input>
                    </Col>
                    <Col>
                      <label>Brand</label> <br />
                      <input
                        type="text"
                        className="_input"
                        id="fname"
                        name="fname"
                        placeholder="DELL"
                      ></input>
                    </Col>
                    <Col>
                      <label>RAM</label> <br />
                      <input
                        type="text"
                        className="_input"
                        id="fname"
                        name="fname"
                        placeholder="16GB"
                      ></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-2">
                      <label> MAC Address</label> <br />
                      <input
                        type="text"
                        className="_input"
                        id="fname"
                        name="fname"
                        placeholder="123456789jhkj"
                      ></input>
                    </Col>
                    <Col className="mt-2">
                      <label>Type</label> <br />
                      <input
                        type="text"
                        className="_input"
                        id="fname"
                        name="fname"
                        placeholder="kuch bhi"
                      ></input>
                    </Col>
                    <Col className="mt-4">
                      <label className="mt-1">
                        View Complete Details <ArrowRightAltIcon />
                      </label>
                      <br />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Card>
        <Container>
          <Row>
            <Col sm={12} className="mt-3">
              <sapn className="hed">Add New Assigment</sapn>
            </Col>
          </Row>
        </Container>
        <Card className="_card">
          <Container className="p-3">
            <Row>
              <Col>
                {" "}
                <label> Assigment Date</label> <br />
                <select className={styles.input}></select>
              </Col>
              <Col>
                {" "}
                <label>Assigment To</label> <br />
                <select className={styles.input}></select>
              </Col>
              <Col>
                {" "}
                <label> Assigment By</label> <br />
                <select className={styles.input}></select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm={4} className="mt-3">
                <label>Return Date</label> <br />
                <select className={styles.input}></select>
              </Col>
              <Col sm={8} className="mt-3">
                <label> Retrun Location</label> <br />
                <select className={styles.input}></select>
              </Col>
            </Row>
          </Container>
        </Card>
        <Container>
          <Row>
            <Col></Col>
            <Col className="btn d-flex justify-content-end mt-2">
              <Button
                style={{
                  border: "1px solid #0d6efd !important",
                  backgroundColor: "#fff",
                  color: "#0d6efd",
                }}
                className="btn-light2"
              >
                Cancel
              </Button>
              <Button style={{ marginLeft: "20px" }} variant="primary">
                Submit
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AddnewAssigntComp;
