import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import style from "./AtsNavigation.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useQuery } from "react-query";
import EPR from "../pages/EPR/EPR";
import EmployeeFeedback from "../pages/AddFeedback/EmployeeFeedback";
import EmployeeFeedbackList from "../pages/EmployeeFeedbackList/EmployeeFeedbackList";
import ViewFeedback from "../pages/ViewFeedback/ViewFeedback";
import EditEmployeeFeedback from "../pages/AddFeedback/EditEmployeeFeedback";

export default function EPRNavigation() {
  const [nextScreen, setNextScreen] = useState("EmployeePerformanceReview");
  const [currentPage, setCurrentPage] = useState(1);

  const [prevData, setPrevData] = useState({
    page: "EmployeePerformanceReview",
    sidebar: "EmployeePerformanceReview",
    page1: "EmployeePerformanceReview",
    active: "EmployeePerformanceReview",
  });

  const sidebarHandler = () => {
    switch (nextScreen) {
      case "EmployeePerformanceReview":
        return (
          <EPR
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "EmployeeFeedback":
        return (
          <EmployeeFeedback
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "editEmployeeFeedback":
        return (
          <EditEmployeeFeedback
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "EmployeeFeedbackList":
        return (
          <EmployeeFeedbackList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "viewFeedback":
        return (
          <ViewFeedback
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      <Header />
      <div className={style.mainSection}>
        <div style={{ width: "20%" }}>
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            // roles={roles}
          />
        </div>
        <div className={style.ATScontainer}>
          {/* <ProgressBar prevData={prevData} /> */}

          {sidebarHandler()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
