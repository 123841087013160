import React, { useState } from "react";
import cx from "classnames";
import { Container, Row, Col, Card } from "react-bootstrap";
import styles from "./General.module.css";
import SettingsIcon from "@mui/icons-material/Settings";
import ExtensionIcon from "@mui/icons-material/Extension";
import "react-datepicker/dist/react-datepicker.css";

const GeneralTab = () => {


    return (
   <div> 
     <form>
       <div>
                  
          {/* <Card> */}
            <Container>
              <p style={{display:"flex"}}>
                <SettingsIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116,styles.sectionHeading, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> General</span>
              </p>
              <div style={{display:"flex"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Serial Number</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Brand</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Model</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <div style={{display:"flex" , marginTop:"2%"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>MAC Address</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Type</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <hr className={styles.section}/>
              <p style={{display:"flex" , marginTop:"2%"}}>
                <ExtensionIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.sectionHeading,styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> Specifications</span>
              </p>
              <div style={{display:"flex"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Processor</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> RAM</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Hard Disk</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <div style={{display:"flex" , marginTop:"2%"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Screen Size</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              </Container>
                </div>
            </form>
            </div>
   
    );
};

export default GeneralTab;
