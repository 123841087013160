import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import classes from "../ViewCreatePartner/ViewCreatePartner.module.css";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import axios from "axios";
import moment from "moment";
const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;

const ViewCreatePartner = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPag,
}) => {
  const [PartnerDataById, setPartnerDataById] = useState();
  const getClientDetails = (id) => {
    console.log("hello");
    axios
      .get(baseUrl + `/getPartnerById?partnerId=${prevData?.CreateId}`, {})
      .then((res) => {
        console.log(res.data.responseData, "testtsts");
        setPartnerDataById(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClientDetails();
  }, [prevData?.CreateId]);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "15px", marginLeft: "25px" }}>View</h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Company Size</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.companySize}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Address 1</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.addressLine1}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Address 2</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.addressLine2}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Country</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.country}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>City</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>State</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.state}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>PinCode</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.pinCode}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label1}>Payment Due Date</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.paymentDueDate &&
                  moment(PartnerDataById?.paymentDueDate).format("DD MMM YY")}
              </p>
            </Col>
            <Col md={6}>
              <p className={classes.label1}>LinkedIn Url</p>
              <a
                href={PartnerDataById?.linkedinLink}
                target="_blank"
                rel="noopener noreferrer"
              
              >
                {" "}
                <p style={{textDecoration:"underLine",color:"blue",paddingLeft:"12px"}}>{PartnerDataById?.linkedinLink}</p>
              </a>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Primary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContactName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryContectEmail}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.primaryMobileNumber}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h6 className={classes.primaryContact}>Secondary Contact</h6>
            </Col>

            <Col md={3}>
              <p className={classes.label}>Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContactName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Email ID</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyContectEmail}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Mobile Number</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.seconderyMobileNumber}
              </p>
            </Col>
          </Row>
        </InvoiceContainer>
      </Container>
      <div className={classes.lastBtn_item}>
        <InvoiceButton
          onClick={() => {
            setNextScreen("EditViewPartner");
            setPrevData((prevData) => ({
              ...prevData,
              page2: null,
              page3: null,
              page4: null,
              active: "EditViewPartner",
              EditId: PartnerDataById?.id,
            }));
          }}
        >
          Edit
        </InvoiceButton>
        <InvoiceButton
          onClick={() => {
            setNextScreen("PartnerList");
            setPrevData((prevData) => ({
              ...prevData,
              page2: null,
              page3: null,
              page4: null,
              active: "PartnerList",
            }));
          }}
        >
          Done
        </InvoiceButton>
      </div>
    </>
  );
};
export default ViewCreatePartner;
