export { default as queryAPI } from "./queryAPI";
export { default as userAPI } from "./userAPI";
export { default as commentAPI } from "./commentAPI";
export { default as feedbackAPI } from "./feedbackAPI";

export const resolve = async (promise) => {
    const resolved = { data: null, error: null };
    try {
        resolved.data = await promise;
    } catch (err) {
        resolved.error = err;
    }
    return resolved;
};
