import React, { useState, useEffect } from "react";
import style from "./dashboard.module.scss";
import cookie from "react-cookies";
import Header from "../../GenericComponent/header/header.js";
import DashboardImage from "./../../assets/dashboardPage@2x.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import constants from "../../Utils/constants";
export default function Dashboard() {
  const history = useHistory();
  const [empAccess, SetEmpAccess] = useState([]);
  const [empAccess1, SetEmpAccess1] = useState();
  const day = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const baseUrl = constants.apiBaseUrl;
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;
  const getAccess = () => {
    axios
      .get(url + `/getUserAccess?userMail=${localStorage.getItem("email")}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        SetEmpAccess1(res.data.responseData.roleName);
      })
      .catch((err) => {
        return err;
      });
  };
  const getUserInfo = () => {
    axios
      .get(
        baseUrl +
          `/getUserApplications?userId=${localStorage.getItem("employeeId")}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData.applications;
        SetEmpAccess(data?.map((item) => item.applicationCode));
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    getUserInfo();
    getAccess();
  }, []);

  const HandleHrHelpDesk = () => {
    if (empAccess1 === null) {
      history.push("/MyIssue");
    } else {
      history.push("/HrHelpDeskAllIssue");
    }
    return null;
  };
  const handleQuestionnaire = () => {
    history.push("/QuestionList");
  };
  const profile = cookie.load("profile");
  const handleTaskTracker = () => {
    history.push("/taskTracker");
  };

  const handleResourceMaster = () => {
    history.push("/projectandResource");
  };

  const handleAtsDashboard = () => {
    history.push("/ats-navigation");
  };
  const handleUMDashboard = () => {
    history.push("/new-employee");
  };

  const handleExpInvDashboard = () => {
    history.push("/exin-navigation");
  };
  const handleExpInductionDashboard = () => {
    history.push("/Employee-Induction");
  };
  return (
    <>
      <Header />
      <div className={style.container}>
        <div className={style.topHeading}>
          <h1 className={style.heading}>IM Portal Apps</h1>
          <label className={style.date}>
            {day[new Date().getDay() - 1]} {new Date().getDate()}{" "}
            {month[new Date().getMonth()]} {new Date().getFullYear()}
          </label>
        </div>
        <section className={style.welcomeContainer}>
          <h1>Hi, {profile.name}</h1>
          <p className={style.welcomeHeading}>Welcome to IM Portal Apps</p>
          <p className={style.welcomePara}>
            Get all the information regarding your tasks, projects, resources
            and assets at a single platform.
          </p>
          <img
            alt="DashBoardImage"
            src={DashboardImage}
            className={style.dashboardImage}
          />
          <div className={style.firstCircle}></div>
          <div className={style.secondCircle}></div>
        </section>
        <section className={style.dashboardOptions}>
          {/* task tracker */}
          {empAccess.includes("TT") ? (
            <div className={style.taskTracker} onClick={handleTaskTracker}>
              <p className={style.optionsText}>Task Tracker</p>
            </div>
          ) : null}
          {/* rms dashboard */}
          {empAccess.includes("RMS") ? (
            <div
              className={style.projectResource}
              onClick={handleResourceMaster}
            >
              <p className={style.optionsText}>
                Resource Management <br />
                System ( RMS )
              </p>
            </div>
          ) : null}
          {/* User Management dashboard */}
          {empAccess.includes("UMS") ? (
            <div className={style.userMDashboard} onClick={handleUMDashboard}>
              <p className={style.optionsText}>
                User Management <br />
                System (UMS)
              </p>
            </div>
          ) : null}
          {/* ats dashboard */}
          {empAccess.includes("ATS") ? (
            <div className={style.atsDashboard} onClick={handleAtsDashboard}>
              <p className={style.optionsText}>
                Applicant Tracking System <br />
                (ATS)
              </p>
            </div>
          ) : null}

          {/* epr */}
          {empAccess.includes("EPR") ? (
            <div
              className={style.erpDashboard}
              onClick={() => history.push("/EmployeePerformanceReview")}
            >
              <p className={style.optionsText}>Employee Performance Review</p>
            </div>
          ) : null}

          {empAccess.includes("FE") ? (
            <div
              className={style.expInvDashboard}
              onClick={handleExpInvDashboard}
              style={{ cursor: "pointer" }}
            >
              <p className={style.optionsText}>Expense & Invoice</p>
            </div>
          ) : null}
          {empAccess.includes("IND") ? (
            <div
              className={style.expInvDashboard}
              onClick={handleExpInductionDashboard}
              style={{ cursor: "pointer" }}
            >
              <p className={style.optionsText}>Employee Induction</p>
            </div>
          ) : null}

          {empAccess?.includes("TS") ? (
            <div
              className={style.etsDashboard}
              onClick={() => history.push("/EmpolyeeTimeSheet")}
            >
              <p className={style.optionsText}>Employee Timesheet</p>
            </div>
          ) : null}

          {empAccess?.includes("PM") ? (
            <div
              className={style.partnerDashboard}
              onClick={() => history.push("/PortalManagement")}
            >
              <p className={style.optionsText}>Portal Master</p>
            </div>
          ) : null}

          {empAccess?.includes("HRHD") ? (
            <div
              className={style.hrHelpDeskDashBoard}
              onClick={HandleHrHelpDesk}
              style={{ cursor: "pointer" }}
            >
              <div>
                {/* <img src={ats} alt="HrHelpDesk" className={style.optionsImage} /> */}
              </div>
              <p className={style.optionsText}>HR Help Desk</p>
            </div>
          ) : null}

          {empAccess?.includes("QUES") ? (
            <div
              className={style.erpDashboard}
              onClick={handleQuestionnaire}
              style={{ cursor: "pointer" }}
            >
              <div>
                {/* <img src={ats} alt="HrHelpDesk" className={style.optionsImage} /> */}
              </div>
              <p className={style.optionsText}>Client Questionnaire</p>
            </div>
          ) : null}
        </section>
      </div>
    </>
  );
}
