import React, { useState } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import style from "./EmployeeInductionDetails.module.css";
import InvoiceButton from "../../../Employee_Induction/CommonComponent/Button/InvoiceButton";
import { useQuery } from "react-query";
import {
  EmployeeInduction,
  getEmployeeInduction,
} from "../../../../Utils/axios/InductionApi";
import moment from "moment";
import { FaLinkedin } from "react-icons/fa";
import CustomTooltip from "../../customTooltip/CustomTooltip.js";
import { useEffect } from "react";
import axios from "axios";
function EmployeeInductionDetails({
  setNextScreen,
  prevData,
  setPrevData,
  setInductionData,
}) {
  const [checkList, setCheckList] = useState();
  const [ActivityStatus, setActivityStatus] = useState("");
  const [JoiningStatus2, setJoiningStatus2] = useState("");
  const [checklistEmployee, setCheckListEmployee] = useState([]);
  const [inductionStatus, setInductionStatus] = useState(false);
  const {
    data: InductionView,
    isLoading,
    isError,
  } = useQuery(
    ["EmployeeInduction"],
    () => EmployeeInduction(prevData?.idData),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
    }
  );
  const getActivity = (checklistId) => {
    axios
      .get(
        apiKey +
          `/activitydetails?checklistId=${checklistId}&empId=${prevData?.idData}`
      )
      .then((res) => {
        setActivityStatus(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   getActivity();
  // }, [setActivityStatus]);

  const getJoiningKit = (checklistId) => {
    axios
      .get(
        apiKey +
          `/joiningdetails?checklistId=${checklistId}&empId=${prevData?.idData}`
      )
      .then((res) => {
        setJoiningStatus2(res?.data?.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   getJoiningKit();
  // }, [setJoiningStatus2]);
  const apiKey = process.env.REACT_APP_EmployeeInduction_URL;

  const getCheckList = async () => {
    try {
      const response = await axios.get(apiKey + "/checklist");
      const { responseData } = response.data;
      setCheckList(responseData);
      const activityCheckListId = responseData?.find(
        (e) => e?.categoryName === "Employee Orientation"
      )?.checkListId;
      const joiningKitCheckListId = responseData?.find(
        (e) => e?.categoryName === "Employee Details"
      )?.checkListId;
      getActivity(activityCheckListId);
      getJoiningKit(joiningKitCheckListId);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCheckList();
  }, []);

  const { data: CreteView } = useQuery(
    ["getEmployeeInductions"],
    () => getEmployeeInduction(prevData?.idData),
    {
      onSuccess: (res) => {
        let status = false;
        for (let i = 0; i < res?.length; i++) {
          if (res[i]?.status === "Done" || res[i]?.status === "N.A") {
            status = true;
            break;
          }
        }
        if (status) {
          setCheckListEmployee([...res]);
        } else {
          getCheckList();
        }
      },
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 1000,
    }
  );

  // useEffect(() => {
  //   refetchData();
  // }, [CreteView]);

  useEffect(() => {
    checkList?.length > 0 && setCheckListEmployee([...checkList]);
  }, [checkList]);

  // useEffect(() => {
  //   getEmployeeInduction();
  //   if (CreteView?.length > 0) {
  //     let flag = false;
  //     for (let i = 0; i < CreteView?.length; i++) {
  //       if (CreteView[i]?.status === "" || CreteView[i]?.status === "N.A") {
  //         flag = false;
  //         break;
  //       } else {
  //         flag = true;
  //       }
  //     }
  //     if (flag) {
  //       setInductionStatus(true);
  //     }
  //   }
  // }, [CreteView]);

  // useEffect(() => {
  //   getEmployeeInduction();
  //   if (CreteView?.length > 0) {
  //     let flag = true;
  //     for (let i = 0; i < CreteView?.length; i++) {
  //       if (CreteView[i]?.status === "" || CreteView[i]?.status === "N.A") {
  //         flag = false;
  //         break;
  //       }
  //     }
  //     setInductionStatus(flag);
  //   }
  // }, [CreteView]);

  const formattedDate = ActivityStatus?.date
    ? moment(ActivityStatus.date).format("DD MMM YY")
    : "";

  const EmpDate = InductionView?.dateOfJoining
    ? moment(InductionView?.dateOfJoining).format("DD MMM YY")
    : "";

  return (
    <>
      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <InvoiceContainer>
        <div className={style.emp_details}>Employee Details </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Emp Code</p>
            <p className={style.value}>{InductionView?.empCode}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Emp Name</p>
            <p className={style.value}>{InductionView?.employeeName}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Date of Joining</p>
            <p className={style.value}>
              {/* {moment(InductionView?.dateOfJoining).format("DD MMM YY")} */}
              {EmpDate}
            </p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Designation</p>
            <p className={style.value}>{InductionView?.designation}</p>
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Department</p>
            <p className={style.value}>{InductionView?.department}</p>
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Employee Details</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Joining Kit Status</p>
            <p className={style.value}>{JoiningStatus2?.joiningStatus}</p>
          </div>
          <div className={style.col213}>
            <p className={style.labelFolderLink}>Folder Link</p>
            <p className={style.valueFolderLink}>
              <a href={JoiningStatus2?.folderLink} target="_blank">
                <CustomTooltip
                  value={JoiningStatus2?.folderLink}
                  limit={30}
                  index={1}
                  styles={{
                    paddingLeft: "2px",
                    cursor: "pointer",
                  }}
                  tooltipstyle={{
                    left: "34%",
                    margin: "-6px",
                    transform: "translate(0px, -270px)",
                  }}
                />
              </a>
            </p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Induction Status</p>
            <p className={style.values}>
              {JoiningStatus2?.overallStatus ? "Done" : "In-Progress"}
            </p>
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Employee Induction Activities</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}> Name</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {element?.categoryId === 6 ? element?.checkListName : ""}
                  </p>
                </>
              );
            })}
          </div>
          <div className={style.col212}>
            <p className={style.label}>Status</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {/* {element?.categoryId === 6 ? element?.status : ""} */}
                    {element?.categoryId === 6
                      ? element?.status === "Done"
                        ? "Done"
                        : element?.status === "N.A"
                        ? "N.A"
                        : "-"
                      : ""}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Employee Document Details</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}> Name</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {element?.categoryId === 3 ? element?.checkListName : ""}
                  </p>
                </>
              );
            })}
          </div>
          <div className={style.col212}>
            <p className={style.label}>Status</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {/* {element?.categoryId === 3 ? element?.status : ""} */}
                    {element?.categoryId === 3
                      ? element?.status === "Done"
                        ? "Done"
                        : element?.status === "N.A"
                        ? "N.A"
                        : "-"
                      : ""}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Joining Form</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}> Name</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {element?.categoryId === 4 ? element?.checkListName : ""}
                  </p>
                </>
              );
            })}
          </div>
          <div className={style.col212}>
            <p className={style.label}>Status</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {/* {element?.categoryId === 4 ? element?.status : ""} */}
                    {element?.categoryId === 4
                      ? element?.status === "Done"
                        ? "Done"
                        : element?.status === "N.A"
                        ? "N.A"
                        : "-"
                      : ""}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      </InvoiceContainer>

      <div className={style.heading}>Employee Orientation</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Activity Date</p>
            <p className={style.values}>{formattedDate}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>HR Name</p>
            <p className={style.values}>{ActivityStatus?.hrName}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Status</p>
            <p className={style.values}>{ActivityStatus?.status}</p>
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Introduction</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}> Name</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {element?.categoryId === 7 ? element?.checkListName : ""}
                  </p>
                </>
              );
            })}
          </div>
          <div className={style.col212}>
            <p className={style.label}>Status</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {/* {element?.categoryId === 7 ? element?.status : ""} */}
                    {element?.categoryId === 7
                      ? element?.status === "Done"
                        ? "Done"
                        : element?.status === "N.A"
                        ? "N.A"
                        : "-"
                      : ""}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Letters</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}> Name</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {element?.categoryId === 8 ? element?.checkListName : ""}
                  </p>
                </>
              );
            })}
          </div>
          <div className={style.col212}>
            <p className={style.label}>Status</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {/* {element?.categoryId === 8 ? element?.status : ""} */}
                    {element?.categoryId === 8
                      ? element?.status === "Done"
                        ? "Done"
                        : element?.status === "N.A"
                        ? "N.A"
                        : "-"
                      : ""}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>HRMS</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}> Name</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {element?.categoryId === 9 ? element?.checkListName : ""}
                  </p>
                </>
              );
            })}
          </div>
          <div className={style.col212}>
            <p className={style.label}>Status</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {/* {element?.categoryId === 9 ? element?.status : ""} */}
                    {element?.categoryId === 9
                      ? element?.status === "Done"
                        ? "Done"
                        : element?.status === "N.A"
                        ? "N.A"
                        : "-"
                      : ""}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Payroll</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}> Name</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {element?.categoryId === 10 ? element?.checkListName : ""}
                  </p>
                </>
              );
            })}
          </div>

          <div className={style.col212}>
            <p className={style.label}>Status</p>
            {checklistEmployee?.map((element) => {
              return (
                <>
                  <p className={style.values}>
                    {/* {element?.categoryId === 10 ? element?.status : ""} */}
                    {element?.categoryId === 10
                      ? element?.status === "Done"
                        ? "Done"
                        : element?.status === "N.A"
                        ? "N.A"
                        : "-"
                      : ""}
                  </p>
                </>
              );
            })}
          </div>
        </div>
      </InvoiceContainer>

      <div className={style.lastBtn}>
        
        <InvoiceButton
          onClick={() => {
            setNextScreen("EditEmployee");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "EditEmployee",
              ActivityStatus: ActivityStatus,
              checkListId1: checklistEmployee?.find(
                (e) => e?.categoryName === "Employee Orientation"
              )?.checkListId,
              JoiningStatus2: JoiningStatus2,
              overallStatus: inductionStatus,
              checkListId2: checklistEmployee?.find(
                (e) => e?.categoryName === "Employee Details"
              )?.checkListId,
              ViewStatus: CreteView,
              checklistEmployee: checklistEmployee,
              page3: null,
              page4: null,
              active: "EditEmployee",
            }));
            setInductionData(CreteView);
          }}
        >
          Edit
        </InvoiceButton>
      </div>
    </>
  );
}

export default EmployeeInductionDetails;
