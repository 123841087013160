import React from 'react';
import style from './Checkbox.module.css';

const CheckBox = (props) => {
    function removeA(arr) {
        var what, a = arguments, L = a.length, ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }
    const handleCheckbox = (arg) => {

        if (props.type == "assigned") {
            console.log("b", props.filterdata1.assignedBy)
            console.log("t", props.filterdata1.assignedTo)
            // if (props.filterdata1.assignedBy && props.filterdata1.assignedTo) {
            //     props.filterdata1.assignedBy.includes(arg) === false ? props.filterdata1.assignedBy.push(arg) : removeA(props.filterdata1.assignedBy, arg);
            // }
            if (props.filterdata1.assignedBy && props.filterdata1.assignedTo || props.filterdata1.assignedBy) {
                props.filterdata1.assignedBy.includes(arg) === false ? props.filterdata1.assignedBy.push(arg) : removeA(props.filterdata1.assignedBy, arg);
            }
            else {
                props.filterdata1.assignedTo.includes(arg) === false ? props.filterdata1.assignedTo.push(arg) : removeA(props.filterdata1.assignedTo, arg);
            }
            props.change(props.value);
        }
        else if (props.type == "priority") {
            props.filterdata1.priority.includes(arg) === false ? props.filterdata1.priority.push(arg) : removeA(props.filterdata1.priority, arg);
            props.change(props.value);
        }
        else if (props.type == "assignedTo") {
            props.filterdata1.assignedTo.includes(arg) === false ? props.filterdata1.assignedTo.push(arg) : removeA(props.filterdata1.assignedTo, arg);
            // props.change(props.value);
        }

    
    else if (props.type == "functionalArea") {
        props?.filterdata1?.functionalAreaId.includes(arg) === false ? props.filterdata1.functionalAreaId.push(arg) : removeA(props.filterdata1.functionalAreaId, arg);
        props.change(props.value);
    }
        else {
            props.filterdata1.status.includes(arg) === false ? props.filterdata1.status.push(arg) : removeA(props.filterdata1.status, arg);
            props.change(props.value);
        }
    }
    return (
        <li style={{ paddingTop: '10px', fontSize: '18px', color: props.userList.id === 'Assigned' ? '#FCB902' : props.userList.id === 'Done' ? '#65A15E' : props.userList.id === 'Onhold' ? '#F94244' : props.userList.id === 'Inprogress' ? '#163bbe' : '#000000', opacity: '.7', fontWeight: '600' }}>
            <label className={style.main}>
                <input type="checkbox" defaultChecked={props?.value?.includes(props?.userList.id)} onClick={() => handleCheckbox(props.userList.id)} />{props.userList.name}
                <span className={style.geekmark}></span>
            </label>
        </li>
    )
}
export default CheckBox;