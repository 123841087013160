import * as XLSX from "xlsx";
import React, { useState } from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { PerformanceReviewList2 } from "../../utils/api";
import moment from "moment";
import { useQuery } from "react-query";

const ReviewFileDownload = ({ limit }) => {
  const [reportStatus, setReportStatus] = useState(true);
  const { refetch } = useQuery(
    ["PerformanceReviewList2", ],
    () => PerformanceReviewList2(),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        handleDownloadButtonClick(res?.performanceResponseList);
      },
    }
  );

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Employee Name",
        "Review Date",
        "Review Month",
        "Review Type",
        "Review Source Type",
        "Online Platform",
        "Client Name",
        "Status",
        "Reviewed By",
      ], // Header row
      ...data.map((val) => [
        val.empName,
        moment(val.ReviewDate).format("DD MMM YY"),
        moment(val.ReviewDate).format("MMM"),
        val.reviewType,
        val.reviewSourceType,
        val.onlinePlatform,
        val.clientName,
        val.interviewResult,
        val.reviewedBy,
      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);

    downloadExcelFile(workbook);
  }

  return (
    <div>
      <FiDownload
        style={{ verticalAlign: "baseline" }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default ReviewFileDownload;
