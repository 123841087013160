//AllIssueComp
import React, { useState, useEffect } from "react";
import style from "./Dashboard.module.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import Moment from "moment";
import Select from "react-select";
import sortDownarrowImg from "../../../../assets/Down.png";
import sortUparrowImg from "../../../../assets/Up.png";
import { customSearch } from "../../commonComponent/style";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import Pagination from "../../commonComponent/Pagination";

export const DashboardCom = () => {
  const history = useHistory();
  const [isLoading, setisLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [totalIssue, settotalIssue] = useState(0);
  const[arrohandle,setArrowHandle]=useState(false)
  const [categary, setcategary] = useState();
  const [raisedBy, setRaisedBy] = useState();
  const [status, setStatus] = useState();
  const [sortOrder, setSortOrder] = useState(null);
  const [allIssue, setAllIssue] = useState([]);
  const [label, setLabel] = useState({
    raiseBy: "",
    categary: "",
    status: "",
  });
  const [allData, setAllData] = useState({
    categoryId: [],
    raisedBy: [],
    email: localStorage.getItem("email"),
    statusId: [],
    subCategoryId: [],
    monthId: [],
    delayByOrder:sortOrder
  });

  const limit = 10;
  // url
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;

  const viewIssueHandler = (id) => {
    history.push(`/HrHelpDeskViewIssue?id=${id}`);
  };

  // onSearchHandler
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code1":
          setAllData({ ...allData, raisedBy: [e?.value] });
          setLabel({ ...label, raiseBy: e?.label });
          break;
        case "Code2":
          setAllData({ ...allData, categoryId: [e?.value] });
          setLabel({ ...label, categary: e?.label });
          break;
        case "Code3":
          setAllData({ ...allData, statusId: [e?.value] });
          setLabel({ ...label, status: e?.label });
          break;
        default:
          return null;
      }
    }
  };

  // pagination Handler
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  categary?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  raisedBy?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  status?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  const getAllCategaryOption = categary?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getAllRaisedOption = raisedBy?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getAllStatusOption = status?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  // API Integration
  const getCategary = () => {
    axios
      .get(url + "/getCategoryList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res);
        setcategary(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRaisedBy = () => {
    axios
      .get(url + "/getRaisedByEmployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res);
        setRaisedBy(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getStatus = () => {
    axios
      .get(url + "/getStatus", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res);
        setStatus(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // All Issue API Integratio
  const getAllIssue = () => {
    axios
      .post(
        url + "/getDashboard?" + "limit=" + limit + "&page=" + currentPage,
        allData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.responseData.issues);
        setAllIssue(res.data.responseData);
        settotalIssue(res.data.responseData.totalIssue);
        setpageCount(res.data.responseData.totalIssue / 10);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllIssue();
  }, [currentPage, allData]);

  useEffect(() => {
    getCategary();
    getRaisedBy();
    getStatus();
  }, []);

  const handleSorting = () => {
    setSortOrder(sortOrder ===null? "ASC" :sortOrder === "ASC"?"DESC":"ASC");
  };
useEffect(()=>{
  setAllData({...allData,delayByOrder:sortOrder})
},[sortOrder])

  return (
    <>
      <div className={style.main}>
        <div className={style.twoFilter}>
          <div className={style.filteralign}>
            <div className={style.s1}>
              {allData?.raisedBy?.length === 0 ? (
                <span className={style.cp_serchIcon2}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon2}
                  onClick={() => {
                    setAllData({
                      ...allData,
                      raisedBy: [],
                    });
                    setLabel({ ...label, raiseBy: "" });
                  }}
                >
                  {allData?.raisedBy.length !== 0 ? <AiOutlineClose /> : null}
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Raised By"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  label?.raiseBy
                    ? {
                        value: allData?.raisedBy,
                        label: label?.raiseBy,
                      }
                    : null
                }
                onChange={(e) => {
                  onSearchHandler(e, "Code1");
                  setCurrentPage(1);
                }}
                options={getAllRaisedOption}
              />
            </div>
            <div className={style.s1}>
              {allData?.categoryId?.length === 0 ? (
                <span className={style.cp_serchIcon2}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon2}
                  onClick={() => {
                    setAllData({
                      ...allData,
                      categoryId: [],
                    });
                    setLabel({ ...label, categary: "" });
                  }}
                >
                  {allData?.categoryId.length !== 0 ? <AiOutlineClose /> : null}
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Category"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  label?.categary
                    ? {
                        value: allData?.categoryId,
                        label: label?.categary,
                      }
                    : null
                }
                onChange={(e) => {
                  onSearchHandler(e, "Code2");
                  setCurrentPage(1);
                }}
                options={getAllCategaryOption}
              />
            </div>
            <div className={style.s1}>
              {allData?.statusId?.length === 0 ? (
                <span className={style.cp_serchIcon2}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon2}
                  onClick={() => {
                    setAllData({
                      ...allData,
                      statusId: [],
                    });
                    setLabel({ ...label, status: "" });
                  }}
                >
                  {allData?.statusId.length !== 0 ? <AiOutlineClose /> : null}
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Status"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  label?.status
                    ? {
                        value: allData?.statusId,
                        label: label?.status,
                      }
                    : null
                }
                onChange={(e) => {
                  onSearchHandler(e, "Code3");
                  setCurrentPage(1);
                }}
                options={getAllStatusOption}
              />
            </div>
          </div>
        </div>
        <div className={style.AllIssue}>
          {isLoading ? (
            <p className={style.loading}>Loading...</p>
          ) : (
            <>
              <table>
                <thead>
                  <tr className={style.th1}>
                    <th className={style.tableContent2}>Issue ID</th>
                    <th className={style.tableContent2}>Raised By</th>
                    <th className={style.tableContent2}>Logged Date</th>
                    <th className={style.tableContent2}>Category</th>
                    <th className={style.tableContent2}>Sub-Category</th>
                    <th className={style.tableContent2}>Status</th>
                    <th className={style.tableContent2}>Closing Date</th>
                    <th className={style.tableContent2}>Delay By <img  alt="" style={{ cursor: "pointer", position: "relative", bottom: '0px', left: "5px" }} src={sortOrder==="ASC"? sortUparrowImg : sortDownarrowImg} onClick={handleSorting}/></th>
                  </tr>
                </thead>

                {allIssue?.issues?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan="8" className={style.noDataFound}>
                      No Data Available
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {allIssue.issues.map((issue, index) => {
                      return (
                        <tr className={style.allIssueListTr} key={index}>
                          <td
                            className={`${style.link} ${style.tableContent}`}
                            onClick={() => viewIssueHandler(issue?.issueId)}
                          >
                            {`HRHD${issue?.issueId}`}
                          </td>
                          <td className={style.td1}>{issue.raiseBy}</td>
                          <td className={style.td1}>
                            {Moment(issue.loggedDate).format("DD MMM YY")}
                          </td>
                          <td className={style.td1}>{issue.category}</td>
                          <td className={style.td1}>{issue.subCategory}</td>
                          <td className={style.td2}>{issue.status}</td>
                          <td className={style.td1}>
                            {issue.closedDate === null
                              ? ""
                              : Moment(issue.closedDate).format("DD MMM YY")}
                          </td>
                          <td className={style.td1} style={{textAlign:"center"}}>{issue?.delayBy}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </>
          )}
        </div>

        <div
          className={style.paginationmobileContainer}
          style={{ marginTop: "20px", paddingRight: "10px" }}
        >
          <Pagination
            pagesCount={pageCount}
            pageSize={limit}
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>
      </div>
    </>
  );
};
