import React from "react";
import { Container, Row, Col, Button, Card, Table } from "react-bootstrap";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import laptop from "../../../../../assets/laptop.png";
import "./AssignMobile.css";
import { useHistory } from "react-router-dom";

const ary = [
  {
    a: "01",
    b: "Dell",
    c: "latitude 342",
    d: "Developer",
    e: "i5",
    f: "16GB",
    g: "Avaibable",
  },
  {
    a: "02",
    b: "Dell",
    c: "latitude 342",
    d: "developer",
    e: "i5",
    f: "16GB",
    g: "Assigned",
  },
  {
    a: "02",
    b: "Dell",
    c: "latitude 342",
    d: "developer",
    e: "i5",
    f: "16GB",
    g: "Assigned",
  },
  {
    a: "02",
    b: "Dell",
    c: "latitude 342",
    d: "developer",
    e: "i5",
    f: "16GB",
    g: "Assigned",
  },
];

const AssignMobileComponent = () => {
  const history = useHistory();
  const addNewLaptop = () => {
    history.push("/laptop/addlaptopassign");
  };
  const issueLaptop = () => {
    history.push("/laptop/issue");
  };
  const healthRecord = () => {
    history.push("/laptop/health");
  };
  return (
    <>
      <div className="main">
        <Container>
          <Row>
            <Col sm={12} className="hed"></Col>
          </Row>
        </Container>
        <Card className="_card">
          <Container>
            <Row>
              <Col sm={2}>
                <img className="mt-0 mb-1" src={laptop} alt="laptop_img" />
              </Col>
              <Col>
                <Container className="mb-3 mt-0">
                  <Row>
                    <Col>
                      <label> Serial Number</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="123456789"
                      ></input>
                    </Col>
                    <Col>
                      <label>Brand</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="DELL"
                      ></input>
                    </Col>
                    <Col>
                      <label>RAM</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="16GB"
                      ></input>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-2">
                      <label> MAC Address</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="123456789jhkj"
                      ></input>
                    </Col>
                    <Col className="mt-2">
                      <label>Type</label> <br />
                      <input
                        type="text"
                        id="fname"
                        name="fname"
                        placeholder="kuch bhi"
                      ></input>
                    </Col>
                    <Col className="mt-4">
                      <label className="mt-1">
                        View Complete Details <ArrowRightAltIcon />
                      </label>
                      <br />
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Card>
        <Card className="_card mt-4">
          <Row className="mt-2 mb-1">
            <Col sm={2} className="addAss ">
              <span className="line"> Assigment</span>
            </Col>
            <Col sm={2} className="addIs" onClick={issueLaptop}>
              Issue
            </Col>
            <Col sm={2} className="addHRss" onClick={healthRecord}>
              Health Record
            </Col>
            <Col sm={6} className="addbtn">
              <Button onClick={addNewLaptop} variant="primary">
                <AddIcon /> Add New
              </Button>
            </Col>
          </Row>
        </Card>

        <Container className="mt-0">
          <Card className="_mcard">
            <Table responsive className="tabl">
              <thead>
                <tr>
                  <th>Assigned Date</th>
                  <th>Assigned To</th>
                  <th>Assigned By</th>
                  <th>
                    Retrun Date <ArrowDownwardIcon />
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {ary.map((ar) => (
                  <tr className="tabledata">
                    <td className="tabletaskId">{ar.a}</td>
                    <td>{ar.b}</td>
                    <td>{ar.c}</td>
                    <td>{ar.d}</td>
                    <td className="tableaction">
                      {/* <img
                      onClick={() => history.push("/viewTask")}
                      src={pencilEditButtonImg}
                    />
                    <img src={rubbishBinImg} /> */}
                      <EditIcon color="primary" />
                      <DeleteIcon color="primary" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default AssignMobileComponent;
