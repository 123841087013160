import React from "react";
import { useHistory } from "react-router-dom";
import "../component/ResourceOnsiteDescription/Table.css";
import Moment from "moment";

const TableRow = (props) => {
  const history = useHistory();
  const getBackgroundColor = (status) => {
    if (status === "Allocated") {
      return "green";
    }
    if (status === "Allocated (Soft)") {
      return "rgb(252, 187, 8)";
    }
    return "red";
  };

  const GoToDetails = () => {
    history.push({
      pathname: "/ResourcesOffshoreDescription",
      state: {
        id: props.id,
        currentPage: props?.currentPage,
        allData: props.allData,
        filterName: props.filterName,
        applied: props.applied,
      },
    });
  };
  return (
    <tr
      style={{
        borderBottom: "2px solid #F0F0F0",
        textAlign: "start",
        fontSize: "13px",
      }}
    >
      <td>{props.empCode}</td>
      <td
        onClick={GoToDetails}
        style={{
          cursor: "pointer",
          padding: "10px 0px",
          textTransform: "capitalize",
          fontWeight: "bolder",

          textDecorationLine: "underline",
        }}
      >
        {props.employeeName}
      </td>

      <td style={{ padding: "10px 5px" }}>
        {~~(props.experience / 12)} Years {props.experience % 12} Months
      </td>
      <td>{props.salaryRange}</td>
      <td>{props.projectName}</td>

      <td>{props.skill}</td>

      {props.allocation === "Half" ? (
        <td style={{ width: "5%" }}>50% </td>
      ) : props.allocation === "ThreeFourth" ? (
        <td style={{ width: "5%" }}>75% </td>
      ) : props.allocation === "OneFourth" ? (
        <td style={{ width: "5%" }}>25% </td>
      ) : (
        <td style={{ width: "5%" }}>100% </td>
      )}
      <td
        style={{
          paddingLeft: "10px",
          color: getBackgroundColor(props.allocationType),
        }}
      >
        {props.allocationType === "In-Active"
          ? "Inactive"
          : props.allocationType}
      </td>
      <td style={{ paddingLeft: "30px !important" }}>
        {Moment(props.allocationDate).format("DD MMM YY")}
      </td>
      <td style={{ paddingLeft: "30px !important" }}>
        {Moment(props.deallocationDate).format("DD MMM YY")}

        {props?.resignStatus ? (
          <span
            style={{
              fontSize: "11.5px",
              paddingLeft: "5px",
              color: "red",
              fontWeight: "900",
            }}
          >
            R
          </span>
        ) : null}
      </td>
      {props?.lastCommentDate ? (
        <td style={{ paddingLeft: "30px !important" }}>
          {Moment(props.lastCommentDate).format("DD MMM YY")}
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
};

export default TableRow;
