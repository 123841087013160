import React, { useState, useEffect } from "react";
import ModalView from "../../../commonComponent/ModalView";
import CheckBox from "./CheckBox";
import style from "./Filter.module.css";
import Iconionicmdclosecircle from "../../../../../assets/Iconionicmdclosecircle.png";

const myUrl = window.location.pathname;

const Filter = (props) => {
  const [tabActive, setTabActive] = useState(window.location.pathname);
  const [userLists, setUserLists] = useState(props.userLists);
  // if (props.filterData.assignedBy) {
  //   const newAssigned = props.filterData.assignedBy;
  // } else {
  //   const newAssigned = props.filterData.assignedTo;
  // }
  // const [newPriority, setNewPriority] = useState(props.filterData.priority);
  // const [newStatus, setNewStatus] = useState(props.filterData.status);
  // userLists.sort((a, b) =>
  //   a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  // );
  // const [priority, setPriority] = useState([
  //   { id: "Urgent", name: "Urgent", isChecked: false },
  //   { id: "High", name: "High", isChecked: false },
  //   { id: "Low", name: "Low", isChecked: false },
  // ]);

  // const [status, setStatus] = useState([
  //   { id: "Assigned", name: "Assigned", isChecked: false },
  //   { id: "Done", name: "Done", isChecked: false },
  //   { id: "Onhold", name: "On Hold", isChecked: false },
  //   { id: "Inprogress", name: "In Progress", isChecked: false },
  // ]);
  return (
    <ModalView>
      <div className={style.container}>
        <div className={style.subContainer}>
          <div className={style.mobileRes}>
            <span
              style={{
                marginLeft: "35%",
                color: "#65A15E",
                fontSize: "18px",
                fontWeight: "500",
                textDecorationLine: "underline",
              }}
            >
              Filters
            </span>
            <img
              style={{ width: "27px" }}
              onClick={props.closeFilter}
              src={Iconionicmdclosecircle}
            />
          </div>
          {/* <div
            style={{
              border: "1px solid #00000033",
              borderRadius: "9px 0px 0px 0px",
            }}
          >
            <header style={{ borderRadius: "9px 0px 0px 0px" }}>
              {tabActive == "/taskTracker/TaskAssignedByMeComponent" && (
                <header>Assigned To</header>
              )}
              {tabActive == "/taskTracker" && <header>Assigned By</header>}
            </header>
            <ul className={style.addScroll}>
              {userLists.map((userList, index1) => {
                return props.filterData.assignedBy ? (
                  <CheckBox
                    filterdata1={props.filterData}
                    userList={userList}
                    value={props.filterData.assignedBy}
                    change={props.setassigned}
                    key={index1}
                    type="assigned"
                  />
                ) : (
                  <CheckBox
                    filterdata1={props.filterData}
                    userList={userList}
                    value={props.filterData.assignedTo}
                    change={props.setassigned}
                    key={index1}
                    type="assigned"
                  />
                );
              })}
            </ul>
          </div> */}

          <div style={{ border: "1px solid #00000033" }}>
            <header>Type</header>
            <ul>
              {/* {priority.map((userList, index2) => {
                return (
                  <CheckBox
                    filterdata1={props.filterData}
                    userList={userList}
                    value={newPriority}
                    change={props.setpriority}
                    key={index2}
                    type="priority"
                  />
                );
              })} */}
            </ul>
          </div>

          <div
            style={{
              border: "1px solid #00000033",
              borderRadius: "0px 9px 0px 0px",
            }}
          >
            <header style={{ borderRadius: "0px 9px 0px 0px" }}>Status</header>
            {/* <ul>
              {status.map((userList, index3) => {
                return (
                  <CheckBox
                    filterdata1={props.filterData}
                    userList={userList}
                    value={newStatus}
                    change={props.setstatus}
                    key={index3}
                    type="status"
                  />
                );
              })}
            </ul> */}
          </div>
        </div>
        <div className={style.buttonSection}>
          <button onClick={props.closeFilter} className={style.clearAll}>
            Clear All
          </button>
          <button
            onClick={props.handleApplied.bind(this)}
            className={style.apply}
          >
            Apply
          </button>
        </div>
      </div>
    </ModalView>
  );
};

export default Filter;
