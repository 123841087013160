import React, { useEffect, useState } from "react";
import style from "./SideBar.module.css";
import performancereview from "../../../assets/performancereview.png";
import ats from "./../../../assets/ats.png";

import { Link, useHistory, useLocation } from "react-router-dom";

const SideBar = ({
  sidebarHandler,
  nextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
}) => {
  return (
    <div className={style.leftSection}>
      {/* 0 */}
      <button className={style.performanceReview}>
        <img
          src={performancereview}
          style={{ backgroundColor: "white", borderRadius: "20px" }}
          alt="img"
          height="40px"
        />
        <span>Employee Performance Review</span>
      </button>
      {/* 1 */}

      <button
        onClick={() => {
          sidebarHandler("EmployeePerformanceReview");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "EmployeePerformanceReview",
            page: "EmployeePerformanceReview",
            page1: "EmployeePerformanceReview",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "EmployeePerformanceReview",
          });
        }}
        className={
          prevData?.sidebar === "EmployeePerformanceReview"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "EmployeePerformanceReview" ? (
            <img src={performancereview} alt="img" />
          ) : (
            <img src={performancereview} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Employee Performance Review</span>
        </div>
      </button>

      {/* 11 */}
      {/* <button
        onClick={() => {
          sidebarHandler("candidateAllocation(Client)");
          setPrevData((prevData) => ({
            ...prevData,
            page: "positionALL",
            sidebar: "candidateAllocation(Client)",
            ListOfProfileInClient: "null",
            page1: "candidateAllocation(Client)",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "candidateAllocation(Client)",
          }));
        }}
        className={
          prevData.sidebar === "candidateAllocation(Client)"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData.sidebar === "candidateAllocation(Client)" ? (
            <img src={cag} alt="img" width="30px" />
          ) : (
            <img src={caw} alt="img" width="30px" />
          )}
        </div>
        <div className={style.sb}>
          <span>Candidate Allocation (Client)</span>
        </div>
      </button> */}
    </div>
  );
};

export default SideBar;
