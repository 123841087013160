import React, { lazy } from "react";

import { Switch, Route } from "react-router-dom";
import DashBoard from "./Pages/dashboard/dashboard.jsx";
import { ProtectedRoute } from "./privateRoutes";
import TaskTracker from "./Pages/taskTracker/tasktracker";
import CreateTask from "./Pages/taskTracker/createTask/CreateTask.js";
import RmsTaskDetalis from "./Pages/Projects_and_Resource_Master/Resources/ViewTask/TaskAssignedByMeDetails.js";
import EditTaskRMS from "./Pages/Projects_and_Resource_Master/Resources/EditTask/EditTask.js";
import CreateTaskRMSComponent from "./Pages/Projects_and_Resource_Master/Resources/createTask/CreateTask.js";
import SignIn from "./Pages/SignIn/SignIn";
import Clients from "./Pages/Projects_and_Resource_Master/Clients/Clients";
import EditTask from "./Pages/taskTracker/EditTask/EditTask.js";
import RMSTaskHistory from "./Pages/Projects_and_Resource_Master/Resources/TaskHistory/TaskHistory.js";
import EditProject from "./Pages/Projects_and_Resource_Master/Projects/component/Editproject/EditProject";
import MyTaskDetails from "./Pages/taskTracker/myTasks/component/MyTaskDetails";
import RMSReport from "./Pages/Projects_and_Resource_Master/Resources/Report/Report.js";
import EditResourcesOffshore from "./Pages/Projects_and_Resource_Master/Resources/EditResourceOffShore/EditResourceOffShore";
import TaskClosedByMeDetails from "./Pages/taskTracker/ClosedTaskByMe/component/TaskClosedByMeDetails";
import ClosedTaskByMe from "./Pages/taskTracker/ClosedTaskByMe/ClosedTaskByMe";
import TaskAssignedByMe from "./Pages/taskTracker/taskAssignedByMe/TaskAssignedByMe";
import ViewTask from "./Pages/taskTracker/ViewTask/ViewTask";
import TaskAssignedByMeDetails from "./Pages/taskTracker/taskAssignedByMe/component/TaskAssignedByMeDetails";
import TaskHistory from "./Pages/taskTracker/TaskHistory";
import OffShoreHistory from "./Pages/Projects_and_Resource_Master/Resources/component/OffshoreHistory/OffshoreHistory.js";
import ItAssetsMain from "./Pages/itAssets/ItAssetsMain";
import Laptop from "./Pages/itAssets/laptops/main/Laptop";
import EditComments from "./Pages/taskTracker/EditComments/EditComments.js";
import EditCommentsRMS from "./Pages/Projects_and_Resource_Master/Resources/EditComments/EditComments.js";
import GrievancePortal from "./Pages/Grievence Portal/pages/Dashboard/Dashboard";
import AssignedTickets from "./Pages/Grievence Portal/pages/ViewReceivedTickets/ViewReceivedTickets";
import LaptopGeneral from "./Pages/itAssets/General/General";
import EditResourcesAllocationOnsite from "./Pages/Projects_and_Resource_Master/Resources/ResourcesOnsite/EditResourceOnsite";
import ResourcesOffshoreDescription from "./Pages/Projects_and_Resource_Master/Resources/OffShoreDescription/OffShoreDesCription";
import LaptopPurchase from "./Pages/itAssets/Purchase/Purchase";
import LaptopProtection from "./Pages/itAssets/Protection/Protection";
import Laptopothers from "./Pages/itAssets/Others/Others";
import LaptopConfirm from "./Pages/itAssets/laptops/addLaptop/Conform/Confirm";
import RaiseTicket from "./Pages/Grievence Portal/pages/RaiseTicket/RaiseTicket";
import ResourceMaster from "./Pages/Projects_and_Resource_Master/Dashboard/Dashboard";
import Clientdescription from "./Pages/Projects_and_Resource_Master/Clients/clientdescription/Clientdescription";
import Projectdescription from "./Pages/Projects_and_Resource_Master/Projects/component/projecDescription/projectDescription";
import availableResources from "./Pages/Projects_and_Resource_Master/Projects/component/availableResources/availableResources";
import AllocateResources from "./Pages/Projects_and_Resource_Master/Projects/component/allocateResources/AllocateResources";
import Projects from "./Pages/Projects_and_Resource_Master/Projects/createProject.js";
import OnsiteHistory from "./Pages/Projects_and_Resource_Master/Resources/component/OnsiteHistory/OnsiteHistory.js";
import AllocationOnsiteDescription from "./Pages/Projects_and_Resource_Master/Resources/component/ResourceOnsiteDescription/OnsiteDesCription";
import EditClient from "./Pages/Projects_and_Resource_Master/Clients/EditClients/EditClient";
import OnsiteRecordData from "./Pages/Projects_and_Resource_Master/Resources/Report/OnSiteRecord/OnsiteRecord.js";
import OffshoreRecordData from "./Pages/Projects_and_Resource_Master/Resources/Report/OffShoreRecord/OffshoreRecord.js";
import PoolRecordData from "./Pages/Projects_and_Resource_Master/Resources/Report/PoolRecordData/PoolRecordData.js";
// import PoolReportData from "./Pages/Projects_and_Resource_Master/Resources/Report/PoolReportData/PoolReportData.js";
// import OnsiteReportData from "./Pages/Projects_and_Resource_Master/Resources/Report/OnsiteReportData/OnsiteReportData.js";
// import OffshoreReportData from "./Pages/Projects_and_Resource_Master/Resources/Report/offshoreReportData/OffshoreReportData.js";
import AddPoolResources from "./Pages/Projects_and_Resource_Master/Resources/PoolResources/AddPollResources.js";
import PoolResourcesDescription from "./Pages/Projects_and_Resource_Master/Resources/PoolResources/ResourceOnsiteDescription/PoolResourcesDescription.js";
import PoolResources from "./Pages/Projects_and_Resource_Master/Resources/PoolResources/PoolResources.js";
import EditPoolResource from "./Pages/Projects_and_Resource_Master/Resources/PoolResources/EditPollResources.js";
import resourceManagement from "./Pages/Projects_and_Resource_Master/Resources/resourceManagement.js";
import AddResourcesAllocationOnsite from "./Pages/Projects_and_Resource_Master/Resources/ResourcesOnsite/AddResourcesAllocationOnsite";
import createProjectMilestone from "./Pages/Projects_and_Resource_Master/Projects/component/milestone/createProjectMilestone";
import Resource from "./Pages/Projects_and_Resource_Master/Projects/component/Resource/Resource";
import PoolHistory from "./Pages/Projects_and_Resource_Master/Resources/component/PoolHistory/PoolHistory.js";
import projectandResource from "./Pages/Projects_and_Resource_Master/Dashboard/Dashboard";
import ResourcesAllocationOnsite from "./Pages/Projects_and_Resource_Master/Resources/ResourcesOnsite/ResourcesAllocationOnsite";
import createClient from "./Pages/Projects_and_Resource_Master/Clients/component/createClient/CreateClient";
import AddResourcesOffshore from "./Pages/Projects_and_Resource_Master/Resources/AddResourcesOffshore/AddResourcesOffShore";
import AssignLaptop from "./Pages/itAssets/laptops/assignLaptop/AssignLaptop";
import AddNewAssigment from "./Pages/itAssets/laptops/addnewLapAssigment/AddNewLAssigment";
import IssueLaptop from "./Pages/itAssets/laptops/issueLaptop/IssueLaptop";
import HealthRecord from "./Pages/itAssets/laptops/healthLRecord/HealthLRecod";
import RepaireJob from "./Pages/itAssets/laptops/repaireJob/RepaireJob";
import Tablet from "./Pages/itAssets/tablets/tablet";
import AddTablet from "./Pages/itAssets/tablets/addTablet/AddTablet";
import ProjectDetails from "./Pages/Projects_and_Resource_Master/Projects/ProjectDetails";
import Confirmation from "./Pages/itAssets/tablets/addTablet/component/component/Confirmation/Confirmation";
import AssignTablet from "./Pages/itAssets/tablets/assignTablet/AssignTablet";
import IssueTablet from "./Pages/itAssets/tablets/issueLaptop/IssueTablet";
import TabletHealthRecod from "./Pages/itAssets/tablets/healthRecord/TabletHealthRecod";
import AddNewTabletAssigment from "./Pages/itAssets/tablets/AddNewTabletAssigment/AddNewAssigment";
import TabletRepaireJob from "./Pages/itAssets/tablets/repaireJob/RepaireJob";
import Mobiles from "./Pages/itAssets/mobiles/main/Mobiles";
import AddMobile from "./Pages/itAssets/mobiles/mobiles/addMobile/AddMobile";
import AddLaptop from "./Pages/itAssets/laptops/laptop/addLaptop/AddLaptop";
import MConfirm from "./Pages/itAssets/mobiles/addMobil/Conform/Confirm";
import LConfirm from "./Pages/itAssets/laptops/addLaptop/Conform/Confirm";
import MAssign from "./Pages/itAssets/mobiles/assignMobile/AssignMobile";
import MIssue from "./Pages/itAssets/mobiles/issueMobile/IssueMobile";
import MHealth from "./Pages/itAssets/mobiles/healthMRecord/HealthMRecod";
import LAssign from "./Pages/itAssets/laptops/assignLaptop/AssignLaptop";
import LIssue from "./Pages/itAssets/laptops/issueLaptop/IssueLaptop";
import LHealth from "./Pages/itAssets/laptops/healthLRecord/HealthLRecod";
import MAddAssign from "./Pages/itAssets/mobiles/addnewMAssigment/AddNewMAssigment";
import LAddAssign from "./Pages/itAssets/laptops/addnewLapAssigment/AddNewLAssigment";
// ATS -
// import AtsNavigation from "./Pages/atsDashboard/navigation/AtsNavigation";
// const AtsNavigation = lazy(() => import("./Pages/atsDashboard/navigation/AtsNavigation"));
// USM -
import UsmNavigation from "./Pages/userManegment/navigation/UsmNavigation";
import ViewUser from "./Pages/userManegment/pageLayout/viewUser/ViewUser.js";
import CreateUser from "./Pages/userManegment/pageLayout/createPosition/CreateUser.js";
import UsersList from "./Pages/userManegment/pageLayout/UsersList/UsersList.js";
import Edituser from "./Pages/userManegment/pageLayout/editUser/Edituser.js";
import Canfirmation from "./Pages/userManegment/pageLayout/createPosition/canfimation/Canfirmation.js";
import Feedback from "./Pages/atsDashboard/pageLayout/emailComp/Feedback.jsx";
import EPRNavigation from "./Pages/EmployeePerformanceReview/navigation/EPRNavigation.js";
import NewEmployee from "./Pages/userManegment/pageLayout/newEmployee/NewEmployee.js";
import ExitEmpolyee from "./Pages/userManegment/pageLayout/ExitEmployee/ExitEmpolyee.js";
import ConfirmationEmployee from "./Pages/userManegment/pageLayout/confirmationEmployee/ConfirmationEmployee.js";
// ETS -
import ETSNavigation from "./Pages/EmployeeTimeSheet/navigation/ETSNavigation";
import ExInNavigation from "./Pages/Expense_Invoice/navigation/ExInNavigation";
import Report from "./Pages/userManegment/pageLayout/UMSReport/Report.js";
import InductionNavigation from "./Pages/Employee_Induction/navigation/InductionNavigation.js";

import EmployeeTimeSheet from "./Pages/NewEmployeeTimeSheet/navigation/ETSNavigation";
import PartnerNavigation from "./Pages/PartnerManagement/navigation/PartnerNavigation";

// HR Help Desk
import { CreateIsseuSuccessfull } from "./Pages/hrHelpDesk/grievancePortal/DoneIssue/CreateIsseuSuccessfull.js";
import { AllIssue } from "./Pages/hrHelpDesk/hrhelpDesk/allIssue/AllIssue.js";
import { Summary } from "./Pages/hrHelpDesk/hrhelpDesk/Summary/Summary.js";
import { MyIssue } from "./Pages/hrHelpDesk/grievancePortal/MyIssue/MyIssue.js";
import { CreateIssue } from "./Pages/hrHelpDesk/grievancePortal/CreateIssue/CreateIssue.js";
import { ViewIssue } from "./Pages/hrHelpDesk/grievancePortal/ViewIssue/ViewIsse.js";
import { HrHelpDeskViewIssue } from "./Pages/hrHelpDesk/hrhelpDesk/HrHelpDeskViewIssue/HrHelpDeskView.js";
import { EditIssue } from "./Pages/hrHelpDesk/grievancePortal/EditIssue/EditIssue.js";
import { HrHelpDeskDashboard } from "./Pages/hrHelpDesk/mainDashboard/DashBoad.js";

// Client Questionnaire route
import GeneRateLink from "./Pages/ClientQuestionnaire/CommonComponent/GenerateLink/GenerateLink.js";
import QuestionnaireSidebar from "./Pages/ClientQuestionnaire/sidebar/SideBar.js";
import { QuestionListt } from "./Pages/ClientQuestionnaire/QuestionList/QuestionList.js";
import { QuestionAdd } from "./Pages/ClientQuestionnaire/CreateQuestionnaire/CreateQuestionnaire.js";
import { ViewQuestionnaire } from "./Pages/ClientQuestionnaire/ViewQuestionnaire/ViewQuestionnaire.js";
import { QuestionEdit } from "./Pages/ClientQuestionnaire/EditQuestionnaire/EditQuestionnaire.js";
import { QuestionByPosition } from "./Pages/ClientQuestionnaire/QuestionByPosition/QuestioByPosition.js";
import { QuestionBySkills } from "./Pages/ClientQuestionnaire/QuestionBySkills/QuestionBySkills.js";
import { ViewQuestionnaireBySkill } from "./Pages/ClientQuestionnaire/ViewQuestionnaireBySkills/ViewQuestionnaireBySkill.js";
import { ViewQuestionnaireByPosition } from "./Pages/ClientQuestionnaire/ViewQuestionnaireByPosition/ViewQuestionnaireByPosition.js";
import { Suspense } from "react";
import MainLoader from "./Pages/loader/MainLoader.js";
const AtsNavigation = lazy(() =>
  import("./Pages/atsDashboard/navigation/AtsNavigation")
);

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/FeedbackByEmail/:id" component={Feedback} />
      <Route
        exact
        path="/viewQuestionDetailsById/:token"
        component={GeneRateLink}
      />
      <ProtectedRoute>
        {/* client Questionnaire route */}
        <Route exact path="/ViewQuestionnaire" component={ViewQuestionnaire} />
        <Route exact path="/Questionnaire" component={QuestionnaireSidebar} />
        <Route exact path="/QuestionList" component={QuestionListt} />
        <Route exact path="/EditQuestionnaire" component={QuestionEdit} />
        <Route exact path="/QuestionByPostion" component={QuestionByPosition} />
        <Route exact path="/QuestionBySkill" component={QuestionBySkills} />
        <Route
          exact
          path="/ViewByPosition"
          component={ViewQuestionnaireByPosition}
        />
        <Route
          exact
          path="/ViewBySkills"
          component={ViewQuestionnaireBySkill}
        />
        <Route exact path="/AddQuestionnaire" component={QuestionAdd} />

        <Route exact path="/dashboard" component={DashBoard} />
        <Route exact path="/taskTracker" component={TaskTracker} />
        <Route exact path="/Grievance-Portal" component={GrievancePortal} />
        <Route exact path="/ItAssetsMain" component={ItAssetsMain} />
        <Route exact path="/ResourceMaster" component={ResourceMaster} />
        <Route exact path="/Clients" component={Clients} />
        <Route
          exact
          path="/projectandResource"
          component={projectandResource}
        />
        <Route exact path="/AddPollResources" component={AddPoolResources} />
        <Route exact path="/createClient" component={createClient} />
        <Route exact path="/PoolResources" component={PoolResources} />
        <Route exact path="/PoolResourcesHistory" component={PoolHistory} />
        <Route
          exact
          path="/PoolResourcesDescription"
          component={PoolResourcesDescription}
        />
        <Route exact path="/EditPoolResource" component={EditPoolResource} />
        <Route
          exact
          path="/AddResourcesOffshore"
          component={AddResourcesOffshore}
        />
        <Route exact path="/editClient" component={EditClient} />
        <Route
          exact
          path="/AllocationOnsiteDescription"
          component={AllocationOnsiteDescription}
        />
        <Route exact path="/clientdescription" component={Clientdescription} />
        <Route
          exact
          path="/Projectdescription"
          component={Projectdescription}
        />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/ProjectDetails" component={ProjectDetails} />
        <Route
          exact
          path="/availableResources"
          component={availableResources}
        />
        <Route
          exact
          path="/ResourcesOffshoreDescription"
          component={ResourcesOffshoreDescription}
        />
        <Route exact path="/AllocateResources" component={AllocateResources} />
        <Route exact path="/milestone" component={createProjectMilestone} />
        <Route
          exact
          path="/resourceManagement"
          component={resourceManagement}
        />
        <Route
          exact
          path="/ResourcesAllocationOnsite"
          component={ResourcesAllocationOnsite}
        />
        <Route exact path="/RMSReport" component={RMSReport} />
        <Route
          exact
          path="/OffshoreChangeHistory"
          component={OffShoreHistory}
        />
        <Route exact path="/OnSiteChangeHistory" component={OnsiteHistory} />

        <Route
          exact
          path="/AddResourcesAllocationOnsite"
          component={AddResourcesAllocationOnsite}
        />
        <Route exact path="/resource" component={Resource} />
        <Route exact path="/EditTask" component={EditTask} />
        <Route
          exact
          path="/EditResourcesOffshore"
          component={EditResourcesOffshore}
        />
        <Route
          exact
          path="/EditResourcesAllocationOnsite"
          component={EditResourcesAllocationOnsite}
        />
        <Route exact path="/EditProject" component={EditProject} />
        <Route exact path="/taskTracker/createTask" component={CreateTask} />
        <Route exact path="/CreateTask" component={CreateTaskRMSComponent} />
        <Route exact path="/Pool/TaskEdit" component={EditTaskRMS} />
        <Route exact path="/Offshore/TaskEdit" component={EditTaskRMS} />
        <Route exact path="/Onsite/TaskEdit" component={EditTaskRMS} />
        <Route exact path="/Pool/TaskDetails" component={RmsTaskDetalis} />
        <Route exact path="/OffShore/TaskDetails" component={RmsTaskDetalis} />
        <Route exact path="/OffShore" component={OffshoreRecordData} />
        <Route exact path="/OnSite" component={OnsiteRecordData} />
        <Route exact path="/Pool" component={PoolRecordData} />
        <Route exact path="/OnSiteRecord" component={OnsiteRecordData} />
        <Route exact path="/OffShoreRecord" component={OffshoreRecordData} />
        <Route exact path="/PoolRecord" component={PoolRecordData} />
        <Route exact path="/OnSite/TaskDetails" component={RmsTaskDetalis} />
        <Route exact path="/RMSTaskDetails" component={RmsTaskDetalis} />
        <Route exact path="/viewTask" component={ViewTask} />
        <Route exact path="/EditRMSTask" component={EditCommentsRMS} />
        <Route exact path="/RMSTaskHistory" component={RMSTaskHistory} />
        <Route
          exct
          path="/taskTracker/TaskAssignedByMeComponent"
          component={TaskAssignedByMe}
        />
        <Route
          exct
          path="/taskTracker/ClosedByMeComponent"
          component={ClosedTaskByMe}
        />
        <Route
          exct
          path="/taskAssignedByMeDetails"
          component={TaskAssignedByMeDetails}
        />
        <Route
          exct
          path="/MyTaskDetailsDetails"
          component={TaskAssignedByMeDetails}
        />
        <Route
          exct
          path="/TaskClosedByMeDetails"
          component={TaskClosedByMeDetails}
        />
        <Route exct path="/MyTaskDetails" component={MyTaskDetails} />
        <Route exct path="/taskHistory" component={TaskHistory} />
        <Route exct path="/laptops" component={Laptop} />
        <Route exct path="/EditComments" component={EditComments} />
        <Route
          exct
          path="/Grievance-Portal/RaiseTicket"
          component={RaiseTicket}
        />
        <Route
          exct
          path="/Grievance-Portal/view-RecievedTicket"
          component={AssignedTickets}
        />

        {/* Hr Help Desk */}
        <Route exct path="/SummaryDetails" component={Summary} />
        <Route exct path="/MyIssue" component={MyIssue} />
        <Route exct path="/CreateIssue" component={CreateIssue} />
        <Route exct path="/EditIssue" component={EditIssue} />
        <Route exct path="/ViewIssue" component={ViewIssue} />
        <Route exact path="/AllIssue" component={AllIssue} />
        <Route
          exact
          path="/HrHelpDeskAllIssue"
          component={HrHelpDeskDashboard}
        />
        <Route
          exact
          path="/HrHelpDeskViewIssue"
          component={HrHelpDeskViewIssue}
        />
        <Route exact path="/DoneIssue" component={CreateIsseuSuccessfull} />

        {/* Laptop */}
        <Route exct path="/laptop/addLaptop" component={AddLaptop} />
        <Route exct path="/laptop/confirm" component={LConfirm} />
        <Route exct path="/laptop/assign" component={LAssign} />
        <Route exct path="/laptop/issue" component={LIssue} />
        <Route exct path="/laptop/health" component={LHealth} />
        <Route exct path="/laptop/addlaptopassign" component={LAddAssign} />
        <Route exct path="/ITassests/LaptopGeneral" component={LaptopGeneral} />
        <Route
          exct
          path="/ITassests/LaptopPurchase"
          component={LaptopPurchase}
        />
        <Route
          exct
          path="/ITassests/LaptopProtection"
          component={LaptopProtection}
        />
        <Route exct path="/ITassests/LaptopConfirm" component={LaptopConfirm} />
        <Route exct path="/ITassests/LaptopOthers" component={Laptopothers} />
        <Route exact path="/assignlaptop" component={AssignLaptop} />
        <Route exact path="/addnewAssigment" component={AddNewAssigment} />
        <Route exact path="/issueLaptop" component={IssueLaptop} />
        <Route exact path="/healthRecord" component={HealthRecord} />
        <Route exact path="/repaireJob" component={RepaireJob} />
        <Route exact path="/tablet" component={Tablet} />
        <Route exact path="/addTablet" component={AddTablet} />
        <Route exact path="/confirmation" component={Confirmation} />
        <Route exact path="/tablet/assignment" component={AssignTablet} />
        <Route exact path="/tablet/issue" component={IssueTablet} />
        <Route
          exact
          path="/tablet/healthRecord"
          component={TabletHealthRecod}
        />
        <Route exact path="/tablet/AddNew" component={AddNewTabletAssigment} />
        <Route exact path="/tablet/Repair" component={TabletRepaireJob} />
        <Route exact path="/mobiles" component={Mobiles} />
        <Route exact path="/mobile/addmobile" component={AddMobile} />
        <Route exact path="/mobile/confirm" component={MConfirm} />
        <Route exact path="/mobile/assign" component={MAssign} />
        <Route exact path="/mobile/issue" component={MIssue} />
        <Route exact path="/mobile/health" component={MHealth} />
        <Route exact path="/mobile/addmobileassign" component={MAddAssign} />
        <Suspense fallback={<MainLoader />}>
          <Route exact path="/ats-navigation" component={AtsNavigation} />
        </Suspense>
        <Route exact path="/ets-navigation" component={ETSNavigation} />
        <Route exact path="/ums-navigation" component={UsmNavigation} />
        <Route exact path="/UsersList" component={UsersList} />
        <Route exact path="/report" component={Report} />
        <Route exact path="/CreateUser" component={CreateUser} />
        <Route exact path="/exin-navigation" component={ExInNavigation} />
        <Route exact path="/CreateCanfirmation" component={Canfirmation} />
        <Route exact path="/UserDescription" component={ViewUser} />
        <Route exact path="/EditUser" component={Edituser} />
        <Route exact path="/EmpolyeeTimeSheet" component={EmployeeTimeSheet} />
        <Route exact path="/PortalManagement" component={PartnerNavigation} />
        <Route
          exact
          path="/Employee-Induction"
          component={InductionNavigation}
        />
        <Route
          exact
          path="/EmployeePerformanceReview"
          component={EPRNavigation}
        />
        <Route exact path="/new-employee" component={NewEmployee} />
        <Route exact path="/exit-employee" component={ExitEmpolyee} />
        <Route
          exact
          path="/confirmation-employee"
          component={ConfirmationEmployee}
        />
      </ProtectedRoute>
    </Switch>
  );
}
