import React, { useEffect } from "react";
import MobileComponent from "./component/MobileComponet";
import SideBar from "../../commonComponent/SideBar";
import Header from "../../commonComponent/Header";
import "./Mobil.css";
const Mobiles = () => {
  return (
    <div>
      <Header />

      <div className="mainSection">
        <SideBar />
        <MobileComponent />
      </div>
    </div>
  );
};

export default Mobiles;
