import React, { useState } from "react";
import style from "./TabletComponent.module.css";
import filterimg from "./../../../../assets/filter-results-button.png";
import Polygon from "../../../../assets/Polygon.png";
import Iconmetrocross from "../../../../assets/Iconmetrocross.png";
import Iconionicmdcheckbox from "../../../../assets/Iconionicmdcheckbox.png";
import Iconfeatherfilter from "../../../../assets/Iconfeatherfilter.png";
import Filter from "./component/Filter";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
// import TaskFilter from './TaskFilter.js.js';
// import  CreateTask  from './CreateTask.js.js';
// import Pagination from './Pagination';

const ary = [
  {
    a: "TAB001",
    b: "Apple",
    c: "IPhone 13",
    d: "Mac",
    e: "8 GB",
    f: "Oreo",
    g: "Android 10",
  },
  {
    a: " TAB002",
    b: "Apple",
    c: "IPhone 13",
    d: "Mac",
    e: "6 GB",
    f: "Oreo",
    g: "Android 11",
    h:true
  },
];
const arr = [
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  },
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  },
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  },
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  },
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  },
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  },
];

const TabletComponent = (props) => {
  const history = useHistory();
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [applied, setApplied] = useState(false);

  const handleApplied = () => {
    setApplied(true);
    setShowFilter(false);
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  console.log(showFilter);

  const handleTask = () => {
    console.log("LLL");
    history.push("/addTablet");
  };

  const handlePageChange = (page) => {
    console.log("kkkkkk", page);
    setCurrentPage(page);
  };

  const closeFilter = () => {
    setShowFilter(false);
    setApplied(false);
  };

  const handleExp = () => {
    history.push("/assignlaptop");
  };

  // const handleExp = () => {
  //   history.push("/repaireJob");
  // };
  return (
    <>
      <div className={style.taskTracker}>
        <div className={style.header}>
          <div>
            <span>Tablets</span>
          </div>
          <button className={style.filter} onClick={handleFilter}>
            <img src={filterimg} />
            <span>Filter</span>
          </button>
          {applied && (
            <img
              style={{
                position: "absolute",
                left: "58%",
                top: "6%",
                width: "12px",
                zIndex: "10",
                borderRadius: "6px",
              }}
              src={Iconionicmdcheckbox}
            />
          )}
          {showFilter && (
            <img
              className={style.polygon}
              style={{ position: "absolute", left: "53%", top: "20%" }}
              src={Polygon}
            />
          )}
          <button onClick={handleTask} className={style.createTask}>
            <img
              style={{ width: "15px", marginRight: "10px" }}
              src={Iconmetrocross}
            />
            <span>Add Tablets</span>
          </button>

          <div className={style.mobileHeader}>
            <p>
              <img onClick={handleFilter} src={Iconfeatherfilter} />
            </p>
            <p style={{ backgroundColor: "#65A15E" }}>
              <img onClick={handleTask} src={Iconmetrocross} />
            </p>
          </div>
        </div>
        {/* S.No.
Brand
Model
MAC
RAM
OS
OS Version
Status
Active */}

        <div className={style.container}>
          <table>
            <thead>
              <tr>
                <th className={style.taskid}>S.No</th>
                <th className={style.taskTitle}>Brand</th>
                <th className={style.taskdesc}>Model</th>
                <th className={style.taskAssigned}>
                MAC <ArrowDownwardIcon />
                </th>
                <th className={style.taskStatus}>
                RAM <ArrowDownwardIcon />
                </th>
                <th className={style.taskplanned}>
                OS <ArrowDownwardIcon />
                </th>
                <th className={style.taskplanned}>
                OS Version <ArrowDownwardIcon />
                </th>
                <th className={style.taskplanned}>
                Status <ArrowDownwardIcon />
                </th>
                <th className={style.taskAction}>Active</th>
              </tr>
            </thead>

            <tbody>
              {ary.map((ar) => (
                <tr className={style.tabledata}>
                  <td className={style.tabletaskId}>{ar.a}</td>
                  <td>{ar.b}</td>
                  <td>{ar.c}</td>
                  <td>{ar.d}</td>
                  <td>{ar.e}</td>
                  <td>{ar.f}</td>
                  <td>{ar.g}</td>
                  <td>{ar.h=== true?<span className={style.statusRed}>Assigned</span>:<span className={style.statusGreen}>Available</span>}</td>
                  <td className={style.tableaction}>
                    <VisibilityIcon onClick={()=>history.push("./tablet/assignment")} className={style.visible} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

         
        </div>
        {/* <button onClick={handleExp} className={style.createTask}>
          <img
            style={{ width: "15px", marginRight: "10px" }}
            src={Iconmetrocross}
          />
          <span>Test Page</span>
        </button> */}
      </div>

      {/* <div className={style.responsivearrow}>
        <img src={downarrow} style={{ transform: "rotateZ(90deg)" }} />
        <img src={downarrow} style={{ transform: "rotateZ(-90deg)" }} />
      </div> */}

      {/* <div className={style.paginationmobileContainer}>
        <Pagination
          itemsCount={30}
          pageSize={4}
          onPageChange={handlePageChange}
          currentPage={currentPage}
        />
      </div> */}

      {showFilter && (
        <Filter closeFilter={closeFilter} handleApplied={handleApplied} />
      )}

      {/* <CreateTask /> */}
    </>
  );
};

export default TabletComponent;
