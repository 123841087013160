import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import cx from "classnames";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import Sidebar from "../commonComponent/SideBar";
import  Header from "../commonComponent/Header"
import styles from "./Others.module.css";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import ForumIcon from "@mui/icons-material/Forum";
import "react-datepicker/dist/react-datepicker.css";


const RaiseTicket = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [department, setDepartment] = useState("");
    const [category, setCategory] = useState("");
    const [plannedEndDate, setPlannedEndDate] = useState(new Date());
    const [description, setDescription] = useState("");
    const [notes, setNotes] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [addRequestStatus, setAddRequestStatus] = useState("idle");

    const departmentAssigneeMapping = {
        "Lorem ipsum": "Robert Percival",
        "Ipsum dolor": "Silena Rogers",
        "Dolor sit": "Karl Benz",
        "Sit amet": "Netra Mohan",
        "Amet lorem": "Sashikant Mishra",
    };
    const handleDepartment = (event) => {
        setDepartment(event.target.value);
    };

    const handleCategory = (event) => {
        setCategory(event.target.value);
    };

    const handlePlannedEndDate = (date) => {
        setPlannedEndDate(date);
    };

    const handleDescription = (event) => {
        setDescription(event.target.value);
    };

    const handleNotes = (event) => {
        setNotes(event.target.value);
    };

    return (
        <div className={styles.maincontainer}>
          <Header/>
        <div className={styles.mainSection}><Sidebar/>
<div className= {styles.container} >
            <div className={cx(styles.rowFlex, styles.spaceBetween)}>
                <div className={cx(styles.font21, styles.fontSemibold, styles.colorBlack)}> Add Laptop </div>
            </div>

<div className={styles.createJobProgress}>
      <div style={{"marginLeft":"5%"}} className={styles.element}>
        <div className={styles.elementImg}>01
        </div>
        <div className={cx(styles.font12, styles.fontMedium, styles.colorBlack,styles.elementText)}>General</div>
      </div>
      <div style={{"marginLeft":"27%"}} className={styles.element}>
        <div className={styles.elementImg}>02
        </div>
        <div className={cx(styles.font12, styles.fontMedium, styles.colorBlack,styles.elementText)}>Purchase</div>
      </div>
      <div style={{"marginLeft":"27%"}} className={styles.element}>
        <div className={styles.elementImg}>03
        </div>
        <div className={cx(styles.font12, styles.fontMedium, styles.colorBlack,styles.elementText)}>Protection</div>
      </div>
      <div style={{"marginLeft":"27%"}} className={styles.element}>
        <div className={styles.elementImg}>04
        </div>
        <div className={cx(styles.font12, styles.fontMedium, styles.colorBlack,styles.elementText)}>Others</div>
      </div>
    </div>

            
            <form>
                <div className={styles.formContainer}>
                  
          
            <Container>
              <p style={{display:"flex" }}>
              <MonitorHeartIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}>Machine Health</span>
              </p>
              <div style={{display:"flex", marginTop:"2%"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Status</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Last Health Check Date</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <hr className={styles.section}/>
              <p style={{display:"flex" , marginTop:"2%"}}>
              <ForumIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> Comments</span>
              </p>
              <div style={{display:"flex"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Add Comments</label><br />
                  <input type="text" id="fname" name="fname" className={styles.comment}></input>
                </div>
                </div>
              </Container>
          
                </div>
                <div className={styles.buttonRow}>
                    <div>
                        <button onClick={()=>history.push("")} className={cx(styles.button1, styles.font12, styles.fontMedium, styles.colorWhite)} style={{ cursor: "pointer" }}>
                            Back
                        </button>
                    </div>
                    <div>
                        <button onClick={()=>history.push("")} className={cx(styles.button, styles.font12, styles.fontMedium, styles.colorWhite)} style={{ cursor: "pointer" }}>
                            Finish
                        </button>
                    </div>
                </div>
            </form>
            </div>
        </div>
        </div>
    );
};

export default RaiseTicket;
