import React, { useState, useEffect } from "react";
import style from "./TaskAssignedByMeComponent.module.css";
import filterimg from "./../../../../assets/filter-results-button.png";
import rubbishBinImg from "./../../../../assets/rubbish-bin.png";
import pencilEditButtonImg from "./../../../../assets/pencil-edit-button.png";
import Iconmetrocross from "../../../../assets/Iconmetrocross.png";
import Iconionicmdcheckbox from "../../../../assets/Iconionicmdcheckbox.png";
import sortDownarrowImg from "./../../../../assets/Down.png";
import sortUparrowImg from "./../../../../assets/Up.png";
import milestone from "../../../../assets/milestone.png";
import Polygon from "../../../../assets/Polygon.png";
import downarrow from "../../../../assets/downarrow.png";
import loaderImg from "../../../../assets/loader-green2.gif";
import Pagination from "../../commonComponent/Pagination";
import Filter from "../../myTasks/component/Filter";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import axios from "axios";
import constants from "../../../../Utils/constants";
import cookie from "react-cookies";
const arr = [];

const TaskAssignedByMeComponent = (props) => {
  const [data, setData] = useState();

  console.log('props',props)
  const [scroll, setScroll] = useState();
  const [tasks, setTasks] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [applied, setApplied] = useState(false);
  const [totalTasks, settotalTasks] = useState(0);
  const [creationDateOrder, setCreationDateOrder] = useState(null);
  const [pageCount, setpageCount] = useState(0);
  const history = useHistory();
  const limit = 10;
  const activePage = 2;

  const [assigned, setAssigned] = useState([]);
  const [planned, setPlanned] = useState([]);
  const [planedEndDateOrder, setPlanedEndDateOrder] = useState(null);
  const [priority, setPriority] = useState([]);
  const [priorityOrder, setPriorityOrder] = useState(null);
  const [status, setStatus] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [arrowEndDate, setArrowPalnEndDate] = useState(false);
  const [priorityArrow, setPriorityArrow] = useState(false);
  const [CreationArrow, setCreationArrow] = useState(true);
  const [allData2, setAllData] = useState({
    assignedTo: assigned,
    creationDateOrder: creationDateOrder,
    planedEndDateOrder: planedEndDateOrder,
    priority: priority,
    priorityOrder: priorityOrder,
    status: status,
    functionalAreaId: [],
  });

  const url2 = constants.apiBaseUrl2;
  const [filterData, SetFilterData] = useState({
    assignedTo:[],
    creationDateOrder:creationDateOrder,
    planedEndDateOrder:null,
    priority:[],
    priorityOrder:null,
    status:[],
    functionalAreaId:[],
  });

  console.log(allData2)
  const getAssignedByme = () => {
    axios
      .get(url2 + "/getaskAssignedByFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setData(res.data.responseData.employeeNames);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleapidata = () => {
    setIsLoaded(true);
    const taskAssignData = axios
      .post(
        url2 + "/getTaskAssignedByMe?limit=" + limit + "&page=" + currentPage,
        allData2,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        }
      )
      .then((response) => {
        setTimeout(() => {
          setIsLoaded(false);
        }, 250);
        if (response.data.statusCode == 0) {
          console.log("responsedata", response);
          const responseData = response.data.responseData;
          setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
          setTasks(responseData.taskResponse);
          settotalTasks(responseData.totalTask);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const geyEmployeeList2 = () => {
    const employeeData = axios
      .get(url2 + "/getaskAssignedByFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })

      .then((res) => {
        if (res.data.statusCode == 0) {
          setUserLists(res.data.responseData.employeeNames);
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const handleApplied = (arg) => {
    setCurrentPage(1)

    if (assigned.length !== 0 || priority.length !== 0 || status.length !== 0) {
      setApplied(true);
    }
    setShowFilter(false);
    handleapidata();
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleTask = () => {
    history.push("/taskTracker/createTask");
  };

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };
  const closeFilter = () => {
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            assignedTo: [],
            priority: [],
            status: [],
            functionalAreaId:[]
          }
        : null;
    });
    setAssigned([]);
    setPriority([]);
    setStatus([]);
    setShowFilter(false);
    setApplied(false);
  };



  const handleCrationDate = () => {
    setPriorityArrow(false);
    setCreationArrow(true);
    setArrowPalnEndDate(false);
    if (creationDateOrder == "ASC") {
      setCreationDateOrder("");
      setAllData((preValue) => {
        return preValue
          ? {
              ...preValue,
              priorityOrder: null,
              planedEndDateOrder: null,
              creationDateOrder: "DESC",
            }
          : null;
      });
    } else if (creationDateOrder == "DESC") {
      setCreationDateOrder(null);
      setAllData((preValue) => {
        return preValue
          ? {
              ...preValue,
              // priorityOrder:null,

              creationDateOrder: null,
            }
          : null;
      });
    } else {
      setCreationDateOrder("ASC");
      setAllData((preValue) => {
        return preValue
          ? {
              ...preValue,
              priorityOrder: null,
              planedEndDateOrder: null,
              creationDateOrder: "ASC",
            }
          : null;
      });
    }
  };

  const handleTaskDetail = (arg) => {
    history.push({
      pathname: "/taskAssignedByMeDetails",
      state: {
        task: arg,
        activePage: 2,
      },
    });
  };



  useEffect(() => {
    handleapidata();
    getAssignedByme();
    geyEmployeeList2();
  }, [
    currentPage,
    creationDateOrder,allData2]);

  return (
    <>
      <div className={style.taskTracker}>
        {/* <div className={style.breadcrums}>
          <span className={style.font14}>Task Tracker {">"}</span>
          <span className={style.font15}> Tasks Assigned By Me </span>
        </div> */}
        <div className={style.header}>
          <div>
            <span>Task Assigned By Me</span>
            <p>
              <span>{tasks ? totalTasks : 0}</span>
            </p>
          </div>
          <button className={style.filter} onClick={handleFilter}>
            {applied && (
              <img className={style.appliedcheck} src={Iconionicmdcheckbox} />
            )}
            <img src={filterimg} />
            <span>Filter</span>
          </button>

          {showFilter && <img className={style.polygon} src={Polygon} />}
          <button onClick={handleTask} className={style.createTask}>
            <img
              style={{ width: "15px", marginRight: "10px" }}
              src={Iconmetrocross}
            />
            <span>Create Task</span>
          </button>

          <div className={style.mobileHeader}>
            {/* <p>
              <img onClick={handleFilter} src={Iconfeatherfilter} />
            </p>
            <p style={{ backgroundColor: "#65A15E" }}>
              <img onClick={handleTask} src={Iconmetrocross} />
            </p> */}
          </div>
        </div>
        <div
          className={style.container}
          onClick={() => {
            setShowFilter(false);
          }}
        >
          {isLoaded ? (
            <div>
              <img src={loaderImg} alt="loader" className={style.loader} />
              <p className={style.loaderPara}>Loading...</p>
            </div>
          ) : (
            ""
          )}
          {tasks.length > 0 && !isLoaded ? (
            <div className={style.tdd}>
              <table>
                <thead>
                  <tr>
                    <th
                      className={style.taskid}
                      style={{
                        paddingLeft: "5px",
                        position: "sticky",
                        top: "0px",
                      }}
                    >
                      Task ID
                    </th>
                    {/* <th className={style.creationDate} style={{ paddingLeft: '11px', position: 'sticky', top: '0px' }}>Creation Date</th> */}

                    <th
                      onClick={() => handleCrationDate()}
                      className={style.taskplanned}
                      style={{
                        position: "sticky",
                        cursor: "pointer",
                        top: "0px",
                      }}
                    >
                      Creation Date
                      {CreationArrow ? (
                        <img
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            bottom: "px",
                            left: "3px",
                          }}
                          src={
                            allData2.creationDateOrder !== "ASC"
                              ? sortDownarrowImg
                              : sortUparrowImg
                          }
                        />
                      ) : null}
                    </th>
                    <th
                      className={style.taskTitle}
                      style={{
                        paddingLeft: "13px",
                        position: "sticky",
                        top: "0px",
                      }}
                    >
                      Task Title
                    </th>
                    {/* <th className={style.taskdesc} style={{ paddingLeft: '14px', position: 'sticky', top: '0px' }}>Task Description</th> */}
                    <th
                      className={style.taskAssigned}
                      style={{
                        paddingLeft: "13px",
                        position: "sticky",
                        top: "0px",
                      }}
                    >
                      Assigned To
                    </th>
                    <th
                      className={style.taskStatus}
                      style={{
                        paddingLeft: "11px",
                        position: "sticky",
                        top: "0px",
                      }}
                    >
                      Status
                    </th>
                    <th
                    
                      className={style.taskPriority}
                      style={{
                        paddingLeft: "10px",
                        position: "sticky",
                        
                        top: "0px",
                      }}
                    >
                      Priority
                     
                    </th>
                    <th
                   
                      className={style.taskplanned}
                      style={{
                        position: "sticky",
                      
                        top: "0px",
                        paddingLeft:"35px"
                      }}
                    >
                      Planned 
                      <br></br>End Date
                  
                    </th>

                    <th
                      className={style.taskStatus}
                      style={{
                        paddingLeft: "23px",
                        position: "sticky",
                        top: "0px",
                      }}
                    >
                      Functional Area
                    </th>
                    {/* <th className={style.taskPriority} style={{paddingLeft: '13px',position: 'sticky',top:'0px'}}>Priority<span><img style={{cursor:"pointer",position:"relative",top:"5px",left:"3px"}} src={allData.priorityOrder=="ASC"?sortUparrowImg:sortDownarrowImg} onClick={() => handlePriority()}/></span></th> */}
                    {/* <th className={style.taskplanned} style={{paddingLeft: '13px',position: 'sticky',top:'0px'}}>Planned<br /> End Date<img style={{cursor:"pointer", position:"relative",bottom: '8px',left:"5px"}} src={allData.planedEndDateOrder=="ASC"?sortUparrowImg:sortDownarrowImg} onClick={() => handlePlannedDate()}/></th> */}
                    {/* {isScroll?<div style={{width:'2px'}}></div>:""} */}
                    {/* <th style={{width: '1.4%'}}></th> */}
                    <div style={{ width: "14px" }}></div>
                    {/* <th className={style.taskAction}>Action</th> */}
                  </tr>
                </thead>
              </table>

              <div className={style.scrollAdd}>
                <table>
                  <tbody>
                    {tasks &&
                      tasks.map((task, index) => (
                        <tr className={style.tabledata} key={index}>
                          <td className={style.tabletaskId} style={{paddingLeft:"14px"}}>{task.taskId}</td>
                          <td className={style.tableCreationDate}>
                            {task.created}
                          </td>
                          <td
                          style={{paddingLeft:"19px"}}
                            className={style.tabletaskTitle}
                            onClick={() => handleTaskDetail(task)}
                          >
                            <b>{task.title}</b>
                          </td>
                          {/* <td className={style.desc} >{task.description ? task.description : "-"}</td> */}
                          <td className={style.name} style={{paddingLeft:"8px"}} >{task.name}</td>
                          <td
                            className={
                              task.status == "Assigned"
                                ? style.asigned_color
                                : task.status == "Done"
                                ? style.done_color
                                : task.status == "Onhold"
                                ? style.onhold_color
                                : style.Closed_color
                            }
                            style={{ width: "10%", paddingLeft:"5px" }}
                          >
                            {task.status === "Closed"
                              ? "Closed"
                              : task.status === "Onhold"
                              ? "On Hold"
                              : task.status}
                          </td>
                          <td className={style.tabtaskpriority}   style={{paddingLeft:"2px"}}>
                            {task.priority}
                          </td>
                          <td className={style.planned}>
                            {Moment(task.planedEndDate).format("DD MMM (ddd)")}
                          </td>
                          <td  style={{paddingLeft:"0px"}} className={style.tabtaskpriority}>
                          {task.functionalArea}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  {/* </div> */}
                </table>
              </div>
            </div>
          ) : (
            <div className={style.noTasks}>
              {!isLoaded ? (
                <div style={{ display: "block" }}>
                  <img src={milestone} />
                  <h2>No Task Assigned Yet</h2>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
          <div className={style.mobileContainer}>
            {arr.map((ar, indexar) => {
              return (
                <div
                  style={{
                    display: "block",
                    borderBottom: "1px solid #0000001A",
                    marginBottom: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <strong>{indexar + 1}. Database Creation</strong>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Assigned By :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.b}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Status :
                    </span>
                    <span
                      style={{
                        color: "#FCB902",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      {" " + ar.c}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Priority :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.d}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Planned End Date :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.a}
                    </span>
                  </p>
                  
                </div>
              );
            })}
          </div>
        </div>

        <div className={style.responsivearrow}>
          <img
            src={downarrow}
            style={{ transform: "rotateZ(90deg)" }}
            onClick={handlePageChange}
          />
          <img src={downarrow} style={{ transform: "rotateZ(-90deg)" }} />
        </div>
        {totalTasks > limit && pageCount > 0 && (
          <div
            className={style.paginationmobileContainer}
            style={{ marginRight: "1.3rem", marginTop: "20px" }}
          >
            <Pagination
              pagesCount={pageCount}
              pageSize={limit}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>

      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          userLists={userLists}
          filterData={allData2}
          setassigned={setAssigned}
          setpriority={setPriority}
          setstatus={setStatus}
          data={data}
        />
      )}
    </>
  );
};

export default TaskAssignedByMeComponent;
