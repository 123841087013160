import React, { useState } from "react";
import SideBar from "../sidebar/SideBar";
import style from "./PartnerNavigation.module.css";
import ProgressBar from "../progressbar/ProgressBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PartnerList from "../pageLayout/PartnerList/PartnerList";
import CreatePartner from "../pageLayout/CreatePartner/CreatePartner";
import ViewPartner from "../pageLayout/ViewPartner/ViewPartner";
import EditPartner from "../pageLayout/EditPartner/EditPartner";
import OnBoarding from "../pageLayout/OnBoarding/OnBoarding";
import OnBoardingStatus from "../pageLayout/OnBoardingStatus/OnBoardingStatus";
import ViewOnBoardingStatus from "../pageLayout/OnBoardingStatus/ViewOnBoardingStatus";
import Agreement from "../pageLayout/Agreement/Agreement";
import TDS from "../pageLayout/TDS/TDS";
import Specialization from "../pageLayout/Specialization/Specialization";
import ViewCreatePartner from "../pageLayout/ViewCreatePartner/ViewCreatePartner";
import EditViewPartner from "../pageLayout/ViewCreatePartner/EditViewPartner";
import ChangeOnBoardingStatus from "../pageLayout/ChangeOnBoardingStatus/ChangeOnBoardingStatus";
import ReportCard from "../pageLayout/report/reportCard/ReportCard";
import EmpanelmentReport from "../pageLayout/report/empanelmentReport/EmpanelmentReport";
export default function PartnerNavigation() {
  const [nextScreen, setNextScreen] = useState("PartnerList");
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState([]);
  const [prevData, setPrevData] = useState({
    page: "PartnerList",
    sidebar: "PartnerList",
    page1: "PartnerList",
    active: "PartnerList",
  });

  const sidebarHandler = () => {
    switch (nextScreen) {
      case "PartnerList":
        return (
          <PartnerList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "CreatePartner":
        return (
          <CreatePartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewPartner":
        return (
          <ViewPartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "EditPartner":
        return (
          <EditPartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "OnBoarding":
        return (
          <OnBoarding
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "OnBoardingStatus":
        return (
          <OnBoardingStatus
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewOnBoardingStatus":
        return (
          <ViewOnBoardingStatus
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "Agreement":
        return (
          <Agreement
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "TDS":
        return (
          <TDS
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "Specialization":
        return (
          <Specialization
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewCreatePartner":
        return (
          <ViewCreatePartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "EditViewPartner":
        return (
          <EditViewPartner
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "ChangeOnBoardingStatus":
        return (
          <ChangeOnBoardingStatus
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "report":
        return (
          <ReportCard
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "empanelmentReport":
        return (
          <EmpanelmentReport
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      {/* <Header /> */}
      <div className={style.mainSection}>
        <div
          style={{
            width: "20%",
            minHeight: "41rem",
            backgroundColor: "#172a3e",
          }}
        >
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div className={style.ATScontainer}>
          <ProgressBar prevData={prevData} />
          {sidebarHandler()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
