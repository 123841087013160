import React, { useState, useEffect } from "react";
import style from "./EditTaskComponent.module.css";
import calendar from "../../../../../assets/Grievance portal/dateldpi.png"
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "react-cookies";
import { Redirect } from "react-router-dom";
import Moment from "moment";
import Calendar from "react-calendar";
import constants from "../../../../../Utils/constants";
import DatePicker from "react-datepicker";
import CustomAutoComplete from "../../../../taskTracker/commonComponent/AutoComplete";

const currentDate = Moment().toDate();
const EditTaskComponent = (props) => {
  const [myTaskDetail, setMyTaskDetailByID] = useState()
  const [employeeList, setEmployeeList] = useState([]);
  const [creationDate, setCreationDate] = useState(myTaskDetail?.created);
  const [taskId, settaskId] = useState();
  const [taskTitle, settaskTitle] = useState();

  const [taskDesc, settaskDesc] = useState();
  const [validate, setValidate] = useState(false)

  const [taskPEDate, settaskPEDate] = useState(
    Moment(myTaskDetail?.planedEndDate)
  );
  const [taskAssignedTo, settaskAssignedTo] = useState(
    myTaskDetail?.assignedTo
      ? myTaskDetail?.assignedTo
      : localStorage.getItem("employeeId")
  );
  const [taskAssignedToName, settaskAssignedToName] = useState(
    myTaskDetail?.name ? myTaskDetail?.name : myTaskDetail?.assignedToName
  );
  const [taskPriority, settaskPriority] = useState(myTaskDetail?.priority);
  const [project, setPRoject] = useState()
  const [functionalAreaa, setFunctionalAreaa] = useState()
  const [relatedToo, setRelatedToo] = useState()
  const [taskStatus, settaskStatus] = useState(myTaskDetail?.status);
  const [showCal, setShowCal] = useState(false);
  const [arrayObj, setarrayObj] = useState([]);
  const [functionalArea, setFunctionalArea] = useState([]);
  const [peojectList, setProjectList] = useState([]);
  const [relatedTo, setRelataedTo] = useState()
  const [showErr, setShowErr] = useState(false);
  const [dateChange, setDateChange] = useState(false);
  const [RelatedToID, setRelatedToID] = useState()
  const [disable, setDisable] = useState(false)
  const [disablee, setDisablee] = useState()
  const [projectShow, setProjectShow] = useState(false)

  const [showDate, setShowDate] = useState(
    Moment(myTaskDetail?.plannedEndDate).format("D MMM YY")
  );
  employeeList.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );


  const handleRedirect = (id) => {
    history.goBack(-1)
  // history.push(`/RMSTaskDetails?id=${props?.taskData}`)
  };

  functionalArea?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref}>{props.value || props.placeholder}</label>
        <img
          type="image/svg+xml"
          width="20px"
          // style={ }}
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const changeDate = (e) => {
    settaskPEDate(e);
    setShowDate(Moment(e).format("D MMM YY"));
    setShowCal(false);
    setDateChange(true);
  };


  const found = () => {
    // if()
    // return element.id === 2
    var e = document.getElementById("selectElement");
    var value = e.options[e.selectedIndex].value; // get selected option value
    var text = e.options[e.selectedIndex].text;
    settaskAssignedToName(text);
    settaskAssignedTo(value);
  };


  const functionalA = (e) => {
    if (e.target.value == 1) {
      setDisable(false)
      setProjectShow(true)
      getProject()
      setDisablee(e.target.value)
      // myTaskDetail?.functionalFieldArea=="Delivery"?project:null
    } else {
      setDisable(true)
      setPRoject(null)
      setProjectShow(false)

      setProjectList([])

    }
    setFunctionalAreaa(e.target.value)

  }

  console.log(project, peojectList)
  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const baseUrl = constants.apiBaseUrlResource;

  const updateData = () => {
    if (!taskAssignedTo || !taskPEDate || !taskPriority || !taskTitle) {
      console.log("validate", validate)
      setValidate(true)
      return;
    }
    const data = {
      taskId: taskId,
      title: taskTitle,
      description: taskDesc,
      planedEndDate: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: taskAssignedTo,
      priority: taskPriority,
      status: taskStatus,
      created: creationDate,
      employeeRelatedToTask: RelatedToID,
      functionalFieldId: 7,
      projectName: project,
      localDateTime: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
    };

    const oldData = {
      taskId: taskId,
      title: taskTitle,
      description: taskDesc,
      employeeRelatedToTask: RelatedToID,
      functionalFieldId: 7,
      projectName: project,
      planedEndDate: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      assignedTo: taskAssignedTo,
      priority: taskPriority,
      status: taskStatus,
      localDateTime: Moment(taskPEDate).format("YYYY-MM-DD hh:mm"),
      name: taskAssignedToName,
      created: creationDate,
    };

    axios
      .post(url2 + "/editTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus) {
          setShowErr(true);
        } else {
          handleRedirect()
        }
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const getFunctionalArea = () => {
    axios
      .get(url2 + "/getAllFunctionalArea", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res)
        setFunctionalArea(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProject = () => {
    axios
      .get(baseUrl + "/getAllActiveProject", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res)
        setProjectList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSingleDetailsById = (id) => {
    axios
      .get(url2 + `/getTaskDetailsByTaskId?taskId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        if (res.data.responseData?.functionalFieldArea == "Delivery") {
          setDisable(false)
        } else {
          setDisable(true)
        }
        setMyTaskDetailByID(res.data.responseData);
        setPRoject(res.data.responseData?.projectName)
        setFunctionalAreaa(res.data.responseData?.functionalFieldAreaId)
        setRelatedToo(res.data.responseData?.taskRelatedToEmployee)
        setRelatedToID(res.data.responseData?.taskRelatedToEmployeeId)
        setRelataedTo(res.data.responseData?.taskRelatedToEmployee)
        settaskTitle(res.data.responseData?.title)
        settaskId(res.data.responseData?.taskId)
        settaskDesc(res.data.responseData?.description)
        settaskAssignedTo(res.data.responseData?.assignedTo)
        settaskAssignedToName(res.data.responseData?.assignedToName)

        settaskPriority(res.data.responseData?.priority)
        settaskStatus(res.data.responseData.status)
        setShowDate(Moment(res.data.responseData.plannedEndDate).format("D MMM YY"))

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const employeedata = () => {
    const historyData = axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setEmployeeList(res.data.responseData.employeeList);
        setarrayObj(
          employeeList.map((item) => {
            return {
              label: item.name,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const history = useHistory();
  useEffect(() => {
    getSingleDetailsById(props?.taskData)
    employeedata();
    getFunctionalArea();
    getProject()
  }, []);



  return (
    <div className={style.rightSection}>
      <div className={style.container} >
        <span>Edit Task</span>
        <div className={style.createTask}>
          <form id="create_task_id" className={style.gridContainer}>
            {/* <div className={style.gridContainer} > */}
            <label className={style.titleLabel} >
              Task Title<span style={{ color: "red" }}>*</span>
              <input
                className={style.input}
                autocomplete="off"
                name="task_title"
                type="input"
                id="tast_title"
                value={taskTitle}
                onChange={(e) => settaskTitle(e.target.value)}
              />
              <p
                className={
                  validate && !taskTitle
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Task Title
              </p>
            </label>

            {/* </div> */}
            <label className={style.assignLabel}>
              Assigned To<span style={{ color: "red" }}>*</span>
              <CustomAutoComplete
                placeholder="Search by Employee Name"
                required="true"
                autocomplete="90%"
                dataList={employeeList}
                onSelect={(value) => {
                  settaskAssignedToName(value.name);
                  // handleChange(value.id)
                  settaskAssignedTo(value.id);
                }}
                onChange={(e) => settaskAssignedToName(e.target.value)}
                value={taskAssignedToName}
              />
              <p
                className={
                  validate && !taskAssignedTo
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Select Employee Name
              </p>
            </label>

            <label className={style.dateLabel}>Planned End Date<span style={{ color: "red" }}>*</span></label>
            <div className={style.inputt}>
              <DatePicker
                dateFormat="D MMM yy"
                minDate={new Date()}
                value={showDate}
                // startDate={taskPEDate}
                tileDisabled={({ date }) => date.getDay() === -1}
                onChange={changeDate}
                disabledKeyboardNavigation
                defaultValue={null}
                customInput={<CustomInput />}
              />

              <label className={style.assignLabel} style={{ marginTop: "14px" }}>
                Functional Area <br />
              </label>
              <select
                //functionalA
                // onChange={(e) => setFunctionalAreaa(e.target.value)}
                onChange={(e) => functionalA(e)}
                disabled={true}
                style={{ width: "100%" }}
              >
                <option>RMS</option>

                {/* <option value="Closed">Closed</option> */}
                {localStorage.getItem("employeeId") ==
                  props.taskData?.assignedBy ? (
                  <option value="Closed">Closed</option>
                ) : null}
              </select>
              {/* {console.log("hhh",localStorage.getItem("employeeId"))} */}

            </div>

            {/* <div className={style.descContainer}> */}
            <label className={style.descLabel}>
              Task Description
              <textarea
                name="task_description"
                type="number"
                id="task_description"
                rows="7"
                cols="78"
                value={taskDesc}
                onChange={(e) => settaskDesc(e.target.value)}
                className={style.text}
                style={{ marginTop: "0.7em" }}
              />
            </label>
            {/* </div> */}
            {/* <div className={style.gridContainer}> */}

            {/* </div> */}
            <div className={style.priorityLabel}>
              <label className={style.priorityLabel12}>
                Priority<span style={{ color: "red" }}>*</span> <br />
                <select
                  className={style.input}
                  onChange={(e) => settaskPriority(e.target.value)}
                  value={taskPriority}
                >
                  <option value="Urgent">Urgent</option>
                  <option value="High">High</option>
                  <option value="Low">Low</option>
                </select>
              </label>
              <label className={style.statusLabel}>
                Status <br />
                <select
                  onChange={(e) => settaskStatus(e.target.value)}
                  value={taskStatus}
                >
                  <option value="Assigned">Assigned</option>
                  <option value="Done">Done</option>
                  <option value="Onhold">On Hold</option>
                  {/* <option value="Closed">Closed</option> */}
                  {localStorage.getItem("employeeId") ==
                    props.taskData?.assignedBy ? (
                    <option value="Closed">Closed</option>
                  ) : null}
                </select>
              </label>
            </div>

            <div className={style.priorityLabel1}>
              <label className={style.priorityLabel12}>
                Project <br />
                <select
                  className={style.input}
                  onChange={(e) => setPRoject(e.target.value)}
                // value={project}

                >
                  <option hidden>{project}</option>
                  {peojectList?.map((val) => {
                    return <option value={val.name}>{val?.name}</option>;
                  })}
                </select>
              </label>
              <label className={style.statusLabel}>
                Task Related To <br />
                <div>
                  <CustomAutoComplete
                    placeholder="Search by Employee Name"
                    required="true"
                    autocomplete="91%"
                    dataList={employeeList}
                    onSelect={(value) => {
                      setRelataedTo(value.name);
                      setRelatedToID(value.id)
                    }}
                    onChange={(e) => setRelataedTo(e.target.value)}
                    value={relatedTo}
                  />
                </div>
              </label>
            </div>
          </form>
        </div>
        <button className={style.submitButton} onClick={() => updateData()}>
          Update
        </button>
        <button
          className={style.backButton}
          onClick={() => history.goBack(-1)}>
          {" "}
          Back
        </button>
      </div>
    </div>
  );
};

export default EditTaskComponent;
