import React from "react";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";

import styles from "./Sidebar.module.css";

import { ReactComponent as Menu } from "../../../../assets/Grievance portal/icon-menu.svg";
import { ReactComponent as CustomerReview } from "../../../../assets/Grievance portal/icon-customer-review.svg";
import { ReactComponent as SpeechBubble } from "../../../../assets/Grievance portal/icon-speech-bubble.svg";
import { ReactComponent as Plus } from "../../../../assets/Grievance portal/icon-plus.svg";
import { ReactComponent as Choice } from "../../../../assets/Grievance portal/icon-choice.svg";

const Sidebar = () => {
    const location = useLocation();

    return (
        <div className={styles.container}>
            <div className={cx(styles.navigationButton, styles.grievanceportal)} style={{ border: "none", cursor: "auto" }}>
                <div>
                    <CustomerReview className={styles.mainButtonIcon}  />
                </div>
                <span className={styles.mainSpan}> Grievance Portal </span>
            </div>

            <Link to="/Grievance-Portal" className={cx(styles.navigationButton, location.pathname === "/Grievance-Portal" ? styles.active : styles.inactive)}>
                <Menu fill={location.pathname === "/Grievance-Portal" ? "#41cbb6" : "#ffffff"} className={styles.navigationButtonIcons} />
                <span className={styles.alternateSpans}> Dashboard </span>
            </Link>

            <Link to="/Grievance-Portal/view-RecievedTicket" className={cx(styles.navigationButton, location.pathname === "/Grievance-Portal/view-RecievedTicket" ? styles.active : styles.inactive)}>
                <Choice fill={location.pathname === "/Grievance-Portal/view-RecievedTicket" ? "#41cbb6" : "#ffffff"} className={styles.navigationButtonIcons} />
                <span className={styles.alternateSpans}> Tickets Assigned to Me </span>
            </Link>

            <Link to="/view-SentTickets" className={cx(styles.navigationButton, location.pathname === "/view-SentTickets" ? styles.active : styles.inactive)}>
                <SpeechBubble fill={location.pathname === "/view-SentTickets" ? "#41cbb6" : "#ffffff"} className={styles.navigationButtonIcons} />
                <span className={styles.alternateSpans}> Tickets Raised by Me </span>
            </Link>

            <Link to="/Grievance-Portal/RaiseTicket" className={cx(styles.navigationButton, location.pathname === "" ? styles.active : styles.inactive)}>
                <Plus fill={location.pathname === "/Grievance-Portal/RaiseTicket" ? "#41cbb6" : "#ffffff"} className={styles.navigationButtonIcons} />
                <span className={styles.alternateSpans}> Raise Ticket </span>
            </Link>
        </div>
    );
};

export default Sidebar;
