import React from "react";
import { Container, Row, Col, Button, Card, Table } from "react-bootstrap";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteIcon from "@mui/icons-material/Delete";
import laptop from "../../../../../assets/laptop.png";
import "./HealthMRecord.css";
import { useHistory } from "react-router-dom";

const ary = [
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
  },
];

const HealthMRecordComp = () => {
  const history = useHistory();
  const addNewLaptop = () => {
    history.push("/mobile/addmobileassign");
  };
  const assignLap = () => {
    history.push("/mobile/assign");
  };
  const issueLaptop = () => {
    history.push("/mobile/issue");
  };
  return (
    <>
      <>
        <div className="main">
          <Container>
            <Row>
              <Col sm={12} className="hed"></Col>
            </Row>
          </Container>
          <Card className="_card">
            <Container>
              <Row>
                <Col sm={2}>
                  <img className="mt-0 mb-1" src={laptop} alt="laptop_img" />
                </Col>
                <Col>
                  <Container className="mb-3 mt-0">
                    <Row>
                      <Col>
                        <label> Serial Number</label> <br />
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="123456789"
                        ></input>
                      </Col>
                      <Col>
                        <label>Brand</label> <br />
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="DELL"
                        ></input>
                      </Col>
                      <Col>
                        <label>RAM</label> <br />
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="16GB"
                        ></input>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-2">
                        <label> MAC Address</label> <br />
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="123456789jhkj"
                        ></input>
                      </Col>
                      <Col className="mt-2">
                        <label>Type</label> <br />
                        <input
                          type="text"
                          id="fname"
                          name="fname"
                          placeholder="kuch bhi"
                        ></input>
                      </Col>
                      <Col className="mt-4">
                        <label className="mt-1">
                          View Complete Details <ArrowRightAltIcon />
                        </label>
                        <br />
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Card>
          <Card className="_card mt-4">
            <Row className="mt-2 mb-1">
              <Col sm={2} className="addAssss" onClick={assignLap}>
                Assigment
              </Col>
              <Col sm={2} className="addIsss" onClick={issueLaptop}>
                Issue
              </Col>
              <Col sm={2} className="addHR">
                <span className="line">Health Record</span>
              </Col>
              <Col sm={6} className="addbtn">
                <Button onClick={addNewLaptop} variant="primary">
                  <AddIcon /> Add New
                </Button>
              </Col>
            </Row>
          </Card>

          <Container className="mt-0">
            <Card className="_mcard">
              <Table responsive className="tabl">
                <thead>
                  <tr>
                    <th>Current Status</th>
                    <th>
                      Last Checked On <ArrowDownwardIcon />
                    </th>
                    <th>Checked By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {ary.map((ar) => (
                    <tr className="tabledata">
                      <td className="tabletaskId">{ar.a}</td>
                      <td>{ar.b}</td>
                      <td>{ar.c}</td>
                      <td className="tableaction">
                        {/* <img
              onClick={() => history.push("/viewTask")}
              src={pencilEditButtonImg}
            />
            <img src={rubbishBinImg} /> */}
                        <EditIcon color="primary" />
                        {/* <DeleteIcon color="primary" /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Container>
        </div>
      </>
    </>
  );
};

export default HealthMRecordComp;
