import axios from "axios";
import cookie from "react-cookies";
const apiKey = process.env.REACT_APP_ATS_APP_URL;
let token = cookie.load("token");
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.post["Content-Type"] = "application/json";
// let apiKey;
// if (process.env.NODE_ENV !== "production") {
//   apiKey = "https://serviceimportal.innovationm.com/ats/v1";
// } else {
//   apiKey = "https://services-portal.innovationm.com/ats/v1";
// }

export const positionList = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllCreatePositions?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const getAllPosition = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllPosition?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};
export const getCurrentAllocations = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getCurrentAllocations?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};
export const getCurrentAllocationsNonClient = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getCurrentAllocationsNonClient?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const getCandidateAllocations = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getCandidateAllocations?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const createPosition = async (data) => {
  const response = await axios.post(`${apiKey}/createPosition`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};
export const deallocateEmployeeOnPosition = async (data) => {
  const response = await axios.post(
    `${apiKey}/deallocateEmployeeOnPosition?allocationId=${data}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const allocateEmployeeOnPosition = async (data) => {
  const response = await axios.post(
    `${apiKey}/allocateEmployeeOnPosition`,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: localStorage.getItem("employeeId"),
      },
    }
  );
  return response.data.responseData;
};

export const getCandidateRoles = async () => {
  const response = await axios.get(
    `${apiKey}/getAllCandidateRoles
    `
  );
  return response.data.responseData;
};

export const getAllEduction = async () => {
  const response = await axios.get(
    `${apiKey}/getAllEduction
    `
  );
  return response.data.responseData;
};

export const getAllPreferredSkill = async () => {
  const response = await axios.get(
    `${apiKey}/getSkills
    `
  );
  return response.data.responseData;
};

export const getMandatorySkillByCoreSkillId = async () => {
  const response = await axios.get(
    `${apiKey}/getSkills
    `
  );
  return response.data.responseData;
};

export const getSkills = async () => {
  const response = await axios.get(
    `${apiKey}/getSkills
    `
  );
  return response.data.responseData;
};

export const getCoreSkill = async () => {
  const response = await axios.get(
    `${apiKey}/getSkills
    `
  );
  return response.data.responseData;
};

export const getPositionHistoryDetails = async (limit, page, positionId) => {
  const response = await axios.get(
    `${apiKey}/getPositionHistoryDetails?limit=${limit}&page=${page}&positionId=${positionId}`
  );
  return response.data.responseData;
};
export const getPositionById = async (id) => {
  const response = await axios.get(`${apiKey}/getPositionById?id=${id}`);
  return response.data.responseData;
};

export const getLeadEmployeeList = async (id) => {
  const response = await axios.get(
    `${apiKey}/getLeadEmployeeList?leadId=${id}`
  );
  return response.data.responseData;
};
export const getPositionCreatedBy = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-position-created-by?empId=${id}`
  );
  return response.data.responseData;
};
export const getRecruiterAndLeadsList = async () => {
  const response = await axios.get(`${apiKey}/getRecruiterAndLeadsList`);
  return response.data.responseData;
};

export const getPositionOwnerList = async () => {
  const response = await axios.get(`${apiKey}/getPositionOwnerList`);
  return response.data.responseData;
};

// export const getPositionById = async (id) => {
//   const response = await axios.get(
//     `${apiKey}/getPositionById?id=${id}`
//   );
//   return response.data.responseData;
// };

export const getAllocations = async (limit, page, positionId) => {
  const response = await axios.get(
    `${apiKey}/getAllocations?limit=${limit}&page=${page}&positionId=${positionId}`
  );
  return response.data.responseData;
};

export const getPositionEmployeeDetails = async () => {
  const response = await axios.get(`${apiKey}/getRecruiterAndLeadsList`);
  return response.data.responseData;
};
export const getAllocationByEmpID = async (empId) => {
  const response = await axios.get(
    `${apiKey}/getAllocationByEmpID?empId=${empId}`
  );
  return response.data.responseData;
};
export const getPastAllocationByPositionId = async (
  limit,
  page,
  positionId
) => {
  const response = await axios.get(
    `${apiKey}/getPastAllocationByPositionId?limit=${limit}&page=${page}&positionId=${positionId}`
  );
  return response.data.responseData;
};

export const getEditById = async (data) => {
  const response = await axios.post(`${apiKey}/editPosition`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};

export const getPositionCodeForFilter = async (id) => {
  const response = await axios.get(
    `${apiKey}/getPositionCodeForFilter?empId=${localStorage.getItem(
      "employeeId"
    )}
    `
  );
  return response.data.responseData;
};

export const getAllocatedPositionCodeForFilter = async () => {
  const response = await axios.get(
    `${apiKey}/getAllocatedPositionCodeForFilter?empId=${localStorage.getItem(
      "employeeId"
    )}
    `
  );
  return response.data.responseData;
};

export const getPositionCoreSkillForFilter = async () => {
  const response = await axios.get(
    `${apiKey}/getPositionCoreSkillForFilter
    `
  );
  return response.data.responseData;
};

export const getPositionOwnerForFilter = async () => {
  const response = await axios.get(
    `${apiKey}/getPositionOwnerForFilter
    `
  );
  return response.data.responseData;
};
export const deletePositionByPositionId = async (id) => {
  const response = await axios.delete(
    `${apiKey}/deletePositionByPositionId?id=${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};

export const deleteResume = async (url) => {
  const response = await axios.put(`${apiKey}/deleteResume?url="${url}"`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};

export const changePositionStatus = async (data) => {
  const response = await axios.post(`${apiKey}/changePositionStatus`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};
export const downloadPositionInformation = async (data) => {
  const response = await axios.post(
    `${apiKey}/downloadPositionInformation`,
    data,
    {
      responseType: "arraybuffer",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    }
  );
  return response;
};

// create candidate
export const createCandidate = async (data) => {
  const response = await axios.post(`${apiKey}/createCandidate`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data;
};

//${apiKey}/uploadResume post api
export const uploadResume = async (data) => {
  let formData = new FormData();
  formData.append("file", data);
  const response = await axios.post(`${apiKey}/uploadResume`, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data.responseData;
};

export const getCandidateById = async (id) => {
  const response = await axios.get(
    `${apiKey}/getCandidateById?candidateId=${id}`
  );
  return response.data.responseData;
};
export const getAllCandidates = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllCandidates?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const getAllNonClientCandidates = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllNonClientCandidates?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const getAllClientCandidates = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllClientCandidates?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const deleteCandidateById = async (id) => {
  const response = await axios.delete(
    `${apiKey}/deleteCandidateById?candidateId=${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const viewCandidateById = async (id) => {
  const response = await axios.get(
    `${apiKey}/viewCandidateById?candidateId=${id}`
  );
  return response.data.responseData;
};
export const allocateCandidateForPosition = async (obj) => {
  const response = await axios.post(
    `${apiKey}/allocateCandidateForPosition?candidateId=${obj?.candidateId}&positionId=${obj?.positionID}&recruiterId=${obj.recruiterId}`,

    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const allocateCandidateOnClientPositions = async (obj) => {
  const response = await axios.post(
    `${apiKey}/allocateCandidateOnClientPositions`,
    obj,

    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data;
};
export const getNoticePeriodList = async () => {
  const response = await axios.get(`${apiKey}/getNoticePeriodList`);
  return response.data.responseData;
};
export const getOfferByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getOfferByCandidateId?candidateId=${id}
    `
  );
  return response.data.responseData;
};

export const createOffer = async (data) => {
  const response = await axios.post(`${apiKey}/createCandidateOffer`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};

export const getOfferByOfferId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getOfferByOfferId?offerId=${id}
    `
  );

  return response.data.responseData;
};

export const getPastMonthOffersByRecruiterId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getPastMonthOffersByRecruiterId?recruiterId=${id}`
  );
  return response.data.responseData;
};

export const releaseOffer = async (data) => {
  const response = await axios.post(`${apiKey}/releaseOffer`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};

export const changeOfferStatus = async (data) => {
  const response = await axios.post(
    `${apiKey}/changeOfferStatus
    `,
    data,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};

export const getInterviewMode = async () => {
  const response = await axios.get(`${apiKey}/getInterviewMode`);
  return response.data.responseData;
};

export const getInterviewType = async () => {
  const response = await axios.get(`${apiKey}/getInterviewType`);
  return response.data.responseData;
};

export const getDuration = async () => {
  const response = await axios.get(`${apiKey}/getDuration`);
  return response.data.responseData;
};

export const getAllInterviewerName = async (id) => {
  console.log({ id });
  const response = await axios.get(
    `${apiKey}/getAllInterviewerName?type=${id}`
  );
  return response.data.responseData;
};

export const getAllCandidateInterviewList = async (limit, page, data) => {
  const response = await axios.post(
    `${apiKey}/getAllCandidateInterviewList?limit=${limit}&page=${page}`,
    data
  );
  return response.data.responseData;
};

export const getCandidateDetailsByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getCandidateDetailsByCandidateId?candidateId=${id}`
  );
  return response.data.responseData;
};

export const getCandidateInterviewListByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getCandidateInterviewListByCandidateId?candidateId=${id}`
  );
  return response.data.responseData;
};

export const UpdateOffer = async (data) => {
  const response = await axios.post(`${apiKey}/editOffer`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};

export const approveOffer = async (empId, offerId) => {
  const response = await axios.post(
    `${apiKey}/approveOffer?empId=${empId}&offerId=${offerId}`
  );
  return response.data.responseData;
};

export const addAcceptDeclineOffer = async (data) => {
  const response = await axios.post(`${apiKey}/addAcceptDeclineOffer`, data);
  return response.data;
};

export const getAllOffers = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllOffers?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};

export const scheduleInterview = async (data) => {
  console.log("API called")
  const response = await axios.post(`${apiKey}/scheduleInterview`, data);
  return response.data;
};
export const getFeedbackField = async (id) => {
  const response = await axios.get(
    `${apiKey}/getFeedbackField?candidateId=${id}`
  );
  return response.data.responseData;
};

export const getInterviewById = async (id) => {
  const response = await axios.get(
    `${apiKey}/getInterviewById?interviewId=${id}`
  );
  return response.data.responseData;
};
export const sendFeedbackMail = async (id) => {
  const response = await axios.get(
    `${apiKey}/sendFeedbackMail?interviewId=${id}`
  );
  return response.data.responseData;
};
export const uploadFeedback = async ({ interviewId, file }) => {
  let formData = new FormData();
  formData.append("file", file);
  const response = await axios.post(
    `${apiKey}/uploadFeedback?interviewId=${interviewId}`,
    formData
  );
  return response.data.responseData;
};

export const getInterviewStatus = async () => {
  const response = await axios.get(`${apiKey}/getInterviewStatus`);
  return response.data.responseData;
};
export const submitFeedback = async (data) => {
  const response = await axios.post(`${apiKey}/submitFeedback`, data);
  return response.data;
};

// -------------------Joing Process----------------

export const getTaskListDropDown = async () => {
  const response = await axios.get(`${apiKey}/getTaskListDropDown`);
  return response.data.responseData;
};

export const getAllJoiningStatusList = async () => {
  const response = await axios.get(`${apiKey}/getAllJoiningStatusList`);
  return response.data.responseData;
};

export const getTaskListByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getTaskListByCandidateId?candidateId=${id}`
  );
  return response.data.responseData;
};

export const getTaskByTaskId = async (id) => {
  const response = await axios.get(`${apiKey}/getTaskByTaskId?taskId=${id}`);
  return response.data.responseData;
};

export const getAllCandidateByRecruiterID = async (limit, page, filter) => {
  const response = await axios.post(
    `${apiKey}/getAllCandidateByRecruiterID?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};
export const createTask = async (data) => {
  const response = await axios.post(`${apiKey}/createTask`, data);
  return response.data;
};
export const deleteTask = async ({ recruiterId, taskId }) => {
  const response = await axios.post(
    `${apiKey}/deleteTask?recruiterId=${recruiterId}&taskId=${taskId}`
  );
  return response.data;
};
export const getCandidateTaskDetailsByRecruiterId = async (
  limit,
  currentPage,
  filter
) => {
  const response = await axios.post(
    `${apiKey}/getCandidateTaskDetailsByRecruiterId?limit=10&page=${currentPage}`,
    filter
  );
  return response.data.responseData;
};
export const getAllClients = async (limit, name, page) => {
  const response = await axios.get(
    `${apiKey}/getAllClients?limit=${limit}&name=${name}&page=${page}`
  );
  return response.data.responseData;
};

export const createClient = async (data) => {
  const response = await axios.post(`${apiKey}/createClient`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};

export const deleteClient = async (id) => {
  const response = await axios.delete(`${apiKey}/deleteClient?clientId=${id}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
    },
  });
  return response.data.responseData;
};
export const getClientById = async (id) => {
  const response = await axios.get(`${apiKey}/getClientById?clientId=${id}`);
  return response.data.responseData;
};

export const addMandatorySkill = async ({ coreSkillId, mandatorySkill }) => {
  const response = await axios.post(
    `${apiKey}/addMandatorySkill?coreSkillId=${coreSkillId}&mandatorySkill=${mandatorySkill}`
  );
  return response.data.responseData;
};
export const addSkill = async ({ skill }) => {
  let data = { name: skill };
  const response = await axios.post(`${apiKey}/addSkill`, data);
  return response.data.responseData;
};
export const getAllPartner = async (limit, name, page, ratingId) => {
  const response = await axios.get(
    `${apiKey}/getAllPartnerList?limit=${limit}&name=${name}&page=${page}&ratingId=${ratingId}`
  );
  return response.data.responseData;
};

export const CreatePartner = async (data) => {
  const response = await axios.post(`${apiKey}/createPartner`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};

export const deletePartner = async (id) => {
  const response = await axios.delete(
    `${apiKey}/deletePartner?partnerId=${id}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
      },
    }
  );
  return response.data.responseData;
};
export const getPartnerById = async (id) => {
  const response = await axios.get(`${apiKey}/getPartnerById?id=${id}`);
  return response.data.responseData;
};

export const getAllClientName = async (empId) => {
  if (!empId) {
    empId = "";
  }
  console.log({ empId });
  const response = await axios.get(`${apiKey}/getAllClientName?empId=${empId}`);
  return response.data.responseData;
};

export const getAllPartnerName = async () => {
  const response = await axios.get(`${apiKey}/getAllPartnerName`);
  return response.data.responseData;
};

export const getAllSourceList = async () => {
  const response = await axios.get(`${apiKey}/getAllSourceList`);
  return response.data.responseData;
};
export const addPreferredSkill = async ({ preferredSkill }) => {
  const response = await axios.post(
    `${apiKey}/addPreferredSkill?preferredSkill=${preferredSkill}`
  );
  return response.data.responseData;
};
export const getAllAllocatedClientCandidate = async (data) => {
  const response = await axios.post(
    `${apiKey}/getAllAllocatedClientCandidate`,
    data
  );
  return response.data.responseData;
};

export const getAllCandidateAllocationStages = async (
  id,
  isForEmployee,
  prositionId
) => {
  const response = await axios.get(
    `${apiKey}/getAllCandidateAllocationStages?id=${id}&isForEmployee=${isForEmployee}&positionId=${prositionId}`
  );
  return response.data.responseData;
};

export const getAllocationStageById = async (id, isForEmployee) => {
  const response = await axios.get(
    `${apiKey}/getAllocationStageById?id=${id} &isForEmployee=${isForEmployee}`
  );
  return response.data.responseData;
};

export const getAllStages = async () => {
  const response = await axios.get(`${apiKey}/getAllStages`);
  return response.data.responseData;
};

export const createAllocationStage = async (data) => {
  const response = await axios.post(`${apiKey}/createAllocationStage`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data.responseData;
};

export const getClientPositions = async (limit, page, positionCode) => {
  const response = await axios.get(
    `${apiKey}/getClientPositions?empId=${localStorage.getItem(
      "employeeId"
    )}&limit=${limit}&page=${page}&positionCode=${positionCode}`
  );
  return response.data.responseData;
};

export const getNoAssessmentScheduled = async (limit, page, empId) => {
  const response = await axios.get(
    `${apiKey}/getNoAssessmentScheduled?employeeId=${empId}&limit=${limit}&page=${page}`
  );
  return response.data.responseData;
};

export const getInterviewPlateform = async () => {
  const response = await axios.get(`${apiKey}/getInterviewPlatform`);
  return response.data.responseData;
};

export const dropCandidateStatus = async (filter) => {
  const response = await axios.post(`${apiKey}/dropCandidateStatus`, filter);
  return response.data.responseData;
};

export const getfeedbackbyinterviewid = async (id) => {
  const response = await axios.get(
    `${apiKey}/getFeedbackByInterviewId?interviewId=${id}`
  );
  return response.data.responseData;
};

export const getAllDropReason = async (id) => {
  const response = await axios.get(`${apiKey}/getAllDropReason`);
  return response.data.responseData;
};

export const getInterviewTypeByInterviewId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getInterviewTypeByInterviewId?interviewId=${id}`
  );
  return response.data.responseData;
};

export const checkIfCandidateEligibleForCreateOffer = async (id) => {
  const response = await axios.get(
    `${apiKey}/checkIfCandidateEligibleForCreateOffer?candidateId=${id}`
  );
  return response.data.responseData;
};

export const checkIfCandidateEligibleForInterviewSchedule = async (id) => {
  const response = await axios.get(
    `${apiKey}/checkIfCandidateEligibleForInterviewSchedule?candidateId=${id}`
  );
  return response.data.responseData;
};

export const checkEligibilityForCreateOffer = async (id) => {
  const response = await axios.get(
    `${apiKey}/checkEligibilityForCreateOffer?candidateId=${id}`
  );
  return response.data.responseData;
};

export const addJoiningDate = async (filter) => {
  const response = await axios.post(`${apiKey}/addJoiningDate`, filter);
  return response.data.responseData;
};

// getEmpRoleByEmpId?empId=42 get api
export const getEmpRoleByEmpId = async (id) => {
  const response = await axios.get(`${apiKey}/getEmpRoleByEmpId?empId=${id}`);
  return response.data.responseData;
};

export const getScheduleByInterviewId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getInterviewDataById?interviewId=${id}`
  );
  return response.data.responseData;
};
export const viewCandidateDetailsById = async (
  candidateId,
  positionId,
  isForEmployee,
  empId
) => {
  if (!positionId) {
    positionId = "";
  }
  const response = await axios.get(
    `${apiKey}/viewCandidateDetailsById?candidateId=${candidateId}&positionId=${positionId}&empId=${empId}&isForEmployee=${isForEmployee}`
  );
  return response.data.responseData;
};

export const addNote = async (filter) => {
  const response = await axios.post(`${apiKey}/addNote`, filter);
  return response.data.responseData;
};
export const updateNote = async (filter) => {
  const response = await axios.put(`${apiKey}/updateNote`, filter);
  return response.data.responseData;
};

export const getEmployeesAllNotes = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeesAllNotes?empId=${id}`
  );
  return response.data.responseData;
};

export const getNoteById = async (id) => {
  const response = await axios.get(`${apiKey}/getNoteById?noteId=${id}`);
  return response.data.responseData;
};

export const deleteNote = async (id) => {
  const response = await axios.get(`${apiKey}/deleteNote?noteId=${id}`);
  return response.data.responseData;
};

export const getFinalStatusByCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/getFinalStatusByCandidateId?candidateId=${id}`
  );
  return response.data.responseData;
};
export const markAsCompleted = async (id) => {
  const response = await axios.post(`${apiKey}/markAsCompleted?taskId=${id}`);
  return response.data.responseData;
};

export const getCandidateHistoryByCandidateID = async (
  limit,
  page,
  candidateId
) => {
  const response = await axios.get(
    `${apiKey}/getCandidateHistoryByCandidateID?limit=${limit}&page=${page}&candidateId=${candidateId}`
  );
  return response.data.responseData;
};

export const downloadScreeningCommentsByCandidateId = async (data) => {
  const response = await axios.post(
    `${apiKey}/downloadScreeningCommentsByCandidateId?candidateId=${data}`,
    {
      responseType: "arraybuffer",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
    }
  );
  return response;
};

export const getAllDegrees = async () => {
  const response = await axios.get(`${apiKey}/getAllDegrees`, {
    headers: {
      Authorization: "",
    },
  });
  return response.data.responseData;
};
export const getAllPriorityList = async () => {
  const response = await axios.get(`${apiKey}/getAllPriorityList`);
  return response.data.responseData;
};
export const getClientPositionCodesForFilter = async () => {
  const response = await axios.get(`${apiKey}/getClientPositionCodesForFilter`);
  return response.data.responseData;
};
export const getWorkModesList = async () => {
  const response = await axios.get(`${apiKey}/getWorkModesList`);
  return response.data.responseData;
};
export const checkCandidateByEmail = async (candidateId, email) => {
  const response = await axios.get(
    `${apiKey}/checkCandidateByEmail?candidateId=${candidateId}&email=${email}`
  );
  return response.data.responseData;
};
export const checkCandidateByMobileNo = async (candidateId, mobileNo) => {
  const response = await axios.get(
    `${apiKey}/checkCandidateByMobileNo?candidateId=${candidateId}&mobileNo=${mobileNo}`
  );
  return response.data.responseData;
};
export const addDegree = async ({ degree }) => {
  const response = await axios.post(`${apiKey}/addDegree?degree=${degree}`);
  return response.data.responseData;
};
export const getCandidateJourney = async (candidateId, sourcing) => {
  const response = await axios.get(
    `${apiKey}/get-candidate-journey?candidateId=${candidateId}&sourcing=${sourcing}`
  );
  return response.data.responseData;
};
export const getCandidatesByName = async (name) => {
  const response = await axios.get(
    `${apiKey}/get-candidates-by-name?name=${name}&empId=${localStorage.getItem(
      "employeeId"
    )}`
  );
  return response.data;
};
export const viewEmployeeDetailsById = async (empId) => {
  const response = await axios.get(
    `${apiKey}/viewEmployeeDetailsById?empId=${empId}`
  );
  return response.data?.responseData;
};
export const getPositionReport = async (data) => {
  const response = await axios.post(`${apiKey}/get-position-report`, data);
  return response.data?.responseData;
};
export const getFutureHireList = async () => {
  const response = await axios.get(`${apiKey}/getFutureHireList`);
  return response.data?.responseData;
};
export const getJoiningDropDetailDyCandidateId = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-joining-drop-detail-by-candidate-id?candidateId=${id}`
  );
  return response.data?.responseData;
};
export const getSourcingReport = async (data) => {
  const response = await axios.post(`${apiKey}/get-sourcing-report`, data);
  return response.data.responseData;
};
export const deleteAllocationStages = async ({ id }) => {
  const response = await axios.delete(
    `${apiKey}/delete-allocation-stages?stageId=${id}`
  );
  return response.data.responseData;
};
export const getAllLeadList = async () => {
  const response = await axios.get(`${apiKey}/get-all-lead-list`);
  return response.data?.responseData;
};
export const getClientServiceReport = async (id, client) => {
  const response = await axios.get(
    `${apiKey}/get-client-service-report?createdBy=${id}&client=${client}`
  );
  return response.data?.responseData;
};
export const getDetailedSourceReport = async (data) => {
  const response = await axios.post(
    `${apiKey}/get-detailed-source-report`,
    data
  );
  return response.data?.responseData;
};
export const getPositionSourcingReport = async (id, type) => {
  const response = await axios.post(
    `${apiKey}/get-position-sourcing-report?createdBy=${id}&positionType=${type}`
  );
  return response.data?.responseData;
};
export const getEmployeeNameById = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-employee-name-by-id?empId=${id}`
  );
  return response.data?.responseData;
};
export const getLowerEmployee = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-lower-employee?empId=${id}&active=true`
  );
  return response.data?.responseData;
};
export const getEmploymentType = async () => {
  const response = await axios.get(`${apiKey}/get-employment-type`);
  return response.data?.responseData;
};
export const getAllRecruiterByManager = async (id) => {
  const response = await axios.get(
    `${apiKey}/get-all-recruiter-by-manager?empId=${id}`
  );
  return response.data?.responseData;
};
export const getCaseSharedStatus = async () => {
  const response = await axios.get(`${apiKey}/get-case-shared-status`);
  return response.data?.responseData;
};
export const getSalaryAssessmentStatus = async () => {
  const response = await axios.get(`${apiKey}/get-salary-assessment-status`);
  return response.data?.responseData;
};
export const addCaseSharedStatus = async (data) => {
  const response = await axios.post(`${apiKey}/add_case_shared_status`, data);
  return response.data?.responseData;
};
export const addSalaryAssessmentStatus = async (data) => {
  const response = await axios.post(
    `${apiKey}/add_salary_assessment_status
`,
    data
  );
  return response.data?.responseData;
};
export const getCaseSharedStatusById = async (id) => {
  const response = await axios.get(
    `${apiKey}/get_candidate_case_shared_status?candidateId=${id}`
  );
  return response.data?.responseData;
};
export const getCandidateSalaryAssById = async (id) => {
  const response = await axios.get(
    `${apiKey}/get_candidate_salary_assessment_status?candidateId=${id}`
  );
  return response.data?.responseData;
};
export const cancelMarkForOffer = async (id) => {
  const response = await axios.put(
    `${apiKey}/cancel-mark-for-offer?candidateId=${id}`
  );
  return response.data?.responseData;
};
export const assignNewRecruiter = async ({ candidateId, recruiterId }) => {
  const response = await axios.put(
    `${apiKey}/assign-new-recruiter?candidateId=${candidateId}&recruiterId=${recruiterId}`
  );
  return response.data?.responseData;
};
export const getAllRecruiters = async () => {
  const response = await axios.get(`${apiKey}/get-all-recruiters`);
  return response.data?.responseData;
};

export const getPositionCodeByFilter = async (empId) => {
  const response = await axios.post(`${apiKey}/getPositionCodeByFilter`, empId);
  return response.data?.responseData;
};
export const getCandidateBgvList = async (limit, page, candidateName) => {
  const response = await axios.get(
    `${apiKey}/getCandidateBgvList?candidateName=${candidateName}&limit=${limit}&page=${page}`
  );
  return response.data?.responseData;
};
export const getBGVByCandidate = async (candidateId) => {
  const response = await axios.get(
    `${apiKey}/getBGVByCandidate?candidateId=${candidateId}`
  );
  return response.data?.responseData;
};
export const createBGV = async (data) => {
  const response = await axios.post(`${apiKey}/createBGV`, data);
  return response.data?.responseData;
};
export const deleteBgvByBgvId = async ({ bgvId }) => {
  const response = await axios.delete(
    `${apiKey}/deleteBgvByBgvId?bgvId=${bgvId}`
  );
  return response.data?.responseData;
};
export const addPartnerRating = async (data) => {
  const response = await axios.post(`${apiKey}/addPartnerRating`, data);
  return response.data?.responseData;
};
export const getAllPartnerRatingList = async (partnerId) => {
  const response = await axios.get(
    `${apiKey}/getAllPartnerRatingList?partnerId=${partnerId}`
  );
  return response.data?.responseData;
};

export const getAcademicsByEmail = async ({ emailId }) => {
  const response = await axios.get(
    `${apiKey}/get-academics-by-email?emailId=${emailId}`,
    {
      headers: {
        Authorization: "",
      },
    }
  );
  return response.data?.responseData;
};
export const getWorkExperienceByEmail = async ({ emailId }) => {
  const response = await axios.get(
    `${apiKey}/get-work-experience-by-email?emailId=${emailId}`,
    {
      headers: {
        Authorization: "",
      },
    }
  );
  return response.data?.responseData;
};
export const addReadyForClientSharing = async (data) => {
  const response = await axios.put(
    `${apiKey}/add-ready-for-client-sharing`,
    data
  );
  return response.data?.responseData;
};
export const getCandidateAllocatedPositions = async (candidateId, isEmp) => {
  const response = await axios.get(
    `${apiKey}/get-candidate-allocated-positions?candidateId=${candidateId}&isEmp=${isEmp}`
  );
  return response.data?.responseData;
};
export const getEmployeeClientAllocationStages = async (empId, limit, page) => {
  const response = await axios.get(
    `${apiKey}/get-employee-client-allocation-stages?empId=${empId}&limit=${limit}&page=${page}`
  );
  return response.data?.responseData;
};
export const getAllJoinedCandidates = async () => {
  const response = await axios.get(`${apiKey}/get-all-joined-candidates`, {
    headers: {
      Authorization: "",
    },
  });
  return response.data?.responseData;
};
export const getPositionStatus = async () => {
  const response = await axios.get(`${apiKey}/get-position-status`);
  return response.data?.responseData;
};
export const getEmploymentModes = async () => {
  const response = await axios.get(`${apiKey}/get-employment-modes`);
  return response.data?.responseData;
};

export const addPositionStatus = async (data) => {
  const response = await axios.post(`${apiKey}/add-position-status`, data);
  return response.data?.responseData;
};

export const getSourcingPerformanceMatrixReport = async (
  leadId,
  reportType,
  report
) => {
  const response = await axios.get(
    `${apiKey}/get-sourcing-performance-matrix-report?leadId=${leadId}&report=${report}&reportType=${reportType}`
  );
  return response.data?.responseData;
};
export const getBgvByCandidateEmail = async (data) => {
  console.log({ data });
  const response = await axios.get(
    `${apiKey}/get-bgv-by-candidate-email?emailId=${data}`
  );
  return response.data?.responseData;
};
export const getClosureReport = async (id, year) => {
  id = id ? id : "";
  const response = await axios.get(
    `${apiKey}/get-closure-report?leadId=${id}&year=${year}`
  );
  return response.data?.responseData;
};
export const getSourcingVsSharedReport = async (leadId, reportType) => {
  const response = await axios.get(
    `${apiKey}/get-sourcing-vs-shared-report?leadId=${leadId}&reportType=${reportType}`
  );
  return response.data?.responseData;
};
export const getAvailablePositionForCandidate = async (id, type) => {
  const response = await axios.get(
    `${apiKey}/get-available-position-for-candidate?id={id}&type={type}`
  );
  return response.data?.responseData;
};
export const getUnsharedEmployeeForClient = async (positionId) => {
  const response = await axios.get(
    `${apiKey}/get-unshared-employee-for-client?positionId=${positionId}`
  );
  return response.data?.responseData;
};
export const getMonthlyDeploymentReport = async () => {
  const response = await axios.get(`${apiKey}/get-monthly-deployment-report`);
  return response.data?.responseData;
};
export const getSkillWiseDeploymentReport = async () => {
  const response = await axios.get(
    `${apiKey}/get-skill-wise-deployment-report`
  );
  return response.data?.responseData;
};
export const getClientWiseDeploymentReport = async () => {
  const response = await axios.get(
    `${apiKey}/get-client-wise-deployment-report`
  );
  return response.data?.responseData;
};
export const updateTeamLeadDecision = async (payload)=>{
  const response = await axios.put(
    `${apiKey}/update-team-lead-decision`,
    payload
  )
  return response
}