import style from "./SideBar.module.css";
import one from "../../../assets/businessman-gear-icon.png";
import oneG from "../../../assets/businessman-gear-icon_GREEN.png";
import ats from "./../../../assets/ats.png";
import InnovationMLogo from "./../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import {useHistory} from "react-router-dom";

const SideBar = ({
  sidebarHandler,
  nextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
}) => {
  let history = useHistory();
  return (
    <div className={style.leftSection}>
    <img
        alt="DashBoardImage"
        src={InnovationMLogo}
        className={style.dashboardImage}
        onClick={() => history.push("/dashboard")}
      />
      <button className={style.taskTracker}>
        <img src={ats} alt="img" height="40px" />
        <span>Employee Induction</span>
      </button>
      <div className={style.side_buttons_div}>
        {/* 1 */}
        <button
          onClick={() => {
            sidebarHandler("employeeInduction");
            setCurrentPage(1);
            setPrevData({
              ...prevData,
              sidebar: "employeeInduction",
              page: "employeeInduction",
              page1: "employeeInduction",
              page2: null,
              page3: null,
              page4: null,
              page5: null,
              page6: null,
              active: "employeeInduction",
            });
          }}
          className={
            prevData?.sidebar === "employeeInduction"
              ? style.selected
              : style.assignedByMe
          }
        >
          <div className={style.sb0}>
            {prevData?.sidebar === "employeeInduction" ? (
              <img src={oneG} alt="img" />
            ) : (
              <img src={one} alt="img" />
            )}
          </div>
          <div className={style.sb}>
            <span>Employee Induction</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SideBar;
