import React, { useEffect, useState } from "react";
import classes from "./PartnerList.module.css";
import { Col, Container, Row } from "react-bootstrap";
import searchIcon from "../../../../assets/Icon-search.png";
import Select from "react-select";
import { customSearch } from "../../utils/style";
import { AiOutlineClose } from "react-icons/ai";
import { useQuery } from "react-query";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import CustomTooltip from "../../../PartnerManagement/customTooltip/CustomTooltip.js";

import {
  getPartnerName,
  PartnerListData,
  getSKillName,
  GetStatusType,
  validateEmployeeAccess
} from "../../../../Utils/axios/PortalManagement.js";
import Pagination from "../../CommonComponent/pagination/pagination.js";

const PartnerList = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [filter, setFilter] = useState(
    prevData?.PMFilter
      ? prevData?.PMFilter
      : {
          empId: 0,
          empName: "",
          empSkillId: "",
          empStatusId: "",
          ratingId: "",
        }
  );
   const { data: getValidateEmp } = useQuery(
    ["validateEmployeeAccess"],
    () => validateEmployeeAccess(parseInt(localStorage.getItem("employeeId")))
  );

  const getVData = getValidateEmp &&
     getValidateEmp?.map((i) => ({
          label: i?.elementCode,
          value: i?.empId,
        }))
      ;

      {console.log("getVData",getVData,getValidateEmp)}


  useEffect(() => {
    setPrevData((prevData) => ({
      ...prevData,
      PMFilter: filter,
    }));
  }, [filter]);
  const [filterName, setFilterName] = useState({});
  const onSearchHandler = (e, option) => {
    setCurrentPage(1);
    if (e || e === "") {
      switch (option) {
        case "empName":
          setFilter({ ...filter, empName: e?.value });
          break;
        case "empSkillId":
          setFilter({ ...filter, empSkillId: e?.value });
          break;

        case "empStatusId":
          setFilter({ ...filter, empStatusId: e?.value });
          break;
        case "ratingId":
          setFilter({ ...filter, ratingId: e?.value });
          break;
        default:
      }
    } else {
      setFilter({
        empName: "",
        empSkillId: "",
        empStatusId: "",
        ratingId: "",
      });
      setFilterName({
        empName: "",
        empSkillId: "",
        empStatusId: "",
        ratingId: "",
      });
    }
  };
  console.log("ppepee", filter);
  const { data, isLoading, isError } = useQuery(
    ["PartnerListData", { currentPage, filter }],
    () => PartnerListData(10, currentPage, filter)
  );

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const { data: PartnerData } = useQuery(["getPartnerName"], getPartnerName);
  PartnerData?.sort((a, b) =>
  a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const partnerOption = PartnerData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));


  const { data: SkillData } = useQuery(["getSKillName"], getSKillName);

  SkillData?.sort((a, b) =>
  a.specialization?.toUpperCase() > b.specialization?.toUpperCase() ? 1 : -1
  );

  const SkillDataOption = SkillData?.map((e) => ({
    value: e.specializationId,
    label: e.specialization,
  }));
  const dataRating = [
    {
      id: 1,
      Rating: "1",
    },
    {
      id: 2,
      Rating: "2",
    },
    {
      id: 3,
      Rating: "3",
    },
    {
      id: 4,
      Rating: "4",
    },
    {
      id: 5,
      Rating: "5",
    },
  ];

  const GetRatingOption = dataRating?.map((e) => ({
    value: e.id,
    label: e.Rating,
  }));

  const { data: GetStatusTypeData } = useQuery(
    ["GetStatusType"],
    GetStatusType
  );
  
  GetStatusTypeData?.sort((a, b) =>
  a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );
  const GetStatusTypeDataOption = GetStatusTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  

  return (
    <>
      <Container fluid>
        <div className={classes.partnertop}>
          <div className={classes.topBar}>
            <div className={classes.s1}>
              {filter?.empStatusId === "" ? (
                <span className={classes.cp_status}>
                  <img src={searchIcon} alt="" />
                </span>
              ) : (
                <span
                  className={classes.cp_status}
                  onClick={() => {
                    setFilter({
                      ...filter,
                      empStatusId: "",
                    });
                    setFilterName({ ...filterName, empStatusId: "" });
                  }}
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Status"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  filter?.empStatusId
                    ? GetStatusTypeDataOption?.find(
                        (e) => e?.value === filter?.empStatusId
                      )
                    : null
                }
                onChange={(e) => onSearchHandler(e, "empStatusId")}
                options={GetStatusTypeDataOption}
              />
            </div>
          </div>
          <div className={classes.topBarRating}>
            <div className={classes.s1}>
              {filter?.ratingId === "" ? (
                <span className={classes.cp_serchIcon1}>
                  <img src={searchIcon} alt="" />
                </span>
              ) : (
                <span
                  className={classes.cp_serchIcon1}
                  onClick={() => {
                    setFilter({
                      ...filter,
                      ratingId: "",
                    });
                    setFilterName({ ...filterName, ratingId: "" });
                  }}
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Rating"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  filter?.ratingId
                    ? GetRatingOption?.find(
                        (e) => e?.value === filter?.ratingId
                      )
                    : null
                }
                onChange={(e) => onSearchHandler(e, "ratingId")}
                options={GetRatingOption}
              />
            </div>
          </div>
          <div className={classes.topBarSKill}>
            <div className={classes.s1}>
              {filter?.empSkillId === "" ? (
                <span className={classes.cp_Skill}>
                  <img src={searchIcon} alt="" />
                </span>
              ) : (
                <span
                  className={classes.cp_Skill}
                  onClick={() => {
                    setFilter({
                      ...filter,
                      empSkillId: "",
                    });
                    setFilterName({ ...filterName, empSkillId: "" });
                  }}
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Skill Name"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  filter?.empSkillId
                    ? SkillDataOption?.find(
                        (e) => e?.value === filter?.empSkillId
                      )
                    : null
                }
                onChange={(e) => onSearchHandler(e, "empSkillId")}
                options={SkillDataOption}
              />
            </div>
          </div>
          <div className={classes.topBarName}>
            <div className={classes.sEmpName}>
              {filter?.empName === "" ? (
                <span className={classes.cp_EmpName}>
                  <img src={searchIcon} alt="" />
                </span>
              ) : (
                <span
                  className={classes.cp_EmpName}
                  onClick={() => {
                    setFilter({
                      ...filter,
                      empName: "",
                    });
                    setFilterName({ ...filterName, empName: "" });
                  }}
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Partner Name"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  filter?.empName
                    ? partnerOption?.find((e) => e?.value === filter?.empName)
                    : null
                }
                onChange={(e) => onSearchHandler(e, "empName")}
                options={partnerOption}
              />
            </div>
          </div>

           {getVData?.some((e) => {
             return e?.label === "329X7157D19173YX";
           }) && (<div className={classes.createPartner}>
            <button
              className={classes.buttonAdd}
              onClick={() => {
                setNextScreen("CreatePartner");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "CreatePartner",
                  page3: null,
                  page4: null,
                  active: "CreatePartner",
                }));
              }}
            >
              Create Partner
            </button>
          </div>)}

          
        </div>
        <Row>
          <Col md={12} style={{ marginTop: "8px" }}>
            <h6 className={classes.manage}>Partner List</h6>
          </Col>
          <Col md={12}>
            <InvoiceContainer>
              <div className={classes.table}>
                <div className={classes.th}>
                  <p className={classes.tableContent}>
                    Partner <br /> Code
                  </p>
                  <p className={classes.tableContent}>Partner Name</p>
                  <p className={classes.tableContent}>Location</p>
                  <p className={classes.tableContent}>Name</p>
                  <p className={classes.tableContent}>Email Id</p>
                  <p className={classes.tableContent}>
                    Current <br /> Rating
                  </p>
                  <p className={classes.tableContent}>Mobile Number</p>
                  <p className={classes.tableContent} style={{ width: "5rem" }}>
                    Status
                  </p>
                </div>
                <div>
                  {isLoading && (
                    <p className={classes.no_data}>Loading .....</p>
                  )}
                  {!isLoading && !isError ? (
                    data?.allPartners?.length > 0 ? (
                      typeof data?.allPartners !== "string" &&
                      data?.allPartners?.map((item, index) => {
                        return (
                          <div
                            className={classes.td}
                            key={item?.positionId}
                            style={{ position: "relative" }}
                          >
                            <p
                              className={`${classes.tableContent} ${classes.link}`}
                              onClick={() => {
                                setNextScreen("ViewPartner");
                                setPrevData((prevData) => ({
                                  ...prevData,
                                  page2: "ViewPartner",
                                  page3: null,
                                  page4: null,
                                  active: "ViewPartner",
                                  page1: "PartnerList",
                                  partnerId: item?.id,
                                }));
                              }}
                            >
                              {item?.partnerCode}
                            </p>
                            <CustomTooltip
                              value={item?.partnerName}
                              limit={15}
                              index={`partnerName${index}`}
                            />
                            <p className={classes.tableContent}>{item?.city}</p>
                            <CustomTooltip
                              value={item?.primaryContactName}
                              limit={10}
                              index={`primaryContactName${index}`}
                            />
                            <CustomTooltip
                              value={item?.primaryContectEmail}
                              limit={15}
                              index={index}
                              tooltipstyle={{ left: "55%" }}
                            />
                            <p className={classes.tableContent}>
                              {item?.currentRating}
                            </p>

                            <p className={classes.tableContent}>
                              {item?.primaryMobileNumber}
                            </p>
                            <p
                              className={classes.tableContent}
                              style={{ width: "5rem" }}
                            >
                              {item?.status}
                            </p>
                          </div>
                        );
                      })
                    ) : (
                      <p className={classes.no_data}>No Data Found</p>
                    )
                  ) : null}
                </div>
              </div>
            </InvoiceContainer>
          </Col>
        </Row>
      </Container>
      <div className={classes.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={data?.totalCount / 10}
          color="#C3B14B"
          color2="white"
        />
      </div>
    </>
  );
};

export default PartnerList;
