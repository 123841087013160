import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import { FaTrash } from "react-icons/fa";
import style from "./CreateExp.module.css";
import Select from "react-select";
import calender from "../../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { InvoiceStyles, InvoiceStylesVendorName } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import cancel from "../../../../../assets/cancell.png";
import axios from "axios";
import AddImg from "../../../../../assets/Icon ionic-md-add-circle.png";
import {
  getVendorName,
  getMonth,
  getExpenseCategory,
  getcopyExpense,
  createvendor,
  createcategory,
} from "../../../../../Utils/axios/expenceApi.js";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { Col, Modal, Row } from "react-bootstrap";
const CreateExpense = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [lineItemArray, setLineItemArray] = useState([1]);
  const [validate, setValidate] = React.useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const [VendorAdd, setVendorAdd] = useState();
  const [VendorAdd1, setVendorAdd1] = useState();
  const [formData, setFormData] = useState({
    copyOfExpense: "",
    vendor: "",
    igst: "",
    sgst: "",
    cgst: "",
    gst: "",
    baseAmount: "",
    totalBill: "",
    amountToBePaid: "",
    gstApplicable: true,
    category: "",
    paymentDueOn: "",
    dateOfExpense: "",
    lineItemDetails: [
      {
        candidateName: "",
        expenseMonth: "",
        expenseYear: "",
        billingRate: "",
        workingDays: "",
        maxWorkingDays: "",
        baseAmount: "",
      },
    ],
  });

  const { data: ExpenseDataById } = useQuery(
    ["getcopyExpense", formData?.vendor],
    () => getcopyExpense(formData?.vendor)
  );

  const [formData1, setFormData1] = useState({
    copyOfExpense: formData?.copyOfExpense,
    vendor: formData?.vendor,
    igst: ExpenseDataById?.igst,
    sgst: ExpenseDataById?.sgst,
    cgst: ExpenseDataById?.cgst,
    gst: ExpenseDataById?.gst,
    baseAmount: ExpenseDataById?.baseAmount,
    totalBill: ExpenseDataById?.totalBilled,
    amountToBePaid: ExpenseDataById?.amountToBePaid,
    gstApplicable: formData?.gstApplicable,
    invoice: ExpenseDataById?.invoice,
    title: ExpenseDataById?.title,
    dateOfExpense: ExpenseDataById?.dateOfExpense,
    paymentDueOn: ExpenseDataById?.paymentDueOn,
    month: ExpenseDataById?.expenseMonth,
    year: ExpenseDataById?.expenseYear,
    category: ExpenseDataById?.categoryName,
    paymentDueOn: ExpenseDataById?.paymentDueOn,
    dateOfExpense: ExpenseDataById?.dateOfExpense,
    lineItemDetails: ExpenseDataById?.lineItemDetails,
  });

  useEffect(() => {
    if (formData?.copyOfExpense === true) {
      setFormData1((prevValue) => ({
        ...prevValue,
        copyOfExpense: formData?.copyOfExpense,
        vendor: formData?.vendor,
        igst: ExpenseDataById?.igst,
        sgst: ExpenseDataById?.sgst,
        cgst: ExpenseDataById?.cgst,
        gst: ExpenseDataById?.gst,
        baseAmount: ExpenseDataById?.baseAmount,
        totalBill: ExpenseDataById?.totalBilled,
        amountToBePaid: ExpenseDataById?.amountToBePaid,
        gstApplicable: formData?.gstApplicable,
        invoice: ExpenseDataById?.invoice,
        title: ExpenseDataById?.title,
        dateOfExpense: ExpenseDataById?.dateOfExpense,
        paymentDueOn: ExpenseDataById?.paymentDueOn,
        month: ExpenseDataById?.expenseMonth,
        year: ExpenseDataById?.expenseYear,
        category: ExpenseDataById?.categoryName,
        paymentDueOn: ExpenseDataById?.paymentDueOn,
        dateOfExpense: ExpenseDataById?.dateOfExpense,
        lineItemDetails: ExpenseDataById?.lineItemDetails,
      }));
    } else {
    }
  }, [ExpenseDataById]);

  const { data: vendorData } = useQuery(["getVendorName"], getVendorName, {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 1000,
  });
  const venderOption = vendorData?.map((e) => ({
    value: e.id,
    label: e.vendorName,
  }));
  const { data: monthData } = useQuery(["getMonth"], getMonth);
  const monthOption = monthData?.map((e) => ({
    value: e.id,
    label: e.month,
  }));
  const { data: expenseCategoryData } = useQuery(
    ["getExpenseCategory"],
    getExpenseCategory,
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 1000,
    }
  );
  const expenseCategoryOption = expenseCategoryData?.map((e) => ({
    value: e.id,
    label: e.expenseCategory,
  }));

  const apiKey = process.env.REACT_APP_Expence_URL;

  const createExpenseApi = (formData) => {
    const url = `${apiKey}/createExpense`;
    axios
      .post(url, formData)
      .then((res) => {
        if (res.data) {
        }
      })
      .catch((err) => {});
  };

  const mutation = useMutation(createvendor, {});
  const mutation1 = useMutation(createcategory, {});

  const vendarValue = {
    companyName: VendorAdd,
    partnerName: "",
  };

  const vendarCategory = {
    categoryName: VendorAdd1,
  };
  const handleDelete = () => {
    setShow(false);
    mutation.mutate(vendarValue);
  };

  const handleDeleteCancle = () => {
    setShow(false);
  };

  const handleShow = (expenseId) => {
    setShow(true);
  };

  const handleCategory = () => {
    setShow1(false);
    mutation1.mutate(vendarCategory);
  };

  const handleCategoryCancle = () => {
    setShow1(false);
  };

  const handleShow1 = (expenseId) => {
    setShow1(true);
  };
  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
        />
      </div>
    );
  });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };
  const radioButton = () => {
    if (formData1?.gstApplicable) {
      if (
        formData1?.igst &&
        formData1?.sgst &&
        formData1?.cgst &&
        formData1?.gst
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const CreateInvoiceHandler = () => {
    if (
      formData?.vendor &&
      // formData?.igst &&
      // formData?.sgst &&
      // formData?.cgst &&
      formData?.totalBill &&
      formData?.baseAmount &&
      formData?.amountToBePaid &&
      formData?.paymentDueOn &&
      //  formData?.gst &&
      formData?.category &&
      formData?.invoice &&
      formData?.dateOfExpense &&
      formData?.month &&
      formData?.year &&
      formData?.title &&
      formData?.lineItemDetails?.length > 0
    ) {
      setValidate(false);
      createExpenseApi(formData);
      setNextScreen("expense");
    } else if (
      radioButton() &&
      formData1?.vendor &&
      formData1?.totalBill &&
      formData1?.baseAmount &&
      formData1?.amountToBePaid &&
      formData1?.paymentDueOn &&
      formData1?.category &&
      formData1?.invoice &&
      formData1?.dateOfExpense &&
      formData1?.month &&
      formData1?.year &&
      formData1?.title &&
      formData1?.lineItemDetails?.length > 0
    ) {
      setValidate(false);
      if (typeof formData1.vendor === "string") {
        const VendorId = venderOption.filter(
          (item) => formData1.vendor == item.label
        );
        formData1.vendor = VendorId[0].value;
      }
      if (typeof formData1.category === "string") {
        const CategoryId = expenseCategoryOption.filter(
          (item) => formData1.category == item.label
        );
        formData1.category = CategoryId[0].value;
      }
      createExpenseApi(formData1);
      setNextScreen("expense");
    } else {
      setValidate(true);
    }
  };

  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      {/*Copy Expence*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Copy Expense </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Expense?</div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="Yes"
                name="copyOfExpense"
                onChange={() =>
                  setFormData({ ...formData, copyOfExpense: true })
                }
                value={formData.copyOfExpense}
              />
              <label for="Yes">Yes</label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="no"
                name="copyOfExpense"
                onChange={() =>
                  setFormData({ ...formData, copyOfExpense: false })
                }
                value={formData.copyOfExpense}
              />
              <label for="no">No</label>
            </div>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        {/* <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>Vendor Name<span style={{ color: "red" }}>*</span></p>
            <Select
              styles={InvoiceStylesVendorName}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  vendor: e.value,
                  month: e.month
                });
              }}
              options={venderOption}
            />
            <p
              className={
                validate && !formData?.vendor
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Vendor Name
            </p>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div> */}

        <Row>
          <Col md={3} style={{ marginLeft: "26px" }}>
            <p className={style.label}>
              Vendor Name<span style={{ color: "red" }}>*</span>
            </p>
            <Select
              styles={InvoiceStylesVendorName}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  vendor: e.value,
                  month: e.month,
                });
              }}
              options={venderOption}
            />
            <p
              className={
                validate && !formData?.vendor
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Vendor Name
            </p>
          </Col>
          <Col md={3} style={{ marginTop: "36px" }}>
            <img
              src={AddImg}
              alt=""
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                handleShow();
              }}
            />
          </Col>
        </Row>
      </InvoiceContainer>
      {/*Expence Details*/}
      {formData.copyOfExpense === true ? (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Expense Details</div>
            {/*row 1*/}
            {/* <div className={style.Container}>
            <div className={style.col12}>
              <p className={style.label}>Category<span style={{ color: "red" }}>*</span></p>
              <Select
                styles={InvoiceStylesVendorName}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                value={expenseCategoryOption?.find(
                  (e) => {
                    return e.label === formData1?.category
                  }
                )}
                onChange={(e) => {
                  setFormData1({
                    ...formData1,
                    category: e.value,
                    // mandatorySkill_id: "",
                  });
                }}
                options={expenseCategoryOption}
              />
              <p
                className={
                  validate && !formData1?.category
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Category
              </p>
            </div>
            <div className={style.col2}></div>
            <div className={style.col3}></div>
            <div className={style.col4}></div>
          </div> */}

            <Row>
              <Col md={3} style={{ marginLeft: "26px" }}>
                <p className={style.label}>
                  Category<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStylesVendorName}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={expenseCategoryOption?.find((e) => {
                    return e.label === formData1?.category;
                  })}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      category: e.value,
                      // mandatorySkill_id: "",
                    });
                  }}
                  options={expenseCategoryOption}
                />
                <p
                  className={
                    validate && !formData1?.category
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Category
                </p>
              </Col>
              <Col md={3} style={{ marginTop: "36px" }}>
                <img
                  src={AddImg}
                  alt=""
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleShow1();
                  }}
                />
              </Col>
            </Row>
            {/*row 2*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Invoice No<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  value={formData1?.invoice}
                  className={style.bigInput}
                  onChange={(e) =>
                    setFormData1({ ...formData1, invoice: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData1?.invoice
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice No
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Date of Expense<span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData1?.dateOfExpense
                        ? moment(formData1?.dateOfExpense).toDate()
                        : null
                    }
                    value={formData1?.dateOfExpense}
                    onChange={(date) => {
                      setFormData1({
                        ...formData1,
                        dateOfExpense: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    // customInput={
                    //   formData1?.lastWorkingDay ? (
                    //     <CustomInputCross properties="lastWorkingDay" />
                    //   ) : (
                    //     <CustomInputClg />
                    //   )
                    // }
                    customInput={
                      formData1?.dateOfExpense ? (
                        <CustomInputCross properties="dateOfExpense" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData1?.dateOfExpense
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Date of Expense
                </p>
              </div>

              <div className={style.col3}>
                <p className={style.label}>
                  Expense Month<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={{
                    value: formData1?.value,
                    label: formData1?.month,
                  }}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      month: e.label,
                      // mandatorySkill_id: "",
                    });
                  }}
                  options={monthOption}
                />
                <p
                  className={
                    validate && !formData1?.month
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Expense Month
                </p>
              </div>
              <div className={style.col4}>
                <p className={style.label}>
                  Expense Year<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  value={formData1?.year}
                  className={style.bigInput}
                  onChange={(e) =>
                    setFormData1({ ...formData1, year: e?.target?.value })
                  }
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.year
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Expense Year
                </p>
              </div>
            </div>
            <div className={style.Container}>
              <div className={style.col212}>
                <p className={style.label}>
                  Title<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  value={formData1?.title}
                  className={style.bigInputTitle}
                  onChange={(e) =>
                    setFormData1({ ...formData1, title: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData1?.title
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Title
                </p>
              </div>
            </div>
            <hr></hr>
            <div className={style.Container}>
              <div className={style.col1}>
                <div className={style.heading}>GST Applicable</div>
                <div className={style.radioitem}>
                  <input
                    // value={formData1?.gstApplicable}
                    type="radio"
                    id="Yes1"
                    name="radio-group"
                    onChange={() =>
                      setFormData1({ ...formData1, gstApplicable: true })
                    }
                    checked={formData1.gstApplicable}
                  />
                  <label for="Yes1">Yes</label>
                </div>
                <div className={style.radioitem}>
                  <input
                    // value={formData1?.gstApplicable}
                    type="radio"
                    id="no1"
                    name="radio-group"
                    onChange={() =>
                      setFormData1({
                        ...formData1,
                        gstApplicable: false,
                        cgst: "",
                        igst: "",
                        sgst: "",
                        gst: "",
                      })
                    }
                  />
                  <label for="no1">No</label>
                </div>
              </div>
              <div className={style.col2}></div>
              <div className={style.col3}></div>
              <div className={style.col4}></div>
            </div>
            {/* {formData1.gstApplicable &&
            <div className={style.GstList}>
              <div className={style.col12}>
                <p className={style.label}>CGST<span style={{ color: "red" }}>*</span></p>
                <input
                  value={formData1?.cgst}
                  type="number"
                  className={style.GstInput}
                  onChange={(e) =>
                    setFormData1({ ...formData1, cgst: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData1?.cgst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write CGST
                </p>
              </div>
              <div className={style.col12}>
                <p className={style.label}>SGST<span style={{ color: "red" }}>*</span></p>
                <input
                  value={formData1?.sgst}
                  type="number"
                  className={style.GstInput}
                  onChange={(e) =>
                    setFormData1({ ...formData1, sgst: e?.target?.value }) 
                  }
                />
                <p
                  className={
                    validate && !formData1?.sgst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write SGST
                </p>
              </div>
              <div className={style.col12}>
                <p className={style.label}>IGST<span style={{ color: "red" }}>*</span></p>
                <input
                  value={formData1?.igst}
                  type="number"
                  className={style.GstInput}
                  onChange={(e) =>
                    setFormData1({ ...formData1, igst: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData1?.igst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write IGST
                </p>
              </div>
              <div className={style.col12}>
                <p className={style.label}>GST(TOTAL)<span style={{ color: "red" }}>*</span></p>
                <input
                  value={formData1?.gst}
                  type="number"
                  className={style.GstInput}
                  onChange={(e) =>
                    setFormData1({ ...formData1, gst: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData1?.gst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write GST
                </p>
              </div>
            </div>}
          <div className={style.GstList2}>
            <div className={style.col12}>
              <p className={style.label}>Base Amount<span style={{ color: "red" }}>*</span></p>
              <input
                value={formData1?.baseAmount}
                type="number"
                className={style.GstInput}
                onChange={(e) =>
                  setFormData1({ ...formData1, baseAmount: e?.target?.value })
                }
              />
              <p
                className={
                  validate && !formData1?.baseAmount
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please write IGST
              </p>
            </div>
            <div className={style.col12}>
              <p className={style.label}>Total Billed<span style={{ color: "red" }}>*</span></p>
              <input
                value={formData1?.totalBill}
                type="number"
                className={style.GstInput}
                onChange={(e) =>
                  setFormData1({ ...formData1, totalBill: e?.target?.value })
                }
              />
              <p
                className={
                  validate && !formData1?.totalBill
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please write Total Billed
              </p>
            </div>
            <div className={style.col12}>
              <p className={style.label}>Amount to be paid<span style={{ color: "red" }}>*</span></p>
              <input
                value={formData1?.amountToBePaid}
                type="number"
                className={style.GstInput}
                onChange={(e) =>
                  setFormData1({ ...formData1, amountToBePaid: e?.target?.value })
                }
              />
              <p
                className={
                  validate && !formData1?.amountToBePaid
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please write Amount to ne paid
              </p>
            </div>
            <div className={style.col2}>
              <p className={style.label}>Payment Due On<span style={{ color: "red" }}>*</span> </p>
              <div className={style.cu_field23Last}>
                <DatePicker
                  placeholder=" DD MM YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={
                    formData1?.paymentDueOn
                      ? moment(formData1?.paymentDueOn).toDate()
                      : null
                  }
                  value={formData1?.paymentDueOn}
                  onChange={(date) => {
                    setFormData1({
                      ...formData1,
                      paymentDueOn: moment(date).format(),
                    });
                  }}
                  dateFormat="dd MMM yyyy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  // customInput={
                  //   formData1?.lastWorkingDay ? (
                  //     <CustomInputCross properties="lastWorkingDay" />
                  //   ) : (
                  //     <CustomInputClg />
                  //   )
                  // }
                  customInput={
                    formData1?.paymentDueOn ? (
                      <CustomInputCross properties="paymentDueOn" />
                    ) : (
                      <CustomInputClg />
                    )
                  }
                />

              </div>
              <p
                className={
                  validate && !formData1?.paymentDueOn
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please write Payment Due On
              </p>
            </div>
          </div> */}
            {formData1.gstApplicable === true ? (
              <>
                <div className={style.GstList}>
                  <div className={style.col12}>
                    <p className={style.label}>
                      CGST<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.cgst}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({ ...formData1, cgst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.cgst
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write CGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      SGST<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.sgst}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({ ...formData1, sgst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.sgst
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write SGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      IGST<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.igst}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({ ...formData1, igst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.igst
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write IGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      GST(TOTAL)<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.gst}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({ ...formData1, gst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.gst
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write GST
                    </p>
                  </div>
                </div>
                <div className={style.GstList2}>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Base Amount<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.baseAmount}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          baseAmount: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.baseAmount
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write IGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Total Billed<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.totalBill}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          totalBill: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.totalBill
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Total Billed
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Amount to be paid<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.amountToBePaid}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          amountToBePaid: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.amountToBePaid
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Amount to ne paid
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>
                      Payment Due On<span style={{ color: "red" }}>*</span>{" "}
                    </p>
                    <div className={style.cu_field23Last}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={
                          formData1?.paymentDueOn
                            ? moment(formData1?.paymentDueOn).toDate()
                            : null
                        }
                        value={formData1?.paymentDueOn}
                        onChange={(date) => {
                          setFormData1({
                            ...formData1,
                            paymentDueOn: moment(date).format(),
                          });
                        }}
                        dateFormat="dd MMM yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        // customInput={
                        //   formData1?.lastWorkingDay ? (
                        //     <CustomInputCross properties="lastWorkingDay" />
                        //   ) : (
                        //     <CustomInputClg />
                        //   )
                        // }
                        customInput={
                          formData1?.paymentDueOn ? (
                            <CustomInputCross properties="paymentDueOn" />
                          ) : (
                            <CustomInputClg />
                          )
                        }
                      />
                    </div>
                    <p
                      className={
                        validate && !formData1?.paymentDueOn
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Payment Due On
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={style.GstList2}>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Base Amount<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.baseAmount}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          baseAmount: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.baseAmount
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write IGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Total Billed<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.totalBill}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          totalBill: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.totalBill
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Total Billed
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Amount to be paid<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.amountToBePaid}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          amountToBePaid: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData1?.amountToBePaid
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Amount to ne paid
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>
                      Payment Due On<span style={{ color: "red" }}>*</span>{" "}
                    </p>
                    <div className={style.cu_field23Last}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={
                          formData1?.paymentDueOn
                            ? moment(formData1?.paymentDueOn).toDate()
                            : null
                        }
                        value={formData1?.paymentDueOn}
                        onChange={(date) => {
                          setFormData1({
                            ...formData1,
                            paymentDueOn: moment(date).format(),
                          });
                        }}
                        dateFormat="dd MMM yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        customInput={
                          formData1?.paymentDueOn ? (
                            <CustomInputCross properties="paymentDueOn" />
                          ) : (
                            <CustomInputClg />
                          )
                        }
                      />
                    </div>
                    <p
                      className={
                        validate && !formData1?.paymentDueOn
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Payment Due On
                    </p>
                  </div>
                </div>
              </>
            )}
          </InvoiceContainer>

          {/* Line Item Details Container */}
          <InvoiceContainer>
            <div className={style.copy_expence}>Line Item Details</div>
            {/*row 1*/}
            {formData1?.lineItemDetails?.map((item, index) => {
              return (
                <>
                  <div className={style.Container} key={index}>
                    <div className={style.col12_item}>
                      <p className={style.label_item}>
                        Candidate Name<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData1?.lineItemDetails];
                          temp[index].candidateName = e?.target?.value;
                          setFormData1({ ...formData1, lineItemDetails: temp });
                        }}
                        value={formData1?.lineItemDetails[index]?.candidateName}
                      />
                      <p
                        className={
                          validate && !item?.candidateName
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Candidate Name
                      </p>
                    </div>
                    <div className={style.col2_item}>
                      <p className={style.label_item}>
                        Expense Month<span style={{ color: "red" }}>*</span>
                      </p>
                      <Select
                        styles={InvoiceStyles}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          let temp = [...formData1?.lineItemDetails];
                          temp[index].expenseMonth = e?.label;
                          setFormData1({
                            ...formData1,
                            lineItemDetails: temp,
                          });
                        }}
                        value={monthOption?.find(
                          (e) =>
                            e.label ===
                            formData1?.lineItemDetails[index].expenseMonth
                        )}
                        options={monthOption}
                      />
                      <p
                        className={
                          validate && !item?.expenseMonth
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Expense Month
                      </p>
                    </div>
                    <div className={style.col3_item}>
                      <p className={style.label_item}>
                        Expense Year<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData1?.lineItemDetails];
                          temp[index].expenseYear = e?.target?.value;
                          setFormData1({ ...formData1, lineItemDetails: temp });
                        }}
                        value={formData1?.lineItemDetails[index]?.expenseYear}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />

                      <p
                        className={
                          validate && !item?.expenseYear
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Expense Year
                      </p>
                    </div>
                    <div className={style.col4_item}>
                      <p className={style.label_item}>
                        Billing Rate<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData1?.lineItemDetails];
                          temp[index].billingRate = e?.target?.value;
                          setFormData1({ ...formData1, lineItemDetails: temp });
                        }}
                        value={formData1?.lineItemDetails[index]?.billingRate}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.billingRate
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Billing Rate
                      </p>
                    </div>
                    <div className={style.col5_item}>
                      <p className={style.label_item}>
                        Working Days<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData1?.lineItemDetails];
                          temp[index].workingDays = e?.target?.value;
                          setFormData1({ ...formData1, lineItemDetails: temp });
                        }}
                        value={formData1?.lineItemDetails[index]?.workingDays}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.workingDays
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Working Days
                      </p>
                    </div>
                    <div className={style.col6_item}>
                      <p className={style.label_item}>
                        Max Working Days<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData1?.lineItemDetails];
                          temp[index].maxWorkingDays = e?.target?.value;
                          setFormData1({ ...formData1, lineItemDetails: temp });
                        }}
                        value={
                          formData1?.lineItemDetails[index]?.maxWorkingDays
                        }
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.maxWorkingDays
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Max Working Days
                      </p>
                    </div>
                    <div className={style.col7_item}>
                      <p className={style.label_item}>
                        Base Amount<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData1?.lineItemDetails];
                          temp[index].baseAmount = e?.target?.value;
                          setFormData1({ ...formData1, lineItemDetails: temp });
                        }}
                        value={formData1?.lineItemDetails[index]?.baseAmount}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.baseAmount
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Base Amount
                      </p>
                    </div>
                    <div className={style.col8_item}>
                      {formData1?.lineItemDetails?.length > 1 && (
                        <>
                          <FaTrash
                            className={style.remove_icon}
                            size={20}
                            onClick={(e) => {
                              setLineItemArray(
                                lineItemArray.filter((_, i) => {
                                  return i !== index;
                                })
                              );
                              setFormData1({
                                ...formData1,
                                lineItemDetails:
                                  formData1?.lineItemDetails?.filter(
                                    (_, i) => i !== index
                                  ),
                              });
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}

            <div className={style.lastBtn_item}>
              <InvoiceButton
                onClick={() => {
                  setLineItemArray([
                    ...lineItemArray,
                    Math.floor(Math.random() * 100),
                  ]);
                  setFormData1({
                    ...formData1,
                    lineItemDetails: [
                      ...formData1?.lineItemDetails,
                      {
                        candidateName: "",
                        expenseMonth: "",
                        expenseYear: "",
                        billingRate: "",
                        workingDays: "",
                        maxWorkingDays: "",
                        baseAmount: "",
                      },
                    ],
                  });
                }}
              >
                Add
              </InvoiceButton>
            </div>
          </InvoiceContainer>
          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("expense");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "expense",
                }));
              }}
            >
              Cancel
            </InvoiceButton>

            <InvoiceButton onClick={CreateInvoiceHandler}>Create</InvoiceButton>
          </div>
        </>
      ) : (
        <>
          {" "}
          <InvoiceContainer>
            <div className={style.copy_expence}>Expense Details</div>
            {/*row 1*/}
            {/* <div className={style.Container}>
            <div className={style.col12}>
              <p className={style.label}>Category<span style={{ color: "red" }}>*</span></p>
              <Select
                styles={InvoiceStylesVendorName}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    category: e.value,
                  });
                }}
                options={expenseCategoryOption}
              />
              <p
                className={
                  validate && !formData?.category
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Category
              </p>
            </div>
           
          </div> */}

            <Row>
              <Col md={3} style={{ marginLeft: "26px" }}>
                <p className={style.label}>
                  Category<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStylesVendorName}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      category: e.value,
                    });
                  }}
                  options={expenseCategoryOption}
                />
                <p
                  className={
                    validate && !formData?.category
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Category
                </p>
              </Col>
              <Col md={3} style={{ marginTop: "36px" }}>
                <img
                  src={AddImg}
                  alt=""
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleShow1();
                  }}
                />
              </Col>
            </Row>

            {/*row 2*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Invoice No<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  className={style.bigInput}
                  onChange={(e) =>
                    setFormData({ ...formData, invoice: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData?.invoice
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice No
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Date of Expense<span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData?.dateOfExpense
                        ? moment(formData?.dateOfExpense).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        dateOfExpense: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    // customInput={
                    //   formData?.lastWorkingDay ? (
                    //     <CustomInputCross properties="lastWorkingDay" />
                    //   ) : (
                    //     <CustomInputClg />
                    //   )
                    // }
                    customInput={
                      formData?.dateOfExpense ? (
                        <CustomInputCross properties="dateOfExpense" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData?.dateOfExpense
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Date of Expense
                </p>
              </div>

              <div className={style.col3}>
                <p className={style.label}>
                  Expense Month<span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      month: e.label,
                      // mandatorySkill_id: "",
                    });
                  }}
                  options={monthOption}
                />
                <p
                  className={
                    validate && !formData?.month
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Expense Month
                </p>
              </div>
              <div className={style.col4}>
                <p className={style.label}>
                  Expense Year<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  className={style.bigInput}
                  onChange={(e) =>
                    setFormData({ ...formData, year: e?.target?.value })
                  }
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.year
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Expense Year
                </p>
              </div>
            </div>
            <div className={style.Container}>
              <div className={style.col212}>
                <p className={style.label}>
                  Title<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  className={style.bigInputTitle}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData?.title
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Title
                </p>
              </div>
            </div>
            <hr></hr>
            <div className={style.Container}>
              <div className={style.col1}>
                <div className={style.heading}>GST Applicable</div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="Yes1"
                    name="radio-group"
                    onChange={() =>
                      setFormData({ ...formData, gstApplicable: true })
                    }
                  />
                  <label for="Yes1">Yes</label>
                </div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="no1"
                    name="radio-group"
                    onChange={() =>
                      setFormData({
                        ...formData,
                        gstApplicable: false,
                        cgst: "",
                        igst: "",
                        sgst: "",
                        gst: "",
                      })
                    }
                  />
                  <label for="no1">No</label>
                </div>
              </div>
            </div>
            {/* {formData.gstApplicable &&
            <div className={style.GstList}>
              <div className={style.col12}>
                <p className={style.label}>CGST<span style={{ color: "red" }}>*</span></p>
                <input
                  type="number"
                  className={style.GstInput}
                  onChange={(e) =>
                    setFormData({ ...formData, cgst: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData?.cgst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write CGST
                </p>
              </div>
              <div className={style.col12}>
                <p className={style.label}>SGST<span style={{ color: "red" }}>*</span></p>
                <input
                  type="number"
                  className={style.GstInput}
                  onChange={(e) =>
                    setFormData({ ...formData, sgst: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData?.sgst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write SGST
                </p>
              </div>
              <div className={style.col12}>
                <p className={style.label}>IGST<span style={{ color: "red" }}>*</span></p>
                <input
                  type="number"
                  className={style.GstInput}
                  onChange={(e) =>
                    setFormData({ ...formData, igst: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData?.igst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please write IGST
                </p>
              </div>
              <div className={style.col12}>
                <p className={style.label}>GST(TOTAL)<span style={{ color: "red" }}>*</span></p>
                <input
                  type="number"
                  className={style.GstInput}
                  onChange={(e) =>
                    setFormData({ ...formData, gst: e?.target?.value })
                  }
                />
                <p
                  className={
                    validate && !formData?.gst
                      ? style.errorText
                      : style.errorTextHidden
                  } 
                >
                  Please write GST
                </p>
              </div>
            </div>}
          <div className={style.GstList2}>
            <div className={style.col12}>
              <p className={style.label}>Base Amount<span style={{ color: "red" }}>*</span></p>
              <input
                type="number"
                className={style.GstInput}
                onChange={(e) =>
                  setFormData({ ...formData, baseAmount: e?.target?.value })
                }
              />
              <p
                className={
                  validate && !formData?.baseAmount
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please write IGST
              </p>
            </div>
            <div className={style.col12}>
              <p className={style.label}>Total Billed<span style={{ color: "red" }}>*</span></p>
              <input
                type="number"
                className={style.GstInput}
                onChange={(e) =>
                  setFormData({ ...formData, totalBill: e?.target?.value })
                }
              />
              <p
                className={
                  validate && !formData?.totalBill
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please write Total Billed
              </p>
            </div>
            <div className={style.col12}>
              <p className={style.label}>Amount to be paid<span style={{ color: "red" }}>*</span></p>
              <input
                type="number"
                className={style.GstInput}
                onChange={(e) =>
                  setFormData({ ...formData, amountToBePaid: e?.target?.value })
                }
              />
              <p
                className={
                  validate && !formData?.amountToBePaid
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please write Amount to ne paid
              </p>
            </div>
            <div className={style.col2}>
              <p className={style.label}>Payment Due On<span style={{ color: "red" }}>*</span> </p>
              <div className={style.cu_field23Last}>
                <DatePicker
                  placeholder=" DD MM YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={
                    formData?.paymentDueOn
                      ? moment(formData?.paymentDueOn).toDate()
                      : null
                  }
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      paymentDueOn: moment(date).format(),
                    });
                  }}
                  dateFormat="dd MMM yyyy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  // customInput={
                  //   formData?.lastWorkingDay ? (
                  //     <CustomInputCross properties="lastWorkingDay" />
                  //   ) : (
                  //     <CustomInputClg />
                  //   )
                  // }
                  customInput={
                    formData?.paymentDueOn ? (
                      <CustomInputCross properties="paymentDueOn" />
                    ) : (
                      <CustomInputClg />
                    )
                  }
                />

              </div>
              <p
                className={
                  validate && !formData?.paymentDueOn
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please write Payment Due On
              </p>
            </div>
          </div> */}
            {formData.gstApplicable === true ? (
              <>
                <div className={style.GstList}>
                  <div className={style.col12}>
                    <p className={style.label}>
                      CGST<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.cgst}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({ ...formData, cgst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.cgst
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write CGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      SGST<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.sgst}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({ ...formData, sgst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.sgst
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write SGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      IGST<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.igst}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({ ...formData, igst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.igst
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write IGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      GST(TOTAL)<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.gst}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({ ...formData, gst: e?.target?.value })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.gst
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write GST
                    </p>
                  </div>
                </div>
                <div className={style.GstList2}>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Base Amount<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.baseAmount}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          baseAmount: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.baseAmount
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write IGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Total Billed<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.totalBill}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          totalBill: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.totalBill
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Total Billed
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Amount to be paid<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.amountToBePaid}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          amountToBePaid: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.amountToBePaid
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Amount to ne paid
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>
                      Payment Due On<span style={{ color: "red" }}>*</span>{" "}
                    </p>
                    <div className={style.cu_field23Last}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={
                          formData?.paymentDueOn
                            ? moment(formData?.paymentDueOn).toDate()
                            : null
                        }
                        value={formData?.paymentDueOn}
                        onChange={(date) => {
                          setFormData({
                            ...formData,
                            paymentDueOn: moment(date).format(),
                          });
                        }}
                        dateFormat="dd MMM yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        customInput={
                          formData?.paymentDueOn ? (
                            <CustomInputCross properties="paymentDueOn" />
                          ) : (
                            <CustomInputClg />
                          )
                        }
                      />
                    </div>
                    <p
                      className={
                        validate && !formData?.paymentDueOn
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Payment Due On
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={style.GstList2}>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Base Amount<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.baseAmount}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          baseAmount: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.baseAmount
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write IGST
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Total Billed<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.totalBill}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          totalBill: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.totalBill
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Total Billed
                    </p>
                  </div>
                  <div className={style.col12}>
                    <p className={style.label}>
                      Amount to be paid<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData?.amountToBePaid}
                      type="number"
                      className={style.GstInput}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          amountToBePaid: e?.target?.value,
                        })
                      }
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                    <p
                      className={
                        validate && !formData?.amountToBePaid
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Amount to ne paid
                    </p>
                  </div>
                  <div className={style.col2}>
                    <p className={style.label}>
                      Payment Due On<span style={{ color: "red" }}>*</span>{" "}
                    </p>
                    <div className={style.cu_field23Last}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        selected={
                          formData?.paymentDueOn
                            ? moment(formData?.paymentDueOn).toDate()
                            : null
                        }
                        value={formData?.paymentDueOn}
                        onChange={(date) => {
                          setFormData({
                            ...formData,
                            paymentDueOn: moment(date).format(),
                          });
                        }}
                        dateFormat="dd MMM yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        customInput={
                          formData?.paymentDueOn ? (
                            <CustomInputCross properties="paymentDueOn" />
                          ) : (
                            <CustomInputClg />
                          )
                        }
                      />
                    </div>
                    <p
                      className={
                        validate && !formData?.paymentDueOn
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please write Payment Due On
                    </p>
                  </div>
                </div>
              </>
            )}
          </InvoiceContainer>
          {/* Line Item Details Container */}
          <InvoiceContainer>
            <div className={style.copy_expence}>Line Item Details</div>
            {/*row 1*/}
            {formData?.lineItemDetails?.map((item, index) => {
              return (
                <>
                  <div className={style.Container} key={index}>
                    <div className={style.col12_item}>
                      <p className={style.label_item}>
                        Candidate Name<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.lineItemDetails];
                          temp[index].candidateName = e?.target?.value;
                          setFormData({ ...formData, lineItemDetails: temp });
                        }}
                        value={formData?.lineItemDetails[index]?.candidateName}
                      />
                      <p
                        className={
                          validate && !item?.candidateName
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Candidate Name
                      </p>
                    </div>
                    <div className={style.col2_item}>
                      <p className={style.label_item}>
                        Expense Month<span style={{ color: "red" }}>*</span>
                      </p>
                      <Select
                        styles={InvoiceStyles}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        onChange={(e) => {
                          let temp = [...formData?.lineItemDetails];
                          temp[index].expenseMonth = e?.label;
                          setFormData({
                            ...formData,
                            lineItemDetails: temp,
                          });
                        }}
                        value={monthOption?.find(
                          (e) =>
                            e.label ===
                            formData?.lineItemDetails[index].expenseMonth
                        )}
                        options={monthOption}
                      />
                      <p
                        className={
                          validate && !item?.expenseMonth
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Expense Month
                      </p>
                    </div>
                    <div className={style.col3_item}>
                      <p className={style.label_item}>
                        Expense Year<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.lineItemDetails];
                          temp[index].expenseYear = e?.target?.value;
                          setFormData({ ...formData, lineItemDetails: temp });
                        }}
                        value={formData?.lineItemDetails[index]?.expenseYear}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.expenseYear
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Expense Year
                      </p>
                    </div>
                    <div className={style.col4_item}>
                      <p className={style.label_item}>
                        Billing Rate<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.lineItemDetails];
                          temp[index].billingRate = e?.target?.value;
                          setFormData({ ...formData, lineItemDetails: temp });
                        }}
                        value={formData?.lineItemDetails[index]?.billingRate}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.billingRate
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Billing Rate
                      </p>
                    </div>
                    <div className={style.col5_item}>
                      <p className={style.label_item}>
                        Working Days<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.lineItemDetails];
                          temp[index].workingDays = e?.target?.value;
                          setFormData({ ...formData, lineItemDetails: temp });
                        }}
                        value={formData?.lineItemDetails[index]?.workingDays}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.workingDays
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Working Days
                      </p>
                    </div>
                    <div className={style.col6_item}>
                      <p className={style.label_item}>
                        Max Working Days<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.lineItemDetails];
                          temp[index].maxWorkingDays = e?.target?.value;
                          setFormData({ ...formData, lineItemDetails: temp });
                        }}
                        value={formData?.lineItemDetails[index]?.maxWorkingDays}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.maxWorkingDays
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Max Working Days
                      </p>
                    </div>
                    <div className={style.col7_item}>
                      <p className={style.label_item}>
                        Base Amount<span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="number"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.lineItemDetails];
                          temp[index].baseAmount = e?.target?.value;
                          setFormData({ ...formData, lineItemDetails: temp });
                        }}
                        value={formData?.lineItemDetails[index]?.baseAmount}
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) =>
                          (e.keyCode === 38 ||
                            e.keyCode === 40 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.preventDefault()
                        }
                        onKeyPress={(e) =>
                          ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                            e.which > 57 ||
                            e.key === "e" ||
                            e.key === "-") &&
                          e.prevent
                        }
                      />
                      <p
                        className={
                          validate && !item?.baseAmount
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please write Base Amount
                      </p>
                    </div>
                    <div className={style.col8_item}>
                      {formData?.lineItemDetails?.length > 1 && (
                        <>
                          <FaTrash
                            className={style.remove_icon}
                            size={20}
                            onClick={(e) => {
                              setLineItemArray(
                                lineItemArray.filter((_, i) => {
                                  return i !== index;
                                })
                              );
                              setFormData({
                                ...formData,
                                lineItemDetails:
                                  formData?.lineItemDetails?.filter(
                                    (_, i) => i !== index
                                  ),
                              });
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}

            <div className={style.lastBtn_item}>
              <InvoiceButton
                onClick={() => {
                  setLineItemArray([
                    ...lineItemArray,
                    Math.floor(Math.random() * 100),
                  ]);
                  setFormData({
                    ...formData,
                    lineItemDetails: [
                      ...formData?.lineItemDetails,
                      {
                        candidateName: "",
                        expenseMonth: "",
                        expenseYear: "",
                        billingRate: "",
                        workingDays: "",
                        maxWorkingDays: "",
                        baseAmount: "",
                      },
                    ],
                  });
                }}
              >
                Add
              </InvoiceButton>
            </div>
          </InvoiceContainer>
          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("expense");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "expense",
                }));
              }}
            >
              Cancel
            </InvoiceButton>

            <InvoiceButton onClick={CreateInvoiceHandler}>Create</InvoiceButton>
          </div>
        </>
      )}

      <Modal
        show={show}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to add Vendor Name ?
        </h6>
        <p className={style.label_item1}>
          Vendor Name <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={style.smallInput1}
          onChange={(e) => setVendorAdd(e.target.value)}
        />
        <button className={style.buttonAdd1} onClick={handleDelete}>
          Yes
        </button>
        <button className={style.buttonCancle} onClick={handleDeleteCancle}>
          No
        </button>
      </Modal>

      <Modal
        show={show1}
        centered
        classNames={{
          modal: "customModal",
        }}
      >
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to add Category ?
        </h6>
        <p className={style.label_item1}>
          Category <span style={{ color: "red" }}>*</span>
        </p>
        <input
          type="text"
          className={style.smallInput1}
          onChange={(e) => setVendorAdd1(e.target.value)}
        />
        <button className={style.buttonAdd1} onClick={handleCategory}>
          Yes
        </button>
        <button className={style.buttonCancle} onClick={handleCategoryCancle}>
          No
        </button>
      </Modal>
    </>
  );
};

export default CreateExpense;
