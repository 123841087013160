import * as XLSX from "xlsx";
import React from "react";
import { saveAs } from "file-saver";
import { FiDownload } from "react-icons/fi";
import { getAllUsersWithFilter } from "../../../../Utils/axios/umsApi";
import moment from "moment";
import { useQuery } from "react-query";
const ExcelFileDownload = ({ filter }) => {
  const { refetch } = useQuery(
    ["getAllUsersWithFilter1", { filter }],
    () => getAllUsersWithFilter(1, 1, filter),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (res) => {
        handleDownloadButtonClick(res?.employeeResponseModels);
      },
    }
  );
  const handleExperiece = (val) => {
    let year = 0;
    let month = 0;
    if (~~(val / 12)) {
      year = `${~~(val / 12)} Years`;
    } else {
      year = "0 Year";
    }
    if (~~(val % 12)) {
      month = `${~~(val % 12)} Months`;
    } else {
      month = "0 Months";
    }
    return `${year} ${month}`;
  };
  const ExitDicisionPlanDate = (decision) => {
    if (decision) {
      return moment(decision).format("DD MMM YY");
    } else return;
  };
  const ExitPlanDate = (value) => {
    if (value) {
      return moment(value).format("DD MMM YY");
    } else {
      return;
    }
  };
  const BondDate = (status, value) => {
    if (status) {
      return `${moment(value).format("DD MMM YY")} `;
    } else {
      return;
    }
  };
  const ReginationData = (status, value) => {
    if (status) {
      return `${moment(value).format("DD MMM YY")} `;
    } else {
      return;
    }
  };
  const ExitDate = (status, value) => {
    if (status) {
      return `${moment(value).format("DD MMM YY")} `;
    } else {
      return;
    }
  };
  const RetainDate = (status, value) => {
    if (status) {
      return `${moment(value).format("DD MMM YY")} `;
    } else {
      return;
    }
  };

  function generateExcelData(data) {
    const sheetName = "Sheet1";
    const sheetData = [
      [
        "Type",
        "Code",
        "Name",
        "Email",
        "Career Start Date",
        "Experience",
        "Relevant Experience",
        "Date of Joining",
        "Technology",
        "Additional Skill",
        "Salary Range",
        "Designation",
        "Functional Area",
        "Assigned Lead",
        "Assigned Client",
        "Exit Decision Date",
        "Exit Planned Date",
        "Resignation Date",
        "Exit Date",
        "Bond Date",
        "Retain Date",
        "Date of Birth",
        "Marriage Anniversary",
        "Canfirmation Date",
        "Period",
      ], // Header row
      ...data.map((val) => [
        val.type,
        val.code,
        val.name,
        val.email,
        moment(val.careerStartDate).format("DD MMM YY"),
        handleExperiece(val.totalExperience),
        handleExperiece(val.relevantExperience),
        moment(val.dateOfJoining).format("DD MMM YY"),
        val?.technology,
        val?.additionalSkill?.map((e) => e?.additionalSkillName).join(", "),
        val?.salaryRangeName,
        val?.employeeDesignation,
        val?.functionalAreaName,
        val?.assignedLead,
        val?.clientName,
        ExitDicisionPlanDate(val?.exitDecisionDate),
        ExitPlanDate(val?.exitPlanDate),
        ReginationData(val?.resignStatus, val?.resignationDate),
        ExitDate(!val?.active, val?.lastWorkingDayDate),
        BondDate(val.bond, val?.bondDate),
        RetainDate(val.retain, val?.retainDate),
        val?.dob ? moment(val?.dob).format("DD MMM YY") : "",
        val?.marriageAnniversary
          ? moment(val?.marriageAnniversary)?.format("DD MMM YY")
          : "",
        val?.confirmationDate
          ? moment(val?.confirmationDate)?.format("DD MMM YY")
          : "",
        val?.period,
      ]), // Data rows
    ];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    return workbook;
  }
  function downloadExcelFile(workbook) {
    const fileExtension = ".xlsx";
    const fileName = `Users${fileExtension}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(blob, fileName);
  }
  function handleDownloadButtonClick(data) {
    if (!data) {
      return;
    }
    const workbook = data && generateExcelData(data);
    downloadExcelFile(workbook);
  }
  return (
    <div>
      <FiDownload
        style={{ verticalAlign: "baseline" }}
        onClick={() => refetch()}
      />
    </div>
  );
};

export default ExcelFileDownload;
