import React, { useState, useEffect } from "react";
import style from "./myTasks.module.css";
import filterimg from "./../../../assets/filter-results-button.png";
import rubbishBinImg from "./../../../assets/rubbish-bin.png";
import pencilEditButtonImg from "./../../../assets/pencil-edit-button.png";
import Polygon from "../../../assets/Polygon.png";
import sortDownarrowImg from "./../../../assets/Down.png";
import sortUparrowImg from "./../../../assets/Up.png";
import Iconmetrocross from "../../../assets/Iconmetrocross.png";
import Iconionicmdcheckbox from "../../../assets/Iconionicmdcheckbox.png";
import Iconfeatherfilter from "../../../assets/Iconfeatherfilter.png";
import Iconionicmdclosecircle from "../../../assets/Iconionicmdclosecircle.png";
import milestone from "../../../assets/milestone.png";
import downarrow from "../../../assets/downarrow.png";
import Pagination from "../commonComponent/Pagination";
import Filter from "./component/Filter";
import { useHistory } from "react-router-dom";
import deleteTaskComment from "../taskAssignedByMe/component/deleteTaskComment";
import EditTask from '../taskAssignedByMe/component/EditTask';
import loaderImg from "../../../assets/loader-green2.gif";
import Moment from 'moment';
import axios from 'axios';
import cookie from "react-cookies";
import constants from "../../../Utils/constants";

const ary = [
  {
    a: "01",
    b: "Database Creation",
    c: "yyu ytujgj jhgj hjg ff",
    d: "Shailly Tyagi",
    e: "Assigned",
    f: "Low",
    g: "20 July(Fri)",
  },
];
const arr = [
  {
    a: "20 July(Fri)",
    b: "Shailly Tyagi",
    c: "Assigned",
    d: "Low",
  }
];

const MyTasks = (props) => {

  const [scroll, setScroll] = useState();
  const [tasks, setTasks] = useState([]);
  const [totalTasks, settotalTasks] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [applied, setApplied] = useState(false);
  const [data, setData] = useState()
  const [assigned, setAssigned] = useState([]);
  const [planned, setPlanned] = useState([]);
  const [planedEndDateOrder, setPlanedEndDateOrder] = useState(null);
  const [creationDateOrder, setCreationDateOrder] = useState(null);
  const [priority, setPriority] = useState([]);
  const [priorityOrder, setPriorityOrder] = useState(null);
  const [status, setStatus] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const[arrowEndDate,setArrowPalnEndDate]=useState(false)
  const[priorityArrow,setPriorityArrow]=useState(false)
  const[CreationArrow,setCreationArrow]=useState(true)
  const [allData, setAllData] = useState({
    assignedBy: assigned,
    creationDateOrder:creationDateOrder,
    planedEndDateOrder: planedEndDateOrder,
    priority: priority,
    priorityOrder: priorityOrder,
    status: status,
    functionalAreaId:[],
  
  });
  let isScroll = true;
  const limit = 10;
  const activePage = 1;
  const history = useHistory();


  const getaskAssignedByFilterData = () => {
    axios
      .get(url2 + "getaskAssignedToFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setData(res.data.responseData.employeeNames)
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const handleapidata = () => {
    setIsLoaded(true)
    const historyData = axios.post(url2 + '/myTask?limit=' + limit + '&page=' + currentPage, allData,
      {
        headers
          : {
          "Access-Control-Allow-Origin": "*",
          'Authorization': ` ${cookie.load('token')}`,
          employeeId: localStorage.getItem("employeeId")

        }
      })
      .then((res) => {
        setTimeout(() => {
          setIsLoaded(false);
        }, 250)
        if (res.data.statusCode == 0) {

          const responseData = res.data.responseData;
          setpageCount(responseData.totalNumberOfPagesAsPerGivenPageLimit);
          setTasks(responseData.tasks);
          settotalTasks(responseData.totalTask);
        }
      })
      .catch((err) => {
        console.log(err)
        return err;
      })
  }

  const geyEmployeeList = () => {
    const employeeData = axios.get(url2 + '/getMytaskFilterData',
      {
        headers
          : {
          "Access-Control-Allow-Origin": "*",
          'Authorization': ` ${cookie.load('token')}`,
          employeeId: localStorage.getItem("employeeId")

        }
      })
      .then((res) => {
        if (res.data.statusCode == 0) {
          setUserLists(res.data.responseData.employeeNames);
          console.log('response', res)
        }
      })
      .catch((err) => {
        console.log(err)
        return err;
      })
  }

  // const handleLoader = () => {
  //   if(isLoaded) {
  //     setTimeout(() => {
  //       setIsLoaded(false);
  //     }, 2000)
  //   }
  // }

  useEffect(() => {
    getaskAssignedByFilterData();
    handleapidata();
    geyEmployeeList();

  }, [currentPage,creationDateOrder, planned])

  const handlePriority = () => {
    setArrowPalnEndDate(false)
    setCreationArrow(false)
    setPriorityArrow(true)
    if (priorityOrder == "ASC") {
      setPriorityOrder("")
      setAllData((preValue) => {
        return ({
          ...preValue,
          priorityOrder: "DESC",
          planedEndDateOrder: null,
          creationDateOrder:null,
        })
      })
    }
    else if (priorityOrder == "DESC") {
      setPriorityOrder(null)
      setAllData((preValue) => {
        return ({
          ...preValue,
          // priorityOrder: null,
          creationDateOrder:null,
          planedEndDateOrder: null
        })
      })
    }
    else {
      setPriorityOrder("ASC")
      setAllData((preValue) => {
        return ({
          ...preValue,
          priorityOrder: "ASC",
          creationDateOrder:null,
          planedEndDateOrder: null
        })
      })
    }
  }

  const handlePlannedDate = () => {
    setPriorityArrow(false)
    setCreationArrow(false)
    setArrowPalnEndDate(true)
    if (planedEndDateOrder == "ASC") {
      setPlanedEndDateOrder("")
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          priorityOrder: null,
          creationDateOrder:null,
          planedEndDateOrder: "DESC",
        } : null)
      })
    }
    else if (planedEndDateOrder == "DESC") {
      setPlanedEndDateOrder(null)
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          // priorityOrder:null,
          planedEndDateOrder: null,
        } : null)
      })
    }
    else {
      setPlanedEndDateOrder("ASC");
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          priorityOrder: null,
          creationDateOrder:null,
          planedEndDateOrder: "ASC",
        } : null)
      })
    }
  }


  const handleCrationDate= () => {
    setPriorityArrow(false)
    setCreationArrow(true)
    setArrowPalnEndDate(false)
    if (creationDateOrder == "ASC") {
      setCreationDateOrder("")
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          priorityOrder: null,
          planedEndDateOrder: null,
          creationDateOrder: "DESC",
        } : null)
      })
    }
    else if (creationDateOrder == "DESC") {
      setCreationDateOrder(null)
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          // priorityOrder:null,
          creationDateOrder: null,
        } : null)
      })
    }
    else {
      setCreationDateOrder("ASC");
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          priorityOrder: null,
          planedEndDateOrder: null,
          creationDateOrder: "ASC",
        } : null)
      })
    }
  }

  const handleApplied = (arg) => {
    setCurrentPage(1)
    if (assigned.length !== 0 || priority.length !== 0 || status.length !== 0) {
      setApplied(true);
    }
    setShowFilter(false);
    handleapidata();
  };


  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const handleTask = () => {
    history.push("/taskTracker/createTask");
  };

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const closeFilter = () => {
    setAllData((preValue) => {
      return (preValue ? {
        ...preValue,
        assignedBy: [],
        priority: [],
        status: [],
        functionalAreaId:[]
      } : null)
    })
    setAssigned([]);
    setPriority([]);
    setStatus([]);
    setShowFilter(false);
    setApplied(false);
  };

  const handleView = (arg) => {
    history.push({
      pathname: '/MyTaskDetailsDetails',
      state: {
        task: arg,
        activePage: 1
      }
    });
  }

  useEffect(() => {
    var div = document.getElementById('div');
    var hs = div.scrollWidth > div.clientWidth;
    var vs = div.scrollHeight > div.clientHeight;
    console.log("scroll=", hs, vs)
    handleapidata();
    geyEmployeeList();
  }, [currentPage, allData])

  return (
    <>
      <div className={style.taskTracker}>
        <div className={style.header}>
          <div>
            <span>My Tasks</span>
            <p>
              <span>{tasks ? totalTasks : 0}</span>
            </p>
          </div>
          <button className={style.filter} onClick={handleFilter}>
            {applied && (
              <img
                className={style.appliedcheck}
                src={Iconionicmdcheckbox}
              />
            )}
            <img src={filterimg} />
            <span>Filter</span>
          </button>

          {showFilter && (
            <img
              className={style.polygon}
              src={Polygon}
            />
          )}
          <button onClick={handleTask} className={style.createTask}>
            <img
              style={{ width: "15px", marginRight: "10px" }}
              src={Iconmetrocross}
            />
            <span>Create Task</span>
          </button>

          <div className={style.mobileHeader}>
            <p>
              <img onClick={handleFilter} src={Iconfeatherfilter} />
            </p>
            <p style={{ backgroundColor: "#65A15E" }}>
              <img onClick={handleTask} src={Iconmetrocross} />
            </p>
          </div>
        </div>
        <div className={style.container} id="div"  onClick={() => {
              setShowFilter(false);
            }}>
          {isLoaded ? <div><img src={loaderImg} alt="loader" className={style.loader}
          />
            <p className={style.loaderPara}>Loading...</p>
          </div> : ""}
          {tasks.length > 0 && !isLoaded ?
            <div>
              <table >
                <thead style={{ position: 'sticky', top: '0px' }}>
                  <tr>
                    <th className={style.taskid} style={{ paddingLeft: '8px', position: 'sticky', top: '0px' }}>Task ID</th>

                    <th  onClick={() => handleCrationDate()} className={style.taskplanned} style={{ paddingLeft: '10px',cursor: "pointer", position: 'sticky', top: '0px' }}>Creation Date
                    {CreationArrow?<img style={{ cursor: "pointer", position: "relative", bottom: '0px', left: "5px" }} src={allData.creationDateOrder== "ASC" ? sortUparrowImg : sortDownarrowImg} />:null}
                    </th>
                    <th className={style.taskTitle} style={{ paddingLeft: '13px', position: 'sticky', top: '0px' }}>Task Title</th>
                    
                    <th className={style.taskAssigned} style={{ position: 'sticky', top: '0px', paddingLeft:"11px" }}>Assigned By</th>
                    <th className={style.taskStatus} style={{ paddingLeft: '18px', position: 'sticky', top: '0px' }}>Status</th>
                    <th     className={style.taskPriority} style={{ paddingLeft: '32px', position: 'sticky', top: '0px' }}>Priority
                   
                    </th>
                    <th className={style.taskplanned} style={{ paddingLeft: '50px', position: 'sticky', top: '0px' }} >Planned End Date
                    
                    </th>
                   
                    <div style={{ width: '14px', paddingLeft:"39px", fontWeight:"700" }}> Functional Area</div>
                  
                  </tr>
                </thead>
                </table>

              <div className={style.scrollAdd}>
                <table>
                  <tbody>
                    {tasks && tasks.map((task, index) => (
                      <tr className={style.tabledata} key={index}>
                        <td className={style.tabletaskId}>{task.taskId}</td>
                        <td className={style.planned} style={{paddingLeft:"16px"}}>{task.created}</td>
                        <td className={style.tabletaskTitle} onClick={() => handleView(task)}><b>{task.title}</b></td>
                        {/* <td className={style.desc} >{task.description}</td> */}
                        <td className={style.name} style={{paddingLeft:"6px"}}>{task.name}</td>
                        <td className={task.status == "Assigned" ? style.asigned_color : (task.status == "Done" ? style.done_color : (task.status == "Onhold" ? style.onhold_color : style.Closed_color))} style={{ width: '10%', paddingLeft:"10px" }}>{(task.status === "Inprogress" ? "In Progress" : task.status === "Onhold" ? "On Hold" : task.status)}</td>
                        <td className={style.tabtaskpriority} style={{paddingLeft:"11px"}}>{task.priority}</td>
                        <td className={style.planned} style={{paddingLeft:"5px"}}>{Moment(task.planedEndDate).format("DD MMM (ddd)")}</td>
                        <td className={style.tabtaskpriority} style={{paddingLeft:"0px"}} >{task.functionalArea}</td>
                      </tr>
                    ))}
                  </tbody>
                  {/* </div> */}
                </table></div>
            </div>
            : <div className={style.noTasks}>
              {!isLoaded ?
                <div style={{ display: 'block' }}>
                  <img src={milestone} alt="No data found" />
                  <h2>No Task Assigned Yet</h2>
                </div> : ""}
            </div>}

          <div className={style.mobileContainer}>

            {arr
              .map((ar, indexa) => {
                return (
                  <div key={indexa}
                    style={{
                      display: "block",
                      borderBottom: "1px solid #0000001A",
                      marginBottom: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <strong>{indexa + 1}. Database Creation</strong>
                    <p>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          paddingTop: "3px",
                        }}
                      >
                        Assigned By :
                      </span>
                      <span style={{ fontSize: "16px", opacity: ".7" }}>
                        {" " + ar.b}
                      </span>
                    </p>
                    <p>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          paddingTop: "3px",
                        }}
                      >
                        Status :
                      </span>
                      <span
                        style={{
                          color: "#FCB902",
                          fontSize: "16px",
                          fontWeight: "300",
                        }}
                      >
                        {" " + ar.c}
                      </span>
                    </p>
                    <p>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          paddingTop: "3px",
                        }}
                      >
                        Priority :
                      </span>
                      <span style={{ fontSize: "16px", opacity: ".7" }}>
                        {" " + ar.d}
                      </span>
                    </p>
                    <p>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          paddingTop: "3px",
                        }}
                      >
                        Planned End Date :
                      </span>
                      <span style={{ fontSize: "16px", opacity: ".7" }}>
                        {" " + ar.a}
                      </span>
                    </p>
                  </div>
                );
              })}
          </div>
        </div>

        {/* <div className={style.responsivearrow}>
          <img src={downarrow} style={{ transform: "rotateZ(90deg)" }} />
          <img src={downarrow} style={{ transform: "rotateZ(-90deg)" }} />
        </div> */}
        {totalTasks > limit && pageCount > 0 && <div className={style.paginationmobileContainer} style={{ marginRight: "1.3rem", marginTop: "20px" }}>
          <Pagination
            pagesCount={pageCount}
            pageSize={limit}
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>}
      </div>

      {showFilter && (
        <Filter closeFilter={closeFilter} handleApplied={handleApplied} userLists={userLists} filterData={allData}
          setassigned={setAssigned} setpriority={setPriority} setstatus={setStatus} data={data} />
      )}
    </>
  );
};
export default MyTasks;