import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Header from "../../../taskTracker/commonComponent/Header";
import style from "./EditPollResources.module.css";
import Sidebar from "../../Clients/component/SideBar";
import cookie from "react-cookies";
import DatePicker from "react-datepicker";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import constants from "../../../../Utils/constants";
import { AiOutlineClose } from "react-icons/ai";
import calendar from "../../../../assets/Grievance portal/dateldpi.png";
import cancel from "../../../../assets/cancell.png";
import Moment from "moment";
import CustomAutoComplete from "../../../taskTracker/commonComponent/AutoComplete";

const EditPoolResource = () => {
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{ fontSize: "15px", fontWeight: 500, marginTop: "5px" }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      
      </div>
      
    );

  });
  const poolEndDateHandle =()=>{
    setState({
      ...state, poolEndDate:null
    })
  }
  const CustomInput1 = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{ fontSize: "15px", fontWeight: 500, marginTop: "5px" }}
        >
          {props.value || props.placeholder}   {state?.poolEndDate?
          
          <img src={cancel} style={{cursor:"pointer", height:"12px", paddingLeft:"12px"}} onClick={poolEndDateHandle}/>
          :null
         
          }
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      
      </div>
      
    );
  });
  const [required, setRequired] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const history = useHistory();
  const [state, setState] = useState([]);

  const [clientData, setClientData] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [arrayObj, setarrayObj] = useState([]);
  const [employeeId, setEmployeeId] = useState();
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(false);
  const[validate,setValidate]=useState(false)
  const[changeRequestById,setChangeRequestedById]=useState(null)
  const[changeRequestByName,setChangeRequestByName]=useState("")

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  
  employeeList?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const query = useQuery();
  const id = query.get("id");
  const baseUrl = constants.apiBaseUrlResource;

  const url = constants.apiBaseUrl;

  const onSubmit = (e) => {
    e.preventDefault();
    if(changeRequestByName?.trim().length==0){
      setValidate(true)
      return false;
    }
    if (!state?.candidateAvailablityDate || state?.poolEndDate) {
      setError(true);
    }
    const data = {
      candidateAvailablityDate: Moment(
        state?.candidateAvailablityDate
      ).format(),
      poolEndDate:state?.poolEndDate? Moment(state?.poolEndDate).format():null,
      empId: employeeId,
      poolStartDate: Moment(state?.poolStartdate).format(),
      skill: state?.skill,
      changeRequestById:changeRequestById,
      id: id,
    };

    axios
      .put(baseUrl + "/editPoolResource", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus.statusCode == 1) {
          notify1();
        } else {
          notify();
          handleRedirect();
          setDisable(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPoolDetails = (id) => {
    axios
      .get(baseUrl + `/getPoolResourceDetaislByID?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setEmployeeName(res.data.responseData.empName);
        setEmployeeId(res.data.responseData.empid);
        setState(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployeeList = () => {
    axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setEmployeeList(res.data.responseData.employeeList);
        setarrayObj(
          employeeList.map((item) => {
            return {
              label: item.name,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const getSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setSkillList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectSkill = () => {
    axios
      .get(baseUrl + "/getProjectforFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setProjectList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClientData();
    getProjectSkill();
    getEmployeeList();
    getPoolDetails(id);
    getSkills();
  }, []);

  function handleChange(id) {
    setEmployeeId(id);
  }

  function handleChange1(id) {
    setChangeRequestedById(id);
  }

  const onInputChange = (e) => {
    if (e.target.name === "projectSkill") {
      setState({
        ...state,
        skill: e.target.value,
      });
    } else if (e.target.name === "clientName") {
      console.log(e.target.value);

      setState({
        ...state,
        clientId: e.target.value,
      });
    } else if (e.target.name === "startDate") {
      setState({
        ...state,
        allocationdate: e.target.value,
      });
    } else if (e.target.name === "endDate") {
      setState({
        ...state,
        deallocationdate: e.target.value,
      });
    }
  };
  console.log({ state });
  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/PoolResources");
    }, 2000);
  };

  const getClientData = () => {
    axios
      .get(baseUrl + "/getClientFilterData?Status=Active", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setClientData(res.data.responseData.clientFilterDataResponse);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const notify = () =>
    toast.success("Data Submitted Successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notify1 = () =>
    toast.error("Employee is already allocated", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const EndDateChange = (date) => {
    setState({
      ...state,
      deallocationdate: date,
    });
  };

  const StartDateChange = (date) => {
    setState({
      ...state,
      allocationdate: Moment(date).format("D MMM YY"),
    });
  };

  const allocation = [
    { name: "25%", allocationPercent: "OneFourth" },
    { name: "50%", allocationPercent: "Half" },
    { name: "75%", allocationPercent: "ThreeFourth" },
    { name: "100%", allocationPercent: "Full" },
  ];

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <Sidebar />

        <form className={style.table_div} onSubmit={onSubmit}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ marginTop: "22px" }}
            ></div>
            <div style={{ marginTop: "22px", fontWeight: "700" }}>
              Edit Pool Resource
            </div>
            <div className={style.form_div}>
              <form id="create_task_id" className={style.gridContainer}>
                <div className={style.Cu_row1}>
                  <div className={style.cu_col1}>
                    <p
                      style={{ paddingBottom: "8px" }}
                      className={style.cu_label}
                    >
                      Employee Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <CustomAutoComplete
                      className={style.cu_field2}
                      placeholder="Search by Employee Name"
                      autocomplete="100%"
                      required="true"
                      dataList={employeeList}
                      style={{ marginLeft: "5px" }}
                      onSelect={(value) => {
                        setEmployeeName(value.name);
                        handleChange(value.id);
                      }}
                      onChange={(e) => setEmployeeName(e.target.value)}
                      value={employeeName}
                      disabled={true}
                    />
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      Technology<span style={{ color: "red" }}>*</span>
                    </p>
                    <select
                      onChange={onInputChange}
                      name="projectSkill"
                      className={style.cu_field2}
                    >
                      <option hidden>{state?.skillName}</option>
                      {skillList.map((val) => {
                        return <option value={val.id}>{val?.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>
                      Pool Start Date<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        // minDate={new Date()}
                        // selected={startDate}
                        startDate={null}
                        value={Moment(state?.poolStartdate).format("D MMM YY")}
                        onChange={(date) =>
                          setState({
                            ...state,
                            poolStartdate: date,
                          })
                        }
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "10px" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Pool End Date</p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        // minDate={new Date()}
                        // selected={startDate}
                        startDate={null}
                        value={
                          state?.poolEndDate
                            ? Moment(state?.poolEndDate).format("D MMM YY")
                            : null
                        }
                        onChange={(date) =>
                          setState({
                            ...state,
                            poolEndDate: date,
                          })
                        }
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput1 />}
                      /> 
                    </div>
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      Candidate Availablity Date{" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        minDate={state.poolStartdate}
                        // selected={endDate}
                        // startDate={null}
                        value={
                          state?.candidateAvailablityDate
                            ? Moment(state?.candidateAvailablityDate).format(
                                "D MMM YY"
                              )
                            : null
                        }
                        onChange={(date) =>
                          setState({
                            ...state,
                            candidateAvailablityDate: date,
                          })
                        }
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                    {error && !state?.candidateAvailablityDate ? (
                      <p className={style.error}>
                        Please Select Candidate Availablity Date
                      </p>
                    ) : null}
                  </div>
                  <div className={style.cu_col3}>
                  <p
                      style={{ paddingBottom: "8px" }}
                      className={style.cu_label}
                    >
                      Change Requested By<span style={{ color: "red" }}>*</span>
                    </p>
                    <CustomAutoComplete
                      className={style.cu_field2}
                      autocomplete="100%"
                      placeholder="Search by Employee Name"
                      dataList={employeeList}
                      style={{
                        marginLeft: "5px",
                        fontWeight: 800,
                        height: "35px",
                      }}
                      onSelect={(value) => {
                        setChangeRequestByName(value.name);
                        handleChange1(value.id);
                        // getResourceAloocation(value.id);
                      }}
                      onChange={(e) => setChangeRequestByName(e.target.value)}
                      value={changeRequestByName}
                    />
                      {validate && changeRequestByName?.trim().length==0 ? (
                      <p className={style.error}>Please Select Change Requested By</p>
                    ) : null} 
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={style.btn_div}>
            <button
              onClick={onSubmit}
              className={style.submitbtn}
              disabled={disable}
            >
              Update
            </button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </>
  );
};

export default EditPoolResource;
