import React from "react";
import Header from "../../../commonComponent/Header";
import SideBar from "../../../commonComponent/SideBar";
import AddLaptopComponent from "./component/AddLaptopComponent";
import style from "./../laptop.module.css";

const AddMobile = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <AddLaptopComponent />
      </div>
    </div>
  );
};

export default AddMobile;
