import React from "react";

import Header from "../commonComponent/Header";
import SideBar from "../commonComponent/SideBar";
import TabletComponent from "./component/TabletComponent";
import style from "./tablet.module.css";

const Tablet = () => {
  return (
    <div>
      <Header />

      <div className={style.mainSection}>
        <SideBar />

       <TabletComponent/>
       
      </div>
    </div>
  );
};

export default Tablet;
