import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { queryAPI } from "../api";

const initialState = { queries: [], status: "idle", error: null };

export const fetchAllQueries = createAsyncThunk("query/fetchAllQueries", async (queryConfig) => {
    const response = await queryAPI.viewAllQueries(queryConfig.isAdmin);
    return response.data.rData;
});

export const fetchQueries = createAsyncThunk("query/fetchSentQueries", async (queryConfig) => {
    const response_sentQueries = await queryAPI.viewSentQueries(queryConfig.userId);
    const response_receivedQueries = await queryAPI.viewReceivedQueries(queryConfig.userId);
    return response_sentQueries.data.data.concat(response_receivedQueries.data.rData);
});

export const addQuery = createAsyncThunk("query/addQuery", async (queryConfig) => {
    const response = await queryAPI.addQuery(queryConfig.userId, queryConfig.queryData);
    return response.data.data;

});
export const viewReceivedQueries = createAsyncThunk("query/viewReceivedQueries", async (queryConfig) => {
    console.log("queryConfig",queryConfig.queryData.email)
    const response = await queryAPI.viewReceivedQueries( queryConfig.queryData.email);
    console.log("response",response)
    return response.data.data;

});
export const updateQuery = createAsyncThunk("query/editReceivedQuery", async (queryConfig) => {
    const response = await queryAPI.editSentQuery( queryConfig.queryData);
    return response.data.data;
});

export const deleteQuery = createAsyncThunk("query/deleteQuery", async (queryConfig) => {
    const response = await queryAPI.deleteSentQuery(queryConfig.userId, queryConfig.queryData);
    return response.data.data;
});

export const querySlice = createSlice({
    name: "query",
    initialState,
    extraReducers: {
        [fetchAllQueries.pending]: (state, action) => {
            state.status = "loading";
        },
        [fetchAllQueries.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.queries = action.payload;
        },
        [fetchAllQueries.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [fetchQueries.pending]: (state, action) => {
            state.status = "loading";
        },
        [fetchQueries.fulfilled]: (state, action) => {
            state.status = "succeeded";
            state.queries = state.queries.concat(action.payload);
        },
        [fetchQueries.rejected]: (state, action) => {
            state.status = "failed";
            state.error = action.error.message;
        },
        [addQuery.fulfilled]: (state, action) => {
            state.queries.push(action.payload);
        },
        [updateQuery.fulfilled]: (state, action) => {
            const index = state.queries.findIndex((query) => query._id === action.payload._id);
            state.queries[index] = action.payload;
        },
        [deleteQuery.fulfilled]: (state, action) => {
            const index = state.queries.findIndex((query) => query._id === action.payload._id);
            state.queries.splice(index, 1);
        },
    },
});

export const selectAllQueries = (state) => state.query.queries;
export const selectSentQueries = (state, requestorId) => state.query.queries.filter((query) => query.requestorId === requestorId);
// export const selectReceivedQueries = (state, assigneeId) => state.query.filter((query) => query.assigneeId === assigneeId);
export const selectQueryById = (state, _id) => state.query.queries.find((query) => query._id === _id);

export default querySlice.reducer;
