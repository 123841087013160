import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "../TeamTimeSheet/TeamTimeSheet.module.css";
import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import PurpleIcon from "../../../../assets/PurpleIcon.png";
import cancel from "../../../../assets/cancell.png";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import {
  TeamTimeSheetData,
  getEmployeeList,
} from "../../../../Utils/axios/NewTimeSheetApi";
import CustomTooltip from "../../../NewEmployeeTimeSheet/customTooltip/CustomTooltip.js";
import Pagination from "../../../NewEmployeeTimeSheet/CommonComponent/Pagination/Pagination.js";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import { useQuery } from "react-query";
import searchIcon from "../../../../assets/Icon-search.png";

import { customStylesERP } from "../../utils/style";
const TeamTimeSheet = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const currentDate = new Date();
  const timesheetDate = currentDate.toISOString().replace("Z", "+05:30");
  const formattedDate = timesheetDate.substring(0, 10);
  const [filterName, setFilterName] = useState({});

  const [filter, setFilter] = useState({
    coreSkill: null,
    positionCode: null,
    projectName: null,
    createdBy: [],
    status: [1],
  });

  const [formData, setFormData] = useState({
    timesheetDate: formattedDate,
  });

  console.log({ currentPage });
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const dataItem = filter?.positionCode === null ? null : filter?.positionCode;
  const regex = /(.+)\((\d+)\)/;
  const result = dataItem === null ? null : dataItem.match(regex);
  const name = result === null ? "" : result[1].trim();

  const { data, isLoading, isError,} = useQuery(
    ["TeamTimeSheetData", { currentPage, name ,formData}],
    () =>
      TeamTimeSheetData(
        formData?.timesheetDate,
        Number(localStorage.getItem("employeeId")),
        10,
        currentPage,
        name
      ),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: getEmpList } = useQuery(["getEmployeeList"], getEmployeeList);

  const getEmployeeListOption2 =
    getEmpList?.length > 1
      ? getEmpList?.map((i) => ({
          value: i?.id,
          label1: i?.name,
          label: `${i?.name} (${i?.employeeCode})`,
        }))
      : [];

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          alt=""
        />
      </div>
    );
  });

  const dates = [];
  const dateString = formData?.timesheetDate;
  const formatString = "YYYY-MM-DD";
  const today = moment(dateString, formatString);
  for (let i = 0; i < 7; i++) {
    const date = today.clone().subtract(i, "days");
    dates.push(date.format("YYYY-MM-DD"));
  }
  function formatDate(dateString) {
    const date = moment(dateString, "YYYY-MM-DD");
    const formattedDate = date.format("DD MMM YY");
    return formattedDate;
  }

  const currentDate1 = formatDate(dates[0]);
  const date = new Date(currentDate1);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const outputDateString = `${year}-${month}-${day}`;

  const currentDate2 = formatDate(dates[1]);
  const date1 = new Date(currentDate2);
  const year1 = date1.getFullYear();
  const month1 = String(date1.getMonth() + 1).padStart(2, "0");
  const day1 = String(date1.getDate()).padStart(2, "0");

  const outputDateString1 = `${year1}-${month1}-${day1}`;

  const currentDate3 = formatDate(dates[2]);
  const date2 = new Date(currentDate3);
  const year2 = date2.getFullYear();
  const month2 = String(date2.getMonth() + 1).padStart(2, "0");
  const day2 = String(date2.getDate()).padStart(2, "0");

  const outputDateString2 = `${year2}-${month2}-${day2}`;

  const currentDate4 = formatDate(dates[3]);
  const date3 = new Date(currentDate4);
  const year3 = date3.getFullYear();
  const month3 = String(date3.getMonth() + 1).padStart(2, "0");
  const day3 = String(date3.getDate()).padStart(2, "0");

  const outputDateString3 = `${year3}-${month3}-${day3}`;
  const currentDate5 = formatDate(dates[4]);
  const date4 = new Date(currentDate5);
  const year4 = date4.getFullYear();
  const month4 = String(date4.getMonth() + 1).padStart(2, "0");
  const day4 = String(date4.getDate()).padStart(2, "0");

  const outputDateString4 = `${year4}-${month4}-${day4}`;

  const currentDate6 = formatDate(dates[5]);
  const date5 = new Date(currentDate6);
  const year5 = date5.getFullYear();
  const month5 = String(date5.getMonth() + 1).padStart(2, "0");
  const day5 = String(date5.getDate()).padStart(2, "0");

  const outputDateString5 = `${year5}-${month5}-${day5}`;

  const currentDate7 = formatDate(dates[6]);
  const date6 = new Date(currentDate7);
  const year6 = date6.getFullYear();
  const month6 = String(date6.getMonth() + 1).padStart(2, "0");
  const day6 = String(date6.getDate()).padStart(2, "0");

  const outputDateString6 = `${year6}-${month6}-${day6}`;

  const onSearchHandler = (e, option) => {
    setCurrentPage(1);
    if (e || e === null) {
      switch (option) {
        case "Code":
          setFilter({ ...filter, positionCode: e?.label });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "ProjectName":
          setFilter({ ...filter, projectName: e?.value });
          setFilterName({ ...filterName, projectName: e?.label });
          break;

        default:
      }
    } else {
      setFilter({
        coreSkill: null,
        positionCode: null,
        positionOwner: null,
        status: null,
        projectName: null,
      });
      setFilterName({});
    }
  };

  return (
    <>
      <Container>
        <Row style={{ marginTop: "20px" }}>
          <Col md={8}>
            <span className={style.TeamSheet}>Team Timesheet</span>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={9}>
            <div className={style.cu_field23Last}>
              <DatePicker
                placeholder=" DD MM YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  formData?.timesheetDate
                    ? moment(formData?.timesheetDate).toDate()
                    : null
                }
                maxDate={moment().toDate()}
                onChange={(date) => {
                  const dateval = date.toISOString().replace("Z", "+05:30");
                  const date1 = new Date(dateval);
                  date1.setDate(date1.getDate() + 1);
                  const formattedDate = date1
                    .toISOString()
                    .replace("Z", "+05:30");
                  const dateData = formattedDate.substring(0, 10);
                  setFormData({
                    ...formData,
                    timesheetDate: dateData,
                  });
                }}
                dateFormat="dd MMM yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={
                  formData?.timesheetDate ? (
                    <CustomInputClg />
                  ) : (
                    <CustomInputCross properties="timesheetDate" />
                  )
                }
              />
            </div>
          </Col>
          <Col md={3} style={{paddingRight:"29px"}}>
            <div className={style.topBar}>
              <div className={style.s1}>
                {filter?.positionCode === null ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt=""  />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        positionCode: null,
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose/>
                  </span>
                )}
                <Select
                  styles={customStylesERP}
                  classNamePrefix={"create_position"}
                  placeholder="Employee Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    filterName?.positionCode
                      ? {
                          value: filter?.positionCode,
                          label: filterName?.positionCode,
                        }
                      : 0
                  }
                  onChange={(e) => onSearchHandler(e, "Code")}
                  options={getEmployeeListOption2}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <InvoiceContainer>
        <div className={style.table}>
          <div className={style.th}>
            <p className={style.tableContent}>Name</p>
            <p className={style.tableContent}>{formatDate(dates[0])}</p>
            <p className={style.tableContent}>{formatDate(dates[1])}</p>
            <p className={style.tableContent}>{formatDate(dates[2])}</p>
            <p className={style.tableContent}>{formatDate(dates[3])}</p>
            <p className={style.tableContent}>{formatDate(dates[4])}</p>
            <p className={style.tableContent}>{formatDate(dates[5])}</p>
            <p className={style.tableContent}>{formatDate(dates[6])}</p>
          </div>
          <div>
            {isLoading && <p className={style.no_data}>Loading .....</p>}

            {!isLoading && !isError ? (
              data?.teamTimesheetResponseList?.length > 0 ? (
                typeof data !== "string" &&
                data?.teamTimesheetResponseList?.map((item, index) => {
                  return (
                    <div className={style.td} key={item?.positionId}>
                      <CustomTooltip
                        value={item?.name}
                        limit={15}
                        index={index}
                        tooltipstyle={{ left: "31%" }}
                        className={style.tableContent}
                      />

                      {item?.dailyStatus[0] === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString,
                              name: item?.name,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[0],
                            }));
                          }}
                        >
                          {item.dailyStatus[0]}
                        </p>
                      ) : item?.dailyStatus[0] === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                            empiD: item?.empId,
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString,
                              name: item?.name,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[0],
                            }));
                          }}
                        >
                          {item.dailyStatus[0]}
                        </p>
                      ) : item?.dailyStatus[0] === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString,
                              name: item?.name,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[0],
                            }));
                          }}
                        >
                          {item.dailyStatus[0]}
                        </p>
                      ) : (
                        <p
                          className={style.tableContent}
                          style={{
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      )}

                      {item?.dailyStatus[1] === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString1,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[1],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[1]}
                        </p>
                      ) : item?.dailyStatus[1] === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString1,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[1],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[1]}
                        </p>
                      ) : item?.dailyStatus[1] === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString1,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[1],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[1]}
                        </p>
                      ) : (
                        <p
                          className={style.tableContent}
                          style={{
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      )}

                      {item?.dailyStatus[2] === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString2,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[2],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[2]}
                        </p>
                      ) : item?.dailyStatus[2] === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString2,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[2],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[2]}
                        </p>
                      ) : item?.dailyStatus[2] === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString2,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[2],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[2]}
                        </p>
                      ) : (
                        <p
                          className={style.tableContent}
                          style={{
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      )}

                      {item?.dailyStatus[3] === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString3,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[3],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[3]}
                        </p>
                      ) : item?.dailyStatus[3] === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString3,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[3],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[3]}
                        </p>
                      ) : item?.dailyStatus[3] === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString3,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[3],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[3]}
                        </p>
                      ) : (
                        <p
                          className={style.tableContent}
                          style={{
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      )}

                      {item?.dailyStatus[4] === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString4,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[4],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[4]}
                        </p>
                      ) : item?.dailyStatus[4] === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString4,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[4],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[4]}
                        </p>
                      ) : item?.dailyStatus[4] === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString4,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[4],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[4]}
                        </p>
                      ) : (
                        <p
                          className={style.tableContent}
                          style={{
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      )}

                      {item?.dailyStatus[5] === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString5,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[5],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[5]}
                        </p>
                      ) : item?.dailyStatus[5] === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString5,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[5],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[5]}
                        </p>
                      ) : item?.dailyStatus[5] === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString5,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[5],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[5]}
                        </p>
                      ) : (
                        <p
                          className={style.tableContent}
                          style={{
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      )}

                      {item?.dailyStatus[6] === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString6,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[6],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[6]}
                        </p>
                      ) : item?.dailyStatus[6] === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString6,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[6],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[6]}
                        </p>
                      ) : item?.dailyStatus[6] === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setNextScreen("ViewTimeSheetByIds");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "ViewTimeSheetByIds",
                              page3: null,
                              page4: null,
                              active: "ViewTimeSheetByIds",
                              outputDate: outputDateString6,
                              empiD: item?.empId,
                              dailyStatus: item?.dailyStatus[6],
                              name: item?.name,
                            }));
                          }}
                        >
                          {item.dailyStatus[6]}
                        </p>
                      ) : (
                        <p
                          className={style.tableContent}
                          style={{
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      )}
                      <p className={style.tableContent}></p>
                    </div>
                  );
                })
              ) : (
                <p className={style.no_data}>No Data Found</p>
              )
            ) : null}
          </div>
        </div>

        <></>
      </InvoiceContainer>

      <div className={style.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={data?.count / 10}
          color="##855ea1"
          color2="white"
        />
      </div>
    </>
  );
};
export default TeamTimeSheet;
