import React from 'react'
import cx from "classnames";
import { Container, Row, Col, Card } from "react-bootstrap";
import styles from "./preview.module.css";
import SettingsIcon from "@mui/icons-material/Settings";
import ExtensionIcon from "@mui/icons-material/Extension";
import "react-datepicker/dist/react-datepicker.css";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import HomeIcon from "@mui/icons-material/Home";
import SecurityIcon from "@mui/icons-material/Security";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import TabletIcon from '@mui/icons-material/Tablet';

const Preview = () => {
  return (
    <div style={{padding:"40px"}}>
      <div style={{width:"103%"}} className={styles.buttonRow}>
                    <div>
                        <button  className={cx(styles.button1, styles.font12, styles.fontMedium, styles.colorWhite)} style={{ cursor: "pointer" }}>
                            Back
                        </button>
                    </div>
                    <div>
                        <button  className={cx(styles.button, styles.font12, styles.fontMedium, styles.colorWhite)} style={{ cursor: "pointer" }}>
                            Next
                        </button>
                    </div>
                </div>
          {/* <Card> */}
            <Container style={{boxShadow:"0 0 30px #ccc",padding:"20px",width:"103%",marginTop:"10px"}}>
              <p style={{display:"flex"}}>
                <SettingsIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116,styles.sectionHeading, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> General</span>
              </p>
              <div style={{display:"flex"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Serial Number</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Brand</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Model</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <div style={{display:"flex" , marginTop:"2%"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>MAC Address</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Type</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <hr className={styles.section}/>
              <p style={{display:"flex" , marginTop:"2%"}}>
                <ExtensionIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.sectionHeading,styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> Specifications</span>
              </p>
              <div style={{display:"flex"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Processor</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> RAM</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Hard Disk</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <div style={{display:"flex" , marginTop:"2%"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Screen Size</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <p className={styles.iconHeadingContainer} style={{marginTop:"2%"}}>
                <LocalAtmIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.sectionHeading, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}>Purchase</span>
              </p>
              <div style={{display:"flex"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Serial Number</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Brand</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Model</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>


              <p style={{display:"flex" ,marginTop:"2%" }}>
            <HealthAndSafetyIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> Anti-Virus</span>
              </p>
              <div style={{display:"flex"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Company</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Start Date</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>End Date</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <div style={{display:"flex" , marginTop:"2%"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Status</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <hr className={styles.section}/>
              <p style={{display:"flex" , marginTop:"2%"}}>
              <AddModeratorIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> ADP(Accidental Damage Protection)</span>
              </p>
              <div style={{display:"flex"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Warrantly Period </label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Start Date</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> End Date</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <div style={{display:"flex" , marginTop:"2%"}}>
                <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Status</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <hr className={styles.section}/>
              <p style={{display:"flex", marginTop:"2%"}}>
              <HomeIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> Insurance</span>
              </p>
              <div style={{display:"flex"}}>
              <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Status </label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Expiry</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Vendor</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>
              <hr className={styles.section}/>
              <p style={{display:"flex", marginTop:"2%"}}>
              <SecurityIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}> Warrantly</span>
              </p>
              <div style={{display:"flex"}}>
              <div>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Warrantly Period </label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Start Date</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> End Date</label><br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>


              <p style={{display:"flex",marginTop:"2%" }}>
            <TabletIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116,styles.sectionHeading, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}>Others</span>
              </p>
              <div style={{display:"flex"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> S.No.</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Device Name</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Brand</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
              </div>

              <div style={{display:"flex",marginTop:"2%"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Model</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Status</label> <br />
                  <select className={styles.input}></select>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Active</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
              </div>
        
              <hr className={styles.section}/>
              </Container>
                </div>
  )
}

export default Preview