import React from "react";
import style from './tasktracker.module.css';

import MyTasks from "./myTasks/myTasks";
import { useHistory, Route, Switch, useRouteMatch } from "react-router-dom";
import CreateTask from "./createTask/CreateTask";
import Header from './commonComponent/Header';
import SideBar from "./commonComponent/SideBar";
import Filter from "./myTasks/component/Filter";

import TaskAssignedByMeComponent from '../taskTracker/taskAssignedByMe/component/TaskAssignedByMeComponent';
import CreateTaskComponent from '../taskTracker/createTask/component/CreateTaskComponent';





// import MyTasks from "./myTasks/myTasks";
// import { useHistory } from "react-router-dom";
// import CreateTask from "./createTask/CreateTask";
// import Header from './commonComponent/Header';
// import SideBar from "./commonComponent/SideBar";
// import Filter from "./myTasks/component/Filter";
export default function TaskTracker(props) {
  const history = useHistory();
  const { path } = useRouteMatch();


  console.log("")

  return (
    <div>
      <Header />

      <div className={style.mainSection}>
        <SideBar />
        <Switch>
          <Route path={"/taskTracker"} exact component={MyTasks} />
          {/* <Route path={"/taskTracker/TaskAssignedByMeComponent"} exact component={TaskAssignedByMeComponent} /> */}
          {/* <Route path={"/taskTracker/CreateTaskComponent"} exact component={CreateTaskComponent} /> */}
          {/* <Route
            exact
            path="/taskTracker/TaskAssignedByMeComponent"
            render={(props) => <TaskAssignedByMeComponent {...props} />}
          />
          <Route
            exact
            path="/taskTracker/CreateTask"
            component={CreateTaskComponent}
          /> */}

        </Switch>

      </div>

      {/* <div className={style.rightSection}>
          <MyTasks />
        </div> */}


    </div>
  );
}

