import React from "react";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import RepaireJobComp from "./component/RepaireJobComp";
import style from "../tablet.module.css";

const TabletRepaireJob = () => {
  return (
    <div>
      {" "}
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <RepaireJobComp />
      </div>
    </div>
  );
};

export default TabletRepaireJob;
