import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import style from "./InductionNavigation.module.css";
import ProgressBar from "../progressbar/ProgressBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQuery } from "react-query";
import { getEmpRoleByEmpId } from "../../../Utils/axios/api";
import EmployeeList from "../pageLayout/empTimeSheet/EmployeeList";
import EmployeeInductionDetails from "../pageLayout/empTimeSheet/EmployeeInductionDetails";
import EmployeeEdit from "../pageLayout/empTimeSheet/EmployeeEdit";
export default function InductionNavigation() {
  // usequery getEmpRoleByEmpId
  const { data, isLoading, isError, error } = useQuery(
    ["getEmpRole"],
    () => getEmpRoleByEmpId(localStorage.getItem("employeeId")),
    {
      refetchOnWindowFocus: false,
    }
  );

  const [nextScreen, setNextScreen] = useState("employeeInduction");
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState([]);
  const [InductionData, setInductionData] = useState("");
  const [prevData, setPrevData] = useState({
    page: "employeeInduction",
    sidebar: "employeeInduction",
    page1: "employeeInduction",
    active: "employeeInduction",
  });

  const sidebarHandler = () => {
    switch (nextScreen) {
      case "employeeInduction":
        return (
          <EmployeeList
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "EmployeeInductionView":
        return (
          <EmployeeInductionDetails
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            setInductionData={setInductionData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "EditEmployee":
        return (
          <EmployeeEdit
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            InductionData={InductionData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      // case "viewExpense":
      //   return (
      //     <ViewExpense
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );

      // case "invoiceList":
      //   return (
      //     <InvoiceList
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      // case "createInvoice":
      //   return (
      //     <CreateInvoice
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      // case "viewInvoice":
      //   return (
      //     <ViewInvoice
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );
      // case "editInvoice":
      //   return (
      //     <EditInvoice
      //       setNextScreen={setNextScreen}
      //       setPrevData={setPrevData}
      //       prevData={prevData}
      //       currentPage={currentPage}
      //       setCurrentPage={setCurrentPage}
      //     />
      //   );

      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      <Header />
      <div className={style.mainSection}>
        <div style={{ width: "20%" }}>
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            roles={roles}
          />
        </div>
        <div className={style.ATScontainer}>
          <ProgressBar prevData={prevData} />
          {sidebarHandler()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
