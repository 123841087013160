import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Modal } from "react-bootstrap";
import style from "../AddTaskDetail/AddTaskDetail.module.css";
import Select from "react-select";
import {
  InvoiceStyles,
  InvoiceStyles2,
  InvoiceStyles3,
  InvoiceStyles4,
} from "../../utils/style";
import dropdownIcon from "../../../../assets/Icon feather-chevron-down.png";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import AddButton from "../../../../assets/Icon ionic-md-add-circle.png";
import Edit from "../../../../assets/Icon material-edit.png";
import Delete from "../../../../assets/delete-icon.png";
import InvoiceButton from "../../../NewEmployeeTimeSheet/CommonComponent/Button/InvoiceButton";
import BreadCrum from "../../../NewEmployeeTimeSheet/breadcrums/BreadCrum";
import cross from "../../../../assets/cross.png";
import CustomTooltip from "../../../NewEmployeeTimeSheet/customTooltip/CustomTooltip.js";
import AddIcon from "../../../../assets/Add_notes.png";
import DatePicker from "react-datepicker";
import PurpleIcon from "../../../../assets/PurpleIcon.png";
import cancel from "../../../../assets/cancell.png";
import moment from "moment/moment";
import {
  getTaskType,
  getTimeSpent,
  getAllActiveProject,
  createTimeSheet,
  getTimesheetId,
  EditTimeSheet,
  ViewTimeSheetByID,
  DeleteTimeSheet,
} from "../../../../Utils/axios/NewTimeSheetApi";
import { useMutation, useQuery } from "react-query";

const AddTaskDetail = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [lineItemArray, setLineItemArray] = useState([1]);
  const [TaskDetail, setTaskDetail] = useState(false);
  const [AddTask, setAddTask] = useState(true);
  const [EditTask, SetEditTask] = useState(true);
  const [editSheetid, seteditSheetid] = useState(0);
  const [deleteId, setdeleteId] = useState();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show4, setShow4] = useState(false);
  const [taskSummaryData, setTaskSummaryData] = useState();

  const currentDate = new Date();
  const timesheetDate = currentDate.toISOString().replace("Z", "+05:30");
  const formattedDate = timesheetDate.substring(0, 10);
  const updatedDate = (date) => {
    let data = moment(date).toISOString();
    data = moment(data);
    data = data
      .add("days")
      .hour(5)
      .minute(58)
      .second(15)
      .millisecond(855)
      .toISOString();
    return data;
  };
  const [formData2, setFormData2] = useState({
    timesheetDate: prevData?.timesheetDate
      ? updatedDate(prevData?.timesheetDate)
      : formattedDate,
  });
  const datetime = formData2?.timesheetDate;

  const dateObject = new Date(formData2?.timesheetDate);
  dateObject.setUTCHours(5);
  dateObject.setUTCMinutes(58);
  dateObject.setUTCSeconds(15);
  dateObject.setUTCMilliseconds(855);
  const timestamp = prevData?.timesheetDate
    ? updatedDate(prevData?.timesheetDate)
    : dateObject.toISOString();

  const convertDateFormate = (value) => {
    const dateObject = new Date(value);
    dateObject.setUTCHours(5);
    dateObject.setUTCMinutes(58);
    dateObject.setUTCSeconds(15);
    dateObject.setUTCMilliseconds(855);
    const timestamp = dateObject.toISOString();
    return timestamp;
  };
  // Output the timestamp in the desired format

  const [formData, setFormData] = useState({
    timeSheetItemDetails: [
      {
        projectId: "",
        timeSpentId: "",
        taskTypeId: "",
        apiName: "",
        screenName: "",
        defectNumber: "",
        featureName: "",
        taskDate: timestamp,
        taskSummary: "",
        timesheetId: "",
        employeeId: Number(localStorage.getItem("employeeId")),
      },
    ],
  });
  console.log("pppppss",prevData);

  useEffect(() => {
    let newarr = formData?.timeSheetItemDetails?.map((prev) => {
      return {
        ...prev,
        taskDate: convertDateFormate(formData2?.timesheetDate),
      };
    });
    setFormData((prev) => ({
      ...prev,
      timeSheetItemDetails: newarr,
    }));
  }, [formData2?.timesheetDate]);
  const { data: ViewTimeById } = useQuery(
    ["ViewTimeSheetByID", { editSheetid }],
    () => ViewTimeSheetByID(editSheetid)
  );

  useEffect(() => {
    seteditSheetid(editSheetid);
  }, [editSheetid]);

  const [formData1, setFormData1] = useState({
    projectId: ViewTimeById?.projectId,
    timeSpentId: ViewTimeById?.timeSpentId,
    taskTypeId: ViewTimeById?.taskTypeId,
    taskSummary: ViewTimeById?.taskSummary,
    apiName: ViewTimeById?.apiname,
    screenName: ViewTimeById?.screenName,
    featureName: ViewTimeById?.featureName,
    taskDate: timestamp,
    // taskStatusId: ViewTimeById?.timeSpentId,
    defectNumber: ViewTimeById?.defectNumber,
    timesheetId: editSheetid,
    employeeId: Number(localStorage.getItem("employeeId")),
  });

  useEffect(() => {
    setFormData1((prevValue) => ({
      ...prevValue,
      projectId: ViewTimeById?.projectId,
      timeSpentId: ViewTimeById?.timeSpentId,
      taskTypeId: ViewTimeById?.taskTypeId,
      taskSummary: ViewTimeById?.taskSummary,
      apiName: ViewTimeById?.apiname,
      screenName: ViewTimeById?.screenName,
      featureName: ViewTimeById?.featureName,
      taskDate: timestamp,
      // taskStatusId: ViewTimeById?.timeSpentId,
      defectNumber: ViewTimeById?.defectNumber,
      timesheetId: editSheetid,
      employeeId: Number(localStorage.getItem("employeeId")),
    }));
  }, [ViewTimeById]);
  const [validate, setValidate] = React.useState(false);
  const [commentindex, setIndex] = useState(0);

  const { data: projectData } = useQuery(["getTaskType"], getTaskType);
  const TaskOption = projectData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getTimeSpentData } = useQuery(["getTimeSpent"], getTimeSpent);
  const TimeSpentData = getTimeSpentData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data, isLoading, isError, refetch } = useQuery(
    ["getTimesheetId"],
    () => getTimesheetId(datetime, Number(localStorage.getItem("employeeId"))),
    {
      refetchOnWindowFocus: false,
    }
  );

  const originalDate = new Date(prevData?.timesheetID);
  const day = originalDate.getDate();
  const month = originalDate.toLocaleString("default", { month: "short" });
  const year = originalDate.getFullYear().toString().slice(-2);
  const convertedDate = `${day} ${month} ${year}`;

  const { data: getAllProject } = useQuery(
    ["getAllActiveProject"],
    getAllActiveProject
  );
  const AllProject = getAllProject?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const mutation = useMutation(createTimeSheet, {
    onSuccess: (res) => {
      setFormData((prevValue) => ({
        ...prevValue,
        timeSheetItemDetails: [
          {
            projectId: "",
            timeSpentId: "",
            taskTypeId: "",
            apiName: "",
            screenName: "",
            defectNumber: "",
            featureName: "",
            taskDate: timestamp,
            taskSummary: "",
            timesheetId: "",
            employeeId: Number(localStorage.getItem("employeeId")),
          },
        ],
      }));
      refetch();
    },
  });

  const mutation1 = useMutation(EditTimeSheet, {
    onSuccess: (res) => {
      setNextScreen("AddTaskDetails");
      SetEditTask(true);
      setFormData((prevValue) => ({
        ...prevValue,
        timeSheetItemDetails: [
          {
            projectId: "",
            timeSpentId: "",
            taskTypeId: "",
            apiName: "",
            screenName: "",
            defectNumber: "",
            featureName: "",
            taskDate: "",
            taskSummary: "",
            timesheetId: "",
            employeeId: "",
          },
        ],
      }));
      refetch();
    },
  });

  console.log("ppphhhh",formData);

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };
  const handleRouter = () => {
    setNextScreen("ViewTask");
    setPrevData((prevData) => ({
      ...prevData,
      page2: "ViewTask",
      page3: null,
      page4: null,
      active: "ViewTask",
    

    }));
  };

  const createTimeSheetHandler = () => {
    if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.apiName !== "" &&
          item?.screenName !== ""
      )
    ) {
      prevData?.timesheetID ? handleRouter() : setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.screenName !== ""
      )
    ) {
      prevData?.timesheetID ? handleRouter() : setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.apiName !== ""
      )
    ) {
      prevData?.timesheetID ? handleRouter() : setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.defectNumber !== ""
      )
    ) {
      prevData?.timesheetID ? handleRouter() : setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.featureName !== ""
      )
    ) {
      prevData?.timesheetID ? handleRouter() : setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== ""
      )
    ) {
      prevData?.timesheetID ? handleRouter() : setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else {
      setValidate(true);
    }
  };
  const editHandler = (id) => {
    seteditSheetid(id);
    SetEditTask(false);
  };
  const updateHandler = () => {
    mutation1.mutate(formData1);
    //  SetEditTask(true);
  };
  const mutation2 = useMutation(DeleteTimeSheet,
    {onSuccess:()=>{
      refetch()
    }}
    
    )
   

  const handleShow = (expenseId) => {
    setShow(true);
    setdeleteId(expenseId);
  };

  const handleDelete = () => {
    setShow(false);
    mutation2.mutate({
      timesheetId:deleteId, 
      requester:Number(localStorage.getItem("employeeId"))
    });
  };

  const handleDeleteCancle = () => {
    setShow(false);
  };

  const handleNotApproved = () => {
    setShow1(false);
    // plapprovedData({
    //   timeid: Number(localStorage.getItem("employeeId")),
    //   Textid: TextData,
    //   RadioButton: datatimesheet,
    // });
    // setNextScreen("ViewTimeSheetByIds");
  };

  const handleNotApproved2 = () => {
    setShow2(false);
  };
  const handleNotApproved4 = () => {
    setShow4(false);
  };

  const handleShow2 = (task) => {
    setShow2(true);
    setTaskSummaryData(task);
  };

  const handleShow1 = (key) => {
    setShow1(true);
    setIndex(key);
  };

  const handleShow4 = (task) => {
    setShow4(true);
    setTaskSummaryData(task);
  };
  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          alt=""
        />
      </div>
    );
  });

  return (
    <>
      {EditTask ? (
        <>
          <div>
            <BreadCrum
              prevData={prevData}
              setPrevData={setPrevData}
              setNextScreen={setNextScreen}
            ></BreadCrum>
          </div>
          <Container>
            <Row style={{ marginTop: "10px" }}></Row>
          </Container>
          
          <Container>
            <Row>
              <Col md={4}>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData2?.timesheetDate
                        ? moment(formData2?.timesheetDate).toDate()
                        : null
                    }
                    maxDate={moment().toDate()}
                    onChange={(date) => {
                      const dateval = date.toISOString().replace("Z", "+05:30");
                      const date1 = new Date(dateval);
                      date1.setDate(date1.getDate() + 1);
                      const formattedDate = date1
                        .toISOString()
                        .replace("Z", "+05:30");
                      const dateData = formattedDate.substring(0, 10);
                      setFormData2({
                        ...formData2,
                        timesheetDate: dateData,
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData2?.timesheetDate ? (
                        <CustomInputClg />
                      ) : (
                        <CustomInputCross properties="timesheetDate" />
                      )
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>

          <InvoiceContainer>
            <div className={style.copy_expence}>
              Add Task Details
              {AddTask ? (
                ""
              ) : (
                <span className={style.btnAddItem}>
                  <InvoiceButton
                    onClick={() => {
                      setAddTask(true);
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      // setFormData({
                      //   ...formData,
                      //   timeSheetItemDetails: [
                      //     ...formData?.timeSheetItemDetails,
                      //     {
                      //       projectId: "",
                      //       timeSpentId: "",
                      //       taskTypeId: "",
                      //       apiName: "",
                      //       screenName: "",
                      //       defectNumber: "",
                      //       featureName: "",
                      //       taskSummary: "",
                      //       timesheetId: "",
                      //       employeeId: Number(
                      //         localStorage.getItem("employeeId")
                      //       ),
                      //     },
                      //   ],
                      // });
                    }}
                  >
                    Add More
                  </InvoiceButton>
                </span>
              )}
            </div>
            {/*row 1*/}

            {AddTask ? (
              <>
                <Row style={{ marginLeft: "3px" }}>
                  <Col md={3}>
                    <p className={style.label_item}>
                      Project Name<span style={{ color: "red" }}>*</span>
                    </p>
                  </Col>
                  <Col>
                    <p className={style.label_item}>
                      Task Type<span style={{ color: "red" }}>*</span>
                    </p>
                  </Col>
                  <Col></Col>
                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(30px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>
                  </Col>
                  <Col>
                    <p className={style.label_item5}>
                      Task Summary
                    </p>
                  </Col>
                </Row>
                {formData?.timeSheetItemDetails?.map((item, index) => {
                  return (
                    <>
                      <div key={index}>
                        <Row
                          style={{ marginLeft: "3px", marginBottom: "-5px" }}
                        >
                          <Col md={3}>
                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].projectId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={AllProject?.find(
                                (e) =>
                                  e.label ===
                                  formData?.timeSheetItemDetails[index]
                                    .projectId
                              )}
                              options={AllProject}
                            />
                            <p
                              className={
                                validate && !item.projectId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Project Name
                            </p>
                          </Col>
                          <Col>
                            <Select
                              styles={InvoiceStyles4}
                              classNamePre
                              fix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskTypeId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TaskOption?.find(
                                (e) =>
                                  e.label ===
                                  formData?.timeSheetItemDetails[index]
                                    .taskTypeId
                              )}
                              options={TaskOption}
                            />

                            <p
                              className={
                                validate && !item.taskTypeId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Type
                            </p>
                          </Col>

                          {formData?.timeSheetItemDetails[index].taskTypeId ===
                            1 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            2 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            3 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            4 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            5 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            6 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            7 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            8 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            9 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            10 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            11 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            12 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            13 ? (
                            ""
                          ) : (
                            <>
                              <Col></Col>

                              <Col md={2}>
                                <Select
                                  styles={InvoiceStyles2}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText1
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col style={{ float: "right" }}>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          )}

                          {formData?.timeSheetItemDetails[index].taskTypeId ===
                          1 ? (
                            <>
                              <Col>
                                <input
                                  type="text"
                                  placeholder="API Name"
                                  style={{ marginLeft: "30px" }}
                                  className={style.smallInput}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].apiName = e?.target?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={
                                    formData?.timeSheetItemDetails[index]
                                      ?.apiName
                                  }
                                />
                                <p
                                  className={
                                    validate && !item.apiName
                                      ? style.errorText2
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter API Name
                                </p>
                              </Col>
                              <Col>
                                <Select
                                  styles={InvoiceStyles2}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText1
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {formData?.timeSheetItemDetails[index].taskTypeId ===
                            2 ||
                          formData?.timeSheetItemDetails[index]?.taskTypeId ===
                            9 ? (
                            <>
                              <Col>
                                <input
                                  type="text"
                                  placeholder="Screen Name"
                                  style={{ marginLeft: "30px" }}
                                  className={style.smallInput}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].screenName = e?.target?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={
                                    formData?.timeSheetItemDetails[index]
                                      ?.screenName
                                  }
                                />
                                <p
                                  className={
                                    validate && !item?.screenName
                                      ? style.errorText2
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter Screen Name
                                </p>
                              </Col>
                              <Col>
                                <Select
                                  styles={InvoiceStyles2}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText1
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                          3 ? (
                            <>
                              <Col>
                                <input
                                  style={{ marginLeft: "20px", width: "120%" }}
                                  type="text"
                                  placeholder="Screen Name"
                                  className={style.smallInput}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].screenName = e?.target?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={
                                    formData?.timeSheetItemDetails[index]
                                      ?.screenName
                                  }
                                />
                                <p
                                  className={
                                    validate && !item?.screenName
                                      ? style.errorText4
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter Screen Name
                                </p>
                              </Col>
                              <Col>
                                <input
                                  type="text"
                                  style={{ marginLeft: "43px" }}
                                  placeholder="API Name"
                                  className={style.smallInput}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].apiName = e?.target?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={
                                    formData?.timeSheetItemDetails[index]
                                      ?.apiName
                                  }
                                />
                                <p
                                  className={
                                    validate && !item?.apiName
                                      ? style.errorText5
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter API Name
                                </p>
                              </Col>
                              <Col>
                                <Select
                                  styles={InvoiceStyles3}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText6
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                            4 ||
                          formData?.timeSheetItemDetails[index]?.taskTypeId ===
                            10 ? (
                            <>
                              <Col>
                                <input
                                  type="text"
                                  placeholder="API Name"
                                  style={{ marginLeft: "30px" }}
                                  className={style.smallInput}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].apiName = e?.target?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={
                                    formData?.timeSheetItemDetails[index]
                                      ?.apiName
                                  }
                                />

                                <p
                                  className={
                                    validate && !item?.apiName
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter API Name
                                </p>
                              </Col>
                              <Col>
                                <Select
                                  styles={InvoiceStyles2}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                          5 ? (
                            <>
                              <Col>
                                <input
                                  style={{ marginLeft: "30px" }}
                                  type="text"
                                  placeholder="Defect Number"
                                  className={style.smallInput}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].defectNumber = e?.target?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={
                                    formData?.timeSheetItemDetails[index]
                                      ?.defectNumber
                                  }
                                />
                                <p
                                  className={
                                    validate && !item?.defectNumber
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter Defect Number
                                </p>
                              </Col>
                              <Col>
                                <Select
                                  styles={InvoiceStyles2}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                          6 ? (
                            <>
                              <Col>
                                {/* <p className={style.label_item1}>
                              Feature Name
                              <span style={{ color: "red" }}>*</span>
                            </p> */}
                                <input
                                  style={{ marginLeft: "30px" }}
                                  type="text"
                                  placeholder=" Feature Name"
                                  className={style.smallInput}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].featureName = e?.target?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={
                                    formData?.timeSheetItemDetails[index]
                                      ?.featureName
                                  }
                                />
                                <p
                                  className={
                                    validate && !item?.featureName
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter Feature Name
                                </p>
                              </Col>
                              <Col>
                                <Select
                                  styles={InvoiceStyles2}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                            7 ||
                          formData?.timeSheetItemDetails[index]?.taskTypeId ===
                            8 ||
                          formData?.timeSheetItemDetails[index]?.taskTypeId ===
                            11 ? (
                            <>
                              <Col>
                                <input
                                  type="text"
                                  placeholder="Feature Name"
                                  style={{ marginLeft: "30px" }}
                                  className={style.smallInput}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].featureName = e?.target?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={
                                    formData?.timeSheetItemDetails[index]
                                      ?.featureName
                                  }
                                />
                                <p
                                  className={
                                    validate && !item?.featureName
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Enter Feature Name
                                </p>
                              </Col>
                              <Col>
                                <Select
                                  styles={InvoiceStyles2}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}

                          {formData?.timeSheetItemDetails[index].taskTypeId ===
                            12 ||
                          formData?.timeSheetItemDetails[index].taskTypeId ===
                            13 ? (
                            <>
                              <Col></Col>
                              <Col>
                                <Select
                                  styles={InvoiceStyles2}
                                  classNamePrefix={"create_position"}
                                  components={{
                                    DropdownIndicator: () => CustomInput(),
                                    IndicatorSeparator: () => null,
                                  }}
                                  onChange={(e) => {
                                    let temp = [
                                      ...formData?.timeSheetItemDetails,
                                    ];
                                    temp[index].timeSpentId = e?.value;
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails: temp,
                                    });
                                  }}
                                  value={TimeSpentData?.find(
                                    (e) =>
                                      e.value ===
                                      formData?.timeSheetItemDetails[index]
                                        .timeSpentId
                                  )}
                                  options={TimeSpentData}
                                />
                                <p
                                  className={
                                    validate && !item?.timeSpentId
                                      ? style.errorText
                                      : style.errorTextHidden
                                  }
                                >
                                  Please Select Time Spent
                                </p>
                              </Col>
                              <Col>
                                <img
                                  src={AddIcon}
                                  alt=""
                                  style={{
                                    width: "20px",
                                    float: "right",
                                    marginTop: "10px",
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleShow1(index)}
                                />
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          <Col md={1}>
                            {formData?.timeSheetItemDetails?.length > 1 && (
                              <>
                                <img
                                  src={cross}
                                  style={{ cursor: "pointer" }}
                                  alt=""
                                  className={style.remove_icon}
                                  size={20}
                                  onClick={(e) => {
                                    setLineItemArray(
                                      lineItemArray.filter((_, i) => {
                                        return i !== index;
                                      })
                                    );
                                    setFormData({
                                      ...formData,
                                      timeSheetItemDetails:
                                        formData?.timeSheetItemDetails?.filter(
                                          (_, i) => i !== index
                                        ),
                                    });
                                  }}
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              ""
            )}

            {AddTask ? (
              <div className={style.lastBtn_item}>
                <img
                  src={AddButton}
                  alt=""
                  style={{
                    width: "3%",
                    height: "30px",
                    paddingTop: "2px",
                    cursor: "pointer",
                    transform: "translate(30px, 0px)",
                  }}
                  onClick={() => {
                    setLineItemArray([
                      ...lineItemArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData({
                      ...formData,
                      timeSheetItemDetails: [
                        ...formData?.timeSheetItemDetails,
                        {
                          projectId: "",
                          timeSpentId: "",
                          taskTypeId: "",
                          apiName: "",
                          screenName: "",
                          defectNumber: "",
                          featureName: "",
                          taskDate: timestamp,
                          taskSummary: "",
                          timesheetId: "",
                          employeeId: Number(
                            localStorage.getItem("employeeId")
                          ),
                        },
                      ],
                    });
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </InvoiceContainer>
          <button
            className={style.buttonAdd}
            onClick={() => {
              createTimeSheetHandler();
            }}
          >
            Submit
          </button>
          <button
            className={style.buttonCancle}
            onClick={() => {
              setNextScreen("empTimeSheet");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "empTimeSheet",
                page3: null,
                page4: null,
                active: "empTimeSheet",
              }));
            }}
          >
            Cancel
          </button>
        </>
      ) : (
        <>
          <Container>
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <span className={style.timesheetdate}>Edit Task Details</span>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row style={{ marginTop: "10px" }}>
              <Col>{/* <p className={style.datetime}>10 Apr 23</p> */}</Col>
            </Row>
          </Container>
          <InvoiceContainer>
            <Row>
              <Col md={3} style={{ marginLeft: "12px" }}>
                <p className={style.label_item}>
                  Project Name<span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={AllProject?.find(
                    (e) => e.value === formData1?.projectId
                  )}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      projectId: e.value,
                    });
                  }}
                  options={AllProject}
                />
              </Col>
              <Col>
                <p className={style.label_item}>
                  Task Type<span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles4}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TaskOption?.find(
                    (e) => e.value === formData1?.taskTypeId
                  )}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      taskTypeId: e.value,
                    });
                  }}
                  options={TaskOption}
                />
              </Col>

              {formData1?.taskTypeId === 1 ? (
                <>
                  <Col>
                    <input
                      type="text"
                      style={{ marginTop: "32px", marginLeft: "30px" }}
                      placeholder="API Name"
                      value={formData1?.apiName}
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          apiName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.apiName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter API Name
                    </p>
                  </Col>

                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(90px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles2}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                  </Col>
                  <Col style={{ float: "right" }}>
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "right",
                        marginTop: "39px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShow2(formData1?.taskSummary)}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 2 || formData1?.taskTypeId === 9 ? (
                <>
                  <Col>
                    <input
                      style={{ marginTop: "32px", marginLeft: "30px" }}
                      placeholder=" Screen Name"
                      type="text"
                      className={style.smallInput}
                      value={formData1?.screenName}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          screenName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && formData1?.screenName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Screen Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(90px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles2}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                  </Col>
                  <Col style={{ float: "right" }}>
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "right",
                        marginTop: "39px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShow2(formData1?.taskSummary)}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 3 ? (
                <>
                  <Col>
                    <input
                      style={{ marginTop: "32px", marginLeft: "30px" }}
                      type="text"
                      placeholder="Screen Name"
                      className={style.smallInput}
                      value={formData1?.screenName}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          screenName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && formData1?.screenName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Screen Name
                    </p>
                  </Col>
                  <Col>
                    <input
                      style={{ marginTop: "32px", marginLeft: "30px" }}
                      value={formData1?.apiName}
                      placeholder="Screen Name"
                      type="text"
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          apiName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.apiName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter API Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(90px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles2}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                  </Col>
                  <Col style={{ float: "right" }}>
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "right",
                        marginTop: "39px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShow2(formData1?.taskSummary)}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 4 || formData1?.taskTypeId === 10 ? (
                <>
                  <Col>
                    <input
                      style={{ marginTop: "32px", marginLeft: "30px" }}
                      type="text"
                      placeholder="API Name"
                      value={formData1?.apiName}
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          apiName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.apiName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter API Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(90px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles2}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                  </Col>
                  <Col style={{ float: "right" }}>
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "right",
                        marginTop: "39px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShow2(formData1?.taskSummary)}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
              {formData1?.taskTypeId === 5 ? (
                <>
                  <Col>
                    <input
                      style={{ marginTop: "32px", marginLeft: "30px" }}
                      value={formData1?.defectNumber}
                      type="text"
                      className={style.smallInput}
                      placeholder="Defect Number"
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          defectNumber: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.defectNumber
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Defect Number
                    </p>
                  </Col>
                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(90px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles2}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                  </Col>
                  <Col style={{ float: "right" }}>
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "right",
                        marginTop: "39px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShow2(formData1?.taskSummary)}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 6 ? (
                <>
                  <Col>
                    <input
                      style={{ marginTop: "32px", marginLeft: "30px" }}
                      placeholder="Feature Name"
                      value={formData1?.featureName}
                      type="text"
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          featureName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.featureName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Feature Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(90px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles2}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                  </Col>
                  <Col style={{ float: "right" }}>
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "right",
                        marginTop: "39px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShow2(formData1?.taskSummary)}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
              {formData1?.taskTypeId === 7 ||
              formData1?.taskTypeId === 8 ||
              formData1?.taskTypeId === 11 ? (
                <>
                  <Col>
                    <input
                      style={{ marginTop: "32px", marginLeft: "30px" }}
                      value={formData1?.featureName}
                      placeholder="Feature Name"
                      type="text"
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          featureName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.featureName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Feature Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(90px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles2}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                  </Col>
                  <Col style={{ float: "right" }}>
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "right",
                        marginTop: "39px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShow2(formData1?.taskSummary)}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 12 ||
              formData1?.taskTypeId === 13 ||
              formData1?.taskTypeId === 14 ||
              formData1?.taskTypeId === 15 ? (
                <>
                  <Col></Col>
                  <Col md={2}>
                    <p
                      className={style.label_item}
                      style={{ transform: "translate(90px,0px)" }}
                    >
                      Time Spent
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles2}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                  </Col>
                  <Col style={{ float: "right" }}>
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "right",
                        marginTop: "39px",
                        marginRight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleShow2(formData1?.taskSummary)}
                    />
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <button
              className={style.EditAdd2}
              onClick={() => {
                updateHandler();
              }}
            >
              Update
            </button>
          </InvoiceContainer>
        </>
      )}

      {TaskDetail ? (
        <>
          <div className={style.ViewsheetcolorPos}>
            <p className={style.ViewEmployeeDetails}>
              {" "}
              <span>Employee Details</span>
            </p>
            <Container fluid>
              <div className={style.th}>
                <p>Project Name</p>
                <p>Task Type</p>
                <p>Name/Number</p>
                <p>Time Spent</p>
                <p>Task Summary</p>
                <p>Lead Approval</p>
                <p>Remark</p>
                <p>Action</p>
                <p></p>
              </div>
              <div>
                {!isLoading && !isError ? (
                  data?.length > 0 ? (
                    typeof data !== "string" &&
                    data?.map((item, index) => {
                      const minutes = item?.timeSpent;
                      const hours = Math.floor(minutes / 60);
                      const remainingMinutes = minutes % 60;
                      return (
                        <div className={style.td}>
                          <CustomTooltip
                            className={style.tableContent}
                            index={`projectName${index}`}
                            value={item?.projectName}
                            limit={28}
                            tooltipstyle={{ left: "37%" }}
                          />
                          <p className={style.tableContent}>{item?.taskType}</p>
                          <p className={style.tableContent}>
                            {item?.taskType === "API Integration"
                              ? `${item?.apiname} <-> ${item?.screenName} `
                              : item?.taskType === "Defect Fixing"
                              ? item?.defectNumber
                              : item?.taskType === "API Development"
                              ? item?.apiname
                              : item?.taskType === "UI Development"
                              ? item?.screenName
                              : ""}
                          </p>
                          <p className={style.tableContent}>
                            {" "}
                            {hours === 0
                              ? `${remainingMinutes} Min`
                              : `${hours} Hrs ${remainingMinutes} Min`}
                          </p>

                          <p className={style.tableContent}>
                            <img
                              src={AddIcon}
                              alt=""
                              style={{ cursor: "pointer", width: "15px" }}
                              onClick={() =>
                                handleShow4(item && item?.taskSummary)
                              }
                            />
                          </p>

                          {item?.plApproved === "Pending" ? (
                            <p
                              className={style.tableContent}
                              style={{
                                color: "#9c58a5",
                                fontSize: "600",
                                fontWeight: "bold",
                              }}
                            >
                              {item?.plApproved}
                            </p>
                          ) : item?.plApproved === "Approved" ? (
                            <p
                              className={style.tableContent}
                              style={{
                                color: "green",
                                fontSize: "600",
                                fontWeight: "bold",
                              }}
                            >
                              {item?.plApproved}
                            </p>
                          ) : item?.plApproved === "Not Approved" ? (
                            <p
                              className={style.tableContent}
                              style={{
                                color: "red",
                                fontSize: "600",
                                fontWeight: "bold",
                              }}
                            >
                              {item?.plApproved}
                            </p>
                          ) : (
                            ""
                          )}
                          <CustomTooltip
                            className={style.tableContent}
                            value={item?.remarks}
                            limit={12}
                            index={`remarks${index}`}
                            tooltipstyle={{ left: "52%" }}
                          />

                          <p className={style.tableContent}>
                            <img
                              src={Edit}
                              alt=""
                              onClick={() => editHandler(item?.timesheetId)}
                              style={{ cursor: "pointer" }}
                            />
                          </p>

                          <p className={style.tableContent}>
                            <img
                              src={Delete}
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() => handleShow(item?.timesheetId)}
                            />
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={style.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </Container>
          </div>
          <button
            className={style.buttonAdd2}
            onClick={() => {
              setNextScreen("empTimeSheet");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "empTimeSheet",
                page3: null,
                page4: null,
                active: "empTimeSheet",
              }));
            }}
          >
            Done
          </button>
        </>
      ) : (
        ""
      )}

      <Modal show={show} centered size="sm">
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to delete
        </h6>
        <button className={style.buttonAdd4} onClick={handleDelete}>
          Yes
        </button>
        <button className={style.buttonCancle4} onClick={handleDeleteCancle}>
          No
        </button>
      </Modal>

      <Modal show={show1} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Task Summary</h6>
        </Modal.Header>
        <Form.Control
          as="textarea"
          rows={2}
          style={{ width: "85%", marginLeft: "24px", marginTop: "10px" }}
          onChange={(e) => {
            let temp = [...formData?.timeSheetItemDetails];
            temp[commentindex].taskSummary = e?.target?.value;
            setFormData({
              ...formData,
              timeSheetItemDetails: temp,
            });
          }}
        />

        <button className={style.buttonAdd3} onClick={handleNotApproved}>
          Ok
        </button>
      </Modal>

      <Modal show={show2} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Task Summary</h6>
        </Modal.Header>
        <Form.Control
          as="textarea"
          value={formData1?.taskSummary}
          onChange={(e) => {
            setFormData1({
              ...formData1,
              taskSummary: e.target.value,
            });
          }}
          rows={2}
          style={{ width: "85%", marginLeft: "24px", marginTop: "10px" }}
        />

        <button className={style.buttonAdd3} onClick={handleNotApproved2}>
          Ok
        </button>
      </Modal>

      <Modal show={show4} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Task Summary</h6>
        </Modal.Header>
        <Form.Control
          as="textarea"
          defaultValue={taskSummaryData}
          disabled={true}
          rows={2}
          style={{ width: "85%", marginLeft: "24px", marginTop: "10px" }}
        />

        <button className={style.buttonAdd3} onClick={handleNotApproved4}>
          Ok
        </button>
      </Modal>
    </>
  );
};
export default AddTaskDetail;
