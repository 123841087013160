import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import Select from "react-select";
import calender from "../../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { InvoiceStyles } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { FaTrash } from "react-icons/fa";
import cancel from "../../../../../assets/cancell.png";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import style from "./CreateInvoice.module.css";
import moment from "moment/moment";
import axios from "axios";
import { Col, Modal, Row } from "react-bootstrap";

import AddImg from "../../../../../assets/Icon ionic-md-add-circle.png";

import {
  createInvoice,
  getClientName,
  getServiceType,
  getCurrencyType,
  getAdvanceStatus,
  getcopyInvoice,
  getMonth,
  createClient,
} from "../../../../../Utils/axios/expenceApi.js";
import { useQuery, useMutation } from "react-query";
const CreateInvoice = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [lineItemArray, setLineItemArray] = useState([1]);
  const [formData, setFormData] = useState({
    copyOfInvoice: "",
    clientId: "",
    projectId: "",
    clientName1: "",
    invoiceNumber: "",
    invoiceDate: "",
    dueDate: "",
    advanceStatusId: "",
    currencyId: "",
    conversionRate: "",
    gstNumber: "",
    baseAmountInvoice: "",
    baseAmountINR: "",
    totalInvoiceAmount: "",
    igst: "",
    sgst: "",
    cgst: "",
    tds: "",
    amountToBeReceived: "",
    invoiceLineItemDetails: [
      {
        amountOverriden: 0,
        amountToBePaid: 0,
        baseAmount: "",
        billingRate: "",
        calculativeBaseAmount: "",
        candidateName: "",
        daysInMonth: "",
        workingDays: "",
        maxWorkingDay: "",
        sno: "",
        description: "",
        projectValue: "",
        paymentPercentage: "",
        timeframe: "",
        price: "",
      },
    ],
  });
  const [validate, setValidate] = React.useState(false);
  const [show, setShow] = useState(false);
  const [ClientAdd, setAddClient] = useState();


  const { data: InvoiceDataById, refetch } = useQuery(
    ["getcopyInvoice", formData?.clientId],

    () => formData?.clientId && getcopyInvoice(formData?.clientId),
    { refetchOnWindowFocus: false, enabled: false }
  );

  useEffect(() => {
    refetch();
  }, [formData?.clientId]);

  const [formData1, setFormData1] = useState({
    copyOfInvoice: "",
    clientId: InvoiceDataById?.clientId,
    projectId: InvoiceDataById?.projectId,
    clientName1: InvoiceDataById?.clientName,
    invoiceNumber: InvoiceDataById?.invoiceNumber,
    invoiceDate: InvoiceDataById?.invoiceDate,
    dueDate: InvoiceDataById?.dueDate,
    advanceStatusId: InvoiceDataById?.advanceStatusId,
    currencyId: InvoiceDataById?.currencyId,
    conversionRate: InvoiceDataById?.conversionRate,
    gstNumber: InvoiceDataById?.gstNumber,
    baseAmountInvoice: InvoiceDataById?.baseAmountInvoice,
    baseAmountINR: InvoiceDataById?.baseAmountINR,
    totalInvoiceAmount: InvoiceDataById?.totalInvoiceAmount,
    igst: InvoiceDataById?.igst,
    sgst: InvoiceDataById?.sgst,
    cgst: InvoiceDataById?.cgst,
    tds: InvoiceDataById?.tds,
    amountToBeReceived: InvoiceDataById?.amountToBeReceived,
    invoiceLineItemDetails: InvoiceDataById?.invoiceLineItemDetails,
    sno: InvoiceDataById?.sno,
    description: InvoiceDataById?.description,
    projectValue: InvoiceDataById?.projectValue,
    paymentPercentage: InvoiceDataById?.paymentPercentage,
    timeframe: InvoiceDataById?.timeframe,
    price: InvoiceDataById?.price,

    // invoiceLineItemDetails: [
    //   {
    //     amountOverriden: 0,
    //     amountToBePaid: 0,
    //     baseAmount: "",
    //     billingRate: "",
    //     calculativeBaseAmount: "",
    //     candidateName: "",
    //     daysInMonth: "",
    //     workingDays: "",
    //   },
    // ],
  });

  useEffect(() => {
    if (formData?.copyOfInvoice === true) {
      setFormData1((prevValue) => ({
        ...prevValue,
        baseAmountInvoice: InvoiceDataById?.baseAmountInvoice,
        gstNumber: InvoiceDataById?.gstNumber,
        copyOfInvoice: InvoiceDataById?.copyInvoice,
        clientId: InvoiceDataById?.clientId,
        projectId: InvoiceDataById?.projectId,
        clientName1: InvoiceDataById?.clientName,
        invoiceNumber: InvoiceDataById?.invoiceNumber,
        invoiceDate: InvoiceDataById?.invoiceDate,
        dueDate: InvoiceDataById?.dueDate,
        advanceStatusId: InvoiceDataById?.advanceStatusId,
        currencyId: InvoiceDataById?.currencyId,
        conversionRate: InvoiceDataById?.conversionRate,
        baseAmountINR: InvoiceDataById?.baseAmountINR,
        totalInvoiceAmount: InvoiceDataById?.totalInvoiceAmount,
        igst: InvoiceDataById?.igst,
        sgst: InvoiceDataById?.sgst,
        cgst: InvoiceDataById?.cgst,
        tds: InvoiceDataById?.tds,
        amountToBeReceived: InvoiceDataById?.amountToBeReceived,
        invoiceLineItemDetails: InvoiceDataById?.invoiceLineItemDetails,
      }));
    } else {
    }
  }, [InvoiceDataById]);

  const { data: clientData } = useQuery(["getClientName"], getClientName, {
    refetchOnWindowFocus: true,
    staleTime: 0,
    cacheTime: 0,
    refetchInterval: 1000,
  });
  const clientOption = clientData?.map((e) => ({
    value: e?.id,
    label: e?.clientName,
  }));

  const { data: statusData } = useQuery(["getAdvanceStatus"], getAdvanceStatus);
  const statusOption = statusData?.map((e) => ({
    value: e.id,
    label: e.status,
  }));
  const { data: projectData } = useQuery(["getServiceType"], getServiceType);
  const projectOption = projectData?.map((e) => ({
    value: e.id,
    label: e.serviceType,
  }));
  const { data: currencyData } = useQuery(["getCurrencyType"], getCurrencyType);
  const currencyOption = currencyData?.map((e) => ({
    value: e.id,
    label: e.currencyName,
  }));

  const { data: GetMonthData } = useQuery(["getMonth"], getMonth);
  const GetMonthoption = GetMonthData?.map((e) => ({
    value: e.month,
    label: e.month,
  }));
  const mutation = useMutation(createInvoice, {});


  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          alt=""
        />
      </div>
    );
  });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const mutation1 = useMutation(createClient, {});

  const AddClient = {
    companyName: ClientAdd,
    partnerName: "",
  };

  const handleDelete = () => {
    setShow(false);
    mutation1.mutate(AddClient);
  };

  const handleDeleteCancle = () => {
    setShow(false);
  };

  const handleShow = (expenseId) => {
    setShow(true);
  };

  const handleWheel = (e) => {
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const CreateInvoiceHandler = () => {
    if (
      // formData?.copyOfInvoice &&
      formData?.clientId &&
      formData?.projectId &&
      // formData?.clientName1 &&
      formData?.invoiceNumber &&
      formData?.invoiceDate &&
      formData?.dueDate &&
      formData?.advanceStatusId &&
      formData?.currencyId &&
      formData?.conversionRate &&
      formData?.gstNumber &&
      formData?.baseAmountInvoice &&
      formData?.baseAmountINR &&
      formData?.totalInvoiceAmount &&
      formData?.igst &&
      formData?.sgst &&
      formData?.cgst &&
      formData?.tds &&
      formData?.amountToBeReceived
    ) {
      setNextScreen("invoiceList");
      setValidate(false);
      mutation.mutate(formData);
      setNextScreen("invoiceList");
    } else if (
      formData1?.clientId &&
      formData1?.projectId &&
      // formData1?.clientName1 &&
      formData1?.invoiceNumber &&
      formData1?.invoiceDate &&
      formData1?.dueDate &&
      formData1?.advanceStatusId &&
      formData1?.currencyId &&
      formData1?.conversionRate &&
      formData1?.gstNumber &&
      formData1?.baseAmountInvoice &&
      formData1?.baseAmountINR &&
      formData1?.totalInvoiceAmount &&
      formData1?.igst &&
      formData1?.sgst &&
      formData1?.cgst &&
      formData1?.tds &&
      formData1?.amountToBeReceived
    ) {
      setValidate(false);
      mutation.mutate(formData1);
      setNextScreen("invoiceList");
    } else {
      setValidate(true);
    }
  };

  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>

      {/*Copy Invoice*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Copy Invoice </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Invoice?</div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="Yes"
                name="radio-group"
                onChange={() =>
                  setFormData({ ...formData, copyOfInvoice: true })
                }
              />
              <label for="Yes">Yes</label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="no"
                name="radio-group"
                onChange={() =>
                  setFormData({ ...formData, copyOfInvoice: false })
                }
              />
              <label for="no">No</label>
            </div>
          </div>

          <div className={style.col2}>
            {/* <p
                  className={
                    validate && !formData?.copyOfInvoice
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Choose Copy Invoice
                </p> */}
          </div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        <Row>
          <Col md={3} style={{ marginLeft: "26px" }}>
            <p className={style.label}>
              Client Name<span style={{ color: "red" }}>*</span>
            </p>
            <Select
              styles={InvoiceStyles}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  clientId: e?.value,
                });
              }}
              options={clientOption}
            />
          </Col>
          <Col md={3} style={{ marginTop: "36px" }}>
            <img
              src={AddImg}
              alt=""
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                handleShow();
              }}
            />
          </Col>
        </Row>
        {/* <div className={style.Container}>
         
          <div className={style.col12}>
            <p className={style.label}>
              Client Name<span style={{ color: "red" }}>*</span>
            </p>
            <Select
              styles={InvoiceStyles}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  clientId: e?.value,
                });
              }}
              options={clientOption}
            />
           

            <p
              className={
                validate && !formData?.clientId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Select Client Name
            </p>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div> */}
      </InvoiceContainer>
      {/*Invoice Details*/}
      {formData.copyOfInvoice === true ? (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Invoice Details</div>
            {/*row 1*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Project Type <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={projectOption?.find(
                    (e) => e.value === formData1?.projectId
                  )}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      projectId: e?.value,
                    });
                  }}
                  options={projectOption}
                />
                <p
                  className={
                    validate && !formData1?.projectId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Project Type
                </p>
              </div>
              {/* <div className={style.col2}>
                <p className={style.label}>
                  Client Name <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  value={formData1.clientName1}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      clientName1: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData1?.clientName1
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Client Name
                </p>
              </div> */}
              <div className={style.col3}></div>
              <div className={style.col4}></div>
            </div>
            {/*row 2*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Invoice No <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  value={formData1?.invoiceNumber}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      invoiceNumber: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData1?.invoiceNumber
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice No
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Invoice Date <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData1?.invoiceDate
                        ? moment(formData1?.invoiceDate).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setFormData1({
                        ...formData1,
                        invoiceDate: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData1?.invoiceDate ? (
                        <CustomInputCross properties="invoiceDate" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData1?.invoiceDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice Date
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  Due Date <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData1?.dueDate
                        ? moment(formData1?.dueDate).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setFormData1({
                        ...formData1,
                        dueDate: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData1?.dueDate ? (
                        <CustomInputCross properties="dueDate" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData1?.dueDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Due Date
                </p>
              </div>
              <div className={style.col4}>
                <p className={style.label}>
                  Advance Status <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={statusOption?.find(
                    (e) => e.value === formData1?.advanceStatusId
                  )}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      advanceStatusId: e.value,
                    });
                  }}
                  options={statusOption}
                />
                <p
                  className={
                    validate && !formData1?.advanceStatusId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Advance Status
                </p>
              </div>
            </div>
            <div className={style.border}></div>
            {/*row 3*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Currency <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      currencyId: e.value,
                    });
                  }}
                  value={currencyOption?.find(
                    (e) => e.value === formData1?.currencyId
                  )}
                  options={currencyOption}
                />
                <p
                  className={
                    validate && !formData1?.currencyId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Currency
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Conversion Rate <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  value={formData1?.conversionRate}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      conversionRate: e.target.value,
                    });
                  }}
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                  min="0"
                />
                <p
                  className={
                    validate && !formData1?.conversionRate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Conversion Rate
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  GSTN <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  defaultValue={formData1.gstNumber}
                  type="text"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      gstNumber: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData1?.gstNumber
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter GSTN
                </p>
              </div>
              <div className={style.col4}></div>
            </div>
            {/*row 4*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Invoice Amount (Base) <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  defaultValue={formData1?.baseAmountInvoice}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      baseAmountInvoice: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.baseAmountInvoice
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice Amount (Base)
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Invoice Amount (Base in INR){" "}
                  <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  value={formData1.baseAmountINR}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      baseAmountINR: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.baseAmountINR
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice Amount (Base in INR)
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  Total Invoice Amount<span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  value={formData1.totalInvoiceAmount}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      totalInvoiceAmount: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.totalInvoiceAmount
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Total Invoice Amount
                </p>
              </div>
              <div className={style.col4}></div>
            </div>
            <div className={style.border}></div>
            {/*row 5*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  IGST <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  value={formData1?.igst}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      igst: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.igst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter IGST
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  CGST <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  value={formData1.cgst}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      cgst: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.cgst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Total CGST
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  SGST <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  value={formData1.sgst}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      sgst: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.sgst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter SGST
                </p>
              </div>
              <div className={style.col4}>
                <p className={style.label}>
                  TDS <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  value={formData1?.tds}
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      tds: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.tds
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter TDS
                </p>
              </div>
            </div>
            {/*row 6*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Amount to be Received <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  value={formData1?.amountToBeReceived}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      amountToBeReceived: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData1?.amountToBeReceived
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Amount to be Received
                </p>
              </div>
              <div className={style.col2}></div>
              <div className={style.col3}></div>
              <div className={style.col4}></div>
            </div>
          </InvoiceContainer>
          {formData1.projectId === 2 ? (
            <>
              <InvoiceContainer>
                <div className={style.copy_expence}>Milestone</div>
                {formData1?.invoiceLineItemDetails?.map((item, index) => {
                  return (
                    <>
                      <div className={style.Container} key={index}>
                        <div className={style.col12_item}>
                          <p className={style.label_item}>S.N.</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].sno = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]?.sno
                            }
                          />
                        </div>
                        <div className={style.col3_item}>
                          <p className={style.label_item}>Description</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].description = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.description
                            }
                          />
                        </div>
                        <div className={style.col4_item}>
                          <p className={style.label_item}>Project Value</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].projectValue = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.projectValue
                            }
                          />
                        </div>
                        <div className={style.col5_item}>
                          <p className={style.label_item}>Payment Percentage</p>
                          <input
                            type="text"
                            className={style.smallInput1}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].paymentPercentage = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.paymentPercentage
                            }
                          />
                        </div>
                        <div className={style.col6_item}>
                          <p className={style.label_item}>Timeframe</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].timeframe = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.timeframe
                            }
                          />
                        </div>
                        <div className={style.col7_item}>
                          <p className={style.label_item}>Price</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].price = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]?.price
                            }
                          />
                        </div>
                        <div className={style.col7_item}>
                          <p className={style.label_item}>Price</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].price = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]?.price
                            }
                          />
                        </div>
                        <div className={style.col8_item}>
                          {formData1?.invoiceLineItemDetails?.length > 1 && (
                            <>
                              <FaTrash
                                className={style.remove_icon}
                                size={20}
                                onClick={(e) => {
                                  setLineItemArray(
                                    lineItemArray.filter((_, i) => {
                                      return i !== index;
                                    })
                                  );
                                  setFormData({
                                    ...formData1,
                                    invoiceLineItemDetails:
                                      formData1?.invoiceLineItemDetails?.filter(
                                        (_, i) => i !== index
                                      ),
                                  });
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className={style.lastBtn_item}>
                  <InvoiceButton
                    style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                    onClick={() => {
                      // push new value 2 in array
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      setFormData1({
                        ...formData1,
                        invoiceLineItemDetails: [
                          ...formData1?.invoiceLineItemDetails,
                          {
                            sno: "",
                            description: "",
                            projectValue: "",
                            paymentPercentage: "",
                            timeframe: "",
                            price: "",
                          },
                        ],
                      });
                    }}
                  >
                    Add
                  </InvoiceButton>
                </div>
              </InvoiceContainer>
              <div className={style.lastBtn}>
                <InvoiceButton
                  type={"cancel"}
                  onClick={() => {
                    setNextScreen("invoiceList");
                    setPrevData((prevData) => ({
                      ...prevData,
                      page2: null,
                      page3: null,
                      page4: null,
                      active: "invoiceList",
                    }));
                  }}
                >
                  Cancel
                </InvoiceButton>
                <InvoiceButton onClick={CreateInvoiceHandler}>
                  Create
                </InvoiceButton>
              </div>
            </>
          ) : (
            <>
              <InvoiceContainer>
                <div className={style.copy_expence}>Line Item Details</div>
                {formData1?.invoiceLineItemDetails?.map((item, index) => {
                  return (
                    <>
                      <div className={style.Container} key={index}>
                        <div className={style.col12_item}>
                          <p className={style.label_item}>Candidate Name</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].candidateName = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.candidateName
                            }
                          />
                          <p
                            className={
                              validate && !item.candidateName
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Candidate Name
                          </p>
                        </div>
                        <div className={style.col2_item}>
                          <p className={style.label_item}>Invoice Month</p>

                          <Select
                            styles={InvoiceStyles}
                            classNamePrefix={"create_position"}
                            components={{
                              DropdownIndicator: () => CustomInput(),
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].invoiceMonth = e?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={GetMonthoption?.find(
                              (e) =>
                                e.value ===
                                formData1?.invoiceLineItemDetails[index]
                                  .invoiceMonth
                            )}
                            options={GetMonthoption}
                          />
                          <p
                            className={
                              validate && !item.invoiceMonth
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Invoice Month
                          </p>
                        </div>
                        <div className={style.col3_item}>
                          <p className={style.label_item}>Invoice Year</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].invoiceYear = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.invoiceYear
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                          <p
                            className={
                              validate && !item.invoiceYear
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Invoice Year
                          </p>
                        </div>
                        <div className={style.col4_item}>
                          <p className={style.label_item}>Billing Rate</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].billingRate = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.billingRate
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                          <p
                            className={
                              validate && !item.billingRate
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter Billing Rate
                          </p>
                        </div>
                        <div className={style.col5_item}>
                          <p className={style.label_item}>Working Days</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].workingDays = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.workingDays
                            }
                          />
                          <p
                            className={
                              validate && !item.workingDays
                                ? style.errorText
                                : style.errorTextHidden
                            }
                          >
                            Please Enter WorkingDays
                          </p>
                        </div>
                        <div className={style.col6_item}>
                          <p className={style.label_item}>Max Working Days</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].maxWorkingDay = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.maxWorkingDay
                            }
                          />
                        </div>
                        <div className={style.col7_item}>
                          <p className={style.label_item}>Base Amount</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData1?.invoiceLineItemDetails];
                              temp[index].baseAmount = e?.target?.value;
                              setFormData1({
                                ...formData1,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData1?.invoiceLineItemDetails[index]
                                ?.baseAmount
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                        </div>
                        <div className={style.col8_item}>
                          {formData1?.invoiceLineItemDetails?.length > 1 && (
                            <>
                              <FaTrash
                                className={style.remove_icon}
                                size={20}
                                onClick={(e) => {
                                  setLineItemArray(
                                    lineItemArray.filter((_, i) => {
                                      return i !== index;
                                    })
                                  );
                                  setFormData({
                                    ...formData1,
                                    invoiceLineItemDetails:
                                      formData1?.invoiceLineItemDetails?.filter(
                                        (_, i) => i !== index
                                      ),
                                  });
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className={style.lastBtn_item}>
                  <InvoiceButton
                    style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                    onClick={() => {
                      // push new value 2 in array
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      setFormData1({
                        ...formData1,
                        invoiceLineItemDetails: [
                          ...formData1?.invoiceLineItemDetails,
                          {
                            amountOverriden: "",
                            amountToBePaid: "",
                            baseAmount: "",
                            billingRate: "",
                            calculativeBaseAmount: "",
                            candidateName: "",
                            daysInMonth: "",
                            workingDays: "",
                          },
                        ],
                      });
                    }}
                  >
                    Add
                  </InvoiceButton>
                </div>
              </InvoiceContainer>
              <div className={style.lastBtn}>
                <InvoiceButton
                  type={"cancel"}
                  onClick={() => {
                    setNextScreen("invoiceList");
                    setPrevData((prevData) => ({
                      ...prevData,
                      page2: null,
                      page3: null,
                      page4: null,
                      active: "invoiceList",
                    }));
                  }}
                >
                  Cancel
                </InvoiceButton>
                <InvoiceButton onClick={CreateInvoiceHandler}>
                  Create
                </InvoiceButton>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {" "}
          <InvoiceContainer>
            <div className={style.copy_expence}>Invoice Details</div>
            {/*row 1*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Project Type <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      projectId: e?.value,
                    });
                  }}
                  options={projectOption}
                />
                <p
                  className={
                    validate && !formData?.projectId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Project Type
                </p>
              </div>
              <div className={style.col3}></div>
              <div className={style.col4}></div>
            </div>
            {/*row 2*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Invoice No <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      invoiceNumber: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.invoiceNumber
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice No
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Invoice Date <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData?.invoiceDate
                        ? moment(formData?.invoiceDate).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        invoiceDate: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData?.invoiceDate ? (
                        <CustomInputCross properties="invoiceDate" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData?.invoiceDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice Date
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  Due Date <span style={{ color: "red" }}>*</span>
                </p>
                <div className={style.cu_field23Last}>
                  <DatePicker
                    placeholder=" DD MM YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    selected={
                      formData?.dueDate
                        ? moment(formData?.dueDate).toDate()
                        : null
                    }
                    onChange={(date) => {
                      setFormData({
                        ...formData,
                        dueDate: moment(date).format(),
                      });
                    }}
                    dateFormat="dd MMM yyyy"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    customInput={
                      formData?.dueDate ? (
                        <CustomInputCross properties="dueDate" />
                      ) : (
                        <CustomInputClg />
                      )
                    }
                  />
                </div>
                <p
                  className={
                    validate && !formData?.dueDate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Due Date
                </p>
              </div>
              <div className={style.col4}>
                <p className={style.label}>
                  Advance Status <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      advanceStatusId: e.value,
                    });
                  }}
                  options={statusOption}
                />
                <p
                  className={
                    validate && !formData?.advanceStatusId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Advance Status
                </p>
              </div>
            </div>
            <div className={style.border}></div>
            {/*row 3*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Currency <span style={{ color: "red" }}>*</span>
                </p>
                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      currencyId: e.value,
                    });
                  }}
                  options={currencyOption}
                />
                <p
                  className={
                    validate && !formData?.currencyId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Currency
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Conversion Rate <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      conversionRate: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.conversionRate
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Conversion Rate
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  GSTN <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="text"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      gstNumber: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.gstNumber
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter GSTN
                </p>
              </div>
              <div className={style.col4}></div>
            </div>
            {/*row 4*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Invoice Amount (Base) <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      baseAmountInvoice: e.target.value
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.baseAmountInvoice
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice Amount (Base)
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  Invoice Amount (Base in INR){" "}
                  <span style={{ color: "red" }}>*</span>{" "}
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      baseAmountINR: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.baseAmountINR
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Invoice Amount (Base in INR)
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  Total Invoice Amount <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      totalInvoiceAmount: e.target.value
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }

                />
                <p
                  className={
                    validate && !formData?.totalInvoiceAmount
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Total Invoice Amount
                </p>
              </div>
              <div className={style.col4}></div>
            </div>
            <div className={style.border}></div>
            {/*row 5*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  IGST <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      igst: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.igst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter IGST
                </p>
              </div>
              <div className={style.col2}>
                <p className={style.label}>
                  CGST<span style={{ color: "red" }}>*</span>{" "}
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      cgst: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.cgst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter CGST
                </p>
              </div>
              <div className={style.col3}>
                <p className={style.label}>
                  SGST <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      sgst: e.target.value,
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.sgst
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter SGST
                </p>
              </div>
              <div className={style.col4}>
                <p className={style.label}>
                  TDS <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      tds: e.target.value,
                    });
                  }}
                />
                <p
                  className={
                    validate && !formData?.tds
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter TDS
                </p>
              </div>
            </div>
            {/*row 6*/}
            <div className={style.Container}>
              <div className={style.col12}>
                <p className={style.label}>
                  Amount to be Received <span style={{ color: "red" }}>*</span>
                </p>
                <input
                  type="number"
                  className={style.smallInput}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      amountToBeReceived: e.target.value
                    });
                  }}
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  onKeyDown={(e) =>
                    (e.keyCode === 38 ||
                      e.keyCode === 40 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.preventDefault()
                  }
                  onKeyPress={(e) =>
                    ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                      e.which > 57 ||
                      e.key === "e" ||
                      e.key === "-") &&
                    e.prevent
                  }
                />
                <p
                  className={
                    validate && !formData?.amountToBeReceived
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Enter Amount to be Received
                </p>
              </div>
              <div className={style.col2}></div>
              <div className={style.col3}></div>
              <div className={style.col4}></div>
            </div>
          </InvoiceContainer>
          {/* Line Item Details Container */}
          {formData?.projectId === 2 ? (
            <>
              <InvoiceContainer>
                <div className={style.copy_expence}>Milestone</div>
                {/*row 1*/}
                {formData?.invoiceLineItemDetails?.map((item, index) => {
                  return (
                    <>
                      <div className={style.Container} key={index}>
                        <div className={style.col12_item}>
                          <p className={style.label_item}>S.N.</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].sno = e?.target?.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={formData?.invoiceLineItemDetails[index]?.sno}
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                        </div>
                        <div className={style.col3_item}>
                          <p className={style.label_item}>Description</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].description = e?.target?.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.description
                            }
                          />
                        </div>
                        <div className={style.col4_item}>
                          <p className={style.label_item}>Project Value</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].projectValue = e?.target?.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.projectValue
                            }
                          />
                        </div>
                        <div className={style.col5_item}>
                          <p className={style.label_item}>Payment Percentage</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].paymentPercentage = e?.target?.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.paymentPercentage
                            }
                          />
                        </div>
                        <div className={style.col6_item}>
                          <p className={style.label_item}>Timeframe</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].timeframe = e?.target?.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]?.timeframe
                            }
                          />
                        </div>
                        <div className={style.col7_item}>
                          <p className={style.label_item}>Price</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].price = e?.target?.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]?.price
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                        </div>
                        <div className={style.col8_item}>
                          {formData?.invoiceLineItemDetails?.length > 1 && (
                            <>
                              <FaTrash
                                className={style.remove_icon}
                                size={20}
                                onClick={(e) => {
                                  setLineItemArray(
                                    lineItemArray.filter((_, i) => {
                                      return i !== index;
                                    })
                                  );
                                  setFormData({
                                    ...formData,
                                    invoiceLineItemDetails:
                                      formData?.invoiceLineItemDetails?.filter(
                                        (_, i) => i !== index
                                      ),
                                  });
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className={style.lastBtn_item}>
                  <InvoiceButton
                    style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                    onClick={() => {
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      setFormData({
                        ...formData,
                        invoiceLineItemDetails: [
                          ...formData?.invoiceLineItemDetails,
                          {
                            sno: "",
                            description: "",
                            projectValue: "",
                            paymentPercentage: "",
                            timeframe: "",
                            price: "",
                          },
                        ],
                      });
                    }}
                  >
                    Add
                  </InvoiceButton>
                </div>
              </InvoiceContainer>
              <div className={style.lastBtn}>
                <InvoiceButton
                  type={"cancel"}
                  onClick={() => {
                    setNextScreen("invoiceList");
                    setPrevData((prevData) => ({
                      ...prevData,
                      page2: null,
                      page3: null,
                      page4: null,
                      active: "invoiceList",
                    }));
                  }}
                >
                  Cancel
                </InvoiceButton>
                <InvoiceButton onClick={CreateInvoiceHandler}>
                  Create
                </InvoiceButton>
              </div>
            </>
          ) : (
            <>
              <InvoiceContainer>
                <div className={style.copy_expence}>Line Item Details</div>
                {/*row 1*/}
                {formData?.invoiceLineItemDetails?.map((item, index) => {
                  return (
                    <>
                      <div className={style.Container} key={index}>
                        <div className={style.col12_item}>
                          <p className={style.label_item}>Candidate Name</p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].candidateName = e?.target?.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.candidateName
                            }
                          />
                        </div>
                        <div className={style.col2_item}>
                          <p className={style.label_item}>Invoice Month</p>

                          <Select
                            styles={InvoiceStyles}
                            classNamePrefix={"create_position"}
                            components={{
                              DropdownIndicator: () => CustomInput(),
                              IndicatorSeparator: () => null,
                            }}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].invoiceMonth = e?.label;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            options={GetMonthoption}
                          />
                        </div>
                        <div className={style.col3_item}>
                          <p className={style.label_item}>Invoice Year</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].invoiceYear = e.target.value
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.invoiceYear
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                        </div>
                        <div className={style.col4_item}>
                          <p className={style.label_item}>Billing Rate</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].billingRate = e.target.value
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.billingRate
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                        </div>
                        <div className={style.col5_item}>
                          <p className={style.label_item}>Working Days</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].workingDays = e.target.value
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.workingDays
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                        </div>
                        <div className={style.col6_item}>
                          <p className={style.label_item}>Max Working Days</p>
                          <input
                            type="number"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].maxWorkingDay =
                                e.target.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.maxWorkingDay
                            }
                            min="0"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) =>
                              (e.keyCode === 38 ||
                                e.keyCode === 40 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.preventDefault()
                            }
                            onKeyPress={(e) =>
                              ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                                e.which > 57 ||
                                e.key === "e" ||
                                e.key === "-") &&
                              e.prevent
                            }
                          />
                        </div>
                        <div className={style.col7_item}>
                          <p className={style.label_item}>
                            Base Amount <span style={{ color: "red" }}>*</span>
                          </p>
                          <input
                            type="text"
                            className={style.smallInput}
                            onChange={(e) => {
                              let temp = [...formData?.invoiceLineItemDetails];
                              temp[index].baseAmount = e?.target?.value;
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails: temp,
                              });
                            }}
                            value={
                              formData?.invoiceLineItemDetails[index]
                                ?.baseAmount
                            }
                          />
                        </div>
                        <div className={style.col8_item}>
                          {formData?.invoiceLineItemDetails?.length > 1 && (
                            <>
                              <FaTrash
                                className={style.remove_icon}
                                size={20}
                                onClick={(e) => {
                                  setLineItemArray(
                                    lineItemArray.filter((_, i) => {
                                      return i !== index;
                                    })
                                  );
                                  setFormData({
                                    ...formData,
                                    invoiceLineItemDetails:
                                      formData?.invoiceLineItemDetails?.filter(
                                        (_, i) => i !== index
                                      ),
                                  });
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}

                <div className={style.lastBtn_item}>
                  <InvoiceButton
                    style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                    onClick={() => {
                      // push new value 2 in array
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      setFormData({
                        ...formData,
                        invoiceLineItemDetails: [
                          ...formData?.invoiceLineItemDetails,
                          {
                            amountOverriden: "",
                            amountToBePaid: "",
                            baseAmount: "",
                            billingRate: "",
                            calculativeBaseAmount: "",
                            candidateName: "",
                            daysInMonth: "",
                            workingDays: "",
                          },
                        ],
                      });
                    }}
                  >
                    Add
                  </InvoiceButton>
                </div>
              </InvoiceContainer>
              <div className={style.lastBtn}>
                <InvoiceButton
                  type={"cancel"}
                  onClick={() => {
                    setNextScreen("invoiceList");
                    setPrevData((prevData) => ({
                      ...prevData,
                      page2: null,
                      page3: null,
                      page4: null,
                      active: "invoiceList",
                    }));
                  }}
                >
                  Cancel
                </InvoiceButton>
                <InvoiceButton onClick={CreateInvoiceHandler}>
                  Create
                </InvoiceButton>
              </div>
            </>
          )}
          <Modal
            show={show}
            centered
            classNames={{
              modal: "customModal",
            }}
          >
            <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
              Do you want to add client ?
            </h6>
            <p className={style.label_item1}>
              Client Name <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              className={style.smallInput1}
              onChange={(e) => setAddClient(e.target.value)}
            />
            <button className={style.buttonAdd1} onClick={handleDelete}>
              Yes
            </button>
            <button className={style.buttonCancle} onClick={handleDeleteCancle}>
              No
            </button>
          </Modal>
        </>
      )}
    </>
  );
};

export default CreateInvoice;
