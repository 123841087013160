import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import cx from "classnames";
import Header from "../../../Grievence Portal/Components/Header/Header";
import Sidebar from "../../../Grievence Portal/Components/Sidebar/Sidebar";
import { addQuery } from "../../slices/query";
import { addComment } from "../../slices/comment";
import styles from "./RaiseTicket.module.css";
import "react-datepicker/dist/react-datepicker.css";

const RaiseTicket = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [department, setDepartment] = useState("");
  const [category, setCategory] = useState("");
  const [plannedEndDate, setPlannedEndDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [addRequestStatus, setAddRequestStatus] = useState("idle");

  const departmentAssigneeMapping = {
    "Lorem ipsum": "Robert Percival",
    "Ipsum dolor": "Silena Rogers",
    "Dolor sit": "Karl Benz",
    "Sit amet": "Netra Mohan",
    "Amet lorem": "Sashikant Mishra",
  };

  let email = localStorage.getItem("email");
  let name = localStorage.getItem("name");


  const handleSubmit = async (event) => {
    event.preventDefault();
    const newTicketData = {
      status: 0,
      comments: [],
      feedback: null,
      requestorId: email,
      assigneeName: name,
      username: name,
      updaterId: email,
      updaterName: name,
      department: "Department 6",
      email: email,
      category: "Query 1",
      plannedEndDate: "15/02",
      description: "Good",
    };
    try {
      setAddRequestStatus("pending");
      const resultAction = await dispatch(
        addQuery({ queryData: newTicketData })
      );
      unwrapResult(resultAction);
      setDisplaySuccess(true);
      // window.setTimeout(() => {
      //     history.push(`/view-ticket/${resultAction.payload._id}`);

      // }, 1000);
    } catch (err) {
      console.error("Failed to save the query: ", err);
    } finally {
      setAddRequestStatus("idle");
      if (notes !== "") {
        dispatch(
          addComment({
            ticketId: 44807122000,
            noteId: 0,
            viewData: {
              reportedDate: "15 Jul",
              userName: "Crisatine Greene",
              note: notes,
            },
          })
        );
      }
    }
  };

  const handleDepartment = (event) => {
    setDepartment(event.target.value);
  };

  const handleCategory = (event) => {
    setCategory(event.target.value);
  };

  const handlePlannedEndDate = (date) => {
    setPlannedEndDate(date);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleNotes = (event) => {
    setNotes(event.target.value);
  };

  return (
    <div className={styles.maincontainer}>
      <Header />
      <div className={styles.mainSection}>
        <Sidebar />
        <div className={styles.container}>
          <div>
            <span
              className={cx(
                styles.font14,
                styles.fontMedium,
                styles.colorBlack
              )}
            >
              Dashboard {">"}
            </span>
            <span
              className={cx(styles.fontSemibold, styles.colorShamrock)}
              style={{ color: "#41CBB6" }}
            >
              {" "}
              Raise Ticket{" "}
            </span>
          </div>
          <div className={cx(styles.rowFlex, styles.spaceBetween)}>
            <div
              className={cx(
                styles.font21,
                styles.fontSemibold,
                styles.colorBlack
              )}
            >
              {" "}
              Raise Ticket{" "}
            </div>
          </div>
          {displaySuccess ? (
            <div
              className={styles.colFlex}
              style={{
                margin: "0.5em 0em",
                borderRadius: "9px",
                backgroundColor: "#41cbb6",
              }}
            >
              <span
                className={cx(
                  styles.font14,
                  styles.fontMedium,
                  styles.colorWhite
                )}
                style={{ padding: "0.5em" }}
              >
                Ticket added successfully!
              </span>
            </div>
          ) : (
            <React.Fragment />
          )}

          <form onSubmit={handleSubmit}>
            <div className={styles.formContainer}>
              <div className={styles.leftColumn}>
                <div className={styles.colFlex}>
                  <label
                    className={cx(
                      styles.font16,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity
                    )}
                  >
                    Department
                  </label>
                  <div
                    className={cx(styles.selectContainer, styles.arrowDropdown)}
                  >
                    <select
                      name="department"
                      value={department}
                      onChange={handleDepartment}
                      className={styles.input}
                      required
                    >
                      <option value=""></option>
                      <option value="Lorem ipsum">Lorem ipsum</option>
                      <option value="Ipsum dolor">Ipsum dolor</option>
                      <option value="Dolor sit">Dolor sit</option>
                      <option value="Sit amet">Sit amet</option>
                      <option value="Amet lorem">Amet lorem</option>
                    </select>
                  </div>
                </div>
                <label
                  className={cx(
                    styles.font16,
                    styles.fontMedium,
                    styles.colorBlack,
                    styles.labelOpacity
                  )}
                >
                  Category
                </label>
                <div
                  className={cx(styles.selectContainer, styles.arrowDropdown)}
                >
                  <select
                    name="category"
                    value={category}
                    onChange={handleCategory}
                    className={styles.input}
                    required
                  >
                    <option value=""></option>
                    <option value="Lorem">Lorem</option>
                    <option value="Ipsum">Ipsum</option>
                    <option value="Dolor">Dolor</option>
                    <option value="Sit">Sit</option>
                    <option value="Amet">Amet</option>
                  </select>
                </div>
                <label
                  className={cx(
                    styles.font16,
                    styles.fontMedium,
                    styles.colorBlack,
                    styles.labelOpacity
                  )}
                >
                  Turnaround time
                </label>
                <div
                  className={cx(
                    styles.selectContainer,
                    styles.calendarDropdown
                  )}
                >
                  <div className={styles.datePicker}>
                    <DatePicker
                      selected={plannedEndDate}
                      onChange={handlePlannedEndDate}
                      minDate={new Date()}
                      maxDate={addDays(new Date(), 14)}
                      className={styles.input}
                    />
                  </div>
                </div>
              </div>
              <div className={cx(styles.rightColumn, styles.spaceBetween)}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label
                    className={cx(
                      styles.font16,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity
                    )}
                  >
                    Query Description
                  </label>
                  <textarea
                    value={description}
                    onChange={handleDescription}
                    className={styles.textArea}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <label
                    className={cx(
                      styles.font16,
                      styles.fontMedium,
                      styles.colorBlack,
                      styles.labelOpacity
                    )}
                  >
                    Notes
                  </label>
                  <textarea
                    value={notes}
                    onChange={handleNotes}
                    className={styles.textArea}
                  />
                </div>
              </div>
            </div>
            <div className={styles.buttonRow}>
              <div>
                <button
                  type="submit"
                  className={cx(
                    styles.button,
                    styles.font18,
                    styles.fontMedium,
                    styles.colorWhite
                  )}
                  style={{ cursor: "pointer" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RaiseTicket;
