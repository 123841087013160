import React, { useState } from "react";
import style from "./CreateQuestionnaireCom.module.css";
import { customStyles } from "../../CommonComponent/style";
import Select from "react-select";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory } from "react-router";
import dropdownIcon from "../../../../assets/downIcon.png";
import { useEffect } from "react";
import { Toastify, ToastifyError } from "../../../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";

export const CreateQuestionnaireCom = () => {
  const [clientListing, setClientListing] = useState([]);
  const [technologyListing, setTechnologyListing] = useState([]);
  const [experienceRange, setExperienceRange] = useState([]);
  const [seniority, setSeniority] = useState([]);
  const [btnDisable, setButtonDisable] = useState(false);
  const [validate, setValidate] = useState(false);
  const [clientDropDownOpen, setClientDropdownOpen] = useState(true);
  const [seniorityDropDownOpen, setSeniorityDropdownOpen] = useState(true);
  const [skillDropDownOpen, setSkillDropdownOpen] = useState(true);
  const [clientName, setClientName] = useState();
  const [skillName, setSkillName] = useState();
  const [experience, setExperience] = useState();
  const [experienceDropDownOpen, setExperienceDropdownOpen] = useState(true);
  const [formData, setFormData] = useState({
    clientId: 0,
    empId: localStorage.getItem("employeeId"),
    questions: "",
    technologyId: 0,
    seniorityId: 0,
    minExperience: 0,
    maxExperience: 0,
    submissionDate: new Date(),
  });

  const handleChange = (field, value) => {
    if (field === "clientId") {
      setFormData({
        ...formData,
        [field]: value,
        technologyId: 0,
      });
    } else {
      setFormData({
        ...formData,
        [field]: value,
      });
    }
  };

  // base URL
  const url = process.env.REACT_APP_QUESTIONNAIRE_URL;
  const url1 = process.env.REACT_APP_ATS_APP_URL;

  const history = useHistory();

  // client listing api ointegrate
  const getClientListing = () => {
    axios
      .get(url1 + "/get-client-for-questionnaire", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch((err) => {});
  };

  const getSeniorityList = () => {
    axios
      .get(url + "/getSeniority", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setSeniority(res.data.responseData);
      })
      .catch((err) => {});
  };
  // technology listing api ointegrate
  const getTechnologyListing = (id) => {
    axios
      .get(url1 + `/get-client-skills?clientId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setTechnologyListing(res.data.responseData);
      })
      .catch((err) => {});
  };
  const getExperiencerange = (clientId, skillId) => {
    axios
      .get(
        url1 +
          `/get-experience-by-client-id?clientId=${clientId}&skillId=${skillId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setExperienceRange(res.data.responseData);
      })
      .catch((err) => {});
  };
  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getSeniorityListt = seniority?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getTechList = technologyListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getExpRange = experienceRange?.map((i) => ({
    value: `${i.minExperience}-${i.maxExperience}`,
    label: `${i.minExperience}-${i.maxExperience}years`,
  }));

  useEffect(() => {
    getClientListing();
    if (formData?.clientId) {
      getTechnologyListing(formData?.clientId);
    }
    if (formData?.technologyId) {
      getExperiencerange(formData?.clientId, formData?.technologyId);
      getSeniorityList();
    }
  }, [formData?.clientId, formData?.technologyId]);

  // raisedToList?.sort((a, b) =>
  //   a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  // );
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="amg"
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  // Handle Redirect After Successfull Addedd Question
  const handleRedirect = (data) => {
    setTimeout(() => {
      history.push({
        pathname: "/ViewQuestionnaire",
        state: data,
      });
    }, 3000);
  };

  // API Integration
  // cancel Button Handler

  const cancelHandler = () => {
    history.push("/QuestionList");
  };
  // formHAndler

  const formHAndler = (e) => {
    e.preventDefault();
    if (!formData?.seniorityId || !formData?.questions) {
      setValidate(true);
      return true;
    }
    axios
      .post(url + "/createQuestions", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        Toastify("Question Added Successfully");
        handleRedirect(res.data.responseData);
        setButtonDisable(true);
      })
      .catch((err) => {
        return err;
      });
  };

  return (
    <>
      <ToastContainer />
      <div className={style.main}>
        <p className={style.createissue}>Add Questionnaire</p>
        <div>
          <div className={style.card}>
            <div className={style.Cu_row1}>
              {!formData?.maxExperience ? (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Select Client<span className={style.mandatory}>* </span>
                  </p>
                  <Select
                    styles={customStyles}
                    menuIsOpen={clientDropDownOpen}
                    onMenuOpen={() => setClientDropdownOpen(true)}
                    onMenuClose={() => setClientDropdownOpen(false)}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={getClientList}
                    onChange={(e) => {
                      handleChange("clientId", e?.value);
                      setClientName(e?.label);
                      setSkillDropdownOpen(true);
                    }}
                  ></Select>
                </div>
              ) : (
                <div className={style.cu_col2}>
                  <p className={style.cu_label}>
                    Client Name<span className={style.mandatory}>* </span>
                  </p>
                  <p>{clientName}</p>
                </div>
              )}

              {!formData?.minExperience ? (
                <div
                  className={style.cu_col1}
                  style={{
                    visibility: formData?.clientId ? "visible" : "hidden",
                  }}
                >
                  <p className={style.cu_label}>
                    Skill<span className={style.mandatory}>* </span>
                  </p>
                  <Select
                    styles={customStyles}
                    onMenuOpen={() => setSkillDropdownOpen(true)}
                    menuIsOpen={skillDropDownOpen}
                    onMenuClose={() => setSkillDropdownOpen(false)}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      formData?.technologyId
                        ? {
                            value: formData?.technologyId,
                            label: skillName,
                          }
                        : null
                    }
                    options={getTechList}
                    onChange={(e) => {
                      handleChange("technologyId", e?.value);
                      setSkillName(e?.label);
                    }}
                  ></Select>
                  <p
                    className={
                      validate && !formData?.technologyId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Skill
                  </p>
                </div>
              ) : (
                <div
                  className={style.cu_col1}
                  style={{
                    visibility: formData?.clientId ? "visible" : "hidden",
                  }}
                >
                  <p className={style.cu_label}>
                    Skill<span className={style.mandatory}>* </span>
                  </p>
                  <p>{skillName}</p>
                </div>
              )}
              {!formData?.minExperience ? (
                <div
                  className={style.cu_col3}
                  style={{
                    visibility: formData?.technologyId ? "visible" : "hidden",
                  }}
                >
                  <p className={style.cu_label}>
                    Experience<span className={style.mandatory}>* </span>
                  </p>
                  <Select
                    menuIsOpen={experienceDropDownOpen}
                    onMenuClose={() => setExperienceDropdownOpen(false)}
                    styles={customStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    options={getExpRange}
                    onChange={(e) => {
                      setExperience(e?.label);
                      const selectedValue = e?.value; // Assuming the selected value is "3-5"
                      const [minExp, maxExp] = selectedValue.split("-"); // Splitting the value into min and max
                      setFormData({
                        ...formData,
                        minExperience: minExp,
                        maxExperience: maxExp,
                      });
                    }}
                  ></Select>
                </div>
              ) : (
                <div
                  className={style.cu_col3}
                  style={{
                    visibility: formData?.technologyId ? "visible" : "hidden",
                  }}
                >
                  <p className={style.cu_label}>
                    Experience<span className={style.mandatory}>* </span>
                  </p>
                  <p>{`${formData?.minExperience}-${formData?.maxExperience} years`}</p>
                </div>
              )}

              <div
                className={style.cu_col3}
                style={{
                  visibility: formData?.minExperience ? "visible" : "hidden",
                }}
              >
                <p className={style.cu_label}>
                  Seniority<span className={style.mandatory}>* </span>
                </p>
                <Select
                  menuIsOpen={seniorityDropDownOpen}
                  onMenuOpen={() => setSeniorityDropdownOpen(true)}
                  onMenuClose={() => setSeniorityDropdownOpen(false)}
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  options={getSeniorityListt}
                  onChange={(e) => {
                    handleChange("seniorityId", e?.value);
                  }}
                ></Select>
                <p
                  className={
                    validate && !formData?.seniorityId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Seniority
                </p>
              </div>
            </div>
            <div
              className={style.cu_col1}
              style={{
                marginLeft: "21px",
                visibility: formData?.minExperience ? "visible" : "hidden",
              }}
            >
              <p className={style.cu_label}>
                Interview Questions<span className={style.mandatory}>*</span>
              </p>
              <textarea
                name="postContent"
                rows={2}
                onChange={(e) => handleChange(`questions`, e.target.value)}
                className={style.textArea}
              />
              <p
                className={
                  validate && !formData?.questions
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Interview Questions
              </p>
            </div>
          </div>
          <div
            className={style.buttonDiv}
            style={{
              marginLeft: "21px",
              visibility: formData?.minExperience ? "visible" : "hidden",
            }}
          >
            <button
              className={style.cancelbtn}
              onClick={() => {
                cancelHandler();
              }}
            >
              Cancel
            </button>
            <button
              className={style.submitbtn}
              disabled={btnDisable}
              onClick={formHAndler}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* <Modal
        open={OptionModal}
        center
        onClose={() => {
          setOptionModal((prevOptionModal) => !prevOptionModal);
        }}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.confirmPopup}>
          <p className={style.cp_label2}>
            Add Technology <span className={style.mandatory}>*</span>
          </p>

          <input
            className={style.cp_field}
            type="text"
            onChange={(e) => setTechnologyname(e.target.value)}
          />
          <p
            className={
              validate1 && !technologyName
                ? style.errorText1
                : style.errorTextHidden
            }
          >
            Please Enter Technology Name
          </p>

          <div className={style.btnCont}>
            <button className={style.yesBtn} onClick={() => AddTechnology()}>
              Submit
            </button>
          </div>
        </div>
      </Modal> */}
    </>
  );
};
