import React, { useEffect, useState } from "react";
import Header from "../../../../taskTracker/commonComponent/Header";
//import SideBar from "../../Clients/component/SideBar";
// import Sidebar from "../../Sidebar/Sidebar"
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import Sidebar from "../../../Clients/component/SideBar";
import style from "./createProjectMilestone.module.css";
import milestone from "./../../../../../../src/assets/milestone.png";
import { Tab, Table } from "react-bootstrap";
import { borderRadius } from "@mui/system";
import axios from "axios";
//import TableRow from '../../Clients/component/TableRow';
import CreateIcon from "@mui/icons-material/Create";

const CreateProjectMilestone = () => {
  const [mileStoneData, setMileStoneData] = useState([]);

  const getProjectMilestone = () => {
    axios
      .get("http://localhost:8083/resourceMaster/v1/getMilestone")
      .then((response) => {
        // console.log("response", response);
        setMileStoneData(response.data.responseData);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getProjectMilestone();
  }, []);

  console.log(mileStoneData);

  const [state, setState] = useState({
    actualEndDate: "",
    comment: "",
    milestoneNumber: 0,
    milestoneStatus: "",
    milestoneTitle: "",
    name: "",
    plannedEndDate: "",
    projectId: 0,
    review: "",
    startDate: "",
  });

  const onInputChange = (e) => {
    if (e.target.name === "projectId") {
      setState({
        ...state,
        projectId: parseInt(e.target.value),
      });
    } else if (e.target.name === "application") {
      setState({
        ...state,
        name: e.target.value,
      });
    } else if (e.target.name === "startDate") {
      setState({
        ...state,
        startDate: e.target.value,
      });
    } else if (e.target.name === "plannedEndDate") {
      setState({
        ...state,
        plannedEndDate: e.target.value,
      });
    } else if (e.target.name === "title") {
      setState({
        ...state,
        milestoneTitle: e.target.value,
      });
    }
  };

  const onSubmit = () => {
    const data = {
      actualEndDate: "2022-03-31T05:09:52.039Z",
      comment: "string",
      milestoneNumber: 0,
      milestoneStatus: "Delay",
      milestoneTitle: state.milestoneTitle,
      name: state.name,
      plannedEndDate: "2022-03-31T05:09:52.039Z",
      projectId: state.projectId,
      review: "string",
      startDate: "2022-03-31T05:09:52.039Z",
    };
    console.log(data);

    axios
      .post("http://localhost:8083/resourceMaster/v1/createMilestone", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId : localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res);
        getProjectMilestone();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <Sidebar />
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "0px 0px" }}>
              <span>
                Project <span className={style.arrow}> {">"}</span>{" "}
                <span className={style.recent_tab}>Create Project</span>
              </span>
            </div>

            <div className={style.second_child}>
              <div style={{ padding: "0px 0px", fontWeight: "500" }}>
                Create Project
              </div>
            </div>
          </div>

          <div
            className={style.table_div_body }
            style={{ backgroundColor: "white" }}
          >
            <div className={style.header}>
              <Link
                to="/projects"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className={style.content}>Project Information</div>
              </Link>
              <Link
                to="/resource"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className={style.content}>Resources</div>
              </Link>
              <div
                className={style.content}
                style={{
                  borderBottom:"5px solid rgb(252, 187, 8)",
                  // color: "rgb(252, 187, 8)",
                   borderRadius:"10px",
          
                }}
              >
                Milestones
              </div>
            </div>

            <div className={style.form_div} style={{ padding: "10px" }}>
              <div className={style.form_first_div}>
                <div className={style.form_content}>
                  <span>Project ID</span>
                  <div className={style.input_box_div}>
                    <input
                     className={style.input}
                      type="number"
                      name="projectId"
                      style={{ width: "90%" }}
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className={style.form_content}>
                  <span>Application</span>
                  <div className={style.input_box_div}>
                    <input
                        className={style.input}
                      type="text"
                      name="application"
                      style={{ width: "90%" }}
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className={style.form_content}>
                  <span>Start Date</span>
                  <div className={style.input_box_div}>
                    <input
                     className={style.input}
                      type="date"
                      name="startDate"
                      style={{ width: "90%" }}
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className={style.form_content}>
                  <span> Planned End Date</span>
                  <div className={style.input_box_div}>
                    <input
                     className={style.input}
                      type="date"
                      name="plannedEndDate"
                      style={{ width: "90%" }}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className={style.form_second_div}>
                <div className="milestone_title" style={{ width: "50%" }}>
                  <span>Milestone Title</span>
                  <div>
                    <textarea
                     className={style.text}
                      name="title"
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div
                  className="milestone_deliverables"
                  style={{ width: "50%" }}
                >
                  <span>Milestone Deliverables</span>
                  <div>
                    <textarea

                      name="deliverables"
                      className={style.text}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={style.btn_div}>
              <button  onClick={onSubmit} style={{height:"45px",width:"135px",borderRadius:"10px",marginRight:"25px"}}>
                Add Milestone
              </button>
            </div>

            <div>
              {mileStoneData.length <= 0 ? (
                <div className={style.noData}>
                  <img src={milestone} />
                  <h3 className={style.noData_header}>
                    {"Milestone is not Created Yet"}
                  </h3>
                </div>
              ) : (
                <Table
                  borderless
                  size="sm"
                  style={{ fontSize: "12px", marginTop: "10px" }}
                >
                  <thead style={{ backgroundColor: "#f5f5f5" }}>
                    <tr style={{ padding: "100px" }}>
                      <th style={{ padding: "15px" }}>Application</th>
                      <th style={{ padding: "15px" }}>Start Date</th>
                      <th style={{ padding: "15px" }}>Planned End Date</th>
                      <th style={{ padding: "15px" }}>Milestone Title</th>
                      <th style={{ padding: "15px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody style={{ overflow: "scroll", height: "80px" }}>
                    {mileStoneData.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ padding: "2px 15px" }}>{val.name}</td>
                          <td style={{ padding: "2px 15px" }}>
                            {val.startDate}
                          </td>
                          <td style={{ padding: "2px 15px" }}>
                            {val.plannedEndDate}
                          </td>
                          <td style={{ padding: "2px 15px" }}>
                            {val.milestoneTitle}
                          </td>
                          <td style={{ padding: "2px 15px" }}>
                            <CreateIcon />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProjectMilestone;
