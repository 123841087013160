import React,{useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import styles from "./successModal.module.css"
const SuccessModal = ({show, handleClose}) => {
   
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName={styles.modalWidth}>
        <Modal.Header style={{borderBottom: "none"}} closeButton>
        
        </Modal.Header>
        <Modal.Body style={{padding:"30px 0px"}}>
            
        <div className={styles.dFJCC}>
        <CheckCircleIcon style={{fontSize:"150px",color:"#2dbe79"}}/>    
       </div>   
       <span className={styles.modalext}>Your Device has been <br/> </span> 
       <span className={styles.modalext}>added successfully <br/> </span>
       
        </Modal.Body>
    
      </Modal>
    </>
  )
}

export default SuccessModal