import React, { useState, useEffect } from "react";
import style from "./EditResourceOffShore.module.css";
import Sidebar from "../../Clients/component/SideBar";
import cookie from "react-cookies";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import calendar from "../../../../assets/Grievance portal/dateldpi.png";
import constants from "../../../../Utils/constants";
import dropdownIcon from "../../../../assets/downIcon.png";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { customStylesForRMS } from "../../Clients/component/style";
import CustomAutoComplete from "../../../taskTracker/commonComponent/AutoComplete";

const EditResourcesOffshore = () => {
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref} style={{ paddingTop: "-5px" }}>
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "25%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const CustomInput1 = () => {
    return (
      <span>
        <img
        alt="dropdownIcon"
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  const [disable, setDisable] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const history = useHistory();
  const [state, setState] = useState([]);
  const [applications, setApplications] = useState([]);
  const [empId, setEmpID] = useState();
  const [allocationId, setAllocationId] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);

  const [arrayObj, setarrayObj] = useState([]);
  const [allocationTypeList, setAllocationTypeList] = useState([]);

  const [selectedvalue, setSelectedvalue] = useState();
  const [employeeId, setEmployeeId] = useState("");
  const [changeRequestById, setChangeRequestedById] = useState(null);
  const [projectApplication, setProjectApplication] = useState([]);
  const [validate, setValidate] = useState(false);

  employeeList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const id = query.get("id");
  const baseUrl = constants.apiBaseUrlResource;

  const url = constants.apiBaseUrl;

  const getEmployeeList = () => {
    axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setEmployeeList(res.data.responseData.employeeList);
        setarrayObj(
          employeeList.map((item) => {
            return {
              label: item.name,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const getSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setSkillList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllocationType = () => {
    axios
      .get(baseUrl + "/getAllocationType", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setAllocationTypeList(res.data.responseData.allocationType.slice(0, 2));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectSkill = () => {
    axios
      .get(baseUrl + "/getProjectforFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setProjectList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAddResourceDetails = () => {
    axios
      .get(baseUrl + `/getOffshoreResourceDetailsById?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setEmployeeName(res.data.responseData.empName);
        setState(res.data.responseData);
        setEmployeeId(res.data.responseData.empid);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ProjectApplication = (id) => {
    axios
      .get(baseUrl + `/getApplicationNameByProjectId?projectId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log({ res });
        setProjectApplication(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAddResourceDetails();
    getProjectSkill();
    getEmployeeList();
    getSkills();
    getAllocationType();
    state?.projectid && ProjectApplication(state?.projectid);
  }, [state?.projectid]);

  const handleChange = (field, value) => {
    setState({ ...state, [field]: value });
  };

  const onInputChange = (e) => {
    if (e.target.name === "projectSkill") {
      setState({
        ...state,
        skill: e.target.value,
      });
    } else if (e.target.name === "projectName") {
      setState({
        ...state,
        projectid: e.target.value,
      });
    } else if (e.target.name === "Allocation") {
      setState({
        ...state,
        bandwidth: e.target.value,
      });
    } else if (e.target.name === "AllocationType") {
      setState({
        ...state,
        allocationTypeId: e.target.value,
      });
    } else if (e.target.name === "application") {
      setState({
        ...state,
        applicationId: e.target.value,
      });
    }
  };

  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/resourceManagement");
    }, 2000);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (!changeRequestById) {
      setValidate(true);
      return;
    }
    const data = {
      allocationDate: Moment(state.allocationdate).format("YYYY-MM-DD hh:mm"),
      bandwidth: state.bandwidth,
      deallocationDate: Moment(state.deallocationdate).format(
        "YYYY-MM-DD hh:mm"
      ),
      empId: employeeId,
      applicationId: state.applicationId,
      projectId: state.projectid,
      skill: state.skill,
      id: id,
      changeRequestedById: changeRequestById,
      allocationType: state.allocationTypeId,
    };
    console.log(data, "data");
    axios
      .put(baseUrl + "/editOffshoreResource", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseData.message === "No bandwidth available") {
          notify1();
        } else if (
          res.data.responseData.message === "Employee is already allocated"
        ) {
          notify2();
        } else {
          setDisable(true);
          notify3();
          handleRedirect();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const EndDateChange = (date) => {
    setState({
      ...state,
      deallocationdate: Moment(date).format("D MMM YY"),
    });
  };

  const StartDateChange = (date) => {
    setState({
      ...state,
      allocationdate: Moment(date).format("D MMM YY"),
    });
  };

  const allocation = [
    { name: "25%", allocationPercent: "OneFourth" },
    { name: "50%", allocationPercent: "Half" },
    { name: "75%", allocationPercent: "ThreeFourth" },
    { name: "100%", allocationPercent: "Full" },
  ];

  const getemployeeList = employeeList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getskillList = skillList?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getallocation = allocation?.map((i) => ({
    value: i.allocationPercent,
    label: i.name,
  }));

  const getprojectApplication = projectApplication?.map((i) => ({
    value: i.applicationId,
    label: i.applicationName,
  }));

  const getallocationTypeList = allocationTypeList?.map((i) => ({
    value: i.id,
    label: i.allocationType,
  }));
  const notify1 = () =>
    toast("No bandwidth available", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notify2 = () =>
    toast("Already allocated on this project", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notify3 = () =>
    toast("Data Submitted Successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  return (
    <>
      <div className={style.main_client_body}>
        <Sidebar />

        <form className={style.table_div} onSubmit={onSubmit}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div style={{ padding: "10px 0px", fontWeight: "700" }}>
              Edit Resource (Offshore)
            </div>
            <div className={style.form_div}>
              {allocationId && allocationId !== "Full" && employeeName ? (
                <p
                  style={{
                    color: "red",
                    paddingBottom: "10px",
                    paddingLeft: "13px",
                  }}
                >
                  You can allocate {employeeName}{" "}
                  {allocationId === "OneFourth"
                    ? `${100 - 25}% only`
                    : allocationId === "Half"
                    ? `${100 - 50}% only`
                    : allocationId === "ThreeFourth"
                    ? `${100 - 75}% only`
                    : allocationId === "Zero"
                    ? `${100}%`
                    : null}
                </p>
              ) : allocationId && allocationId === "Full" && employeeName ? (
                <p
                  style={{
                    color: "red",
                    paddingLeft: "13px",
                    paddingBottom: "10px",
                  }}
                >
                  {" "}
                  You can't allocate {employeeName} because bandwidth is not
                  available{" "}
                </p>
              ) : null}
              <div id="create_task_id" className={style.gridContainer}>
                <div className={style.Cu_row1}>
                  <div className={style.cu_col1}>
                    <p
                      style={{ paddingBottom: "8px" }}
                      className={style.cu_label}
                    >
                      Employee Name
                    </p>
                    <CustomAutoComplete
                      className={style.cu_field2}
                      autocomplete="100%"
                      placeholder="Search by Employee Name"
                      disabled={true}
                      style={{ marginLeft: "5px", fontWeight: 800 }}
                      value={employeeName}
                    />
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Project</p>
                    <select
                      onChange={(e) => {
                        onInputChange(e);
                        ProjectApplication(e.target.value);

                        setApplications([]);
                      }}
                      name="projectName"
                      className={style.cu_field2}
                      disabled={true}
                    >
                      <option hidden>{state?.projectName}</option>
                      {projectList.map((val) => {
                        return <option value={val.id}>{val?.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className={style.cu_col3}>
                    <p
                      className={style.cu_label}
                      style={{ paddingLeft: "5px" }}
                    >
                      Application
                    </p>

                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () =>CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      value={
                        state.applicationId
                          ? {
                              value: state.applicationId,
                              label: state.applcationName,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setState({
                          ...state,
                          applicationId: e.value,
                          applcationName: e.label,
                        });
                      }}
                      options={getprojectApplication}
                    />
                  </div>
                </div>
                <div className={style.Cu_row1} style={{ marginTop: "10px" }}>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Technology</p>

                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () =>CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      value={
                        state.skillName
                          ? {
                              value: state.skill,
                              label: state.skillName,
                            }
                          : null
                      }
                      // onChange={(e) => handleChange(`skill`, e?.value)}
                      onChange={(e) => {
                        setState({
                          ...state,
                          skill: e.value,
                          skillName: e.label,
                        });
                      }}
                      options={getskillList}
                    />
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>% Allocation</p>

                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      value={
                        state.bandwidth
                          ? {
                              value: state.bandwidth,
                              label:
                                state.bandwidth === "Half"
                                  ? "50%"
                                  : state.bandwidth === "ThreeFourth"
                                  ? "75%"
                                  : state.bandwidth === "OneFourth"
                                  ? "25%"
                                  : "100%",
                            }
                          : null
                      }
                      components={{
                        DropdownIndicator: () =>CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => handleChange(`bandwidth`, e?.value)}
                      options={getallocation}
                    />
                  </div>
                  <div className={style.cu_col3}>
                    <p
                      className={style.cu_label}
                      style={{ paddingLeft: "5px" }}
                    >
                      Type of Allocation
                    </p>
                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      value={
                        state.allocationTypeId
                          ? {
                              value: state.allocationTypeId,
                              label: state.allocationTypeName,
                            }
                          : null
                      }
                      components={{
                        DropdownIndicator: () =>CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setState({
                          ...state,
                          allocationTypeId: e.value,
                          allocationTypeName: e.label,
                        });
                      }}
                      options={getallocationTypeList}
                    />
                  </div>
                </div>

                <div className={style.Cu_row1} style={{ marginTop: "14px" }}>
                  <div className={style.cu_col1}>
                    <p className={style.cu_label}>Project Start Date</p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        startDate={null}
                        value={Moment(state?.allocationdate).format("D MMM YY")}
                        onChange={(date) => StartDateChange(date)}
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>Project End Date</p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        startDate={null}
                        value={Moment(state.deallocationdate).format(
                          "D MMM YY"
                        )}
                        onChange={(date) => EndDateChange(date)}
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>
                      Change Requested By<span style={{ color: "red" }}>*</span>
                    </p>
                    <Select
                      styles={customStylesForRMS}
                      classNamePrefix={"create_position"}
                      placeholder="Search By Client Name"
                      components={{
                        DropdownIndicator: () =>CustomInput1(),
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e) => {
                        setChangeRequestedById(e.value);
                      }}
                      options={getemployeeList}
                    />
                    <p
                      className={
                        validate && !changeRequestById
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Select Change Requested By
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={style.btn_div}>
            <button
              type="submit"
              className={style.submitbtn}
              disabled={disable}
            >
              Update
            </button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </>
  );
};

export default EditResourcesOffshore;
