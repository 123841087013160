import React from "react";
import AssignMobileComponent from "./component/AssignMobileComponent";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "../mobiles/mobiles.module.css";

const AssignLaptop = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <AssignMobileComponent />
      </div>
    </div>
  );
};

export default AssignLaptop;
