import React from "react";
import Modal from "react-responsive-modal";
import style from "./FilterInvoice.module.css";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearch } from "../utils/style";
import {
  getClientName,
  getServiceType,
  getAdvanceStatus,
  getAmountToBeReceived,
  getPaymentStatus,
  getPaymentDate,
} from "../../../Utils/axios/expenceApi.js";

import Select from "react-select";
import "./style.css";
import { useQuery } from "react-query";

function FilterInvoice({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  firstCardlist,
  secondCardlist,
  thirdCard,
  thirdCardlist,
  forthCard,
  forthCardlist,
  fifthCard,
  fifthCardList,
  sixthCard,
  sixthCardList,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
  sevenCard,
  eightCard,
}) {
  const CustomInput = () => {
    return (
      <img
        src={searchIcon}
        style={{
          position: "absolute",
          top: "0.6rem",
          right: "0.4rem",
          width: "18px",
        }}
        alt=""
      />
    );
  };

  const { data: clientData } = useQuery(["getClientName"], getClientName);
  const clientOption = clientData?.map((e) => ({
    value: e.id,
    label: e.clientName,
  }));
  const { data: ServiceType } = useQuery(["getServiceType"], getServiceType);
  const ServiceTypeData = ServiceType?.map((e) => ({
    value: e.id,
    label: e.serviceType,
  }));
  const { data: AdvanceStatus } = useQuery(
    ["getAdvanceStatus"],
    getAdvanceStatus
  );
  const AdvanceStatusData = AdvanceStatus?.map((e) => ({
    value: e.id,
    label: e.status,
  }));
  const { data: AmountToBeReceived } = useQuery(
    ["getAmountToBeReceived"],
    getAmountToBeReceived
  );
  const AmountToBeReceivedData = AmountToBeReceived?.map((e) => ({
    value: e.amount,
    label: e.amount,
  }));
  const { data: PaymentStatusData } = useQuery(
    ["getPaymentStatus"],
    getPaymentStatus
  );
  const PaymentStatus = PaymentStatusData?.map((e) => ({
    value: e.id,
    label: e.status,
  }));

  const { data: getPaymentDateData } = useQuery(
    ["getPaymentDate"],
    getPaymentDate
  );
  const PaymentDate = getPaymentDateData?.map((e) => ({
    value: e.id,
    label: e.paymentDate,
  }));

  const handleInputValue = (e, status) => {
    setFilterData((preData) => ({
      ...preData,
      projectId: e.value,
      projectValue: e.label,
    }));
  };

  const handleChnageAdvanceStatus = (e) => {
    setFilterData((preData) => ({
      ...preData,
      advanceStausId: e.value,
      advanceStausValue:e.label
    }));
  };

  const handleClientName = (e) => {
    setFilterData((preData) => ({
      ...preData,
      clientId: e.value,
      ClientValue:e.label
    }));
  };

  const handleAmountToBeReceived = (e) => {
    setFilterData((preData) => ({
      ...preData,
      amountToBeReceived: e.value,
      // amountToBeReceivedValue:e.value
    }));
  };
  const handlePaymentStatus = (e) => {
    setFilterData((preData) => ({
      ...preData,
      paymentStatus: e.value,
      paymentStatusValue:e.label
    }));
  };

  const handlePaymentDate = (e) => {
    setFilterData((preData) => ({
      ...preData,
      paymentDate: e.value,
      paymentDateValue:e.label
    }));
  };

  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: style.reactmodal,
        }}
      >
        <div className={style.filterModal}>
          <div className={style.filterCards}>
            {firstCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{firstCard}</div>
                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                 { filterData?.projectId === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    projectId: "",
                  });
                  setFilterData({...filterData,projectId:""})
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    // components={{
                    //   DropdownIndicator: () =>
                    //     selectedOption ? CustomCross() : CustomInput(),
                    //   IndicatorSeparator: () => null,
                    // }}
                    value={
                      filterData?.projectId
                        ? {
                            value: filterData?.projectId,
                            label: filterData?.projectValue,
                          }
                        : null
                    }
                    
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => handleInputValue(e)}
                    options={ServiceTypeData}
                    // placeholder={"Search Employee..."}
                  />
                </div>
              </div>
            )}
            {forthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{forthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                   { filterData?.amountToBeReceived === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    amountToBeReceived: "",
                  });
                  setFilterData({...filterData,amountToBeReceived:""})
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    // value={selectedOption}
                    // components={{
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => handleAmountToBeReceived(e)}
                    // placeholder={"Search Employee..."}
                    value={
                      filterData?.amountToBeReceived
                        ? {
                            value: filterData?.amountToBeReceived,
                            label: filterData?.amountToBeReceived,
                          }
                        : null
                    }
                    options={AmountToBeReceivedData}
                  />
                </div>
              </div>
            )}

            {secondCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{secondCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  { filterData?.clientId === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    clientId: "",
                  });
                  setFilterData({...filterData,clientId:""})
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    // value={selectedOption}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => handleClientName(e)}
                    options={clientOption}
                    // placeholder={"Search Employee..."}
                    value={
                      filterData?.clientId
                        ? {
                            value: filterData?.clientId,
                            label: filterData?.ClientValue,
                          }
                        : null
                    }
                  />
                </div>
              </div>
            )}
            {thirdCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{thirdCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                 { filterData?.advanceStausId === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    advanceStausId: "",
                  });
                  setFilterData({...filterData,advanceStausId:""})
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    // value={selectedOption}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => handleChnageAdvanceStatus(e)}
                    options={AdvanceStatusData}
                    // placeholder={"Search Employee..."}
                    value={
                      filterData?.advanceStausId
                        ? {
                            value: filterData?.advanceStausId,
                            label: filterData?.advanceStausValue,
                          }
                        : null
                    }
                  />
                </div>
              </div>
            )}
            {fifthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{fifthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                 { filterData?.paymentDate === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    paymentDate: "",
                  });
                  setFilterData({...filterData,paymentDate:""})
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    // value={selectedOption}
                    // components={{
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => handlePaymentDate(e)}
                    options={PaymentDate}
                    // placeholder={"Search Employee..."}
                    value={
                      filterData?.paymentDate
                        ? {
                            value: filterData?.paymentDate,
                            label: filterData?.paymentDateValue,
                          }
                        : null
                    }
                  />
                </div>
              </div>
            )}
            {sixthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sixthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  { filterData?.paymentStatus === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} alt="" />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    paymentStatus: "",
                  });
                  setFilterData({...filterData,paymentStatus:""})
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    // value={selectedOption}
                    // components={{
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => handlePaymentStatus(e)}
                    options={PaymentStatus}
                    // placeholder={"Search Employee..."}
                    value={
                      filterData?.paymentStatus
                        ? {
                            value: filterData?.paymentStatus,
                            label: filterData?.paymentStatusValue,
                          }
                        : null
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
              // filterData.projectValue()
              // setFilterData("")
            }}
          >
            Clear All
          </button>
          <button className={style.createBtn} onClick={() => filterUpdateFuc()}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
}

export default FilterInvoice;
