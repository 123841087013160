import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../TDS/TDS.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import { useState } from "react";
import Select from "react-select";
import { InvoiceStyles4 } from "../../utils/style";
import dropdownIcon from "../../../../assets/downIcon.png";
import EditIcon from "../../../../assets/Icon material-edit_.png";
import { useMutation, useQuery } from "react-query";
import {
  getViewPartner,
  getTdsList,
  AddTds,
  getTdsDetailsList,
  TdsViewByPartnerId,
} from "../../../../Utils/axios/PortalManagement";
import { useEffect } from "react";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";

const TDS = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const { data: getTdsListData } = useQuery(["getTdsList"], getTdsList);

  const getTdsListDataoption = getTdsListData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const [lavelChange, setLavelChage] = useState();
  const dataitem = getTdsListDataoption?.find(
    (option) => option?.label === "10%"
  );

  const [formData, setFormData] = useState({
    effectiveDateTo: "",
    effectiveFrom: "",
    partnerId: prevData?.TDSID,
    rate: "",
    tdsId: dataitem,
  });

  useEffect(() => {
    setFormData((prevValue) => ({
      ...prevValue,
      effectiveDateTo: "",
      effectiveFrom: "",
      partnerId: prevData?.TDSID,
      rate: "",
      tdsId: dataitem?.value,
    }));
    setLavelChage(
      getTdsListDataoption?.find((option) => option?.label === "10%")
    );
  }, [getTdsListData]);

  const [TDSViewer, setTDSViewer] = useState(false);
  const [effectiveFromData, SeteffectiveFromData] = useState();
  const [effectiveDateTo, SeteffectiveDateTo] = useState();
  const [rateData, setRateData] = useState();
  const [tdsPercentData, setTdsPercent] = useState();
  const [EditTDSData, setEditTDsData] = useState(true);

  const {
    data: TDSView,
    isLoading1,
    isError1,
    refetch,
  } = useQuery(
    ["TdsViewByPartnerId"],
    () => TdsViewByPartnerId(prevData?.partnerId),
    {}
  );
  useEffect(() => {
    TDSView?.map((elem) => {
      SeteffectiveFromData(elem?.effectiveFrom);
      SeteffectiveDateTo(elem?.effectiveDateTo);
      setRateData(elem?.rate);
      setTdsPercent(elem?.tdsPercentId);
    });
  }, [TDSView]);

  const [formData1, setFormData1] = useState({
    effectiveDateTo: effectiveDateTo,
    effectiveFrom: effectiveFromData,
    partnerId: prevData?.TDSID,
    rate: rateData,
    tdsId: tdsPercentData,
  });

  useEffect(() => {
    setFormData1((prevValue) => ({
      ...prevValue,
      effectiveDateTo: effectiveDateTo,
      effectiveFrom: effectiveFromData,
      partnerId: prevData?.TDSID,
      rate: rateData,
      tdsId: tdsPercentData,
    }));
  }, [rateData]);

  const { data: PartnerDataById } = useQuery(
    ["getViewPartner"],
    () => getViewPartner(prevData?.TDSID),
    {}
  );

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const mutation = useMutation(AddTds, {
    onSuccess: (res) => {
      refetch();
      setFormData((prevValue) => ({
        ...prevValue,
        effectiveDateTo: "",
        effectiveFrom: "",
        rate: "",
        tdsId: "",
      }));
    },
  });

  const handlerTDS = () => {
    mutation.mutate(formData);
    setTDSViewer(true);
  };
  const handlerEditTDS = () => {
    setTDSViewer(true);
    setEditTDsData(false);
    // setFormData1((prevValue) => ({
    //   ...prevValue,
    //   effectiveDateTo: "",
    //   effectiveFrom: "",
    //   partnerId: "",
    //   rate: "",
    //   tdsId: "",
    // }));
  };

  const handlerEditTds = () => {
    mutation.mutate(formData);
    setFormData((prevValue) => ({
      ...prevValue,
      effectiveDateTo: "",
      effectiveFrom: "",
      partnerId: "",
      rate: "",
      tdsId: "",
    }));
  };
  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <InvoiceContainer>
          <Row>
            <Col>
              <h6 className={classes.primaryContact}>Partner Details</h6>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Location</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
          </Row>
        </InvoiceContainer>
        {EditTDSData ? (
          <InvoiceContainer>
            <Row>
              <Col>
                <h6 className={classes.primaryContact}>TDS Details</h6>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <p className={classes.label6}>TDS Rate</p>
                <Select
                  options={getTdsListDataoption}
                  styles={InvoiceStyles4}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={lavelChange}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      tdsId: e?.value,
                    });
                    setLavelChage(e);
                  }}
                />
              </Col>
            </Row>
            <button className={classes.buttonAdd} onClick={handlerTDS}>
              Submit
            </button>
          </InvoiceContainer>
        ) : (
          <InvoiceContainer>
            <Row>
              <Col>
                <h6 className={classes.primaryContact}>TDS Details</h6>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <p className={classes.label6}>Edit TDS Rate</p>
                <Select
                  options={getTdsListDataoption}
                  styles={InvoiceStyles4}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={lavelChange}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      tdsId: e?.value,
                    });
                    setLavelChage(e);
                  }}
                />
              </Col>
            </Row>
            <button className={classes.buttonAdd} onClick={handlerEditTds}>
              Update
            </button>
          </InvoiceContainer>
        )}

        {/* {TDSViewer ? ( */}
          <>
            <Col md={12}>
              <Row>
                <Col>
                  <h6 style={{ marginLeft: "25px", marginTop: "40px" }}>
                    TDS View
                  </h6>
                </Col>
              </Row>

              <InvoiceContainer>
                <div className={classes.table}>
                  <div className={classes.th}>
                    <p className={classes.tableContent}>TDS Rate</p>
                    <p className={classes.tableContent}>Action</p>
                  </div>
                  <div>
                    {isLoading1 && (
                      <p className={classes.no_data}>Loading .....</p>
                    )}
                    {!isLoading1 && !isError1 ? (
                      TDSView?.length > 0 ? (
                        typeof TDSView?.invoice !== "string" &&
                        TDSView?.map((item, index) => {
                          return (
                            <div className={classes.td} key={index}>
                              <p className={classes.tableContent}>
                                {item?.tdsPercent}
                              </p>
                              <p className={classes.tableContent}>
                                <img
                                  src={EditIcon}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                  onClick={handlerEditTDS}
                                />
                              </p>
                            </div>
                          );
                        })
                      ) : (
                        <p className={classes.no_data}>No Data Found</p>
                      )
                    ) : null}
                  </div>
                </div>
              </InvoiceContainer>
            </Col>
            <button
              className={classes.buttonAdd4}
              onClick={() => {
                setNextScreen("PartnerList");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: null,
                  page3: null,
                  page4: null,
                  active: "PartnerList",
                }));
              }}
            >
              Done
            </button>
          </>
        {/* ) : (
          ""
        )} */}
      </Container>
    </>
  );
};
export default TDS;
