import React, { useEffect, useState } from "react";
import style from "./QuestionmListCom.module.css";
import Select from "react-select";
import cookie from "react-cookies";
import axios from "axios";
import { useHistory } from "react-router";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearch } from "../../CommonComponent/style";
import Moment from "moment";
import Pagination from "../../CommonComponent/Pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ImLink } from "react-icons/im";
import { Tooltip } from "@material-ui/core";
import { generateRandomString } from "../../CommonComponent/GenerateRandomSreing";
const QuestionListCom = () => {
  const history = useHistory(); // base URL
  const url = process.env.REACT_APP_QUESTIONNAIRE_URL;
  const [clientListing, setClientListing] = useState([]);
  const [technologyListing, setTechnologyListing] = useState([]);
  const [clientName, setclientName] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [questionListing, setQuestionListing] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [totalQuestionnaire, setTotalQuestionnaire] = useState(0);
  const [technologyName, setTechnologyName] = useState({});
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState({
    clientId: 0,
    skillId: 0,
    maxExperience: 0,
    minExperience: 0,
    seniorityId: 0,
  });

  // onSearchHandler
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code1":
          setAllData({ ...allData, clientId: e?.value });
          setclientName({ ...clientName, positionCode: e?.label });
          break;
        case "Code2":
          setAllData({ ...allData, skillId: e?.value });
          setTechnologyName({ ...technologyName, positionCode: e?.label });
          break;
        default:
          return null;
      }
    }
  };

  let limit = 10;
  // client listing api ointegrate
  const getClientListing = () => {
    axios
      .get(url + "/getClientForDashboard", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // technology listing api ointegrate
  const getTechnologyListing = () => {
    axios
      .get(url + "/getSkillForDashboard", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setTechnologyListing(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getTechList = technologyListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  useEffect(() => {
    getClientListing();
    getTechnologyListing();
  }, []);

  const AddQuestionnaireHandler = () => {
    history.push("/AddQuestionnaire");
  };
  const ViewQuestionnaireHandler = (
    clientId,
    minExperience,
    maxExperience,
    skillId,
    seniorityId
  ) => {
    // console.log(array,"id")
    // setAllData({
    //   ...allData,
    //   clientId:clientId,
    //   skillId:0,
    //   maxExperience:maxExperience,
    //   minExperience:minExperience,
    //   seniorityId:skillId
    // })
    history.push({
      pathname: "/ViewQuestionnaire",
      state: { clientId, minExperience, maxExperience, skillId, seniorityId },
    });
  };
  const GenerateLink = (checkedBoxes) => {
    // console.log("link", checkedBoxes, clientNameSelect?.positionCode);
    let clientNameSelect = true;
    let instructionHide = true;
    history.push({
      pathname: `/viewQuestionDetailsById/${generateRandomString()}`,
      state: { clientNameSelect, checkedBoxes, instructionHide },
    });
  };

  // pagination handle
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const getAllQuestionnaireList = (allData, currentPage) => {
    axios
      .get(
        url +
          `/get-recent-submissions?clientId=${allData?.clientId}&limit=10&page=${currentPage}&skillId=${allData?.skillId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setQuestionListing(res.data.responseData.getRecentSubmissionsDataList);
        setpageCount(res.data.responseData.count / 10);
        setTotalQuestionnaire(res.data.responseData.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllQuestionnaireList(allData, currentPage);
  }, [allData, currentPage]);

  return (
    <>
      <div className={style.main}>
        <div className={style.twoFilter}>
          <div className={style.filteralign}>
            <div
              className={style.s1}

              // onClick={() => {
              //   setShowFilter(false);
              // }}
            >
              {allData?.clientId === 0 ? (
                <span className={style.cp_serchIcon2}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon2}
                  onClick={() => {
                    setAllData({
                      ...allData,
                      clientId: 0,
                    });
                    setclientName({ ...clientName, positionCode: null });
                  }}
                >
                  {allData?.clientId !== 0 ? <AiOutlineClose /> : null}
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Client Name"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  clientName?.positionCode
                    ? {
                        value: allData?.employeeId,
                        label: clientName?.positionCode,
                      }
                    : null
                }
                onChange={(e) => {
                  onSearchHandler(e, "Code1");
                  setCurrentPage(1);
                }}
                options={getClientList}
              />
            </div>
            <div
              className={style.s1}
              // onClick={() => {
              //   setShowFilter(false);
              // }}
            >
              {allData?.skillId === 0 ? (
                <span className={style.cp_serchIcon2}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon2}
                  onClick={() => {
                    setAllData({
                      ...allData,
                      skillId: 0,
                    });
                    setTechnologyName({
                      ...technologyName,
                      positionCode: null,
                    });
                  }}
                >
                  <AiOutlineClose />
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Position Skills"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  technologyName?.positionCode
                    ? {
                        value: allData?.skillId,
                        label: technologyName?.positionCode,
                      }
                    : null
                }
                onChange={(e) => {
                  onSearchHandler(e, "Code2");

                  setCurrentPage(1);
                }}
                options={getTechList}
              />
            </div>
          </div>
          <button
            className={style.addQuestionnairebtn}
            onClick={() => AddQuestionnaireHandler()}
          >
            Add Questionnaire{" "}
          </button>
        </div>
        <p className={style.pp}>Recent Submissions</p>
        {loading ? (
          <p className={style.loading}>Loading...</p>
        ) : questionListing && questionListing.length > 0 ? (
          <div className={style.QuestionList}>
            <table>
              <thead>
                <tr className={style.th1}>
                  <th className={style.tableContent2}>Submitted Date</th>
                  <th className={style.tableContent2}>Client Name</th>
                  <th className={style.tableContent2}>Position Skills</th>
                  <th className={style.tableContent2}>Position Experience</th>
                  <th className={style.tableContent2}>View</th>
                  <th className={style.tableContent2}>Link</th>
                </tr>
              </thead>
              <tbody>
                {questionListing.map((val, index) => {
                  return (
                    <tr className={style.td1} key={index}>
                      <td className={style.td1}>
                        {val?.submissionDate
                          ? Moment(val?.submissionDate).format("D MMM YY")
                          : null}
                      </td>
                      <td className={style.td1}>{val.clientName}</td>
                      <td className={style.td1}>{val.positionSkill}</td>
                      <td className={style.td1}>
                        {val?.minExperience} - {val?.maxExperience} Years
                      </td>
                      <td className={style.td2}>
                        <Tooltip
                          title="View Questionnaire"
                          arrow
                          style={{ backgroundColor: "black" }}
                        >
                          <RemoveRedEyeIcon
                            style={{ cursor: "pointer", color: "#964431" }}
                            onClick={() => {
                              ViewQuestionnaireHandler(
                                val.clientId,
                                val?.minExperience,
                                val?.maxExperience,
                                val?.positionSkillId,
                                0
                              );
                            }}
                          />
                        </Tooltip>
                      </td>
                      <td className={style.td3}>
                        <Tooltip
                          title="Generate Link"
                          arrow
                          style={{ backgroundColor: "black" }}
                        >
                          <ImLink
                            style={{
                              cursor: "pointer",
                              color: "#964431",
                              fontSize: "1.2rem",
                            }}
                            onClick={() => {
                              GenerateLink(
                                val.questionsCard?.map((e) => e?.id)
                              );
                            }}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <p className={style.noData}>No data available</p>
        )}

        {totalQuestionnaire > limit && pageCount > 0 && (
          <div
            className={style.paginationmobileContainer}
            style={{ marginTop: "20px", marginRight: "12px" }}
          >
            <Pagination
              pagesCount={pageCount}
              pageSize={limit}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionListCom;
