// import useState and useEffect hooks from React
import { useState, useEffect } from "react";

/**
 * custom hook to create persistent states i.e. states that remain conserved between refreshes/reloads (using local storage)
 * @param {string} key the unique key (label) that is used to identify various states in local storage (typically name of state)
 * @param {any} defaultValue the default value that is ascribed to the state at the time of creation
 * @returns an array of two elements: the persistent state and its corresponding setter
 */
const usePersistentState = (key, defaultValue, isSessionStorage) => {
    // fetch state and setter from local storage, or assign default value if key absent from local storage
    const [state, setState] = useState(() => (isSessionStorage ? JSON.parse(sessionStorage.getItem(key)) || defaultValue : JSON.parse(localStorage.getItem(key)) || defaultValue));
    // on changes to key or state, make corresponding changes in the local storage
    useEffect(() => {
        isSessionStorage ? sessionStorage.setItem(key, JSON.stringify(state)) : localStorage.setItem(key, JSON.stringify(state));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key, state]);
    // return the persistent state and its corresponding setter
    return [state, setState];
};

// export the usePersistentState hook as default
export default usePersistentState;
