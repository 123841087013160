import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import style from "../EmployeeTimeSheet/EmployeeTimeSheet.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import moment from "moment/moment";
import { useQuery } from "react-query";
import Select from "react-select";
import { customStylesERP3 } from "../../utils/style";

import Pagination from "../../CommonComponent/Pagination/Pagination.js";
import { getTimeSheetEmpId } from "../../../../Utils/axios/NewTimeSheetApi";
const EmployeeTimeSheet = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [fourWeek, setFourWeek] = useState();

  const [filter, setFilter] = useState({
    startDate: null,
  });
  const timestamp = filter?.startDate;
  const date3 = new Date(timestamp);
  const year = date3.getFullYear();
  const month = date3.getMonth() + 1;
  const day = date3.getDate();
  const dateString = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const { data, isLoading, isError } = useQuery(
    ["getTimeSheetEmpId", { currentPage, filter }],
    () =>
      getTimeSheetEmpId(
        Number(localStorage.getItem("employeeId")),
        // formData?.timesheetDate,'
        dateString,
        filter?.projectLead,
        filter?.projectName
      ),
    // {
    //   refetchOnWindowFocus: true,
    //   staleTime: 0,
    //   cacheTime: 0,
    //   refetchInterval: 1000,
    // }
  );

  const handleFourWeek = () => {
    const currentDate = new Date();
    let weeks = [];
    let today = moment(currentDate).format("DD");
    for (let i = 0; i < 4; i++) {
      const startDate = new Date(
        currentDate.getTime() - i * 7 * 24 * 60 * 60 * 1000
      );
      startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
      endDate.setHours(23, 59, 59, 999);
      let status = false;
      let data = today - moment(startDate.toISOString()).format("DD");
      if (data >= 0 && data <= 6) {
        status = true;
      }
      let newData = "- Current Week";
      weeks = [
        ...weeks,
        {
          value: `${moment(startDate.toISOString()).format("YYYY")}-${moment(
            startDate.toISOString()
          ).format("MM")}-${moment(startDate.toISOString()).format("DD")} `,
          label: `${moment(startDate.toISOString()).format("DD")} -  
              ${moment(endDate.toISOString()).format("DD")} 
              ${moment(endDate.toISOString()).format("MMM")} ${
            status ? newData : ""
          }`,
        },
      ];
    }

    setFourWeek(weeks);
  };

  useEffect(() => {
    handleFourWeek();
  }, []);
  useEffect(() => {
    fourWeek?.length > 0 &&
      setFilter((prevData) => ({
        ...prevData,
        startDate: fourWeek[0]?.value,
      }));
  }, [fourWeek]);

  const handleDate = (e) => {
    setFilter((prevData) => ({
      ...prevData,
      startDate: e?.value?.split("@")[0],
    }));
  };

  return (
    <>
      <Container>
        <div className={style.timesheetdate}>
          <Row>
            <Col md={6} style={{ marginTop: "-20px" }}>
              <span className={style.TimeSheet}>My Timesheet</span>
            </Col>
          </Row>
        </div>
      </Container>
      <Container>
        <Row>
          <Col md={3}>
            <Select
              styles={customStylesERP3}
              classNamePrefix={"create_position"}
              placeholder="Select Week"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              value={
                filter?.startDate
                  ? fourWeek?.find((e) => e.value === filter?.startDate)
                  : null
              }
              onChange={(e) => handleDate(e)}
              options={fourWeek}
            />
          </Col>
          <Col md={9}>
            <button
              className={style.buttonAdd}
              onClick={() => {
                setNextScreen("AddTaskDetails");
                setPrevData((prevData) => ({
                  ...prevData,
                  timesheetDate: "",
                }));
              }}
            >
              <span className={style.plus}>+</span>
              Fill Timesheet
            </button>
          </Col>
        </Row>
      </Container>

      <InvoiceContainer>
        <div className={style.table}>
          <div className={style.th}>
            <p className={style.tableContent}>Task Date</p>
            <p className={style.tableContent}>Time Spent</p>
            <p className={style.tableContent}>Status</p>
          </div>
          <div>
            {isLoading && <p className={style.no_data}>Loading .....</p>}

            {!isLoading && !isError ? (
              data?.length > 0 ? (
                typeof data !== "string" &&
                data?.map((item, index) => {
                  console.log("item", item);
                  const minutes = item?.timeSpent;
                  const hours = Math.floor(minutes / 60);
                  const remainingMinutes = minutes % 60;
                  const date = new Date(item?.taskDate);
                  const options = {
                    year: "2-digit",
                    month: "short",
                    day: "numeric",
                  };
                  const formattedDate1 = date.toLocaleDateString(
                    "en-GB",
                    options
                  );
                  return (
                    <div className={style.td}>
                      <p
                        className={style.tableContent}
                        onClick={() => {
                          setNextScreen("ViewTask");
                          setPrevData((prevData) => ({
                            ...prevData,
                            page2: "ViewTask",
                            page3: null,
                            page4: null,
                            active: "ViewTask",
                            timesheetId: Number(
                              localStorage.getItem("employeeId")
                            ),
                            timesheetDate: item?.taskDate,
                          }));
                        }}
                      >
                        {formattedDate1}
                      </p>

                      <p className={style.tableContent}>
                        {hours === 0
                          ? `${remainingMinutes} Min`
                          : `${hours} Hrs ${remainingMinutes} Min`}
                      </p>

                      {item?.status === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.status}
                        </p>
                      ) : item?.status === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.status}
                        </p>
                      ) : item?.status === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.status}
                        </p>
                      ) : (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "black",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          -
                        </p>
                      )}
                    </div>
                  );
                })
              ) : (
                <p className={style.no_data}>No Data Found</p>
              )
            ) : null}
          </div>
        </div>
        <></>
      </InvoiceContainer>

      <div className={style.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={data?.count / 12}
          color="##855ea1"
          color2="white"
        />
      </div>
    </>
  );
};
export default EmployeeTimeSheet;
