import React, { useEffect, useState } from "react";
import Header from "../../../../taskTracker/commonComponent/Header";
import style from "./OffshoreHistory.module.css";
import { useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import TableRow from "./TableRow";
import SideBar from "../../../Clients/component/SideBar";
import cookie from "react-cookies";
import constants from "../../../../../Utils/constants";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useLocation } from "react-router-dom";
import Pagination from "../../../CommonComponent/Pagination";
import loaderImg from "../../../../../assets/loader.gif";

export default function OffshoreHistory() {
  const history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;
  const [HistoryData, sethistoryList] = useState([]);
  const [projectCategoryForFilter, setProjectCategoryForFilter] = useState([])
  const [projectForFilter, setProjectForFilter] = useState([]);
  const [clientForFilter, setClientForFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalProject, settotalProject] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [applied, setApplied] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);


  const [projectCategory, setProjectCategory] = useState([]);
  const [client, setClient] = useState([]);
  const [project, setProject] = useState([]);
  const [filterName, setFilterName] = useState({});
  const[searchData,setSearchData]=useState()
  const [allData, setAllData] = useState({
    catogaryprojectId: projectCategory,
    clientId: client,
    projectId: project,
    searchName:""
  })


  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const [FilterData, setFilterData] = useState({
    catogaryprojectId: [],
    clientId: [],
    projectId: []
  })
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");

  projectForFilter?.push(...clientForFilter);

  const getPositionCodeForFilterOption = projectForFilter?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const goBack = () => {
    history.goBack(-1)
  }

  const getClientForFilter = () => {
    axios
      .get(baseUrl + "/getClientDataForFilterOfProject", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log("data", res.data.responseData);
        setClientForFilter(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getProjectTypeForFilter = () => {
    axios
      .get(baseUrl + "/getProjectTypes", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      }
      )
      .then((res) => {
        console.log(res.data.responseData)
        setProjectCategoryForFilter(res.data.responseData)

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectForFilter = () => {
    axios
      .get(baseUrl + "/getProjectforFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log("data", res.data.responseData);
        setProjectForFilter(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getProjectData = (clearData,currentPage) => {
    const filterData = clearData?.clientId?.length == 0 ? clearData : allData
    setIsLoaded(true)
    axios
      .get(baseUrl + '/getOffshoreResourceHistoryDetails?' + 'limit=' + limit + '&page=' + currentPage+`&resourceId=`+id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        sethistoryList(res.data.responseData);
        settotalProject(res.data.responseData.resourceCount);
        setpageCount(~~(res.data.responseData.resourceCount/10+1));
        setIsLoaded(false)
      })
      .catch((err) => {
        setIsLoaded(false)
        console.log(err);
      });

  };

  
  const limit = 10;
  const handlePageChange = (page,status) => {
    
    if(status=="next"){
      setCurrentPage(page+1);
    }else if(status=="prev"){
    setCurrentPage(page-1)
    }
   
  };


  const onSearchHandler = (e, option) => {
    setSearchData(e.target.value)
    if (e || e === null) {
      switch (option) {
        case "Name":
          setAllData({ ...allData, searchName:e.target.value });
          // setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
  };
}


  
  const closeFilter = () => {
    setAllData((preValue) => {
      return ({
        ...preValue,
        catogaryprojectId: [],
        clientId: [],
        projectId: []
      })
    })
    getProjectData(FilterData,1);
    setProjectCategory([]);
    setClient([]);
    setProject([]);
    setShowFilter(false);
    setApplied(false);
  };

  const handleApplied = (arg) => {
    if (projectCategory.length !== 0 || client.length !== 0 || project.length !== 0) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1)
    getProjectData(allData,1);
  };

  useEffect(() => {
    getProjectData(allData,currentPage);
    getProjectTypeForFilter();
    getClientForFilter();
    getProjectForFilter();
  }, [currentPage,allData]);




  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />
        {/* Table Div Content */}
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              {/* <span>
                Dashboard{">"}{" "}
                <span className={style.recent_tab}>Project</span>
              </span> */}
            </div>

            <div className={style.second_child}>
              <div style={{ padding: "10px 0px", fontWeight: "700",width:"30%", }}>
                View Offshore  Resource History
              </div>
              <span className={style.back_btn} style={{ cursor: "pointer",marginLeft:"39rem" }} onClick={goBack}>
            <ArrowBackIcon />
              Back</span>
            </div>
          </div>

          <div
            className={style.original_table}
            style={{ backgroundColor: "white", height:"27.5rem"}}
          >
            {isLoaded ? <div><img src={loaderImg} alt="loader" style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }} className={style.loader}
            />
              <p style={{ fontSize: "24px", fontWeight: "bold",paddingLeft:"38%" ,position: "absolute", color: "#FBBB08", marginTop: "-4px", fontStyle: "italic", marginLeft: "34.5%" }}>Loading...</p>
            </div> :
            HistoryData.getAllData ? (

                <Table borderless size="sm" style={{ fontSize: "12px" }}>
                  <thead style={{ backgroundColor: "#F0F0F0", position: 'sticky', top: '0px' }}>
                    <tr style={{ padding: "150px" }}>
                      <th style={{ padding: "25px" }}>Date Modified</th>
                      <th style={{ padding: "25px" }}>Change By</th>
                      <th style={{ padding: "25px" }}>Change Requested By </th>
                      <th style={{ padding: "25px" }}>Fields</th>
                      <th style={{ padding: "25px" }}>Changes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {HistoryData?.getAllData.map((val) => {
                      return (
                        <TableRow
                          modifiedDate={val.modifiedDate}
                          changeBy={val.changeBy}
                          fields={val.fields}
                          changedDescription={val.changedDescription}
                          resourceId={val.resourceId}
                          changeReqBy={val.changeRequestedBy}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              ) : null}
            {/* (
                <div className={style.noData}>
                  <img src={milestone} />
                  <h3 className={style.noData_header}>
                    No data available
                  </h3>
                </div>
              )} */}
          </div>
          {/* <Link to="/clientdescription">Click</Link> */}
          {totalProject > limit && pageCount > 0 && <div className={style.paginationmobileContainer} style={{marginTop: "20px" }}>
            <Pagination
              pagesCount={pageCount}
              pageSize={limit}
              onPageChange={handlePageChange}
              currentPage={currentPage}
            />
          </div>}
        </div>
      </div>
 

    </>
  );
}