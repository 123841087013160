import axios from "axios";
import { resolve } from "./index";

const queryAPI = {
  addQuery: async (userId, queryData) => {
    return await resolve(
      axios
        .post(
          `${process.env.REACT_APP_GRIEVANCE_APP_URL}/query/addQuery`,
          queryData
        )
        .then((res) => res.data)
    );
  },

  viewAllQueries: async (isAdmin) => {
    return await resolve(
      axios
        .get(
          `${process.env.REACT_APP_GRIEVANCE_APP_URL}/query/viewAllQueries`,
          { params: { isAdmin } }
        )
        .then((res) => res.data)
    );
  },

  viewSentQueries: async (userId) => {
    return await resolve(
      axios
        .get(
          `${process.env.REACT_APP_GRIEVANCE_APP_URL}/query/viewSentQueries`,
          { params: { userId } }
        )
        .then((res) => res.data)
    );
  },

  viewReceivedQueries: async (queryData) => {
    return await resolve(
      axios
        .post(
          `${process.env.REACT_APP_GRIEVANCE_APP_URL}/query/viewReceivedQueries`,
          { queryData }
        )
        .then(
          (res) => res.data
          
        )
    );
  },

  viewQuery: async (userId, queryId) => {
    return await resolve(
      axios
        .get(`${process.env.REACT_APP_GRIEVANCE_APP_URL}/query/viewQuery`, {
          data: { queryId },
          params: { userId },
        })
        .then((res) => res.data)
    );
  },

  editSentQuery: async (userId, queryData) => {
    return await resolve(
      axios
        .post(
          `${process.env.REACT_APP_GRIEVANCE_APP_URL}/query/editSentQuery`,
          { queryData }
        )
        .then((res) => res.data)
    );
  },

  editReceivedQuery: async (userId, queryData) => {
    return await resolve(
      axios
        .post(
          `${process.env.REACT_APP_GRIEVANCE_APP_URL}/query/editReceivedQuery`,
          queryData
        )
        .then((res) => res.data)
    );
  },

  deleteSentQuery: async (userId, queryId) => {
    return await resolve(
      axios
        .delete(
          `${process.env.REACT_APP_GRIEVANCE_APP_URL}/query/deleteSentQuery`,
          { data: { queryId }, params: { userId } }
        )
        .then((res) => res.data)
    );
  },
};

export default queryAPI;
