import React, { useEffect } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import style from "../ViewTaskDetails/ViewTaskDetails.module.css";

import { useMutation } from "react-query";
import { DeleteTimeSheet } from "../../../../Utils/axios/NewTimeSheetApi";
import Edit from "../../../../assets/Icon material-edit.png";
import Delete from "../../../../assets/delete-icon.png";
import CustomTooltip from "../../../NewEmployeeTimeSheet/customTooltip/CustomTooltip.js";
import CustomTooltipDiv from "../../../NewEmployeeTimeSheet/customTooltip/CustomTooltip.js";

import { useState } from "react";
import AddIcon from "../../../../assets/Add_notes.png";
import axios from "axios";
const baseURL = process.env.REACT_APP_NEW_TIMESHEET_URL;

const ViewTaskDetails = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [deleteId, setdeleteId] = useState();
  const [show1, setShow1] = useState(false);
  const [taskSummaryData, setTaskSummaryData] = useState();
  const currentDate = new Date();
  const timesheetDate = currentDate.toISOString().replace("Z", "+05:30");
  const formattedDate = timesheetDate.substring(0, 10);
  const [data, setData] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [isError, setisError] = useState(true);
  const [formData, setFormData] = useState({
    timesheetDate: formattedDate,
  });

  const [show, setShow] = useState(false);
  const originalDate = new Date(prevData?.timesheetDate);
  const day = originalDate.getDate();
  const month = originalDate.toLocaleString("default", { month: "short" });
  const year = originalDate.getFullYear().toString().slice(-2);
  const convertedDate = `${day} ${month} ${year}`;

  const getTimesheetId = (timeId, empId) => {
    axios
      .get(`${baseURL}/view-task-details?date=${timeId}&empId=${empId}`)
      .then((res) => {
        setData(res?.data?.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ViewData = () => {
    setTimeout(() => {
      getTimesheetId(prevData?.timesheetDate, prevData?.timesheetId);
    }, 300);
  };

  useEffect(() => {
    ViewData();
  }, []);

  const mutation2 = useMutation(DeleteTimeSheet, {
    onSuccess: () => {
      ViewData();
    },
  });
  const handleDelete = () => {
    setShow(false);
    mutation2.mutate({
      timesheetId: deleteId,
      requester: Number(localStorage.getItem("employeeId")),
    });
  };

  const handleDeleteCancle = () => {
    setShow(false);
  };
  const handleShow = (expenseId) => {
    setShow(true);
    setdeleteId(expenseId);
  };
  const handleNotApproved = () => {
    setShow1(false);
  };

  const handleShow1 = (task) => {
    setShow1(true);
    setTaskSummaryData(task);
  };
  return (
    <>
      <Container>
        <div className={style.timesheetdate}>
          <Row>
            <Col md={6}>
              <span className={style.TimeSheet}>View Task Details</span>
            </Col>
          </Row>
        </div>
        <Row style={{ marginLeft: "5px" }}>
          <Col>
            <p style={{ fontWeight: "bold" }}>{convertedDate}</p>
          </Col>
        </Row>
      </Container>

      <div className={style.ViewsheetcolorPos}>
        <p className={style.ViewEmployeeDetails}>
          {" "}
          <span>Employee Details</span>
          <button
            className={style.addMore}
            onClick={() => {
              setNextScreen("AddTaskDetails");
              setPrevData((prevData) => ({
                ...prevData,
                timesheetID: formData.timesheetDate,
              }));
            }}
          >
            Add More
          </button>
        </p>
        <Container fluid>
          <div className={style.th}>
            <p style={{ fontWeight: "700" }}>Project Name</p>
            <p style={{ fontWeight: "700" }}>Task Type</p>
            <p style={{ fontWeight: "700" }}>Name/Number</p>
            <p style={{ fontWeight: "700" }}>Time Spent</p>
            <p style={{ fontWeight: "700" }}>Task Summary</p>
            <p style={{ fontWeight: "700" }}>Lead Approval</p>
            <p style={{ fontWeight: "700" }}>Remark</p>
            <p style={{ fontWeight: "700" }}>Action</p>
          </div>
          <div>
            {isLoading && isError ? (
              data?.length > 0 ? (
                typeof data !== "string" &&
                data?.map((item, index) => {
                  const minutes = item?.timeSpent;
                  const hours = Math.floor(minutes / 60);
                  const remainingMinutes = minutes % 60;
                  return (
                    <div className={style.td}>
                      <CustomTooltip
                        value={item?.projectName}
                        limit={20}
                        index={`positionName${index}`}
                        tooltipstyle={{ left: "35%" }}
                      />
                      <p className={style.tableContent}>{item?.taskType}</p>
                     
                      <CustomTooltipDiv
                        value={item?.taskType === "API Integration"
                          ? `${item?.apiname} <-> ${item?.screenName} `
                          : item?.taskType === "Defect Fixing"
                          ? item?.defectNumber
                          : item?.taskType === "API Development"
                          ? item?.apiname
                          : item?.taskType === "UI Development"
                          ? item?.screenName
                          : ""}
                        limit={30}
                        index={`Number${index}`}
                        tooltipstyle={{ left: "55%" }}
                      />
                       
                      {/* <p>{item?.taskType === "API Integration"
                          ? `${item?.apiname} <-> ${item?.screenName} `
                          : item?.taskType === "Defect Fixing"
                          ? item?.defectNumber
                          : item?.taskType === "API Development"
                          ? item?.apiname
                          : item?.taskType === "UI Development"
                          ? item?.screenName
                          : ""}</p> */}
                      <p className={style.tableContent}>
                        {" "}
                        {hours === 0
                          ? `${remainingMinutes} Min`
                          : `${hours} Hrs ${remainingMinutes} Min`}
                      </p>

                      <p className={style.tableContent}>
                      {item?.taskSummary &&<img
                          src={AddIcon}
                          alt=""
                          style={{ cursor: "pointer", width: "15px" }}
                          onClick={() => handleShow1(item?.taskSummary)}
                        />}
                      </p>

                      {item?.plApproved === "Pending" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "#9c58a5",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.plApproved}
                        </p>
                      ) : item?.plApproved === "Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "green",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.plApproved}
                        </p>
                      ) : item?.plApproved === "Not Approved" ? (
                        <p
                          className={style.tableContent}
                          style={{
                            color: "red",
                            fontSize: "600",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.plApproved}
                        </p>
                      ) : (
                        ""
                      )}

                      <CustomTooltip
                        value={item?.remarks}
                        limit={12}
                        index={index}
                        tooltipstyle={{ left: "55%" }}
                      />

                      <p className={style.tableContent}>
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gridColumnGap: "20%",
                          }}
                        >
                          <img
                            src={Edit}
                            alt=""
                            onClick={() => {
                              setNextScreen("EditEmployeeTimeSheets");
                              setPrevData((prevData) => ({
                                ...prevData,
                                page2: "EditEmployeeTimeSheets",
                                page3: null,
                                page4: null,
                                active: "EditEmployeeTimeSheets",
                                timesheetID: item.timesheetId,
                              }));
                            }}
                            style={{ cursor: "pointer" }}
                          />{" "}
                          <img
                            src={Delete}
                            alt=""
                            onClick={() => handleShow(item?.timesheetId)}
                            style={{ cursor: "pointer" }}
                          />
                        </span>
                      </p>
                    </div>
                  );
                })
              ) : (
                <p className={style.no_data}>No Data Found</p>
              )
            ) : null}
          </div>
        </Container>
      </div>

      <button
        className={style.buttonAdd}
        onClick={() => {
          setNextScreen("empTimeSheet");
          setPrevData((prevData) => ({
            ...prevData,
            page2: "empTimeSheet",
            page3: null,
            page4: null,
            active: "empTimeSheet",
          }));
        }}
      >
        Done
      </button>
      <></>

      <Modal show={show} centered size="sm">
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to delete
        </h6>
        <button className={style.buttonAdd4} onClick={handleDelete}>
          Yes
        </button>
        <button className={style.buttonCancle4} onClick={handleDeleteCancle}>
          No
        </button>
      </Modal>
      <Modal show={show1} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Task Summary</h6>
        </Modal.Header>
        {/* <Form.Control
          as="textarea"
          value={taskSummaryData}
          disabled={true}
          rows={2}
          style={{ width: "85%", marginLeft: "24px", marginTop: "10px" }}
        /> */}
        <p style={{paddingLeft:"10px",whiteSpace: "pre-line",height:"10rem",overflowY:"auto"}}>{taskSummaryData}</p>

        <button className={style.buttonAdd3} onClick={handleNotApproved}>
          Ok
        </button>
      </Modal>
    </>
  );
};
export default ViewTaskDetails;
