import React from "react";
import style from "./InvoiceButton1.module.css";

const InvoiceButton1 = ({ children, onClick, type }) => {
  switch (type) {
    case "plus":
      return (
        <button className={style.createBtn} onClick={onClick}>
          <span> + </span>
          {children}
        </button>
      );
      case "plus1":
        return (
          <button className={style.createBtn1} onClick={onClick}>
          {children}
        </button>
        );
    case "cancel":
      return (
        <button className={style.cancelBtn} onClick={onClick}>
          {children}
        </button>
      );
    default:
      return (
        <button className={style.createBtn} onClick={onClick}>
          {children}
        </button>
      );
  }
};

export default InvoiceButton1;
