import React from 'react';
import style from './Header.module.css';
import DashboardImage from "./../../../assets/innovationm-navbar-logo.png";
import Iconmaterialmenu from '../../../assets/Iconmaterialmenu.png';
import { useHistory } from "react-router-dom";
import cookie from "react-cookies";
import logo from "../../../assets/IM logo 2x.png";
const Header = () => {
  let history = useHistory();
  const profile = cookie.load("profile");
  function onLogout() {
    cookie.remove("token");
    history.push("/");
  }
  return (
    <div className={style.header}>
      <img alt="logo" src={logo} className={style.logo} onClick={() => history.push("/dashboard")} />
      <div className={style.profileSection}>
        <img
          className={style.profileImage}
          alt={profile.givenName}
          src={profile.imageUrl}
        />
        <label className={style.logout} onClick={onLogout}>
          Logout
        </label>
      </div>
    </div>
  );
}

export default Header;