import React, { useState, useEffect } from "react";
import style from "./TaskClosedByMeComponent.module.css";
import filterimg from "./../../../../assets/filter-results-button.png";
import rubbishBinImg from "./../../../../assets/rubbish-bin.png";
import pencilEditButtonImg from "./../../../../assets/pencil-edit-button.png";
import Iconmetrocross from "../../../../assets/Iconmetrocross.png";
import Iconionicmdcheckbox from "../../../../assets/Iconionicmdcheckbox.png";
import Iconfeatherfilter from "../../../../assets/Iconfeatherfilter.png";
import sortDownarrowImg from "./../../../../assets/Down.png";
import sortUparrowImg from "./../../../../assets/Up.png";
import milestone from "../../../../assets/milestone.png";
import Polygon from "../../../../assets/Polygon.png";
import downarrow from "../../../../assets/downarrow.png";
import loaderImg from "../../../../assets/loader-green2.gif";
import Pagination from "../../commonComponent/Pagination";
import Filter from "../../myTasks/component/Filter";
import { useHistory } from "react-router-dom";
import Moment from 'moment';
import axios from 'axios';
import constants from "../../../../Utils/constants";
import cookie from "react-cookies";
const arr = [

];



const TaskClosedByMeComponent = (props) => {

  const [data, setData] = useState()

  const [scroll, setScroll] = useState();
  const [tasks, setTasks] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [applied, setApplied] = useState(false);
  const [totalTasks, settotalTasks] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const history = useHistory();
  const limit = 10;
  const activePage = 2;

  const [assigned, setAssigned] = useState([]);
  const [assignedTo, setAssignedTo] = useState([]);
  const [planned, setPlanned] = useState([]);
  const [planedEndDateOrder, setPlanedEndDateOrder] = useState(null);
  const [priority, setPriority] = useState([]);
  const [creationDateOrder, setCreationDateOrder] = useState(null);
  const [priorityOrder, setPriorityOrder] = useState(null);
  const [status, setStatus] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const[arrowEndDate,setArrowPalnEndDate]=useState(false)
  const[priorityArrow,setPriorityArrow]=useState(false)
  const[CreationArrow,setCreationArrow]=useState(true)
  const [allData3, setAllData] = useState({
    assignedBy: assigned,
    assignedTo: assignedTo,
    creationDateOrder:creationDateOrder,
    planedEndDateOrder: planedEndDateOrder,
    priority: priority,
    priorityOrder: priorityOrder,
  });
  const [closedTaskList, setClosedTaskList] = useState([]);
  const [assignByList, setAssigneByList] = useState([])
  const [assignToList, setAssigneToList] = useState([])

  const baseUrl = constants.apiBaseUrl2;

  const getClosedTaskList = () => {
    setIsLoaded(true);
    axios
      .post(baseUrl + "/getClosedStatusData?limit=" + limit + '&page=' + currentPage, allData3,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        })
      .then((res) => {
        setIsLoaded(false);
        setClosedTaskList(res.data.responseData.tasks);
        settotalTasks(res.data.responseData.totalTask);
        setpageCount(res.data.responseData.totalNumberOfPagesAsPerGivenPageLimit);

      })
      .catch((err) => {
        console.log(err);
      });
  }
  const getAssignedByData = () => {
   
    axios
      .get(baseUrl + "/getAssignedByData",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        })
      .then((res) => {
        
        setAssigneByList(res.data.responseData.employeeNames)
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const getAssignedToData = () => {
    axios
      .get(baseUrl + "/getAssignedToData",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        })
      .then((res) => {
        console.log({ res })
        setAssigneToList(res.data.responseData.assignedTo);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  const handleApplied = (arg) => {
    if (allData3?.assignedTo.length !== 0 || priority.length !== 0 || status.length !== 0) {
      setApplied(true);
    }
    setShowFilter(false);
    getClosedTaskList();
  };

  const closeFilter = () => {
    setAllData((preValue) => {
      return (preValue ? {
        ...preValue,
        assignedBy: [],
        assignedTo:[],
        priority: [],
        status: []
      } : null)
    })
    setAssigned([]);
    setAssignedTo([])
    setPriority([]);
    setShowFilter(false);
    setApplied(false);
  };

  useEffect(() => {
    getClosedTaskList();
    getAssignedByData();
    getAssignedToData();
  }, [currentPage, allData3, priority])

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const handlePriority = () => {
    setArrowPalnEndDate(false)
    setCreationArrow(false)
    setPriorityArrow(true)
    if (priorityOrder == "ASC") {
      setPriorityOrder("")
      setAllData((preValue) => {
        return ({
          ...preValue,
          priorityOrder: "DESC",
          planedEndDateOrder: null,
          creationDateOrder:null
        })
      })
    }
    else if (priorityOrder == "DESC") {
      setPriorityOrder(null)
      setAllData((preValue) => {
        return ({
          ...preValue,
          // priorityOrder: null,
          planedEndDateOrder: null,
          creationDateOrder:null
        })
      })
    }
    else {
      setPriorityOrder("ASC")
      setAllData((preValue) => {
        return ({
          ...preValue,
          priorityOrder: "ASC",
          planedEndDateOrder: null,
          creationDateOrder:null
        })
      })
    }
  }


  const handleCrationDate = () => {
    setPriorityArrow(false)
    setCreationArrow(true)
    setArrowPalnEndDate(false)
    if (creationDateOrder == "ASC") {
      setCreationDateOrder("")
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          priorityOrder: null,
          planedEndDateOrder: null,
          creationDateOrder: "DESC",
        } : null)
      })
    }
    else if (creationDateOrder == "DESC") {
      setCreationDateOrder(null)
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          // priorityOrder:null,
          creationDateOrder: null,
        } : null)
      })
    }
    else {
      setCreationDateOrder("ASC");
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          priorityOrder: null,
          planedEndDateOrder: null,
          creationDateOrder: "ASC",
        } : null)
      })
    }
  }

  const handlePlannedDate = () => {
    setPriorityArrow(false)
    setCreationArrow(false)
    setArrowPalnEndDate(true)
    if (planedEndDateOrder == "ASC") {
      setPlanedEndDateOrder("")
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          priorityOrder: null,
          creationDateOrder:null,
          planedEndDateOrder: "DESC",
        } : null)
      })
    }
    else if (planedEndDateOrder == "DESC") {
      setPlanedEndDateOrder(null)
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          // priorityOrder:null,
          planedEndDateOrder: null,
        } : null)
      })
    }
    else {
      setPlanedEndDateOrder("ASC");
      setAllData((preValue) => {
        return (preValue ? {
          ...preValue,
          priorityOrder: null,
          creationDateOrder:null,
          planedEndDateOrder: "ASC",
        } : null)
      })
    }
  }


  const handleTaskDetail = (arg) => {
    history.push({
      pathname: '/TaskClosedByMeDetails',
      state: {
        task: arg,
        activePage: 2
      }
    });
  }


  const handleFilter = () => {
    setShowFilter(!showFilter);
  };


  return (
    <>
      <div className={style.taskTracker}>
        <div className={style.header}>
          <div>
            <span>Closed Tasks</span>
            <p>
              <span>{tasks ? totalTasks : 0}</span>
            </p>
          </div>
          <button className={style.filter} onClick={handleFilter}>
            {applied && (
              <img
                className={style.appliedcheck}
                src={Iconionicmdcheckbox}
              />
            )}
            <img src={filterimg} />
            <span>Filter</span>
          </button>

          {showFilter && (
            <img
              className={style.polygon}
              src={Polygon}
            />
          )}
        </div>
        <div className={style.container}>
          {isLoaded ? <div><img src={loaderImg} alt="loader" className={style.loader}
          />
            <p className={style.loaderPara}>Loading...</p>
          </div> : ""}
          {closedTaskList?.length > 0 && !isLoaded ?
            <div className={style.tdd}>
              <table >
                <thead>
                  <tr>
                    <th className={style.taskid} style={{ paddingLeft: '11px', position: 'sticky', cursor: "pointer", top: '0px' }}>Task ID</th>
                    <th  onClick={() => handleCrationDate()} className={style.taskplanned} style={{ paddingLeft: '13px',cursor: "pointer", position: 'sticky', top: '0px' }}>Creation Date
                    {CreationArrow?<img style={{ cursor: "pointer", position: "relative", bottom: '0px', left: "5px" }} src={allData3.creationDateOrder == "ASC" ? sortUparrowImg : sortDownarrowImg}  />:null}
                    </th>
                    <th className={style.taskTitle} style={{ paddingLeft: '7px', position: 'sticky', top: '0px' }}>Task Title</th>
                    <th className={style.taskAssigned} style={{ paddingLeft: '22px', position: 'sticky', top: '0px' }}>Assigned To</th>
                    <th className={style.taskAssigned} style={{ paddingLeft: '24px', position: 'sticky', top: '0px' }}>Assigned By</th>
                    <th onClick={() => handlePriority()}  className={style.taskPriority} style={{ paddingLeft: '19px',cursor: "pointer", position: 'sticky', top: '0px' }}>Priority
                    {priorityArrow?<span><img style={{ cursor: "pointer", position: "relative", top: "0px", left: "3px" }} src={allData3.priorityOrder == "ASC" ? sortUparrowImg : sortDownarrowImg} /></span>:null}
                    </th>
                    <th onClick={() => handlePlannedDate()}  className={style.taskplanned} style={{ paddingLeft: '10px',cursor: "pointer", position: 'sticky', top: '0px' }}>Planned End Date
                    {arrowEndDate?<img style={{ cursor: "pointer", position: "relative", bottom: '2px', left: "5px" }} src={allData3.planedEndDateOrder == "ASC" ? sortUparrowImg : sortDownarrowImg} />:null}
                    </th>
                    <div style={{ width: '14px' }}></div>
                  </tr>
                </thead>
              </table>
              

              <div className={style.scrollAdd}>
                <table>
                  <tbody>
                    {closedTaskList && closedTaskList.map((task, index) => (
                      <tr className={style.tabledata} key={index}>
                        <td className={style.tabletaskId}>{task.taskId}</td>
                        <td className={style.tableCreationDate}>
                          {Moment(task.created).format("DD MMM (ddd)")}
                        </td>
                        <td className={style.tabletaskTitle} onClick={() => handleTaskDetail(task)} ><b>{task.title}</b></td>
                        <td className={style.name}>{task.assignedToName}</td>
                        <td className={style.name}>{task.assignedByName}</td>
                        <td className={style.tabtaskpriority}>{task.priority}</td>
                        <td className={style.planned}>{Moment(task.planedEndDate).format("DD MMM (ddd)")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table></div>
            </div>
            : <div className={style.noTasks}>
              {!isLoaded ?
                <div style={{ display: 'block' }}>
                  <img src={milestone} />
                  <h2>No Task Assigned Yet</h2>
                </div> : ""}
            </div>}
          <div className={style.mobileContainer}>
            {arr.map((ar, indexar) => {
              return (
                <div
                  style={{
                    display: "block",
                    borderBottom: "1px solid #0000001A",
                    marginBottom: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <strong>{indexar + 1}. Database Creation</strong>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Assigned By :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.b}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Status :
                    </span>
                    <span
                      style={{
                        color: "#FCB902",
                        fontSize: "16px",
                        fontWeight: "300",
                      }}
                    >
                      {" " + ar.c}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Priority :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.d}
                    </span>
                  </p>
                  <p>
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        paddingTop: "3px",
                      }}
                    >
                      Planned End Date :
                    </span>
                    <span style={{ fontSize: "16px", opacity: ".7" }}>
                      {" " + ar.a}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>


        <div className={style.responsivearrow}>
          <img src={downarrow} style={{ transform: "rotateZ(90deg)" }} onClick={handlePageChange} />
          <img src={downarrow} style={{ transform: "rotateZ(-90deg)" }} />
        </div>
        {totalTasks > limit && pageCount > 0 && <div className={style.paginationmobileContainer} style={{ marginRight: "1.3rem", marginTop: "20px" }}>
          <Pagination
            pagesCount={pageCount}
            pageSize={limit}
            onPageChange={handlePageChange}
            currentPage={currentPage}
          />
        </div>}
      </div>

      {showFilter && (
        <Filter closeFilter={closeFilter} handleApplied={handleApplied} assignTo={assignToList} filterData={allData3}
          setassigned={setAssigned} setassignedTo={setAssignedTo} setpriority={setPriority} data={assignByList} />
      )}
    </>
  );
};

export default TaskClosedByMeComponent;