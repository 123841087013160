import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Header from "../../../taskTracker/commonComponent/Header";
import style from "./AddPoolResources.module.css";
import Sidebar from "../../Clients/component/SideBar";
import cookie from "react-cookies";
import axios from "axios";
import DatePicker from "react-datepicker";
import calendar from "../../../../assets/Grievance portal/dateldpi.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import constants from "../../../../Utils/constants";
import Moment from "moment";
import CustomAutoComplete from "../../../taskTracker/commonComponent/AutoComplete";

const AddPoolResources = () => {
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{ fontSize: "15px", fontWeight: 500, marginTop: "5px" }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "28%", zIndex: "0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const [availabilityDate, setAvailabilityDate] = useState(0);
  const [poolStartDate, setPoolStartDate] = useState(0);
  const [poolEndDate, setPoolEndDate] = useState(0);

  const [projectList, setProjectList] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [disable, setDisable] = useState(false);
  const [state, setState] = useState({
    allocationDate: "",
    deallocationDate: "",
    empId: 0,
    clientId: 0,
    skill: "",
  });

  const [clientData, setClientData] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [arrayObj, setarrayObj] = useState([]);
  const [employeeId, setEmployeeId] = useState("");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  employeeList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const query = useQuery();
  const id = query.get("id");
  const baseUrl = constants.apiBaseUrlResource;

  const url = constants.apiBaseUrl;

  const getEmployeeList = () => {
    axios
      .get(url + "/getEmployeeList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setEmployeeList(res.data.responseData.employeeList);
        setarrayObj(
          employeeList.map((item) => {
            return {
              label: item.name,
              id: item.id,
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const getSkills = () => {
    axios
      .get(baseUrl + "/getAllSkills", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setSkillList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectSkill = () => {
    axios
      .get(baseUrl + "/getProjectforFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setProjectList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getClientData();
    getProjectSkill();
    getEmployeeList();
    getSkills();
  }, []);

  function handleChange(id) {
    setEmployeeId(id);
  }

  const onInputChange = (e) => {
    if (e.target.name === "projectSkill") {
      setState({
        ...state,
        skill: e.target.value,
      });
    } else if (e.target.name === "clientName") {
      console.log(e.target.value);

      setState({
        ...state,
        clientId: e.target.value,
      });
    } else if (e.target.name === "startDate") {
      setState({
        ...state,
        allocationDate: e.target.value,
      });
    } else if (e.target.name === "endDate") {
      setState({
        ...state,
        deallocationDate: e.target.value,
      });
    }
  };
  console.log({ state });
  const handleRedirect = () => {
    setTimeout(() => {
      history.push("/PoolResources");
    }, 2000);
  };

  const getClientData = () => {
    axios
      .get(baseUrl + "/getClientFilterData?Status=Active", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setClientData(res.data.responseData.clientFilterDataResponse);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!availabilityDate || !employeeId || !poolStartDate) {
      setError(true);
    } else {
      setError(false);
      const data = {
        // candidateAvailablityDate: Moment(availabilityDate).format(),
        // poolEndDate:Moment(poolEndDate).format(),
        empId: employeeId,
        poolStartDate: Moment(poolStartDate).format(),
        skill: state.skill,
        candidateAvailablityDate: Moment(availabilityDate).format(),
      };

      axios
        .post(baseUrl + "/addPoolResource", data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        })
        .then((res) => {
          if (res.data.responseStatus.statusCode == 1) {
            notify1();
          } else {
            setDisable(true);
            notify();
            handleRedirect();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const notify = () =>
    toast("Data Submitted Successfully", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notify1 = () =>
    toast("Employee is already allocated", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const allocation = [
    { name: "25%", allocationPercent: "OneFourth" },
    { name: "50%", allocationPercent: "Half" },
    { name: "75%", allocationPercent: "ThreeFourth" },
    { name: "100%", allocationPercent: "Full" },
  ];

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <Sidebar />

        <form className={style.table_div} onSubmit={onSubmit}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div style={{ padding: "10px 0px", fontWeight: "700" }}>
              Pool Resources
            </div>
            <div className={style.form_div}>
              <form id="create_task_id" className={style.gridContainer}>
                <div className={style.Cu_row1}>
                  <div className={style.cu_col1}>
                    <p
                      style={{ paddingBottom: "8px" }}
                      className={style.cu_label}
                    >
                      Employee Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <CustomAutoComplete
                      className={style.cu_field2}
                      autocomplete="100%"
                      placeholder="Search by Employee Name"
                      required={true}
                      dataList={employeeList}
                      style={{
                        marginLeft: "5px",
                        fontWeight: 800,
                        height: "35px",
                      }}
                      onSelect={(value) => {
                        setEmployeeName(value.name);
                        handleChange(value.id);
                        // getResourceAloocation(value.id);
                      }}
                      onChange={(e) => setEmployeeName(e.target.value)}
                      value={employeeName}
                    />
                    {error && !employeeId ? (
                      <p className={style.error}>Please Select Employee Name</p>
                    ) : null}
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>
                      Technology<span style={{ color: "red" }}>*</span>
                    </p>
                    <select
                      onChange={onInputChange}
                      name="projectSkill"
                      className={style.cu_field2}
                    >
                      <option hidden>Select Technology</option>
                      {skillList.map((val) => {
                        return <option value={val.id}>{val?.name}</option>;
                      })}
                    </select>
                    {error && !state.skill ? (
                      <p className={style.error}>Please Select Technology</p>
                    ) : null}
                  </div>
                  <div className={style.cu_col3}>
                    <p className={style.cu_label}>
                      Pool Start Date<span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        // minDate={new Date()}
                        selected={poolStartDate}
                        startDate={null}
                        onChange={(date) => setPoolStartDate(date)}
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>

                    <p
                      className={
                        error && !poolStartDate ? style.error : style.hide
                      }
                    >
                      Please Select Pool Start Date{" "}
                    </p>
                  </div>
                </div>
                <div className={style.Cu_row2}>
                  <div className={style.cu_col2}>
                    <p className={style.cu_label}>
                      Candidate Availablity Date{" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <div className={style.cu_field2}>
                      <DatePicker
                        dateFormat="dd MMM yy"
                        minDate={state.poolStartdate}
                        // selected={endDate}
                        // startDate={null}
                        value={
                          availabilityDate
                            ? Moment(availabilityDate).format("D MMM YY")
                            : null
                        }
                        onChange={(date) => setAvailabilityDate(date)}
                        disabledKeyboardNavigation
                        defaultValue={null}
                        customInput={<CustomInput />}
                      />
                    </div>

                    <p
                      className={
                        error && !availabilityDate ? style.error : style.hide
                      }
                    >
                      Please Select Candidate Availablity Date
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={style.btn_div}>
            <button
              onClick={onSubmit}
              className={style.submitbtn}
              disabled={disable}
            >
              Submit
            </button>
          </div>
        </form>

        <ToastContainer />
      </div>
    </>
  );
};

export default AddPoolResources;
