import React, { useEffect } from "react";
import { useQuery } from "react-query";
import {
  getFeedbackField,
  getInterviewById,
  getInterviewTypeByInterviewId,
} from "../../../../Utils/axios/api";
import Header from "../../header/Header";
import style from "./Feedback.module.css";
import HrFeedback from "./HrFeedback";
import TechnicalFeedback from "./TechnicalFeedback";

function Feedback(props) {
  const [showInterview, setShowInterview] = React.useState("");
  const [checkSubmit, setCheckSubmit] = React.useState(false);

  const { data: getInterviewTypeByInterview, refetch } = useQuery(
    ["getInterviewTypeByInterviewId"],
    () => getInterviewTypeByInterviewId(props.match.params.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: getInterviewBy } = useQuery(
    ["getInterviewById", showInterview],
    () => getInterviewById(showInterview?.interviewId),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: getFeedback } = useQuery(
    ["getFeedbackField", getInterviewBy],
    () => getFeedbackField(getInterviewBy?.candidateId),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setShowInterview(getInterviewTypeByInterview);
  }, [getInterviewTypeByInterview]);

  useEffect(() => {
    refetch();
  }, [checkSubmit]);

  return (
    <>
      <Header />

      {showInterview?.interviewType === "HR Round" &&
        showInterview?.submitted === false && (
          <HrFeedback
            fieldName={getFeedback}
            interviewId={showInterview?.interviewId}
            getInterviewBy={getInterviewBy}
            setCheckSubmit={setCheckSubmit}
          />
        )}
      {showInterview?.interviewType === "Technical" &&
        showInterview?.submitted === false && (
          <TechnicalFeedback
            fieldName={getFeedback}
            interviewId={showInterview?.interviewId}
            getInterviewBy={getInterviewBy}
            setCheckSubmit={setCheckSubmit}
          />
        )}
      {showInterview?.submitted === true && (
        <div className={style.submitted}>
          <p>
            {checkSubmit
              ? "The interview feedback has been submitted."
              : "The interview feedback has already been submitted."}{" "}
          </p>
        </div>
      )}
    </>
  );
}

export default Feedback;
