import React, { useEffect, useState } from "react";
import style from "./OffshoreReportData.module.css";
import TableRow from "../../component/TableRow";
import { Table } from "react-bootstrap";
import loaderImg from "../../../../../assets/loader.gif";
import constants from "../../../../../Utils/constants";
import axios from "axios";
import cookie from "react-cookies";
import Pagination from "../../../CommonComponent/Pagination";
import { useHistory } from "react-router-dom"

const OffshoreReportData = (props) => {
  const history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;
  const [applied] = useState(false);
  const [OffShoreData, setOffshoreData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResourceOffShore, setTotalResourceOffShore] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [offshoreEmp, setOffshoreEmp] = useState([]);
  const [offshoreProject, setOffshoreProject] = useState([]);
  const [offshoreSkills, setOffshoreSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterName] = useState({});
  
  const [allData,setAllData] = useState(
   {
          action: [],
          bandwidths:[],
          empId: [],
          projectId:[props?.project],
          skillId: [],
          salaryRange: [],
          partners: [],
        }
  );

  offshoreEmp?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const url = process.env.REACT_APP_UMS_APP_URL;
 
  const getResourceOffShoreData = (clearData, currentPage) => {
    const filterData = clearData?.skillId?.length == 0 ? allData : allData;

    setIsLoaded(true);

    axios
      .post(
        baseUrl +
          "/getAddResourceOffshore?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setOffshoreData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(res.data.responseData.totalResources / 20);
      })
      .catch((err) => {
        setIsLoaded(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setCurrentPage(props?.page===2?1:currentPage)
    getResourceOffShoreData(setAllData({...allData,projectId:[props?.project]}), currentPage);
    getOffshoreProject();
    getOffshoreEmp();
    getOffshoreSkills();
  }, [currentPage, props]);
console.log(props,"props")
  const getOffshoreEmp = () => {
    axios
      .get(baseUrl + "/getAllocatedOffShoreEmployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreEmp(res.data.responseData.employees);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOffshoreProject = () => {
    axios
      .get(baseUrl + "/getProjectOffShore", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreProject(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = () => {
    axios
      .get(url + `/updateUsersDetails`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const getOffshoreSkills = () => {
    axios
      .get(baseUrl + "/getOffshoreEmployeeSkillForFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreSkills(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const limit = 20;
  // pagination arrow click handler
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <div className={style.main_client_body}>
        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              {/* <span><span>Dashboard{'>'}</span><span className={style.recent_tab}>Resource Allocation Offshore </span></span> */}
            </div>

            <div className={style.second_child}>
           
            </div>
          </div>

          <div
            style={{ backgroundColor: "white", minHeight: "5rem" }}
            className={isLoaded ?null: style.offshorelist}
          >

            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "0%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : OffShoreData?.resources.length > 0 ? (
              <Table borderless size="sm" style={{ fontSize: "12px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                    <th style={{ padding: "15px" }}>Emp Code</th>
                    <th style={{ padding: "15px" }}>Emp Name</th>
                    <th style={{ padding: "15px" }}>Experience</th>
                    <th style={{ padding: "15px" }}>Salary Range</th>
                    <th style={{ padding: "15px" }}>Project</th>
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{ textAlign: "center" }}>% Allocation</th>
                    <th style={{ padding: "10px" }}>Status</th>
                    <th style={{ padding: "15px" }}>Start Date</th>
                    <th style={{ padding: "15px" }}>End Date</th>
                    <th style={{ padding: "15px" }}>
                      Last Comment <br></br> Date
                    </th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {OffShoreData?.resources.map((val) => {
                    return (
                      <TableRow
                        projectName={val.projectName}
                        employeeName={val.empName}
                        skill={val.skillName}
                        applicationList={val.applicationList}
                        allocationType={val.allocationTypeName}
                        allocation={val.bandwidth}
                        allocationDate={val.allocationdate}
                        deallocationDate={val.deallocationdate}
                        applicationName={val.applicationName}
                        id={val.id}
                        experience={val.experience}
                        resignStatus={val.resignStatus}
                        lastCommentDate={val.currentCommentDate}
                        empCode={val.empCode}
                        salaryRange={val.salaryRange}
                        allData={allData}
                        currentPage={currentPage}
                        filterName={filterName}
                        applied={applied}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <p className={style.p}>No Data Available</p>
            )}
          </div>
          {totalResourceOffShore > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}

        </div>
      </div>
 
    </>
  );
};

export default OffshoreReportData;
