import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import cookie from "react-cookies";
import { toast } from "react-toastify";


const TaskTable = (props) => {
console.log(props)
 
  return (
    <tr style={{ borderBottom: "2px solid #F0F0F0", textAlign: "start" }}>
      <td
        // onClick={() => props.clickHandler(props.data.poolCommentId)}
        style={{
          padding: "7px 0px",
        
          fontSize: "13px",
         
        }}
      >
     {props?.data.taskId}
      </td>
      <td style={{ fontSize: "initial", fontSize: "13px" }}>
    {props?.data?.created}
      </td>
      <td 
      onClick={() => props.clickHandler(props?.data?.taskId)}
      style={{ fontSize: "initial", fontSize: "13px",  maxWidth:"18%",   padding: "10px 0px",
              textTransform: "capitalize",
              fontWeight: "bolder",
              textDecorationLine:"underline", color:"rgb(252, 187, 8)", cursor:"pointer" }}>

        {props?.data.title}
      </td>
      <td>
      {props?.data.assignToName
}
      </td>
      <td>
      {props?.data.name
}
      </td>
      <td>
      {props?.data.status
}
      </td>
      <td>
      {props?.data.priority
}
      </td>
      <td>
      {Moment(props?.data.planedEndDate).format("DD MMM YY")}

      </td>
<td> {props?.data.functionalArea
}</td>
      
    </tr>
  );
};

export default TaskTable;
