import React,{useState} from 'react';
import "./AddTabletComponent.css"
import GeneralTab from './component/General/generaltab';
import Button from '@mui/material/Button';
import PurchaseTab from './component/Purchase/PurchaseTab';
import Protection from './component/Protection/Protection';
import Others from './component/Others/others';
import { useHistory } from "react-router-dom";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step  } from "react-step-progress-bar";

const AddTabletComponent = () => {
    const [percent, setpercent] = useState(0);
    const history = useHistory();
    const  ModalFunction =()=>{
  
        history.push("/confirmation");

    }
 
    const handleStepper=(str)=>{

   if(str=="next"){
     setpercent(percent+ 34)
   }else  if(str=="back"){
    setpercent(percent - 34)
  }

    }
    const stepview = () => {
    if(percent==0){
    return <div style={{boxShadow:"0 0 20px #ccc",padding:"10px 30px",borderRadius:"10px",height:"auto"}}><GeneralTab/></div> 
    }else if(percent==34){
     return <div style={{boxShadow:"0 0 20px #ccc",padding:"10px 30px",borderRadius:"10px",height:"auto"}}><PurchaseTab/></div> 
    }else if(percent==68){
     return <div style={{boxShadow:"0 0 20px #ccc",padding:"10px 30px",borderRadius:"10px",height:"auto"}}><Protection/></div> 
    }else if(percent==102){
     return <div style={{boxShadow:"0 0 20px #ccc",padding:"10px 30px",borderRadius:"10px",height:"auto"}}><Others/></div> 
    }

    }

    return (<>


<div style={{width:"100%",padding:"50px"}}>
<div style={{ textAlign: 'center', marginBottom: 60, marginTop: 30, width: '90%', marginInline: 'auto' }}>
								<ProgressBar
									percent={percent}
									filledBackground="#2196f3" >
									<Step>
										{({ accomplished, index }) => (
											<div style={{width:"43px"}}>
												<div  className={`indexedStep ${accomplished ? "accomplished" : ""}`}>
													{index + 1}
												</div>
                        <br></br>
												<div className={`${accomplished ? "bluestep" : ""}`}>
                        <span style={{fontSize:"12px"}}>		General</span>
												</div>
											</div>
										)}
									</Step>
									<Step>
										{({ accomplished, index }) => (
											<div style={{width:"43px"}}>
												<div className={`indexedStep ${accomplished ? "accomplished" : ""}`}>
													{index + 1}
												</div>
                        <br></br>
												<div className={`${accomplished ? "bluestep" : ""}`}>
												<span style={{fontSize:"12px",marginLeft:"-3px"}}>Purchase</span>	
												</div>
										
											
											</div>
										)}
									</Step>
									
                  <Step>
										{({ accomplished, index }) => (
											<div style={{width:"43px"}}>
												<div className={`indexedStep ${accomplished ? "accomplished" : ""}`}>
													{index + 1}
												</div>
											  <br></br>
												<div className={`${accomplished ? "bluestep" : ""}`}>
											<span style={{fontSize:"12px",marginLeft:"-3px"}}>	Protection</span>	
												</div>
											
											</div>
										)}
									</Step>
                  <Step>
										{({ accomplished, index }) => (
											<div style={{width:"43px"}}>
												<div className={`indexedStep ${accomplished ? "accomplished" : ""}`}>
													{index + 1}
												</div>
											  <br></br>
												<div className={`${accomplished ? "bluestep" : ""}`}>
                        <span style={{fontSize:"12px",marginLeft:"-3px"}}>		Others</span>
												</div>
											
											</div>
										)}
									</Step>

								</ProgressBar>
			  				</div>
               {stepview()}
               <div className="buttonRow">
              {percent!==0? <div>
                    <Button onClick={()=>{ 
                  handleStepper("back")
                       }} variant="outlined">Back</Button>
                    </div>:null}
               
                   { percent>68 ?<div style={{marginLeft:"10px"}}>
                    <Button onClick={()=>{ 
                      ModalFunction()
                       }} variant="contained">Finish</Button>
                    </div>:<div style={{marginLeft:"10px"}}>
                    <Button onClick={()=>{ 
                      handleStepper("next")
                       }} variant="contained">Next</Button>
                    </div>}
                </div>
    </div>
    </>  );
}
 
export default AddTabletComponent;
