import axios from "axios";
import cookie from "react-cookies";
const apiKey = process.env.REACT_APP_Expence_URL;
const apiKey1 = process.env.REACT_APP_ATS_APP_URL;
const baseUrl = process.env.REACT_APP_PARTNER_MANAGEMENT;
const apiKey4 = process.env.REACT_APP_VALIDATE_URL;
let token = cookie.load("token");
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const invoiceListData = async () => {
  const response = await axios.get(`${apiKey}/invoiceList?limit=10&page=1`);
  return response.data.responseData;
};

export const getCandidateRoles = async () => {
  const response = await axios.get(
    `${apiKey1}/getAllCandidateRoles
      `
  );
  return response.data.responseData;
};

export const getSkills = async () => {
  const response = await axios.get(
    `${apiKey1}/getSkills
    `
  );
  return response.data.responseData;
};

export const addSkill = async ({ skill }) => {
  let data = { name: skill };
  const response = await axios.post(`${apiKey1}/addSkill`, data);
  return response.data.responseData;
};

export const createPartner = async (data) => {
  const response = await axios.post(`${baseUrl}/createPartner`, data);
  return response.data.responseData;
};

export const PartnerListData = async (limit, page, filter) => {
  console.log(limit, page, filter, "poosjs");
  const response = await axios.post(
    `${baseUrl}/getCreatePartnerList?limit=${limit}&page=${page}&partnerId=${filter?.empName}&specializationId=${filter?.empSkillId}&statusId=${filter?.empStatusId}&ratingId=${filter?.ratingId}`
  );
  return response.data.responseData;
};

export const getViewPartner = async (id) => {
  const response = await axios.get(`${baseUrl}/getPartnerById?partnerId=${id}`);
  return response.data.responseData;
};

export const getOnBoardingList = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getOnBoardingList?partnerId=${id}`
  );
  return response.data.responseData;
};

export const getPartnerName = async () => {
  const response = await axios.get(`${baseUrl}/getPartnerListForFilter`);
  return response.data.responseData;
};

export const getSKillName = async () => {
  const response = await axios.get(`${baseUrl}/getAllSpecializatiponForfilter`);
  return response.data.responseData;
};

export const PartnerOnBoarding = async (data) => {
  const response = await axios.post(`${baseUrl}/PartnerOnBoarding`, data);
  return response.data.responseData;
};

export const getOnBoardingStatusList = async () => {
  const response = await axios.get(
    `${baseUrl}/getOnBoardingStatusList
    `
  );
  return response.data.responseData;
};

export const specialization = async (data) => {
  const response = await axios.post(`${baseUrl}/add-specialization`, data);
  return response.data.responseData;
};

export const getOnBoardingByOnBoardingId = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getOnBoardingByOnBoardingId?onboardingId=${id}`
  );
  return response.data.responseData;
};

export const getPartnerOnBoardingHistoryList = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getPartnerOnBoardingHistoryList?partnerId=${id}`
  );
  return response.data.responseData;
};

export const getTdsList = async () => {
  const response = await axios.get(
    `${baseUrl}/getTdsList
    `
  );
  return response.data.responseData;
};

export const AddTds = async (data) => {
  const response = await axios.post(`${baseUrl}/add-TdsDetails`, data);
  return response.data.responseData;
};

export const getTdsDetailsList = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getTdsDetailsList?partnerId=${id}`
  );
  return response.data.responseData;
};

export const ResourceType = async () => {
  const response = await axios.get(
    `${baseUrl}/get-ResourceType
    `
  );
  return response.data.responseData;
};
export const addPartnerAgreement = async (data) => {
  const response = await axios.post(`${baseUrl}/addPartnerAgreement`, data);
  return response.data.responseData;
};

export const addPartnerRating = async (data) => {
  const response = await axios.post(`${baseUrl}/addPartnerRating`, data);
  return response.data.responseData;
};

export const TdsViewByPartnerId = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-TdsViewByPartnerId?partnerId=${id}`
  );
  return response.data.responseData;
};

export const AgreementIDByData = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-agreements-by-partner-id?partnerId=${id}`
  );
  return response.data.responseData;
};

export const AgreementIDByHistory = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-agreement-history?agreementId=${id}`
  );
  return response.data.responseData;
};

export const ViewAgreementID = async (id) => {
  const response = await axios.get(
    `${baseUrl}/view-agreement?agreementId=${id}`
  );
  return response.data.responseData;
};

export const EditPartnerAgreement = async (data) => {
  const response = await axios.post(`${baseUrl}/edit-partner-agreement`, data);
  return response.data.responseData;
};

export const specializationByID = async (id) => {
  const response = await axios.get(
    `${baseUrl}/get-specialization?partnerId=${id}`
  );
  return response.data.responseData;
};

export const GetStatusType = async () => {
  const response = await axios.get(`${baseUrl}/getStateListforFilter`);
  return response.data.responseData;
};

export const getCityList = async () => {
  const response = await axios.get(
    `${baseUrl}/getCityList
    `
  );
  return response.data.responseData;
};

export const getStateList = async () => {
  const response = await axios.get(
    `${baseUrl}/getStateList
    `
  );
  return response.data.responseData;
};

export const addViewCity = async ({ id }) => {
  const response = await axios.post(`${baseUrl}/addCity?cityName=${id}`);
  return response.data.responseData;
};

export const getOverAllPartnerRating = async (id) => {
  const response = await axios.get(
    `${baseUrl}/getOverAllPartnerRating?partnerId=${id}`
  );
  console.log("pdkkds", response);
  return response.data.responseData;
};

export const validateEmployeeAccess = async (id) => {
  const response = await axios.get(
    `${baseUrl}/validate-employee-access?empId=${id}`
  );
  return response.data.responseData;
};

export const editOnboarding = async (data) => {
  const response = await axios.put(`${baseUrl}/edit-onboarding`, data);
  return response.data.responseData;
};

export const getAllLead = async (id) => {
  const response = await axios.get(`${baseUrl}/get-all-lead`);
  return response.data.responseData;
};
export const getPartnerReport = async (leadId, reportType) => {
  const response = await axios.get(
    `${baseUrl}/get-partner-report?leadId=${leadId}&reportType=${reportType}`
  );
  return response.data.responseData;
};
