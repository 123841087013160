import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../../../taskTracker/commonComponent/Header";
import SideBar from "../../../Clients/component/SideBar";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import style from "./OffshoreRecord.module.css";
import { Tooltip } from "@material-ui/core";
import SubjectIcon from "@mui/icons-material/Subject";
import AppsIcon from "@mui/icons-material/Apps";
import constants from "../../../../../Utils/constants";
import OffshoreReportData from "../offshoreReportData/OffshoreReportData";

function OffshoreRecordData(props) {
  const[loading,setLoading]=useState(true);
  const history = useHistory();
  const[pagehandle,setpagehandle]=useState(1)
  const [selectedProjectId, setSelectedProjectid] = useState("");
  const [projectData, setProjectData] = useState();
  const [employee, setEmployee] = useState();
  const [listSelect, setListSelect] = useState(props.location.pathname==="/OffShore"?true:false);
  const baseUrl = constants.apiBaseUrlResource;

  const [selectedValue, setSelectedValue] = useState("OffShore");
  // radio button handler
  function handleOptionChange(event) {
    setSelectedValue(event.target.value);
  }

  useEffect(() => {
    if(selectedValue!=="OffShore"){
      ReportHandler();
    }

  }, [selectedValue]);

  function getAllOffshoreData(projectId) {
    axios
      .get(
        baseUrl +
          `/getAllOffshoreDataByTechnologyFromProjectId?projectId=${projectId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setEmployee(response.data.responseData);
        // Do something with the API response data
      })
      .catch((error) => {
        console.error(error);
        // Handle any API errors
      });
  }

  useEffect(() => {
    axios
      .get(baseUrl + `/getAllActiveProjectsOfOffshore`)
      .then((response) => {
        console.log(response.data);
        setProjectData(response.data.responseData);
        setLoading(false)
        // setSelectedProjectid(response.data.responseData[0].id);
        // Do something with the API response data
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getAllOffshoreData(selectedProjectId);
  }, [selectedProjectId]);

  const handleProjectClick = (projectid) => {
    setSelectedProjectid(projectid);
    setpagehandle(2)
  };

  // Data Handler
  const ListingHandler = () => {
    setListSelect(true);
  };

  const ReportHandler1 = () => {
    setListSelect(false);
  };

  // back button handler
  const backHandler = () => {
    history.push("/RMSReport");
  };
  let total = 0;
  projectData?.forEach((project) => {
    total += project?.count;
  });

  // Report Handler
  const ReportHandler = () => {
    console.log(selectedValue); // add this line to check the value of selectedValue
    switch (selectedValue) {
      case "Pool":
        history.push("/PoolRecord");
        break;
      case "OffShore":
        history.push("/OffShoreRecord");
        break;
      case "OnSite":
        history.push("/OnSiteRecord");
        break;
      default:
        // Code to handle any other location not covered by the previous cases
        break;
    }
  };

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />
        {loading?<p className={style.loadingP}>Loading...</p>:
        <div className={style.secondDiv}>
          <div className={style.iconset}>
            <p style={{ margin: 0,width:"20%"}}>Offshore Resources</p>
            <label>
              <input
                type="radio"
                name="location"
                value="OffShore"
                checked={selectedValue === "OffShore"}
                onChange={handleOptionChange}
              />
              Offshore
            </label>
            <label>
              <input
                type="radio"
                name="location"
                value="OnSite"
                checked={selectedValue === "OnSite"}
                onChange={handleOptionChange}
              />
              Onsite
            </label>

            <label>
              <input
                type="radio"
                name="location"
                value="Pool"
                checked={selectedValue === "Pool"}
                onChange={handleOptionChange}
              />
              Pool
            </label>
            <div>
              <Tooltip title="Report" placement="bottom">
                <AppsIcon className={listSelect?style.Icon:style.Icon1} 
                onClick={ReportHandler1} />
              </Tooltip>{" "}
              <Tooltip title="Listing" placement="bottom">
                <SubjectIcon
                  className={listSelect?style.Icon1:style.Icon}
                  onClick={() => ListingHandler()}
                />
              </Tooltip>
            </div>
          </div>

          {employee ? (
            <div className={style.buttonContainer}>
              <button
                className={
                  "" === selectedProjectId ? style.button : style.activeButton
                }
                onClick={() => handleProjectClick("")}
              >
                ALL
                <span
                  className={
                    "" === selectedProjectId ? style.count1 : style.count2
                  }
                >
                  {total}
                </span>
              </button>
              {projectData?.map((project) => (
                <button
                  key={project.id}
                  className={
                    project.id === selectedProjectId
                      ? style.button
                      : style.activeButton
                  }
                  onClick={() => handleProjectClick(project.id)}
                >
                  {project.name}{" "}
                  <span
                    className={
                      project.id === selectedProjectId
                        ? style.count1
                        : style.count2
                    }
                  >
                    {project?.count}
                  </span>
                </button>
              ))}
            </div>
          ) : (
            <p className={style.noRecordPara}>No Project Found</p>
          )}
          {listSelect ? null : (
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {employee &&
                employee.map((data) => {
                  return (
                    <div style={{ width: "98%" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p className={style.projectHeading}>{data?.techName}</p>
                        <span className={style.count} style={{ flexShrink: 0 }}>
                          {data?.details?.length}
                        </span>
                      </div>
                      <div key={data.techName} className={style.div}>
                        {data.details && (
                          <table>
                            <thead className={style.thead}>
                              <tr style={{ fontSize: "12px"}}>
                                <th className={style.th}>Name</th>
                                <th className={style.th}>Exp</th>
                                <th
                                  className={
                                    !selectedProjectId ? style.td21 : style.td4
                                  }
                                >
                                  Salary Range
                                </th>
                                {!selectedProjectId && (
                                  <th className={style.th}>Project Name</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {data.details.map((emp) => (
                                <tr
                                  key={emp.name}
                                  style={{ borderBottom: "1px solid #ebe5e5", fontSize:"14px",height:"2.3rem"  }}
                                >
                                  <td className={style.td1}>{emp.empName}</td>
                                  <td className={style.td2}>
                                    {~~(emp.experience / 12)} Y -{" "}
                                    {emp.experience % 12} M
                                  </td>
                                  <td
                                    className={
                                      !selectedProjectId
                                        ? style.td21
                                        : style.td4
                                    }
                                    style={
                                      emp.salaryRange === "Very High"
                                        ? { color: "red" }
                                        : null
                                    }
                                  >
                                    {emp.salaryRange}
                                  </td>
                                  <td
                                    className={
                                      !selectedProjectId ? style.th : style.td4
                                    }
                                  >
                                    {!selectedProjectId ? emp.projectName : ""}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          {listSelect ? <OffshoreReportData project={selectedProjectId} page={pagehandle}/> : (
            <div>
              {" "}
              
            </div>
          )}
        </div>}
      </div>
    </>
  );
}

export default OffshoreRecordData;
