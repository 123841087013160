import axios from "axios";
import cookie from "react-cookies";
import constants from "../constants";

const url = process.env.REACT_APP_UMS_APP_URL;

export const getAllUsersName = async () => {
  const response = await axios.get(url + `/getAllUsersName`);
  return response.data.responseData;
};
export const getAllTechnology = async () => {
  const response = await axios.get(url + `/getAllTechnology`);
  return response.data.responseData;
};
export const getAllTechnologyforFilter = async () => {
  const response = await axios.get(url + `/getTechnologyForFilter`);
  return response.data.responseData;
};
// export const getType = async () => {
//   const response = await axios.get(url + `/getAllType`);
//   return response.data.responseData;
// };

export const getRole = async () => {
  const response = await axios.get(url + `/getAllEmployeeRole`);
  return response.data.responseData;
};

export const createUser = async (data) => {
  const response = await axios.post(url + "/submitUser", data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data;
};
export const assignLeadToEmployee = async ({ empId, leadEmpId }) => {
  const response = await axios.post(
    url + `/assignLeadToEmployee?empId=${empId}&leadEmpId=${leadEmpId}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: ` ${cookie.load("token")}`,
        employeeId: localStorage.getItem("employeeId"),
      },
    }
  );
  return response.data;
};
export const getAllUsersFilterByStatus = async (data) => {
  const response = await axios.post(url + `/getAllUsersFilterByStatus`, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: ` ${cookie.load("token")}`,
      employeeId: localStorage.getItem("employeeId"),
    },
  });
  return response.data;
};

export const viewUserDetailsById = async (id) => {
  const response = await axios.get(url + `/getUserById?useId=${id}`);
  return response.data.responseData;
};
export const getAllType = async (id) => {
  const response = await axios.get(url + `/getAllType`);
  return response.data.responseData;
};

export const getAllUsersWithFilter = async (limit, page, filter) => {
  const response = await axios.post(
    url + `/getAllUsersWithFilter?limit=${limit}&page=${page}`,
    filter
  );
  return response.data.responseData;
};
export const getSalaryRange = async () => {
  const response = await axios.get(url + `/getSalaryRange`);
  return response?.data?.responseData;
};
export const getAllPartnerList = async () => {
  const response = await axios.get(url + `/getAllPartnerList`);
  return response?.data?.responseData;
};
export const getAllFunctionalArea = async () => {
  const response = await axios.get(url + `/getAllFunctionalArea`);
  return response?.data?.responseData;
};

export const getAllLeads = async () => {
  const response = await axios.get(url + `/get-all-leads`);
  return response?.data?.responseData;
};
export const markAsExit = async (data) => {
  const response = await axios.post(url + `/markAsExit`, data);
  return response;
};
export const addSkill = async ({ skill }) => {
  const response = await axios.post(url + `/addSkill?skill=${skill}`);
  return response?.data?.responseData;
};
export const markAsRetain = async (data) => {
  const response = await axios.post(url + `/markAsRetain`, data);
  return response?.data?.responseData;
};
export const deleteEmployee = async ({ employeeId }) => {
  const response = await axios.delete(
    url + `/deleteEmployee?employeeId=${employeeId}`
  );
  return response?.data?.responseData;
};
export const getConfirmationPeriod = async () => {
  const response = await axios.get(url + `/getConfirmationPeriod`);
  return response?.data?.responseData;
};
export const addConfirmation = async (data) => {
  const response = await axios.post(url + `/add-confirmation`, data);
  return response?.data?.responseData;
};
export const getClientList = async () => {
  const response = await axios.get(url + `/getClientList`);
  return response?.data?.responseData;
};
export const assignClientToEmployee = async (data) => {
  const response = await axios.post(
    url + `/assignClientToEmployee?clientId`,
    data
  );
  return response?.data?.responseData;
};
export const getReportOne = async () => {
  const response = await axios.get(url + `/getReportOne`);
  return response?.data?.responseData;
};
export const getAllTeams = async () => {
  const response = await axios.get(url + `/get-all-teams`);
  return response?.data?.responseData;
};
export const assignTeamToEmployee = async ({ empId, teamId }) => {
  const response = await axios.get(
    url + `/assign-team-to-employee?empId=${empId}&teamId=${teamId}`
  );
  return response?.data?.responseData;
};
export const getNextMonthAnniversaries = async () => {
  const response = await axios.get(url + `/get-next-month-anniversaries`);
  return response?.data?.responseData;
};
export const getPendingExit = async (limit, page) => {
  const response = await axios.get(
    url + `/get-pending-exit?limit=${limit}&page=${page}`
  );
  return response?.data?.responseData;
};
export const editExitComment = async (data) => {
  const response = await axios.put(url + `/edit-exit-comment`, data);
  return response?.data?.responseData;
};

export const getConfirmationList = async (page) => {
  const response = await axios.get(
    url + `/get-confirmation-list?limit=10&page=${page}`
  );
  return response?.data?.responseData;
};
export const updateConfirmationComment = async ({ comments, empId }) => {
  const response = await axios.post(
    url + `/update-confirmation-comment?comment=${comments}&empId=${empId}`
  );
  return response?.data?.responseData;
};
export const createBGV = async (data) => {
  const response = await axios.post(url + `/createBGV`, data);
  return response?.data?.responseData;
};
export const getBGVByEmployee = async (id) => {
  const response = await axios.get(url + `/getBGVByEmployee?empId=${id}`);
  return response?.data?.responseData;
};
