import React, { useState, useEffect } from "react";
import style from "./HrHelpDeskViewCom.module.css";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import Moment from "moment";
import ConfirmPopup from "../../../commonComponent/ConfirmPopup";
import cookie from "react-cookies";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import { Toastify, ToastifyError } from "../../../../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomTooltip from "../../../../atsDashboard/customTooltip/CustomTooltip";
import EditComments from "../../../../taskTracker/EditComments/EditComments";

export const HrViewIssueComponent = () => {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();
  const profile = cookie.load("profile");

  const [AllData, setAllData] = useState({
    closeDate: new Date(),
    issueId: id,
    statusId: 0,
    email: localStorage.getItem("email"),
  });
  const [issueById, setIssueByID] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModal1, setConfirmModal1] = useState(false);
  const [confirmModal2, setConfirmModal2] = useState(false);
  const [showAddNotes, setShowAddNotes] = useState(false);
  const [comments, setComments] = useState("");
  const [validate, setValidate] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [commentsDetailsById, setCommentsDetailsById] = useState();
  const [allcommentsDetailsById, setAllCommentsDetailsById] = useState();
  const [editCommentsShow, setEditCommentsShow] = useState(false);
  const [deletedId, setDeletedId] = useState();
  const [editCommentsId, setEditCommentsId] = useState();
  // Edit Handler
  const EditHandler = () => {
    history.push(`/EditIssue?id=${id}`);
  };

  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;

  console.log(commentsDetailsById, "id");
  //file icon  setting
  const checkfileType = (value) => {
    console.log(value, "fdf");
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
    }
  };
  const getIssueById = (id) => {
    axios
      .get(url + `/getIssueById?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data.responseData);
        setIssueByID(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCommentsById = (id1) => {
    axios
      .get(url + `/get-comment-by-id?id=${id1}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setCommentsDetailsById(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCommentsById = (id) => {
    axios
      .get(url + `/get-all-comments-by-issue-id?issueId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setAllCommentsDetailsById(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getIssueById(id);
    getAllCommentsById(id);
  }, [AllData]);

  //markAsClosedHandler
  const markAsClosedHandler = () => {
    setConfirmModal(true);
  };
  const AddNotes = () => {
    setShowAddNotes(true);
    setEditCommentsShow(false)
  };
  const cancelAddComments = () => {
    setShowAddNotes(false);
  };
  const cancelAddComments1 = () => {
    setEditCommentsShow(false);
  };

  //MarkAs Inprogress Handler
  const markAsInprogress = () => {
    setConfirmModal1(true);
  };
  // closedIssue Handler

  const ClosedIssueHandler = (id) => {
    setAllData({ ...AllData, statusId: id });
    setConfirmModal(true);
  };
  const deleteComments = (id) => {
    setConfirmModal2(true);
    setDeletedId(id);
  };
  const editCommentsHandlerById = (id1) => {
    setEditCommentsId(id1);
    getCommentsById(id1);
  };

  const DeleteIssueHandler = (id1) => {
    axios
      .delete(
        url +
          `/delete-comment-by-id?empId=${localStorage.getItem(
            "employeeId"
          )}&id=${id1}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        }
      )
      .then((res) => {
        Toastify("Deleted Comments Successfully");
        getAllCommentsById(id);
        setConfirmModal2(false);
      })
      .catch((err) => {
        return err;
      });
  };

  const cancelHandler = () => {
    history.goBack({
      state: {
        categoryId: issueById?.categoryId,
        statusId: issueById?.statusId,
      },
    });
  };

  // formHAndler
  const AddCommentsHandler = () => {
    if (!comments?.trim()) {
      setValidate(true);
      return true;
    }
    const AddCommentsData = {
      comment: comments,
      empId: localStorage.getItem("employeeId"),
      issueId: id,
    };
    axios
      .post(url + "/add-comment-in-issue", AddCommentsData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          ToastifyError(res.message);
        } else {
          Toastify("Added Comments Successfully");
          setShowAddNotes(false);
          getAllCommentsById(id);
        }
      })
      .catch((err) => {
        return err;
      });
  };
  // formHAndler
  const editCommentsHandler = () => {
    if (!commentsDetailsById?.comments?.trim()) {
      setValidate(true);
      return true;
    }
    const AddCommentsDataa = {
      comment: commentsDetailsById?.comment,
      empId: localStorage.getItem("employeeId"),
      issueId: id,
      id: editCommentsId,
    };
    console.log(AddCommentsDataa, "id");
    axios
      .post(url + "/add-comment-in-issue", AddCommentsDataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        if (res.data.responseStatus?.statusCode === 1) {
          ToastifyError(res.message);
        } else {
          Toastify("Edit Comments Successfully");
          setEditCommentsShow(false);
          getAllCommentsById(id);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    if (confirmModal || confirmModal1) {
      axios
        .post(url + "/changeIssueStatus", AllData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        })
        .then((res) => {
          Toastify("Change Status Successfully");
          setConfirmModal(false);
          setConfirmModal1(false);
          getIssueById(id);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [AllData]);

  return (
    <>
      <ToastContainer />
      <div className={style.main}>
        <p className={style.viewissueP}>View Issue</p>
        <div className={style.card}>
          <p className={style.title}>Issue Details</p>
          <div className={style.Cu_row1}>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Issue ID</p>
              <p>{`HRHD${issueById?.id}`}</p>
            </div>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Category</p>
              <p>{issueById?.categoryName}</p>
            </div>
            <div className={style.cu_col2}>
              <p className={style.cu_label}>Sub Category</p>
              <p>{issueById?.subCategoryName}</p>
            </div>
            <div className={style.cu_col3}>
              <p className={style.cu_label}>Logged Date</p>
              <p>{Moment(issueById?.loggedDate).format("DD MMM YY")}</p>
            </div>
          </div>
          <div className={style.Cu_row1}>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Raised By</p>
              <p>{issueById?.raisedByName}</p>
            </div>
            <div className={style.cu_col1}>
              <p className={style.cu_label}>Status</p>
              <p>{issueById?.status}</p>
            </div>
            <div className={style.cu_col2}></div>
            <div className={style.cu_col3}></div>
          </div>
          <div className={style.cu_col1} style={{ marginLeft: "21px" }}>
            <p className={style.cu_label}>Description</p>
            <p className={style.description}>{issueById?.description}</p>
          </div>
          <p className={style.cu_label1}>Attachment</p>
          <div style={{ paddingLeft: "22px" }}>
            {checkfileType(issueById?.attachment?.split(".")[5])}
            <a
              style={{ textDecoration: "underline" }}
              href={issueById?.attachment}
              target="_blank"
            >
              {issueById?.attachment.split("@")[1]}
            </a>
          </div>
        </div>
        <div className={style.buttonDiv}>
          <button className={style.cancelbtn} onClick={() => cancelHandler()}>
            Cancel
          </button>

          <button className={style.AddNotes} onClick={() => AddNotes()}>
            Add Comments
          </button>

          {issueById?.statusId !== 3 ? (
            <button
              className={style.markAsClosed}
              onClick={() => markAsClosedHandler()}
            >
              Mark As Closed
            </button>
          ) : null}
          {issueById?.statusId !== 2 ? (
            <button
              className={style.MarkAsInProgressbtn}
              onClick={() => markAsInprogress()}
            >
              Mark As In-Progress
            </button>
          ) : null}

          {issueById?.raisedBy &&
          issueById?.raisedBy == localStorage.getItem("employeeId") ? (
            <button className={style.editbtn} onClick={EditHandler}>
              Edit
            </button>
          ) : null}
        </div>
        {showAddNotes ? (
          <div className={style.card1}>
            <p className={style.title}>Add Comments</p>
            <textarea
              name="postContent"
              placeholder="Add Comments"
              rows={2}
              onChange={(e) => setComments(e?.target.value)}
              style={{
                outline: "none",
                width: "98%",
                height: "5rem",
                paddingLeft: "5px",
                marginTop: "5px",
                resize: "none",
                marginLeft: "10px",
              }}
            />
            <p
              className={
                validate && !comments?.trim()
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Comments
            </p>
            <div className={style.AddCommentsButtonAlign}>
              <button
                className={style?.cancelbtn}
                onClick={() => cancelAddComments()}
              >
                Cancel
              </button>{" "}
              <button
                className={style?.editbtn}
                onClick={() => AddCommentsHandler()}
              >
                Submit
              </button>
            </div>
          </div>
        ) : null}

        {editCommentsShow ? (
          <div className={style.card1}>
            <p className={style.title}>Edit Comments</p>
            <textarea
              name="postContent"
              placeholder="Edit Comments"
              rows={2}
              onChange={(e) =>
                setCommentsDetailsById({
                  ...commentsDetailsById,
                  comment: e.target.value,
                })
              }
              style={{
                outline: "none",
                width: "98%",
                height: "5rem",
                paddingLeft: "5px",
                marginTop: "5px",
                resize: "none",
                marginLeft: "10px",
              }}
              value={commentsDetailsById?.comment}
            />
            <p
              className={
                validate && !commentsDetailsById?.comment?.trim()
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Comments
            </p>
            <div className={style.AddCommentsButtonAlign}>
              <button
                className={style?.cancelbtn}
                onClick={() => cancelAddComments1()}
              >
                Cancel
              </button>{" "}
              <button
                className={style?.editbtn}
                onClick={() => editCommentsHandler()}
              >
                Update
              </button>
            </div>
          </div>
        ) : null}
        <div className={style.IssueList}>
          {isLoading ? (
            <p className={style.noDataAlign}>Loading...</p>
          ) : (
            <table style={{ width: "100%" }}>
              <thead>
                <tr className={style.th}>
                  <th className={style.tableContent}>Date</th>
                  <th className={style.tableContent}>Added By</th>
                  <th className={style.tableContent} style={{ width: "23rem" }}>
                    Comments
                  </th>
                  <th className={style.tableContent}>Status</th>
                  <th
                    className={style.tableContent}
                    style={{ textAlign: "end" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              {/* {myIssueList?.issues?.length===0?<p className={style?.noDataAlign}>No Comments Available</p>:( */}
              {allcommentsDetailsById?.length === 0 ? (
                <p className={style?.noDataAlign}>No Comments Available</p>
              ) : (
                <tbody style={{ height: "20px" }}>
                  {/* {myIssueList?.issues.map((issue) => ( */}
                  {allcommentsDetailsById?.map((val) => (
                    <tr key={val?.id} className={style.td} style={{position:"relative"}}>
                      <td className={style.tableContent}>
                        {Moment(val?.date).format("DD MMM YY")}
                      </td>
                      <td className={style.tableContent}>{val?.empName}</td>
                      <td
                        className={style.tableContent}
                        style={{ width: "23rem" }}
                      >
  
                        <CustomTooltip
                        value={val?.comment}
                        index={`clientName${val?.id}`}
                        limit={70}
                      />
                      </td>
                      <td className={style.tableContent}>{val?.status}</td>
                      {val?.empId == localStorage.getItem("employeeId") ? (
                        <td
                          className={style.tableContent}
                          style={{ textAlign: "end" }}
                        >
                          <CreateIcon
                            className={style?.EditAndDeleteIcon}
                            onClick={() => {
                              setEditCommentsShow(true);
                              setShowAddNotes(false);
                              editCommentsHandlerById(val?.id);
                            }}
                          />
                          <DeleteIcon
                            className={style?.EditAndDeleteIcon}
                            onClick={() => deleteComments(val?.id)}
                          />
                        </td>
                      ) : (
                        <td
                          className={style.tableContent}
                          style={{ textAlign: "end" }}
                        >
                          <CreateIcon
                            className={style?.HideEditAndDeleteIcon}
                          />
                          <DeleteIcon
                            className={style?.HideEditAndDeleteIcon}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          )}
        </div>
      </div>

      <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to closed?
          </h5>
          <div className={style.btnCont}>
            <button
              className={style.yesBtn}
              onClick={() => ClosedIssueHandler(3)}
            >
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={() => {
                setConfirmModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
      <ConfirmPopup open={confirmModal2}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to Delete?
          </h5>
          <div className={style.btnCont}>
            <button
              className={style.yesBtn}
              onClick={() => DeleteIssueHandler(deletedId)}
            >
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={() => {
                setConfirmModal2(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
      <ConfirmPopup open={confirmModal1}>
        <div className={style.confirmPopup}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to in progress?
          </h5>
          <div className={style.btnCont}>
            <button
              className={style.yesBtn}
              onClick={() => ClosedIssueHandler(2)}
            >
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={() => {
                setConfirmModal1(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </>
  );
};
