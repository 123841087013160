import React, { useEffect, useState } from "react";
import SideBar from "../../../Clients/component/SideBar";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import TableRow from "./TableRow";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./projectdescription.module.css";
import constants from "../../../../../Utils/constants";
import Moment from "moment";
import calendar from "../../../../../assets/Grievance portal/dateldpi.png";
import ApplicationInformation from "../../component/ApplicationInformation/ApplicationInformation";
import ConfirmPopup from "./ConfirmPopup";
import { Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

const Projectdescription = () => {
  const [projectData, setProjectData] = useState([]);
  const [ApplicationData, setApplicationData] = useState([]);
  const [AddToggle, setAddToggle] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [UnconfirmModal, setUnConfirmModal] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState([]);
  const [markAsHolds, setMarkAsHolds] = useState({});
  const [markAsClose, setmarkAsClose] = useState({});
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);

  const baseUrl = constants.apiBaseUrlResource;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");
  const history = useHistory();

  function handleConfirmPopupClose() {
    setError1(false);
    setConfirmModal(false);
    setMarkAsHolds({});
  }
  const changeStatusPopup = () => {
    setConfirmModal(true);
  };
  const changeStatusPopup1 = () => {
    setUnConfirmModal(true);
  };

  const changeOnCancel = () => {
    setUnConfirmModal(false);
    setError(false);
    setmarkAsClose({})
    
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            padding: "8px",
            fontWeight: 600,
            position: "absolute",
            top: "-1.2rem",
            left: "0.6rem",
            cursor: "pointer",
          }}
          onClick={props.onClick}
        >
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{
            position: "absolute",
            top: "-0.5rem",
            right: "0.6rem",
            zIndex: "0",
          }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });
  const editClient = () => {
    history.push(`/EditProject?id=${id}`);
  };

  const AddHandler = (id) => {
    console.log({ id });
    if (id) {
      getApplicationDetailByID(id);
    }

    setAddToggle(!AddToggle);
  };

  const getApplicationDetailByID = (id) => {
    axios
      .get(baseUrl + `/getSingleApplicationById?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log({ res });
        setApplicationDetail(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAPI = (data) => {
    console.log(data);
    axios
      .post(baseUrl + `/changeProjectStatus`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        // setProjectData(res.data?.responseData?.changeStatus);
        setUnConfirmModal(false);
        setConfirmModal(false);
        setmarkAsClose({});
        setMarkAsHolds({});
        getProjectDetails(id);
        notify(res.data?.responseData?.changeStatus);
      })
      .catch((err) => {
        notifyError(err);
      });
  };

  const notify = (res) =>
    toast(res, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const notifyError = (res) =>
    toast.error(res, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const markAsHoldHandle = () => {
    let Y = moment(markAsClose?.holdDate).format("YYYY");
    let M = moment(markAsClose?.holdDate).format("MM");
    let D = moment(markAsClose?.holdDate).format("DD");
    let H = moment(markAsClose?.holdDate).format("HH");
    let Min = moment(markAsClose?.holdDate).format("mm");
    let Date = Y + "-" + M + "-" + D + " " + H + ":" + Min;

    if (markAsHolds?.holdDate && markAsHolds?.reasonForhold) {
      let data = {
        holdDate: Date,
        projectId: projectData?.projectId,
        reasonForhold: markAsHolds?.reasonForhold,
        statusId: 2,
      };
      handleAPI(data);
      setError1(false);
    } else {
      setError1(true);
    }
  };

  const UnResignedHandle = () => {
    console.log({ error });
    let Y = moment(markAsClose?.ProjectEndData).format("YYYY");
    let M = moment(markAsClose?.ProjectEndData).format("MM");
    let D = moment(markAsClose?.ProjectEndData).format("DD");
    let H = moment(markAsClose?.ProjectEndData).format("HH");
    let Min = moment(markAsClose?.ProjectEndData).format("mm");
    let Date = Y + "-" + M + "-" + D + " " + H + ":" + Min;
    console.log(Date);
    if (markAsClose?.ProjectEndData) {
      let data = {
        projectEndDate: Date,
        projectId: projectData?.projectId,
        statusId: 3,
      };
      handleAPI(data);
    } else {
      setError(true);
    }

    // if (markAsClose !== "") {
    // data = {
    //   projectEndDate: markAsClose,
    //   projectId: 3,
    //   statusId: 3,
    // };
    // } else {
    // }
  };
  

  const getProjectDetails = (id) => {
    axios
      .get(baseUrl + `/getProjectDetails?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setProjectData(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getApplicationDetails = () => {
    axios
      .get(baseUrl + `/getAllApplications?projectId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setApplicationData(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProjectDetails(id);
    getApplicationDetails();
  }, []);
  
  return (
    <>
    
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.center_div}>
          <div
            className={style.breadscrumb}
            style={{ paddingBottom: "10px", marginTop: "-3px" }}
          >
            <span style={{ fontWeight: "700" }}>
              View{" "}
              <span className={style.arrow} style={{ fontWeight: "700" }}>
                {" "}
                {">"} Project
              </span>
            </span>
          </div>
          <div className={style.description_box}>
            <div className={style.span2}>
              <p>Project Information</p>
            </div>
            {/*start*/}
            <div className="container" style={{ height: "auto" }}>
              <div className={style.maindivContent}></div>
              <Row>
                <div className="row">
                  <div
                    className="col-3"
                    style={{ width: "25%", marginTop: "14px" }}
                  >
                    <h6>Client Name</h6>
                    <p>{projectData?.clientName}</p>
                  </div>
                  <div
                    className="col-3"
                    style={{ width: "25%", marginTop: "14px" }}
                  >
                    <h6>Project Name</h6>
                    <p style={{ textTransform: "capitalize" }}>
                      {projectData?.name}
                    </p>
                  </div>

                  <div
                    className="col-3"
                    style={{ width: "25%", marginTop: "14px" }}
                  >
                    <h6>PM/PC</h6>
                    <p style={{ textTransform: "capitalize" }}>
                      {projectData?.pmpc}
                    </p>
                  </div>
                  <div
                    className="col-3"
                    style={{ width: "25%", marginTop: "14px" }}
                  >
                    <h6>Project Start Date</h6>
                    <p>{Moment(projectData?.startdate).format("DD MMM YY")}</p>
                  </div>
                </div>
              </Row>
              <Row>
                <div className="row">
                  <div
                    className="col-3"
                    style={{ width: "25%", marginTop: "15px" }}
                  >
                    <h6>Type of Project</h6>
                    <p style={{ textTransform: "capitalize" }}>
                      {projectData?.typeofproject}
                    </p>
                  </div>
                  <div
                    className="col-3"
                    style={{ width: "25%", marginTop: "15px" }}
                  >
                    <h6>Status</h6>
                    {projectData?.status === "HOLD" ? (
                      <p style={{ textTransform: "capitalize" }}>
                        {projectData?.status}
                        {Moment(projectData.holdDate).format("DD MMM YY")}
                      </p>
                    ) : projectData?.status === "CLOSED" ? (
                      <p style={{ textTransform: "capitalize" }}>
                        {projectData?.status}
                        {Moment(projectData.endDate).format("DD MMM YY")}
                      </p>
                    ) : (
                      <p style={{ textTransform: "capitalize" }}>
                        {projectData?.status}
                      </p>
                    )}
                  </div>
                  <div
                    className="col-3"
                    style={{ width: "25%", marginTop: "15px" }}
                  >
                    {projectData?.holdDate && (
                      <>
                        <h6>Hold Date</h6>
                        <p>
                          {Moment(projectData?.holdDate).format("DD MMM YY")}
                        </p>
                      </>
                    )}
                  </div>
                  <div
                    className="col-3"
                    style={{ width: "25%", marginTop: "15px" }}
                  >
                    {projectData.projectEndDate && (
                      <>
                        <h6>Project End Date</h6>
                        <p style={{ textTransform: "capitalize" }}>
                          {Moment(projectData.projectEndDate).format(
                            "DD MMM YY"
                          )}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </Row>

              {projectData?.holdForReason && (
                <div
                  className="col-6"
                  style={{ width: "100%", height: "auto", marginTop: "10px" }}
                >
                  <h6>Reason For Hold</h6>
                  <p style={{ textTransform: "capitalize" }}>
                    {projectData?.holdForReason}
                  </p>
                </div>
              )}
              <div
                className="col-6"
                style={{ width: "100%", height: "auto", marginTop: "10px" }}
              >
                <h6>Description</h6>
                <p>{projectData?.projectdescription}</p>
              </div>
            </div>
            {/*end*/}
          </div>
          <div className={style.btn_box}>
            <button
              className={style.markAsClose_btn}
              onClick={changeStatusPopup}
              disabled={projectData?.holdDate ? true : false}
              style={
                projectData?.holdDate
                  ? { background: "gray", border: "gray" }
                  : null
              }
            >
              Mark as Hold
            </button>
            <button
              className={style.markAsClose_btn}
              onClick={changeStatusPopup1}
              disabled={projectData?.projectEndDate ? true : false}
              style={
                projectData?.projectEndDate
                  ? { background: "gray", border: "gray" }
                  : null
              }
            >
              Mark as Closed
            </button>
            <button className={style.edit_btn} onClick={editClient}>
              Edit
            </button>
          </div>

          <ConfirmPopup open={confirmModal}>
            <div className={style.confirmPopup1}>
              <h5 className={style.confirmPopupTitle1}> Hold Date<span style={{ color: "red" }}>*</span></h5>
              <div className={style.csd} style={{ marginLeft: "60px" }}>
                <DatePicker
                  dateFormat="dd MMM yy "
                  // minDate={new Date()}
                  selected={markAsHolds?.holdDate}
                  startDate={null}
                  onChange={(date) =>
                    setMarkAsHolds((prevdata) => ({
                      ...prevdata,
                      holdDate: date,
                    }))
                  }
                  defaultValue={null}
                  customInput={<CustomInputClg />}
                  style={{ backgroundColor: "#F5F6FA" }}
                />
              </div>

              <p
                className={
                  error1 && !markAsHolds?.holdDate
                    ? style.errorText2
                    : style.errorTextHidden2
                }
              >
                Please Select Hold Date
              </p>
              <h5 className={style.confirmPopupTitle2}> Reason For Hold<span style={{ color: "red" }}>*</span></h5>
              <div className={style.csd1} style={{ marginLeft: "48px" }}>
                <textarea
                  style={{ height: "inherit", outline: "none" }}
                  value={markAsHolds?.reasonForhold}
                  onChange={(e) =>
                    setMarkAsHolds((prevdata) => ({
                      ...prevdata,
                      reasonForhold: e.target.value,
                    }))
                  }
                />
              </div>
              <p
                className={
                  error1 && !markAsHolds?.reasonForhold?.trim().length
                    ? style.errorText2
                    : style.errorTextHidden2
                }
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please Enter Reason
                For Hold
              </p>
              <div className={style.btnCont3}>
                <button
                  className={style.markAsClose_btn1}
                  onClick={() => markAsHoldHandle()}
                  style={{ marginTop: "0.5rem" }}
                >
                  Done
                </button>
                <button
                  className={style.markAsClose_btn1}
                  onClick={() => handleConfirmPopupClose()}
                  style={{ marginTop: "0.5rem" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ConfirmPopup>
          <ConfirmPopup open={UnconfirmModal}>
            <div className={style.confirmPopup}>
              <h5 className={style.confirmPopupTitle}>
                Choose Project End Date<span style={{ color: "red" }}>*</span>
              </h5>
              <div className={style.csd} style={{ marginLeft: "60px" }}>
                <DatePicker
                  dateFormat="dd MMM yy "
                  selected={markAsClose?.ProjectEndData}
                  startDate={null}
                  onChange={(date) => setmarkAsClose({ ProjectEndData: date })}
                  defaultValue={null}
                  customInput={<CustomInputClg />}
                  style={{ backgroundColor: "#F5F6FA" }}
                />
              </div>
              {console.log(!markAsClose?.ProjectEndData, error)}
              <p
                className={
                  error && !markAsClose?.ProjectEndData
                    ? style.errorText2
                    : style.errorTextHidden2
                }
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please
                Choose Project End Date
              </p>
              <div className={style.btnCont1}>
                <button
                  className={style.markAsClose_btn1}
                  onClick={() => UnResignedHandle()}
                >
                  Done
                </button>
                <button
                  className={style.markAsClose_btn1}
                  onClick={() => changeOnCancel()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ConfirmPopup>
          <div className={style.description_box1}>
            <div className={style.span2}>
              <p>Application</p>
            </div>
            <div className={style.maindivContent}></div>
            <div
              className={style.original_table}
              style={{
                backgroundColor: "white",
                maxHeight: "200px",
                overflow: "auto",
                marginTop: "7px",
              }}
            >
              {ApplicationData.length > 0 ? (
                <Table borderless size="sm" style={{ fontSize: "12px" }}>
                  <thead
                    style={{
                      backgroundColor: "#F0F0F0",
                      position: "sticky",
                      top: "0px",
                    }}
                  >
                    <tr style={{ padding: "100px" }}>
                      <th style={{ padding: "25px" }}>Application</th>
                      <th style={{ padding: "25px" }}>Technology</th>
                      <th style={{ padding: "25px" }}>Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ApplicationData?.map((val) => {
                      return <TableRow clickHandler={AddHandler} data={val} />;
                    })}
                  </tbody>
                </Table>
              ) : (
                <p className={style.p1} style={{ textAlign: "center" }}>
                  No Application
                </p>
              )}
            </div>
          </div>
          <div className={style.btn_box}>
            {AddToggle ? null : (
              <button
                className={style.Add_Application_btn}
                onClick={AddHandler}
              >
                {" "}
                <span className={style.plus}>+</span>Add Application
              </button>
            )}
          </div>
          {AddToggle ? (
            <ApplicationInformation
              id={id}
              applicationDetail={applicationDetail}
            />
          ) : null}
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default Projectdescription;
