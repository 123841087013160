import React, { Children, useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import style from "./USMNavigation.module.css";

import UsersList from "../pageLayout/UsersList/UsersList";
import ViewUser from "../pageLayout/viewUser/ViewUser";
import CreateUser from "../pageLayout/createPosition/CreateUser";

export default function UsmNavigation() {
  const [currentScreen, setCurrentScreen] = useState("usersList");

  const sidebarHandler = () => {
    switch (currentScreen) {
      case "usersList":
        return <UsersList setCurrentScreen={setCurrentScreen} />;
      case "usersView":
        return <ViewUser setCurrentScreen={setCurrentScreen} />;
      case "createUser":
        return <CreateUser setCurrentScreen={setCurrentScreen} />;
      default:
    }
  };

  const setCurrScreen = (val) => {
    setCurrentScreen(val);
  };

  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        {/* left */}
        <div style={{ width: "20%" }}>
          <SideBar sidebarHandler={setCurrScreen} />
        </div>
        {/* right */}
        <div
          style={{ width: "80%", height: "100vh", overflow: "auto" }}
          className={style.umsNavigation}
        >
          <div style={{ marginLeft: "30px", marginTop: "10px" }}>
            {/* header */}
            <p style={{ fontSize: "20px", fontWeight: "bold" }}>
              {currentScreen.charAt(0).toUpperCase() +
                currentScreen
                  .slice(1)
                  .replace(/([A-Z])/g, " $1")
                  .trim()}
            </p>

            {/* extra */}
          </div>
          {sidebarHandler()}
        </div>
      </div>
    </div>
  );
}
