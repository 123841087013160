import React, { useEffect, useState } from "react";
import style from "./ViewUser.module.css";
import SideBar from "../../sidebar/SideBar";
import axios from "axios";
import ConfirmPopup from "./ConfirmPopup";
import dropdownIcon from "../../../../assets/arrowdownIcon.png";
import "react-responsive-modal/styles.css";
import cookie from "react-cookies";
import calendarIcon from "../../../../assets/calendarIcon.png";
import { useHistory, useLocation } from "react-router-dom";
import Moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useQuery as useQuerys, useMutation, useQuery } from "react-query";
import cancel from "../../../../assets/cancell.png";
import moment from "moment";
import { ImageProfileView } from "../commonComponent/ImageProfileView";
import { FiArrowDown } from "react-icons/fi";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
// import { CustomTooltipDiv } from "../../../customTooltip/CustomTooltip";
import { MdModeEditOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import {
  uploadResume,
  deleteResume,
  getBgvByCandidateEmail,
} from "../../../../Utils/axios/api";

import {
  getAllUsersName,
  assignLeadToEmployee,
  markAsExit,
  deleteEmployee,
  markAsRetain,
  getConfirmationPeriod,
  addConfirmation,
  getClientList,
  assignClientToEmployee,
  getAllTeams,
  assignTeamToEmployee,
  createBGV,
  getBGVByEmployee,
} from "../../../../Utils/axios/umsApi";
import ATSCard from "../../../atsDashboard/customTooltip/ATSCard";
import ATSButton from "../../../atsDashboard/customTooltip/ATSButton";
import Loader from "./../../../PartnerManagement/Loader/Loader";
import { CustomTooltipDiv } from "../../../atsDashboard/customTooltip/CustomTooltip";
import Modal from "react-responsive-modal";

const ViewUser = () => {
  const url = process.env.REACT_APP_UMS_APP_URL;
  const history = useHistory();
  const location = useLocation();
  let filterData = location?.state?.filterData;
  let currentPage = location?.state?.currentPage;
  let filter = location?.state?.filter;

  const { id } = location.state || { id: "none" };
  const [show, setShow] = useState(false);
  const [userDescription, setUserDescription] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [UnconfirmModal, setUnConfirmModal] = useState(false);
  const [resignedDate, setResignedDate] = useState(0);
  const [lwdDate, setLwdDate] = useState(null);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledResign, setDisabledResign] = useState(false);
  const [leadModal, setLeadModal] = useState(false);
  const [exitModal, setExitModal] = useState(false);
  const [formData, setFormData] = useState({ leadEmpId: "" });
  const { data: getAllUsers } = useQuerys(["getAllUsersName"], getAllUsersName);
  const [validate, setValidate] = useState(false);
  const [candidateDropped, setCondidateDropped] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [markAsExitData, setMarkAsExit] = useState({
    empId: id,
    exitDecisionDate: "",
    exitPlanDate: "",
  });
  const AllUsersList = getAllUsers?.map((e) => {
    return { value: e.id, label: e.name };
  });
  const [retainModal, setRetainModal] = useState(false);
  const [retain, setRetain] = useState({
    empId: id,
    retain: "",
    retainDate: "",
  });
  const [errorRetain, setErrorRetain] = useState(false);
  const [showImageProfile, setShowImageProfile] = useState(false);
  const [addCanfirmation, setAddCanfirmation] = useState(false);
  const [confirmationData, setConfirmationData] = useState({
    actualConfirmationDate: null,
    confirmationDate: null,
    delayReason: null,
    empId: id,
    periodId: null,
  });
  const [errorCanfirmation, setErrorCanfirmation] = useState(false);
  const [assignClientShow, setAssignClientShow] = useState(false);
  const [assignTeamShow, setAssignTeamShow] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [teamId, setTeamId] = useState(false);

  const [bgvRequestModels, setBgvRequestModels] = useState([]);
  const [editStatus, setEditStatus] = useState(true);
  // const [show, setShow] = useState(false);
  const [editRequest, setEditRequest] = useState([]);
  const [bgvId, setBgv] = useState(null);
  const [uploadIn, setUploadIn] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [comments, setComment] = useState(null);
  const [showCommnent, setShowComment] = useState(false);
  const [validation, setValidation] = useState(false);
  const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf style={{ fontSize: "2rem" }} />;
      case "docx":
        return <AiOutlineFileWord style={{ fontSize: "2rem" }} />;
      default:
        return;
    }
  };
  useEffect(() => {
    setMarkAsExit((prev) => ({
      ...prev,
      exitDecisionDate: userDescription?.exitDecisionDate,
      exitPlanDate: userDescription?.exitPlanDate,
    }));
    setClientId(userDescription?.clientId);
    setTeamId(userDescription?.teamId);
  }, [userDescription]);

  const handleRedirect = () => {
    setUnConfirmModal(false);
    setConfirmModal(false);
    setTimeout(() => {
      history.push("/userDescription", { id, filterData, currentPage, filter });
    }, 2000);
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref} style={{ padding: "8px", fontWeight: 600 }}>
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "13%", zIndex: "0" }}
          src={calendarIcon}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });

  const edituser = () => {
    history.push("/EditUser", { id, filterData, currentPage, filter });
  };
  const uploadResumeMutation = useMutation(uploadResume, {
    onSuccess: (res) => {
      setUploadUrl(res);
    },
    onError: () => {},
  });

  function handleConfirmPopupClose() {
    setConfirmModal(false);
    setResignedDate(null);
    setError(false);
  }
  const changeStatusPopup = () => {
    setConfirmModal(true);
  };
  const changeStatusPopup1 = () => {
    setUnConfirmModal(true);
  };

  const changeOnCancel = () => {
    setUnConfirmModal(false);
    setResignedDate(null);
    setError(false);
  };

  const MarkasChange = (data) => {
    setDisabled(true);
    axios
      .put(url + `/updateUserStatus`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setShow(!show);
        setDisabled(false);
        if (res.data.responseStatus.statusCode === 1) {
          notify(res.data.responseData.message);
        } else {
          notifySuccess(res.data.responseData);
          handleRedirect();
          setError(false);
          setLwdDate(null);
          setCondidateDropped(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getApplicationDetails = (id) => {
    axios
      .get(url + `/getUserById?useId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setUserDescription(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeResignedStatus = (id, status) => {
    if (!resignedDate) {
      setError(true);
      return false;
    }
    setConfirmModal(false);
    const data = {
      date: Moment(resignedDate).format(),
      employeeId: localStorage.getItem("employeeId"),
      resignStatus: status === "resigne" ? true : false,
      userId: id,
    };
    setDisabledResign(true);
    axios
      .put(url + "/updateUserResignStatus", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setResignedDate(null);
        setError(false);
        setDisabledResign(false);
        setShow(!show);
        if (res.data.responseStatus.statusCode === 1) {
          notify(res.data.responseData.message);
        } else {
          notifySuccess(res.data.responseData);
          handleRedirect();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getApplicationDetails(id);
  }, [id, show]);

  const customStyles = {
    control: () => ({
      boxShadow: "0px 3px 9px #00000029",
      borderRadius: "9px",
      background: "transparent 0% 0% no-repeat padding-box",
      backgroundColor: "#f5f6fa",
      minWidth: "inherit",
      margin: "8px 1.5% 0px 1.7%",
      height: "38px",
      outline: "none",
    }),
    placeholder: () => ({
      display: "none",
    }),
  };
  const notifySuccess = (success) =>
    toast.success(success, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });

  const notify = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="dropdownIcon"
          style={{
            position: "absolute",
            top: "32%",
            right: "6%",
            width: "18px",
          }}
        />
      </span>
    );
  };
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{ padding: "8px", fontWeight: 600, cursor: "pointer" }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          alt="cancel"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "30%",
            cursor: "pointer",
          }}
          onClick={() =>
            setMarkAsExit((prev) => ({ ...prev, [props?.properties]: "" }))
          }
        />
      </div>
    );
  });
  const mutation = useMutation(assignLeadToEmployee, {
    onSuccess: (res) => {
      LeadSuccess(res.responseData);
      setLeadModal(false);
      getApplicationDetails(id);
      setValidate(false);
      setFormData({ ...formData, leadEmpId: null });
    },
    onError: () => {
      notify1("Something went wrong..!");
      setValidate(false);
    },
  });
  const mutateMark = useMutation(markAsRetain, {
    onSuccess: (res) => {
      LeadSuccess("Mark as retain successfully");
      setRetainModal(false);
      setRetain((prev) => ({
        ...prev,
        retain: "",
        retainDate: null,
      }));
      setErrorRetain(false);
      getApplicationDetails(id);
    },
    onError: (res) => {
      notify1("Something went wrong..!");
      setRetainModal(false);
    },
  });
  const assignClientMutation = useMutation(assignClientToEmployee, {
    onSuccess: (res) => {
      setAssignClientShow(false);
      getApplicationDetails(id);
      LeadSuccess(res);
    },
    onError: (res) => {
      notify1("Something went wrong..!");
      setAssignClientShow(false);
    },
  });
  const assignTeamMutation = useMutation(assignTeamToEmployee, {
    onSuccess: (res) => {
      setAssignTeamShow(false);
      getApplicationDetails(id);
      LeadSuccess(res);
    },
    onError: (res) => {
      notify1("Something went wrong..!");
      setAssignTeamShow(false);
    },
  });
  const AssignLeadMethod = () => {
    if (!formData?.leadEmpId) {
      setValidate(true);
    } else {
      mutation.mutate({ empId: id, leadEmpId: formData?.leadEmpId });
    }
  };
  const LeadSuccess = (res) =>
    toast.success(res, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const notify1 = (error) =>
    toast.error(error, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      closeButton: false,
    });
  const handleLastDay = (id) => {
    if (!lwdDate) {
      setError(true);
    } else {
      const data = {
        employeeId: localStorage.getItem("employeeId"),
        lastWorkingDate: Moment(lwdDate).format(),
        userId: id,
      };
      MarkasChange(data);
      setCondidateDropped(false);
    }
  };
  const exitMutation = useMutation(markAsExit, {
    onSuccess: () => {
      setExitModal(false);
      notifySuccess("Mark as Exit Planned Successfully");
      getApplicationDetails(id);
      handleRedirect();
      setError(false);
      setMarkAsExit((prevData) => ({
        ...prevData,
        exitPlanDate: "",
        exitDecisionDate: "",
      }));
    },
    onError: () => {
      notify("Some Think Went Wrong");
    },
  });
  const handleMarkAsExit = () => {
    if (userDescription?.exitDecisionDate || userDescription?.exitPlanDate) {
      return exitMutation.mutate(markAsExitData);
    }
    if (markAsExitData?.exitDecisionDate) {
      exitMutation.mutate(markAsExitData);
    } else {
      setError(true);
    }
  };
  const deleteMutation = useMutation(deleteEmployee, {
    onSuccess: (res) => {
      notifySuccess(res);
      setTimeout(() => {
        history.push("/UsersList");
      }, 3000);
    },
    onError: (res) => {
      notify("Something Went Wrong");
    },
  });
  const retainOption = [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];
  const handleReatain = () => {
    if (retain?.retain === false || retain?.retain === true) {
      if (retain?.retainDate) {
        mutateMark.mutate(retain);
      } else {
        setErrorRetain(true);
      }
    } else {
      setErrorRetain(true);
    }
  };
  const errorMess = () => {
    if (retain?.retain === false || retain?.retain === true) {
      return false;
    } else {
      return true;
    }
  };
  const handleUploadImage = (value) => {
    const exe = value?.split("@")[1];
    if (
      exe?.split(".")[1] === "png" ||
      exe?.split(".")[1] === "jpg" ||
      exe?.split(".")[1] === "jpeg"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const { data: cafirmation } = useQuerys(
    ["getConfirmationPeriod"],
    getConfirmationPeriod,
    { refetchOnWindowFocus: false }
  );
  const canfirmationOption = cafirmation?.map((e) => {
    return {
      value: e?.id,
      label: e?.name,
    };
  });
  const { data: getClientData } = useQuerys(["getClientList"], getClientList, {
    refetchOnWindowFocus: false,
  });
  const { data: getTeamsData } = useQuerys(["getAllTeams"], getAllTeams, {
    refetchOnWindowFocus: false,
  });
  const teamOption = getTeamsData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const clientOption = getClientData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });
  const disabledConfirmationDate = (value, cmdate) => {
    if (value === 1) {
      return true;
    } else if (value > 1 && cmdate === "confimationDate") {
      return true;
    } else {
      return false;
    }
  };
  const handleConfimation = (e) => {
    if (e?.value === 1) {
      setConfirmationData((prev) => ({
        ...prev,
        periodId: e?.value,
        confirmationDate: null,
        actualConfirmationDate: null,
        delayReason: null,
      }));
    } else {
      let date = moment(userDescription?.dateOfJoining);
      let newDate = date?.add(e?.label?.split(" ")[0], "months");
      setConfirmationData((prev) => ({
        ...prev,
        periodId: e?.value,
        confirmationDate: newDate,
      }));
    }
  };
  const mutationConfirmation = useMutation(addConfirmation, {
    onSuccess: (res) => {
      LeadSuccess(res);
      setErrorCanfirmation(false);
      setAddCanfirmation(false);
      setConfirmationData((prev) => ({
        ...prev,
        actualConfirmationDate: null,
        confirmationDate: null,
        delayReason: null,
        periodId: null,
      }));
      getApplicationDetails(id);
    },
  });
  const handleConfirmationDate = () => {
    if (confirmationData?.periodId) {
      mutationConfirmation.mutate(confirmationData);
    } else {
      setErrorCanfirmation(true);
    }
  };
  const handleTeam = () => {
    if (teamId) {
      assignTeamMutation?.mutate({
        teamId: teamId,
        empId: id,
      });
      setError(false);
    } else {
      setError(true);
    }
  };
  const handleUpload = () => {
    let key = [...bgvRequestModels];
    for (let i = 0; i < key.length; i++) {
      if (i === Number(uploadIn)) {
        key[uploadIn]["bgvDocUrl"] = uploadUrl;
      }
    }

    setUploadIn(null);
    setBgvRequestModels([...key]);
  };
  useEffect(() => {
    uploadUrl && handleUpload();
  }, [uploadUrl]);
  const createBgvMutation = useMutation(createBGV, {
    onSuccess: () => {
      LeadSuccess("BGV Submited Successfully");
      setBgvRequestModels([]);
      setEditRequest({});
      setEditStatus(true);
      refetchEmp();
    },
  });
  const editBgvMutation = useMutation(createBGV, {
    onSuccess: () => {
      LeadSuccess("BGV Updated Successfully");
      setBgvRequestModels([]);
      setEditRequest({});
      setEditStatus(true);
      refetchEmp();
    },
  });
  const handleBgv = () => {
    let data = bgvRequestModels?.filter((item) => item?.companyName !== "");
    data = data?.map((e) => {
      return {
        bgvstatus: e?.bgvstatus,
        comments: e?.comments,
        companyName: e?.companyName,
        employeeId: e?.employeeId,
        feedbackStatus: e?.feedbackStatus,
        bgvDocUrl: e?.bgvDocUrl,
      };
    });
    createBgvMutation.mutate({ bgvRequestModels: data });
  };
  const handleEditBgv = () => {
    if (editRequest?.companyName) {
      editBgvMutation.mutate({ bgvRequestModels: [editRequest] });
    } else {
      setValidation(true);
    }
  };
  const {
    data: bgvByEmp,
    isLoading,
    isError,
    refetch: refetchEmp,
  } = useQuery(
    ["getBGVByEmployee", userDescription],
    () => userDescription?.id && getBGVByEmployee(userDescription?.id)
  );
  const bgvByEmailMutation = useMutation(getBgvByCandidateEmail, {
    onSuccess: (res) => {
      if (res?.length > 0) {
        let newData = res?.map((e) => {
          return {
            bgvstatus: e?.bgvStatus,
            comments: e?.comments,
            companyName: e?.companyName,
            feedbackStatus: e?.feedbackStatus,
            employeeId: userDescription?.id,
            bgvDocUrl: e?.bgvDocUrl,
          };
        });
        setBgvRequestModels(newData);
      } else {
        notify1("BGV Doesn't Exists");
      }
    },
  });
  const EditResumeMutation = useMutation(uploadResume, {
    onSuccess: (res) => {
      setEditRequest((prev) => ({
        ...prev,
        bgvDocUrl: res,
      }));
    },
    onError: () => {},
  });

  return (
    <>
      <ToastContainer />
      {/*  <Header />*/}
      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.breadscrumb} style={{ padding: "6px " }}></div>
          <p className={style.vieUser}>View User</p>
          <div className={style.createposition}>
            <div className={style.inner_card}>
              <div
                className={style.rowContainernew}
                style={{ marginTop: "15px" }}
              >
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Type</p>
                  <p className={style.cp_value}>{userDescription?.type}</p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Code</p>
                  <p className={style.cp_value}>{userDescription?.code}</p>
                </div>
                {handleUploadImage(userDescription?.uploadImageUrl) && (
                  <div className={style.cp_rowcol122}>
                    <img
                      src={userDescription?.uploadImageUrl}
                      alt="Userprofileimage"
                      style={{
                        borderRadius: "50%",
                        width: "3.5rem",
                        height: "3.5rem",
                        border: "1px solid black",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowImageProfile(true)}
                    />
                  </div>
                )}
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Name</p>
                  <p className={style.cp_value}>{userDescription?.name}</p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Email</p>
                  <p className={style.cp_value}>{userDescription?.email}</p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Personal Email</p>
                  <p className={style.cp_value}>
                    {userDescription?.personalEmail}
                  </p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Phone Number</p>
                  <p className={style.cp_value}>{userDescription?.number}</p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Date of Joining</p>
                  <p className={style.cp_value}>
                    {Moment(userDescription?.dateOfJoining).format("DD MMM YY")}
                  </p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Career Start Date</p>
                  <p className={style.cp_value}>
                    {Moment(userDescription?.careerStartDate).format(
                      "DD MMM YY"
                    )}
                  </p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Total Experience</p>
                  <p className={style.cp_value}>
                    {~~(userDescription?.totalExperience / 12)
                      ? ~~(userDescription?.totalExperience / 12)
                      : 0}{" "}
                    Years{" "}
                    {userDescription?.totalExperience % 12
                      ? userDescription?.totalExperience % 12
                      : 0}{" "}
                    Months
                  </p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Relevant Experience </p>
                  <p className={style.cp_value}>
                    {~~(userDescription?.relevantExperience / 12)
                      ? ~~(userDescription?.relevantExperience / 12)
                      : 0}{" "}
                    Years{" "}
                    {userDescription?.relevantExperience % 12
                      ? userDescription?.relevantExperience % 12
                      : 0}{" "}
                    Months
                  </p>
                </div>
                {userDescription?.period && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Confirmation</p>
                    <p className={style.cp_value}>{userDescription?.period}</p>
                  </div>
                )}
                {userDescription?.confirmationDate && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Confirmation Date</p>
                    <p className={style.cp_value}>
                      {moment(userDescription?.confirmationDate).format(
                        "DD MMM YY"
                      )}
                    </p>
                  </div>
                )}
                {userDescription?.actualConfirmationDate && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}> Actual Confirmation Date</p>
                    <p className={style.cp_value}>
                      {moment(userDescription?.actualConfirmationDate).format(
                        "DD MMM YY"
                      )}
                    </p>
                  </div>
                )}
                {userDescription?.delayReason && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}> Confirmation Remarks</p>
                    <p className={style.cp_value}>
                      {userDescription?.delayReason}
                    </p>
                  </div>
                )}
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Technology </p>
                  <p className={style.cp_value}>
                    {userDescription?.technology}
                  </p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Additional Skill</p>
                  <p className={style.cp_value}>
                    {userDescription?.additionalSkill
                      ?.map((e) => e.additionalSkillName)
                      .join(", ")}
                  </p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Role</p>
                  <p className={style.cp_value}>
                    {userDescription?.employeeDesignation}
                  </p>
                </div>
                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Salary Range</p>
                  <p className={style.cp_value}>
                    {userDescription?.salaryRangeName}
                  </p>
                </div>

                {userDescription?.functionalAreaName && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Function Area</p>
                    <p className={style.cp_value}>
                      {userDescription?.functionalAreaName}
                    </p>
                  </div>
                )}

                <div className={style.cp_rowcol122}>
                  <p className={style.cp_label}>Bond</p>
                  <p className={style.cp_value}>
                    {userDescription?.bond ? "Yes" : "No"}
                  </p>
                </div>
                {userDescription?.bond && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}> Bond End Date</p>
                    <p className={style.cp_value}>
                      {userDescription?.bondDate
                        ? moment(userDescription?.bondDate).format("DD MMM YY")
                        : null}
                    </p>
                  </div>
                )}

                {userDescription?.assignedLead && (
                  <div className={style.cp_rowcol122}>
                    <>
                      <p className={style.cp_label}>Assigned Lead</p>
                      <p className={style.cp_value}>
                        {userDescription?.assignedLead}
                      </p>
                    </>
                  </div>
                )}
                {userDescription?.teamName && (
                  <div className={style.cp_rowcol122}>
                    <>
                      <p className={style.cp_label}>Assigned Team</p>
                      <p className={style.cp_value}>
                        {userDescription?.teamName}
                      </p>
                    </>
                  </div>
                )}
                {userDescription?.clientName && (
                  <div className={style.cp_rowcol122}>
                    <>
                      <p className={style.cp_label}>Assigned Client</p>
                      <p className={style.cp_value}>
                        {userDescription?.clientName}
                      </p>
                    </>
                  </div>
                )}

                {userDescription?.resignStatus && (
                  <div className={style.cp_rowcol122}>
                    <>
                      <p className={style.cp_label}>Resignation Date</p>
                      <p className={style.cp_value}>
                        {Moment(userDescription?.resignationDate).format(
                          "DD MMM YY"
                        )}
                      </p>
                    </>
                  </div>
                )}
                {userDescription?.retain === true && (
                  <div className={style.cp_rowcol122}>
                    <>
                      <p className={style.cp_label}>Retain End Date</p>
                      <p className={style.cp_value}>
                        {Moment(userDescription?.retainDate).format(
                          "DD MMM YY"
                        )}
                      </p>
                    </>
                  </div>
                )}

                {userDescription?.lastWorkingDayDate && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Last Working Day</p>
                    <p className={style.cp_value}>
                      {Moment(userDescription?.lastWorkingDayDate).format(
                        "DD MMM YY"
                      )}
                    </p>
                  </div>
                )}
                {userDescription?.exitDecisionDate && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Exit Decision Date</p>
                    <p className={style.cp_value}>
                      {Moment(userDescription?.exitDecisionDate).format(
                        "DD MMM YY"
                      )}
                    </p>
                  </div>
                )}
                {userDescription?.exitPlanDate && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Exit Planned Date</p>
                    <p className={style.cp_value}>
                      {Moment(userDescription?.exitPlanDate).format(
                        "DD MMM YY"
                      )}
                    </p>
                  </div>
                )}
                {userDescription?.dob && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Date of Birth</p>
                    <p className={style.cp_value}>
                      {Moment(userDescription?.dob).format("DD MMM YYYY")}
                    </p>
                  </div>
                )}
                {userDescription?.marriageAnniversary && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>Marriage Anniversary</p>
                    <p className={style.cp_value}>
                      {Moment(userDescription?.marriageAnniversary).format(
                        "DD MMM YY"
                      )}
                    </p>
                  </div>
                )}
                {userDescription?.linkedInUrl && (
                  <div className={style.cp_rowcol122}>
                    <p className={style.cp_label}>LinkedIn URL</p>
                    <a
                      href={userDescription?.linkedInUrl}
                      target="_black"
                      rel="noreferrer"
                      title="Click here"
                      className={style.cp_value}
                    >
                      {userDescription?.linkedInUrl?.length > 30
                        ? `${userDescription?.linkedInUrl?.slice(0, 30)}...`
                        : userDescription?.linkedInUrl}
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className={style.Cu_row2} style={{ marginTop: "5px" }}>
              <div className={style.cp_col4}>
                <p className={style.cp_label}>Note</p>
                <p className={style.cp_value}>{userDescription?.note}</p>
              </div>
            </div>
          </div>
          {/*Experience*/}
          <div
            className={style.createposition}
            style={{ marginBottom: "2rem" }}
          >
            <p className={style.title}>Work Experience</p>
            <div className={style.Cu_row1} style={{ paddingBottom: "1rem" }}>
              <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={style.cp_label}>Company Name</p>
              </div>
              <div className={style.cu_col2_to} style={{ flex: "0 0 17%" }}>
                {
                  <div className={style.multiinput}>
                    <div className={style.cp_label} style={{ flex: "0 0 55%" }}>
                      From
                    </div>
                    <div className={style.cp_label} style={{ flex: "0 0 55%" }}>
                      To
                    </div>
                  </div>
                }
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 9%" }}>
                <p className={style.cp_label}>Dur</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 19%" }}>
                <p className={style.cp_label}>Role</p>
              </div>

              <div className={style.cu_col3} style={{ flex: "0 0 27%" }}>
                <p className={style.cp_label}>Reason For Change</p>
              </div>
            </div>
            {userDescription?.workExpResponseModels?.map((item, index) => {
              return (
                <div key={item?.id}>
                  <div className={style.Cu_row1}>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <p className={style.cu_field2}>{item?.companyName}</p>
                    </div>
                    <div className={style.cu_col2} style={{ flex: "0 0 17%" }}>
                      <div
                        className={style.multiinput}
                        style={{ width: "inherit" }}
                      >
                        <div
                          className={style.cu_field2}
                          style={{ flex: "0 0 55%" }}
                        >
                          <p className={style.cu_field2}>
                            {item?.startDate &&
                              moment(item?.startDate).format("MMM YYYY")}
                          </p>
                        </div>

                        <div
                          className={style.cu_field23}
                          style={{ flex: "0 0 55%" }}
                        >
                          <p className={style.cu_field2}>
                            {item?.endDate &&
                              moment(item?.endDate).format("MMM YYYY")}
                            {item?.presentlyWorking && "Till Now"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={style.cu_col3} style={{ flex: "0 0 9%" }}>
                      <p className={style.cu_field2}>
                        {(function () {
                          let year1 = Math.floor(item.expDurationYear);
                          let month = item.expDurationMonth;
                          let year = year1 > 0 ? year1 + " Y  " : "";
                          month =
                            year1 > 0 && month > 0
                              ? ` - ${month}  M `
                              : month > 0
                              ? `${month}  M `
                              : "";
                          return `   ${year}  ${month}`;
                        })()}
                      </p>
                    </div>
                    <div className={style.cu_col3} style={{ flex: "0 0 19%" }}>
                      <p className={style.cu_field2}>{item?.role}</p>
                    </div>
                    <div
                      className={style.cu_col1Reasone}
                      style={{ flex: "0 0 27%" }}
                    >
                      <p className={style.cu_fieldReasone}>
                        {item?.reasonForChange}
                      </p>
                    </div>
                  </div>
                  {/* Second */}
                  <div className={style.Cu_row11Reasone}>
                    {/* <div className={style.cu_col1Reasone}>
                    <p className={style.cu_label}>Reason For Change</p>
                    <p className={style.cu_fieldReasone}>
                      {item?.reasonForChange}
                    </p>
                            </div>*/}
                    {/* <div className={style.cu_col2}></div>
                  <div className={style.cu_col4}></div> */}
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
          {/*Academics */}
          <div className={style.createposition}>
            <p className={style.title}>Academics</p>
            <div className={style.Cu_row1} style={{ paddingBottom: "1rem" }}>
              <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                <p className={style.cp_label}>Degree</p>
              </div>
              <div className={style.cu_col2} style={{ flex: "0 0 20%" }}>
                <p className={style.cp_label}>Passing Year</p>
              </div>
              <div className={style.cu_col2} style={{ flex: "0 0 15%" }}>
                <p className={style.cp_label}>Grade</p>
              </div>
              <div className={style.cu_col3} style={{ flex: "0 0 35%" }}>
                <p className={style.cp_label}>Board of Education/University</p>
              </div>
            </div>
            {userDescription?.educationResponseModels?.map((item, index) => {
              return (
                <div key={item?.id}>
                  <div className={style.Cu_row1}>
                    <div className={style.cu_col1} style={{ flex: "0 0 23%" }}>
                      <p className={style.cp_value}>{item?.degree}</p>
                    </div>
                    <div className={style.cu_col2} style={{ flex: "0 0 20%" }}>
                      <p className={style.cp_value}>{item?.passingYear}</p>
                    </div>
                    <div className={style.cu_col2} style={{ flex: "0 0 15%" }}>
                      <p className={style.cp_value}>
                        {item?.grade}
                        {item?.gradeStatus === "CGPA" && " CGPA"}
                        {item?.gradeStatus === "Percentage" && "%"}
                      </p>
                    </div>
                    <div className={style.cu_col3} style={{ flex: "0 0 35%" }}>
                      <p className={style.cp_value}>{item?.board}</p>
                    </div>
                  </div>
                  {/* Second */}

                  <hr />
                </div>
              );
            })}
          </div>

          {/*BGV */}
          <div className={style.createposition}>
            <p className={style.title}>BGV</p>
            {editStatus ? (
              <>
                <div
                  className={style.bgvcantainer}
                  style={{ marginBottom: "0px", padding: "0 0.6rem" }}
                >
                  <div className={style.bgvrow1}>
                    <div>
                      <p className={style.label}>Company Name</p>
                    </div>
                    <div>
                      <p className={style.label}>Status</p>
                    </div>
                    <div>
                      <p className={style.label}> &nbsp;Positive/Negative</p>
                    </div>
                    <div>
                      <p className={style.label}> &nbsp;Upload Doc</p>
                    </div>
                    <div>
                      <p className={style.label}>Comments</p>
                    </div>
                  </div>
                </div>
                {bgvRequestModels?.map((item, key) => {
                  return (
                    <div className={style.bgvcantainer}>
                      {bgvRequestModels?.length > 0 && (
                        <div
                          className={style.cross}
                          onClick={() => {
                            let arr = [...bgvRequestModels];
                            arr?.splice(key, 1);
                            setBgvRequestModels([...arr]);
                          }}
                        >
                          ❌
                        </div>
                      )}
                      <div className={style.bgvrow1}>
                        <div>
                          <input
                            className={style.bgvInput}
                            value={bgvRequestModels[key].companyName}
                            onChange={(e) => {
                              let arr = [...bgvRequestModels];
                              arr[key].companyName = e?.target?.value;
                              setBgvRequestModels([...arr]);
                            }}
                          />
                        </div>

                        <div className={style.radiocantainer}>
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test1${key}`}
                              name={`radio-group${key}`}
                              checked={
                                bgvRequestModels[key]?.bgvstatus === "Done"
                              }
                              onChange={() => {
                                let arr = [...bgvRequestModels];
                                arr[key].bgvstatus = "Done";
                                setBgvRequestModels([...arr]);
                              }}
                            />
                            <label for={`test1${key}`}>Done</label>
                          </p>
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test2${key}`}
                              name={`radio-group${key}`}
                              checked={
                                bgvRequestModels[key]?.bgvstatus === "N.A"
                              }
                              onChange={() => {
                                let arr = [...bgvRequestModels];
                                arr[key].bgvstatus = "N.A";
                                setBgvRequestModels([...arr]);
                              }}
                            />
                            <label for={`test2${key}`}>N.A</label>
                          </p>
                        </div>

                        <div className={style.radiocantainer}>
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test3${key}`}
                              name={`positive${key}`}
                              checked={
                                bgvRequestModels[key]?.feedbackStatus === true
                              }
                              onChange={() => {
                                let arr = [...bgvRequestModels];
                                arr[key].feedbackStatus = true;
                                setBgvRequestModels([...arr]);
                              }}
                            />
                            <label for={`test3${key}`}>Positive</label>
                          </p>
                          <p className={style.radioitem}>
                            <input
                              type="radio"
                              id={`test4${key}`}
                              name={`positive${key}`}
                              checked={
                                bgvRequestModels[key]?.feedbackStatus === false
                              }
                              onChange={() => {
                                let arr = [...bgvRequestModels];
                                arr[key].feedbackStatus = false;
                                setBgvRequestModels([...arr]);
                              }}
                            />
                            <label for={`test4${key}`}>Negative</label>
                          </p>
                        </div>

                        {bgvRequestModels[key]?.bgvDocUrl ? (
                          <div>
                            <p className={style.rmpdf}>
                              {checkfileType(
                                bgvRequestModels[key]?.bgvDocUrl
                                  ?.split("@")[1]
                                  ?.split(".")[1]
                              )}
                            </p>
                            <p
                              className={style.rmpdfcross1}
                              onClick={() => {
                                deleteResume(
                                  bgvRequestModels[key]?.bgvDocUrl?.resumeUrl
                                );
                                let arr = [...bgvRequestModels];
                                arr[key].bgvDocUrl = null;
                                setBgvRequestModels([...arr]);
                              }}
                            >
                              x
                            </p>
                          </div>
                        ) : (
                          <div>
                            <label
                              htmlFor={`myFileInput${key}`}
                              className={style.fileInputLabel}
                            >
                              <input
                                type="file"
                                id={`myFileInput${key}`}
                                // name="myFile"
                                className={style.fileInput}
                                accept=".pdf,.doc,.docx"
                                onChange={(e) => {
                                  setUploadIn(key);
                                  uploadResumeMutation.mutate(
                                    e.target.files[0]
                                  );
                                }}
                              />
                            </label>
                          </div>
                        )}
                        <div>
                          <textarea
                            className={style.bgvInput}
                            value={bgvRequestModels[key].comments}
                            onChange={(e) => {
                              let arr = [...bgvRequestModels];
                              arr[key].comments = e?.target?.value;
                              setBgvRequestModels([...arr]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div
                  className={style.bgvcantainer}
                  style={{ marginBottom: "0px" }}
                >
                  <div className={style.bgvrow1}>
                    <div>
                      <p className={style.label}>Company Name</p>
                    </div>
                    <div>
                      <p className={style.label}>&nbsp;Status</p>
                    </div>
                    <div>
                      <p className={style.label}> &nbsp;Positive/Negative</p>
                    </div>
                    <div>
                      <p className={style.label}>Upload Doc</p>
                    </div>
                    <div>
                      <p className={style.label}>Comments</p>
                    </div>
                  </div>
                </div>
                <div className={style.bgvcantainer}>
                  <div className={style.bgvrow1}>
                    <div>
                      <input
                        className={style.bgvInput}
                        value={editRequest.companyName}
                        onChange={(e) => {
                          setEditRequest((prev) => ({
                            ...prev,
                            companyName: e?.target.value,
                          }));
                        }}
                      />
                      <p
                        className={
                          validation && !editRequest.companyName
                            ? style.errorText
                            : style.errorTextHidden
                        }
                      >
                        Please Enter Company Name
                      </p>
                    </div>

                    <div className={style.radiocantainer}>
                      <p className={style.radioitem}>
                        <input
                          type="radio"
                          id={`test1`}
                          name={`radio-group`}
                          onChange={(e) => {
                            setEditRequest((prev) => ({
                              ...prev,
                              bgvstatus: "Done",
                            }));
                          }}
                          checked={editRequest.bgvstatus === "Done"}
                        />
                        <label for={`test1`}>Done</label>
                      </p>
                      <p className={style.radioitem}>
                        <input
                          type="radio"
                          id={`test2`}
                          name={`radio-group`}
                          onChange={(e) => {
                            setEditRequest((prev) => ({
                              ...prev,
                              bgvstatus: "N.A",
                            }));
                          }}
                          checked={editRequest.bgvstatus === "N.A"}
                        />
                        <label for={`test2`}>N.A</label>
                      </p>
                    </div>

                    <div className={style.radiocantainer}>
                      <p className={style.radioitem}>
                        <input
                          type="radio"
                          id={`test3`}
                          name={`positive`}
                          onChange={(e) => {
                            setEditRequest((prev) => ({
                              ...prev,
                              feedbackStatus: true,
                            }));
                          }}
                          checked={editRequest.feedbackStatus === true}
                        />
                        <label for={`test3`}>Positive</label>
                      </p>
                      <p className={style.radioitem}>
                        <input
                          type="radio"
                          id={`test4`}
                          name={`positive`}
                          onChange={() => {
                            setEditRequest((prev) => ({
                              ...prev,
                              feedbackStatus: false,
                            }));
                          }}
                          checked={editRequest.feedbackStatus === false}
                        />
                        <label for={`test4`}>Negative</label>
                      </p>
                    </div>

                    {editRequest.bgvDocUrl ? (
                      <div>
                        <p className={style.rmpdf}>
                          {checkfileType(
                            editRequest.bgvDocUrl?.split("@")[1]?.split(".")[1]
                          )}
                        </p>
                        <p
                          className={style.rmpdfcross}
                          onClick={() => {
                            deleteResume(editRequest.bgvDocUrl);
                            setEditRequest((prev) => ({
                              ...prev,
                              bgvDocUrl: null,
                            }));
                          }}
                        >
                          x
                        </p>
                      </div>
                    ) : (
                      <div>
                        <label
                          htmlFor={`myFileInput`}
                          className={style.fileInputLabel}
                        >
                          <input
                            type="file"
                            id={`myFileInput`}
                            // name="myFile"
                            className={style.fileInput}
                            accept=".pdf,.doc,.docx"
                            onChange={(e) => {
                              // setUploadIn(key);
                              EditResumeMutation.mutate(e.target.files[0]);
                            }}
                          />
                        </label>
                      </div>
                    )}
                    <div>
                      <textarea
                        className={style.bgvInput}
                        value={editRequest.comments}
                        onChange={(e) => {
                          setEditRequest((prev) => ({
                            ...prev,
                            comments: e?.target?.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className={style.bgvbutton}>
              <button
                onClick={() =>
                  setBgvRequestModels((prev) => [
                    ...prev,
                    {
                      bgvStatus: "",
                      employeeId: userDescription?.id,
                      comments: "",
                      companyName: "",
                      feedbackStatus: "",
                      bgvDocUrl: null,
                    },
                  ])
                }
              >
                + ADD BGV
              </button>
              <button
                onClick={() =>
                  bgvByEmailMutation.mutate(userDescription?.personalEmail)
                }
              >
                Auto Fill BGV
              </button>
            </div>
          </div>

          {bgvRequestModels?.length > 0 && (
            <div className={style.bgvbutton} style={{ marginRight: "0.3rem" }}>
              <button onClick={() => handleBgv()}>Submit</button>
            </div>
          )}
          {!editStatus && (
            <div className={style.bgvbutton}>
              <button
                onClick={() => {
                  setEditRequest({});
                  setEditStatus(true);
                }}
                styles={{ marginRight: "1rem" }}
              >
                Cancel
              </button>
              <button onClick={() => handleEditBgv()}>Submit</button>
            </div>
          )}

          {/*BGV Table */}
          <div className={style.createposition}>
            <section className={style.th}>
              <div>Company Name</div>
              <div>Status</div>
              <div>Positive/Negative</div>
              <div>Upload Doc</div>
              <div>Comments</div>
              <div></div>
              <div></div>
            </section>
            {isLoading && <div>Loader...</div>}
            {isError && <div>Something Went Wrong</div>}
            {!isLoading && !isError ? (
              bgvByEmp?.length > 0 ? (
                bgvByEmp?.map((item, key) => {
                  return (
                    <section
                      className={style.td}
                      key={key}
                      style={{ position: "relative" }}
                    >
                      <CustomTooltipDiv
                        index={`c${key}`}
                        value={item?.companyName}
                        limit={25}
                        tooltipstyle={{ left: "5%" }}
                      />

                      <div>{item?.bgvstatus}</div>
                      <div>
                        {item?.feedbackStatus ? "Positive" : "Negative"}
                      </div>
                      <div>
                        <a
                          href={item?.bgvDocUrl}
                          title="Click Here"
                          target="_black"
                          download="example.pdf"
                          className={style.download}
                          style={{ fontSize: "1.3rem", color: "black" }}
                        >
                          {checkfileType(
                            item?.bgvDocUrl?.split("@")[1]?.split(".")[1]
                          )}
                        </a>
                      </div>
                      {item?.comments?.length > 70 ? (
                        <div
                          onClick={() => {
                            setShowComment(true);
                            setComment(item?.comments);
                          }}
                          style={{
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          {item?.comments}
                        </div>
                      ) : (
                        <div>{item?.comments}</div>
                      )}
                      <div
                        onClick={() => {
                          setEditStatus(false);
                          setEditRequest(item);
                          setBgvRequestModels([]);
                        }}
                      >
                        <MdModeEditOutline
                          style={{ color: "green", fontSize: "1.3rem" }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setShow(true);
                          setBgv(item?.id);
                        }}
                      >
                        {/*<MdDelete
                          style={{ color: "red", fontSize: "1.3rem" }}
                      />*/}
                      </div>
                    </section>
                  );
                })
              ) : (
                <div>No Data</div>
              )
            ) : null}
          </div>
          <Modal
            open={showCommnent}
            center
            onClose={() => setShowComment(false)}
            showCloseIcon={false}
            classNames={{
              modal: "reactmodal",
            }}
          >
            <div className={style.modal_container1}>
              <div>
                <p className={style.textM}>{comments}</p>
              </div>
              <div className={style.M_button}>
                <button
                  onClick={() => {
                    setShowComment(false);
                    setComment(null);
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </Modal>
          <ImageProfileView
            show={showImageProfile}
            image={userDescription?.uploadImageUrl}
            okaybtn={() => setShowImageProfile(false)}
          ></ImageProfileView>
          <ConfirmPopup open={candidateDropped}>
            <div className={style.confirmPopup}>
              <h5 className={style.confirmPopupTitle1}>
                Last Working Day <span style={{ color: "red" }}>*</span>
              </h5>
              <div className={style.csd} style={{ marginLeft: "43px" }}>
                <DatePicker
                  dateFormat="dd MMM yy"
                  // minDate={new Date()}
                  selected={lwdDate}
                  startDate={null}
                  onChange={(date) => setLwdDate(date)}
                  defaultValue={null}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<CustomInputClg />}
                  style={{ backgroundColor: "#F5F6FA" }}
                />
              </div>
              <p
                className={
                  error && !lwdDate ? style.errorText : style.errorTextHidden
                }
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please
                Select Last Working Day
              </p>

              <div className={style.btnCont}>
                <button
                  className={style.delete_btn}
                  // onClick={() =>
                  //   changeResignedStatus(userDescription?.id, "resigne")
                  // }
                  onClick={() => handleLastDay(userDescription?.id)}
                >
                  In-Active
                </button>
                <button
                  className={style.delete_btn}
                  onClick={() => {
                    setCondidateDropped(false);
                    setLwdDate(null);
                    setError(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </ConfirmPopup>
          <div className={style.container} style={{ marginBottom: "2rem" }}>
            <button
              className={style.back_btn}
              onClick={() =>
                history.push("/UsersList", {
                  id,
                  filterData,
                  currentPage,
                  filter,
                })
              }
            >
              Back
            </button>
            <div>
              <button
                className={style.edit_btn}
                onClick={edituser}
                style={{ marginRight: "1rem" }}
              >
                Edit
              </button>
              <button
                className={style.edit_btn}
                onClick={() => {
                  setShowbtn((prev) => !prev);
                }}
              >
                More
                <FiArrowDown
                  style={{ fontSize: "1.5rem", paddingLeft: "0.2rem" }}
                />
              </button>
            </div>
          </div>
          {showbtn && (
            <>
              <div
                className={style.container1}
                style={{ marginBottom: "1rem" }}
              >
                <button
                  className={style.back_btn}
                  onClick={() =>
                    deleteMutation.mutate({
                      employeeId: id,
                    })
                  }
                >
                  Delete
                </button>
                <button
                  className={style.Exit_btn}
                  onClick={() => setExitModal(true)}
                >
                  Mark as Exit Planned
                </button>
                <button
                  className={style.inActive_btn}
                  onClick={() => setLeadModal(true)}
                  style={{ width: "7rem" }}
                >
                  Assign Lead
                </button>
                {userDescription?.active ? (
                  <button
                    className={style.inActive_btn}
                    disabled={disabled}
                    // onClick={() => MarkasChange(EmployeeId, userDescription?.id)}
                    onClick={() => setCondidateDropped(true)}
                  >
                    Mark as In-Active
                  </button>
                ) : (
                  <button
                    className={style.active_btn}
                    disabled={disabled}
                    onClick={() =>
                      MarkasChange({
                        employeeId: localStorage.getItem("employeeId"),
                        lastWorkingDate: null,
                        userId: userDescription?.id,
                      })
                    }
                  >
                    Mark as Active
                  </button>
                )}
              </div>
              <div
                className={style.container1}
                style={{ marginBottom: "1rem" }}
              >
                {userDescription?.resignStatus ? (
                  <button
                    className={style.delete_btn1}
                    disabled={disabledResign}
                    onClick={() => changeStatusPopup1()}
                  >
                    Mark as Un-Resigned
                  </button>
                ) : (
                  <button
                    className={style.inActive_btn}
                    onClick={() => changeStatusPopup()}
                  >
                    Mark as Resign
                  </button>
                )}
                {
                  <button
                    className={style.inActive_btn}
                    onClick={() => {
                      setAddCanfirmation(true);
                    }}
                  >
                    Add Confirmation
                  </button>
                }
                <button
                  className={style.inActive_btn}
                  onClick={() => {
                    setRetainModal(true);
                  }}
                >
                  Mark as Retain
                </button>
                <button
                  className={style.assignClient}
                  onClick={() => {
                    setAssignClientShow(true);
                  }}
                >
                  Assign Client
                </button>
                <button
                  className={style.assignClient}
                  onClick={() => {
                    setAssignTeamShow(true);
                  }}
                >
                  Assign Team
                </button>
              </div>
            </>
          )}
        </div>
        <ConfirmPopup open={assignTeamShow}>
          <div className={style.confirmPopup}>
            <h5
              className={style.confirmPopupTitle1}
              style={{ marginLeft: "-18px" }}
            >
              Assign Team<span style={{ color: "red" }}>*</span>
            </h5>

            <div style={{ width: "76%", marginLeft: "45px" }}>
              {teamId === undefined || teamId === null ? (
                <span className={style.cp_serchIcon}>
                  <img src={dropdownIcon} alt="dropdownIcon" />
                </span>
              ) : (
                <span
                  className={style.cp_crossIcon}
                  onClick={() => {
                    setTeamId(null);
                  }}
                >
                  <img src={cancel} alt="cancel" />
                </span>
              )}
              <Select
                styles={customStyles}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  teamId ? teamOption?.find((e) => e?.value === teamId) : null
                }
                onChange={(e) => setTeamId(e.value)}
                options={teamOption}
              />
              <p
                className={
                  error && !teamId ? style.errorText : style.errorTextHidden
                }
              >
                &nbsp; Please Select Assign Team
              </p>
            </div>

            <div className={style.btnCont} style={{ marginTop: "1rem" }}>
              <button
                className={style.delete_btn}
                onClick={() => {
                  handleTeam();
                }}
              >
                Assign
              </button>
              <button
                className={style.delete_btn}
                onClick={() => {
                  setAssignTeamShow(false);
                  setError(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmPopup>
        <ConfirmPopup open={assignClientShow}>
          <div className={style.confirmPopup}>
            <h5
              className={style.confirmPopupTitle1}
              style={{ marginLeft: "-18px" }}
            >
              Assign Client
            </h5>

            <div style={{ width: "76%", marginLeft: "45px" }}>
              {clientId === undefined || clientId === null ? (
                <span className={style.cp_serchIcon}>
                  <img src={dropdownIcon} alt="dropdownIcon" />
                </span>
              ) : (
                <span
                  className={style.cp_crossIcon}
                  onClick={() => {
                    setClientId(null);
                  }}
                >
                  <img src={cancel} alt="cancel" />
                </span>
              )}
              <Select
                styles={customStyles}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  clientId
                    ? clientOption?.find((e) => e?.value === clientId)
                    : null
                }
                onChange={(e) => setClientId(e.value)}
                options={clientOption}
              />
            </div>

            <div className={style.btnCont} style={{ marginTop: "2rem" }}>
              <button
                className={style.delete_btn}
                onClick={() => {
                  assignClientMutation?.mutate({
                    clientId,
                    empId: id,
                  });
                }}
              >
                Assign
              </button>
              <button
                className={style.delete_btn}
                onClick={() => setAssignClientShow(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmPopup>
        <ConfirmPopup open={addCanfirmation}>
          <div className={style.confirmPopup1}>
            <h5 className={style.exitTitle}>
              Confirmation <span style={{ color: "red" }}>*</span>
            </h5>
            <div style={{ width: "76%", marginLeft: "45px" }}>
              <Select
                styles={customStyles}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) => handleConfimation(e)}
                options={canfirmationOption?.sort(
                  (a, b) => a?.value - b?.value
                )}
              />
            </div>
            <p
              className={
                errorCanfirmation && !confirmationData?.periodId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;Please Select Confirmation
            </p>
            <h5 className={style.exitTitle} style={{ paddingTop: "0rem" }}>
              Confirmation Date
            </h5>
            <div
              className={style.csd}
              style={
                disabledConfirmationDate(
                  confirmationData?.periodId,
                  "confimationDate"
                )
                  ? {
                      background: "#8f8b8b69",
                      marginLeft: "49px",
                      width: "74%",
                    }
                  : {
                      marginLeft: "49px",
                      width: "74%",
                    }
              }
            >
              <DatePicker
                dateFormat="dd MMM yy"
                selected={
                  confirmationData?.confirmationDate
                    ? moment(confirmationData?.confirmationDate).toDate()
                    : null
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                disabled={disabledConfirmationDate(
                  confirmationData?.periodId,
                  "confimationDate"
                )}
                dropdownMode="select"
                startDate={null}
                onChange={(date) =>
                  setConfirmationData((prevData) => ({
                    ...prevData,
                    confirmationDate: date,
                  }))
                }
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>

            <h5 className={style.exitTitle} style={{ paddingTop: "1.1rem" }}>
              Actual Confimation Date
            </h5>
            <div
              className={style.csd}
              style={
                disabledConfirmationDate(confirmationData?.periodId)
                  ? {
                      background: "#8f8b8b69",
                      marginLeft: "49px",
                      width: "74%",
                    }
                  : {
                      marginLeft: "49px",
                      width: "74%",
                    }
              }
            >
              <DatePicker
                dateFormat="dd MMM yy"
                // minDate={new Date()}
                selected={
                  confirmationData?.actualConfirmationDate
                    ? moment(confirmationData?.actualConfirmationDate).toDate()
                    : null
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                disabled={disabledConfirmationDate(confirmationData?.periodId)}
                dropdownMode="select"
                startDate={null}
                onChange={(date) =>
                  setConfirmationData((prevData) => ({
                    ...prevData,
                    actualConfirmationDate: date,
                  }))
                }
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
            <h5 className={style.exitTitle} style={{ paddingTop: "1.2rem" }}>
              Remarks
            </h5>
            <div style={{ marginLeft: "49px" }}>
              <textarea
                type="textarea"
                disabled={disabledConfirmationDate(confirmationData?.periodId)}
                className={style.textarea}
                onChange={(e) => {
                  setConfirmationData((prev) => ({
                    ...prev,
                    delayReason: e.target?.value,
                  }));
                }}
              />
            </div>

            <div className={style.btnCont} style={{ marginBottom: "26px" }}>
              <button
                className={style.mark_btn}
                onClick={() => handleConfirmationDate()}
              >
                Confirmation
              </button>
              <button
                className={style.delete_btn}
                onClick={() => {
                  setAddCanfirmation(false);
                  setErrorCanfirmation(false);
                  setConfirmationData((prev) => ({
                    ...prev,
                    actualConfirmationDate: null,
                    confirmationDate: null,
                    delayReason: null,
                    periodId: null,
                  }));
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmPopup>
        <ConfirmPopup open={retainModal}>
          <div className={style.confirmPopup1} style={{ height: "260px" }}>
            <h5 className={style.exitTitle}>
              Retain <span style={{ color: "red" }}>*</span>
            </h5>
            <div style={{ width: "273px", marginLeft: "45px" }}>
              <Select
                styles={customStyles}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) =>
                  setRetain((prev) => ({ ...prev, retain: e?.value }))
                }
                options={retainOption}
              />
            </div>
            <p
              className={
                errorRetain && errorMess()
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;Please Select Retain
            </p>

            <h5 className={style.exitTitle} style={{ paddingTop: "0px" }}>
              Date <span style={{ color: "red" }}>*</span>
            </h5>
            <div className={style.csd} style={{ marginLeft: "49px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                // minDate={new Date()}
                selected={
                  retain?.retainDate
                    ? moment(retain?.retainDate).toDate()
                    : null
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                startDate={null}
                onChange={(date) =>
                  setRetain((prevData) => ({
                    ...prevData,
                    retainDate: date,
                  }))
                }
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
            <p
              className={
                errorRetain && !retain?.retainDate
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Please Select Date
            </p>

            <div className={style.btnCont} style={{ marginTop: "2px" }}>
              <button
                className={style.mark_btn}
                onClick={() => handleReatain()}
              >
                Mark as Retain
              </button>
              <button
                className={style.delete_btn}
                onClick={() => {
                  setRetainModal(false);
                  setRetain((prev) => ({
                    ...prev,
                    retain: "",
                    retainDate: null,
                  }));
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmPopup>
        <ConfirmPopup open={exitModal}>
          <div className={style.confirmPopup1}>
            <h5 className={style.exitTitle}>
              Exit Decision Date{" "}
              {!userDescription?.exitDecisionDate &&
                !userDescription?.exitPlanDate && (
                  <span style={{ color: "red" }}>*</span>
                )}
            </h5>
            <div className={style.csd} style={{ marginLeft: "49px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                // minDate={new Date()}
                selected={
                  markAsExitData?.exitDecisionDate
                    ? moment(markAsExitData?.exitDecisionDate).toDate()
                    : null
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                startDate={null}
                onChange={(date) =>
                  setMarkAsExit((prevData) => ({
                    ...prevData,
                    exitDecisionDate: date,
                  }))
                }
                defaultValue={null}
                customInput={
                  markAsExitData?.exitDecisionDate ? (
                    <CustomInputCross properties="exitDecisionDate" />
                  ) : (
                    <CustomInputClg />
                  )
                }
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
            <p
              className={
                error &&
                !userDescription?.exitDecisionDate &&
                !markAsExitData?.exitDecisionDate
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Select Exit
              Decision Date
            </p>
            <h5 className={style.exitTitle} style={{ paddingTop: "0px" }}>
              Exit Planned Date
            </h5>
            <div className={style.csd} style={{ marginLeft: "49px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                // minDate={new Date()}
                selected={
                  markAsExitData?.exitPlanDate
                    ? moment(markAsExitData?.exitPlanDate).toDate()
                    : null
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                startDate={null}
                onChange={(date) =>
                  setMarkAsExit((prevData) => ({
                    ...prevData,
                    exitPlanDate: date,
                  }))
                }
                defaultValue={null}
                customInput={
                  markAsExitData?.exitPlanDate ? (
                    <CustomInputCross properties="exitPlanDate" />
                  ) : (
                    <CustomInputClg />
                  )
                }
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
            <h5 className={style.exitTitle} style={{ paddingTop: "1rem" }}>
              Exit Note
            </h5>
            <div style={{ marginLeft: "49px", marginRight: "1.5rem" }}>
              <textarea
                type="textarea"
                className={style.textarea}
                onChange={(e) => {
                  setMarkAsExit((prevData) => ({
                    ...prevData,
                    exitComments: e?.target.value,
                  }));
                }}
              />
            </div>

            <div className={style.btnCont} style={{ paddingBottom: "2rem" }}>
              <button
                className={style.mark_btn}
                onClick={() => handleMarkAsExit()}
              >
                Mark as Exit Planned
              </button>
              <button
                className={style.delete_btn}
                onClick={() => {
                  setError(false);
                  setExitModal(false);
                  setMarkAsExit((prevData) => ({
                    ...prevData,
                    exitPlanDate: "",
                    exitDecisionDate: "",
                  }));
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmPopup>
        <ConfirmPopup open={leadModal}>
          <div className={style.confirmPopupLead}>
            <h5 className={style.confirmPopupTitleLead}>
              Assign Lead <span style={{ color: "red" }}>*</span>
            </h5>
            <div style={{ width: "330px", margin: "0px auto" }}>
              <Select
                styles={customStyles}
                classNamePrefix={"create_position"}
                components={{
                  DropdownIndicator: () => CustomInput(),
                  IndicatorSeparator: () => null,
                }}
                onChange={(e) =>
                  setFormData({ ...formData, leadEmpId: e.value })
                }
                options={AllUsersList}
              />

              <p
                className={
                  validate && !formData?.leadEmpId
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Select Assign Lead
              </p>
            </div>
            <div className={style.btnCont}>
              <button
                className={style.unResigned}
                onClick={() => AssignLeadMethod()}
              >
                Assign Lead
              </button>
              <button
                className={style.delete_btn}
                onClick={() => {
                  setLeadModal(false);
                  setValidate(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmPopup>
        <ConfirmPopup open={confirmModal}>
          <div className={style.confirmPopup}>
            <h5 className={style.confirmPopupTitle1}>
              Resign Date <span style={{ color: "red" }}>*</span>
            </h5>
            <div className={style.csd} style={{ marginLeft: "60px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                // minDate={new Date()}
                selected={resignedDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                startDate={null}
                onChange={(date) => setResignedDate(date)}
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>
            <p
              className={
                error && !resignedDate ? style.errorText : style.errorTextHidden
              }
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please
              Select Resign Date
            </p>

            <div className={style.btnCont}>
              <button
                className={style.delete_btn}
                onClick={() =>
                  changeResignedStatus(userDescription?.id, "resigne")
                }
              >
                Resign
              </button>
              <button
                className={style.delete_btn}
                onClick={() => handleConfirmPopupClose()}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmPopup>
        <ConfirmPopup open={UnconfirmModal}>
          <div className={style.confirmPopup}>
            <h5 className={style.confirmPopupTitle}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Un-Resigned
              Date
            </h5>
            <div className={style.csd} style={{ marginLeft: "60px" }}>
              <DatePicker
                dateFormat="dd MMM yy"
                // minDate={new Date()}
                selected={resignedDate}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                startDate={null}
                onChange={(date) => setResignedDate(date)}
                defaultValue={null}
                customInput={<CustomInputClg />}
                style={{ backgroundColor: "#F5F6FA" }}
              />
            </div>

            <p
              className={
                error && !resignedDate ? style.errorText : style.errorTextHidden
              }
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Please Select Un-Resigned Date
            </p>
            <div className={style.btnCont}>
              <button
                className={style.unResigned}
                onClick={() =>
                  changeResignedStatus(userDescription?.id, "unResigned")
                }
              >
                Un-Resigned
              </button>
              <button
                className={style.delete_btn}
                onClick={() => changeOnCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </ConfirmPopup>
      </div>
    </>
  );
};

export default ViewUser;
