import React, { useState, useEffect } from "react";
import style from "./ViewQuestionnaireBySkillsCom.module.css";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import cookie from "react-cookies";
import Select from "react-select";
import Moment from "moment";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Toastify } from "../../../../App";
import { ToastContainer } from "react-toastify";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearch } from "../../CommonComponent/style";
import "react-responsive-modal/styles.css";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmPopup from "../../CommonComponent/ConfirmPopup";
import { generateRandomString } from "../../CommonComponent/GenerateRandomSreing";

export const ViewQuestionnaireBySkillsCom = () => {
  const url = process.env.REACT_APP_QUESTIONNAIRE_URL;
  const [viewQuestion, setViewQuestion] = useState();
  const [selectedItemId, setselectedItemId] = useState();
  const [editDetails, setEditDetails] = useState();
  const [deleteQuestionnaireId, setDeleteQuestionnaireId] = useState();
  const [confirmModal, setConfirmModal] = useState(false);
  const [clientListing, setClientListing] = useState([]);
  const [seniority, setSeniority] = useState([]);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [clientNameSelect, setClientName] = useState(false);
  const [AllData, setAllData] = useState({
    seniorityId: 0,
    SeniorityLabel: "",
    clientId: 0,
    clientLabel: "",
  });
  const [instructionHide, setInstructionHide] = useState(false);

  const iconStyle = {
    color: "#964431",
    cursor: "pointer",
  };
  useEffect(() => {
    setInstructionHide(AllData?.clientId ? true : false);
  }, [AllData?.clientId]);

  // cancel Handler
  const cancelHandler = () => {
    setselectedItemId();
    setEditDetails();
  };

  // onSearchHandler
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code1":
          setAllData({
            ...AllData,
            seniorityId: e?.value,
            SeniorityLabel: e?.label,
          });
          // setclientName({ ...clientName, positionCode: e?.label });
          break;
        case "Code2":
          setAllData({ ...AllData, clientId: e?.value, clientLabel: e?.label });
          // setclientName({ ...clientName, positionCode: e?.label });
          break;
        default:
          return null;
      }
    }
  };

  const handleTextareaChange = (event) => {
    const { value } = event.target;
    setEditDetails((prevEditDetails) => {
      return prevEditDetails.map((item) => {
        if (item.questionnaireId === selectedItemId) {
          return { ...item, questions: value };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [selectedItemId]);

  const history = useHistory();
  const adjustTextareaHeight = () => {
    const textareaElement = document.getElementById(selectedItemId);
    if (textareaElement) {
      textareaElement.style.height = "auto";
      textareaElement.style.height = `${textareaElement.scrollHeight}px`;
    }
  };

  const location = useLocation();
  const { state } = location;

  const editHandler = (id) => {
    setselectedItemId(id);
    setEditDetails(
      viewQuestion?.getQuestionsBySkillsFilterList.filter(
        (item) => item.questionnaireId == id
      )
    );
  };

  const cardStyle = {
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const titleStyle = {
    fontWeight: "bold",
  };

  const dateStyle = {
    fontWeight: 500,
    /* align-self: flex-start; */
    paddingLeft: "11px",
    color: "#964431",
  };

  const addedByStyle = {
    alignSelf: "center",
    fontWeight: 500,
    /* align-self: flex-start; */
    paddingLeft: "11px",
    color: "#964431",
  };

  const editDeleteStyle = {
    alignSelf: "flex-end",
  };

  const getSeniorityList = () => {
    axios
      .get(url + "/getSeniority", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setSeniority(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getClientListing = () => {
    axios
      .get(url + "/getClientForDashboard", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getViewQuestion = (id) => {
    axios
      .get(
        url +
          `/get-questions-by-skills?clientId=${AllData?.clientId}&seniorityId=${AllData?.seniorityId}&skillId=${state?.technologyId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setViewQuestion(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    state && getViewQuestion();
    getSeniorityList();
    getClientListing();
  }, [AllData]);

  // Delete Handler
  const deleteHandler = (id) => {
    setDeleteQuestionnaireId(id);
    setConfirmModal(true);
  };

  const EditformHAndler = (e) => {
    const data = {
      clientId: editDetails[0]?.clientId,
      empId: editDetails[0]?.addedById,
      id: editDetails[0]?.questionnaireId,
      maxExperience: editDetails[0]?.maxExperience,
      minExperience: editDetails[0]?.minExperience,
      questions: editDetails[0]?.questions,
      seniorityId: editDetails[0]?.seniorityId,
      submissionDate: new Date(),
      technologyId: editDetails[0]?.skillId,
    };

    e.preventDefault();
    axios
      .post(url + "/createQuestions", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        Toastify("Question Edited Successfully");
        getViewQuestion();
        setselectedItemId();
      })
      .catch((err) => {
        return err;
      });
  };

  const deleteIssueHandler = (id) => {
    axios
      .delete(url + `/delete-questionnaire?questionnaireId=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        Toastify("Question Deleted Successfully");
        getViewQuestion();
        setConfirmModal(false);
        // handleRedirect(res.data.responseData);
        // setButtonDisable(true);
      })
      .catch((err) => {
        return err;
      });
  };

  const getSeniorityListt = seniority?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const handleCheckboxChange1 = (checkboxId) => {
    const isChecked = checkedBoxes.includes(checkboxId);

    if (isChecked) {
      setCheckedBoxes(checkedBoxes.filter((id) => id !== checkboxId));
    } else {
      setCheckedBoxes([...checkedBoxes, checkboxId]);
    }
  };

  const handleClientNameSelectHandler = () => {
    setClientName(!clientNameSelect);
  };
  const handleSelectAllChange = () => {
    // Toggle the selectAllChecked state
    setSelectAllChecked(!selectAllChecked);

    // Update the checkedBoxes state based on the selectAllChecked status
    if (!selectAllChecked) {
      // If selectAllChecked is false, select all checkboxes
      const allCheckboxIds = viewQuestion?.getQuestionsBySkillsFilterList?.map(
        (item) => item.questionnaireId
      );
      setCheckedBoxes(allCheckboxIds);
    } else {
      // If selectAllChecked is true, deselect all checkboxes
      setCheckedBoxes([]);
    }
  };

  const generateLinkHandle = () => {
    history.push({
      pathname: `/viewQuestionDetailsById/${generateRandomString()}`,
      state: { clientNameSelect, checkedBoxes, instructionHide },
    });
  };
  return (
    <>
      <ToastContainer />
      <div className={style.main}>
        <div className={style.Cu_row1}>
          <div className={style.cu_col1}>
            <span className={style.cu_label}>Skill</span> :{" "}
            <span>{viewQuestion?.skill}</span>
          </div>
          <div className={style.cu_col5}>
            <div className={style.s1}>
              {AllData?.clientId === 0 ? (
                <span className={style.cp_serchIcon2}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon2}
                  onClick={() => {
                    setAllData({
                      ...AllData,
                      clientId: 0,
                      clientLabel: "",
                    });
                  }}
                >
                  {AllData?.clientId !== 0 ? <AiOutlineClose /> : null}
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Search Client"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  AllData?.clientId
                    ? {
                        value: AllData?.clientId,
                        label: AllData?.clientLabel,
                      }
                    : null
                }
                onChange={(e) => {
                  onSearchHandler(e, "Code2");
                }}
                options={getClientList}
              />
            </div>
          </div>
          <div className={style.cu_col4}>
            <div className={style.s1}>
              {AllData?.seniorityId === 0 ? (
                <span className={style.cp_serchIcon2}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon2}
                  onClick={() => {
                    setAllData({
                      ...AllData,
                      seniorityId: 0,
                      SeniorityLabel: "",
                    });
                  }}
                >
                  {AllData?.seniorityId !== 0 ? <AiOutlineClose /> : null}
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Search Seniority"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  AllData?.seniorityId
                    ? {
                        value: AllData?.seniorityId,
                        label: AllData?.SeniorityLabel,
                      }
                    : null
                }
                onChange={(e) => {
                  onSearchHandler(e, "Code1");
                  // setCurrentPage(1);
                }}
                options={getSeniorityListt}
              />
            </div>
          </div>
          <div className={style.cu_col3}>
            <button
              onClick={() => generateLinkHandle()}
              className={
                checkedBoxes?.length !== 0
                  ? style.generateLink
                  : style.generateLinkDisable
              }
              disabled={checkedBoxes?.length === 0}
            >
              <AttachmentIcon />
              Generate Link
            </button>
          </div>
        </div>
        {viewQuestion?.getQuestionsBySkillsFilterList?.length === 0 ? (
          <p className={style?.noDataFound}>No Data Available</p>
        ) : (
          <>
            <div style={{ display: "flex", marginTop: "2rem" }}>
              {" "}
              <label className={style.container}>
                Select All
                <input type="checkbox" onChange={handleSelectAllChange} />
                <span className={style.checkmark}></span>
              </label>
              {AllData?.clientId ? (
                <label className={style.container}>
                  Share Client Name
                  <input
                    type="checkbox"
                    onChange={handleClientNameSelectHandler}
                  />
                  <span className={style.checkmark}></span>
                </label>
              ) : null}
            </div>

            {viewQuestion?.getQuestionsBySkillsFilterList?.map((item) => (
              <div key={item.questionnaireId}>
                <div className={style.card}>
                  <span>
                    {" "}
                    <label className={style.container1}>
                      <input
                        type="checkbox"
                        checked={checkedBoxes.includes(item.questionnaireId)}
                        onChange={() =>
                          handleCheckboxChange1(item.questionnaireId)
                        }
                      />
                      <span className={style.checkmark1}></span>
                    </label>
                  </span>
                  <div style={cardStyle}>
                    <div style={{ ...addedByStyle }}>
                      Client Name : {item?.clientName}
                    </div>
                    <div style={{ ...addedByStyle }}>
                      Experience : {item?.minExperience} - {item?.maxExperience}{" "}
                      Years
                    </div>
                    <div style={{ ...addedByStyle }}>
                      Seniority : {item?.seniority}
                    </div>
                    <div style={{ ...dateStyle }}>
                      Date : {Moment(state?.date).format("D MMM YY")}
                    </div>
                    <div style={{ ...addedByStyle }}>
                      Added By : {item?.addedBy}
                    </div>
                    <div style={cardStyle}>
                      <div style={{ ...editDeleteStyle }}>
                        <CreateIcon
                          style={iconStyle}
                          onClick={() => editHandler(item?.questionnaireId)}
                        />
                      </div>
                      <div
                        style={{ ...titleStyle, ...editDeleteStyle }}
                        onClick={() => deleteHandler(item?.questionnaireId)}
                      >
                        <DeleteIcon style={iconStyle} />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className={style.cu_col1} style={{ marginLeft: "21px" }}>
                    {item.questionnaireId === selectedItemId ? (
                      <textarea
                        id={item?.questionnaireId}
                        style={{
                          whiteSpace: "pre-line",
                          paddingBottom: "10px",
                          height: "100px",
                          resize: "none",
                          width: "98%",
                          outline: "none",
                          paddingLeft: "5px",
                        }}
                        value={editDetails[0]?.questions}
                        onChange={handleTextareaChange}
                      ></textarea>
                    ) : (
                      <p style={{ whiteSpace: "pre-line" }}>{item.questions}</p>
                    )}
                    {item.questionnaireId === selectedItemId ? (
                      <div className={style?.buttonAlign}>
                        {" "}
                        <button
                          className={style.cancel}
                          onClick={() => cancelHandler()}
                        >
                          Cancel
                        </button>
                        <button
                          className={style.edit}
                          onClick={EditformHAndler}
                        >
                          Done
                        </button>{" "}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <ConfirmPopup open={confirmModal}>
        <div className={style.confirmPopup1}>
          <h5 className={style.confirmPopupTitle}>
            Are you sure you want to Delete?
          </h5>
          <div className={style.btnCont}>
            <button
              className={style.yesBtn}
              onClick={() => deleteIssueHandler(deleteQuestionnaireId)}
            >
              Yes
            </button>
            <button
              className={style.noBtn}
              onClick={() => {
                setConfirmModal(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </ConfirmPopup>
    </>
  );
};
