import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../../../taskTracker/commonComponent/Header";
import SideBar from "../../../Clients/component/SideBar";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom";
import style from "./OnsiteRecord.module.css";
import { Tooltip } from "@material-ui/core";
import SubjectIcon from "@mui/icons-material/Subject";
import AppsIcon from "@mui/icons-material/Apps";
import constants from "../../../../../Utils/constants";
import OnsiteReportData from "../OnsiteReportData/OnsiteReportData";

function OnsiteRecordData(props) {
  const history = useHistory();
  const[pagehandle,setpagehandle]=useState(1)
  const[loading,setLoading]=useState(true);
  const [selectedClientId, setSelectedClientid] = useState("");
  const [clientData, setClientData] = useState();
  const [employee, setEmployee] = useState();
  const [listSelect, setlistSelect] = useState(props.location.pathname==="/OnSite"?true:false);
  const baseUrl = constants.apiBaseUrlResource;
  const url = process.env.REACT_APP_UMS_APP_URL;

  const [selectedValue, setSelectedValue] = useState("OnSite");
  // radio button handler
  function handleOptionChange(event) {
    setSelectedValue(event.target.value);
  }

  useEffect(() => {
    if(selectedValue!=="OnSite"){
      ReportHandler();
    }
  }, [selectedValue]);

  function getAllOnSiteData(clientId) {
    axios
      .get(
        baseUrl +
          `/getAllOnsiteDataByTechnologyFromClientId?clientId=${clientId}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setEmployee(response.data.responseData);
        // Do something with the API response data
      })
      .catch((error) => {
        console.error(error);
        // Handle any API errors
      });
  }

  useEffect(() => {
    axios
      .get(baseUrl + `/getAllActiveClientsOfOnsite`)
      .then((response) => {
        console.log(response.data);
        setClientData(response.data.responseData);
        setLoading(false)
        // setSelectedClientid(response.data.responseData[0].id);
        // Do something with the API response data
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getAllOnSiteData(selectedClientId);
  }, [selectedClientId]);

  const handleProjectClick = (projectid) => {
    setSelectedClientid(projectid);
    setpagehandle(2)
  };

  // Data Handler
  const Listhandler = () => {
    setlistSelect(true)
  };

  const ReportHandler1 = () => {
    setlistSelect(false)
  };

  // total Client
  let total = 0;
  clientData?.forEach((project) => {
    total += project?.count;
  });

  // Report Handler
  const ReportHandler = () => {
    console.log(selectedValue); // add this line to check the value of selectedValue
    switch (selectedValue) {
      case "Pool":
        history.push("/PoolRecord");
        break;
      case "OffShore":
        history.push("/OffShoreRecord");
        break;
      case "OnSite":
        history.push("/OnSiteRecord");
        break;
      default:
        // Code to handle any other location not covered by the previous cases
        break;
    }
  };

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />
        {loading?<p className={style.loadingP}>Loading...</p>:
        <div className={style.secondDiv}>
          <div className={style.iconset}>
            <p style={{ margin: 0 ,width:"20%"}}>Onsite Resources</p>
            <label>
              <input
                type="radio"
                name="location"
                value="OffShore"
                checked={selectedValue === "OffShore"}
                onChange={handleOptionChange}
              />
              Offshore
            </label>
            <label>
              <input
                type="radio"
                name="location"
                value="OnSite"
                checked={selectedValue === "OnSite"}
                onChange={handleOptionChange}
              />
              Onsite
            </label>

            <label>
              <input
                type="radio"
                name="location"
                value="Pool"
                checked={selectedValue === "Pool"}
                onChange={handleOptionChange}
              />
              Pool
            </label>
            <div>
              <Tooltip title="Report" placement="bottom">
                <AppsIcon className={listSelect?style.Icon:style.Icon1} onClick={ReportHandler1} />
              </Tooltip>{" "}
              <Tooltip title="Listing" placement="bottom">
                <SubjectIcon
                  className={listSelect?style.Icon1:style.Icon}
                  onClick={() => Listhandler()}
                />
              </Tooltip>
            </div>
          </div>
       
         {clientData ? (
            <div className={style.buttonContainer}>
              <button
                className={
                  "" === selectedClientId ? style.button : style.activeButton
                }
                onClick={() => handleProjectClick("")}
              >
                ALL
                <span
                  className={
                    "" === selectedClientId ? style.count1 : style.count2
                  }
                >
                  {total}
                </span>
              </button>
              {clientData?.map((client) => (
                <button
                  key={client.id}
                  className={
                    client.id === selectedClientId
                      ? style.button
                      : style.activeButton
                  }
                  onClick={() => handleProjectClick(client.id)}
                >
                  {client.name}{" "}
                  <span
                    className={
                      client.id === selectedClientId
                        ? style.count1
                        : style.count2
                    }
                  >
                    {client?.count}
                  </span>
                </button>
              ))}
            </div>
          ) : (
            <p className={style.noRecordPara}>No Client Found</p>
          )}

          {listSelect?(<OnsiteReportData client={selectedClientId} page={pagehandle} />):(<div style={{ display: "flex", flexWrap: "wrap" }}>
            {employee &&
              employee.map((data) => {
                return (
                  <div style={{ width: "99%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className={style.projectHeading}>{data?.techName}</p>
                      <span className={style.count} style={{ flexShrink: 0 }}>
                        {data?.details?.length}
                      </span>
                    </div>

                    <div key={data.techName} className={style.div}>
                      {data.details && (
                        <table>
                          <thead className={style.thead}>
                            <tr style={{ fontSize: "12px" }}>
                              <th className={style.th}>Name</th>
                              <th className={style.th}>Exp</th>
                              <th
                                className={
                                  !selectedClientId ? style.td21 : style.td4
                                }
                              >
                                Salary Range
                              </th>
                              {!selectedClientId && (
                                <th className={style.th}>Client Name</th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {data.details.map((emp) => (
                              <tr
                                key={emp.name}
                                style={{ borderBottom: "1px solid #ebe5e5",fontSize:"14px",height:"2.3rem"}}
                              >
                                <td className={style.td1}>{emp.empName}</td>
                                <td className={style.td2}>
                                  {~~(emp.experience / 12)} Y -{" "}
                                  {emp.experience % 12} M
                                </td>
                                <td
                                  className={
                                    !selectedClientId ? style.td21 : style.td4
                                  }
                                  style={
                                    emp.salaryRange === "Very High"
                                      ? { color: "red" }
                                      : null
                                  }
                                >
                                  {emp.salaryRange}
                                </td>
                                <td
                                  className={
                                    !selectedClientId ? style.th : style.td4
                                  }
                                >
                                  {!selectedClientId ? emp.projectName : ""}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>)}
        </div>}
      </div>
    </>
  );
}

export default OnsiteRecordData;
