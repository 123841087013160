import React, { useState } from "react";
import { Container, Row, Col, Button, Card, Table } from "react-bootstrap";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import filterimg from "../../../../../assets/filter-results-button.png";
import Polygon from "../../../../../assets/Polygon.png";
import Iconionicmdcheckbox from "../../../../../assets/Iconionicmdcheckbox.png";
import Filter from "../common/Filter";
import "./MobileComp.css";
import { useHistory } from "react-router-dom";
const ary = [
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
    f: "Lorem Ipsum",
    g: "Lorem Ipsum",
    h: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
    f: "Lorem Ipsum",
    g: "Lorem Ipsum",
    h: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
    f: "Lorem Ipsum",
    g: "Lorem Ipsum",
    h: "Lorem Ipsum",
  },
  {
    a: "Lorem Ipsum",
    b: "Lorem Ipsum",
    c: "Lorem Ipsum",
    d: "Lorem Ipsum",
    e: "Lorem Ipsum",
    f: "Lorem Ipsum",
    g: "Lorem Ipsum",
    h: "Lorem Ipsum",
  },
];
const MobileComponet = () => {
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [applied, setApplied] = useState(false);
  const history = useHistory();

  const handleApplied = () => {
    setApplied(true);
    setShowFilter(false);
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };
  const addMobile = () => {
    history.push("/laptop/addLaptop");
  };

  const closeFilter = () => {
    setShowFilter(false);
    setApplied(false);
  };
  const handleExp = () => {
    history.push("/laptop/assign");
  };
  return (
    <>
      <div className="main">
        <Container>
          <Row>
            <Col sm={12} className="hed"></Col>
          </Row>
        </Container>
        <Container className="mt-1 mb-2">
          <Row className="mobile_filter ml-0">
            <Col sm={4} className="">
              Laptops
            </Col>
            <Col sm={6}>
              {" "}
              <button className="filter" onClick={handleFilter}>
                <img src={filterimg} />
                <span>Filter</span>
              </button>
              {applied && (
                <img className="filter_img" src={Iconionicmdcheckbox} />
              )}
              {showFilter && (
                <img className="polygon filter_img2" src={Polygon} />
              )}
            </Col>
            <Col sm={2}>
              <Button onClick={addMobile} variant="primary">
                <AddIcon /> Add Laptop
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className="mt-0">
          <Card className="m_card">
            <Table responsive className="tabl">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Brand</th>
                  <th>Model</th>
                  <th>MAC</th>
                  <th>RAM</th>
                  <th>OS</th>
                  <th>OS Version</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {ary.map((ar) => (
                  <tr className="tabledata">
                    <td className="tabletaskId">{ar.a}</td>
                    <td>{ar.b}</td>
                    <td>{ar.c}</td>
                    <td>{ar.d}</td>
                    <td>{ar.e}</td>
                    <td>{ar.f}</td>
                    <td>{ar.h}</td>
                    <td>{ar.g}</td>
                    <td className="tableaction">
                      {/* <img
                  onClick={() => history.push("/viewTask")}
                  src={pencilEditButtonImg}
                />
                <img src={rubbishBinImg} /> */}
                      {/* <EditIcon color="primary" /> */}
                      <VisibilityIcon onClick={handleExp} color="primary" />

                      {/* <DeleteIcon color="primary" /> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Container>
        {/* <button onClick={handleExp}>
          <img style={{ width: "15px", marginRight: "10px" }} />
          <span>Test Page</span>
        </button> */}
      </div>
      {showFilter && (
        <Filter closeFilter={closeFilter} handleApplied={handleApplied} />
      )}
    </>
  );
};

export default MobileComponet;
