import React, { useState } from "react";
import cx from "classnames";
import { Container, Row, Col, Card } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Purchase.module.css"
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

const PurchaseTab = () => {
    return (   <div>
             <form>
            <Container>
              <p className={styles.iconHeadingContainer}>
                <LocalAtmIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116, styles.sectionHeading, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}>Purchase</span>
              </p>
              <div style={{display:"flex"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> Serial Number</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Brand</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Model</label> <br />
                  <input type="text" id="fname" name="fname" className={styles.input}></input>
                </div>
              </div>         
              </Container>    
              </form>
             </div>  );
}
 
export default PurchaseTab;