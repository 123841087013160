import React, { useEffect } from "react";
import { Col, Container, Row, Modal, Form } from "react-bootstrap";
import style from "../ViewTimeSheetById/ViewTimeSheetById.module.css";
import { useMutation, useQuery } from "react-query";
import {
  getTimesheetId,
  ViewTeamTimeSheetBYID,
  plapproved1,
  plapproved,
} from "../../../../Utils/axios/NewTimeSheetApi";
import { Toastify, ToastifyError } from "../../../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import CustomTooltip from "../../../NewEmployeeTimeSheet/customTooltip/CustomTooltip.js";
const ViewTimeSheetById = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [TextData, setTextData] = useState();
  const [RadioButton, setRadioButton] = useState();
  const [ProjectName, setProjectName] = useState();

  const { data: getTimesheetDataId } = useQuery(["getTimesheetId"], () =>
    getTimesheetId(prevData?.timesheetId)
  );

  const { mutate: plapprovedData } = useMutation(plapproved1, {
    // refetchOnWindowFocus: true,
    // staleTime: 0,
    // cacheTime: 0,
    // refetchInterval: 1000,
    onSuccess:(res)=>{
      if(res?.responseStatus?.statusCode===0){
        ToastifyError(res?.responseData?.message)
      }else{
        Toastify("Timesheet PL status not approved")
      }
    
      refetch()
    }
  });

  const { mutate: plapprovedID } = useMutation(plapproved, {
    onSuccess:(res)=>{
      // ToastifyError(res.message)
      if(res?.responseStatus?.statusCode===0){
        ToastifyError(res?.responseData?.message)
      }else{
        Toastify("Timesheet PL status approved Successfully")
      }
      refetch()
    }
  });

  const btnData = RadioButton;
  const datatimesheet = btnData?.timesheetId;
  const dataProject = btnData?.projectName;

  const handleApproved = () => {
    setShow(false);
    plapprovedID({
      timeid: Number(localStorage.getItem("employeeId")),
      RadioButton: datatimesheet,
    });
    // setNextScreen("TeamTimeSheets");
  };

  const handleApprovedCancle = () => {
    setShow(false);
  };

 

  const handleNotApproved = () => {
    setShow1(false);
    plapprovedData({
      timeid: Number(localStorage.getItem("employeeId")),
      Textid: TextData,
      RadioButton: datatimesheet,
    });
    // setNextScreen("ViewTimeSheetByIds");
  };

  const handleNotApprovedCancle = () => {
    setShow1(false);
  };

  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  const { data, isLoading, isError,refetch } = useQuery(
    ["ViewTeamTimeSheetBYID"],
    () => ViewTeamTimeSheetBYID(prevData?.outputDate, prevData?.empiD),
    {
      refetchOnWindowFocus: false,
    }
  );


  const originalDate = new Date(prevData?.outputDate);
  const day = originalDate.getDate();
  const month = originalDate.toLocaleString("default", { month: "short" });
  const year = originalDate.getFullYear().toString().slice(-2);
  const convertedDate = `${day} ${month} ${year}`;

  useEffect(() => {
    setProjectName();
  }, []);

  return (
    <>
      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        <Row>
          <Col>
            <span className={style.ViewTeamTask}>
              {prevData?.name} Task's Details
            </span>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ marginLeft: "2px" }}>
          <Col>
            <p className={style.datetime}>{convertedDate}</p>
          </Col>
        </Row>
      </Container>
      <div className={style.td}>
        <div className={style.DetailTeamView}>
          <p className={style.ViewTeamEmployeeDetails}>Task Details</p>
          <Container fluid >
            <Row>
              <Col md={2} style={{ marginTop: "5px"}}>
                <h6 style={{fontWeight:'700px',fontSize:'14px' }}>Project Name</h6>
              </Col>
              <Col md={2} style={{ marginTop: "5px" }}>
                <h6 style={{fontWeight:'700px',fontSize:'14px' }}>Task Type</h6>
              </Col>
              <Col md={2} style={{ marginTop: "5px" }}>
                <h6 style={{fontWeight:'700px',fontSize:'14px' }}>
                  Time Spent
                </h6>
              </Col>
              <Col md={2} style={{ marginTop: "5px" }}>
                <h6 style={{fontWeight:'700px',fontSize:'14px' }}>Task Summary</h6>
              </Col>

              <Col md={2} style={{ marginTop: "5px" }}>
                <h6 style={{fontWeight:'700px',fontSize:'14px' }}>Lead Approval</h6>
              </Col>
              <Col md={2} style={{ marginTop: "5px" }}>
                <h6 style={{fontWeight:'700px',fontSize:'14px' }}>Status</h6>
              </Col>
            </Row>
          </Container>
          {!isLoading && !isError ? (
            data?.length > 0 ? (
              typeof data !== "string" &&
              data?.map((item, index) => {
                const projectData = {
                  projectName: item?.projectName,
                  timesheetId: item?.timesheetId,
                };
                const minutes = item?.timeSpent;
                  const hours = Math.floor(minutes / 60);
                  const remainingMinutes = minutes % 60;
                return (
                  <>
                    <Row>
                      <Col md={2}>
                        <p style={{ paddingLeft: "15px" }}>
                          {/* {item?.projectName} */}
                          <CustomTooltip
                            value={item?.projectName}
                            limit={16}
                            index={index}
                            tooltipstyle={{ left: "34%" }}
                          />
                        </p>
                      </Col>
                      <Col md={2}>
                        <p style={{ paddingLeft: "9px" }}>{item?.taskType}</p>
                      </Col>
                      <Col md={2}>
                        <p style={{ paddingLeft: "7px" }}>   {hours === 0
                          ? `${remainingMinutes} Min`
                          : `${hours} Hrs ${remainingMinutes} Min`}</p>
                      </Col>
                      <Col md={2}>
                        <p style={{ paddingLeft: "5px" }}>
                          <CustomTooltip
                            value={item?.taskSummary}
                            limit={15}
                            index={`summary${index}`}
                            tooltipstyle={{ left: "55%" }}
                          />
                        </p>

                      </Col>

                      <Col md={2}>
                        <input
                          type="radio"
                          id="html"
                          name="fav_language"
                          value={JSON.stringify(projectData)}
                          style={{
                            height: "18px",
                            width: "18px",
                            cursor: "pointer",
                          }}
                          onChange={(e) =>
                            setRadioButton(JSON.parse(e.target.value))
                          }
                          disabled={
                            item?.plApproved === "Approved" ? true : false
                          }
                        ></input>
                      </Col>
                      <Col md={2}>
                        {item?.plApproved === "Pending" ? (
                          <p
                            style={{
                              color: "#9c58a5",
                              fontSize: "600",
                              fontWeight: "bold",
                              transform:'translate(-6px,0px)'
                            }}
                            disabled={
                              prevData?.dailyStatus === "Approved"
                                ? true
                                : false
                            }
                          >
                            {item?.plApproved}
                          </p>
                        ) : item?.plApproved === "Approved" ? (
                          <p
                            style={{
                              color: "green",
                              fontSize: "600",
                              fontWeight: "bold",
                              transform:'translate(-6px,0px)'
                            }}
                          >
                            {item?.plApproved}
                          </p>
                        ) : item?.plApproved === "Not Approved" ? (
                          <p
                            style={{
                              color: "red",
                              fontSize: "600",
                              fontWeight: "bold",
                              transform:'translate(-6px,0px)'
                            }}
                          >
                            {item?.plApproved}
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </>
                );
              })
            ) : (
              <p className={style.no_data}>No Data Found</p>
            )
          ) : null}
        </div>
      </div>
      {prevData?.dailyStatus === "Approved" ? (
        ""
      ) : (
        <>
          <button className={style.buttonAdd} onClick={handleShow}>
            Approved
          </button>
          <button className={style.buttonCancle} onClick={handleShow1}>
            Not Approved
          </button>
        </>
      )}

      <Modal show={show} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Confirm Status</h6>
        </Modal.Header>
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to approve.
        </h6>
        <button className={style.buttonAdd4} onClick={handleApproved}>
          Yes
        </button>
        <button className={style.buttonCancle4} onClick={handleApprovedCancle}>
          No
        </button>
        <style type="text/css">
    {`
      .modal-content {
        width: 80%;
      }
    `}
  </style>
      </Modal>
<ToastContainer/>
      <Modal show={show1} centered size="sm">
        <Modal.Header
          style={{
            paddingBottom: "0px",
            paddingTop: "6px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 style={{ fontSize: "14px", color: "#9c58a5" }}>Confirm Status</h6>
        </Modal.Header>
        <h6 style={{ margin: "5px 24px", fontSize: "12px" }}>
          Reason for not approve
        </h6>
        <h6 style={{ margin: "0px 24px", fontSize: "12px" }}>
          Project Name: {dataProject}
        </h6>
        <Form.Control
          as="textarea"
          rows={2}
          style={{ width: "85%", marginLeft: "24px", marginTop: "10px" }}
          onChange={(e) => {
            setTextData(e.target.value);
          }}
        />

        <button className={style.buttonAdd3} onClick={handleNotApproved}>
          Yes
        </button>
        <button
          className={style.buttonCancle3}
          onClick={handleNotApprovedCancle}
        >
          No
        </button>
        
      </Modal>
    </>
  );
};
export default ViewTimeSheetById;
