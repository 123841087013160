import React, { useEffect } from "react";
import cx from "classnames";

import { CustomCheckbox } from "../";
import { ReactComponent as Dropdown } from "../../../../assets/Grievance portal/icon-dropdown.svg";

import usePersistentState from "../../hooks/usePersistentState";

import styles from "./FilterForm.module.css";

const FilterForm = (props) => {
    const [requestorName, setRequestorName] = usePersistentState("requestorName", Array(0), true);
    const [category, setCategory] = usePersistentState("category", Array(0), true);
    const [status, setStatus] = usePersistentState("status", Array(0), true);
    const clearingArray = [];

    // useEffect(() => {
    //     window.onbeforeunload = (event) => {
    //         event.preventDefault();
    //         onApplyFilter();
    //     };
    // });

    // useEffect(() => {
    //     onApplyFilter();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [requestorName, category, status]);

    const onChangeFilter = (type, label) => {
        if (type === "requestorName") {
            if (requestorName.includes(label)) {
                setRequestorName(requestorName.filter((itm) => itm !== label));
            } else {
                setRequestorName([label].concat(requestorName));
            }
        } else if (type === "category") {
            if (category.includes(label)) {
                setCategory(category.filter((itm) => itm !== label));
            } else {
                setCategory([label].concat(category));
            }
        } else if (type === "status") {
            if (status.includes(label)) {
                setStatus(status.filter((itm) => itm !== label));
            } else {
                setStatus([label].concat(status));
            }
        }
    };

    const onApplyFilter = () => {
        props.onFilter(requestorName, category, status);
    };

    const onClearFilter = () => {
        clearingArray.map((x) => x());
        setRequestorName([]);
        setCategory([]);
        setStatus([]);
    };

    return (
        <div className={styles.overlayContainer}>
            <Dropdown fill="#41cbb6" style={{ width: "1em" }} />
            <div className={cx(styles.colFlex, styles.container)}>
                <div className={styles.rowFlex} style={{ margin: "5px 5px 0px 5px" }}>
                    {props.showRequestorName && (
                        <React.Fragment>
                            <div className={styles.colFlex}>
                                <div
                                    className={cx(styles.backgroundOceanGray, styles.fontMedium)}
                                    style={{ borderTopLeftRadius: "9px", padding: "0.5em 2em", width: "15.5em", textAlign: "center" }}
                                >
                                    Requestor Name
                                </div>
                                <div className={cx(styles.colFlex, styles.checkboxList, styles.fontRegular)} style={{ height: "146px", padding: "0em 1em" }}>
                                    {[...new Set(props.requestorNames)].map((name, index) => (
                                        <CustomCheckbox
                                            key={index}
                                            type="requestorName"
                                            checked={requestorName.includes(name)}
                                            label={name}
                                            onChange={onChangeFilter}
                                            onClear={clearingArray}
                                        />
                                    ))}
                                </div>
                            </div>
                            <span style={{ display: "inline-block", margin: "0px 5px", width: "1px", height: "191px", backgroundColor: "#cbc9c9" }}></span>
                        </React.Fragment>
                    )}
                    <div className={styles.colFlex}>
                        <div
                            className={cx(styles.backgroundOceanGray, styles.fontMedium)}
                            style={
                                props.showAssigneeName
                                    ? { padding: "0.5em 2em", width: "15.5em", textAlign: "center" }
                                    : { borderTopLeftRadius: "9px", padding: "0.5em 2em", width: "15.5em", textAlign: "center" }
                            }
                        >
                            Category
                        </div>
                        <div className={cx(styles.colFlex, styles.checkboxList, styles.fontRegular)} style={{ height: "146px", padding: "0em 1em" }}>
                            {[...new Set(props.categories)].map((categ, index) => (
                                <CustomCheckbox key={index} type="category" checked={category.includes(categ)} label={categ} onChange={onChangeFilter} onClear={clearingArray} />
                            ))}
                        </div>
                    </div>
                    <span style={{ display: "inline-block", margin: "0px 5px", width: "1px", height: "191px", backgroundColor: "#cbc9c9" }}></span>
                    <div className={styles.colFlex}>
                        <div
                            className={cx(styles.backgroundOceanGray, styles.fontMedium)}
                            style={{ borderTopRightRadius: "9px", padding: "0.5em 2em", width: "15.5em", textAlign: "center" }}
                        >
                            Status
                        </div>
                        <div className={cx(styles.colFlex, styles.checkboxList, styles.fontRegular)} style={{ height: "146px", padding: "0em 1em" }}>
                            <CustomCheckbox
                                type="status"
                                checked={status.includes("In-Progress")}
                                label="In-Progress"
                                color="#fcb902"
                                onChange={onChangeFilter}
                                onClear={clearingArray}
                            />
                            <CustomCheckbox
                                type="status"
                                checked={status.includes("Complete")}
                                label="Complete"
                                color="#65a15e"
                                onChange={onChangeFilter}
                                onClear={clearingArray}
                            />
                            <CustomCheckbox type="status" checked={status.includes("Queue")} label="Queue" color="#f94244" onChange={onChangeFilter} onClear={clearingArray} />
                        </div>
                    </div>
                </div>
                <hr style={{ margin: "auto", border: "0", width: "98%", height: "1px", color: "#cbc9c9", backgroundColor: "#cbc9c9" }} />
                <div className={styles.rowFlex} style={{ marginLeft: "auto", marginRight: "0.25em" }}>
                    <button onClick={onClearFilter} className={cx(styles.font18, styles.fontMedium, styles.colorShamrock, styles.clearAllButton)} style={{ margin: "0.5em" }}>
                        Clear All
                    </button>
                    <button onClick={onApplyFilter} className={cx(styles.font18, styles.fontMedium, styles.colorWhite, styles.applyButton)} style={{ margin: "0.5em" }}>
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FilterForm;
