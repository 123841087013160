import React, { useEffect, useState } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import style from "./EmployeeEdit.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../../Employee_Induction/CommonComponent/Button/InvoiceButton";
import calender from "../../../../assets/calblue.png";
import cancel from "../../../../assets/cancell.png";
import { useQuery, useMutation } from "react-query";
import DatePicker from "react-datepicker";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import {
  EmployeeInduction,
  CreateInduction,
  CreateActivity,
  CreateJoiningStatus,
} from "../../../../Utils/axios/InductionApi";
import moment from "moment";
import { GrPowerReset } from "react-icons/gr";

function EmployeeEdit({ setNextScreen, prevData, setPrevData, InductionData }) {
  let {
    ActivityStatus,
    checkListId1,
    idData,
    JoiningStatus2,
    checkListId2,
    overallStatus,
    checklistEmployee,
  } = prevData;
  const [inductionForm, setInductionForm] = useState([
    ...checklistEmployee.map((e) => ({ ...e, employeeId: idData })),
  ]);

  // let history = useHistory();
  const [validate, setValidate] = React.useState(false);
  const [checkList, setCheckList] = useState();

  const {
    data: InductionEditView,
    isLoading,
    isError,
  } = useQuery(
    ["EmployeeInduction"],
    () => EmployeeInduction(prevData?.idData),
    {}
  );

  const apiKey = process.env.REACT_APP_EmployeeInduction_URL;
  const getCheckList = () => {
    axios
      .get(apiKey + "/checklist")
      .then((res) => {
        setCheckList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCheckList();
  }, [setCheckList]);

  const [formData3, setFormData3] = useState({
    employeeName: InductionEditView?.employeeName,
    empCode: InductionEditView?.empCode,
    dateOfJoining: InductionEditView?.dateOfJoining,
    designation: InductionEditView?.designation,
    department: InductionEditView?.department,
    recruiter: InductionEditView?.recruiter,
  });

  const [formData2, setFormData2] = useState({
    ...ActivityStatus,
    checkListId: checkListId1,
    employeeId: idData,
  });

  const [formData4, setFormData4] = useState({
    ...JoiningStatus2,
    checkListId: checkListId2,
    employeeId: idData,
    overallStatus: overallStatus,
  });
  const mutation = useMutation(CreateInduction, {
    onMutate: () => {
      // setDisabled(true);
    },

    onSuccess: (res) => {
      // setPrevData({ ...prevData, clientData: res });
      // setNextScreen("Confirm3_noheader");
      // setDisabled(false);
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });

  const mutation1 = useMutation(CreateActivity, {
    onMutate: () => {
      // setDisabled(true);
    },

    onSuccess: (res) => {
      // setPrevData({ ...prevData, clientData: res });
      // setNextScreen("Confirm3_noheader");
      // setDisabled(false);
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });
  const mutation2 = useMutation(CreateJoiningStatus, {
    onMutate: () => {},

    onSuccess: (res) => {},
    onError: (error) => {},
  });

  const EditInductionHandler = () => {
    let updatejoinData = formData4;
    if (true) {
      setValidate(false);
      let inductionData = inductionForm?.filter((e) => {
        return e?.categoryName !== "Employee Details";
      });
      let inductionData1 = inductionData?.filter((e) => {
        return e?.categoryName !== "Employee Orientation";
      });

      if (inductionData1?.length > 0) {
        let flag = false;
        for (let i = 0; i < inductionData1?.length; i++) {
          if (
            inductionData1[i]?.status === null ||
            inductionData1[i]?.status === "N.A"
          ) {
            flag = false;
            break;
          } else {
            flag = true;
          }
        }
        if (flag) {
          if (formData2?.status === "Done") {
            updatejoinData["overallStatus"] = true;
          } else {
            updatejoinData["overallStatus"] = false;
          }
        } else {
          updatejoinData["overallStatus"] = false;
        }
      }

      mutation.mutate(inductionData1);
      mutation1.mutate(formData2);
      mutation2.mutate(updatejoinData);
      // history.goBack()
      setNextScreen("EmployeeInductionView");
    }
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
        />
      </div>
    );
  });
  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() =>
            setFormData2({ ...formData2, [props?.properties]: "" })
          }
        />
      </div>
    );
  });

  return (
    <>
      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Emp Code</p>
            <p className={style.value}>{formData3?.empCode}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Emp Name</p>
            <p className={style.value}>{formData3?.employeeName}</p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Date of Joining</p>
            <p className={style.value}>
              {moment(formData3?.dateOfJoining).format("DD MMM YY")}
            </p>
          </div>
          <div className={style.col212}>
            <p className={style.label}>Designation</p>
            <p className={style.value}>{formData3?.designation}</p>
          </div>
        </div>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Department</p>
            <p className={style.value}>{formData3?.department}</p>
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Employee Details</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.col212}>
            <p className={style.label}>Joining Kit Status</p>
            <div className={style.cu_col1} style={{ marginTop: "13px" }}>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="Done36"
                  name="joiningStatus"
                  value="Done"
                  checked={formData4?.joiningStatus === "Done"}
                  onChange={(e) => {
                    setFormData4({
                      ...formData4,
                      joiningStatus: e?.target?.value,
                    });
                  }}
                />

                <label for="Done36">Done</label>
              </div>
              <div className={style.radioitem}>
                <input
                  type="radio"
                  id="N.A36"
                  name="joiningStatus"
                  value="N.A"
                  checked={formData4?.joiningStatus === "N.A"}
                  onChange={(e) =>
                    setFormData4({
                      ...formData4,
                      joiningStatus: e?.target?.value,
                    })
                  }
                />

                <label for="N.A36">N.A</label>
              </div>
            </div>
            <div
              onClick={(e) => {
                setFormData4({
                  ...formData4,
                  joiningStatus: e?.target?.value,
                });
              }}
              style={{
                paddingLeft: "9rem",
                cursor: "pointer",
                marginTop: "-22px",
              }}
              title="Reset"
            >
              <GrPowerReset />
            </div>
          </div>
          <div className={style.col}>
            <p className={style.labelFolderlink}>Folder Link</p>
            <input
              value={formData4?.folderLink}
              className={style.bigInputFolderLink}
              onChange={(e) =>
                setFormData4({ ...formData4, folderLink: e?.target?.value })
              }
            />
          </div>
        </div>
      </InvoiceContainer>
      <div className={style.heading}>Employee Induction Activities</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label2}>Status</p>
          </div>
        </div>

        {checklistEmployee?.map((element, index) => {
          if (element?.categoryId === 6) {
            return (
              <div className={style.Container}>
                <div className={style.payrollName}>
                  {<p>{element?.checkListName}</p>}
                </div>

                <div className={style.payrollStatus}>
                  <div className={style.statusContainer}>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`Done${index}`}
                        name={`InductionActivity${index}`}
                        value="Done"
                        checked={inductionForm[index]?.status === "Done"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "Done";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`Done${index}`}>Done</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`NA${index}`}
                        name={`InductionActivity${index}`}
                        value="N.A"
                        checked={inductionForm[index]?.status === "N.A"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "N.A";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`NA${index}`}>N.A</label>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      let data = [...inductionForm];
                      data[index].status = "";
                      setInductionForm([...data]);
                    }}
                    style={{ paddingLeft: "1rem", cursor: "pointer" }}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </InvoiceContainer>
      <div className={style.heading}>Employee Document Details</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label2}>Status</p>
          </div>
        </div>

        {checklistEmployee?.map((element, index) => {
          if (element?.categoryId === 3) {
            return (
              <div className={style.Container}>
                <div className={style.payrollName}>
                  {<p>{element?.checkListName}</p>}
                </div>

                <div className={style.payrollStatus}>
                  <div className={style.statusContainer}>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`Done${index}`}
                        name={`Document${index}`}
                        value="Done"
                        checked={inductionForm[index]?.status === "Done"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "Done";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`Done${index}`}>Done</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`NA${index}`}
                        name={`Document${index}`}
                        value="N.A"
                        checked={inductionForm[index]?.status === "N.A"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "N.A";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`NA${index}`}>N.A</label>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      let data = [...inductionForm];
                      data[index].status = "";
                      setInductionForm([...data]);
                    }}
                    style={{ paddingLeft: "1rem", cursor: "pointer" }}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </InvoiceContainer>

      <div className={style.heading}>Joining Form</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label2}>Status</p>
          </div>
        </div>

        {checklistEmployee?.map((element, index) => {
          if (element?.categoryId === 4) {
            return (
              <div className={style.Container}>
                <div className={style.payrollName}>
                  {<p>{element?.checkListName}</p>}
                </div>

                <div className={style.payrollStatus}>
                  <div className={style.statusContainer}>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`Done28${index}`}
                        name={`JoiningForm${index}`}
                        value="Done"
                        checked={inductionForm[index]?.status === "Done"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "Done";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`Done28${index}`}>Done</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`NA${index}`}
                        name={`JoiningForm${index}`}
                        value="N.A"
                        checked={inductionForm[index]?.status === "N.A"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "N.A";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`NA${index}`}>N.A</label>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      let data = [...inductionForm];
                      data[index].status = "";
                      setInductionForm([...data]);
                    }}
                    style={{ paddingLeft: "1rem", cursor: "pointer" }}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </InvoiceContainer>
      <div className={style.heading}>Employee Orientation</div>
      <InvoiceContainer>
        <Container>
          <Row>
            <Col md={3}>
              <p className={style.label}>Activity Date</p>
              <div className={style.cu_field23Last}>
                <DatePicker
                  placeholder=" DD MM YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={
                    formData2?.date ? moment(formData2?.date).toDate() : null
                  }
                  onChange={(date) => {
                    setFormData2({
                      ...formData2,
                      date: moment(date).format(),
                    });
                  }}
                  dateFormat="dd MMM yyyy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  customInput={
                    formData2?.date ? (
                      <CustomInputCross properties="date" />
                    ) : (
                      <CustomInputClg />
                    )
                  }
                />
              </div>
            </Col>
            <Col md={3} style={{ marginLeft: "29px" }}>
              <p className={style.label}>HR Name</p>
              <input
                value={formData2?.hrName}
                className={style.bigInput}
                onChange={(e) =>
                  setFormData2({ ...formData2, hrName: e?.target?.value })
                }
              />
            </Col>
            <Col md={3} style={{ marginLeft: "29px" }}>
              <p className={style.label2}>Status</p>
              <div className={style.cu_col1}>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="Done55"
                    name="Status"
                    value="Done"
                    checked={formData2?.status === "Done"}
                    onChange={(e) =>
                      setFormData2({ ...formData2, status: e?.target?.value })
                    }
                  />
                  <label for="Done55">Done</label>
                </div>
                <div className={style.radioitem}>
                  <input
                    type="radio"
                    id="N.A55"
                    name="Status"
                    value="N.A"
                    checked={formData2?.status === "N.A"}
                    onChange={(e) =>
                      setFormData2({ ...formData2, status: e?.target?.value })
                    }
                  />
                  <label for="N.A55">N.A</label>
                </div>
              </div>
              <div
                onClick={(e) => {
                  setFormData2({ ...formData2, status: e?.target?.value });
                }}
                style={{
                  paddingLeft: "9rem",
                  cursor: "pointer",
                  marginTop: "-22px",
                }}
                title="Reset"
              >
                <GrPowerReset />
              </div>
            </Col>
          </Row>
        </Container>
      </InvoiceContainer>
      <div className={style.heading}>Introduction</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label2}>Status</p>
          </div>
        </div>

        {checklistEmployee?.map((element, index) => {
          if (element?.categoryId === 7) {
            return (
              <div className={style.Container}>
                <div className={style.payrollName}>
                  {<p>{element?.checkListName}</p>}
                </div>

                <div className={style.payrollStatus}>
                  <div className={style.statusContainer}>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`Done${index}`}
                        name={`Introduction${index}`}
                        value="Done"
                        checked={inductionForm[index]?.status === "Done"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "Done";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`Done${index}`}>Done</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`NA${index}`}
                        name={`Introduction${index}`}
                        value="N.A"
                        checked={inductionForm[index]?.status === "N.A"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "N.A";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`NA${index}`}>N.A</label>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      let data = [...inductionForm];
                      data[index].status = "";
                      setInductionForm([...data]);
                    }}
                    style={{ paddingLeft: "1rem", cursor: "pointer" }}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </InvoiceContainer>
      <div className={style.heading}>Letters</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label2}>Status</p>
          </div>
        </div>

        {checklistEmployee?.map((element, index) => {
          if (element?.categoryId === 8) {
            return (
              <div className={style.Container}>
                <div className={style.payrollName}>
                  {<p>{element?.checkListName}</p>}
                </div>

                <div className={style.payrollStatus}>
                  <div className={style.statusContainer}>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`Done30${index}`}
                        name={`Letters${index}`}
                        value="Done"
                        checked={inductionForm[index]?.status === "Done"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "Done";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`Done30${index}`}>Done</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`NA${index}`}
                        name={`Letters${index}`}
                        value="N.A"
                        checked={inductionForm[index]?.status === "N.A"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "N.A";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`NA${index}`}>N.A</label>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      let data = [...inductionForm];
                      data[index].status = "";
                      setInductionForm([...data]);
                    }}
                    style={{ paddingLeft: "1rem", cursor: "pointer" }}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </InvoiceContainer>

      <div className={style.heading}>HRMS</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label2}>Status</p>
          </div>
        </div>

        {checklistEmployee?.map((element, index) => {
          if (element?.categoryId === 9) {
            return (
              <div className={style.Container}>
                <div className={style.payrollName}>
                  {<p>{element?.checkListName}</p>}
                </div>

                <div className={style.payrollStatus}>
                  <div className={style.statusContainer}>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`Done31${index}`}
                        name={`HRMS${index}`}
                        value="Done"
                        checked={inductionForm[index]?.status === "Done"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "Done";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`Done31${index}`}>Done</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`NA${index}`}
                        name={`HRMS${index}`}
                        value="N.A"
                        checked={inductionForm[index]?.status === "N.A"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "N.A";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`NA${index}`}>N.A</label>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      let data = [...inductionForm];
                      data[index].status = "";
                      setInductionForm([...data]);
                    }}
                    style={{ paddingLeft: "1rem", cursor: "pointer" }}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </InvoiceContainer>
      <div className={style.heading}>Payroll</div>
      <InvoiceContainer>
        <div className={style.Container}>
          <div className={style.payroll}>
            <p className={style.label}> Name</p>
          </div>
          <div className={style.payrollStatus}>
            <p className={style.label2}>Status</p>
          </div>
        </div>

        {checklistEmployee?.map((element, index) => {
          if (element?.categoryId === 10) {
            return (
              <div className={style.Container}>
                <div className={style.payrollName}>
                  {<p>{element?.checkListName}</p>}
                </div>

                <div className={style.payrollStatus}>
                  <div className={style.statusContainer}>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`Done32${index}`}
                        name={`PayrollUpdateinSUMHR${index}`}
                        value="Done"
                        checked={inductionForm[index]?.status === "Done"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "Done";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`Done32${index}`}>Done</label>
                    </div>
                    <div className={style.radioitem}>
                      <input
                        type="radio"
                        id={`NA${index}`}
                        name={`PayrollUpdateinSUMHR${index}`}
                        value="N.A"
                        checked={inductionForm[index]?.status === "N.A"}
                        onChange={() => {
                          let data = [...inductionForm];
                          data[index].status = "N.A";
                          setInductionForm([...data]);
                        }}
                      />
                      <label for={`NA${index}`}>N.A</label>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      let data = [...inductionForm];
                      data[index].status = "";
                      setInductionForm([...data]);
                    }}
                    style={{ paddingLeft: "1rem", cursor: "pointer" }}
                    title="Reset"
                  >
                    <GrPowerReset />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </InvoiceContainer>

      <div className={style.SubmitBtn}>
        <InvoiceButton
          onClick={() => {
            EditInductionHandler();
            setNextScreen("EmployeeInductionView");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "EmployeeInductionView",
              page3: null,
              page4: null,
              active: "EmployeeInductionView",
            }));
          }}
        >
          Submit
        </InvoiceButton>
      </div>
    </>
  );
}

export default EmployeeEdit;
