import React, { useEffect, useState } from "react";
import style from "./SideBar.module.css";
import buttonimg from "../../../assets/collaboration.png";
import one from "../../../assets/businessman-gear-icon.png";
import two from "../../../assets/referral-customer-icon.png";
import three from "../../../assets/education-group-icon.png";
import four from "../../../assets/connect-people-icon.png";
import oneG from "../../../assets/businessman-gear-icon_GREEN.png";
import twoG from "../../../assets/referral-customer-icon_GREEN.png";
import threeG from "../../../assets/education-group-icon_GREEN.png";
import fourG from "../../../assets/connect-people-icon_GREEN.png";
import ats from "./../../../assets/ats.png";
import ap from "../../../assets/ap.png";
import apg from "../../../assets/apg.png";
import orp from "../../../assets/orp.png";
import orpg from "../../../assets/orpg.png";
import iv from "../../../assets/ivicon.png";
import ivg from "../../../assets/ivicon-gr.png";
import jpp from "../../../assets/jpp.png";
import jppg from "../../../assets/jpp-g.png";
import cm from "../../../assets/cm.png";
import cmg from "../../../assets/cmg.png";
import pm from "../../../assets/pmg.png";
import pmg from "../../../assets/pm.png";
import cag from "../../../assets/cag.png";
import caw from "../../../assets/caw.png";

import { Link, useHistory, useLocation } from "react-router-dom";

const SideBar = ({
  sidebarHandler,
  nextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
}) => {
  return (
    <div className={style.leftSection}>
      {/* 0 */}
      <button className={style.taskTracker}>
        <img src={ats} alt="img" height="40px" />
        <span>Expense & Invoice</span>
      </button>
      {/* 1 */}

      <button
        onClick={() => {
          sidebarHandler("expense");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "expense",
            page: "expense",
            page1: "expense",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            // active: "expense",
          });
        }}
        className={
          prevData?.sidebar === "expense" ? style.selected : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "expense" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Expense</span>
        </div>
      </button>

      {/* 2 */}
      {/* <button
        // onClick={() => {
        //   setCurrentPage(1);
        //   sidebarHandler("CurrentAllocation");
        //   setPrevData({
        //     ...prevData,
        //     sidebar: "CurrentAllocation",
        //     page: "CurrentAllocation",
        //     page1: "CurrentAllocation",
        //     page2: null,
        //     page3: null,
        //     page4: null,
        //     page5: null,
        //     page6: null,
        //     active: "CurrentAllocation",
        //   });
        // }}
        className={
          prevData?.sidebar === "CurrentAllocation"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "CurrentAllocation" ? (
            <img src={twoG} alt="img" />
          ) : (
            <img src={two} alt="img" />
          )}
        </div>

        <div className={style.sb}>
          <span>Expense Details</span>
        </div>
      </button> */}
      {/* 3 */}
      <button
        onClick={() => {
          sidebarHandler("invoiceList");
          setPrevData((prevData) => ({
            ...prevData,
            sidebar: "invoiceList",
            page1: "invoiceList",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            active: "invoiceList",
          }));
        }}
        className={
          prevData.sidebar === "invoiceList"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData.sidebar === "invoiceList" ? (
            <img src={threeG} alt="img" />
          ) : (
            <img src={three} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Invoice</span>
        </div>
      </button>

      {/* 4 */}
      {/* <button
        // onClick={() => {
        //   sidebarHandler("AssessmentProcess");
        //   setPrevData((prevData) => ({
        //     ...prevData,
        //     page: "positionALL",
        //     sidebar: "AssessmentProcess",
        //   }));
        // }}
        className={
          prevData.sidebar === "AssessmentProcess"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {nextScreen === "AssessmentProcess" ? (
            <img src={apg} alt="img" width="30px" />
          ) : (
            <img src={ap} alt="img" width="30px" />
          )}
        </div>
        <div className={style.sb}>
          <span>Invoice Details</span>
        </div>
      </button> */}
    </div>
  );
};

export default SideBar;
