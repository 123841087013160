import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { textAlign } from "@mui/system";
import Moment from 'moment';


const TableRow = (props) => {
  const history = useHistory();
  return (
    <tr style={{ borderBottom: "2px solid #F0F0F0", textAlign: "start" }}>
      <td onClick={() => props.clickHandler(props.data.applicationId)} style={{ padding: "7px 0px", width:"43%",fontWeight: "bolder", color:"#172A3E",fontSize:"13px", cursor: "pointer" ,textDecoration:"underLine"}}>
        {props.data.application}
      </td>
      <td style={{ fontSize: "initial",fontSize:"13px", width:"43%"}}>{props.data.skillName}</td>
      <td style={{ fontSize: "initial" ,fontSize:"13px"}}>{props.data.typeStatus}</td>


    </tr>
  );
};

export default TableRow;