import React, { useEffect, useState } from "react";

import style from "./SideBar.module.css";
import SRC_ASSET_MENU_MAIN from "../../../assets/assetsmangement/devices.png";
import SRC_ASSET_MENU_DASHBOARD from "../../../assets/assetsmangement/menu.png";
import SRC_ASSET_MENU_DASHBOARD_WHITE from "../../../assets/assetsmangement/menuWhite.png";
import SRC_ASSET_MENU_DEVICE from '../../../assets/assetsmangement/devicesIcon.png';
import ARROW_DOWN from '../../../assets/assetsmangement/arrowDown.png';
import { useHistory } from "react-router-dom";

const SideBar = () => {
  const [buttonStyle, setButtonStyle] = useState("");
  const history = useHistory();
  const handleTask = (option) => {
    setButtonStyle(option);
    if (option === "Dashboard") {
      history.push("/itAssetsMain");
    } else if (option === "devices") {
      // history.push("/taskAssignedByMe");
    } else if (option === "Laptops") {
      history.push("/laptops");
    } 
    else if (option === "Mobiles") {
      history.push("/mobiles");
    }
    else if(option ==="Tablets"){
      history.push("/tablet");
    }
  };

  useEffect(() => {
    console.log("((((((((((((((((((((((((((((");
    if (buttonStyle === "Dashboard") {
      setButtonStyle("Dashboard");
    } else if (buttonStyle === "devices") {
      setButtonStyle("devices");
    } 
  }, [buttonStyle]);


  return (
    <div className={style.leftSection}>
      <button className={style.taskTracker}>
        <div>
          <img src={SRC_ASSET_MENU_MAIN} alt="img" />
        </div>

        <span>It Assets<br/>Management</span>
      </button>

      <button
        onClick={() => handleTask("Dashboard")}
        className={style.myTask}
        style={
          buttonStyle === "Dashboard"
            ? { backgroundColor: "#ffffff", color: "#0F5EF7" }
            : null
        }
      >
        <div>
          <img  src={buttonStyle === "Dashboard" ? SRC_ASSET_MENU_DASHBOARD : SRC_ASSET_MENU_DASHBOARD_WHITE} alt="img" />
        </div>

        <span>Dashboard</span>
      </button>

        <div
        className={style.devices}
        style={
          buttonStyle !== "Dashboard" && buttonStyle !== ""
            ? { backgroundColor: "#2F4052", color: "#ffffff" }
            : null
        }>
      <div
        onClick={() => handleTask("devices")}
      >
        <div className={style.devicesMain}>
          <div>
            <img src={SRC_ASSET_MENU_DEVICE} alt="img" />
          </div>

          <span>Devices</span>

          <div>
            <img src={ARROW_DOWN} alt="img" />
          </div>
        </div>
       
      </div>
      <div>
          {
            buttonStyle !== "Dashboard" && buttonStyle !== "" &&
              <>
                <button
                onClick={() => handleTask("Laptops")}
                className={style.subButton}
                style={
                  buttonStyle === "Laptops"
                    ? { backgroundColor: "#ffffff", color: "#0F5EF7" }
                    : null
                }
              >
                <span>Laptops</span>
              </button>
              <button
              onClick={() => handleTask("Mobiles")}
              className={style.subButton}
              style={
                buttonStyle === "Mobiles"
                  ? { backgroundColor: "#ffffff", color: "#0F5EF7" }
                  : null
              }
              >
                <span>Mobiles</span>
              </button>
              <button
              onClick={() => handleTask("Tablets")}
              className={style.subButton}
              style={
                buttonStyle === "Tablets"
                  ? { backgroundColor: "#ffffff", color: "#0F5EF7" }
                  : null
              }
              >
                <span>Tablets</span>
              </button>
            <button
              onClick={() => handleTask("Others")}
              className={style.subButton}
              style={
                buttonStyle === "Others"
                  ? { backgroundColor: "#ffffff", color: "#0F5EF7" }
                  : null
              }
            >
              <span>Others</span>
            </button>
            </>
          }
        </div>
      </div>
     
    </div>
  );
};

export default SideBar;
