import React, { useEffect, useState } from "react";
import Header from "../../../taskTracker/commonComponent/Header";
import SideBar from "../../Clients/component/SideBar";
import style from "./PoolResources.module.css";
import TableRow from "./TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import constants from "../../../../Utils/constants";
import axios from "axios";
import loaderImg from "../../../../assets/loader.gif";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { customSearch } from "../../Clients/component/style";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import milestone from "../../../../assets/milestone.png";
import Pagination from "../../CommonComponent/Pagination";
import rightTickPRM from "../../../../assets/rightTickPRM.png";
import Filter from "../../components/Filter";

const PoolResources = (props) => {
  const history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;
  const [showFilter, setShowFilter] = useState(false);
  const [applied, setApplied] = useState(props.history.location.state?.allData?.applied?props.history.location?.state?.allData?.applied:false);
  const [poolData, setPoolData] = useState([]);
  const [currentPage, setCurrentPage] = useState(props.history.location?.state?.allData?.currentPage?props.history.location?.state?.allData?.currentPage:1);
  const [pageCount, setpageCount] = useState(0);
  const [totalResourceOnsite, setTotalResourceOffShore] = useState(0);
  const [clientListing, setClientListing] = useState([]);
  const [empListing, setEmpListing] = useState([]);
  const [onsiteSkills, setOnsiteSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterName, setFilterName] = useState(props.history.location.state?.allData?.filterName?props.history.location.state?.allData?.filterName:{});

  const [action, setAction] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [skills, setSkills] = useState([]);
  const [show, setShow] = useState(false);
  const[empId,setEmpID]=useState(0);
  const[AllocationInformation,SetAllocationInformation]=useState([]);

  
  const [allData, setAllData] = useState(
    props.history.location.state?.allData.allData?props.history.location.state?.allData.allData:{
    statusId: action,
    empId: employeeId,
    skillId: skills,
    experienceRange:[],
    salaryRange:[]
  });


  const [ClearData, setClearData] = useState({
    statusId: [],
    empId: [],
    skillId: [],
    experienceRange:[],
    salaryRange:[]
  });

  const url = process.env.REACT_APP_UMS_APP_URL;
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, empId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
    }
  };

  empListing?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const getPositionCodeForFilterOption = empListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getAddResourceOnsiteData = (clearData, currentPage) => {
    const filterData = clearData?.empId?.length == 0 ? allData : allData;
    setIsLoaded(true);
    axios
      .post(
        baseUrl +
          "/getPoolResource?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        console.log(res);
        setPoolData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(res.data.responseData.totalResources / 10);
      })
      .catch((err) => {
        setIsLoaded(false);
        console.log(err);
      });
  };

  const getClientListing = () => {
    axios
      .get(baseUrl + "/getClientOnSite", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProjectAllocationByID = (id) => {
    axios
      .get(baseUrl + `/getProjectAllocationByEmpId?empId=${empId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        SetAllocationInformation(res.data.responseData);
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = () => {
    axios
      .get(
        url + `/updateUsersDetails`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmpListing = () => {
    axios
      .get(baseUrl + "/getPoolResourceEmployeeForFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setEmpListing(res.data.responseData.employees);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOnsiteSkills = () => {
    axios
      .get(baseUrl + "/getPoolResourceSkillsForFilter", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log("skills", res);
        setOnsiteSkills(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setCurrentPage(1)
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            empId:allData?.empId,
            skillId: [],
            statusId: [],
            experienceRange:[],
            salaryRange:[]
          }
        : null;
    });
    // getAddResourceOnsiteData(allData, 1);
    setAction([]);
    setClientId([]);
    // setEmployeeId([]);
    setSkills([]);
    setShowFilter(false);
    setApplied(false);
  };

  const handleApplied = (arg) => {
    if (allData?.skillId?.length > 0 || allData?.statusId?.length > 0 || allData ?.experienceRange?.length>0 || allData?.salaryRange?.length>0 ) {
      setApplied(true);
    } else {
      setApplied(false);
    }
    if (
      allData?.skillId?.length == 0 &&
      allData?.statusId?.length == 0 &&
      allData?.empId?.length == 0 &&
      allData?.experienceRange.length==0 && 
      allData?.salaryRange.length==0
    ) {
      setAllData((preValue) => ({
        ...preValue,
        skillId: [],
        statusId: [],
        empId: [],
        experienceRange:[],
        salaryRange:[]
      }));
    }
    setShowFilter(false);
    setCurrentPage(1);
    getAddResourceOnsiteData(allData, 1);
  };

  useEffect(() => {
    getAddResourceOnsiteData(allData, currentPage);
    getClientListing();
    getEmpListing();
    getOnsiteSkills();
  }, [currentPage, allData]);

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(()=>{
  empId>0 && getProjectAllocationByID(empId)
  },[show])
  return (
    <>
   
      <Header />
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              {/* <span><span>Dashboard{'>'}</span><span className={style.recent_tab}>Resource Allocation Onsite </span></span> */}
            </div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "600", width: "12%" }}
              >
                Pool Resources
              </div>
              <div style={{ width: "13%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {totalResourceOnsite}
                  </span>
                </p>
              </div>
              <div style={{ width: "31%", paddingLeft: "14%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img className={style.appliedcheck} src={rightTickPRM} />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>
              <div
                className={style.s1}
                style={{ width: "24%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.empId.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        empId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Employee Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    filterName?.positionCode
                      ? {
                          value: allData?.clientId,
                          label: filterName?.positionCode,
                        }
                      : null
                  }
                  onChange={(e) => {
                    onSearchHandler(e, "Code");
                    setShowFilter(false);
                    setCurrentPage(1);
                  }}
                  options={getPositionCodeForFilterOption}
                />
              </div>
              <div className={style.btn_add}>
                <Link to="/AddPollResources" style={{ textDecoration: "none" }}>
                  <button className={style.create_btn}>
                    <span className={style.plus}>+</span>Allocate Resource
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.poolList}
          
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : poolData.resources?.length>0? (
              <Table borderless size="sm" style={{ fontSize: "12px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                  <th style={{ padding: "15px" }}>Employee Code</th>
                    <th style={{ padding: "15px" }}>Employee Name</th>
                    <th style={{ padding: "15px" }}>Experience</th>
                    {/* <th style={{ padding: "15px" }}>Experience<br/> Range</th> */}
                    <th style={{ padding: "15px" }}>
                    Experience <br /> Range
                    </th>
                    <th style={{ padding: "15px" }}>
                    Salary <br /> Range
                    </th>
                    <th style={{ padding: "15px" }}>Technology</th>
                    <th style={{ padding: "10px" }}>
                      Pool Start
                      <br /> Date
                    </th>
                    <th style={{ padding: "15px" }}>
                      Pool End
                      <br /> Date
                    </th>
                    {/* <th style={{ padding: "15px" }}>Last Comment <br/>Date</th> */}
                    <th style={{ padding: "15px" }}>
                      Bench <br />
                      Age
                    </th>
                    <th style={{ padding: "15px" }}>
                      Candidate <br />
                      Availability Date
                    </th>
                    <th style={{ padding: "15px" }}>
                    Last Comment<br />
                      Date
                    </th>  
                    <th style={{ padding: "10px" }}></th>
                    <th style={{ padding: "10px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {poolData?.resources.map((val) => {
                    return (
                      <TableRow
                        benchAge={val.benchAge}
                        candidateAvailablityDate={val.candidateAvailablityDate}
                        empName={val.empName}
                        empId={val.empId}
                        poolEndDate={val.poolEndDate}
                        poolStartdate={val.poolStartdate}
                        skillName={val.skillName}
                        statusTypeName={val.statusTypeName}
                        experience={val.experience}
                        id={val.id}
                        resignStatus={val.resignStatus}
                        setShow={setShow}
                        currentCommentDate={val.currentCommentDate}
                        experienceRange={val.experienceRange}
                        setEmpID={setEmpID}
                        empCode={val.empCode}
                        salaryRange={val.salaryRange}
                        applied={applied}
                        currentPage={currentPage}
                        allData={allData}
                        filterName={filterName}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) :
            <div className={style.noData}>
                  <img src={milestone} />
                  <h3 className={style.noData_header}>
                    No data available
                  </h3>
                </div> }
            
               
              

            {/* } */}
          </div>
          
          {totalResourceOnsite > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"pool"}
          empListing={empListing}
          clientListing={clientListing}
          setAction={setAction}
          setEmployeeId={setEmployeeId}
          setClientId={setClientId}
          setSkills={setSkills}
          onsiteSkills={onsiteSkills}
        />
      )}
      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
        {AllocationInformation.length>0?
          <div>
          {AllocationInformation?.map((val)=>{
            return (<p className={style.textM}>{val?.projectName} {val?.allocationType} {val?.bandwidthUsed} </p>)
          })}
          </div>:<div><p> No Project Assigned</p></div>}
          <div className={style.M_button}>
            <div className={style.button}></div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PoolResources;
