import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../OnBoarding/OnBoarding.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import { useState } from "react";

import DatePicker from "react-datepicker";
import PurpleIcon from "../../../../assets/Calander.png";
import cancel from "../../../../assets/cancell.png";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton1";
import { InvoiceStyles } from "../../utils/style";
import EditIcon from "../../../../assets/Icon material-edit_.png";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";
import Select from "react-select";
import dropdownIcon from "../../../../assets/downIcon.png";

import {
  getViewPartner,
  PartnerOnBoarding,
  getOnBoardingStatusList,
  getPartnerOnBoardingHistoryList,
  getOnBoardingByOnBoardingId,
  editOnboarding,
} from "../../../../Utils/axios/PortalManagement";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import { useEffect } from "react";
const OnBoarding = ({
  setNextScreen,
  prevData,
  setPrevData,
  // currentPage,
  // setCurrentPag,
}) => {
  const [formData, setFormData] = useState({
    timesheetDate: "",
  });
  const [validate, setValidate] = React.useState(false);
  const [validate1, setValidate1] = React.useState(false);
  const [OnBoardStatusOption, setOnBoardStatusOption] = useState([]);

  const [onboardStatus, setonboardStatus] = useState(false);
  const [EditItemId, setEditItemId] = useState();
  const [disable, setDisable] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const [change, setChange] = useState(false);

  const {
    data: OnBoardList,
    // isLoading,
    // isError,
  } = useQuery(
    ["getOnBoardingByOnBoardingId", EditItemId],
    () => EditItemId && getOnBoardingByOnBoardingId(EditItemId),
    {}
  );

  const [formData1, setFormData1] = useState({
    effectiveFrom: OnBoardList?.effectiveFrom,
    statusId: OnBoardList?.statusId,
    partnerId: prevData?.partnerId,
    id: OnBoardList?.id,
  });

  const { data: PartnerDataById } = useQuery(
    ["getViewPartner"],
    () => getViewPartner(prevData?.OnBoardId),
    {}
  );

  const {
    data: getOnBoardingHistory,
    isLoading1,
    isError1,
    refetch,
  } = useQuery(
    ["getPartnerOnBoardingHistoryList"],
    () => getPartnerOnBoardingHistoryList(prevData?.OnBoardId),
    {}
  );

  const { data: OnBoardStatus } = useQuery(
    ["getOnBoardingStatusList"],
    getOnBoardingStatusList
  );
  useEffect(() => {
    setFormData1((prevValue) => ({
      ...prevValue,
      effectiveFrom: OnBoardList?.effectiveFrom,
      statusId: OnBoardList?.statusId,
      partnerId: prevData?.partnerId,
      id: OnBoardList?.id,
    }));
  }, [OnBoardList]);

  useEffect(() => {
    const OnBoardStatusOption =
      OnBoardStatus?.length > 0
        ? OnBoardStatus?.map((e) => ({
            value: e.id,
            label: e.name,
          }))
        : [];
    setOnBoardStatusOption([...OnBoardStatusOption]);
  }, [OnBoardStatus]);

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          alt=""
        />
      </div>
    );
  });

  const CustomInputClg1 = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross1 = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() =>
            setFormData1({ ...formData1, [props?.properties]: "" })
          }
          alt=""
        />
      </div>
    );
  });

  const dateString =
    formData?.timesheetDate === "" ? null : formData?.timesheetDate;

  const dateObject = new Date(dateString);

  const isoString = dateObject.toISOString(); // "2023-05-16T00:00:00.000Z"
  const formattedDate = isoString.replace("T00:00:00.000Z", "T05:52:34.459Z");
  const mutation = useMutation(PartnerOnBoarding, {
    onSuccess: (res) => {
      setPrevData((prevData) => ({
        ...prevData,
        status: res?.status,
        IdStatus: prevData?.OnBoardId,
        effectiveFrom: res?.effectiveFrom,
        page: "ChangeOnBoardingStatus",
        page1: "PartnerList",
        page2: "ViewPartner",
        page3: "OnBoarding",
        page4: "ChangeOnBoardingStatus",
        active: "ChangeOnBoardingStatus",
      }));
      setNextScreen("OnBoardingStatus");
    },
    // onError: (res) => {},
  });

  const onBoardData = {
    // effectiveDate: null,
    effectiveFrom: formattedDate,
    partnerId: prevData?.OnBoardId,
    // statusId: null,
  };

  const handlerOnbording = () => {
    if (formData?.timesheetDate) {
      setValidate(false);
      mutation.mutate(onBoardData);
    } else {
      setValidate(true);
    }
  };

  const mutation1 = useMutation(PartnerOnBoarding, {
    onSuccess: (res) => {
      refetch();
      setChange(false);
      setonboardStatus(false);
      const OnBoardStatusOption =
        OnBoardStatus?.length > 0
          ? OnBoardStatus?.map((e) => ({
              value: e.id,
              label: e.name,
            }))
          : [];
      setOnBoardStatusOption([...OnBoardStatusOption]);
      setFormData1((prevValue) => ({
        ...prevValue,
        effectiveFrom: "",
        statusId: "",
        partnerId: "",
      }));
    },
  });

  const editMutation = useMutation(editOnboarding, {
    onSuccess: (res) => {
      refetch();
      setFormData1((prevValue) => ({
        ...prevValue,
        effectiveFrom: "",
        statusId: "",
        partnerId: "",
      }));
      setonboardStatus(false);
      setEditItemId(null);
    },
  });

  const handlerOnbordingView = () => {
    if (formData1?.effectiveFrom === "") {
      setValidate1(true);
    } else {
      editMutation.mutate(formData1);
      setValidate1(false);
    }
  };
  const handlerChangeStatus = () => {
    if (formData1?.effectiveFrom === "") {
      setValidate1(true);
    } else {
      mutation1.mutate(formData1);
      setValidate1(false);
    }
  };
  const handleOnboardStatus = (id, stId) => {
    setEditItemId(id);
    setonboardStatus(true);
    setDisable(true);
    setStatusId(stId);
  };

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };
  const changeStatus = () => {
    setDisable(false);
    let data = OnBoardStatusOption?.filter((item) => {
      return item?.value !== statusId;
    });
    setOnBoardStatusOption(data);
    setChange(true);
  };

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        >
          {onboardStatus && (
            <InvoiceButton onClick={() => changeStatus()}>
              Change Status
            </InvoiceButton>
          )}
        </BreadCrum>
      </div>
      <Container>
        <Row>
          <Col>
            <h6 style={{ marginTop: "-5px", marginLeft: "25px" }}>
              On Boarding
            </h6>
          </Col>
        </Row>
        <InvoiceContainer>
          <Row>
            <Col>
              <h6 className={classes.primaryContact}>Partner Details</h6>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Location</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
          </Row>
        </InvoiceContainer>

        {onboardStatus ? (
          <>
            <InvoiceContainer>
              <Row>
                <Col>
                  <h6 className={classes.primaryContact}>Status Management</h6>
                </Col>
              </Row>
              <Row style={{ paddingBottom: "10px" }}>
                <Col md={4}>
                  <p className={classes.label3}>
                    Status <span style={{ color: "red" }}>*</span>
                  </p>
                  <Select
                    styles={InvoiceStyles}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      formData1?.statusId
                        ? OnBoardStatusOption?.find(
                            (e) => e.value === formData1?.statusId
                          )
                        : null
                    }
                    options={OnBoardStatusOption}
                    // getOptionLabel={(option) => option.label}
                    // getOptionDisabled={(option) => option.disabled}
                    // isOptionDisabled={(option) => option.disabled}
                    onChange={(e) => {
                      setFormData1({
                        ...formData1,
                        statusId: e?.value,
                      });
                    }}
                    isDisabled={disable}
                  />
                </Col>
                <Col md={4}>
                  <p className={classes.label3}>
                    Effective From <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={classes.cu_field23Last1}>
                    <DatePicker
                      placeholder=" DD MM YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={
                        formData1?.effectiveFrom
                          ? moment(formData1?.effectiveFrom).toDate()
                          : null
                      }
                      onChange={(date) => {
                        const dateString = date;

                        const dateObject = new Date(dateString);

                        const isoString = dateObject.toISOString(); // "2023-05-16T00:00:00.000Z"
                        const formattedDate = isoString.replace(
                          "T00:00:00.000Z",
                          "T05:52:34.459Z"
                        );

                        setFormData1({
                          ...formData1,
                          effectiveFrom: formattedDate,
                        });
                      }}
                      dateFormat="dd MMM yyyy"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      customInput={
                        formData1?.effectiveFrom ? (
                          <CustomInputCross1 properties="effectiveFrom" />
                        ) : (
                          <CustomInputClg1 />
                        )
                      }
                    />
                    <p
                      className={
                        validate1 && !formData1?.effectiveFrom
                          ? classes.errorText
                          : classes.errorTextHidden
                      }
                    >
                      Please Select Effective From
                    </p>
                  </div>
                </Col>
                <Col md={4}>
                  {/*<p className={classes.label4}>
                    End Date <span style={{ color: "red" }}>*</span>
                  </p>
                  <div className={classes.cu_field23Last}>
                    <DatePicker
                      placeholder=" DD MM YYYY"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      selected={
                        formData1?.effectiveDate
                          ? moment(formData1?.effectiveDate).toDate()
                          : null
                      }
                      // minDate={moment().toDate()}

                      minDate={moment(formData1?.effectiveFrom).toDate()}
                      onChange={(date) => {
                        const dateString = date;

                        const dateObject = new Date(dateString);

                        const isoString = dateObject.toISOString(); // "2023-05-16T00:00:00.000Z"
                        const formattedDate = isoString.replace(
                          "T00:00:00.000Z",
                          "T05:52:34.459Z"
                        );
                        setFormData1({
                          ...formData1,
                          effectiveDate: formattedDate,
                        });
                      }}
                      dateFormat="dd MMM yyyy"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      customInput={
                        formData1?.effectiveDate ? (
                          <CustomInputCross1 properties="effectiveDate" />
                        ) : (
                          <CustomInputClg />
                        )
                      }
                    />
                    <p
                      className={
                        validate1 && !formData1?.effectiveDate
                          ? classes.errorText
                          : classes.errorTextHidden
                      }
                    >
                      Please Select End Date
                    </p>
                    </div>*/}
                </Col>
              </Row>
            </InvoiceContainer>
            {change ? (
              <button
                className={classes.buttonAdd}
                onClick={handlerChangeStatus}
              >
                Submit
              </button>
            ) : (
              <button
                className={classes.buttonAdd}
                onClick={handlerOnbordingView}
              >
                Submit
              </button>
            )}
          </>
        ) : (
          ""
        )}

        {prevData?.status === 1 || prevData?.status === 2 ? (
          <>
            <Col md={12} style={{ marginTop: "w0px" }}>
              <Row>
                <Col>
                  <h6 style={{ marginLeft: "25px", marginTop: "25px" }}>
                    View On Boarding
                  </h6>
                </Col>
              </Row>

              <InvoiceContainer>
                <div className={classes.table}>
                  <div className={classes.th}>
                    <p className={classes.tableContent}>Status</p>
                    <p className={classes.tableContent}>Effective From</p>
                    {/*<p className={classes.tableContent}>End Date</p>*/}
                    <p className={classes.tableContent}>Action</p>
                  </div>
                  <div>
                    {isLoading1 && (
                      <p className={classes.no_data}>Loading .....</p>
                    )}
                    {!isLoading1 && !isError1 ? (
                      getOnBoardingHistory?.length > 0 ? (
                        typeof getOnBoardingHistory?.invoice !== "string" &&
                        getOnBoardingHistory?.map((item, index) => {
                          return (
                            <div className={classes.td} key={item?.positionId}>
                              <p className={classes.tableContent}>
                                {item?.status}
                              </p>

                              <p className={classes.tableContent}>
                                {item?.effectiveFrom === null
                                  ? ""
                                  : moment(item?.effectiveFrom).format(
                                      "DD MMM YY"
                                    )}
                              </p>

                              {/*<p className={classes.tableContent}>
                                {item?.effectiveDate === null
                                  ? ""
                                  : moment(item?.effectiveDate).format(
                                      "DD MMM YY"
                                    )}
                                  </p>*/}
                              <p>
                                {index === 0 && ( // Display the edit icon only for the first item
                                  <img
                                    src={EditIcon}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      handleOnboardStatus(
                                        item?.id,
                                        item?.statusId
                                      );
                                    }}
                                  />
                                )}
                              </p>
                            </div>
                          );
                        })
                      ) : (
                        <p className={classes.no_data}>No Data Found</p>
                      )
                    ) : null}
                  </div>
                </div>
              </InvoiceContainer>
            </Col>
            <button
              className={classes.buttonAdd}
              onClick={() => {
                setNextScreen("PartnerList");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "PartnerList",
                  page3: null,
                  page4: null,
                  active: "PartnerList",
                }));
              }}
            >
              Done
            </button>
          </>
        ) : (
          <Row>
            <Col md={12}>
              <h6 style={{ marginTop: "-5px", marginLeft: "25px" }}>
                On Boarding Date
              </h6>
            </Col>
            <Col md={3} style={{ marginLeft: "27px" }}>
              <div className={classes.cu_field23Last}>
                <DatePicker
                  placeholder=" DD MM YYYY"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  selected={
                    formData?.timesheetDate
                      ? moment(formData?.timesheetDate).toDate()
                      : null
                  }
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      timesheetDate: moment(date).format("DD MMM YYYY"),
                    });
                  }}
                  dateFormat="dd MMM yyyy"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  customInput={
                    formData?.timesheetDate ? (
                      <CustomInputCross properties="timesheetDate" />
                    ) : (
                      <CustomInputClg />
                    )
                  }
                />
                <p
                  className={
                    validate && !formData?.timesheetDate
                      ? classes.errorText
                      : classes.errorTextHidden
                  }
                >
                  Please Enter On Boarding Date
                </p>
              </div>
            </Col>
            <Col md={3} style={{ marginLeft: "24px", marginTop: "7px" }}>
              <InvoiceButton
                onClick={() => {
                  handlerOnbording();
                }}
              >
                Done
              </InvoiceButton>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};
export default OnBoarding;
