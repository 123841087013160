import React, { useState, useEffect } from "react";
import style from "./CreateTaskComponent.module.css";
import calendar from "../../../../../assets/Grievance portal/dateldpi.png"
import "react-calendar/dist/Calendar.css";
import { Col, Row, Form, Button, Image } from "react-bootstrap";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory,useLocation } from "react-router-dom";
import Moment from "moment";
import constants from "../../../../../Utils/constants";
import RequiredFieldIndication from "./RequiredFieldIndication";
import DatePicker from 'react-datepicker';
import CustomAutoComplete from "../../../../taskTracker/commonComponent/AutoComplete";
import { color } from "@mui/system";


const CreateTaskComponent = (props) => {
  console.log(props?.data.location?.state?.comment)
  const currentDate = Moment().toDate();
  const date= new Date();
  date.setDate(date.getDate()+1)
  const [startDate1, setStartDate] = useState(date);
  const [functionalArea, setFunctionalArea] = useState([]);
  const[peojectList, setProjectList]=useState([]);
  const[disable,setDisable]=useState(true)

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img
          type="image/svg+xml"
          width="20px"
          style={{ position: "absolute", right: "5%", top: "0%", zIndex:"0" }}
          src={calendar}
          onClick={props.onClick}
          alt=""
        />
      </div>
    );
  });


  const [employeeName, setEmployeeName] = useState()
  const[relatedTo, setRelataedTo]=useState(props?.data.location?.state?.task?.empName)
  const[TaskRelatedToId,setTaskRelatedToId]=useState();
  const [showRes, setshowRes] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const[arrayObj,setarrayObj]=useState([])
  const [employeeId, setEmployeeId] = useState("");
  const [option, setOption] = useState("High");
  const[projectoptin,setProjectOption]=useState(null)
  const[funtionalAreaOpt,setFunctionalOption]=useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const[validate,setValidate]=useState(false)
  const [state, setState] = useState({
    description:props?.data.location?.state?.comment,
    title: "",
  });
  const history = useHistory();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
    employeeList?.sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );


  functionalArea?.sort((a, b) =>
  a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
);
  function handleChange(id) {
    setEmployeeId(id);
  }

 

  function handleOption(e) {
    e.preventDefault();
    setOption(e.target.value);
  }

  function handleOption1(e) {
    e.preventDefault();
    setProjectOption(e.target.value);
  }

  function handleOption2(e) {
    e.preventDefault();
  
    if(e.target.value===1){
      setDisable(false)
      getProject();
    }else{
      setDisable(true)
      setProjectList([])
      setProjectOption(null)
    }
    setFunctionalOption();
  }
  const hideAlert = (data) => {
    setTimeout(() => {
      setshowRes(false);
      handleRedirect(props?.data.location?.state?.page);
    }, 1000);
  };

  const handleRedirect = (id) => {
    history.goBack(-1)
    
  };

  useEffect(()=>{
    setTaskRelatedToId(props?.data.location?.state?.task?.empid)
  },[])
  const url = constants.apiBaseUrl;
  const url2 = constants.apiBaseUrl2;
  const baseUrl = constants.apiBaseUrlResource;


  
  const getFunctionalArea = () => {
    axios
      .get(url2 + "/getAllFunctionalArea", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res)
        setFunctionalArea(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProject = () => {
    axios
      .get(baseUrl + "/getAllActiveProject", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res)
        setProjectList(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function postData(e) {
if(!employeeId || !startDate1 || !option || !state?.title){
  console.log("validate", validate)
  setValidate(true)
  return;
}
    e.preventDefault();
    const data = {
      assignedTo: employeeId,
      planedEndDate: Moment(startDate1).format("YYYY-MM-DD hh:mm"),
      priority: option,
      title: state.title.trim(),
      functionalFieldId:7,
      project:projectoptin,
       taskRelatedToId:TaskRelatedToId,
    };

    if (state.description) {
      data.description = state.description.trim();
    }
    setIsLoading(true);
    axios
      .post(url2 + "/createTask", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        setIsLoading(false);
        if (res.data.statusCode === 1) {
          // setShowErr(true);
          return;
        }
        if (res.data.responseStatus) {
          // setShowErr(true);
        } else {
          setshowRes(true);
          setShowErr(false);
          hideAlert(res.data.responseData);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        return err;
      });
  }
  

  useEffect(() => {
    async function employeedata() {
      await axios
        .get(url + "/getEmployeeList", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
            employeeId: localStorage.getItem("employeeId"),
          },
        })
        .then((res) => {
          console.log(res, "getEmployee LIst")
          setEmployeeList(res.data.responseData.employeeList);
          console.log("taskassignbyme", res);
          setarrayObj (employeeList.map(item => {
            return {
              label: item.name,
              id: item.id
            };
          }));
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    }
    employeedata();
  }, []);

  function onChange(e) {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  }



  useEffect(() => {
    getFunctionalArea();
    getProject()
  }, []);
  return (
    <div className={style.rightSection}>

      <div className={style.container}>
        <span>Create Task</span>
        {showRes && (
          <div className={style.alert} id="alert_msg">
            Task added successfully!
          </div>
        )}
        <div className={style.createTask}>
          <form id="create_task_id" className={style.gridContainer}>
            <Col>
              <Row>
                <label className={style.font15}>
                  Task Title<span style={{ color: "red" }}>*</span>
                  <input
                    className={style.input}
                    autocomplete="off"
                    type="input"
                    id="tast_title"
                    name="title"
                    value={state.title}
                    style={{marginTop:"10px"}}
                    onChange={onChange}
                  />
                   <p
                    className={
                      validate && !state?.title
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Enter Task Title
                  </p>
                </label>
               
              </Row>
              <Row>
                <label className={style.font15}>
                  Assigned To
                <span style={{ color: "red" }}>*</span></label>
                <CustomAutoComplete
                  placeholder="Search by Employee Name"
                  
                  autocomplete="90%"
                  dataList={employeeList}
                  onSelect={(value) => {
                    setEmployeeName(value.name);
                    handleChange(value.id)
                  }}
                  onChange={(e) => setEmployeeName(e.target.value)}
                  value={employeeName}
                />
                  <p
                    className={
                      validate && !employeeId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Assigned To
                  </p>
              </Row>
              <Row>
                <div className={style.dateLabel} style={{marginTop:"3px"}}>
                  <label className={style.font15}>Planned End Date<span style={{ color: "red" }}>*</span></label>
                  <div className={style.input}>
                    <DatePicker
                      dateFormat="dd MMM yy"
                      minDate={new Date()}
                      selected={startDate1}
                      startDate={null}
                      onChange={(date) => setStartDate(date)}
                      disabledKeyboardNavigation
                      defaultValue={null}
                      customInput={<CustomInput />}
                    />
                  </div>
                  <p
                    className={
                      validate && !startDate1
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Planned End Date
                  </p>
                </div>
              </Row>

              <Row>
                <div style={{width:"100%"}}>
                  <label className={style.font15} style={{marginTop:"5px"}}>
                  Project<br />
                    <select onChange={(e) => handleOption1(e)} >
                      <option value="" disabled="" selected="">
                        ---select---
                      </option>
                      {peojectList?.map((val) => {
                return <option value={val.name}>{val?.name}</option>;
              })}
                    </select>
                  </label>
                </div>
              </Row>
            </Col>
            <Col>
              <Row>
                <label className={style.descLabel}>
                  Task Description
                  <textarea
                    className={style.text}
                    style={{ color: "black" }}
                    name="description"
                    value={state.description}
                    onChange={onChange}
                    id="task_description"
                    
                  />
                </label>
              </Row>
              <Row>
                <div className={style.priorityLabel}>
                  <label className={style.font15}>
                    Priority<span style={{ color: "red" }}>*</span> <br />
                    <select onChange={(e) => handleOption(e)}>
                      <option value="High">High</option>
                      <option value="Urgent">Urgent</option>
                      <option value="Low">Low</option>
                    </select>
                    <p
                    className={
                      validate && !option
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Priority
                  </p>
                  </label>
                  
                  <label className={style.font15}>
                  Functional Area {showErr && <RequiredFieldIndication />} <br />
                    <select onChange={(e) => handleOption2(e)} disabled={true}>
                      <option value="" disabled="" selected="">
                      RMS
                      </option>
                      {functionalArea?.map((val) => {
                return <option value={val.id}>{val?.name}</option>;
              })}
                    </select>
                  </label>
                </div>
              </Row>
              

              <Row>
                <div className={style.priorityLabel}>
                  <label className={style.font15}>
                  Task Related To{showErr && <RequiredFieldIndication />} <br />
                  <div style={{marginTop:"10px"}}>
                  <CustomAutoComplete
                  placeholder="Search by Employee Name"
                  autocomplete="91%"
                  disabled={true}                  
                  // dataList={employeeList}
                  // onSelect={(value) => {
                  //   setRelataedTo(value.name);
                  //   handleChange1(value.id)
                  // }}
                  // onChange={(e) => setRelataedTo(e.target.value)}
                  value={relatedTo}
                />
                </div>
                  </label>
                </div>
              </Row>
            </Col>
          </form>
        </div>
        <button
          className={style.submitButton}
          onClick={postData}
          disabled={isLoading}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default CreateTaskComponent;