import axios from "axios";
import { resolve } from "./index";

const commentAPI = {
    addComment: async (userId, queryId, commentData) => {
        return await resolve(axios.post(`${process.env.REACT_APP_GRIEVANCE_APP_URL}/comment/addComment`, { data: commentData,}).then((res) => res.data));
    },
    editComment: async (userId, commentData) => {
        return await resolve(axios.patch(`${process.env.REACT_APP_GRIEVANCE_APP_URL}/comment/editComment`, { data: commentData, params: { userId } }).then((res) => res.rData));
    },
    deleteComment: async (userId, queryId, commentId) => {
        return await resolve(
            axios.delete(`${process.env.REACT_APP_GRIEVANCE_APP_URL}/comment/deleteComment`, { data: { commentId }, params: { userId, queryId } }).then((res) => res.rData)
        );
    },
};

export default commentAPI;
