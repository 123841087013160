import React, { useState, useEffect } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Toastify, ToastifyError } from "../../../../App";
import {
  getEmployeeDetailsByEmployeeId,
  viewFeedback,
  deletePerformanceReview,
} from "../../utils/api";
import style from "./ViewFeedback.module.css";

function ViewFeedback({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) {
  const [show, setShow] = useState(false);
  const { data: profileData } = useQuery(
    ["  getEmployeeDetailsByEmployeeId"],
    () => getEmployeeDetailsByEmployeeId(prevData?.empId),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const { data: profileData1, refetch } = useQuery(
    ["viewFeedback"],
    () => viewFeedback(prevData?.pReId),
    {
      refetchOnWindowFocus: false,
      removeAfterUnmount: true,
      cacheTime: 1000,
    }
  );

  const mutation = useMutation(deletePerformanceReview, {
    onSuccess: (res) => {
      Toastify("Deleted Successfully.");
      setNextScreen("EmployeeFeedbackList");
      setPrevData((prevData) => ({
        ...prevData,
        sidebar: "EmployeePerformanceReview",
        page: "EmployeeFeedbackList",
        page1: "EmployeePerformanceReview",
        page2: "EmployeeFeedbackList",
        page3: null,
        active: "EmployeeFeedbackList",
      }));
    },
    onError: () => {
      ToastifyError("Something went wrong.");
      setShow(false);
    },
  });

  useEffect(() => {
    refetch();
  }, [profileData1]);

  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
      ></BreadCrum>
      {/* first */}
      <div className={style.card}>
        <p className={style.title}>Employee Details</p>
        <div className={style.Cu_row1}>
          <div className={style.cu_col1}>
            <p className={style.cu_label}>Employee Name</p>
            <p>
              <p>{` ${profileData?.name} (${profileData?.empCode})`}</p>
            </p>
          </div>
          {/* <div className={style.cu_col1}>
            <p className={style.cu_label}>Employee Name</p>
            <p>{profileData?.name}</p>
          </div> */}
        </div>
      </div>
      {/* second */}
      <div className={style.card}>
        <p className={style.title}>Reviewer</p>
        <div className={style.Cu_row1}>
          <div className={style.cu_col11}>
            <p className={style.cu_label}>Review Date</p>
            <p>{moment(profileData1?.reviewDateView).format("DD MMM YY")}</p>
          </div>
          <div className={style.cu_col11}>
            <p className={style.cu_label}>Review Source</p>
            <p>{profileData1?.reviewSourceType}</p>
          </div>
          <div className={style.cu_col11}>
            <p className={style.cu_label}>Review Type</p>
            <p>{profileData1?.reviewType}</p>
          </div>
          {profileData1?.reviewSourceType !== "Client" &&
          profileData1?.reviewSourceType !== "Online" ? (
            <div className={style.cu_col11}>
              <p className={style.cu_label}>Reviewed By</p>
              <p>{profileData1?.reviewedBy}</p>
            </div>
          ) : null}
          {profileData1?.reviewSourceType === "Client" ? (
            <div className={style.cu_col11}>
              <p className={style.cu_label}>Client Name</p>
              <p>{profileData1?.clientName}</p>
            </div>
          ) : null}
          {profileData1?.reviewSourceType === "Online" ? (
            <div className={style.cu_col11}>
              <p className={style.cu_label}>Online Platform</p>
              <p>{profileData1?.onlinePlatform}</p>
            </div>
          ) : null}
        </div>
        <div className={style.Cu_row1}>
          {profileData1?.projectName != null ? (
            <>
              <div className={style.cu_col11}>
                <p className={style.cu_label}>Project Name</p>
                <p>{profileData1?.projectName}</p>
              </div>
            </>
          ) : null}
          {profileData1?.reviewSourceType === "Client" ? (
            profileData1?.reviewedByName !== "" ? (
              <div className={style.cu_col11}>
                <p className={style.cu_label}>Reviewed By</p>
                <p>{profileData1?.reviewedBy}</p>
              </div>
            ) : null
          ) : null}
          {/* {profileData1?.reviewSourceType == "Online" ? (
            <div className={style.cu_col11}>
              <p className={style.cu_label}>Online Platform</p>
              <p>{profileData1?.onlinePlatform}</p>
            </div>
          ) : null} */}

          <div className={style.cu_col11}></div>
          <div className={style.cu_col11}></div>
        </div>
      </div>
      {/* third */}
      <div className={style.card2}>
        <p className={style.title}>Feedback</p>
        {/* Rating */}
        {Number(profileData1?.feedbackTypeId) === 1 ? (
          <div>
            <div className={style.rating}>
              <p>
                <span className={style.cu_label}>Skills Rating(1-10):</span> 1-3
                Poor, 4-5 Average, 6-7 Good, 8-10 Excellent
              </p>
              <p></p>
              <div className={style.box}>
                {profileData1?.feedbackSkill?.map((i) => {
                  return (
                    <div className={style.boxitem}>
                      <p style={{ width: "25%" }}>{i?.label}</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gridColumnGap: "50%",
                        }}
                      >
                        <div className={style.ratingAlign}>
                          <p style={{ width: "11vw" }}>
                            {i?.rating >= 1 && i?.rating <= 3
                              ? "Poor"
                              : i?.rating >= 4 && i?.rating <= 5
                              ? "Average"
                              : i?.rating >= 6 && i?.rating <= 7
                              ? "Good"
                              : i?.rating >= 8 && i?.rating <= 10
                              ? "Excellent"
                              : null}
                          </p>
                          <p style={{ width: "1vw" }}>{i?.rating}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div className={style.box}>
                {profileData1?.feedbackSkill?.map((i) => {
                  return (
                    <div className={style.boxitem}>
                      <p style={{ width: "25%" }}>{i?.label}</p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gridColumnGap: "50%",
                        }}
                      >
                        <p>
                          {i?.rating >= 1 && i?.rating <= 3
                            ? "Poor"
                            : i?.rating >= 4 && i?.rating <= 5
                            ? "Average"
                            : i?.rating >= 6 && i?.rating <= 7
                            ? "Good"
                            : i?.rating >= 8 && i?.rating <= 10
                            ? "Excellent"
                            : null}
                        </p>
                        <p>{i?.rating}</p>
                      </div>
                    </div>
                  );
                })}
              </div> */}
            </div>
            <div className={style.feedback}>
              <p className={style.cu_label}>
                Overall Feedback <span className={style.mand}>*</span>
              </p>
              <p>{profileData1?.overallFeedback}</p>
            </div>
            <div className={style.other}>
              <p>
                <span className={style.mand}>*</span>NS - Candidate doest not
                have skill
              </p>
              <p>
                <span className={style.mand}>*</span>NE - Skill not evaluated by
                interviewer
              </p>
            </div>

            {Number(profileData1?.statusIdRating) === 1 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue === 1
                      //     ? "Poor"
                      //     : profileData1?.overallRatingValue === 2
                      //     ? "Good"
                      //     : profileData1?.overallRatingValue === 3
                      //     ? "Excellent"
                      //     : profileData1?.overallRatingValue == "NE"
                      //     ? "Not Evaluated"
                      //     : profileData1?.overallRatingValue == "NS"
                      //     ? "Not Skill"
                      //     : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {profileData1?.overallRatingValue === "1" ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : profileData1?.overallRatingValue === "2" ? (
                      <span style={{ color: "yello" }}>Good</span>
                    ) : profileData1?.overallRatingValue === "3" ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
            {Number(profileData1?.statusIdRating) === 2 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue == 1
                      //     ? "Poor"
                      //     : profileData1?.overallRatingValue == 2
                      //     ? "Average"
                      //     : profileData1?.overallRatingValue == 3
                      //     ? "Good"
                      //     : profileData1?.overallRatingValue == 4
                      //     ? "Very Good"
                      //     : profileData1?.overallRatingValue == 5
                      //     ? "Excellent"
                      //     : profileData1?.overallRatingValue == "NE"
                      //     ? "Not Evaluated"
                      //     : profileData1?.overallRatingValue == "NS"
                      //     ? "Not Skill"
                      //     : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {Number(profileData1?.overallRatingValue) === 1 ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : Number(profileData1?.overallRatingValue) === 2 ? (
                      <span style={{ color: "green" }}>Average</span>
                    ) : Number(profileData1?.overallRatingValue) === 3 ? (
                      <span style={{ color: "green" }}>Good</span>
                    ) : Number(profileData1?.overallRatingValue) === 4 ? (
                      <span style={{ color: "green" }}>Very Good</span>
                    ) : Number(profileData1?.overallRatingValue) === 5 ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
            {Number(profileData1?.statusIdRating) === 3 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue > 7
                      //     ? "Excellent"
                      //     : profileData1?.overallRatingValue < 4
                      //     ? "Poor"
                      //     : profileData1?.overallRatingValue < 6
                      //     ? "Average"
                      //     : profileData1?.overallRatingValue < 8
                      //     ? "Good"
                      //     : profileData1?.overallRatingValue == "NE"
                      //     ? "Not Evaluated"
                      //     : profileData1?.overallRatingValue == "NS"
                      //     ? "Not Skill"
                      //     : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {profileData1?.overallRatingValue > 7 ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue < 4 ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : profileData1?.overallRatingValue < 6 ? (
                      <span style={{ color: "green" }}>Average</span>
                    ) : profileData1?.overallRatingValue < 8 ? (
                      <span style={{ color: "green" }}>Good</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}

            <p
              className={style.rating}
              style={{ color: "black", fontWeight: "600" }}
            >
              Status :
              <span
                style={{
                  color:
                    Number(profileData1?.statusId) === 1
                      ? "green"
                      : Number(profileData1?.statusId) === 2
                      ? "red"
                      : Number(profileData1?.statusId) === 3
                      ? "orange"
                      : Number(profileData1?.statusId) === 4
                      ? "brown"
                      : null,
                  fontWeight: "600",
                }}
              >
                {" "}
                {profileData1?.status}
              </span>
            </p>
          </div>
        ) : null}
        {/* fileupload */}
        {Number(profileData1?.feedbackTypeId) === 3 ? (
          <div>
            <div>
              <p
                className={style.rating}
                style={{ color: "black", fontWeight: "600" }}
              >
                Uploaded Feedback
              </p>
              <p className={style.rating}>
                <a
                  href={profileData1?.filePath}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p
                    style={{
                      color: "#a15f5f",
                      textDecoration: "underline",
                      width: "34rem",
                    }}
                  >
                    {profileData1?.filePath?.split("@")[1]}
                  </p>
                </a>
              </p>
              {Number(profileData1?.statusIdRating) === 1 ? (
                <>
                  <p
                    className={style.rating}
                    style={{ color: "black", fontWeight: "600" }}
                  >
                    Overall Rating :
                    <span
                      style={{
                        // color:
                        //   profileData1?.overallRatingValue === 1
                        //     ? "Poor"
                        //     : profileData1?.overallRatingValue === 2
                        //     ? "Good"
                        //     : profileData1?.overallRatingValue === 3
                        //     ? "Excellent"
                        //     : profileData1?.overallRatingValue == "NE"
                        //     ? "Not Evaluated"
                        //     : profileData1?.overallRatingValue == "NS"
                        //     ? "Not Skill"
                        //     : null,
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {profileData1?.overallRatingValue}{" "}
                      {profileData1?.overallRatingValue === "1" ? (
                        <span style={{ color: "red" }}>Poor</span>
                      ) : profileData1?.overallRatingValue === "2" ? (
                        <span style={{ color: "yello" }}>Good</span>
                      ) : profileData1?.overallRatingValue === "3" ? (
                        <span style={{ color: "green" }}>Excellent</span>
                      ) : profileData1?.overallRatingValue === "NE" ? (
                        <span style={{ color: "red" }}>Not Evaluated</span>
                      ) : profileData1?.overallRatingValue === "NS" ? (
                        <span style={{ color: "red" }}>Not Skill</span>
                      ) : null}
                    </span>
                  </p>
                </>
              ) : null}
              {Number(profileData1?.statusIdRating) === 2 ? (
                <>
                  <p
                    className={style.rating}
                    style={{ color: "black", fontWeight: "600" }}
                  >
                    Overall Rating :
                    <span
                      style={{
                        // color:
                        //   profileData1?.overallRatingValue == 1
                        //     ? "Poor"
                        //     : profileData1?.overallRatingValue == 2
                        //     ? "Average"
                        //     : profileData1?.overallRatingValue == 3
                        //     ? "Good"
                        //     : profileData1?.overallRatingValue == 4
                        //     ? "Very Good"
                        //     : profileData1?.overallRatingValue == 5
                        //     ? "Excellent"
                        //     : profileData1?.overallRatingValue == "NE"
                        //     ? "Not Evaluated"
                        //     : profileData1?.overallRatingValue == "NS"
                        //     ? "Not Skill"
                        //     : null,
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {profileData1?.overallRatingValue}{" "}
                      {Number(profileData1?.overallRatingValue) === 1 ? (
                        <span style={{ color: "red" }}>Poor</span>
                      ) : Number(profileData1?.overallRatingValue) === 2 ? (
                        <span style={{ color: "green" }}>Average</span>
                      ) : Number(profileData1?.overallRatingValue) === 3 ? (
                        <span style={{ color: "green" }}>Good</span>
                      ) : Number(profileData1?.overallRatingValue) === 4 ? (
                        <span style={{ color: "green" }}>Very Good</span>
                      ) : Number(profileData1?.overallRatingValue) === 5 ? (
                        <span style={{ color: "green" }}>Excellent</span>
                      ) : profileData1?.overallRatingValue === "NE" ? (
                        <span style={{ color: "red" }}>Not Evaluated</span>
                      ) : profileData1?.overallRatingValue === "NS" ? (
                        <span style={{ color: "red" }}>Not Skill</span>
                      ) : null}
                    </span>
                  </p>
                </>
              ) : null}
              {Number(profileData1?.statusIdRating) === 3 ? (
                <>
                  <p
                    className={style.rating}
                    style={{ color: "black", fontWeight: "600" }}
                  >
                    Overall Rating :
                    <span
                      style={{
                        // color:
                        //   profileData1?.overallRatingValue > 7
                        //     ? "Excellent"
                        //     : profileData1?.overallRatingValue < 4
                        //     ? "Poor"
                        //     : profileData1?.overallRatingValue < 6
                        //     ? "Average"
                        //     : profileData1?.overallRatingValue < 8
                        //     ? "Good"
                        //     : profileData1?.overallRatingValue == "NE"
                        //     ? "Not Evaluated"
                        //     : profileData1?.overallRatingValue == "NS"
                        //     ? "Not Skill"
                        //     : null,
                        fontWeight: "600",
                      }}
                    >
                      {" "}
                      {profileData1?.overallRatingValue}{" "}
                      {profileData1?.overallRatingValue > 7 ? (
                        <span style={{ color: "green" }}>Excellent</span>
                      ) : profileData1?.overallRatingValue < 4 ? (
                        <span style={{ color: "red" }}>Poor</span>
                      ) : profileData1?.overallRatingValue < 6 ? (
                        <span style={{ color: "green" }}>Average</span>
                      ) : profileData1?.overallRatingValue < 8 ? (
                        <span style={{ color: "green" }}>Good</span>
                      ) : profileData1?.overallRatingValue === "NE" ? (
                        <span style={{ color: "red" }}>Not Evaluated</span>
                      ) : profileData1?.overallRatingValue === "NS" ? (
                        <span style={{ color: "red" }}>Not Skill</span>
                      ) : null}
                    </span>
                  </p>
                </>
              ) : null}
              <p
                className={style.rating}
                style={{ color: "black", fontWeight: "600" }}
              >
                Status :{" "}
                <span
                  style={{
                    color:
                      profileData1?.statusId === 1
                        ? "green"
                        : profileData1?.statusId === 2
                        ? "red"
                        : profileData1?.statusId === 3
                        ? "orange"
                        : profileData1?.statusId === 4
                        ? "brown"
                        : null,
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  {profileData1?.status}
                </span>
              </p>
            </div>
          </div>
        ) : null}
        {/* test */}
        {Number(profileData1?.feedbackTypeId) === 2 ? (
          <div className={style.rating}>
            <div className={style.h}>
              <label className={style.h_name}>Skills</label>
              <div className={style.h1}>
                <label className={style.h_name}>Score Obtained</label>
                <label className={style.h_name}>Maximum Score</label>
              </div>
            </div>
            {profileData1?.feedbackSkill?.map((item, index) => {
              return (
                <>
                  {/* <div key={index} className={style.h}>
                    <label>{item.label}</label>
                    <div className={style.h2}>
                      <p className={style.mg}>{item.scoreObtained}</p>
                      <p className={style.mg1}>{item.maxScore}</p>
                    </div>
                  </div> */}
                  <div key={index}>
                    <div className={style.table}>
                      <div className={style.th2}>
                        <p>{item.label}</p>
                        <p>{item.scoreObtained}</p>
                        <p>{item.maxScore}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            {Number(profileData1?.statusIdRating) === 1 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600", padding: "0" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue === 1
                      //     ? "Poor"
                      //     : profileData1?.overallRatingValue === 2
                      //     ? "Good"
                      //     : profileData1?.overallRatingValue === 3
                      //     ? "Excellent"
                      //     : profileData1?.overallRatingValue == "NE"
                      //     ? "Not Evaluated"
                      //     : profileData1?.overallRatingValue == "NS"
                      //     ? "Not Skill"
                      //     : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {profileData1?.overallRatingValue === "1" ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : profileData1?.overallRatingValue === "2" ? (
                      <span style={{ color: "yello" }}>Good</span>
                    ) : profileData1?.overallRatingValue === "3" ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
            {Number(profileData1?.statusIdRating) === 2 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600", padding: "0" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue == 1
                      //     ? "Poor"
                      //     : profileData1?.overallRatingValue == 2
                      //     ? "Average"
                      //     : profileData1?.overallRatingValue == 3
                      //     ? "Good"
                      //     : profileData1?.overallRatingValue == 4
                      //     ? "Very Good"
                      //     : profileData1?.overallRatingValue == 5
                      //     ? "Excellent"
                      //     : profileData1?.overallRatingValue == "NE"
                      //     ? "Not Evaluated"
                      //     : profileData1?.overallRatingValue == "NS"
                      //     ? "Not Skill"
                      //     : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {Number(profileData1?.overallRatingValue) === 1 ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : Number(profileData1?.overallRatingValue) === 2 ? (
                      <span style={{ color: "green" }}>Average</span>
                    ) : Number(profileData1?.overallRatingValue) === 3 ? (
                      <span style={{ color: "green" }}>Good</span>
                    ) : Number(profileData1?.overallRatingValue) === 4 ? (
                      <span style={{ color: "green" }}>Very Good</span>
                    ) : Number(profileData1?.overallRatingValue) === 5 ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
            {Number(profileData1?.statusIdRating) === 3 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600", padding: "0" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue > 7
                      //     ? "Excellent"
                      //     : profileData1?.overallRatingValue < 4
                      //     ? "Poor"
                      //     : profileData1?.overallRatingValue < 6
                      //     ? "Average"
                      //     : profileData1?.overallRatingValue < 8
                      //     ? "Good"
                      //     : profileData1?.overallRatingValue == "NE"
                      //     ? "Not Evaluated"
                      //     : profileData1?.overallRatingValue == "NS"
                      //     ? "Not Skill"
                      //     : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {profileData1?.overallRatingValue > 7 ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue < 4 ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : profileData1?.overallRatingValue < 6 ? (
                      <span style={{ color: "green" }}>Average</span>
                    ) : profileData1?.overallRatingValue < 8 ? (
                      <span style={{ color: "green" }}>Good</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
          </div>
        ) : null}
        <div></div>
        {/* subjective */}
        {Number(profileData1?.feedbackTypeId) === 4 ? (
          <div>
            <div>
              <p
                className={style.rating}
                style={{
                  color: "black",
                  fontWeight: "600",
                  paddingBottom: "0px",
                }}
              >
                Subjective Feedback
              </p>
              <p className={style.rating}>
                {profileData1?.feedbackDescription}
              </p>
            </div>
            {Number(profileData1?.statusIdRating) === 1 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue === 1
                      //     ? "Poor"
                      //     : profileData1?.overallRatingValue === 2
                      //     ? "Good"
                      //     : profileData1?.overallRatingValue === 3
                      //     ? "Excellent"
                      //     : profileData1?.overallRatingValue == "NE"
                      //     ? "Not Evaluated"
                      //     : profileData1?.overallRatingValue == "NS"
                      //     ? "Not Skill"
                      //     : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {profileData1?.overallRatingValue === "1" ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : profileData1?.overallRatingValue === "2" ? (
                      <span style={{ color: "yello" }}>Good</span>
                    ) : profileData1?.overallRatingValue === "3" ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
            {Number(profileData1?.statusIdRating) === 2 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue == 1 ? (
                      //     <span style={{ color: "red" }}>Poor</span>
                      //   ) : profileData1?.overallRatingValue == 2 ? (
                      //     <span style={{ color: "green" }}>Average</span>
                      //   ) : profileData1?.overallRatingValue == 3 ? (
                      //     <span style={{ color: "green" }}>Good</span>
                      //   ) : profileData1?.overallRatingValue == 4 ? (
                      //     <span style={{ color: "green" }}>Very Good</span>
                      //   ) : profileData1?.overallRatingValue == 5 ? (
                      //     <span style={{ color: "green" }}>Excellent</span>
                      //   ) : profileData1?.overallRatingValue == "NE" ? (
                      //     <span style={{ color: "red" }}>Not Evaluated</span>
                      //   ) : profileData1?.overallRatingValue == "NS" ? (
                      //     <span style={{ color: "red" }}>Not Skill</span>
                      //   ) : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {Number(profileData1?.overallRatingValue) === 1 ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : Number(profileData1?.overallRatingValue) === 2 ? (
                      <span style={{ color: "green" }}>Average</span>
                    ) : Number(profileData1?.overallRatingValue) === 3 ? (
                      <span style={{ color: "green" }}>Good</span>
                    ) : Number(profileData1?.overallRatingValue) === 4 ? (
                      <span style={{ color: "green" }}>Very Good</span>
                    ) : Number(profileData1?.overallRatingValue) === 5 ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
            {profileData1?.statusIdRating == 3 ? (
              <>
                <p
                  className={style.rating}
                  style={{ color: "black", fontWeight: "600" }}
                >
                  Overall Rating :
                  <span
                    style={{
                      // color:
                      //   profileData1?.overallRatingValue > 7
                      //     ? "Excellent"
                      //     : profileData1?.overallRatingValue < 4
                      //     ? "Poor"
                      //     : profileData1?.overallRatingValue < 6
                      //     ? "Average"
                      //     : profileData1?.overallRatingValue < 8
                      //     ? "Good"
                      //     : profileData1?.overallRatingValue == "NE"
                      //     ? "Not Evaluated"
                      //     : profileData1?.overallRatingValue == "NS"
                      //     ? "Not Skill"
                      //     : null,
                      fontWeight: "600",
                    }}
                  >
                    {" "}
                    {profileData1?.overallRatingValue}{" "}
                    {profileData1?.overallRatingValue > 7 ? (
                      <span style={{ color: "green" }}>Excellent</span>
                    ) : profileData1?.overallRatingValue < 4 ? (
                      <span style={{ color: "red" }}>Poor</span>
                    ) : profileData1?.overallRatingValue < 6 ? (
                      <span style={{ color: "green" }}>Average</span>
                    ) : profileData1?.overallRatingValue < 8 ? (
                      <span style={{ color: "green" }}>Good</span>
                    ) : profileData1?.overallRatingValue === "NE" ? (
                      <span style={{ color: "red" }}>Not Evaluated</span>
                    ) : profileData1?.overallRatingValue === "NS" ? (
                      <span style={{ color: "red" }}>Not Skill</span>
                    ) : null}
                  </span>
                </p>
              </>
            ) : null}
            <p
              className={style.rating}
              style={{ color: "black", fontWeight: "600" }}
            >
              Status :
              <span
                style={{
                  color:
                    profileData1?.statusId === 1
                      ? "green"
                      : profileData1?.statusId === 2
                      ? "red"
                      : profileData1?.statusId === 3
                      ? "orange"
                      : profileData1?.statusId === 4
                      ? "brown"
                      : null,
                  fontWeight: "600",
                }}
              >
                {profileData1?.status}
              </span>
            </p>
          </div>
        ) : null}
      </div>
      {/* btn */}
      <div className={style.button}>
        <button
          className={style.btn3}
          onClick={() => {
            setShow(true);
          }}
        >
          Delete
        </button>
        <button
          className={style.btn1}
          onClick={() => {
            setNextScreen("editEmployeeFeedback");
            setPrevData((prevData) => ({
              ...prevData,
              empId: prevData?.empId,
              sidebar: "EmployeePerformanceReview",
              page: "editEmployeeFeedback",
              page1: "EmployeePerformanceReview",
              page2: "EmployeeFeedbackList",
              page3: "viewFeedback",
              page4: "editEmployeeFeedback",
              page5: null,
              active: "editEmployeeFeedback",
            }));
          }}
        >
          Edit Feedback
        </button>
        <button
          className={style.btn2}
          onClick={() => {
            setNextScreen("EmployeeFeedbackList");
            setPrevData((prevData) => ({
              ...prevData,
              // empId: item?.employeeId,
              sidebar: "EmployeePerformanceReview",
              page: "EmployeeFeedbackList",
              page1: "EmployeePerformanceReview",
              page2: "EmployeeFeedbackList",
              page3: null,
              active: "EmployeeFeedbackList",
            }));
          }}
        >
          Done
        </button>
      </div>
      <Modal
        open={show}
        center
        onClose={() => setShow(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          <div>
            <p className={style.textM}>Do you want to delete this Feedback?</p>
          </div>
          <div className={style.M_button}>
            <div className={style.button1}>
              <button
                className={style.w_inactive}
                onClick={() => setShow(false)}
              >
                No
              </button>
              <button
                className={style.b_inactive}
                // onClick={() => setShow(false)}
                onClick={() => {
                  mutation.mutate({
                    // recruiterId: localStorage.getItem("employeeId"),
                    performanceReviewId: prevData?.pReId,
                  });
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ViewFeedback;
