import React, { useEffect, useState } from "react";
import constants from "../../../Utils/constants";
import cookie from "react-cookies";
import SideBar from "../Clients/component/SideBar";
import Style from "./Dashboard.module.css";
import HandShakeIcon from "../../../assets/hand-shake-icon.png";
import HrIcon from "../../../assets/hr-icon.png";
import loaderImg from "../../../assets/loader.gif";
import ProjectWorkIcon from "../../../assets/project-work-icon.png";
import axios from "axios";

import { useHistory } from "react-router-dom";

export default function Dashboard() {
  let history = useHistory();

  const baseUrl = constants.apiBaseUrlResource;
  const [isLoaded, setIsLoaded] = useState(false);
  const [poolCount,setyPooCount] = useState(0);
  const[totalOffshore,setTotalResourceOffShore]=useState()
  const[totalOnsite,setOnsiteTotalCount]=useState()
  const [count, setCount] = useState("");
  const [allData1, setAllData1] = useState({
    action: [],
    bandwidths:[],
    empId:[],
    projectId:[],
    skillId: [],
    salaryRange:[],
    partners:[],
  });
  const [allData, setAllData] = useState({
    statusId: [],
    empId: [],
    skillId:[],
    experienceRange:[],
    salaryRange:[]
  });
  const [allData2, setAllData2] = useState({
    action:[],
    clientId:[],
    employeeId:[],
    skill: [],
    salaryRange:[],
    partners: [],
  });
  const getPoolCount = (clearData,currentPage) => {
    axios
      .post(
        baseUrl +
          "/getPoolResource?" +
          "limit=" +
          10 +
          "&page=" +
          currentPage,
          clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        
        setyPooCount(res.data.responseData.totalResources);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getOffshoreCount = (clearData, currentPage) => {
    setIsLoaded(true);

    axios
      .post(
        baseUrl +
          "/getAddResourceOffshore?" +
          "limit=" +
          10 +
          "&page=" +
          currentPage,
          clearData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setTotalResourceOffShore(res.data.responseData.totalResources);
      })
      .catch((err) => {
        setIsLoaded(false);
        console.log(err);
      });
  };
  const getOnsiteCount = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length == 0 ? allData : allData;
    setIsLoaded(true);
    axios
      .post(
        baseUrl +
          "/getAddResourceOnsite?" +
          "limit=" +
          10 +
          "&page=" +
          currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setOnsiteTotalCount(res.data.responseData.totalResources);
       })
      .catch((err) => {
      });
  };
  const getCount = () => {
    setIsLoaded(true);
    axios
      .get(baseUrl + "/getDashboard", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log({ res });
        setIsLoaded(false);
        setCount(res.data.responseData);
      })
      .catch((err) => {
        setIsLoaded(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getCount();
    getPoolCount(allData,1);
    getOffshoreCount(allData1,1);
    getOnsiteCount(allData2,1);
  }, []);

  return (
    <>
      <div className={Style.projectresource_mainSection}>
        <SideBar />
        <div className={Style.projectresource_mainsection}>
          <div
            style={{
              marginLeft: "25px",
              marginTop: "15px",
              padding: "10px 0px",
              fontWeight: "700",
            }}
          >
            Dashboard
          </div>

          {isLoaded ? (
            <div>
              <img
                src={loaderImg}
                alt="loader"
                style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
              />
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  paddingLeft: "38%",
                  position: "absolute",
                  color: "#FBBB08",
                  marginTop: "-4px",
                  fontStyle: "italic",
                  marginLeft: "34.5%",
                }}
              >
                Loading...
              </p>
            </div>
          ) : null}

          {count ? (
            <div style={{ marginLeft: "0px" }}>
              {/* one */}
              <div className={Style.imgSection}>
                <div
                  className={Style.imgcontentSection}
                  onClick={() => history.push("/clients")}
                >
                  <div className={Style.imgdiv}>
                    <img src={HandShakeIcon} alt="this is img section" />
                  </div>
                  <h1>{count.totalClient}</h1>
                  <span>Clients</span>
                </div>
                <div
                  className={Style.imgcontentSection}
                  onClick={() => history.push("/ProjectDetails")}
                >
                  <div className={Style.imgdiv}>
                    <img src={ProjectWorkIcon} alt="this is img section" />
                  </div>
                  <h1>{count.totalProjects}</h1>
                  <span>Projects</span>
                </div>
              </div>
              {/* two */}
              <div className={Style.imgSection}>
                <div
                  className={Style.imgcontentSection}
                  onClick={() => history.push("/resourceManagement")}
                >
                  <div className={Style.imgdiv}>
                    <img src={HrIcon} alt="this is img section" />
                  </div>
                  <h1>{totalOffshore}</h1>
                  <span>Resources (Offshore)</span>
                </div>
                <div
                  className={Style.imgcontentSection}
                  onClick={() => history.push("/ResourcesAllocationOnsite")}
                >
                  <div className={Style.imgdiv}>
                    <img src={HrIcon} alt="this is img section" />
                  </div>
                  <h1>{totalOnsite}</h1>
                  <span>Resources (Onsite)</span>
                </div>
              </div>
              {/* Three */}
              <div className={Style.imgSection3}>
                <div
                  className={Style.imgcontentSection}
                  onClick={() => history.push("/PoolResources")}
                >
                  <div className={Style.imgdiv}>
                    <img src={HrIcon} alt="this is img section" />
                  </div>
                  <h1>{poolCount}</h1>
                  <span>Pool Resources</span>
                </div>
                <div
                  className={Style.imgcontentSection3}
                  onClick={() => history.push("/resourceManagement")}
                >
                  <div className={Style.imgdiv}>
                    <img src={HrIcon} alt="this is img section" />
                  </div>
                  <h1>{count?.totalOffShoreResources}</h1>
                  <span>Pool Resources</span>
                </div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
