import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import style from "../AddTaskDetail/AddTaskDetail.module.css";
import Select from "react-select";
import { InvoiceStyles } from "../../utils/style";
import dropdownIcon from "../../../../assets/Icon feather-chevron-down.png";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import AddButton from "../../../../assets/Icon ionic-md-add-circle.png";
import Edit from "../../../../assets/Icon material-edit.png";
import Delete from "../../../../assets/delete-icon.png";
import InvoiceButton from "../../../NewEmployeeTimeSheet/CommonComponent/Button/InvoiceButton";
import BreadCrum from "../../../NewEmployeeTimeSheet/breadcrums/BreadCrum";
import {
  getTaskType,
  getTimeSpent,
  getAllActiveProject,
  createTimeSheet,
  getTimesheetId,
  EditTimeSheet,
  ViewTimeSheetByID,
  DeleteTimeSheet
} from "../../../../Utils/axios/NewTimeSheetApi";
import { useMutation, useQuery } from "react-query";

const FillTeamTimeSheet = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const date = new Date(prevData?.timesheetID);
  const dateTime = date.toISOString().slice(0, 19) + ".780";
 



  const [lineItemArray, setLineItemArray] = useState([1]);
  const [TaskDetail, setTaskDetail] = useState(false);
  const [AddTask, setAddTask] = useState(true);
  const [EditTask, SetEditTask] = useState(true);
  const [editSheetid, seteditSheetid] = useState(0);
  const [deleteId, setdeleteId] = useState();
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    timeSheetItemDetails: [
      {
        projectId: "",
        timeSpentId: "",
        taskTypeId: "",
        apiName: "",
        screenName: "",
        defectNumber: "",
        featureName: "",
        taskDate: dateTime,
        taskSummary: "",
        timesheetId: "",
        employeeId: Number(localStorage.getItem("employeeId")),
      },
    ],
  });

  const { data: ViewTimeById } = useQuery(["ViewTimeSheetByID"], () =>
    ViewTimeSheetByID(editSheetid)

    
  );

  console.log("ViewTimeById", editSheetid, ViewTimeById);
  useEffect(() => {
    seteditSheetid(editSheetid);
  }, [editSheetid]);

  const [formData1, setFormData1] = useState({
    projectId: ViewTimeById?.projectId,
    timeSpentId: ViewTimeById?.timeSpentId,
    taskTypeId: ViewTimeById?.taskTypeId,
    taskSummary: ViewTimeById?.taskSummary,
    apiName: ViewTimeById?.apiname,
    screenName: ViewTimeById?.screenName,
    featureName: ViewTimeById?.featureName,
    taskDate: prevData?.timesheetID,
    // taskStatusId: ViewTimeById?.timeSpentId,
    defectNumber: ViewTimeById?.defectNumber,
    timesheetId: editSheetid,
    employeeId: Number(localStorage.getItem("employeeId")),
  });

  useEffect(() => {
    setFormData1((prevValue) => ({
      ...prevValue,
      projectId: ViewTimeById?.projectId,
      timeSpentId: ViewTimeById?.timeSpentId,
      taskTypeId: ViewTimeById?.taskTypeId,
      taskSummary: ViewTimeById?.taskSummary,
      apiName: ViewTimeById?.apiname,
      screenName: ViewTimeById?.screenName,
      featureName: ViewTimeById?.featureName,
      taskDate: prevData?.timesheetID,
      // taskStatusId: ViewTimeById?.timeSpentId,
      defectNumber: ViewTimeById?.defectNumber,
      timesheetId: editSheetid,
      employeeId: Number(localStorage.getItem("employeeId")),
    }));
  }, [ViewTimeById]);
  const [validate, setValidate] = React.useState(false);

  const { data: projectData } = useQuery(["getTaskType"], getTaskType);
  const TaskOption = projectData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data: getTimeSpentData } = useQuery(["getTimeSpent"], getTimeSpent);
  const TimeSpentData = getTimeSpentData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const { data, isLoading, isError } = useQuery(["getTimesheetId"], () =>
    getTimesheetId(
      prevData?.timesheetID,
      Number(localStorage.getItem("employeeId"))
    )
  );


  const originalDate = new Date(prevData?.timesheetID);
  const day = originalDate.getDate();
  const month = originalDate.toLocaleString("default", { month: "short" });
  const year = originalDate.getFullYear().toString().slice(-2);
  const convertedDate = `${day} ${month} ${year}`;


  const { data: getAllProject } = useQuery(
    ["getAllActiveProject"],
    getAllActiveProject
  );
  const AllProject = getAllProject?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const mutation = useMutation(createTimeSheet, {
    // onSuccess: (res) => {
    //   console.log("res", res);
    //   setPrevData((prevData) => ({
    //     ...prevData,
    //     timeId: res?.timesheetId,
    //     taskTypeId: res?.taskTypeId,
    //   }));
    //   setNextScreen("ViewTask");
    // },
  });

  const mutation1 = useMutation(EditTimeSheet, {});

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const createTimeSheetHandler = () => {
    if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.taskSummary !== "" &&
          item?.apiName !== "" &&
          item?.screenName !== ""
      )
    ) {
      setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.taskSummary !== "" &&
          item?.screenName !== ""
      )
    ) {
      setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.taskSummary !== "" &&
          item?.apiName !== ""
      )
    ) {
      setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.taskSummary !== "" &&
          item?.defectNumber !== ""
      )
    ) {
      setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.taskSummary !== "" &&
          item?.featureName !== ""
      )
    ) {
      setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else if (
      formData?.timeSheetItemDetails.find(
        (item) =>
          item?.projectId !== "" &&
          item?.taskTypeId !== "" &&
          item?.timeSpentId !== "" &&
          item?.taskSummary !== ""
      )
    ) {
      setTaskDetail(true);
      mutation.mutate(formData);
      setValidate(false);
      setAddTask(false);
    } else {
      setValidate(true);
    }
  };

  // const createTimeSheetHandler = () => {
  //   setTaskDetail(true);
  //   mutation.mutate(formData);
  //   setValidate(false);
  //   setAddTask(false);
  // };

  const editHandler = (id) => {
    seteditSheetid(id);
    SetEditTask(false);
  };
  const updateHandler = () => {
    mutation1.mutate(formData1);
  };

  console.log("formddd", formData1?.projectId);

  const mutation2 = useMutation(["DeleteTimeSheet"], () =>
  DeleteTimeSheet(
    deleteId,
    Number(localStorage.getItem("employeeId"))
  ));
  const handleShow = (expenseId) => {
    setShow(true);
    setdeleteId(expenseId)
  };
  
  const handleDelete = () => {
    setShow(false);
    mutation2.mutate();
  }; 

  const handleDeleteCancle = () => {
    setShow(false);
  };
  return (
    <>
      {EditTask ? (
        <>
          <div>
            <BreadCrum
              prevData={prevData}
              setPrevData={setPrevData}
              setNextScreen={setNextScreen}
            ></BreadCrum>
          </div>
          <Container>
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <span className={style.timesheetdate}>Add Task Details</span>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row style={{ marginTop: "10px" }}>
              <Col>
                <p className={style.datetime}>{convertedDate}</p>
              </Col>
            </Row>
          </Container>
          <InvoiceContainer>
            <div className={style.copy_expence}>
              Add Task Details
              {AddTask ? (
                ""
              ) : (
                <span className={style.btnAddItem}>
                  <InvoiceButton
                    onClick={() => {
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      setFormData({
                        ...formData,
                        timeSheetItemDetails: [
                          ...formData?.timeSheetItemDetails,
                          {
                            projectId: "",
                            timeSpentId: "",
                            taskTypeId: "",
                            apiName: "",
                            screenName: "",
                            defectNumber: "",
                            featureName: "",
                            taskDate: dateTime,
                            taskSummary: "",
                            timesheetId: "",
                            employeeId: Number(
                              localStorage.getItem("employeeId")
                            ),
                          },
                        ],
                      });
                    }}
                  >
                    Add More
                  </InvoiceButton>
                </span>
              )}
            </div>
            {/*row 1*/}
            {formData?.timeSheetItemDetails?.map((item, index) => {
              return (
                <>
                  <div key={index}>
                    <Row style={{ marginLeft: "3px" }}>
                      <Col md={3}>
                        <p className={style.label_item}>
                          Project Name<span style={{ color: "red" }}>*</span>
                        </p>
                        <Select
                          styles={InvoiceStyles}
                          classNamePrefix={"create_position"}
                          components={{
                            DropdownIndicator: () => CustomInput(),
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(e) => {
                            let temp = [...formData?.timeSheetItemDetails];
                            temp[index].projectId = e?.value;
                            setFormData({
                              ...formData,
                              timeSheetItemDetails: temp,
                            });
                          }}
                          value={AllProject?.find(
                            (e) =>
                              e.label ===
                              formData?.timeSheetItemDetails[index].projectId
                          )}
                          options={AllProject}
                        />
                        <p
                          className={
                            validate && !item.projectId
                              ? style.errorText
                              : style.errorTextHidden
                          }
                        >
                          Please Enter Project Name
                        </p>
                      </Col>
                      <Col md={3}>
                        <p className={style.label_item}>
                          Task Type<span style={{ color: "red" }}>*</span>
                        </p>
                        <Select
                          styles={InvoiceStyles}
                          classNamePre
                          fix={"create_position"}
                          components={{
                            DropdownIndicator: () => CustomInput(),
                            IndicatorSeparator: () => null,
                          }}
                          onChange={(e) => {
                            let temp = [...formData?.timeSheetItemDetails];
                            temp[index].taskTypeId = e?.value;
                            setFormData({
                              ...formData,
                              timeSheetItemDetails: temp,
                            });
                          }}
                          value={TaskOption?.find(
                            (e) =>
                              e.value ===
                              formData?.timeSheetItemDetails[index].taskTypeId
                          )}
                          options={TaskOption}
                        />

                        <p
                          className={
                            validate && !item.taskTypeId
                              ? style.errorText
                              : style.errorTextHidden
                          }
                        >
                          Please Enter Task Type
                        </p>
                      </Col>
                      {formData?.timeSheetItemDetails[index].taskTypeId === 1 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 2 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 3 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 4 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 5 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 6 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 7 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 8 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 9 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 10 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 11 ||
                      formData?.timeSheetItemDetails[index].taskTypeId === 12 ||
                      formData?.timeSheetItemDetails[index].taskTypeId ===
                        13 ? (
                        ""
                      ) : (
                        <>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[index]
                                    .timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3} style={{ marginLeft: "15px" }}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      )}

                      {formData?.timeSheetItemDetails[index].taskTypeId ===
                      1 ? (
                        <>
                          <Col md={3}>
                            <p className={style.label_item}>
                              API Name<span style={{ color: "red" }}>*</span>
                            </p>
                            <input
                              type="text"
                              className={style.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].apiName = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]?.apiName
                              }
                            />
                            <p
                              className={
                                validate && !item.apiName
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter API Name
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[index]
                                    .timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {formData?.timeSheetItemDetails[index].taskTypeId === 2 ||
                      formData?.timeSheetItemDetails[index]?.taskTypeId ===
                        9 ? (
                        <>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Screen Name<span style={{ color: "red" }}>*</span>
                            </p>
                            <input
                              type="text"
                              className={style.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].screenName = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.screenName
                              }
                            />
                            <p
                              className={
                                validate && !item?.screenName
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Screen Name
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[index]
                                    .timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                      {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                      3 ? (
                        <>
                          <Col md={3} style={{ width: "23%" }}>
                            <p className={style.label}>
                              Screen Name<span style={{ color: "red" }}>*</span>
                            </p>
                            <input
                              type="text"
                              className={style.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].screenName = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.screenName
                              }
                            />
                            <p
                              className={
                                validate && !item?.screenName
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Screen Name
                            </p>
                          </Col>
                          <Col md={3} style={{ width: "23%" }}>
                            <p className={style.label}>
                              API Name<span style={{ color: "red" }}>*</span>
                            </p>
                            <input
                              type="text"
                              className={style.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].apiName = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]?.apiName
                              }
                            />
                            <p
                              className={
                                validate && !item?.apiName
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter API Name
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[index]
                                    .timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                        4 ||
                      formData?.timeSheetItemDetails[index]?.taskTypeId ===
                        10 ? (
                        <>
                          <Col md={3} style={{ width: "23%" }}>
                            <p className={style.label}>
                              API Name<span style={{ color: "red" }}>*</span>
                            </p>
                            <input
                              type="text"
                              className={style.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].apiName = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]?.apiName
                              }
                            />

                            <p
                              className={
                                validate && !item?.apiName
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter API Name
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[index]
                                    .timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                      5 ? (
                        <>
                          <Col md={3} style={{ width: "23%" }}>
                            <p className={style.label}>
                              Defect Number
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <input
                              type="text"
                              className={style.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].defectNumber = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.defectNumber
                              }
                            />
                            <p
                              className={
                                validate && !item?.defectNumber
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Defect Number
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[index]
                                    .timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                      6 ? (
                        <>
                          <Col md={3} style={{ width: "23%" }}>
                            <p className={style.label}>
                              Feature Name
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <input
                              type="text"
                              className={style.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].featureName = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.featureName
                              }
                            />
                            <p
                              className={
                                validate && !item?.featureName
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Feature Name
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[index]
                                    .timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {formData?.timeSheetItemDetails[index]?.taskTypeId ===
                        7 ||
                      formData?.timeSheetItemDetails[index]?.taskTypeId === 8 ||
                      formData?.timeSheetItemDetails[index]?.taskTypeId ===
                        11 ? (
                        <>
                          <Col md={3} style={{ width: "23%" }}>
                            <p className={style.label}>
                              Feature Name
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <input
                              type="text"
                              className={style.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].featureName = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.featureName
                              }
                            />
                            <p
                              className={
                                validate && !item?.featureName
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Feature Name
                            </p>
                          </Col>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[0].timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      {formData?.timeSheetItemDetails[index].taskTypeId ===
                        12 ||
                      formData?.timeSheetItemDetails[index].taskTypeId ===
                        13 ? (
                        <>
                          <Col md={2}>
                            <p className={style.label_item}>
                              Time Spent ( In Hrs/Min )
                              <span style={{ color: "red" }}>*</span>
                            </p>

                            <Select
                              styles={InvoiceStyles}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].timeSpentId = e?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={TimeSpentData?.find(
                                (e) =>
                                  e.value ===
                                  formData?.timeSheetItemDetails[0].timeSpentId
                              )}
                              options={TimeSpentData}
                            />
                            <p
                              className={
                                validate && !item?.timeSpentId
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Select Time Spent ( In Hrs/Min )
                            </p>
                          </Col>
                          <Col md={3}>
                            <p className={style.label_item}>
                              Task Summary
                              <span style={{ color: "red" }}>*</span>
                            </p>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              onChange={(e) => {
                                let temp = [...formData?.timeSheetItemDetails];
                                temp[index].taskSummary = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  timeSheetItemDetails: temp,
                                });
                              }}
                              value={
                                formData?.timeSheetItemDetails[index]
                                  ?.taskSummary
                              }
                            />
                            <p
                              className={
                                validate && !item.taskSummary
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                  </div>
                </>
              );
            })}
            {AddTask ? (
              <div className={style.lastBtn_item}>
                <img
                  src={AddButton}
                  alt=""
                  style={{
                    width: "3%",
                    height: "30px",
                    paddingTop: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setLineItemArray([
                      ...lineItemArray,
                      Math.floor(Math.random() * 100),
                    ]);
                    setFormData({
                      ...formData,
                      timeSheetItemDetails: [
                        ...formData?.timeSheetItemDetails,
                        {
                          projectId: "",
                          timeSpentId: "",
                          taskTypeId: "",
                          apiName: "",
                          screenName: "",
                          defectNumber: "",
                          featureName: "",
                          taskDate: dateTime,
                          taskSummary: "",
                          timesheetId: "",
                          employeeId: Number(
                            localStorage.getItem("employeeId")
                          ),
                        },
                      ],
                    });
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </InvoiceContainer>
          <button
            className={style.buttonAdd}
            // onClick={() => {
            //   setNextScreen("ViewTask");
            //   setPrevData((prevData) => ({
            //     ...prevData,
            //     page2: "ViewTask",
            //     page3: null,
            //     page4: null,
            //     active: "ViewTask",
            //   }));
            // }}
            onClick={createTimeSheetHandler}
          >
            Submit
          </button>
          <button
            className={style.buttonCancle}
            onClick={() => {
              setNextScreen("empTimeSheet");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "empTimeSheet",
                page3: null,
                page4: null,
                active: "empTimeSheet",
              }));
            }}
          >
            Cancel
          </button>
        </>
      ) : (
        <>
          <Container>
            <Row style={{ marginTop: "20px" }}>
              <Col>
                <span className={style.timesheetdate}>Edit Task Details</span>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row style={{ marginTop: "10px" }}>
              <Col>
                <p className={style.datetime}>10 Apr 23</p>
              </Col>
            </Row>
          </Container>
          <InvoiceContainer>
            <Row>
              <Col md={3} style={{ marginLeft: "12px" }}>
                <p className={style.label_item}>
                  Project Name<span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={AllProject?.find(
                    (e) => e.value === formData1?.projectId
                  )}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      projectId: e.value,
                    });
                  }}
                  options={AllProject}
                />
                {/* <p
                    className={
                      validate && !formData1?.projectId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Project Name
                  </p> */}
              </Col>
              <Col md={3} style={{ marginLeft: "15px" }}>
                <p className={style.label_item}>
                  Task Type<span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  styles={InvoiceStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={TaskOption?.find(
                    (e) => e.value === formData1?.taskTypeId
                  )}
                  onChange={(e) => {
                    setFormData1({
                      ...formData1,
                      taskTypeId: e.value,
                    });
                  }}
                  options={TaskOption}
                />
                {/* <p
                    className={
                      validate && !formData?.taskTypeId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Project Name
                  </p> */}
              </Col>
              {formData1?.taskTypeId === 1 ||
              formData1?.taskTypeId === 2 ||
              formData1?.taskTypeId === 3 ||
              formData1?.taskTypeId === 4 ||
              formData1?.taskTypeId === 5 ||
              formData1?.taskTypeId === 6 ||
              formData1?.taskTypeId === 7 ||
              formData1?.taskTypeId === 8 ||
              formData1?.taskTypeId === 9 ||
              formData1?.taskTypeId === 10 ||
              formData1?.taskTypeId === 11 ||
              formData1?.taskTypeId === 12 ||
              formData1?.taskTypeId === 13 ? (
                ""
              ) : (
                <>
                  <Col md={2}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min )
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              )}

              {formData1?.taskTypeId === 1 ? (
                <>
                  <Col md={3}>
                    <p className={style.label}>
                      API Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      value={formData1?.apiName}
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          apiName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.apiName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter API Name
                    </p>
                  </Col>

                  <Col md={2}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min ){" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 2 || formData1?.taskTypeId === 9 ? (
                <>
                  <Col md={3}>
                    <p className={style.label_item}>
                      Screen Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      className={style.smallInput}
                      value={formData1?.screenName}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          screenName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && formData1?.screenName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Screen Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min ){" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 3 ? (
                <>
                  <Col md={2} style={{ width: "23%" }}>
                    <p className={style.label_item}>
                      Screen Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      className={style.smallInput}
                      value={formData1?.screenName}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          screenName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && formData1?.screenName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Screen Name
                    </p>
                  </Col>
                  <Col md={2} style={{ width: "23%" }}>
                    <p className={style.label}>
                      API Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.apiName}
                      type="text"
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          apiName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.apiName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter API Name
                    </p>
                  </Col>
                  <Col md={2} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min ){" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 4 || formData1?.taskTypeId === 10 ? (
                <>
                  <Col md={3} style={{ width: "23%" }}>
                    <p className={style.label}>
                      API Name<span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      type="text"
                      value={formData1?.apiName}
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          apiName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.apiName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter API Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min ){" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              ) : (
                ""
              )}
              {formData1?.taskTypeId === 5 ? (
                <>
                  <Col md={3} style={{ width: "23%" }}>
                    <p className={style.label}>
                      Defect Number
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.defectNumber}
                      type="text"
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          defectNumber: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.defectNumber
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Defect Number
                    </p>
                  </Col>
                  <Col md={2}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min ){" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 6 ? (
                <>
                  <Col md={3} style={{ width: "23%" }}>
                    <p className={style.label}>
                      Feature Name
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.apiName}
                      type="text"
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          apiName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.apiName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Feature Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min ){" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              ) : (
                ""
              )}
              {formData1?.taskTypeId === 7 ||
              formData1?.taskTypeId === 8 ||
              formData1?.taskTypeId === 11 ? (
                <>
                  <Col md={3} style={{ width: "23%" }}>
                    <p className={style.label}>
                      Feature Name
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <input
                      value={formData1?.featureName}
                      type="text"
                      className={style.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          featureName: e.target.value,
                        });
                      }}
                    />
                    <p
                      className={
                        validate && !formData1?.featureName
                          ? style.errorText
                          : style.errorTextHidden
                      }
                    >
                      Please Enter Feature Name
                    </p>
                  </Col>
                  <Col md={2}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min ){" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              ) : (
                ""
              )}

              {formData1?.taskTypeId === 12 || formData1?.taskTypeId === 13 ? (
                <>
                  <Col md={2}>
                    <p className={style.label_item}>
                      Time Spent ( In Hrs/Min ){" "}
                      <span style={{ color: "red" }}>*</span>
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={TimeSpentData?.find(
                        (e) => e.value === formData1?.timeSpentId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          timeSpentId: e.value,
                        });
                      }}
                      options={TimeSpentData}
                    />
                    {/* <p
                    className={
                      validate && !formData1?.timeSpentId
                        ? style.errorText
                        : style.errorTextHidden
                    }
                  >
                    Please Select Time Spent ( In Hrs/Min )
                  </p> */}
                  </Col>
                  <Col md={3} style={{ marginLeft: "15px" }}>
                    <p className={style.label_item}>
                      Task Summary
                      <span style={{ color: "red" }}>*</span>
                    </p>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={formData1?.taskSummary}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          taskSummary: e.target.value,
                        });
                      }}
                    />
                    {/* <p
                              className={
                                validate && !item.invoiceYear
                                  ? style.errorText
                                  : style.errorTextHidden
                              }
                            >
                              Please Enter Task Summary
                            </p> */}
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <button
              className={style.EditAdd2}
              onClick={() => {
                setNextScreen("empTimeSheet");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "empTimeSheet",
                  page3: null,
                  page4: null,
                  active: "empTimeSheet",
                }));
                updateHandler();
              }}
            >
              Update
            </button>
          </InvoiceContainer>
        </>
      )}

      {TaskDetail ? (
        <>
          <div className={style.ViewsheetcolorPos}>
            <p className={style.ViewEmployeeDetails}>
              {" "}
              <span style={{ marginRight: "21rem" }}>Employee Details</span>
            </p>
            <Container fluid style={{ paddingBottom: "20px" }}>
              <div className={style.td}>
                <p style={{ fontWeight: "700" }}>Project Name</p>
                <p style={{ fontWeight: "700" }}>Task Type</p>
                <p style={{ fontWeight: "700" }}>Time Spent(In Hr/Min)</p>
                <p style={{ fontWeight: "700" }}>Task Summary</p>
                <p style={{ fontWeight: "700" }}>API Name</p>
                <p style={{ fontWeight: "700" }}>Screen Name</p>
                <p style={{ fontWeight: "700" }}>Defect Number</p>
                <p style={{ fontWeight: "700" }}>Feature Name</p>
                <p style={{ fontWeight: "700" }}>PL Approved</p>
                <p style={{ fontWeight: "700" }}>Remark</p>
              </div>
              <div>
                {!isLoading && !isError ? (
                  data?.length > 0 ? (
                    typeof data !== "string" &&
                    data?.map((item, index) => {
                      return (
                        <div className={style.td}>
                          <p
                            className={style.tableContent1}
                            style={{ transform: "translate(2px,0px)" }}
                          >
                            {item?.projectName}
                          </p>
                          <p className={style.tableContent}>{item?.taskType}</p>
                          <p
                            className={style.tableContent}
                            style={{ transform: "translate(70px,0px)" }}
                          >
                            {item?.timeSpent}
                          </p>
                          <p
                            className={style.tableContent}
                            style={{ transform: "translate(46px,0px)" }}
                          >
                            {item?.taskSummary}
                          </p>
                          <p
                            className={style.tableContent}
                            style={{ transform: "translate(43px,0px)" }}
                          >
                            {item?.apiname}
                          </p>
                          <p
                            className={style.tableContent}
                            style={{ transform: "translate(71px,0px)" }}
                          >
                            {item?.screenName}
                          </p>
                          <p className={style.tableContent}>
                            {item?.defectNumber}
                          </p>
                          <p className={style.tableContent}>
                            {item?.featureName}
                          </p>
                          <p className={style.tableContent}>
                            {item?.plApproved}
                          </p>
                          <p className={style.tableContent}>{item?.remarks}</p>
                          <p className={style.tableContent}>
                            <img
                              src={Delete}
                              alt=""
                              style={{ marginLeft: "10px",cursor:'pointer' }}
                              onClick={() => handleShow(item?.timesheetId)}
                            />
                          </p>
                          <p className={style.tableContent}>
                            {" "}
                            <img
                              src={Edit}
                              alt=""
                              onClick={() => editHandler(item?.timesheetId)}
                              style={{ cursor: "pointer" }}
                            />
                          </p>
                     
                        </div>
                      );
                    })
                  ) : (
                    <p className={style.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </Container>
          </div>
          <button
            className={style.buttonAdd2}
            onClick={() => {
              setNextScreen("empTimeSheet");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "empTimeSheet",
                page3: null,
                page4: null,
                active: "empTimeSheet",
              }));
            }}
          >
            Done
          </button>
        </>
      ) : (
        ""
      )}

<Modal show={show} centered   size="sm">
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to delete 
        </h6>
        <button className={style.buttonAdd4} onClick={handleDelete}>
          Yes
        </button>
        <button className={style.buttonCancle4} onClick={handleDeleteCancle}>
          No
        </button>
      </Modal>
    </>
  );
};
export default FillTeamTimeSheet;
