import React from "react";
import loader from "../../assets/loadermain.gif";
import style from "./loader.module.css";
const MainLoader = () => {
  return (
    <div className={style.overlay}>
      <div className={style.content}>
        <img className={style.img} src={loader} alt="loader/" />
      </div>
    </div>
  );
};

export default MainLoader;
