import React, { useState } from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import { Modal } from "react-responsive-modal";
import moment from "moment";
import "react-responsive-modal/styles.css";
import style from "./EmployeeFeedbackList.module.css";
import Tooltip from "react-simple-tooltip";
import { useQuery } from "react-query";
import {
  getEmployeePerformanceByUserId,
  getEmployeeDetailsByEmployeeId,
} from "../../utils/api";

function EmployeeFeedbackList({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) {
  const [show2, setShow2] = useState(false);

  const { data, isError, isLoading } = useQuery(
    ["getEmployeePerformanceByUserId"],
    () => getEmployeePerformanceByUserId(prevData?.empId),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );
  const { data: profileData } = useQuery(
    ["  getEmployeeDetailsByEmployeeId"],
    () => getEmployeeDetailsByEmployeeId(prevData?.empId),
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      cacheTime: 0,
      refetchInterval: 0,
    }
  );

  return (
    <>
      <BreadCrum
        prevData={prevData}
        setPrevData={setPrevData}
        setNextScreen={setNextScreen}
        PlusComponent={
          prevData.page === "CurrentAllocation" ? null : (
            <Tooltip
              customCss={`
                          white-space: nowrap;
                          // position: absolute;
                        `}
              radius={5}
              padding={5}
              background="#001426"
              content="Add New"
              className={style.tooltip}
            >
              <div
                className={style.add}
                onClick={() => {
                  setNextScreen("EmployeeFeedback");
                  setPrevData((prevData) => ({
                    ...prevData,
                    empId: prevData?.empId,
                    sidebar: "EmployeePerformanceReview",
                    page: "EmployeeFeedback",
                    page1: "EmployeePerformanceReview",
                    page2: "EmployeeFeedbackList",
                    page3: "EmployeeFeedback",
                    page4: null,
                    active: "EmployeeFeedback",
                  }));
                }}
              >
                <p className={style.plus}>+</p>
              </div>
            </Tooltip>
          )
        }
      ></BreadCrum>
      {/* first */}
      <div className={style.card}>
        <p className={style.title}>Employee Details</p>
        <div className={style.Cu_row1}>
          <div className={style.cu_col1}>
            <p className={style.cu_label}>Employee Name</p>
            <p className={style.value}>
              <p className={style.value}>
                {` ${profileData?.name} (${profileData?.empCode})`}
              </p>
            </p>
          </div>
          {/* <div className={style.cu_col1}>
            <p className={style.cu_label}>Employee Name</p>
            <p className={style.value}>{profileData?.name}</p>
          </div> */}
        </div>
      </div>
      {/* second */}
      <div className={style.card}>
        <p className={style.title}>Feedback</p>
        <div className={style.PositionList}>
          <div className={style.table}>
            <div className={style.th}>
              {/* <p className={style.tableContent}>Employee Id</p> */}
              {/* <p className={style.tableContent}>Employee Name</p> */}
              <p className={style.tableContent}>
                Review<br></br> Date
              </p>
              <p className={style.tableContent}>
                Review<br></br> Month
              </p>
              <p className={style.tableContent}>
                Review<br></br> Type
              </p>
              <p className={style.tableContent}>
                Review<br></br> Source Type
              </p>
              <p className={style.tableContent}>
                Online<br></br> Platform
              </p>
              <p className={style.tableContent}>
                Client<br></br> Name
              </p>
              <p className={style.tableContent}>Status</p>
              <p className={style.tableContentStatus}>Reviewed By</p>
            </div>
            <div>
              {isLoading && <p className={style.no_data}>Loading .....</p>}
              {!isLoading && !isError ? (
                data?.length > 0 ? (
                  typeof data !== "string" &&
                  data?.map((item) => {
                    return (
                      <div className={style.td} key={item?.positionId}>
                        {/* <p
                          className={`${style.tableContent1} ${style.link}`}
                        >
                          {item.empCode}
                        </p> */}
                        {/* <p
                          className={`${style.tableContent} ${style.link}`}
                          onClick={() => {
                            setNextScreen("viewFeedback");
                            setPrevData((prevData) => ({
                              ...prevData,
                              empId: item?.empId,
                              pReId: item?.performanceReviewId,
                              sidebar: "EmployeePerformanceReview",
                              page: "viewFeedback",
                              page1: "EmployeePerformanceReview",
                              page2: "EmployeeFeedbackList",
                              page3: "viewFeedback",
                              page4: null,
                              active: "viewFeedback",
                            }));
                          }}
                        >
                          {item.empName}
                        </p> */}
                        <p
                          className={`${style.tableContent} ${style.link}`}
                          onClick={() => {
                            setNextScreen("viewFeedback");
                            setPrevData((prevData) => ({
                              ...prevData,
                              empId: item?.empId,
                              pReId: item?.performanceReviewId,
                              sidebar: "EmployeePerformanceReview",
                              page: "viewFeedback",
                              page1: "EmployeePerformanceReview",
                              page2: "EmployeeFeedbackList",
                              page3: "viewFeedback",
                              page4: null,
                              active: "viewFeedback",
                            }));
                          }}
                        >
                          {moment(item.reviewDate).format("DD MMM YY")}
                        </p>
                        <p className={style.tableContent}>
                          {moment(item.reviewDate).format("MMM")}
                        </p>
                        <p className={style.tableContent}>{item?.reviewType}</p>
                        <p className={style.tableContent}>
                          {item?.reviewSourceType}
                        </p>
                        <p className={style.tableContent}>
                          {item?.onlinePlatform}
                        </p>
                        <p className={style.tableContent}>{item?.clientName}</p>
                        <p className={style.tableContent}>
                          {item?.interviewResult}
                        </p>
                        <p className={style.tableContent}>
                          {item?.interviewerName}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p className={style.no_data}>No Data Found</p>
                )
              ) : null}
            </div>
          </div>
          <></>
        </div>
      </div>
      <Modal
        open={show2}
        center
        onClose={() => setShow2(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.modal_container}>
          <div>
            <p className={style.textM}>Data not found!</p>
          </div>
          <div className={style.M_button}>
            <div className={style.button}>
              <button
                className={style.b_inactive}
                onClick={() => {
                  setNextScreen("EmployeePerformanceReview");
                  setPrevData((prevData) => ({
                    ...prevData,
                    sidebar: "EmployeePerformanceReview",
                    page: "EmployeePerformanceReview",
                    page1: "EmployeePerformanceReview",
                    page2: null,
                    page3: null,
                    active: "EmployeePerformanceReview",
                  }));
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EmployeeFeedbackList;
