//AllIssueComp
import React, { useState, useEffect } from "react";
import style from "./AllIsuueComponent.module.css";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import Select from "react-select";
import searchIcon from "../../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearch } from "../../../commonComponent/style";
import Pagination from "../../../commonComponent/Pagination";
import sortDownarrowImg from "../../../../../assets/Down.png";
import sortUparrowImg from "../../../../../assets/Up.png";
import cookie from "react-cookies";

export const AllIssueComp = (props) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [raisedBy, setRaisedBy] = useState();
  const { categaryId, statusId,monthId } = location.state;
  const [allIssue, setAllIssue] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [totalIssue, settotalIssue] = useState(0);
  const[isLoading,setisLoading]=useState(true)
  const [sortOrder, setSortOrder] = useState(null);
  const [allData, setAllData] = useState({
    categoryId: [categaryId],
    raisedBy: [],
    email: localStorage.getItem("email"),
    statusId: [statusId],
    subCategoryId: [],
    monthId:monthId,
    delayByOrder:sortOrder,

  });



  const [label, setLabel] = useState({ raiseBy: "" });
  // onSearchHandler
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code1":
          setAllData({ ...allData, raisedBy: [e?.value] });
          setLabel({ ...label, raiseBy: e?.label });
          break;
        default:
          return null;
      }
    }
  };
  // pagination Handler
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  // goBack handler
  const goBackHandler = () => {
    history.push("/SummaryDetails");
  };
  const limit = 10;
  // url
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;

  const viewIssueHandler = (id) => {
    history.push(`/HrHelpDeskViewIssue?id=${id}`);
  };

  // raised by api integration
  const getRaisedBy = () => {
    axios
      .get(url +`/getRaisedByEmployeeByCategoryId?categoryId=${categaryId}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res);
        setRaisedBy(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // All Issue API Integratio
  const getAllIssue = () => {
    axios
      .post(
        url +
          "/getListOfAllIssuesBasedOnCategory?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        allData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.responseData.issues);
        setAllIssue(res.data.responseData);
        settotalIssue(res.data.responseData.totalIssue);
        setpageCount(res.data.responseData.totalIssue / 10);
        setisLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getRaisedBy();
  }, []);
  useEffect(() => {
    getAllIssue();
  }, [currentPage, allData]);

  const getAllRaisedOption = raisedBy?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const handleSorting = () => {
    setSortOrder(sortOrder ===null? "ASC" :sortOrder === "ASC"?"DESC":"ASC");
  };
useEffect(()=>{
  setAllData({...allData,delayByOrder:sortOrder})
},[sortOrder])

  return (
    <>
      <div className={style.main}>
        <div className={style.twoFilter}>
          <div className={style.filteralign}>
            <p className={style.paragraph}>Category-Wise Issue List</p>
            <div className={style.s1}>
              {allData?.raisedBy?.length === 0 ? (
                <span className={style.cp_serchIcon2}>
                  <img src={searchIcon} alt="img" />
                </span>
              ) : (
                <span
                  className={style.cp_serchIcon2}
                  onClick={() => {
                    setAllData({
                      ...allData,
                      raisedBy: [],
                    });
                    setLabel({ ...label, raiseBy: "" });
                  }}
                >
                  {allData?.raisedBy.length !== 0 ? <AiOutlineClose /> : null}
                </span>
              )}
              <Select
                styles={customSearch}
                classNamePrefix={"create_position"}
                placeholder="Raised By"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                value={
                  label?.raiseBy
                    ? {
                        value: allData?.raisedBy,
                        label: label?.raiseBy,
                      }
                    : null
                }
                onChange={(e) => {
                  onSearchHandler(e, "Code1");
                  setCurrentPage(1);
                }}
                options={getAllRaisedOption}
              />
            </div>
          </div>
        </div>
        <div className={style.IssueList}>
        {isLoading ? (
  <p className={style.loading}>Loading...</p>
) : (
  <table style={{ width: "100%" }}>
    <thead>
      <tr className={style.th}>
        <th className={style.tableContent}>Issue ID</th>
        <th className={style.tableContent}>Raised By</th>
        <th className={style.tableContent}>Logged Date</th>
        <th className={style.tableContent}>Category</th>
        <th className={style.tableContent}>Sub-Category</th>
        <th className={style.tableContent}>Status</th>
        {statusId===3? (
  <>
        <th className={style.tableContent}>Closed Date</th>
          </>
) : null}
<th className={style.tableContent} style={{textAlign:"center"}}>Delay By <img  alt="" style={{ cursor: "pointer", position: "relative", bottom: '0px', left: "5px" }} src={sortOrder==="ASC"? sortUparrowImg : sortDownarrowImg} onClick={handleSorting}/></th>
      </tr>
    </thead>
    
    {allIssue?.issues?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan="8" className={style.noDataFound}>
                      No Data Available
                      </td>
                    </tr>
                  </tbody>
                ) : (
    <tbody style={{ height: "20px" }}>
      {allIssue?.issues?.map((issue) => (
        <tr key={issue.issueId} className={style.td}>
          <td
            className={`${style.link} ${style.tableContent}`}
            onClick={() => viewIssueHandler(issue?.issueId)}
          >{`HRHD${issue?.issueId}`}</td>
          <td className={style.tableContent}>{issue.raiseBy}</td>
          <td className={style.tableContent}>
            {Moment(issue.loggedDate).format("DD MMM YY")}
          </td>
          <td className={style.tableContent}>{issue.category}</td>
          <td className={style.tableContent}>{issue.subCategory}</td>
          {/* <td className={style.tableContent}>{issue.description}</td> */}
          <td className={style.tableContent}>{issue.status}</td>
          {statusId===3? (
  <>
    <td className={style.tableContent}> {Moment(issue.closedDate).format("DD MMM YY")}</td>
  </>
) : null}
<td className={style.tableContent} style={{textAlign:"center"}}>{issue.delayBy}</td>
        </tr>
      ))}
      {allIssue?.issues?.length === 0 && (

<tr>
  <td colSpan="6">
    No data found.
  </td>
</tr>

)}
    </tbody>
    )}
  </table>
  )}
        </div>
        <div className={style.paginationalign}>
          <button className={style.goBack} onClick={() => goBackHandler()}>
            Back
          </button>
          {totalIssue > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
