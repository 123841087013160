import React, {useState} from "react";
import BreadCrum from "../../breadcrums/BreadCrum";
import style from "./EmployeeList.module.css";
import FilterButton from "../Filterbutton/FilterButton";
import Filter from "../../filter/Filter";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import { useQuery } from "react-query";
import { employeeList } from "../../../../Utils/axios/InductionApi";
import moment from "moment";
import Pagination from "../../../Employee_Induction/CommonComponent/Pagination";
import ReviewFileDownload from "../ReviewFileDownload/ReviewFileDownload";

const EmployeeList = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [Data, setData] = useState("");
  const [filterData, setFilterData] = useState({
    empId: "",
    EmpValue: "",
    inductionStatus: "",
    inductionStatusValue:"",
  });
  const filterUpdateFuc = () => {
    setCurrentPage(1)
    setData(filterData);
    setShowFilter(false);
  };
  const filterClearAllFuc = () => {
    setData("");
    setShowFilter(false);
    setFilterData((preData) => ({
      ...preData,
      empId: "",
      EmpValue: "",
      inductionStatus: "",
      inductionStatusValue: "",
    }));
    return;
  };

  const { data, isLoading, isError } = useQuery(
    ["employeeList", { currentPage, Data }],
    () => employeeList(10, currentPage, Data.empId, Data.inductionStatusValue)
  );

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  return (
    <>
      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
          
          downloadComponent={
            <div style={{ width: "8%"}} aria-id="PM0001">
          <p className={style.download}>
            <span
              className={style.backColor1}
              style={{
                fontSize: "2rem",
                fontWeight: "500",
                cursor: "pointer",
                color: "#a15e5e",
              }}
            >
              <ReviewFileDownload
                limit="10"
                page={currentPage}
              
              />
            </span>
          </p>
        </div>
          }
          filterComponent={
            <FilterButton
              setShowFilter={setShowFilter}
              filterData={filterData}
            />
          }
        
        ></BreadCrum>

        <InvoiceContainer>
          <div className={style.table}>
            <div className={style.th}>
              <p className={style.tableContent}>Emp Code</p>
              <p className={style.tableContent}>Employee Name</p>
              <p className={style.tableContent}>Date of Joining</p>
              <p className={style.tableContent}>Designation</p>
              <p className={style.tableContent}>Department</p>
              <p className={style.tableContent}>Induction Status</p>
              <p className={style.tableContent}>Folder Link</p>
            </div>
            <div>
              {!isLoading && !isError ? (
                data?.empDetails?.length > 0 ? (
                  typeof data?.empDetails !== "string" &&
                  data?.empDetails?.map((item, index) => {
                    return (
                      <div className={style.td}>
                        <p
                          className={`${style.tableContent1} ${style.link}`}
                          onClick={() => {
                            setNextScreen("EmployeeInductionView");
                            setPrevData((prevData) => ({
                              ...prevData,
                              page2: "EmployeeInductionView",
                              page3: null,
                              page4: null,
                              active: "EmployeeInductionView",
                              idData: item.id,
                            }));
                          }}
                        >
                          {item?.empCode}{" "}
                        </p>
                        <p className={style.tableContent}>{item?.empName}</p>
                        <p className={style.tableContent}>
                          {moment(item?.dateOfJoining).format("DD MMM YY")}
                        </p>
                        <p className={style.tableContent}>
                          {item?.designation}
                        </p>
                        <p className={style.tableContent}>{item?.department}</p>
                        <p className={style.tableContent}>
                         
                          {item?.overallStatus===null?"__":item?.overallStatus===true?"Done":"In-Progress"}
                        </p>
                        <p className={style.tableContent3}>
                          <a
                            href={item?.folderLink}
                            target="_blank"
                            title="Click Here"
                          >
                            {item?.folderLink === null ? "" : "Link"}
                          </a>
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <p className={style.no_data}>No Data Found</p>
                )
              ) : null}
            </div>
          </div>
          <>
            <Filter
              filterData={filterData}
              setFilterData={setFilterData}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              firstCard="Department Filter"
              secondCard="Status"
              filterUpdateFuc={filterUpdateFuc}
              filterClearAllFuc={filterClearAllFuc}
            />
          </>
        </InvoiceContainer>
        <div className={style.pagination}>
          <Pagination
            onPageChange={handlePageChange}
            currentPage={currentPage}
            pagesCount={data?.totalEmployee / 10}
            color="##855ea1"
            color2="white"
          />
        </div>
      </div>
    </>
  );
};

export default EmployeeList;
