import React, {useState } from "react";
import style from "./SideBar.module.css";
import buttonimg from "../../../assets/collaboration.png";
import Iconopentask from '../../../assets/Iconopentask.png';
import Iconopentask_2 from '../../../assets/Iconopentask_2.png';
import Iconawesometasks_2 from '../../../assets/Iconawesometasks_2.png';
import Iconmetrocross_2 from '../../../assets/Iconmetrocross_2.png';
import Iconawesometasks from '../../../assets/Iconawesometasks.png';
import Iconmetrocross from '../../../assets/Iconmetrocross.png';
import IconClosed_2  from  '../../../assets/closed1.png';
import IconClosed from  '../../../assets/closed2.png';
import {useHistory, useLocation } from "react-router-dom";
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";


const SideBar = () => {
  const location = useLocation();
  const history = useHistory();
  const [tabActive, setTabActive] = useState(" ");
  

  return (

    <div className={style.leftSection}>
     <div className={style.header}>
        <img
          alt="DashBoardImage"
          src={InnovationMLogo}
          className={style.dashboardImage}
          onClick={() => history.push("/dashboard")}
        />
      </div>
      <button className={style.taskTracker}
        style={{ cursor: 'auto', border: 'none' }}

      >
        <div>
          <img src={buttonimg} alt="img" />
        </div>

        <span >Task Tracker</span>
      </button>

      <button
        onClick={() => {
          setTabActive("myTasks")
          history.push("/taskTracker")
        
        }
        }
        className={location.pathname === "/taskTracker" ? style.selected : style.myTask}
        style={{ cursor: 'pointer', border: 'none', }}
      >
        <div>
          {location.pathname === "/taskTracker" ?
            <img src={Iconopentask} alt="img" /> :
            <img src={Iconopentask_2} alt="img" />
          }

        </div>

        <span><div style={{ marginTop: '5px', marginLeft: '5px' }}>My Tasks</div></span>
      </button>


      <button
        onClick={() => {
          setTabActive("createTask")
          history.push('/taskTracker/createTask')
        
        }}
        className={location.pathname === "/taskTracker/createTask" ? style.selected : style.myTask}
        style={{ border: 'none' }}
      >
        <div>
        </div>
        <div>
          {location.pathname === "/taskTracker/createTask" ?
            <img src={Iconmetrocross_2} alt="img" /> :
            <img src={Iconmetrocross} alt="img" />
          }
        </div>

        <span>Create Task</span>
      </button>
      <button
        onClick={() => {
          setTabActive("myTtasksAssignedasks")
          history.push("/taskTracker/TaskAssignedByMeComponent")
        
        }
        }
        className={location.pathname === "/taskTracker/TaskAssignedByMeComponent" ? style.selected : style.assignedByMe}
        style={{ cursor: 'pointer', border: 'none', }}
      >
        <div>
          {location.pathname === "/taskTracker/TaskAssignedByMeComponent" ?
            <img src={Iconawesometasks_2} alt="img" /> :
            <img src={Iconawesometasks} alt="img" />
          }
        </div>
        <span style={{ whiteSpace: "nowrap" }}>Tasks Assigned by Me</span>
      </button>
      <button
        onClick={() => {
          setTabActive("closedTask")
          history.push('/taskTracker/ClosedByMeComponent')
          
        }
        }
        className={location.pathname === "/taskTracker/ClosedByMeComponent" ? style.selected : style.assignedByMe}
        style={{ cursor: 'pointer', border: 'none', }}
      >
        <div>
          {location.pathname === "/taskTracker/ClosedByMeComponent" ?
          <img style={{height: "120%",width: "120%"}} src={IconClosed_2} alt="img" /> :
            <img style={{height: "120%",width: "120%"}} src={IconClosed} alt="img" />
          }
        </div>
        <span style={{ whiteSpace: "nowrap" }}>Closed Tasks</span>
      </button>
    </div>
  );
};

export default SideBar;
