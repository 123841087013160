import React from "react";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import HealthRecordComp from "./component/HealthRecordComp";
import style from "../tablet.module.css";

const TabletHealthRecod = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <HealthRecordComp />
      </div>
    </div>
  );
};

export default TabletHealthRecod;
