import React from "react";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "../tablet.module.css";
import AddnewAssigntComp from "./component/AddnewAssigntComp";

const AddNewTabletAssigment = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <AddnewAssigntComp />
      </div>
    </div>
  );
};

export default AddNewTabletAssigment;
