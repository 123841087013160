import React, { useState } from "react";
import style from "./EditQuestionnaireCom.module.css";
import { customStyles } from "../../CommonComponent/style";
import Select from "react-select";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory, useLocation } from "react-router";
import dropdownIcon from "../../../../assets/downIcon.png";
import { useEffect } from "react";
import constants from "../../../../Utils/constants";
import { Toastify, ToastifyError } from "../../../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const EditQuestionnaireCom = () => {
  const [clientListing, setClientListing] = useState([]);
  const [technologyListing, setTechnologyListing] = useState([]);
  const [btnDisable, setButtonDisable] = useState(false);
  const [validate, setValidate] = useState(false);
  const [experienceRange, setExperienceRange] = useState([]);
  const [formData, setFormData] = useState({});

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");
  // base URL
  const baseUrl = constants.apiBaseUrlResource;
  const url = process.env.REACT_APP_QUESTIONNAIRE_URL;
  const history = useHistory();

  // client listing api ointegrate
  const getClientListing = () => {
    axios
      .get(baseUrl + "/getClientOnSite", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch((err) => {});
  };
  // technology listing api ointegrate
  const getTechnologyListing = () => {
    axios
      .get(url + "/getTechnology", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setTechnologyListing(res.data.responseData);
      })
      .catch((err) => {});
  };
  const getExperiencerange = () => {
    axios
      .get(url + "/getExperienceRange", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setExperienceRange(res.data.responseData);
      })
      .catch((err) => {});
  };
  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getTechList = technologyListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getExpRange = experienceRange?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  useEffect(() => {
    getClientListing();
    getTechnologyListing();
    getExperiencerange();
  }, []);

  const getQuestionnaireDetailsById = (id) => {
    axios
      .get(url + `/getQuestionById?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setFormData(res.data.responseData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getQuestionnaireDetailsById(id);
  }, [id]);
  // raisedToList?.sort((a, b) =>
  //   a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  // );
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          alt="amg"
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  // Handle Redirect After Successfull Addedd Question
  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push(`/ViewQuestionnaire?id=${id}`);
    }, 3000);
  };

  // API Integration
  // cancel Button Handler

  const cancelHandler = () => {
    history.push(`/ViewQuestionnaire?id=${id}`);
  };
  // formHAndler

  const formHAndler = (e) => {
    e.preventDefault();

    const data = {
      technologyId: formData?.technologyId,
      clientId: formData?.clientId,
      id: formData?.id,
      questions: formData?.questions,
      empId: localStorage.getItem("employeeId"),
      expRangeId: formData?.expRangeId,
    };
    if (
      !formData?.technologyId ||
      !formData?.questions ||
      !formData?.clientId
    ) {
      setValidate(true);
      return true;
    }
    axios
      .post(url + "/createQuestions", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        Toastify("Edit Question Successfully");
        handleRedirect(res.data.responseData?.id);
        setButtonDisable(true);
      })
      .catch((err) => {
        return err;
      });
  };
  return (
    <>
      <ToastContainer />
      <div className={style.main}>
        <p className={style.createissue}>Edit Questionnaire</p>
        <div>
          <div className={style.card}>
            <p className={style.title}>Details</p>
            <div className={style.Cu_row1}>
              <div className={style.cu_col1}>
                <p className={style.cu_label}>Technology</p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    formData?.technologyId
                      ? {
                          value: formData?.technologyId,
                          label: formData?.technologyName,
                        }
                      : null
                  }
                  options={getTechList}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      technologyId: e.value,
                      technologyName: e.label,
                    });
                  }}
                ></Select>
                <p
                  className={
                    validate && !formData?.technologyId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Technology
                </p>
              </div>
              <div className={style.cu_col2}>
                <p className={style.cu_label}>Client Name</p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    formData?.clientId
                      ? {
                          value: formData?.clientId,
                          label: formData?.clientName,
                        }
                      : null
                  }
                  options={getClientList}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      clientId: e.value,
                      clientName: e.label,
                    });
                  }}
                ></Select>
                <p
                  className={
                    validate && !formData?.clientId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Client Name
                </p>
              </div>
              <div className={style.cu_col3}>
                <p className={style.cu_label}>Seniority</p>
                <Select
                  styles={customStyles}
                  classNamePrefix={"create_position"}
                  components={{
                    DropdownIndicator: () => CustomInput(),
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    formData?.expRangeId
                      ? {
                          value: formData?.expRangeId,
                          label: formData?.expRange,
                        }
                      : null
                  }
                  options={getExpRange}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      expRangeId: e.value,
                      expRange: e.label,
                    });
                  }}
                ></Select>
                <p
                  className={
                    validate && !formData?.expRangeId
                      ? style.errorText
                      : style.errorTextHidden
                  }
                >
                  Please Select Seniority
                </p>
              </div>
            </div>
            <div className={style.cu_col1} style={{ marginLeft: "21px" }}>
              <p className={style.cu_label}>
                Interview Questions<span className={style.mandatory}>*</span>
              </p>
              <textarea
                name="postContent"
                rows={2}
                value={formData.questions}
                onChange={(e) => handleChange(`questions`, e.target.value)}
                className={style.textArea}
              />
              <p
                className={
                  validate && !formData?.questions
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter InterView Questions
              </p>
            </div>
          </div>
          <div className={style.buttonDiv}>
            <button
              className={style.cancelbtn}
              onClick={() => {
                cancelHandler();
              }}
            >
              Cancel
            </button>
            <button
              className={style.submitbtn}
              disabled={btnDisable}
              onClick={formHAndler}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
