import React from "react";
import style from "./loader.module.css";
import LoadImg from "../../../assets/spinner.gif";
const Loader = () => {
  return (
    <>
      <div className={style.overlay}>
        <div className={style.content}>
          <img className={style.img} src={LoadImg} alt="loader/" />
        </div>
      </div>
    </>
  );
};

export default Loader;
