import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import BreadCrum from "../../../breadcrums/BreadCrum";
import InvoiceContainer from "../../../CommonComponent/InvoiceContainer/InvoiceContainer";
import Select from "react-select";
import calender from "../../../../../assets/calblue.png";
import DatePicker from "react-datepicker";
import { InvoiceStyles } from "../../../utils/style";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { FaTrash } from "react-icons/fa";
import cancel from "../../../../../assets/cancell.png";
import InvoiceButton from "../../../CommonComponent/Button/InvoiceButton";
import style from "./EditInvoice.module.css";
import { getInvoiceId } from "../../../../../Utils/axios/expenceApi.js";
import {
  getClientName,
  getServiceType,
  getAdvanceStatus,
  getCurrencyType,
  createInvoice,
  getMonth,
} from "../../../../../Utils/axios/expenceApi.js";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import { useEffect } from "react";
const EditInvoice = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [lineItemArray, setLineItemArray] = useState([1]);

  const {
    data: InvoiceDataById,
    isLoading,
    isError,
  } = useQuery(["getInvoiceId"], () => getInvoiceId(prevData?.invoiceId));
  // useEffect (() => {
  //   setFormData(InvoiceDataById)
  // },[InvoiceDataById]
  // )
  const [validate, setValidate] = React.useState(false);
  const [formData, setFormData] = useState({
    projectId: InvoiceDataById?.projectId,
    projectType: InvoiceDataById?.projectType,
    advanceStatus: InvoiceDataById?.advanceStatus,
    clientName: InvoiceDataById?.clientName,
    invoiceDate: InvoiceDataById?.invoiceDate,
    advanceStatusId: InvoiceDataById?.advanceStatusId,
    clientId: InvoiceDataById?.clientId,
    currencyId: InvoiceDataById?.currencyId,
    currency: InvoiceDataById?.currency,
    invoiceNumber: InvoiceDataById?.invoiceNumber,
    conversionRate: InvoiceDataById?.conversionRate,
    gstNumber: InvoiceDataById?.gstNumber,
    baseAmountInvoice: InvoiceDataById?.baseAmountInvoice,
    baseAmountINR: InvoiceDataById?.baseAmountINR,
    totalInvoiceAmount: InvoiceDataById?.totalInvoiceAmount,
    description: InvoiceDataById?.description,
    dueDate: InvoiceDataById?.dueDate,
    id: prevData?.invoiceId,
    igst: InvoiceDataById?.igst,
    cgst: InvoiceDataById?.cgst,
    sgst: InvoiceDataById?.sgst,
    tds: InvoiceDataById?.tds,
    amountToBeReceived: InvoiceDataById?.amountToBeReceived,
    amountReceivedInBank: 0,
    bankReferenceId: InvoiceDataById?.bankReferenceId,
    clientAcknowledgementStatus: "done",
    reimbursableExpenses: 0,
    invoiceLineItemDetails: InvoiceDataById?.invoiceLineItemDetails,
    // invoiceLineItemDetails: [
    //   {
    //     candidateName: CandidateName,
    //     amountOverriden: 0,
    //     daysInMonth: "",
    //     // invoiceYear: "",
    //     billingRate: "",
    //     workingDays: "",
    //     // maxWorkingDays: "",
    //     baseAmount: "",
    //     calculativeBaseAmount: 0,
    //   },
    // ],
  });

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={calender}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() => setFormData({ ...formData, [props?.properties]: "" })}
          alt=""
        />
      </div>
    );
  });
  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };
  const { data: clientData } = useQuery(["getClientName"], getClientName);
  const EditgetClientName = clientData?.map((e) => ({
    value: e.id,
    label: e.clientName,
  }));
  const { data: monthData } = useQuery(["getMonth"], getMonth);
  const monthOption = monthData?.map((e) => ({
    value: e.id,
    label: e.month,
  }));
  const { data: projectData } = useQuery(["getServiceType"], getServiceType);
  const projectOption = projectData?.map((e) => ({
    value: e.id,
    label: e.serviceType,
  }));

  const { data: statusData } = useQuery(["getAdvanceStatus"], getAdvanceStatus);
  const statusOption = statusData?.map((e) => ({
    value: e.id,
    label: e.status,
  }));
  const { data: currencyData } = useQuery(
    ["getCurrencyType", "getCurrencyType"],
    getCurrencyType
  );
  const currencyOption = currencyData?.map((e) => ({
    value: e.id,
    label: e.currencyName,
  }));

  const mutation = useMutation(createInvoice, {
    onMutate: () => {
      // setDisabled(true);
    },
    onSuccess: (res) => {
      // setPrevData({ ...prevData, clientData: res });
      // setNextScreen("Confirm3_noheader");
      // setDisabled(false);
    },
    onError: (error) => {
      // setDisabled(false);
      // setShowError(true);
    },
  });

  const EditInvoiceHandler = () => {
    if (
      formData.clientName &&
      formData.invoiceDate &&
      formData.advanceStatusId &&
      formData.clientId &&
      formData.currencyId &&
      formData.invoiceNumber &&
      formData.conversionRate &&
      formData.gstNumber &&
      formData.baseAmountInvoice &&
      formData.baseAmountINR &&
      formData.totalInvoiceAmount &&
      formData.igst &&
      formData.cgst &&
      formData.sgst &&
      formData.tds &&
      formData.amountToBeReceived
    ) {
      setValidate(false);

      mutation.mutate(formData);
      setNextScreen("viewInvoice");
    } else {
      setValidate(true);
    }
  };

  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      {/*Copy Invoice*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Copy Invoice </div>
        <div className={style.Container}>
          <div className={style.col1}>
            <div className={style.heading}>Copy Invoice?</div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="Yes"
                name="radio-group"
                // onChange={() =>
                //   setFormData({ ...formData, status: "Shortlisted" })
                // }
              />
              <label for="Yes">Yes</label>
            </div>
            <div className={style.radioitem}>
              <input
                type="radio"
                id="no"
                name="radio-group"
                // onChange={() =>
                //   setFormData({ ...formData, status: "Shortlisted" })
                // }
              />
              <label for="no">No</label>
            </div>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>
              Client Name <span style={{ color: "red" }}>*</span>
            </p>
            <Select
              styles={InvoiceStyles}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              value={EditgetClientName?.find(
                (e) => e.value === formData?.clientId
              )}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  clientId: e.value,
                });
              }}
              options={EditgetClientName}
            />
            <p
              className={
                validate && !formData?.clientId
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Client Name
            </p>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
      </InvoiceContainer>
      {/*Invoice Details*/}
      <InvoiceContainer>
        <div className={style.copy_expence}>Invoice Details </div>
        {/*row 1*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>
              Project Type <span style={{ color: "red" }}>*</span>
            </p>
            <Select
              styles={InvoiceStyles}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              value={projectOption?.find(
                (e) => e.value === formData?.projectId
              )}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  projectId: e.value,
                });
              }}
              options={projectOption}
            />
          </div>

          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
        {/*row 2*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>
              Invoice No <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.invoiceNumber}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  invoiceNumber: e.target.value,
                });
              }}
            />
            <p
              className={
                validate && !formData?.invoiceNumber
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Invoice No
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>
              Invoice Date<span style={{ color: "red" }}>*</span>{" "}
            </p>
            <div className={style.cu_field23Last}>
              <DatePicker
                placeholder=" DD MM YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  formData?.invoiceDate
                    ? moment(formData?.invoiceDate).toDate()
                    : null
                }
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    invoiceDate: moment(date).format(),
                  });
                }}
                dateFormat="dd MMM yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={
                  formData?.invoiceDate ? (
                    <CustomInputCross properties="invoiceDate" />
                  ) : (
                    <CustomInputClg />
                  )
                }
              />
              <p
                className={
                  validate && !formData?.invoiceDate
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Invoice Date
              </p>
            </div>
          </div>
          <div className={style.col3}>
            <p className={style.label}>
              Due Date <span style={{ color: "red" }}>*</span>
            </p>
            <div className={style.cu_field23Last}>
              <DatePicker
                placeholder=" DD MM YYYY"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                selected={
                  formData?.dueDate ? moment(formData?.dueDate).toDate() : null
                }
                onChange={(date) => {
                  setFormData({
                    ...formData,
                    dueDate: moment(date).format(),
                  });
                }}
                dateFormat="dd MMM yyyy"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                customInput={
                  formData?.dueDate ? (
                    <CustomInputCross properties="dueDate" />
                  ) : (
                    <CustomInputClg />
                  )
                }
              />
              <p
                className={
                  validate && !formData?.dueDate
                    ? style.errorText
                    : style.errorTextHidden
                }
              >
                Please Enter Invoice Date
              </p>
            </div>
          </div>
          <div className={style.col4}>
            <p className={style.label}>
              Advance Status <span style={{ color: "red" }}>*</span>
            </p>
            <Select
              styles={InvoiceStyles}
              classNamePrefix={"create_position"}
              value={statusOption?.find(
                (e) => e.value === formData?.advanceStatusId
              )}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  advanceStatusId: e.value,
                });
              }}
              options={statusOption}
            />
          </div>
        </div>
        <div className={style.border}></div>
        {/*row 3*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>
              Currency <span style={{ color: "red" }}>*</span>
            </p>
            <Select
              styles={InvoiceStyles}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              value={currencyOption?.find(
                (e) => e.value === formData?.currencyId
              )}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  currencyId: e.value,
                });
              }}
              options={currencyOption}
            />
          </div>
          <div className={style.col2}>
            <p className={style.label}>
              Conversion Rate<span style={{ color: "red" }}>*</span>{" "}
            </p>
            <input
              type="text"
              value={formData?.conversionRate}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  conversionRate: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.conversionRate
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Conversion Rate
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>
              GSTN <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.gstNumber}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  gstNumber: e.target.value,
                });
              }}
            />
            <p
              className={
                validate && !formData?.gstNumber
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter GSTN
            </p>
          </div>
          <div className={style.col4}></div>
        </div>
        {/*row 4*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>
              Invoice Amount (Base) <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.baseAmountInvoice}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  baseAmountInvoice: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.baseAmountInvoice
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Invoice Amount (Base)
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>
              Invoice Amount (Base in INR){" "}
              <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.baseAmountINR}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  baseAmountINR: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.baseAmountINR
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Invoice Amount (Base in INR)
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>
              Total Invoice Amount <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.totalInvoiceAmount}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  totalInvoiceAmount: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.totalInvoiceAmount
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Total Invoice Amount
            </p>
          </div>
          <div className={style.col4}></div>
        </div>
        <div className={style.border}></div>
        {/*row 5*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>
              IGST <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.igst}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  igst: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.igst
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter IGST
            </p>
          </div>
          <div className={style.col2}>
            <p className={style.label}>
              CGST<span style={{ color: "red" }}>*</span>{" "}
            </p>
            <input
              type="text"
              value={formData?.cgst}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  cgst: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.cgst
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter CGST
            </p>
          </div>
          <div className={style.col3}>
            <p className={style.label}>
              SGST <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.sgst}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  sgst: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.sgst
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter SGST
            </p>
          </div>
          <div className={style.col4}>
            <p className={style.label}>
              TDS <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.tds}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  tds: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.tds
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter TDS
            </p>
          </div>
        </div>
        {/*row 6*/}
        <div className={style.Container}>
          <div className={style.col12}>
            <p className={style.label}>
              Amount to be Received <span style={{ color: "red" }}>*</span>
            </p>
            <input
              type="text"
              value={formData?.amountToBeReceived}
              className={style.smallInput}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  amountToBeReceived: e.target.value,
                });
              }}
              min="0"
              onKeyPress={(e) => {
                if (e.key === "e" || e.key === "-") {
                  e.preventDefault();
                }
              }}
            />
            <p
              className={
                validate && !formData?.amountToBeReceived
                  ? style.errorText
                  : style.errorTextHidden
              }
            >
              Please Enter Amount to be Received
            </p>
          </div>
          <div className={style.col2}></div>
          <div className={style.col3}></div>
          <div className={style.col4}></div>
        </div>
      </InvoiceContainer>
      {/* Line Item Details Container */}

      {formData?.projectId === 2 ? (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Milestone</div>
            {/*row 1*/}
            {formData?.invoiceLineItemDetails?.map((item, index) => {
              return (
                <>
                  <div className={style.Container} key={index}>
                    <div className={style.col12_item}>
                      <p className={style.label_item}>S.N.</p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].sno = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={formData?.invoiceLineItemDetails[index]?.sno}
                      />
                    </div>

                    <div className={style.col3_item}>
                      <p className={style.label_item}>Description</p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].description = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.description
                        }
                      />
                    </div>
                    <div className={style.col4_item}>
                      <p className={style.label_item}>Project Value</p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].projectValue = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.projectValue
                        }
                      />
                    </div>
                    <div className={style.col5_item}>
                      <p className={style.label_item}>Payment Percentage</p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].paymentPercentage = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]
                            ?.paymentPercentage
                        }
                      />
                    </div>
                    <div className={style.col6_item}>
                      <p className={style.label_item}>Timeframe </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].timeframe = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.timeframe
                        }
                      />
                    </div>
                    <div className={style.col7_item}>
                      <p className={style.label_item}>Price</p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].price = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={formData?.invoiceLineItemDetails[index]?.price}
                      />
                    </div>
                    <div className={style.col8_item}>
                      {formData?.invoiceLineItemDetails?.length > 1 && (
                        <>
                          <FaTrash
                            className={style.remove_icon}
                            size={20}
                            onClick={(e) => {
                              setLineItemArray(
                                lineItemArray.filter((_, i) => {
                                  return i !== index;
                                })
                              );
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails:
                                  formData?.invoiceLineItemDetails?.filter(
                                    (_, i) => i !== index
                                  ),
                              });
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}

            <div className={style.lastBtn_item}>
              <InvoiceButton
                onClick={() => {
                  // push new value 2 in array
                  setLineItemArray([
                    ...lineItemArray,
                    Math.floor(Math.random() * 100),
                  ]);
                  setFormData({
                    ...formData,
                    invoiceLineItemDetails: [
                      ...formData?.invoiceLineItemDetails,
                      {
                        candidateName: "",
                        invoiceMonth: "",
                        invoiceYear: "",
                        billingRate: "",
                        workingDays: "",
                        maxWorkingDay: "",
                        baseAmount: "",
                      },
                    ],
                  });
                }}
              >
                Add
              </InvoiceButton>
            </div>
          </InvoiceContainer>
          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("viewInvoice");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "viewInvoice",
                  page3: null,
                  page4: null,
                  active: "viewInvoice",
                }));
              }}
            >
              Cancel
            </InvoiceButton>
            <InvoiceButton
              onClick={() => {
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "viewInvoice",
                  page3: null,
                  page4: null,
                  active: "viewInvoice",
                }));
                EditInvoiceHandler();
              }}
            >
              Update
            </InvoiceButton>
          </div>
        </>
      ) : (
        <>
          <InvoiceContainer>
            <div className={style.copy_expence}>Line Item Details</div>
            {/*row 1*/}
            {formData?.invoiceLineItemDetails?.map((item, index) => {
              return (
                <>
                  <div className={style.Container} key={index}>
                    <div className={style.col12_item}>
                      <p className={style.label_item}>
                        Candidate Name <span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].candidateName = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.candidateName
                        }
                      />
                      {/* <p
            className={
              validate && !formData?.amountToBeReceived
                ? style.errorText
                : style.errorTextHidden
            }
          >
            Please Enter Amount to be Received
          </p> */}
                    </div>
                    <div className={style.col2_item}>
                      <p className={style.label_item}>
                        Invoice Month <span style={{ color: "red" }}>*</span>
                      </p>
                      {/* <input
                  type="text"
                  className={style.smallInput}
                  onChange={(e) => {
                    // save workexperince.company for index
                    let temp = [...formData?.invoiceLineItemDetails];
                    temp[index].daysInMonth = e?.target?.value;
                    setFormData({
                      ...formData,
                      invoiceLineItemDetails: temp,
                    });
                  }}
                  value={formData?.invoiceLineItemDetails[index]?.daysInMonth}
                /> */}
                      <Select
                        styles={InvoiceStyles}
                        classNamePrefix={"create_position"}
                        components={{
                          DropdownIndicator: () => CustomInput(),
                          IndicatorSeparator: () => null,
                        }}
                        value={monthOption?.find(
                          (e) =>
                            e.label ===
                            formData?.invoiceLineItemDetails[index].invoiceMonth
                        )}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].invoiceMonth = e?.label;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        options={monthOption}
                      />
                    </div>
                    <div className={style.col3_item}>
                      <p className={style.label_item}>
                        Invoice Year <span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].invoiceYear = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.invoiceYear
                        }
                      />
                    </div>
                    <div className={style.col4_item}>
                      <p className={style.label_item}>
                        Billing Rate <span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].billingRate = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.billingRate
                        }
                      />
                    </div>
                    <div className={style.col5_item}>
                      <p className={style.label_item}>
                        Working Days <span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].workingDays = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.workingDays
                        }
                      />
                    </div>
                    <div className={style.col6_item}>
                      <p className={style.label_item}>
                        Max Working Days <span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].maxWorkingDay = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.maxWorkingDay
                        }
                      />
                    </div>
                    <div className={style.col7_item}>
                      <p className={style.label_item}>
                        Base Amount <span style={{ color: "red" }}>*</span>
                      </p>
                      <input
                        type="text"
                        className={style.smallInput}
                        onChange={(e) => {
                          // save workexperince.company for index
                          let temp = [...formData?.invoiceLineItemDetails];
                          temp[index].baseAmount = e?.target?.value;
                          setFormData({
                            ...formData,
                            invoiceLineItemDetails: temp,
                          });
                        }}
                        value={
                          formData?.invoiceLineItemDetails[index]?.baseAmount
                        }
                      />
                    </div>
                    <div className={style.col8_item}>
                      {formData?.invoiceLineItemDetails?.length > 1 && (
                        <>
                          <FaTrash
                            className={style.remove_icon}
                            size={20}
                            onClick={(e) => {
                              setLineItemArray(
                                lineItemArray.filter((_, i) => {
                                  return i !== index;
                                })
                              );
                              setFormData({
                                ...formData,
                                invoiceLineItemDetails:
                                  formData?.invoiceLineItemDetails?.filter(
                                    (_, i) => i !== index
                                  ),
                              });
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </>
              );
            })}

            <div className={style.lastBtn_item}>
              <InvoiceButton
                onClick={() => {
                  // push new value 2 in array
                  setLineItemArray([
                    ...lineItemArray,
                    Math.floor(Math.random() * 100),
                  ]);
                  setFormData({
                    ...formData,
                    invoiceLineItemDetails: [
                      ...formData?.invoiceLineItemDetails,
                      {
                        candidateName: "",
                        invoiceMonth: "",
                        invoiceYear: "",
                        billingRate: "",
                        workingDays: "",
                        maxWorkingDay: "",
                        baseAmount: "",
                      },
                    ],
                  });
                }}
              >
                Add
              </InvoiceButton>
            </div>
          </InvoiceContainer>
          <div className={style.lastBtn}>
            <InvoiceButton
              type={"cancel"}
              onClick={() => {
                setNextScreen("viewInvoice");
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "viewInvoice",
                  page3: null,
                  page4: null,
                  active: "viewInvoice",
                }));
              }}
            >
              Cancel
            </InvoiceButton>
            <InvoiceButton
              onClick={() => {
                setPrevData((prevData) => ({
                  ...prevData,
                  page2: "viewInvoice",
                  page3: null,
                  page4: null,
                  active: "viewInvoice",
                }));
                EditInvoiceHandler();
              }}
            >
              Update
            </InvoiceButton>
          </div>
        </>
      )}
    </>
  );
};

export default EditInvoice;
