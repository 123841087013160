import React, { useEffect, useState } from "react";
import style from "./DoneIssueComp.module.css";
import axios from "axios";
import cookie from "react-cookies";
import { useHistory,useLocation } from "react-router";
import Moment from "moment";
import { BsFilePdf } from "react-icons/bs";
import { AiOutlineFileWord } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DoneIssueComp = () => {
    const [issueById,setIssueByID]=useState()
    const url = process.env.REACT_APP_HRHELPDESK_APP_URL;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const source = axios.CancelToken.source();

  const query = useQuery();
  const id = query.get("id");
  const history=useHistory()

   //file icon  setting
   const checkfileType = (value) => {
    switch (value) {
      case "pdf":
        return <BsFilePdf />;
      case "docx":
        return <AiOutlineFileWord />;
    }
  };

  const getIssueById = (id) => {
    axios
      .get(url + `/getIssueById?id=${id}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
        cancelToken: source.token,
      })
      .then((res) => {
        console.log(res.data.responseData)
        setIssueByID(res.data.responseData)
      })
      .catch((err) => {
        console.log(err);
      });
  }
 
  // cancel Button Handler

  // Edit button Handler
  const EditHandler=()=>{
    history.push(`/EditIssue?id=${id}`)
  }

  // Done Button Handler
const doneHandler =()=>{
    history.push("/MyIssue")
  }

  useEffect(()=>{
getIssueById(id)
return () => {
  // Cancel the request when the component is unmounted
  source.cancel("Component is unmounted");
};
  },[])

 
  return (
    <>
    <ToastContainer />
<div className={style.main}>
<p className={style.viewIssuP}>View Issue</p>

      <div className={style.card}>
        <p className={style.title}>Issue Details</p>
        <div className={style.Cu_row1}>
        <div className={style.cu_col1}>
            <p className={style.cu_label}>
              Issue Id
            </p>
          <p>{`HRHD${issueById?.id}`}</p>
          </div>
          <div className={style.cu_col1}>
          <p className={style.cu_label}>Category</p>
            <p>{issueById?.categoryName}</p>
          </div>
          <div className={style.cu_col2}>
          <p className={style.cu_label}>Sub Category</p>
         <p>{issueById?.subCategoryName}</p>
          </div>
          <div className={style.cu_col3}>
          <p className={style.cu_label}>
              Logged Date
            </p>
          <p>{Moment(issueById?.loggedDate).format("DD MMM YY")}</p>
          </div>
        </div>
        <div className={style.Cu_row1}>
        <div className={style.cu_col1}>
        <p className={style.cu_label}>
              Raised By
            </p>
           <p>{issueById?.raisedByName}</p>
          </div>
          <div className={style.cu_col1}>
          <p className={style.cu_label}>Status</p>
            <p>{issueById?.status}</p>
          </div>
          <div className={style.cu_col2}>
          
          </div>
          <div className={style.cu_col3}>
         
          </div>
        </div>
        <div className={style.cu_col1} style={{ marginLeft: "21px" }}>
          <p className={style.cu_label}>Description</p>
         <p className={style.description}>{issueById?.description}</p>
        </div>
        <p className={style.cu_label1}>
              Attachment
            </p>
            <div style={{paddingLeft:"22px"}}>
           {checkfileType(issueById?.attachment?.split(".")[5])}
          <a style={{textDecoration:"underline"}} href={issueById?.attachment} target="_blank">{issueById?.attachment.split('@')[1]}</a>
        </div>
      </div>
      <div className={style.buttonDiv}>
  <button className={style.submitbtn} onClick={EditHandler}>Edit</button>
  <button className={style.Done} onClick={()=>doneHandler()}>Done</button>
</div>
   
  </div>
    </>
  );
};
