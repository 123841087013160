import React, { useEffect, useState } from "react";
import style from "./SideBar.module.css";
import one from "../../../assets/businessman-gear-icon.png";
import oneG from "../../../assets/businessman-gear-icon_GREEN.png";
import ats from "./../../../assets/ats.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import {
  getCheckEmpRole
} from "../../../Utils/axios/NewTimeSheetApi";
import { useQuery } from "react-query";


const SideBar = ({
  sidebarHandler,
  nextScreen,
  setPrevData,
  prevData,
  setCurrentPage,
  roles,
  getTeamMembers,
  setNextScreen
}) => {
  const [downArrow, setDownArrow] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [employeeIdData, setEmployeeIdData] = useState('')


  let history = useHistory();
  const [entity,setEntity]=useState("")

  const {
    data: getCheckEmpRoleId} = useQuery(["getCheckEmpRole"], () => getCheckEmpRole(Number(localStorage.getItem("employeeId"))));

  console.log("getCheckEmpRoleId",getCheckEmpRoleId);


  const apiKey =process.env.REACT_APP_NEW_TIMESHEET_URL;

  useEffect(() => {
    if (entity.length === 0) {
      const url = `${apiKey}/get-team-members?leadId=${Number(localStorage.getItem("employeeId"))}`
      axios
        .get(url)
        .then((res) => {
          console.log("res",res);
          setEntity(res.data.responseData)
        })
        .catch((err) => {
        });
    }
  }, [])
 
  return (
    <>
    
    {getCheckEmpRoleId&&getCheckEmpRoleId === true ?  <div className={style.leftSection}>
      {/* 0 */}
      <img
        alt="DashBoardImage"
        src={InnovationMLogo}
        className={style.dashboardImage}
        onClick={() => history.push("/dashboard")}
      />
      <button className={style.taskTracker}>
        <img src={ats} alt="img" height="40px" />
        <span>Employee Timesheet</span>
      </button>

      {/* 1 */}

      <button
        onClick={() => {
          sidebarHandler("empTimeSheet");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "empTimeSheet",
            page: "empTimeSheet",
            page1: "empTimeSheet",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "empTimeSheet",
          });
        }}
        className={
          prevData?.sidebar === "empTimeSheet"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "empTimeSheet" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>My Timesheet</span>
        </div>
      </button>

       <button
        onClick={() => {
          sidebarHandler("TeamTimeSheets");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "TeamTimeSheets",
            page: "TeamTimeSheets",
            page1: "TeamTimeSheets",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "TeamTimeSheets",
          });
        }}
        className={
          prevData?.sidebar === "TeamTimeSheets"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "TeamTimeSheets" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>Team Timesheet</span>
        </div>
      </button>
    </div> :  
    <div className={style.leftSection}>
      {/* 0 */}
      <button className={style.taskTracker}>
        <img src={ats} alt="img" height="40px" />
        <span>Employee Timesheet</span>
      </button>

      {/* 1 */}

      <button
        onClick={() => {
          sidebarHandler("empTimeSheet");
          setCurrentPage(1);
          setPrevData({
            ...prevData,
            sidebar: "empTimeSheet",
            page: "empTimeSheet",
            page1: "empTimeSheet",
            page2: null,
            page3: null,
            page4: null,
            page5: null,
            page6: null,
            active: "empTimeSheet",
          });
        }}
        className={
          prevData?.sidebar === "empTimeSheet"
            ? style.selected
            : style.assignedByMe
        }
      >
        <div className={style.sb0}>
          {prevData?.sidebar === "empTimeSheet" ? (
            <img src={oneG} alt="img" />
          ) : (
            <img src={one} alt="img" />
          )}
        </div>
        <div className={style.sb}>
          <span>My Timesheet</span>
        </div>
      </button>

    
    </div>
    
    }

    </>
   
  );
};

export default SideBar;
