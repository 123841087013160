//AllIssueComp
import React, { useState,useEffect } from "react";
import style from "./MyIssuecom.module.css"
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import cookie from "react-cookies";
import sortDownarrowImg from "../../../../../assets/Down.png";
import sortUparrowImg from "../../../../../assets/Up.png";
import Pagination from "../../../commonComponent/Pagination";

export const MyIssueCom = () => {
  const[myIssueList,setMyIssueList]=useState()
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setpageCount] = useState(0);
  const [totalIssue, settotalIssue] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState(null);

  const [allData,setAllData] = useState({
    categoryId: [],
    delayByOrder:sortOrder
  });

  const limit = 10;
  // url
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;
  const source = axios.CancelToken.source();

  const createHandler =()=>{
    history.push("/CreateIssue")
  }

  const viewIssueHandler =(id)=>{
    history.push(`/ViewIssue?id=${id}`)
  }

// pagination Handler
const handlePageChange = (page, status) => {
  if (status === "next") {
    setCurrentPage(page + 1);
  } else if (status === "prev") {
    setCurrentPage(page - 1);
  }
};

   // My Issue API Integratio
   const getSummary = () => {
    axios
      .post(
        url +
          `/getMyIssueByFilter?empId=${localStorage.getItem("employeeId")}&` +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        allData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
          cancelToken: source.token,
        }
      )
      .then((res) => {
        setMyIssueList(res.data.responseData)
        settotalIssue(res.data.responseData.totalIssue)
        setpageCount(res.data.responseData.totalIssue / 10);
        setisLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getSummary();
    return () => {
      source.cancel("Component is unmounted");
    };
  },[currentPage,allData]); 

  const handleSorting = () => {
    setSortOrder(sortOrder ===null? "ASC" :sortOrder === "ASC"?"DESC":"ASC");
  };
useEffect(()=>{
  setAllData({...allData,delayByOrder:sortOrder})
},[sortOrder])

  return (
    <>
    <div className={style.main}>
        <div className={style.issueAllign}>
        <p className={style.myIssueP}>My Issue</p>
        <button  className={style.btnColor} onClick={createHandler}>
                <AddIcon /> Create Issue
              </button>
        </div>
       
    
    <div className={style.IssueList}>
    {isLoading ? (
                <p className={style.noDataAlign}>Loading...</p>
              ) : (
    <table style={{width:"100%"}}>
      <thead>
        <tr className={style.th}>
          <th className={style.tableContent}>Issue ID</th>
          <th className={style.tableContent}>Logged Date</th>
          <th className={style.tableContent}>Category</th>
          <th className={style.tableContent}>Sub-Category</th>
          <th className={style.tableContent}>Status</th>
          <th className={style.tableContent} style={{textAlign:"center"}} >Delay By<img  alt="" style={{ cursor: "pointer", position: "relative", bottom: '0px', left: "5px" }} src={sortOrder==="ASC"? sortUparrowImg : sortDownarrowImg} onClick={handleSorting}/></th>
        </tr>
      </thead>
      {myIssueList?.issues?.length===0?<p className={style?.noDataAlign}>No Data Available</p>:(
      <tbody  style={{height:"20px"}}>
        {myIssueList?.issues.map((issue) => (
          <tr key={issue.issueId} className={style.td}>
            <td className={`${style.link} ${style.tableContent}`} onClick={()=>viewIssueHandler(issue?.issueId)}>{`HRHD${issue?.issueId}`}</td>
            <td className={style.tableContent}>{Moment(issue.loggedDate).format("DD MMM YY")}</td>
            <td className={style.tableContent}>{issue.category}</td>
            <td className={style.tableContent}>{issue.subCategory}</td>
            <td className={style.tableContent}>{issue.status}</td>
            <td className={style.tableContent} style={{textAlign:"center"}}>{issue?.delayBy}</td>
          </tr>
        ))}
      </tbody>
      )}
    </table>
    )}
    </div>
    {totalIssue > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px",paddingRight:"10px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
    </div>
    </>
  );
};


