import React, { useEffect, useState } from "react";
import style from "./summaryCom.module.css";
import { useHistory } from "react-router";
import Select from "react-select";
import dropdownIcon from "../../../../../assets/downIcon.png";
import { customStyles } from "../../../commonComponent/style";
import axios from "axios";
import cookie from "react-cookies";

const SummaryCom = () => {
  const [currentPage] = useState(1);
  const[monthlyOverView,setMonthlyOverview]=useState()
  const [summaryList, setSummary] = useState();
  const[months,setMonths]=useState()
  const[monthslabel,setMonthslabel]=useState()
  const [allData, setAllData] = useState({
    email:localStorage.getItem("email"),
    monthId:0,
  });
  
  const history = useHistory();
  const limit = 10;
  // url
  const url = process.env.REACT_APP_HRHELPDESK_APP_URL;
  const CustomInput = () => {
    return (
      <span>
        <img
        alt=""
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
        />
      </span>
    );
  };

  // DataClickeHandler
  const DataClickHandler = (categaryId, statusId) => {
    history.push({
      pathname: "/AllIssue",
      state: {
        categaryId: categaryId,
        statusId: statusId,
        monthId:allData?.monthId
      },
    });
  };

  // getSummary Api Integrate

  const fetMonthlyOverView = () => {
    axios
      .get(
        url +`/getMonthlySummary?email=${localStorage.getItem("email")}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.responseData.summary);
        setMonthlyOverview(res.data.responseData);
        // setpageCount(res.data.responseData.totalClient / 10);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSummary = () => {
    axios
      .post(
        url +
          "/getIssueSummaryList?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        allData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.responseData.summary);
        setSummary(res.data.responseData);
        // setpageCount(res.data.responseData.totalClient / 10);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  const getMonthsList = () => {
    axios
      .get(url+ "/getMonthsList", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
          employeeId: localStorage.getItem("employeeId"),
        },
      })
      .then((res) => {
        console.log(res);
        setMonths(res.data.responseData)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllmonthsList = months?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const defaultOption = getAllmonthsList?.find(option => option.value === new Date().getMonth() + 1);
console.log("defaultOption",defaultOption)
  useEffect(() => {
    getSummary();
    fetMonthlyOverView()
    getMonthsList()
  }, [allData]);

  return (
    <>
      <div className={style.main}>
        <p className={style.pp}>Monthly Overview</p>
        <div className={style.IssueList}>
  <div className={style.tableContainer}>
    <table className={style.table}>
      <thead>
        <tr className={style.th}>
          <th className={style.tableContent}>Status</th>
          <th className={style.tableContent}>Jan</th>
          <th className={style.tableContent}>Feb</th>
          <th className={style.tableContent}>Mar</th>
          <th className={style.tableContent}>Apr</th>
          <th className={style.tableContent}>May</th>
          <th className={style.tableContent}>Jun</th>
          <th className={style.tableContent}>Jul</th>
          <th className={style.tableContent}>Aug</th>
          <th className={style.tableContent}>Sep</th>
          <th className={style.tableContent}>Oct</th>
          <th className={style.tableContent}>Nov</th>
          <th className={style.tableContent}>Dec</th>
        </tr>
      </thead>
      <tbody>
        {monthlyOverView?.map((row, rowIndex) => (
          <tr key={rowIndex} className={style.td}>
            <td className={style.tableContent}>{row.status}</td>

            {row.months.map((month, monthIndex) => (
              <td key={monthIndex} className={style.tableContent}>
                {month.count}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

        <div className={style.div}>
          <span className={style.pp}>Summary</span>{" "}
          <label className={style.select}>Month</label>
          <div className={style.cu_col1}>
            <Select
              styles={customStyles}
              classNamePrefix={"create_position"}
              components={{
                DropdownIndicator: () => CustomInput(),
                IndicatorSeparator: () => null,
              }}
              value={allData?.monthId? monthslabel:defaultOption}
              options={getAllmonthsList}
              onChange={(e) =>{
                setAllData({ ...allData, monthId:e?.value});
                setMonthslabel(e)
              }
              }
            ></Select>
          </div>
        </div>

        <div className={style.IssueList1}>
          <table>
            <thead>
              <tr className={style.th1}>
                <th className={style.tableContent1}>Category</th>
                <th className={style.tableContent2}>Open</th>
                <th className={style.tableContent2}>In Progress</th>
                <th className={style.tableContent2}>Closed</th>
                <th className={style.tableContent2}>Total</th>
              </tr>
            </thead>
            <tbody>
              {summaryList?.summary.map((item) => (
                <tr className={style.td1} key={item.categoryId}>
                  <td className={style.td1}>{item.category}</td>
                  <td
                    className={`${style.td1} ${style.link}`}
                    onClick={() =>
                      DataClickHandler(item.categoryId,1)
                    }
                    title="Click here"
                  >
                    {item.openCount}
                  </td>
                  <td
                    className={`${style.td1} ${style.link}`}
                    onClick={() =>
                      DataClickHandler(item.categoryId,2)
                    }
                  >
                    {item.inprogressCount}
                  </td>
                  <td
                    className={`${style.td1} ${style.link}`}
                    onClick={() =>
                      DataClickHandler(item.categoryId,3)
                    }
                  >
                    {item.closedCount}
                  </td>
                  <td
                    className={`${style.td1}`}
                   >
                    {item.openCount + item.inprogressCount + item.closedCount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SummaryCom;
