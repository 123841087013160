import React from "react";
import style from "./SideBar.module.css";
import SRC_ASSET_MENU_MAIN from "../../../assets/assetsmangement/devices.png";
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { getUserAccess } from "../../../Utils/axios/expenceApi";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import { useQuery } from "react-query";

const SideBar = () => {
  const history = useHistory();
  const location = useLocation();


  const {data:empAccess}=useQuery(["getUserAccess"],()=>getUserAccess())
  return (
    <div className={style.leftSection}>
      <div className={style.header}>
        <img
          alt="DashBoardImage"
          src={InnovationMLogo}
          className={style.dashboardImage}
          onClick={() => history.push("/dashboard")}
        />
      </div>
      <button className={style.taskTracker}>
        <div>
          <img src={SRC_ASSET_MENU_MAIN} alt="img" />
        </div>

        <span>HR Help Desk</span>
      </button>

      {empAccess === "Admin" || empAccess === "Super Admin" ? (
        <Link to="/HrHelpDeskAllIssue" style={{ textDecoration: "none" }}>
          <div
            style={{ textDecoration: "none" }}
            className={
              location.pathname === "/HrHelpDeskAllIssue"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GridViewRoundedIcon className={style.icon} />}
            <div className={style.heading}>Dashboard</div>
          </div>
        </Link>
      ) : null}
      {empAccess === null || empAccess === "Admin" || empAccess === "Super Admin"? (
        <Link to="/MyIssue" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/MyIssue" ||
              location.pathname === "/CreateIssue" ||
              location.pathname === "/ViewIssue"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<AccountCircleIcon className={style.icon} />}
            <div className={style.heading}>HR Help Desk </div>
          </div>
        </Link>
      ) : null}

      {empAccess === "Admin" || empAccess === "Super Admin" ? (
        <Link to="/SummaryDetails" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/SummaryDetails" ||
              location.pathname === "/AllIssue"
                ? // location.pathname === "/HrHelpDeskViewIssue"
                  style.active_btn
                : style.btn_menu
            }
          >
            {<LiveHelpIcon className={style.icon} />}
            <div className={style.heading}>Help Desk Summary</div>
          </div>
        </Link>
      ) : null}
    </div>
  );
};

export default SideBar;
