import React, { useEffect, useState } from "react";
import SideBar from "../Clients/component/SideBar";
import style from "./resourceManagement.module.css";
import TableRow from "./component/TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import loaderImg from "../../../assets/loader.gif";
import constants from "../../../Utils/constants";
import axios from "axios";
import Select from "react-select";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import { customSearch } from "../Clients/component/style";
import Pagination from "../CommonComponent/Pagination";
import milestone from "../../../assets/milestone.png";
import rightTickPRM from "../../../assets/rightTickPRM.png";
import Filter from "../components/Filter";


const ResourceManagement = (props) => {
  const history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;
  const [applied, setApplied] = useState(props.history?.location.state?.allData?.applied?props.history.location.state?.allData?.applied:false);
  const [showFilter, setShowFilter] = useState(false);
  const [OffShoreData, setOffshoreData] = useState();
  const [currentPage, setCurrentPage] = useState(props.history?.location?.state?.allData?.currentPage?props.history.location?.state?.allData?.currentPage:1);
  const [totalResourceOffShore, setTotalResourceOffShore] = useState(0);
  const [pageCount, setpageCount] = useState(0);
  const [offshoreEmp, setOffshoreEmp] = useState([]);
  const [offshoreProject, setOffshoreProject] = useState([]);
  const [offshoreSkills, setOffshoreSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [action, setAction] = useState([]);
  const [bandwidths, setBandwidths] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [filterName, setFilterName] = useState(props.history.location.state?.allData?.filterName?props.history.location.state?.allData?.filterName:{});
  const [projectId, setProjectId] = useState([]);
  const [skills, setSkills] = useState([]);

  const [allData, setAllData] = useState(
    props.history.location.state?.allData?.allData?props.history.location.state?.allData?.allData:{
    action: [],
    bandwidths: bandwidths,
    empId: employeeId,
    projectId: projectId,
    skillId: skills,
    salaryRange:[],
    partners:[],
  });


  offshoreEmp?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const url = process.env.REACT_APP_UMS_APP_URL;
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, empId: [e?.value] });
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        default:
      }
    }
  };
  const getResourceOffShoreData = (clearData, currentPage) => {
    const filterData = clearData?.skillId?.length === 0 ? allData : allData;

    setIsLoaded(true);

    axios
      .post(
        baseUrl +
          "/getAddResourceOffshore?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setOffshoreData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(
          res.data.responseData.totalResources/10
        );
      })
      .catch((err) => {
        setIsLoaded(false);
        console.log(err);
      });
  };

  
  useEffect(() => {
    getResourceOffShoreData(allData, currentPage);
    getOffshoreProject();
    getOffshoreEmp();
    getOffshoreSkills();

  }, [currentPage,allData]);
  const getOffshoreEmp = () => {
    axios
      .get(baseUrl + "/getAllocatedOffShoreEmployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreEmp(res.data.responseData.employees);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOffshoreProject = () => {
    axios
      .get(baseUrl + "/getProjectOffShore", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreProject(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = () => {
    axios
      .get(
        url +
          `/updateUsersDetails`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getOffshoreSkills = () => {
    axios
      .get(baseUrl + "/getOffshoreEmployeeSkillForFilterData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setOffshoreSkills(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };
  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setCurrentPage(1)
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            action: [],
            bandwidths:[],
            empId:allData?.empId,
            projectId:[],
            skillId:[],
            salaryRange:[],
            partners:[]
          }
        : null;
    });
    // getResourceOffShoreData(allData, 1);
    setAction([]);
    setBandwidths([]);
    // setEmployeeId([]);
    setProjectId([]);
    setSkills([]);
    setShowFilter(false);
    setApplied(false);
  };


  const handleApplied = (arg) => {
    if (
      allData?.action?.length !== 0 ||
      allData?.bandwidths?.length !== 0 ||
      allData?.empId?.length !== 0 ||
      allData?.skillId?.length !== 0 ||
      allData?.projectId?.length !== 0 ||
      allData?.salaryRange?.length !==0 ||
      allData?.partners.length !==0
    ) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1);
    getResourceOffShoreData(allData, 1);
  };

   
  const getPositionCodeForFilterOption = offshoreEmp?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  useEffect(()=>{
    getUserData()
    
  },[])

  return (
    <>
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              {/* <span><span>Dashboard{'>'}</span><span className={style.recent_tab}>Resource Allocation Offshore </span></span> */}
            </div>

            <div className={style.second_child}>
              <div
                style={{ padding: "10px 0px", fontWeight: "600", width: "15%" }}
              >
                Resource Offshore
              </div>
              <div style={{width:"14%"}}>
              <p className={style.count}>
              <span className={style.backColor1} style={{fontSize:"1.235rem", fontWeight:"500"}}>{totalResourceOffShore}</span>
            </p>
              </div>
              <div style={{ width: "27%", paddingLeft: "10%" }}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img className={style.appliedcheck} src={rightTickPRM} alt="img"/>
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>

              <div
                className={style.s1}
                style={{ width: "24%" }}
                onClick={() => {
                  setShowFilter(false);
                }}
              >
                {allData?.empId?.length === 0 ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon}  alt="img"/>
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setAllData({
                        ...allData,
                        empId: [],
                      });
                      setFilterName({ ...filterName, positionCode: null });
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}
                <Select
                  styles={customSearch}
                  classNamePrefix={"create_position"}
                  placeholder="Employee Name"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={
                    filterName?.positionCode
                      ? {
                          value: allData?.clientId,
                          label: filterName?.positionCode,
                        }
                      : null
                  }
                  onChange={(e) => {
                    onSearchHandler(e, "Code");
                    setCurrentPage(1)
                    setShowFilter(false);
                  }}
                  options={getPositionCodeForFilterOption}
                />
              </div>
              <div className={style.btn_add}>
                <Link
                  to="/AddResourcesOffshore"
                  style={{ textDecoration: "none" }}
                >
                  <button className={style.create_btn}>
                    <span className={style.plus}>+</span>Allocate Resource
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.offshorelist}
             style={{ backgroundColor: "white", minHeight:"28rem"}}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : OffShoreData?.resources.length>0 ? (
              <Table borderless size="sm" style={{ fontSize: "12px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                  <th style={{ padding: "15px" }}>Emp Code</th>
                    <th style={{ padding: "15px" }}>Emp Name</th>
                    <th style={{ padding: "15px" }}>Experience</th>
                    <th style={{ padding: "15px" }}>Salary Range</th>
                    <th style={{ padding: "15px" }}>Project</th>
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{textAlign:"center"}}>% Allocation</th>
                    <th style={{ padding: "10px" }}>Status</th>
                    <th style={{ padding: "15px" }}>Start Date</th>
                    <th style={{ padding: "15px" }}>End Date</th>
                    <th style={{ padding: "15px" }}>Last Comment <br></br> Date</th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody >
                  {OffShoreData?.resources.map((val) => {
                    return (
                      <TableRow
                        projectName={val.projectName}
                        employeeName={val.empName}
                        skill={val.skillName}
                        applicationList={val.applicationList}
                        allocationType={val.allocationTypeName}
                        allocation={val.bandwidth}
                        allocationDate={val.allocationdate}
                        deallocationDate={val.deallocationdate}
                        applicationName={val.applicationName}
                        id={val.id}
                        experience={val.experience}
                        resignStatus={val.resignStatus}
                      lastCommentDate={val.currentCommentDate}
                      empCode={val.empCode}
                      salaryRange={val.salaryRange}
                      allData={allData}
                      currentPage={currentPage}
                      filterName={filterName}
                      applied={applied}
                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : 

            
                <div className={style.noData}>
                  <img src={milestone} alt="img" />
                  <h3 className={style.noData_header}>
                    No data available
                  </h3>
                </div>
              }
          </div>
          {totalResourceOffShore > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"offShore"}
          setAction={setAction}
          setBandwidths={setBandwidths}
          employeeId={offshoreEmp}
          setEmployeeId={setEmployeeId}
          projectList={offshoreProject}
          setProject={setProjectId}
          offshoreSkills={offshoreSkills}
          setSkills={setSkills}
        />
      )}
    </>
  );
};

export default ResourceManagement;
