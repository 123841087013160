import React from "react";
import { Link } from "react-router-dom";
import style from "./PoolResources.module.css";
import Moment from "moment";
import { useHistory, useLocation } from "react-router-dom";


const TableRow = (props) => {
  const history = useHistory();
  const GoToDetails=()=>{
    history.push({
      pathname: '/PoolResourcesDescription',
      state: {
        id:props.id,
        currentPage:props?.currentPage,
        allData:props.allData,
        // filterName:props.filterName,
        applied:props.applied,
        filterName:props.filterName,
        
      }
    });
  }
  return (
    <tr style={{ borderBottom: "2px solid #F0F0F0", textAlign: "start",  fontSize:"13px", }}>

<td >{props.empCode}</td>
      {/* <Link
        to={`/PoolResourcesDescription?id=${props.id}`}
        className={style.backColor}
      > */}
       
          <td
             onClick={GoToDetails}
            style={{
              padding: "10px 0px",
              cursor:"pointer",
              textTransform: "capitalize",
              fontWeight: "bolder",
              textDecorationLine:"underline",
              
            }}
          >
            {props.empName}
            
          </td>
      
        {/* <td style={{ padding: "7px 0px", textTransform: "capitalize",fontWeight:"bolder",width:"20%" }}>
        {props.empName}
        </td> */}
      {/* </Link> */}
      <td >
        {" "}
        {~~(props.experience / 12)} Years {props.experience % 12} Months
      </td>
      <td >
        {props?.experienceRange}
      </td>
      <td >{props.salaryRange}</td>
      <td >{props.skillName}</td>
      <td >
        {Moment(props.poolStartdate).format("DD MMM YY")}
      </td>
      <td >
        {props.poolEndDate
          ? Moment(props.poolEndDate).format("DD MMM YY")
          : null}
      </td>
      <td > {props.benchAge}</td>
      <td >
        {props.candidateAvailablityDate
          ? Moment(props.candidateAvailablityDate).format("DD MMM YY")
          : null}
      </td>
      <td >
        {props.currentCommentDate
          ? Moment(props.currentCommentDate).format("DD MMM YY")
          : null}
      </td>
      
      <td style={{width:"2%"}}>{ props?.resignStatus? <span style={{fontSize:"11.5px", color:"red", fontWeight:"900"}}>R</span>:null}</td>
      <td style={{width:"2%"}}><span className={style.count1} style={{fontSize:"11.5px", color:"green",cursor:"pointer", fontWeight:"900"}}  onClick={() =>( props.setShow(true), props.setEmpID(props.empId))}>A</span></td>
      {/* <td 
          style={{
            width:"10%",
          fontWeight:"500",
          color: props.statusTypeName === "Active" ? "green" :"red",
          
        }}> {props.statusTypeName}</td> */}
    </tr>
    
  );

};
export default TableRow;

{
  /* <button style={{ color: state === "active" ? "#F00" : "#00F" }}> */
}
