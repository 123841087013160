import { cloneDeep } from "lodash";
import React from "react";

import Modal from "react-responsive-modal";
import style from "./Filter.module.css";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearch } from "../utils/style";
import { useQuery } from "react-query";
import {getExpenseCategory,getVendorName,getPaymentStatus,getPaymentDate,getMonth} from "../../../Utils/axios/expenceApi.js";
import Select from "react-select";
import "./style.css";
import { filterBox } from "../utils/style";
function Filter({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  firstCardlist,
  secondCardlist,
  thirdCard,
  thirdCardlist,
  forthCard,
  forthCardlist,
  fifthCard,
  fifthCardList,
  sixthCard,
  sixthCardList,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
  sevenCard,
  eightCard,
}) {
  const CustomInput = () => {
    return (
      <img
        src={searchIcon}
        style={{
          position: "absolute",
          top: "0.6rem",
          right: "0.4rem",
          width: "18px",
        }}
      />
    );
  };

  const { data: clientCategory } = useQuery(["getExpenseCategory"], getExpenseCategory);
  const CategoryOption = clientCategory?.map((e) => ({
    value: e.id,
    label: e.expenseCategory,
  }));
  const { data: clientMonth } = useQuery(["getMonth"], getMonth);
  const MonthOption = clientMonth?.map((e) => ({
    value: e.id,
    label: e.month,
  }));
  const { data: Vendor } = useQuery(["getVendorName"], getVendorName);
  const VendorOption = Vendor?.map((e) => ({
    value: e.id,
    label: e.vendorName,
  }));
  const { data: Status } = useQuery(["getPaymentStatus"], getPaymentStatus);
  const StatusOption = Status?.map((e) => ({
    value: e.id,
    label: e.status,
  }));
  const { data: PaymentDate } = useQuery(["getPaymentDate"], getPaymentDate);
  const PaymentOption = PaymentDate?.map((e) => ({
    value: e.id,
    label: e.paymentDate,
  }));
  
  const CustomCross = () => {
    return (
      <span
        style={{
          position: "absolute",
          top: "0.3rem",
          right: "0.4rem",
          width: "18px",
          cursor: "pointer",
        }}
        // onClick={() => setSelectedOption(null)}
      >
        <AiOutlineClose />
      </span>
    );
  };
  const CategoryValue = (e, status) => {
    setFilterData((preData) => ({
      ...preData,
      category: e.value,
      CategoryValue: e.label,
    }));
  };
  
  const handleChnageAdvanceStatus = (e) => {
    setFilterData((preData) => ({
      ...preData,
      advanceStausId: e.value,
      advanceStausValue:e.label,
    }));
  };
  const handleSearchYear = (e) => {
    setFilterData((preData) => ({
      ...preData,
      MonthId: e.label,
      MonthValue:e.label,
    }));
  };
  
  const handleSearchPayment = (e) => {
    setFilterData((preData) => ({
      ...preData,
      PaymentDateId: e.value,
      PaymentStatusValue:e.label,
    }));
  };
  const handleSearchVendor = (e) => {
    setFilterData((preData) => ({
      ...preData,
      VendorId: e.value,
      VendorValue:e.label,
    }));
  };
  
  
  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        
        <div className={style.filterModal}>
          <div className={style.filterCards}>
            {firstCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{firstCard}</div>
                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  {filterData?.MonthId === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} style={{marginTop:"15px",marginLeft:"-5px"}} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                style={{marginTop:"13px"}}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    MonthId: "",
                  });
                  //  setFilterData({ ...filterData,MonthId : 0 });
                }}
                
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                  // menuPosition="fixed"
                    // styles={customSearch}
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    
                    value={
                      filterData?.MonthId
                        ? {
                            value: filterData?.MonthValue,
                            label: filterData?.MonthValue,
                          }
                        : null
                    }
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                     onChange={(e) => handleSearchYear(e)}
                      options={MonthOption}
                  />
                </div>
              </div>
            )}
            {forthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{forthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  {filterData?.category === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} style={{marginTop:"15px",marginLeft:"-5px"}} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                style={{marginTop:"10px"}}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    category: "",
                  });
                  //  setFilterData({ ...filterData,MonthId : 0 });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                     styles={filterBox}
                    classNamePrefix={"create_position"}
                    value={
                      filterData?.category
                        ? {
                            value: filterData?.category,
                            label: filterData?.CategoryValue,
                          }
                        : null
                    }
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => CategoryValue(e)}
                     options={CategoryOption}
                  />
                </div>
              </div>
            )}

            {secondCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{secondCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                   {filterData?.VendorId === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} style={{marginTop:"15px",marginLeft:"-5px"}} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                style={{marginTop:"10px"}}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    VendorId: "",
                  });
                  //  setFilterData({ ...filterData,MonthId : 0 });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                 styles={filterBox}
                    classNamePrefix={"create_position"}
                    value={
                      filterData?.VendorId
                        ? {
                            value: filterData?.VendorId,
                            label: filterData?.VendorValue,
                          }
                        : null
                    }
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                     onChange={(e) => handleSearchVendor(e)}
                     options={VendorOption}
                  />
                </div>
              </div>
            )}
            {thirdCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{thirdCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  
                  <Select
                     styles={filterBox}
                    classNamePrefix={"create_position"}
                    
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                  />
                </div>
              </div>
            )}
            {fifthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{fifthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                   {filterData?.advanceStausId === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} style={{marginTop:"15px",marginLeft:"-5px"}} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                style={{marginTop:"10px"}}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    advanceStausId: "",
                  });
                  //  setFilterData({ ...filterData,MonthId : 0 });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                     styles={filterBox}
                    classNamePrefix={"create_position"}
                    
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                     onChange={(e) => handleChnageAdvanceStatus(e)}
                     options={StatusOption}
                    // placeholder={"Search Employee..."}
                    value={
                      filterData?.advanceStausId
                        ? {
                            value: filterData?.advanceStausId,
                            label: filterData?.advanceStausValue,
                          }
                        : null
                    }
                  />
                </div>
              </div>
            )}
            {sixthCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sixthCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                   {filterData?.PaymentDateId === "" ? (
              <span className={style.cp_serchIcon}>
                <img src={searchIcon} style={{marginTop:"15px",marginLeft:"-5px"}} />
              </span>
            ) : (
              <span
                className={style.cp_serchIcon}
                style={{marginTop:"10px"}}
                onClick={() => {
                  setFilterData({
                    ...filterData,
                    PaymentDateId: "",
                  });
                  //  setFilterData({ ...filterData,MonthId : 0 });
                }}
              >
                <AiOutlineClose />
              </span>
            )}
                  <Select
                     styles={filterBox}
                    classNamePrefix={"create_position"}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                     onChange={(e) => handleSearchPayment(e)}
                     options={PaymentOption}
                    // placeholder={"Search Employee..."}
                    value={
                      filterData?.PaymentDateId
                        ? {
                            value: filterData?.PaymentDateId,
                            label: filterData?.PaymentStatusValue,
                          }
                        : null
                    }
                  />
                </div>
              </div>
            )}
            {sevenCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sevenCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    // value={selectedOption}
                    // components={{
                    //   DropdownIndicator: () =>
                    //     selectedOption ? CustomCross() : CustomInput(),
                    //   IndicatorSeparator: () => null,
                    // }}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    // onChange={(e) => handleSearch(e)}
                    // options={getPositionOwnerForFilterOption}
                    // placeholder={"Search Employee..."}
                  />
                </div>
              </div>
            )}
            {eightCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{eightCard}</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                  }}
                >
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    // value={selectedOption}
                    // components={{
                    //   DropdownIndicator: () =>
                    //     selectedOption ? CustomCross() : CustomInput(),
                    //   IndicatorSeparator: () => null,
                    // }}
                    components={{
                      DropdownIndicator: () => CustomInput(),
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    // onChange={(e) => handleSearch(e)}
                    // options={getPositionOwnerForFilterOption}
                    // placeholder={"Search Employee..."}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
            }}
          >
            Clear All
          </button>
          <button className={style.createBtn} onClick={() => filterUpdateFuc()}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Filter;
