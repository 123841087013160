import React,{useState} from "react";
import Header from "../../../../../commonComponent/Header";
 import SideBar from "../../../../../commonComponent/SideBar";
import Preview from "./component/Preview";
import style from "./confirmation.module.css"
import SuccessModal from "../../../../component/Modal/SuccessModal";
const Confirmation = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <div>
    <Header />
    <div className={style.mainSection}>
      <SideBar />
    <Preview/>
    </div>
    <SuccessModal show={show} handleClose={handleClose}/>
  </div>
  )
}


export default Confirmation