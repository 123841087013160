import React from "react";

import Modal from "react-responsive-modal";
import style from "./Filter.module.css";
import "./style.css";

function filterModal({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  firstCardlist,
  secondCardlist,
  thirdCard,
  thirdCardlist,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
  forthCard,
  forthCardlist,
  fiveCardlist,
  fiveCard,
  SixCardlist,
  SixCard,
  sevenCard,
  sevenCardlist,
}) {
  const handleCheckBox = (e, status) => {
    switch (status) {
      case firstCard:
        let data = filterData;
        if (data.status.includes(Number(e.target.value))) {
          let index = data.status.indexOf(Number(e.target.value));
          data.status.splice(index, 1);
          return setFilterData({ ...filterData, status: data.status });
        } else {
          data.status.push(Number(e.target.value));
          return setFilterData({ ...filterData, status: data.status });
        }
      case secondCard:
        let data1 = filterData;
        if (data1.allocated.includes(Number(e.target.value))) {
          let index = data1.allocated.indexOf(Number(e.target.value));
          data1.allocated.splice(index, 1);
          return setFilterData({ ...filterData, allocated: data1.allocated });
        } else {
          data1.allocated.push(Number(e.target.value));
          return setFilterData({ ...filterData, allocated: data1.allocated });
        }
      case thirdCard:
        let data3 = filterData;
        if (data3.employeeType.includes(Number(e.target.value))) {
          let index = data3.employeeType.indexOf(Number(e.target.value));
          data3?.employeeType?.splice(index, 1);
          return setFilterData({
            ...filterData,
            employeeType: data3.employeeType,
          });
        } else {
          data3.employeeType.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            employeeType: data3.employeeType,
          });
        }
      case forthCard:
        let data4 = filterData;
        if (data4.salaryRange.includes(Number(e.target.value))) {
          let index = data4.salaryRange.indexOf(Number(e.target.value));
          data4?.salaryRange?.splice(index, 1);
          return setFilterData({
            ...filterData,
            salaryRange: data4.salaryRange,
          });
        } else {
          data4.salaryRange.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            salaryRange: data4.salaryRange,
          });
        }
      case fiveCard:
        let data5 = filterData;
        if (data5?.functionalArea?.includes(Number(e.target.value))) {
          let index = data5.functionalArea.indexOf(Number(e.target.value));
          data5?.functionalArea?.splice(index, 1);
          return setFilterData({
            ...filterData,
            functionalArea: data5.functionalArea,
          });
        } else {
          data5?.functionalArea?.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            functionalArea: data5.functionalArea,
          });
        }
      case SixCard:
        let data6 = filterData;
        if (data6?.leadId?.includes(Number(e.target.value))) {
          let index = data6.leadId.indexOf(Number(e.target.value));
          data6?.leadId?.splice(index, 1);
          return setFilterData({
            ...filterData,
            leadId: data6.leadId,
          });
        } else {
          data6?.leadId?.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            leadId: data6.leadId,
          });
        }
      case sevenCard:
        let data7 = filterData;
        if (data7?.clientId?.includes(Number(e.target.value))) {
          let index = data7.clientId.indexOf(Number(e.target.value));
          data7?.clientId?.splice(index, 1);
          return setFilterData({
            ...filterData,
            clientId: data7.clientId,
          });
        } else {
          data7?.clientId?.push(Number(e.target.value));
          return setFilterData({
            ...filterData,
            clientId: data7.clientId,
          });
        }
      default:
        return;
    }
  };

  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.filterModal}>
          <div className={style.filterCards}>
            {secondCard && secondCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{secondCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {secondCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={item.value}
                          value={item.value}
                          checked={filterData?.allocated?.includes(item.value)}
                          onChange={(e) => handleCheckBox(e, secondCard)}
                        />
                        <label
                          htmlFor={item.value}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {fiveCard && fiveCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{fiveCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {fiveCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`F${key}`}
                          value={Number(item.value)}
                          checked={filterData?.functionalArea?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, fiveCard)}
                        />
                        <label
                          htmlFor={`F${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {SixCard && SixCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{SixCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {SixCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`L${key}`}
                          value={Number(item.value)}
                          checked={filterData?.leadId?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, SixCard)}
                        />
                        <label
                          htmlFor={`L${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {sevenCard && sevenCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{sevenCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {sevenCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`CI${key}`}
                          value={Number(item.value)}
                          checked={filterData?.clientId?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, sevenCard)}
                        />
                        <label
                          htmlFor={`CI${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {thirdCard && thirdCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{thirdCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {thirdCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`E${key}`}
                          value={Number(item.value)}
                          checked={filterData?.employeeType?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, thirdCard)}
                        />
                        <label
                          htmlFor={`E${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {forthCard && forthCardlist && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{forthCard}</div>
                <div
                  style={{
                    height: "15rem",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  {forthCardlist?.map((item, key) => {
                    return (
                      <div className={style.list} key={key}>
                        <input
                          type="checkbox"
                          className={style.vh}
                          id={`S${key}`}
                          value={Number(item.value)}
                          checked={filterData?.salaryRange?.includes(
                            Number(item.value)
                          )}
                          onChange={(e) => handleCheckBox(e, forthCard)}
                        />
                        <label
                          htmlFor={`S${key}`}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className={style.label}
                        >
                          {item.label}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {firstCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>{firstCard}</div>
                {firstCardlist?.map((item, key) => {
                  return (
                    <div className={style.list} key={key}>
                      <input
                        type="checkbox"
                        className={style.vh}
                        id={item}
                        value={key + 1}
                        checked={filterData?.status?.includes(key + 1)}
                        onChange={(e) => handleCheckBox(e, firstCard)}
                      />
                      <label
                        htmlFor={item}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className={style.label}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
            }}
          >
            Clear All
          </button>
          <button className={style.createBtn} onClick={() => filterUpdateFuc()}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
}

export default filterModal;
