import React, { useState } from "react";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import style from "./SideBar.module.css";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import ReportIcon from "@mui/icons-material/Report";
import InnovationMLogo from "../../../../assets/Grievance portal/image-innovationm-logo@2x.png";

import cookie from "react-cookies";

const Sidebar = () => {
  const location = useLocation();
  const [toggle, setToggle] = useState(false);
  let history = useHistory();
  const profile = cookie.load("profile");

  const hideShow = () => {
    setToggle(!toggle);
  };
  return (
    <div className={style.side_bar}>
      {/* Project and Resource Heading */}
      <div className={style.header}>
        <img
          alt="DashBoardImage"
          src={InnovationMLogo}
          className={style.dashboardImage}
          onClick={() => history.push("/dashboard")}
        />
      </div>
      <div className={style.project_heading}>
        <div className={style.prmheading}>
          <span className={style.prmheading}>
            Resource Management <br /> System ( RMS )
          </span>
        </div>
      </div>

      {/* Sidebar buttons */}
      <div className={style.side_buttons_div}>
        <Link to="/ResourceMaster" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/ResourceMaster"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GridViewRoundedIcon className={style.icon} />}
            <div className={style.heading}>Dashboard</div>
          </div>
        </Link>

        <Link to="/clients" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/clients" ||
              location.pathname === "/clientdescription" ||
              location.pathname === "/createClient" ||
              location.pathname === "/EditClient"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<PersonIcon className={style.icon} />}
            <div className={style.heading}>Clients</div>
          </div>
        </Link>
        <Link to="/ProjectDetails" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/projects" ||
              location.pathname === "/resource" ||
              location.pathname === "/ProjectDetails" ||
              location.pathname === "/EditProject" ||
              location.pathname === "/availableResources" ||
              location.pathname === "/AllocateResources" ||
              location.pathname === "/Projectdescription"
                ? style.active_btn
                : style.btn_menu
            }
          >
            <ContentPasteIcon className={style.icon} />

            <div className={style.heading}>Projects</div>
          </div>
        </Link>

        <div>
          <Link to="/resourceManagement" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/resourceManagement" ||
                location.pathname === "/ResourcesOffshoreDescription" ||
                location.pathname === "/EditResourcesOffshore" ||
                location.pathname === "/AddResourcesOffshore" ||
                location.pathname === "/OffshoreChangeHistory" ||
                location.pathname === "/OffShore/TaskDetails" ||
                location.pathname === "/Offshore/TaskEdit"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<GroupsIcon className={style.icon} />}
              <div className={style.heading}>Resources (Offshore)</div>
            </div>
          </Link>
          <Link
            to="/ResourcesAllocationOnsite"
            style={{ textDecoration: "none" }}
          >
            <div
              className={
                location.pathname === "/ResourcesAllocationOnsite" ||
                location.pathname === "/AllocationOnsiteDescription" ||
                location.pathname === "/EditResourcesAllocationOnsite" ||
                location.pathname === "/AddResourcesAllocationOnsite" ||
                location.pathname === "/OnSiteChangeHistory" ||
                location.pathname === "/OnSite/TaskDetails" ||
                location.pathname === "/Onsite/TaskEdit"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<GroupsIcon className={style.icon} />}
              <div className={style.heading}>Resources (Onsite)</div>
            </div>
          </Link>
          <Link to="/PoolResources" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/PoolResources" ||
                location.pathname === "/EditPoolResource" ||
                location.pathname === "/PoolResourcesDescription" ||
                location.pathname === "/AddPollResources" ||
                location.pathname === "/PoolResourcesHistory" ||
                location.pathname === "/Pool/TaskDetails" ||
                location.pathname === "/Pool/TaskEdit"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<GroupsIcon className={style.icon} />}
              <div className={style.heading}>Pool Resources</div>
            </div>
          </Link>
          <Link to="/OffShoreRecord" style={{ textDecoration: "none" }}>
            <div
              className={
                location.pathname === "/OffShoreRecord" ||
                location.pathname === "/OnSiteRecord" ||
                location.pathname === "/PoolRecord"
                  ? style.active_btn
                  : style.btn_menu
              }
            >
              {<ReportIcon className={style.icon} />}
              <div className={style.heading}>Report</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
