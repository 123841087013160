import { useEffect, useState } from "react";
import style from "./GenerateLink.module.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import cookie from "react-cookies";
const GeneRateLink = () => {
  const url = process.env.REACT_APP_QUESTIONNAIRE_URL;
  const [question, setQuestion] = useState();
  const [error, setError] = useState("");

  const location = useLocation();
  let token =
    location?.pathname?.split("/")[location?.pathname?.split("/")?.length - 1];
  const { state } = location;
  const questionDetails = () => {
    let checkedBoxes = state?.checkedBoxes ?? [];
    let clientNameSelect = state?.clientNameSelect ?? false;

    axios
      .get(
        url +
          `/generate-link?questionnaireIds=${checkedBoxes}&shareClientId=${clientNameSelect}&token=${token}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setQuestion(res.data.responseData);
        setError(res?.data?.responseData?.message);
      })
      .catch((err) => {
        setError("Something went wrong");
      });
  };
  useEffect(() => {
    questionDetails();
  }, []);
  return (
    <>
      {/* <Header/> */}
      <div className={style.main}>
        {question?.questions?.length > 0 ? (
          <>
            <div className={style.clientName}>
              {question?.clientName && (
                <h6 style={{ marginTop: "13px" }}>
                  Client Name : {question?.clientName}
                </h6>
              )}
              <h6 style={{ marginTop: "13px" }}>
                Technology : {question?.technology}
              </h6>
            </div>
            {question?.instructions ? (
              <div className={style?.generalComments}>
                <h6 style={{ paddingLeft: "3rem" }}>Specific Instructions</h6>
                <p style={{ paddingLeft: "3rem", whiteSpace: "pre-line" }}>
                  {question?.instructions}
                </p>
              </div>
            ) : null}
            <div className={style?.questions}>
              <h6 style={{ paddingLeft: "3rem" }}>Questions</h6>
              <p style={{ paddingLeft: "3rem", whiteSpace: "pre-line" }}>
                {question?.questions?.map((val) => val)}
              </p>
            </div>
          </>
        ) : (
          <div className={style.error}>{error}</div>
        )}
      </div>
    </>
  );
};
export default GeneRateLink;
