import React, { useState } from "react";
import cx from "classnames";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import TabletIcon from '@mui/icons-material/Tablet';
import "react-datepicker/dist/react-datepicker.css";
import styles from "./others.module.css"
const Others = () => {
  return (
    <div>
            <Container>
            <p style={{display:"flex" }}>
            <TabletIcon color="disabled" fontSize="large" />
                <span className={cx(styles.font116,styles.sectionHeading, styles.fontMedium, styles.colorBlack, styles.labelOpacity, styles.heading)}>Others</span>
              </p>
              <div style={{display:"flex"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}> S.No.</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Device Name</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Brand</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
              </div>

              <div style={{display:"flex",marginTop:"2%"}}>
                <div >
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Model</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Status</label> <br />
                  <select className={styles.input}></select>
                </div>
                <div className={styles.values}>
                  <label className={cx(styles.font12, styles.fontMedium, styles.colorBlack, styles.labelOpacity)}>Active</label> <br />
                  <input type="text"   className={styles.input}></input>
                </div>
              </div>
        
              <hr className={styles.section}/>


              </Container>
              </div>
  )
}

export default Others