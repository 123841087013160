import React from "react";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "../laptop/laptop.module.css";
import IssueLaptopComp from "./component/IssueLaptopComp";

const IssueLaptop = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <IssueLaptopComp />
      </div>
    </div>
  );
};

export default IssueLaptop;
