import axios from "axios";
import cookie from "react-cookies";
const apiKey = process.env.REACT_APP_EmployeeInduction_URL;
const apiKey1 = process.env.REACT_APP_UMS_APP_URL;
let token = cookie.load("token");
axios.defaults.headers.common["Authorization"] = token;
axios.defaults.headers.post["Content-Type"] = "application/json";
export const EmployeeInduction = async (id) => {
  const response = await axios.get(
    `${apiKey}/getEmployeeInductionById?empId=${id}`
  );
  return response.data.responseData;
};
export const getEmployeeInduction = async (id) => {
  const response = await axios.get(`${apiKey}/getEmployeeInduction/${id}`);
  return response.data.responseData;
};

export const getEmployeeListDownload = async () => {
  const response = await axios.get(`${apiKey}/getEmployeeListDownload`);
  return response.data.responseData;
};

export const getStatus = async (data) => {
  const response = await axios.get(`${apiKey}/getStatus`, data);
  return response.data.responseData;
};
export const getFeedback = async (data) => {
  const response = await axios.get(`${apiKey}/getProgress`, data);
  return response.data.responseData;
};
export const UpdateInduction = async (data) => {
  const response = await axios.post(`${apiKey}/updateInduction`, data);
  return response.data.responseData;
};
export const CreateInduction = async (data) => {
  const response = await axios.post(`${apiKey}/createinduction`, data);
  return response.data.responseData;
};
export const CreateActivity = async (data) => {
  const response = await axios.post(`${apiKey}/updateactivity`, data);
  return response.data.responseData;
};
export const CreateJoiningStatus = async (data) => {
  const response = await axios.post(`${apiKey}/updatejoiningkit`, data);
  return response.data.responseData;
};
export const employeeList = async (
  limit,
  page,
  empIdFilter,
  inductionStatusFilter
) => {
  if (!empIdFilter && !inductionStatusFilter) {
    console.log(inductionStatusFilter, "data");
    const response = await axios.get(
      `${apiKey}/getListOfEmployee?limit=${limit}&page=${page}`
    );
    return response.data.responseData;
  } else if (empIdFilter) {
    const response = await axios.get(
      `${apiKey}/getListOfEmployee?department=${empIdFilter}&limit=${limit}&page=${page}`
    );
    return response.data.responseData;
  } else if (inductionStatusFilter) {
    const response = await axios.get(
      `${apiKey}/getListOfEmployee?overallStatus=${inductionStatusFilter}&limit=${limit}&page=${page}`
    );
    return response.data.responseData;
  } else {
    const response = await axios.get(
      `${apiKey}/getListOfEmployee?department=${empIdFilter}&overallStatus=${inductionStatusFilter}&limit=${limit}&page=${page}`
    );
    return response.data.responseData;
  }
};
export const filterEmp = async () => {
  const response = await axios.get(`${apiKey}/getemployeename`);
  return response.data.responseData;
};

export const department = async () => {
  const response = await axios.get(`${apiKey1}/getAllFunctionalArea`);
  return response.data.responseData;
};