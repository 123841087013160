import moment from "moment";

export const validateEmail = (email) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const CurrentToPreviousTwoMonths = () => {
  // Get the current date
  const currentDate = new Date();

  // Create an array to store the objects
  const monthsArray = [];

  // Loop through the previous two months
  for (let i = 2; i >= 0; i--) {
    // Get the month and year for the current iteration
    const month = currentDate.getMonth() - i;
    const year = currentDate.getFullYear();

    // Create a new date object for the start of the month
    const startOfMonth = new Date(year, month, 1);

    // Get the month name
    const monthName = startOfMonth.toLocaleString("default", { month: "long" });

    // Create a new date object for the end of the month
    // const endOfMonth = new Date(year, month + 1, 0);

    // Create an object representing the month
    const monthObject = {
      label: i === 0 ? `${monthName} - Current Month` : monthName,
      value: startOfMonth,
      isMonth: true,
    };

    // Add the object to the array
    monthsArray.push(monthObject);
  }

  // Reverse the array
  monthsArray.reverse();

  // Print the array of objects
  return monthsArray;
};
export const handleFourWeek = () => {
  const currentDate = new Date();
  let weeks = [];
  let today = moment(currentDate).format("DD");
  for (let i = 0; i < 4; i++) {
    const startDate = new Date(
      currentDate.getTime() - i * 7 * 24 * 60 * 60 * 1000
    );
    const startDayOfWeek = startDate.getDay(); // Get the current day of the week
    const offset = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1; // Calculate the offset based on the current day
    startDate.setDate(startDate.getDate() - offset); // Adjust the start date using the offset
    startDate.setHours(0, 0, 0, 0); // Set start time to 00:00:00
    const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
    endDate.setHours(23, 59, 59, 999);
    let status = false;
    let data = today - moment(startDate.toISOString()).format("DD");
    if (data >= 0 && data <= 6) {
      status = true;
    }
    let newData = "";
    weeks = [
      ...weeks,
      {
        value: `${startDate.toISOString()}`,
        label: `${moment(startDate.toISOString()).format("DD")} -  
              ${moment(endDate.toISOString()).format("DD")} 
              ${moment(endDate.toISOString()).format("MMM")} ${
          status ? newData : ""
        }`,
        isMonth: false,
      },
    ];
  }
  return weeks;
};

export const handleCurrentPreviousTwoMonths = () => {
  // Get the current date
  var currentDate = new Date();

  // Create an array to store the JSON objects
  var data = [];

  // Get the current month
  var currentMonth = currentDate.getMonth(); // Months are zero-based

  // Generate JSON objects for the current month and the two previous months
  for (var i = 2; i >= 0; i--) {
    var month = currentMonth - i;
    var year = currentDate.getFullYear();

    // Adjust the year if the month is less than 0 (January)
    if (month < 0) {
      month = 12 + month;
      year--;
    }

    // Create a date object to get the month name
    var dateObj = new Date(year, month, 1);

    // Get the month name
    var monthName = dateObj.toLocaleString("en-US", { month: "long" });

    // Create the JSON object
    var jsonObject = {
      month: monthName,
      year: year,
    };

    // Add the object to the array
    data.push(jsonObject);
  }

  // Reverse the order of the array
  data.reverse();
  return data;
};
export const monthsData = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const getPrviousYear = () => {
  const currentYear = new Date().getFullYear();

  // Calculate the previous year
  const previousYear = currentYear - 1;

  // Create an array with the current year and previous year
  const yearsArray = [currentYear, previousYear];
  return yearsArray;
};

export const urlValidation = (url) => {
  let pattern = /^(http|https):\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,}(\/\S*)?$/;
  if (pattern.test(url)) {
    return true;
  } else {
    return false;
  }
};
