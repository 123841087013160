import React from "react";
import Moment from "moment";
import { useHistory} from "react-router-dom";


const TableRow = (props) => {
  const history = useHistory();
  const GoToDetails=()=>{
    history.push({
      pathname: '/AllocationOnsiteDescription',
      state: {
        id:props.id,
        currentPage:props?.currentPage,
        allData:props.allData,
        applied:props.applied,
        filterName:props.filterName,
        filterEmp:props.filterEmp
      }
    });
  }
  const getBackgroundColor = (status) => {
    if (status === "Active") {
      return "green";
    }
    return "red";
  };

  return (
    <tr
      style={{
        borderBottom: "2px solid #F0F0F0",
        textAlign: "start",
        fontSize:"13px"
     
      }}
    >
    <td>{props.empCode}</td>
        <td
        onClick={GoToDetails}
          style={{
            cursor:"pointer",
            padding: "10px 0px",
            textTransform: "capitalize",
            fontWeight: "bolder",
            textDecorationLine:"underline",
        
          }}
        >{props.clientName}
        </td>
      
      <td>{props.employeeName}</td>
      {props.experience?
      <td>
        {~~(props.experience / 12)} Years {props.experience % 12} Months
      </td>: <td >
      0 Years 0 Months
      </td>}
      <td >{props.salaryRange}</td>
      <td >{props.projectSkill}</td>
      <td style={{color: getBackgroundColor(props.statusTypeName)}}>{props.statusTypeName==="In-Active"?"Inactive":props.statusTypeName}</td>
      <td>
        {Moment(props.startDate).format("DD MMM YY")}
      </td>
      {props.EndDate ? (
        <td>
          {Moment(props.EndDate).format("DD MMM YY")}
        </td>
      ) : (
        <td>{props.EndDate}</td>
      )}
{ props?.lastCommentDate?
      <td>
        {Moment(props.lastCommentDate).format("DD MMM YY")}
      </td>
      :
      <td></td>
}
      <td style={{width:"0.5%"}}>{ props?.resignStatus? <span style={{fontSize:"11.5px", color:"red", fontWeight:"900"}}>R</span>:null} 
    </td>
    </tr>
  );
};

export default TableRow;