import React from "react";
import style from './tasktracker.module.css';

import Dashboard from "./dashboard/dashboard";
import { useHistory } from "react-router-dom";
import Header from './commonComponent/Header';
import SideBar from "./commonComponent/SideBar";

export default function ItAssetsMain() {
  const history = useHistory();

  return (
    <div>
      <Header />

      <div className={style.mainSection}>
        <SideBar />

        <div className={style.rightSection}>
          <Dashboard />
        </div>
      </div>
    </div>
  );
}

