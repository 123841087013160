import React, {useState } from "react";
import Header from "../header/Header";
import SideBar from "../sidebar/SideBar";
import style from "./ETSNavigation.module.css";
import ProgressBar from "../progressbar/ProgressBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmployeeTimeSheet from "../pageLayout/EmployeeTimeSheet/EmployeeTimeSheet";
import AddTaskDetail from "../pageLayout/AddTaskDetail/AddTaskDetail";
import TeamTimeSheet from "../pageLayout/TeamTimeSheet/TeamTimeSheet";
import ViewTaskDetails from "../pageLayout/ViewTaskDetails/ViewTaskDetails";
import ViewTeamTimeSheet from "../pageLayout/ViewTeamTimeSheet/ViewTeamTimeSheet";
import EditEmployeeTimeSheet from "../pageLayout/EditEmployeeTimeSheet/EditEmployeeTimeSheet";
import ViewTimeSheetById from "../pageLayout/ViewTimeSheetById/ViewTimeSheetById";
import EditViewTaskSheet from "../pageLayout/EditEmployeeTimeSheet/EditViewTaskSheet";
import FillTeamTimeSheet from "../pageLayout/FillTeamTimeSheet/FillTeamTimeSheet";
export default function ETSNavigation() {
  const [nextScreen, setNextScreen] = useState("empTimeSheet");
  const [currentPage, setCurrentPage] = useState(1);
  const [roles, setRoles] = useState([]);
  const [prevData, setPrevData] = useState({
    page: "empTimeSheet",
    sidebar: "empTimeSheet",
    page1: "empTimeSheet",
    active: "empTimeSheet",
    Data: "",
    empID:""
  })
  const sidebarHandler = () => {
    switch (nextScreen) {
      case "empTimeSheet":
        return (
          <EmployeeTimeSheet
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "AddTaskDetails":
        return (
          <AddTaskDetail
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "TeamTimeSheets":
        return (
          <TeamTimeSheet
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewTask":
        return (
          <ViewTaskDetails
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      case "ViewTeamTimeSheets":
        return (
          <ViewTeamTimeSheet
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "EditEmployeeTimeSheets":
        return (
          <EditEmployeeTimeSheet
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "ViewTimeSheetByIds":
        return (
          <ViewTimeSheetById
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "EditViewTaskSheets":
        return (
          <EditViewTaskSheet
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "FillTeamTimeSheets":
        return (
          <FillTeamTimeSheet
            setNextScreen={setNextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );

      default:
    }
  };

  const setCurrScreen = (val) => {
    setNextScreen(val);
  };

  return (
    <div className={style.ATSNav}>
      <Header />
      <div className={style.mainSection}>
        <div style={{ width: "20%" }}>
          <SideBar
            sidebarHandler={setCurrScreen}
            nextScreen={nextScreen}
            setPrevData={setPrevData}
            prevData={prevData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            roles={roles}
            setNextScreen={setNextScreen}
          />
        </div>
        <div className={style.ATScontainer}>
          <ProgressBar prevData={prevData} />
          {sidebarHandler()}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
