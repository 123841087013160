import React from "react";
import style from "./AllocateResources.module.css";
import Header from "../../../../taskTracker/commonComponent/Header";
import Sidebar from "../../../Clients/component/SideBar";
import Select from "./Select";
// import MultiSelectDemo from "./MultiSelectDemo";

const AllocateResources = () => {
  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <Sidebar />
        <div className={style.table_div} style={{overflowY:"scroll"}}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              <span>
                Project <span className={style.arrow}> {">"} </span> Create
                Project <span className={style.arrow}> {">"} </span>
                Available Resources
                <span className={style.arrow2}> {">"} </span>
                <span className={style.recent_tab}>Allocate Resources</span>
              </span>
            </div>

            <div className={style.second_child}>
              <div style={{ fontWeight: "500" }}>
                Allocate Resources
              </div>
            </div>
          </div>

          <div className={style.description_box}>
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <h6>Employee Name</h6>
                  <p style={{ fontSize: "13px" }}>User Name</p>
                </div>
                <div className="col-7">
                  <h6>Project Name</h6>
                  <p style={{ fontSize: "13px" }}>IM Portal Apps</p>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <h6>Project Skill</h6>
                  <p style={{ fontSize: "13px" }}>
                    <Select />
                  </p>
                </div>
                <div className="col-7">
                  <h6>Allocation</h6>
                  <p style={{ fontSize: "13px" }}>
                    <Select />
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div className={style.description_box}>
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <h6>Employee Name</h6>
                  <p style={{ fontSize: "13px" }}>User Name</p>
                </div>
                <div className="col-7">
                  <h6>Project Name</h6>
                  <p style={{ fontSize: "13px" }}>IM Portal Apps</p>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <h6>Project Skill</h6>
                  <p style={{ fontSize: "13px" }}>
                    <Select />
                  </p>
                </div>
                <div className="col-7">
                  <h6>Allocation</h6>
                  <p style={{ fontSize: "13px" }}>
                    <Select />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={style.description_box}>
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <h6>Employee Name</h6>
                  <p style={{ fontSize: "13px" }}>User Name</p>
                </div>
                <div className="col-7">
                  <h6>Project Name</h6>
                  <p style={{ fontSize: "13px" }}>IM Portal Apps</p>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <h6>Project Skill</h6>
                  <p style={{ fontSize: "13px" }}>
                    <Select />
                  </p>
                </div>
                <div className="col-7">
                  <h6>Allocation</h6>
                  <p style={{ fontSize: "13px" }}>
                    <Select />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllocateResources;
