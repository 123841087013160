import React, { useEffect, useState } from "react";
import style from "./UsersList.module.css";
import SideBar from "../../sidebar/SideBar";
import { Link, useHistory, useLocation } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExcelFileDownload from "../ExcelFileDownload/ExcelFileDownload";
import {
  getAllUsersWithFilter,
  getAllTechnologyforFilter,
  getAllType,
  getSalaryRange,
  getAllFunctionalArea,
  getAllLeads,
  getClientList,
} from "../../../../Utils/axios/umsApi";
import moment from "moment";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import loaderImg from "../../../../assets/umsLoader.gif";
// import Pagination from "../commonComponent/Pagination";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import FilterModal from "../../filter/FilterModal";
import { useQuery } from "react-query";
import UmsCustomTooltip from "../../umsCustomTooltips/UmsCustomTooltip";
import ShowStatus from "../../showStatus/ShowStatus";
function UsersList() {
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState(
    location?.state?.currentPage ? location?.state?.currentPage : 1
  );
  const [pageCount, setpageCount] = useState(0);
  const [userList, setUserList] = useState([]);
  const [totalCount, settotaluserCount] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [allCount, setAllCount] = useState(0);

  const [filterData, setFilterData] = useState(
    location?.state?.filterData
      ? location?.state?.filterData
      : {
          status: [],
          allocated: [],
          employeeType: [],
          salaryRange: [],
          functionalArea: [],
          leadId: [],
          clientId: [],
        }
  );
  const [filter, setFilter] = useState(
    location?.state?.filter
      ? location?.state?.filter
      : {
          employeeCode: "",
          nameEmail: "",
          status: [],
          technology: [],
          employeeType: [],
          salaryRange: [],
          functionalArea: [],
          leadId: [],
          clientId: [],
        }
  );
  const limit = 20;

  const { data, isLoading } = useQuery(
    ["getAllUsersWithFilter", { filter, currentPage }],
    () => getAllUsersWithFilter(limit, currentPage, filter)
  );
  useEffect(() => {
    setUserList(data?.employeeResponseModels);
    settotaluserCount(data?.allCount);
    setIsLoaded(false);
    setpageCount();
    setpageCount(~~(data?.allCount / 20 + 1));
    setAllCount(data?.allCount);
  }, [data]);
  const { data: getCoreSkillData } = useQuery(
    ["getAllTechnologyforFilter"],
    getAllTechnologyforFilter
  );
  const getTechnologyList = getCoreSkillData?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const { data: getAllTypeData } = useQuery(["getAllType"], getAllType);
  const getAllTypeOption = getAllTypeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const { data: getAllLeadsData } = useQuery(["getAllLeads"], getAllLeads);
  const getAllLeadsOption = getAllLeadsData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const { data: getSalaryRangeData } = useQuery(
    ["getSalaryRange"],
    getSalaryRange
  );
  const { data: getAllFunctionalData } = useQuery(
    ["getAllFunctionalArea"],
    getAllFunctionalArea
  );
  const getSalaryRangeOption = getSalaryRangeData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const getFunctionalOption = getAllFunctionalData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));
  const { data: getClientData } = useQuery(["getClientList"], getClientList, {
    refetchOnWindowFocus: false,
  });
  const clientOption = getClientData?.map((item) => {
    return {
      value: item?.id,
      label: item?.name,
    };
  });

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "emailName":
          setCurrentPage(1);
          setFilter({ ...filter, nameEmail: e.target.value });
          break;
        case "codeTech":
          setCurrentPage(1);
          setFilter({ ...filter, employeeCode: e.target.value });
          break;
        default:
      }
    } else {
    }
  };
  const filterUpdateFuc = () => {
    setCurrentPage(1);
    setFilter((prev) => ({
      ...prev,
      status: [...filterData?.status],
      technology: [...filterData?.allocated],
      employeeType: [...filterData?.employeeType],
      salaryRange: [...filterData?.salaryRange],
      functionalArea: [...filterData?.functionalArea],
      leadId: [...filterData?.leadId],
      clientId: [...filterData?.clientId],
    }));
    setShowFilterModal(false);
  };
  const filterClearAllFuc = () => {
    setFilter((prev) => ({
      ...prev,
      status: [],
      technology: [],
      employeeType: [],
      salaryRange: [],
      functionalArea: [],
      leadId: [],
      clientId: [],
    }));
    setFilterData((prev) => ({
      ...prev,
      status: [],
      allocated: [],
      employeeType: [],
      functionalArea: [],
      salaryRange: [],
      leadId: [],
      clientId: [],
    }));
    setShowFilterModal(false);
  };
  const history = useHistory();
  const handleRedirect = (id) => {
    setTimeout(() => {
      history.push("/userDescription", { id, filterData, currentPage, filter });
    }, 0);
  };
  const ReginationData = (value) => {
    return (
      <>
        <div>Resignation Date</div>
        <div>{moment(value).format("DD MMM YY")}</div>
      </>
    );
  };
  const ExitDate = (value) => {
    return (
      <>
        <div>Exit Date</div>
        <div>{moment(value).format("DD MMM YY")}</div>
      </>
    );
  };
  const BondDate = (value) => {
    return (
      <>
        <div>Bond Date</div>
        <div>{moment(value).format("DD MMM YY")}</div>
      </>
    );
  };
  const RetainDate = (value) => {
    return (
      <>
        <div>Retain Date</div>
        <div>{moment(value).format("DD MMM YY")}</div>
      </>
    );
  };
  const ExitDicisionPlanDate = (decision, planned) => {
    return (
      <>
        <div>Exit Decision Date</div>
        <div>{moment(decision).format("DD MMM YY")}</div>
        <div>Exit Planned Date</div>
        <div>{moment(planned).format("DD MMM YY")}</div>
      </>
    );
  };
  return (
    <>
      {/* <Header />*/}

      <div className={style.main_client_body}>
        <SideBar />
        <div className={style.main_create}>
          <div className={style.table_div_head}>
            <div
              className={style.breadscrumb}
              style={{ padding: "6px 0px" }}
            ></div>
            <div
              className={style.second_child}
              style={{
                display: "flex",
                justifyContent: "inherit",
                width: "100%",
              }}
            >
              <div
                style={{
                  paddingLeft: "28px",
                  width: "7%",
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
              >
                Users
              </div>
              <div style={{ width: "10%" }}>
                <p className={style.count}>
                  <span
                    className={style.backColor1}
                    style={{ fontSize: "1.235rem", fontWeight: "500" }}
                  >
                    {allCount}
                  </span>
                </p>
              </div>
              <div style={{ width: "8%" }}>
                <p className={style.download}>
                  <span
                    className={style.backColor1}
                    style={{
                      fontSize: "2rem",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                  >
                    <ExcelFileDownload filter={filter} />
                  </span>
                </p>
              </div>
              {
                <div style={{ width: "12%" }}>
                  <button
                    className={style.filter_btn}
                    onClick={() => setShowFilterModal(true)}
                    style={
                      filterData?.status?.length > 0 ||
                      filterData?.allocated?.length > 0 ||
                      filterData?.employeeType?.length > 0 ||
                      filterData?.salaryRange?.length > 0 ||
                      filterData?.functionalArea?.length > 0 ||
                      filterData?.leadId?.length > 0 ||
                      filterData?.clientId?.length > 0
                        ? { background: "#289e98", color: "#fff" }
                        : null
                    }
                  >
                    <FilterListIcon style={{ marginRight: "10px" }} />
                    Filter
                  </button>
                </div>
              }
              <div className={style.s1} style={{ width: "22%" }}>
                {filter?.nameEmail === null || filter?.nameEmail === "" ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="searchIcon" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setFilter((prev) => ({
                        ...prev,
                        nameEmail: "",
                      }));
                      setCurrentPage(1);
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}

                <input
                  placeholder=" Name/Email"
                  value={filter?.nameEmail}
                  onChange={(e) => onSearchHandler(e, "emailName")}
                  className={style.coreSkillInput}
                />
              </div>
              <div className={style.s1} style={{ width: "22%" }}>
                {filter?.employeeCode === null ||
                filter?.employeeCode === "" ? (
                  <span className={style.cp_serchIcon}>
                    <img src={searchIcon} alt="searchIcon" />
                  </span>
                ) : (
                  <span
                    className={style.cp_serchIcon}
                    onClick={() => {
                      setFilter((prev) => ({ ...prev, employeeCode: "" }));
                      setCurrentPage(1);
                    }}
                  >
                    <AiOutlineClose />
                  </span>
                )}

                <input
                  placeholder=" Employee Code"
                  value={filter?.employeeCode}
                  onChange={(e) => onSearchHandler(e, "codeTech")}
                  className={style.coreSkillInput}
                />
              </div>
              <div style={{ width: "17%", paddingLeft: "9px" }}>
                <Link to="/CreateUser" style={{ textDecoration: "none" }}>
                  <button className={style.create_btn}>
                    <span className={style.plus}>+</span>
                    <p>Create User</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div>
              <img
                src={loaderImg}
                alt="loader"
                style={{ marginLeft: "46%", marginTop: "8%", width: "12%" }}
              />
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  paddingLeft: "38%",
                  position: "absolute",
                  color: "#289e98",
                  marginTop: "-4px",
                  fontStyle: "italic",
                  marginLeft: "33.5%",
                }}
              >
                Loading...
              </p>
            </div>
          ) : (
            <div className={style.UsersList}>
              <div className={style.td} style={{ backgroundColor: "#F0F0F0" }}>
                <p
                  style={{
                    fontWeight: "700",
                    height: "inherit",
                    position: "sticky",
                    top: 0,
                    left: "0px",
                    zInndex: "500",
                    backgroundColor: "#F0F0F0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Type
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    position: "sticky",
                    top: 0,
                    left: "7rem",
                    zInndex: "1000",
                    backgroundColor: "#F0F0F0",
                    height: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Code
                </p>
                <p
                  style={{
                    fontWeight: "700",
                    position: "sticky",
                    top: 0,
                    left: "13rem",
                    zInndex: "800",
                    backgroundColor: "#F0F0F0",
                    height: "inherit",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Name
                </p>
                <p style={{ fontWeight: "700" }}>
                  Career Start
                  <br /> Date
                </p>
                <p style={{ fontWeight: "700" }}>Experience</p>
                <p style={{ fontWeight: "700" }}>
                  Relevant <br /> Experience
                </p>
                <p style={{ fontWeight: "700" }}>
                  Date of <br />
                  Joining
                </p>
                <p style={{ fontWeight: "700" }}>Technology</p>
                <p style={{ fontWeight: "700" }}>Additional Skill</p>
                <p style={{ fontWeight: "700" }}>Salary Range</p>
                <p style={{ fontWeight: "700" }}>Designation</p>
                <p style={{ fontWeight: "700" }}>Functional Area</p>
                <p style={{ fontWeight: "700" }}> Assigned Lead</p>
                <p style={{ fontWeight: "700" }}> Assigned Client</p>
                <p style={{ fontWeight: "700", color: "#666666" }}>Status</p>
              </div>
              {userList?.length > 0 ? (
                userList?.map((val, key) => {
                  return (
                    <div
                      className={style.td}
                      style={{ position: "relative" }}
                      key={key}
                    >
                      <UmsCustomTooltip
                        value={val.type}
                        limit={15}
                        index={`type${key}`}
                        styles={{
                          position: "sticky",
                          top: 0,
                          left: "0px",
                          zInndex: "1000",
                          backgroundColor: "#fff",
                        }}
                      />
                      <p
                        onClick={() => handleRedirect(val.id)}
                        className={style.backColor}
                        style={{
                          position: "sticky",
                          top: 0,
                          left: "7rem",
                          zInndex: "900",
                          backgroundColor: "#fff",
                        }}
                      >
                        {val.code}
                      </p>
                      <UmsCustomTooltip
                        value={val.name}
                        limit={24}
                        index={`name${key}`}
                        styles={{
                          position: "sticky",
                          top: 0,
                          left: "13rem",
                          zInndex: "800",
                          backgroundColor: "#fff",
                        }}
                      />
                      <p>{moment(val.careerStartDate).format("DD MMM YY")}</p>
                      <p>
                        {~~(val.totalExperience / 12)
                          ? ~~(val.totalExperience / 12)
                          : 0}{" "}
                        Years{" "}
                        {val.totalExperience % 12
                          ? val.totalExperience % 12
                          : 0}{" "}
                        Months
                      </p>
                      <p>
                        {~~(val.relevantExperience / 12)
                          ? ~~(val.relevantExperience / 12)
                          : 0}{" "}
                        Years{" "}
                        {val.relevantExperience % 12
                          ? val.relevantExperience % 12
                          : 0}{" "}
                        Months
                      </p>
                      <p>{moment(val.dateOfJoining).format("DD MMM YY")}</p>
                      <UmsCustomTooltip
                        value={val?.technology}
                        limit={20}
                        index={`tec${key}`}
                        // stylestootip={{ right: "2%" }}
                      />
                      <UmsCustomTooltip
                        value={val?.additionalSkill
                          ?.map((e) => e?.additionalSkillName)
                          .join(", ")}
                        limit={37}
                        index={`tadd${key}`}
                        // stylestootip={{ left: "10%" }}
                      />
                      <p>{val?.salaryRangeName}</p>
                      <UmsCustomTooltip
                        value={val?.employeeDesignation}
                        limit={24}
                        index={`desi${key}`}
                        // stylestootip={{ right: "2%" }}
                      />
                      <UmsCustomTooltip
                        value={val?.functionalAreaName}
                        limit={30}
                        index={`dFsi${key}`}
                        // stylestootip={{ right: "2%" }}
                      />
                      <UmsCustomTooltip
                        value={val?.assignedLead}
                        limit={20}
                        index={`aFsi${key}`}
                        // stylestootip={{ right: "2%" }}
                      />
                      <UmsCustomTooltip
                        value={val?.clientName}
                        limit={23}
                        index={`clientName${key}`}
                        // stylestootip={{ right: "2%" }}
                      />
                      <p>
                        {val.hasOwnProperty("exitDecisionDate") &&
                          val.hasOwnProperty("exitPlanDate") &&
                          val?.exitPlanStatus && (
                            <ShowStatus
                              index={`EP${key}`}
                              component={ExitDicisionPlanDate(
                                val?.exitDecisionDate,
                                val?.exitPlanDate
                              )}
                              circleColor={{ border: "2px solid #289e98" }}
                            >
                              EP
                            </ShowStatus>
                          )}
                        {val.hasOwnProperty("resignationDate") &&
                          val?.resignStatus && (
                            <ShowStatus
                              index={`R${key}`}
                              component={ReginationData(val?.resignationDate)}
                              circleColor={{ border: "2px solid #fb6c65" }}
                            >
                              R
                            </ShowStatus>
                          )}

                        {val.hasOwnProperty("lastWorkingDayDate") &&
                          !val?.active && (
                            <ShowStatus
                              index={`E${key}`}
                              component={ExitDate(val?.lastWorkingDayDate)}
                              circleColor={{ border: "2px solid #c10614" }}
                            >
                              E
                            </ShowStatus>
                          )}
                        {val.bond && (
                          <ShowStatus
                            index={`B${key}`}
                            component={BondDate(val?.bondDate)}
                            circleColor={{ border: "2px solid orange" }}
                          >
                            B
                          </ShowStatus>
                        )}
                        {val.retain && (
                          <ShowStatus
                            index={`RT${key}`}
                            component={RetainDate(val?.retainDate)}
                            circleColor={{ border: "2px solid #ADD8E6" }}
                          >
                            RT
                          </ShowStatus>
                        )}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div>
                  <p
                    style={{
                      fontSize: "1rem",
                      paddingLeft: "45%",
                      color: "#289e98",
                      paddingTop: "1rem",
                    }}
                  >
                    No Users
                  </p>
                </div>
              )}
            </div>
          )}
          {!isLoading && totalCount > limit && pageCount > 0 && !isLoaded && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginRight: "29px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={20}
                onPageChange={handlePageChange}
                currentPage={currentPage}
                color="#289e98"
                color2="white"
              />
            </div>
          )}
          <FilterModal
            showFilter={showFilterModal}
            setShowFilter={setShowFilterModal}
            firstCard="Status"
            firstCardlist={["Exit", "Resigned", "Exit Plan", "Retain", "Bond"]}
            filterUpdateFuc={filterUpdateFuc}
            filterClearAllFuc={filterClearAllFuc}
            setFilterData={setFilterData}
            filterData={filterData}
            secondCard="Technology"
            secondCardlist={getTechnologyList}
            thirdCard="Employee Type"
            thirdCardlist={getAllTypeOption}
            forthCard="Salary Range"
            forthCardlist={getSalaryRangeOption}
            fiveCard="Functional Area"
            fiveCardlist={getFunctionalOption}
            SixCard="Lead"
            SixCardlist={getAllLeadsOption}
            sevenCard="Client"
            sevenCardlist={clientOption}
          />
        </div>
      </div>
    </>
  );
}

export default UsersList;
