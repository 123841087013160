import React from "react";
import style from "./ProgressBar.module.css";

function ProgressBar({ prevData }) {
  
  return (
    <>
      {prevData?.sidebar === "currentCandidateAllocation" &&
      prevData?.page2 === "nonClientCandidateList" ? (
        <div className={style.progressive}>
          <div className={prevData.setnav >= 1 ? style.pSelected : style.p}>
            <p
              className={prevData.setnav >= 1 ? style.pnumSelected : style.pnum}
            >
              1
            </p>
            <p>Candidate List</p>
          </div>
          <span
            className={
              prevData.setnav >= 1 ? style.dividercolor : style.divider
            }
          ></span>
          <div className={prevData.setnav >= 2 ? style.pSelected : style.p}>
            <p
              className={prevData.setnav >= 2 ? style.pnumSelected : style.pnum}
            >
              2
            </p>
            <p>View Candidate</p>
          </div>
          <span
            className={
              prevData.setnav >= 2 ? style.dividercolor : style.divider
            }
          ></span>
          <div className={prevData.setnav >= 3 ? style.pSelected : style.p}>
            <p
              className={prevData.setnav >= 3 ? style.pnumSelected : style.pnum}
            >
              3
            </p>
            <p>Choose Position</p>
          </div>
          <span
            className={
              prevData.setnav >= 3 ? style.dividercolor : style.divider
            }
          ></span>
          <div className={prevData.setnav >= 4 ? style.pSelected : style.p}>
            <p
              className={prevData.setnav >= 4 ? style.pnumSelected : style.pnum}
            >
              4
            </p>
            <p>Confirmation</p>
          </div>
        </div>
      ) : null}
      {prevData?.sidebar == "candidateAllocation(Client)" &&
      prevData?.ListOfProfileInClient == "ListOfProfileInClient" ? (
        <div className={style.progressive}>
          <div className={prevData.setCac >= 1 ? style.pSelected : style.p}>
            <p
              className={prevData.setCac >= 1 ? style.pnumSelected : style.pnum}
            >
              1
            </p>
            <p>Candidate List</p>
          </div>
          <span className={style.divider}></span>
          <div className={prevData.setCac >= 2 ? style.pSelected : style.p}>
            <p
              className={prevData.setCac >= 2 ? style.pnumSelected : style.pnum}
            >
              2
            </p>
            <p>View Candidate</p>
          </div>
          <span className={style.divider}></span>
          <div className={prevData.setCac >= 3 ? style.pSelected : style.p}>
            <p
              className={prevData.setCac >= 3 ? style.pnumSelected : style.pnum}
            >
              3
            </p>
            <p>Choose Position</p>
          </div>
          <span className={style.divider}></span>
          <div className={prevData.setCac >= 4 ? style.pSelected : style.p}>
            <p
              className={prevData.setCac >= 4 ? style.pnumSelected : style.pnum}
            >
              4
            </p>
            <p>Confirmation</p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ProgressBar;
