import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../Agreement/Agreement.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import Select from "react-select";
import { InvoiceStyles } from "../../utils/style";
import { FaTrash } from "react-icons/fa";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton";
import dropdownIcon from "../../../../assets/downIcon.png";
import DatePicker from "react-datepicker";
import PurpleIcon from "../../../../assets/Calander.png";
import cancel from "../../../../assets/cancell.png";
import EditIcon from "../../../../assets/Icon material-edit_.png";

import { useState } from "react";
import moment from "moment";
import CustomTooltip from "../../../PartnerManagement/customTooltip/CustomTooltip.js";

import { useMutation, useQuery } from "react-query";

import {
  getViewPartner,
  addPartnerAgreement,
  ResourceType,
  AgreementIDByData,
  AgreementIDByHistory,
  ViewAgreementID,
  EditPartnerAgreement,
} from "../../../../Utils/axios/PortalManagement";
import BreadCrum from "../../../PartnerManagement/breadcrums/BreadCrum";

const Agreement = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [formData, setFormData] = useState({
    agreementDetails: [],
  });

  console.log("jfjjff", formData);

  const [lineItemArray, setLineItemArray] = useState([1]);
  const [AggrementData, setAggrementData] = useState(false);
  const [EditAgreement, setEditAgreement] = useState(true);
  const [EditId, setEditId] = useState();
  const [EditHistory, setEditHistory] = useState();
  const [EditOpenHistory, setEditOpenHistory] = useState(false);
  const [HideData, setHideData] = useState(false);

  const { data: PartnerDataById } = useQuery(
    ["getViewPartner"],
    () => getViewPartner(prevData?.partnerId),
    {}
  );

  const { data: ViewAgreementIDData } = useQuery(
    ["ViewAgreementID", EditId],
    () => ViewAgreementID(EditId),
    {}
  );

  const [formData1, setFormData1] = useState({
    resource: ViewAgreementIDData?.resource,
    startDate: ViewAgreementIDData?.startDate,
    endDate: ViewAgreementIDData?.endDate,
    rate: ViewAgreementIDData?.rate,
    partnerId: prevData?.partnerId,
    rateTypeId: ViewAgreementIDData?.resourceTypeId,
    agreementId: EditId,
  });

  useEffect(() => {
    setFormData1((prevValue) => ({
      ...prevValue,
      resource: ViewAgreementIDData?.resource,
      startDate: ViewAgreementIDData?.startDate,
      endDate: ViewAgreementIDData?.endDate,
      rate: ViewAgreementIDData?.rate,
      partnerId: prevData?.partnerId,
      rateTypeId: ViewAgreementIDData?.resourceTypeId,
      agreementId: EditId,
    }));
  }, [ViewAgreementIDData]);

  const {
    data: AgreementList,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["AgreementIDByData"],
    () => AgreementIDByData(prevData?.partnerId),
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: AgreementDataTech,
    isLoading1,
    isError1,
  } = useQuery(["AgreementIDByHistory", { EditHistory }], () =>
    AgreementIDByHistory(EditHistory)
  );

  const mutation = useMutation(addPartnerAgreement, {
    onSuccess: (res) => {
      refetch();
      setFormData((prevValue) => ({
        agreementDetails: [
          {
            resource: "",
            startDate: "",
            endDate: "",
            rate: "",
            partnerId: "",
            rateTypeId: "",
          },
        ],
      }));
    },
  });
  const mutation1 = useMutation(EditPartnerAgreement, {
    onSuccess: (res) => {
      refetch();
      setFormData1((prevValue) => ({
        ...prevValue,
        resource: "",
        startDate: "",
        endDate: "",
        rate: "",
        partnerId: "",
        rateTypeId: "",
        agreementId: "",
      }));
    },
  });

  const { data: getAggrementData } = useQuery(["ResourceType"], ResourceType);

  const getAggrementDataoption = getAggrementData?.map((e) => ({
    value: e.id,
    label: e.name,
  }));

  const CustomInput = () => {
    return (
      <span>
        <img
          src={dropdownIcon}
          style={{ position: "absolute", top: "32%", right: "3%" }}
          height="10"
          alt=""
        />
      </span>
    );
  };

  const CustomInputClg = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross = React.forwardRef((props, ref) => {
    const clearData = () => {
      setFormData((prevFormData) => {
        const updatedAgreementDetails = prevFormData.agreementDetails.map(
          (detail, index) => {
            return {
              ...detail,
              [props?.properties]: "", // Clear the specific property value
            };
          }
        );

        return {
          ...prevFormData,
          agreementDetails: updatedAgreementDetails,
        };
      });
    };

    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={clearData}
          alt=""
        />
      </div>
    );
  });

  const CustomInputClg1 = React.forwardRef((props, ref) => {
    return (
      <div onClick={props.onClick}>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={PurpleIcon}
          style={{
            position: "absolute",
            right: "5%",
            top: "13%",
            cursor: "pointer",
          }}
          alt=""
        />
      </div>
    );
  });

  const CustomInputCross1 = React.forwardRef((props, ref) => {
    return (
      <div>
        <label
          ref={ref}
          style={{
            cursor: "pointer",
          }}
        >
          {props.value || props.placeholder}
        </label>
        <img
          src={cancel}
          width="15px"
          height="15px"
          style={{
            position: "absolute",
            right: "5%",
            top: "16%",
            cursor: "pointer",
          }}
          onClick={() =>
            setFormData1({ ...formData1, [props?.properties]: "" })
          }
          alt=""
        />
      </div>
    );
  });

  const HandlerAgreement = () => {
    mutation.mutate(formData);
    setAggrementData(true);
  };

  const HandlerAgreementHistoryID = () => {
    setAggrementData(true);
  };

  const EditAgreementHandler = (id) => {
    setEditId(id);
    setEditAgreement(false);
  };

  const HandlerAgreementHistory = (id) => {
    setEditHistory(id);
    setEditOpenHistory(true);
  };

  const EditHandlerAgreement = () => {
    mutation1.mutate(formData1);
  };

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
        ></BreadCrum>
      </div>
      <Container>
        {/* <Row>
          <Col>
            <h6 style={{ marginLeft: "25px" }}>Agreement</h6>
          </Col>
        </Row> */}
        <InvoiceContainer>
          <Row>
            <Col>
              <h6 className={classes.primaryContact}>Partner Details</h6>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <p className={classes.label}>Partner Name</p>
              <p className={classes.partnerlabal}>
                {PartnerDataById?.partnerName}
              </p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>GSTIN</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.gstin}</p>
            </Col>
            <Col md={3}>
              <p className={classes.label}>Location</p>
              <p className={classes.partnerlabal}>{PartnerDataById?.city}</p>
            </Col>
          </Row>
        </InvoiceContainer>
        {EditAgreement ? (
          <>
            <InvoiceContainer>
              <div className={classes.copy_expence}>
                Add Agreement{" "}
                <span style={{ marginLeft: "10px", float: "right" }}>
                  <InvoiceButton
                    type={"plus2"}
                    style={{ width: "9%", height: "30px", paddingTop: "2px" }}
                    onClick={() => {
                      setHideData(true);
                      setLineItemArray([
                        ...lineItemArray,
                        Math.floor(Math.random() * 100),
                      ]);
                      setFormData({
                        ...formData,
                        agreementDetails: [
                          ...formData?.agreementDetails,
                          {
                            resource: "",
                            startDate: "",
                            endDate: "",
                            partnerId: prevData?.partnerId,
                          },
                        ],
                      });
                    }}
                  >
                    Add
                  </InvoiceButton>
                </span>
              </div>
              {/*row 1*/}

              <Row>
                <div className={classes.Container1}>
                  <div className={classes.col12_item}>
                    <p className={classes.label_item_resource}>Resource</p>
                  </div>
                  <div
                    className={classes.col2_item}
                    style={{ marginLeft: "-8px" }}
                  >
                    <p className={classes.label_item_resource_type}>
                      Resource Type
                    </p>
                  </div>
                  <div
                    className={classes.col3_item}
                    style={{ marginTop: "-1px" }}
                  >
                    <p className={classes.label_item5}>Start Date</p>
                  </div>
                  <div className={classes.col4_item}>
                    <p className={classes.label_item6}>End Date</p>
                  </div>
                  <div className={classes.col5_item}>
                    <p className={classes.label_item}></p>
                  </div>
                </div>
              </Row>
              {HideData
                ? formData?.agreementDetails?.map((item, index) => {
                    return (
                      <>
                        <div className={classes.Container} key={index}>
                          <div className={classes.col12_item}>
                            <input
                              style={{ marginLeft: "-12px" }}
                              type="text"
                              className={classes.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.agreementDetails];
                                temp[index].resource = e?.target?.value;
                                setFormData({
                                  ...formData,
                                  agreementDetails: temp,
                                });
                              }}
                              value={
                                formData?.agreementDetails[index]?.resource
                              }
                            />
                          </div>
                          <div
                            className={classes.col2_item}
                            style={{ marginLeft: "-25px" }}
                          >
                            <Select
                              styles={InvoiceStyles}
                              options={getAggrementDataoption}
                              classNamePrefix={"create_position"}
                              components={{
                                DropdownIndicator: () => CustomInput(),
                                IndicatorSeparator: () => null,
                              }}
                              value={
                                formData?.agreementDetails[index]?.rateTypeId
                                  ? getAggrementDataoption?.find(
                                      (e) =>
                                        e?.value ===
                                        formData?.agreementDetails[index]
                                          ?.rateTypeId
                                    )
                                  : null
                              }
                              onChange={(e) => {
                                let temp = [...formData?.agreementDetails];
                                temp[index].rateTypeId = e?.value;
                                setFormData({
                                  ...formData,
                                  agreementDetails: temp,
                                });
                              }}
                            />
                          </div>
                          <div className={classes.col3_item}>
                            <div className={classes.cu_field23Last1}>
                              <DatePicker
                                placeholder=" DD MM YYYY"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                style={{ fontSize: "12px" }}
                                selected={
                                  formData?.agreementDetails[index]?.startDate
                                    ? moment(
                                        formData?.agreementDetails[index]
                                          ?.startDate
                                      ).toDate()
                                    : null
                                }
                                onChange={(newDate) => {
                                  setFormData((prevFormData) => {
                                    const updatedAgreementDetails =
                                      prevFormData.agreementDetails.map(
                                        (detail, i) => {
                                          if (i === index) {
                                            return {
                                              ...detail,
                                              startDate: moment(newDate).format(
                                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                              ),
                                            };
                                          }
                                          return detail;
                                        }
                                      );

                                    return {
                                      ...prevFormData,
                                      agreementDetails: updatedAgreementDetails,
                                    };
                                  });
                                }}
                                dateFormat="dd MMM yyyy"
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                customInput={
                                  formData?.agreementDetails[index]
                                    ?.startDate ? (
                                    <CustomInputCross properties="startDate" />
                                  ) : (
                                    <CustomInputClg />
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className={classes.col4_item}>
                            <div className={classes.cu_field23Last1}>
                              <DatePicker
                                placeholder=" DD MM YYYY"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selected={
                                  formData?.agreementDetails[index]?.endDate
                                    ? moment(
                                        formData?.agreementDetails[index]
                                          ?.endDate
                                      ).toDate()
                                    : null
                                }
                                onChange={(newDate) => {
                                  setFormData((prevFormData) => {
                                    const updatedAgreementDetails =
                                      prevFormData.agreementDetails.map(
                                        (detail, i) => {
                                          if (i === index) {
                                            return {
                                              ...detail,
                                              endDate: moment(newDate).format(
                                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                              ),
                                            };
                                          }
                                          return detail;
                                        }
                                      );

                                    return {
                                      ...prevFormData,
                                      agreementDetails: updatedAgreementDetails,
                                    };
                                  });
                                }}
                                dateFormat="dd MMM yyyy"
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                                customInput={
                                  formData?.agreementDetails[index]?.endDate ? (
                                    <CustomInputCross properties="endDate" />
                                  ) : (
                                    <CustomInputClg />
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className={classes.col5_item}>
                            <input
                              placeholder={
                                formData?.agreementDetails[index]
                                  ?.rateTypeId === 1
                                  ? "Monthly Rate"
                                  : formData?.agreementDetails[index]
                                      ?.rateTypeId === 2
                                  ? "Hourly Rate"
                                  : "Rate"
                              }
                              type="number"
                              className={classes.smallInput}
                              onChange={(e) => {
                                let temp = [...formData?.agreementDetails];
                                temp[index].rate = e.target.value;
                                setFormData({
                                  ...formData,
                                  agreementDetails: temp,
                                });
                              }}
                              value={formData?.agreementDetails[index]?.rate}
                              min="0"
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) =>
                                (e.keyCode === 38 ||
                                  e.keyCode === 40 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.preventDefault()
                              }
                              onKeyPress={(e) =>
                                ((e.which !== 8 &&
                                  e.which !== 0 &&
                                  e.which < 48) ||
                                  e.which > 57 ||
                                  e.key === "e" ||
                                  e.key === "-") &&
                                e.prevent
                              }
                            />
                          </div>

                          <div className={classes.col8_item}>
                            {formData?.agreementDetails?.length > 1 && (
                              <>
                                <FaTrash
                                  className={classes.remove_icon}
                                  size={20}
                                  onClick={(e) => {
                                    setLineItemArray(
                                      lineItemArray.filter((_, i) => {
                                        return i !== index;
                                      })
                                    );
                                    setFormData({
                                      ...formData,
                                      agreementDetails:
                                        formData?.agreementDetails?.filter(
                                          (_, i) => i !== index
                                        ),
                                    });
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })
                : ""}
              <button className={classes.buttonAdd} onClick={HandlerAgreement}>
                Submit
              </button>
            </InvoiceContainer>
          </>
        ) : (
          <>
            <InvoiceContainer>
              <div className={classes.copy_expence}>Edit Agreement</div>
              {/*row 1*/}
              <>
                <div className={classes.Container}>
                  <div className={classes.col12_item}>
                    <p className={classes.label_item_resource1}>Resource</p>
                    <input
                      type="text"
                      className={classes.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          resource: e.target.value,
                        });
                      }}
                      value={formData1?.resource}
                    />
                  </div>
                  <div className={classes.col2_item}>
                    <p className={classes.label_item_resource2}>
                      Resource Type
                    </p>

                    <Select
                      styles={InvoiceStyles}
                      options={getAggrementDataoption}
                      classNamePrefix={"create_position"}
                      components={{
                        DropdownIndicator: () => CustomInput(),
                        IndicatorSeparator: () => null,
                      }}
                      value={getAggrementDataoption?.find(
                        (e) => e.value === formData1?.rateTypeId
                      )}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          rateTypeId: e?.value,
                        });
                      }}
                    />
                  </div>
                  <div className={classes.col3_item}>
                    <p className={classes.label_item_resource3}>Start Date</p>
                    <div className={classes.cu_field23Last1}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={moment().toDate()}
                        selected={
                          formData1?.startDate
                            ? moment(formData1?.startDate).toDate()
                            : null
                        }
                        onChange={(date) => {
                          const dateString = date;

                          const dateObject = new Date(dateString);

                          const isoString = dateObject.toISOString();
                          const formattedDate = isoString.replace(
                            "T00:00:00.000Z",
                            "T05:52:34.459Z"
                          );
                          setFormData1({
                            ...formData1,
                            startDate: formattedDate,
                          });
                        }}
                        dateFormat="dd MMM yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        customInput={
                          formData1?.startDate ? (
                            <CustomInputCross1 properties="startDate" />
                          ) : (
                            <CustomInputClg1 />
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.col4_item}>
                    <p className={classes.label_item_resource4}>End Date</p>
                    <div className={classes.cu_field23Last1}>
                      <DatePicker
                        placeholder=" DD MM YYYY"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        maxDate={moment().toDate()}
                        dateFormat="dd MMM yyyy"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        selected={
                          formData1?.endDate
                            ? moment(formData1?.endDate).toDate()
                            : null
                        }
                        onChange={(date) => {
                          const dateString = date;

                          const dateObject = new Date(dateString);

                          const isoString = dateObject.toISOString();
                          const formattedDate = isoString.replace(
                            "T00:00:00.000Z",
                            "T05:52:34.459Z"
                          );
                          setFormData1({
                            ...formData1,
                            endDate: formattedDate,
                          });
                        }}
                        customInput={
                          formData1?.endDate ? (
                            <CustomInputCross1 properties="endDate" />
                          ) : (
                            <CustomInputClg1 />
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.col5_item}>
                    <p className={classes.label_item9}></p>
                    <input
                      type="number"
                      className={classes.smallInput}
                      onChange={(e) => {
                        setFormData1({
                          ...formData1,
                          rate: e.target.value,
                        });
                      }}
                      value={formData1?.rate}
                      min="0"
                      onWheel={(e) => e.target.blur()}
                      onKeyDown={(e) =>
                        (e.keyCode === 38 ||
                          e.keyCode === 40 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.preventDefault()
                      }
                      onKeyPress={(e) =>
                        ((e.which !== 8 && e.which !== 0 && e.which < 48) ||
                          e.which > 57 ||
                          e.key === "e" ||
                          e.key === "-") &&
                        e.prevent
                      }
                    />
                  </div>
                </div>
              </>
            </InvoiceContainer>
            <button
              className={classes.buttonAdd4}
              onClick={() => {
                EditHandlerAgreement();
                setFormData((prevData) => ({
                  ...prevData,
                  resource: "",
                  startDate: "",
                  endDate: "",
                  rate: "",
                  partnerId: "",
                  rateTypeId: "",
                  agreementId: "",
                }));
              }}
            >
              Submit
            </button>
          </>
        )}

        <Row>
          <Col>
            <h6 style={{ marginLeft: "14px", marginTop: "50px" }}>
              Resource Rate
            </h6>
          </Col>
        </Row>
        <Col md={12}>
          <InvoiceContainer>
            <div className={classes.table}>
              <div className={classes.th1}>
                <p className={classes.tableContent}>Resource</p>
                <p className={classes.tableContent}>Resource Type</p>
                <p className={classes.tableContent}>Start Date</p>
                <p className={classes.tableContent}>End Date</p>
                <p className={classes.tableContent}>Rate</p>
                <p className={classes.tableContent}>Action</p>
              </div>
              <div>
                {isLoading && <p className={classes.no_data}>Loading .....</p>}
                {!isLoading && !isError ? (
                  AgreementList?.length > 0 ? (
                    typeof AgreementList !== "string" &&
                    AgreementList?.map((item, index) => {
                      return (
                        <div className={classes.td1} key={item?.positionId} style={{position:'relative'}}>
                          {/* <p
                            className={classes.tableContent}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() =>
                              HandlerAgreementHistory(item?.agreementId)
                            }
                          >
                            {item?.resource}
                          </p> */}

                          <p
                           onClick={() =>
                            HandlerAgreementHistory(item?.agreementId)
                          }
                          >
                          <CustomTooltip
                            className={classes.tableContent}
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                             
                            }}
                            value={item?.resource}
                            limit={15}
                            index={index}
                           
                            // tooltipstyle={{ left: "55%" }}
                          />

                          </p>
                           
                        
                          <p className={classes.tableContent}>
                            {item?.resourceType}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.startDate?moment(item?.startDate).format("DD MMM YY"):""}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.endDate?moment(item?.endDate).format("DD MMM YY"):""}
                          </p>

                          <p className={classes.tableContent}>{item?.rate}</p>
                          <p
                            className={classes.tableContent}
                            onClick={() => {
                              EditAgreementHandler(item?.agreementId);
                            }}
                          >
                            <img
                              src={EditIcon}
                              alt=""
                              style={{ cursor: "pointer" }}
                            />
                          </p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={classes.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </div>
          </InvoiceContainer>
        </Col>

        <Col md={12} style={{ marginTop: "10px" }}>
          <Row>
            <Col>
              <h6 style={{ marginLeft: "25px" }}>Resource History</h6>
            </Col>
          </Row>

          <InvoiceContainer>
            <div className={classes.table}>
              <div className={classes.th}>
                <p className={classes.tableContent}>Resource</p>
                <p className={classes.tableContent}>Resource Type</p>
                <p className={classes.tableContent}>Start Date</p>
                <p className={classes.tableContent}>End Date</p>
                <p className={classes.tableContent}>Rate</p>
                <p></p>
              </div>
              <div>
                {isLoading && <p className={classes.no_data}>Loading .....</p>}
                {!isLoading1 && !isError1 ? (
                  AgreementDataTech?.length > 0 ? (
                    typeof AgreementDataTech !== "string" &&
                    AgreementDataTech?.map((item, index) => {
                      return (
                        <div className={classes.td} key={item?.positionId} style={{position:'relative'}}>
                          {/* <p
                            className={`${classes.tableContent} ${classes.link}`}
                          >
                            {item?.resource}
                          </p> */}
                          
                          <CustomTooltip
                            value={item?.resource}
                            limit={15}
                            className={`${classes.tableContent} ${classes.link}`}
                            index={`resource${index}`}

                            // tooltipstyle={{ left: "55%" }}
                          />
                          <p className={classes.tableContent}>
                            {item?.resourceType}
                          </p>

                          <p className={classes.tableContent}>
                            {item?.startDate?moment(item?.startDate).format("DD MMM YY"):""}
                          </p>

                          <p className={classes.tableContent}>
                            { item?.endDate?moment(item?.endDate).format("DD MMM YY"):""}
                          </p>

                          <p className={classes.tableContent}>{item?.rate}</p>
                          <p></p>
                        </div>
                      );
                    })
                  ) : (
                    <p className={classes.no_data}>No Data Found</p>
                  )
                ) : null}
              </div>
            </div>
          </InvoiceContainer>
        </Col>
        <button
          className={classes.buttonAdd1}
          onClick={() => {
            setNextScreen("PartnerList");
            setPrevData((prevData) => ({
              ...prevData,
              page2: "PartnerList",
              page3: null,
              page4: null,
              active: "PartnerList",
            }));
          }}
        >
          Done
        </button>
      </Container>
    </>
  );
};
export default Agreement;
