import React, {useState} from 'react';
import SideBar from '../commonComponent/SideBar';
import Header from '../commonComponent/Header';
import style from './EditComments.module.css';
import EditCommentsComponent from './component/EditCommentsComponent';

const EditComments = (props) => {
  console.log(props)
  const [commentData, setcommentData] = useState(props.history.location.state.comment);
  const [taskData, settaskData] = useState(props.history.location.state.newData);
    return(
    
    <div>
        <Header />
  
        <div className={style.mainSection}>
          <SideBar />
  
          {/* <div className={style.rightSection}> */}
            <EditCommentsComponent commentData={commentData} taskData={taskData}/>
            {/* <Filter /> */}
          {/* </div> */}
        </div>
      </div>);
}

export default EditComments;