import React from "react";
import style from "./invoiceButton.module.css";

const InvoiceButton = ({ children, onClick, type }) => {
  switch (type) {
    case "plus":
      return (
        <button className={style.createBtn} onClick={onClick}>
          <span> + </span>
          {children}
        </button>
      );
    case "cancel":
      return (
        <button className={style.cancelBtn} onClick={onClick}>
          {children}
        </button>
      );
      case "plus2":
      return (
        <button className={style.createBtn1} onClick={onClick}>
        {children}
      </button>
      );
    default:
      return (
        <button className={style.createBtn} onClick={onClick}>
          {children}
        </button>
      );
  }
};

export default InvoiceButton;
