import React from "react";
import style from "./SideBar.module.css";
import buttonimg from "../../../assets/collaboration.png";
import { Link } from "react-router-dom";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import { useLocation } from "react-router-dom";
import InnovationMLogo from "../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import { useHistory } from "react-router";


const SideBar = () => {
  const location = useLocation();
  let history = useHistory();
  return (
    <div className={style.leftSection}>
      <img
        alt="DashBoardImage"
        src={InnovationMLogo}
        className={style.dashboardImage}
        onClick={() => history.push("/dashboard")}
      />
      <button
        className={style.taskTracker}
        style={{ cursor: "auto", border: "none" }}
      >
        <div>
          <img src={buttonimg} alt="img" />
        </div>

        <span>User Management</span>
      </button>

      <div className={style.side_buttons_div}>
        <Link to="/new-employee" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/" ||
              location.pathname === "/" ||
              location.pathname === "/new-employee"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GridViewRoundedIcon className={style.icon} />}
            <div className={style.heading}>Dashboard</div>
          </div>
        </Link>

        <Link to="/UsersList" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/UsersList" ||
              location.pathname === "/CreateUser" ||
              location.pathname === "/userDescription" ||
              location.pathname === "/EditUser" ||
              location.pathname === "/CreateCanfirmation"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GroupsIcon className={style.icon} />}
            <div className={style.heading}>Users</div>
          </div>
        </Link>

        <Link to="/report" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/report"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GroupsIcon className={style.icon} />}
            <div className={style.heading}>Report</div>
          </div>
        </Link>
        <Link to="/exit-employee" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/exit-employee"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GroupsIcon className={style.icon} />}
            <div className={style.heading}>Exit Employee Lists</div>
          </div>
        </Link>
        <Link to="/confirmation-employee" style={{ textDecoration: "none" }}>
          <div
            className={
              location.pathname === "/confirmation-employee"
                ? style.active_btn
                : style.btn_menu
            }
          >
            {<GroupsIcon className={style.icon} />}
            <div className={style.heading}>Confirmation Lists</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SideBar;
