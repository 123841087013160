import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Modal} from "react-bootstrap";
import BreadCrum from "../../breadcrums/BreadCrum";
import FilterButton from "../Filterbutton/FilterButton";
import Pagination from "../../CommonComponent/Pagination/Pagination.js";
import moment from "moment";
import Filter from "../../filter/FilterInvoice";
import style from "./InvoiceList.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton";
import { invoiceListData } from "../../../../Utils/axios/expenceApi.js";
import CustomTooltip from "../../customTooltip/CustomTooltip.js";
import delete1 from "../../../../assets/delete-icon.png";
import {DeleteInvoice} from "../../../../Utils/axios/expenceApi"
import { useQuery,useMutation } from "react-query";
const InvoiceList = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,

  
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [Data, setData] = useState("");
  const [deleteId, setdeleteId] = useState();
  const [show, setShow] = useState(false);
  const [filterData, setFilterData] = useState({
    projectId: "",
    advanceStausId: "",
    clientId: "",
    amountToBeReceived: "",
    paymentStatus: "",
    paymentDate: "",
    projectValue: "",
    amountToBeReceivedValue:"",
    ClientValue:"",
    advanceStausValue:"",
    paymentDateValue:"",
    paymentStatusValue:"",
  });

  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  const filterUpdateFuc = () => {
    setData(filterData);
    setShowFilter(false);
  };

  const mutation1 = useMutation(DeleteInvoice, {
    onSuccess: (res) => {
      setNextScreen("invoiceList");
    },
  });
  const handleShow = (expenseId) => {
    setShow(true);
    setdeleteId(expenseId)
  };
  
  const handleDelete = () => {
    setShow(false);
    mutation1.mutate(deleteId);
  }; 
  
  const handleDeleteCancle = () => {
    setShow(false);
  };

  const filterClearAllFuc = () => {
    setData("");
    setShowFilter(false);
    setFilterData((preData) => ({
      ...preData,
      projectValue:"",
      projectId: "",
      amountToBeReceived:"",
      ClientValue:"",
      clientId: "",
      advanceStausValue:"",
      advanceStausId:"",
      paymentDate: "",
      paymentDateValue:"",
      paymentStatusValue:"",
      paymentStatus:"",
    }));
    return;
  };

  const { data, isLoading, isError } = useQuery(
    ["invoiceListData", { currentPage, Data }],
    () =>
      invoiceListData(
        10,
        currentPage,
        Data.projectId,
        Data.advanceStausId,
        Data.clientId,
        Data.amountToBeReceived,
        Data.paymentStatus,
        Data.paymentDate
      ),
      {
        refetchOnWindowFocus: true,
        staleTime: 0,
        cacheTime: 0,
        refetchInterval: 1000,
      }
  );


  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
          filterComponent={
            <FilterButton
              setShowFilter={setShowFilter}
              filterData={filterData}
            />
          }
        >
          <InvoiceButton
            type={"plus"}
            onClick={() => {
              setNextScreen("createInvoice");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "createInvoice",
                page3: null,
                page4: null,
                active: "createInvoice",
              }));
            }}
          >
            Create Invoice
          </InvoiceButton>
        </BreadCrum>
      </div>

      <InvoiceContainer>
        <div className={style.table}> 
          <div className={style.th}>
            <p className={style.tableContent}>Project Type</p>
            <p className={style.tableContent}>Client Name</p>
            <p className={style.tableContent}>Advance Status</p>
            <p className={style.tableContent}>Amount to be Received</p>
            <p className={style.tableContent}>Payment Received Date</p>
            <p className={style.tableContent}>Payment Status</p>
            <p className={style.tableContent}>Total Invoice Amount</p>
          </div>
          <div>
            {isLoading && <p className={style.no_data}>Loading .....</p>}
            {!isLoading && !isError ? (
              data?.invoice?.length > 0 ? (
                typeof data?.invoice !== "string" &&
                data?.invoice?.map((item,index) => {
                  return (
                    <div className={style.td} key={item?.positionId}>
                      <p className={style.tableContent1}>{item?.projectType}</p>
                      <p
                        className={`${style.tableContent} ${style.link}`}
                        onClick={() => {
                          setNextScreen("viewInvoice"); 
                          setPrevData((prevData) => ({
                            ...prevData,
                            page2: "viewInvoice",
                            page3: null,
                            page4: null,
                            active: "viewInvoice",
                            invoiceId: item?.id,
                          }));
                        }}
                      >
                        <CustomTooltip
                          value={item?.clientName}
                          limit={12}
                          index={index}
                          styles={{
                            padding: "7px 5px 10px 4px",
                            marginTop: "10px",
                            color: "#855ea1",
                            fontWeight:"bolder",
                            // transform: "translate(-7px, 0px)"
                          }}
                          tooltipstyle={{ left: "42%" }}
                        />
                      </p>

                      <p className={style.tableContent}>{item.advanceStatus}</p>

                      <p className={style.tableContent}>
                        {item.amountToBeReceived}
                      </p>

                      <p className={style.tableContent}>
                        {moment(item.paymentDate).format("DD MMM YY")}
                      </p>
                      <p className={style.tableContent}>{item.paymentStatus}</p>
                      <p className={style.tableContent}>
                        {item.totalInvoiceAmount}
                      </p>
                      <figure>
                        <img
                          style={{ cursor: "pointer",marginTop:"16px",marginRight:"9px"}}
                          src={delete1}
                          alt=""
                          onClick={() => {
                            handleShow(item?.id);
                          }}
                        />
                      </figure>
                    </div>
                  );
                })
              ) : (
                <p className={style.no_data}>No Data Found</p>
              )
            ) : null}
          </div>
        </div>

        <>
          <Filter
            filterData={filterData}
            setFilterData={setFilterData}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            firstCard="Project Type"
            secondCard="Client Name"
            thirdCard="Advance Status"
            forthCard="Amount To Be Received"
            fifthCard="Payment Received Date"
            sixthCard="Payment Status"
            filterUpdateFuc={filterUpdateFuc}
            filterClearAllFuc={filterClearAllFuc}
          />
        </>
      </InvoiceContainer>
      <div className={style.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={data?.totalInvoice / 12}
          color="##855ea1"
          color2="white"
        />
      </div>
      <Modal show={show} centered   classNames={{
          modal: 'customModal',
        }}>
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to delete 
        </h6>
        <button className={style.buttonAdd1} onClick={handleDelete}>
          Yes
        </button>
        <button className={style.buttonCancle} onClick={handleDeleteCancle}>
          No
        </button>
      </Modal>
    </>
  );
};

export default InvoiceList;
