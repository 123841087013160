import React, { useEffect, useState } from "react";
import Header from "../../../taskTracker/commonComponent/Header";
import SideBar from "../../Clients/component/SideBar";
import style from "./ResourcesAllocationOnsite.module.css";
import TableRow from "./TableRow";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import constants from "../../../../Utils/constants";
import axios from "axios";
import loaderImg from "../../../../assets/loader.gif";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import Select from "react-select";
import { customSearch } from "../../Clients/component/style";
import searchIcon from "../../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import milestone from "../../../../assets/milestone.png";
import Pagination from "../../CommonComponent/Pagination";
import rightTickPRM from "../../../../assets/rightTickPRM.png";
import Filter from "../../components/Filter";

const ResourcesAllocationOnsite = (props) => {
  const history = useHistory();
  const baseUrl = constants.apiBaseUrlResource;
  const [showFilter, setShowFilter] = useState(false);
  const [applied, setApplied] = useState(props.history.location.state?.allData.applied?props.history.location.state?.allData.applied:false);
  const [OnsiteData, setOnsiteData] = useState([]);
  const [currentPage, setCurrentPage] = useState(props.history.location.state?.allData.currentPage?props.history.location.state?.allData.currentPage:1);
  const [pageCount, setpageCount] = useState(0);
  const [totalResourceOnsite, setTotalResourceOffShore] = useState(0);
  const [clientListing, setClientListing] = useState([]);
  const [empListing, setEmpListing] = useState([]);
  const [onsiteSkills, setOnsiteSkills] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [action, setAction] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [actionActive] = useState(1);
  const[toglle,setToggle]=useState(true)
  const [employeeId, setEmployeeId] = useState([]);
  const [skills, setSkills] = useState([]);
  const [filterName, setFilterName] = useState(props.history.location.state?.allData?.filterName?props.history.location.state?.allData?.filterName:{});
  const [filterEmp, setFilterEmp] = useState(props.history.location.state?.allData?.filterEmp?props.history.location.state?.allData?.filterEmp:{});
  const [allData, setAllData] = useState(
    props.history.location.state?.allData.allData?props.history.location.state?.allData.allData:{ 
    action:[],
    clientId:[],
    employeeId:[],
    skill: [],
    salaryRange:[],
    partners: [],
  });

  const url = process.env.REACT_APP_UMS_APP_URL;
  const onSearchHandler = (e, option) => {
    if (e || e === null) {
      switch (option) {
        case "Code":
          setAllData({ ...allData, clientId: [e?.value]});
          setFilterName({ ...filterName, positionCode: e?.label });
          break;
        case "Code1":
          setAllData({ ...allData, employeeId: [e?.value] });
          setFilterEmp({ ...filterEmp, positionCode: e?.label });
          break;
        default:
      }
    }
  };

  empListing?.sort((a, b) =>
    a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1
  );

  const getClientList = clientListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));

  const getemployee = empListing?.map((i) => ({
    value: i.id,
    label: i.name,
  }));
  const getAddResourceOnsiteData = (clearData, currentPage) => {
    const filterData = clearData?.clientId?.length == 0 ? allData : allData;
    setIsLoaded(true);
    axios
      .post(
        baseUrl +
          "/getAddResourceOnsite?" +
          "limit=" +
          limit +
          "&page=" +
          currentPage,
        filterData,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
        setIsLoaded(false);
        setOnsiteData(res.data.responseData);
        setTotalResourceOffShore(res.data.responseData.totalResources);
        setpageCount(
          res.data.responseData.totalResources/10
        );
      })
      .catch((err) => {
        setIsLoaded(false);
        console.log(err);
      });
  };

  const getClientListing = () => {
    axios
      .get(baseUrl + "/getClientOnSite", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setClientListing(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmpListing = () => {
    axios
      .get(baseUrl + "/getAllOnSiteEmployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        setEmpListing(res.data.responseData.employees);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = () => {
    console.log("hh")
    axios
      .get(
        url +
          `/updateUsersDetails`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: ` ${cookie.load("token")}`,
          },
        }
      )
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getOnsiteSkills = () => {
    axios
      .get(baseUrl + "/getOnsiteFilterSkillData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: ` ${cookie.load("token")}`,
        },
      })
      .then((res) => {
        console.log("skills", res);
        setOnsiteSkills(res.data.responseData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const limit = 10;

  const handlePageChange = (page, status) => {
    if (status == "next") {
      setCurrentPage(page + 1);
    } else if (status == "prev") {
      setCurrentPage(page - 1);
    }
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setCurrentPage(1)
    setAllData((preValue) => {
      return preValue
        ? {
            ...preValue,
            action:[],
            clientId:allData?.clientId,
            employeeId:allData?.employeeId,
            skill: [],
            salaryRange:[],
            partners: [],
          }
        : null;
    });
    console.log(allData)
    // getAddResourceOnsiteData(allData, 1);
    setAction([]);
    setClientId([]);
    setEmployeeId([]);
    setSkills([]);
    setShowFilter(false);
    setApplied(false);
  };

  const handleApplied = (arg) => {
    if (
      action.length !== 0 ||
      clientId.length !== 0 ||
      employeeId.length !== 0 ||
      skills.length !== 0 ||
      allData?.salaryRange.length !==0 ||
      allData?.partners.length!==0
    ) {
      setApplied(true);
    }
    setShowFilter(false);
    setCurrentPage(1);
    getAddResourceOnsiteData(allData, 1);
  };

  useEffect(() => {
    getAddResourceOnsiteData(allData, currentPage);
    getClientListing();
    getEmpListing();
    getOnsiteSkills();
  }, [currentPage,allData]);

  useEffect(()=>{
    getUserData()
  },[])

  return (
    <>
      <Header />
      <div className={style.main_client_body}>
        <SideBar />

        <div className={style.table_div}>
          <div className={style.table_div_head}>
            <div className={style.breadscrumb} style={{ padding: "6px 0px" }}>
              {/* <span><span>Dashboard{'>'}</span><span className={style.recent_tab}>Resource Allocation Onsite </span></span> */}
            </div>

            <div className={style.second_child}>
              <div className={style.title}>Resource Onsite
              </div>
              <div  style={{width:"12%", marginRight:"42px"}}>
              <p className={style.count}>
              <span className={style.backColor1} style={{fontSize:"1.235rem", fontWeight:"500"}}>{totalResourceOnsite}</span>
            </p>
              </div>
              <div className={style.mainFilter}>
                <button className={style.filter_btn} onClick={handleFilter}>
                  {applied && (
                    <img className={style.appliedcheck} src={rightTickPRM} />
                  )}
                  {<FilterListIcon style={{ marginRight: "10px" }} />}
                  Filter
                </button>
              </div>
              <div className={style.twoFilter}>
                <div
                  className={style.s1}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.clientId.length==0? (
                    <span className={style.cp_serchIcon}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          clientId:[],
                        });
                        setFilterName({ ...filterName, positionCode: null });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Client Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filterName?.positionCode
                        ? {
                            value: allData?.clientId,
                            label: filterName?.positionCode,
                          }
                        : null
                    }
                    onChange={(e) => {
                      onSearchHandler(e, "Code");
                      setShowFilter(false);
                      setCurrentPage(1)
                    }}
                    options={getClientList}
                  />
                </div>
                <div
                  className={style.s2}
                  // style={{ width: "26%" }}
                  onClick={() => {
                    setShowFilter(false);
                  }}
                >
                  {allData?.employeeId.length==0 ? (
                    <span className={style.cp_serchIcon2}>
                      <img src={searchIcon} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon2}
                      onClick={() => {
                        setAllData({
                          ...allData,
                          employeeId:[],
                        });
                        setFilterEmp({ ...filterEmp, positionCode: null });
                      }}
                    >
                      {allData?.employeeId.length !=0 ? <AiOutlineClose /> : null}
                    </span>
                  )}
                  <Select
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    placeholder="Employee Name"
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    value={
                      filterEmp?.positionCode
                        ? {
                            value: allData?.employeeId,
                            label: filterEmp?.positionCode,
                          }
                        : null
                    }
                    onChange={(e) => {
                      onSearchHandler(e, "Code1");
                      setCurrentPage(1)
                      setShowFilter(false);
                    }}
                    options={getemployee}
                  />
                </div>
              </div>
              <div className={style.AddBtn}>
                <Link to="/AddResourcesAllocationOnsite">
                  <button className={style.create_btn}>
                    <span className={style.plus}>+</span>Allocate Resource
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div
            onClick={() => {
              setShowFilter(false);
            }}
            className={style.onsiteList}
            // style={{ backgroundColor: "white", minHeight: "27rem" }}
          >
            {isLoaded ? (
              <div>
                <img
                  src={loaderImg}
                  alt="loader"
                  style={{ marginLeft: "47%", marginTop: "8%", width: "12%" }}
                  className={style.loader}
                />
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    paddingLeft: "38%",
                    position: "absolute",
                    color: "#FBBB08",
                    marginTop: "-4px",
                    fontStyle: "italic",
                    marginLeft: "34.5%",
                  }}
                >
                  Loading...
                </p>
              </div>
            ) : OnsiteData.resources?.length>0? (
              <Table borderless size="sm" style={{ fontSize: "12px" }}>
                <thead
                  style={{
                    backgroundColor: "#F0F0F0",
                    position: "sticky",
                    top: "0px",
                  }}
                >
                  <tr style={{ padding: "100px" }}>
                  <th style={{ padding: "15px" }}>Employee Code</th>
                    <th style={{ padding: "10px" }}>Clients Name</th>
                    <th style={{ padding: "15px" }}>Employee Name</th>
                    <th style={{ padding: "15px" }}>Experience</th>
                    <th style={{ padding: "15px" }}>Salary Range</th>
                    <th style={{ padding: "10px" }}>Technology</th>
                    <th style={{ padding: "10px" }}>Status</th>
                    <th style={{ padding: "10px" }}>Start Date</th>
                    <th style={{ padding: "10px" }}>End Date</th>
                    <th style={{ padding: "10px" }}>Last Comment Date</th>
                    <th style={{ padding: "10px" }}></th>
                  </tr>
                </thead>
                <tbody>
                  {OnsiteData?.resources.map((val) => {
                    return (
                      <TableRow
                        clientName={val.clientName}
                        experience={val.experience}
                        projectSkill={val.skillName}
                        startDate={val.allocationdate}
                        EndDate={val.deallocationdate}
                        employeeName={val.empName}
                        id={val.id}
                        statusTypeName={val.statusTypeName}
                        resignStatus={val.resignStatus}
                        lastCommentDate={val.currentCommentDate}
                        empCode={val.empCode}
                        salaryRange={val.salaryRange}
                        applied={applied}
                        currentPage={currentPage}
                        allData={allData}
                        filterName={filterName}
                        filterEmp={filterEmp}

                      />
                    );
                  })}
                </tbody>
              </Table>
            ) : 
            
                <div className={style.noData}>
                  <img src={milestone} />
                  <h3 className={style.noData_header}>
                    No data available
                  </h3>
                </div>
              }

            {/* } */}
          </div>
          {totalResourceOnsite > limit && pageCount > 0 && (
            <div
              className={style.paginationmobileContainer}
              style={{ marginTop: "20px" }}
            >
              <Pagination
                pagesCount={pageCount}
                pageSize={limit}
                onPageChange={handlePageChange}
                currentPage={currentPage}
              />
            </div>
          )}
        </div>
      </div>
      {showFilter && (
        <Filter
          closeFilter={closeFilter}
          handleApplied={handleApplied}
          filterData={allData}
          type={"onSite"}
          empListing={empListing}
          clientListing={clientListing}
          setAction={setAction}
          setEmployeeId={setEmployeeId}
          setClientId={setClientId}
          setSkills={setSkills}
          onsiteSkills={onsiteSkills}
        />
      )}
    </>
  );
};

export default ResourcesAllocationOnsite;
