import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Modal} from "react-bootstrap";
import Tooltip from "react-simple-tooltip";
import BreadCrum from "../../breadcrums/BreadCrum";
import FilterButton from "../Filterbutton/FilterButton";
import Pagination from "../../../Projects_and_Resource_Master/CommonComponent/Pagination";
import moment from "moment";
import Filter from "../../filter/Filter";
import style from "./Expense.module.css";
import InvoiceContainer from "../../CommonComponent/InvoiceContainer/InvoiceContainer";
import InvoiceButton from "../../CommonComponent/Button/InvoiceButton";
import CustomTooltip from "../../customTooltip/CustomTooltip.js";
import { useQuery, useMutation } from "react-query";
import { expenseList } from "../../../../Utils/axios/expenceApi.js";
import delete1 from "../../../../assets/delete-icon.png";
import {DeleteExpense} from "../../../../Utils/axios/expenceApi"
import axios from "axios";
import { colors } from "@mui/material";

const Expense = ({
  setNextScreen,
  prevData,
  setPrevData,
  currentPage,
  setCurrentPage,
}) => {
  const [deleteId, setdeleteId] = useState();
  const [show, setShow] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [Data, setData] = useState("");
  const [filterData, setFilterData] = useState({
    expenseMonth: "",
    category: "",
    serviceMonth: "",
    status: "",
    paymentDate: "",
    CategoryValue: "",
    VendorId: "",
    VendorValue: "",
    advanceStausValue: "",
    advanceStausId: "",
    PaymentDateId: "",
    PaymentStatusValue: "",
    MonthId: "",
    MonthValue: "",
  });
  const filterUpdateFuc = () => {
    setData(filterData);
    setShowFilter(false);
  };
  const mutation1 = useMutation(DeleteExpense, {
    onSuccess: (res) => {
      setNextScreen("expense");
    },
  });
  const handleShow = (expenseId) => {
    setShow(true);
    setdeleteId(expenseId)
  };
  
  const handleDelete = () => {
    setShow(false);
    mutation1.mutate(deleteId);
  }; 
  
  const handleDeleteCancle = () => {
    setShow(false);
  };



  const filterClearAllFuc = () => {
    setData("");
    setShowFilter(false);
    setFilterData((preData) => ({
      ...preData,
      expenseMonth: "",
      category: "",
      serviceMonth: "",
      status: "",
      paymentDate: "",
      CategoryValue: "",
      VendorValue: "",
      VendorId: "",
      advanceStausId: "",
      advanceStausValue: "",
      PaymentDateId: "",
      PaymentStatusValue: "",
      MonthId: "",
      MonthValue: "",
    }));
    return;
  };
  const handlePageChange = (page, status) => {
    if (status === "next") {
      setCurrentPage(page + 1);
    } else if (status === "prev") {
      setCurrentPage(page - 1);
    }
  };

  

  const { data, isLoading, isError } = useQuery(
    ["expenseList", { currentPage, Data }], () =>
    expenseList(
      10,
      currentPage,
      Data.MonthId,
      Data.category,
      Data.PaymentDateId,
      Data.advanceStausId,
      Data.VendorId,
    ),
    { refetchOnWindowFocus: true, refetchInterval: 1000 }
  );
  


  return (
    <>
      <ToastContainer />

      <div>
        <BreadCrum
          prevData={prevData}
          setPrevData={setPrevData}
          setNextScreen={setNextScreen}
          filterComponent={
            <FilterButton
              setShowFilter={setShowFilter}
              filterData={filterData}
            />
          }
        >
          <InvoiceButton
            type={"plus"}
            onClick={() => {
              setNextScreen("createExpense");
              setPrevData((prevData) => ({
                ...prevData,
                page2: "createExpense",
                page3: null,
                page4: null,
                active: "createExpense",
              }));
            }}
          >
            Create Expense
          </InvoiceButton>
        </BreadCrum>
      </div>

      <InvoiceContainer>
        <div className={style.table}>
          <div className={style.th}>
            <p className={style.tableContent}>Expense Month</p>
            <p className={style.tableContent}>Vendor Name</p>
            <p className={style.tableContent}>Service Month/Year</p>
            <p className={style.tableContent}>Category</p>
            <p className={style.tableContent}>Total Billed Amount</p>
            <p className={style.tableContent}>Amount to be paid</p>
            <p className={style.tableContent}>Payment Status</p>
            <p className={style.tableContent}>Payment Date</p>
          </div>
          <div>
            {isLoading && <p className={style.no_data}>Loading .....</p>}
            {!isLoading && !isError ? (
              data?.expenses?.length > 0 ? (
                typeof data?.expenses !== "string" &&
                data?.expenses?.map((item,index) => {
                  return (
                    <div className={style.td} key={item?.positionId}>
                      <p className={style.tableContent}>{item.expenseMonth}</p>
                      <p
                        className={`${style.tableContent1} ${style.link}`}
                        onClick={() => {
                          setNextScreen("viewExpense");
                          setPrevData((prevData) => ({
                            ...prevData,
                            page2: "viewExpense",
                            page3: null,
                            page4: null,
                            active: "viewExpense",
                            idData: item.id
                          }));

                        }}
                      >
                        <CustomTooltip
                          value={item?.vendorName}
                          limit={10}
                          index={index}
                          styles={{
                            padding: "7px 5px 10px 15px",
                            marginTop: "10px",
                            color: "#855ea1",
                            fontWeight:"bolder"
                          }}
                          tooltipstyle={{ left: "36%",margin:"-6px" }}
                        />
                        
                      </p>
                      <p className={style.tableContent}>{item.month_year}</p>
                      <p className={style.tableContent}>{item.categoryName}</p>
                      <p className={style.tableContent}>{item.totalAmount}</p>
                      <p className={style.tableContent}>{item.amountToBePaid}</p>
                      <p className={style.tableContent}>{item.paymentStatus}</p>
                      <p>{item.paymentDate}</p>
                      <div>
                      <figure>
                        <img
                          style={{ cursor: "pointer",marginRight:"11px",marginTop:"13px"}}
                          src={delete1}
                          alt=""
                          onClick={() => {
                            handleShow(item?.id);
                          }}
                        />
                      </figure>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className={style.no_data}>No Data Found</p>
              )
            ) : null}
          </div>
        </div>

        <>
          <Filter
            filterData={filterData}
            setFilterData={setFilterData}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            firstCard="Expense Month/Year"
            // firstCardlist={["In-Active", "All"]}
            secondCard="Vendor"
            // thirdCard="Service Month/Year"
            forthCard="Category"
            fifthCard="Status"
            sixthCard="Payment Date"
            // sevenCard="Amount to be paid"
            // eightCard="Total Billed Amount"
            // secondCardlist={getPositionOwnerForFilterOption}
            filterUpdateFuc={filterUpdateFuc}
            filterClearAllFuc={filterClearAllFuc}
          />
        </>
      </InvoiceContainer>
      <div className={style.pagination}>
        <Pagination
          onPageChange={handlePageChange}
          currentPage={currentPage}
          pagesCount={data?.totalExpense / 10}
          color="#855ea1"
          color2="white"
        />
      </div>
      <Modal show={show} centered>
        <h6 style={{ margin: "20px 10px", paddingLeft: "10px" }}>
          Do you want to delete 
        </h6>
        <button className={style.buttonAdd1} onClick={handleDelete}>
          Yes
        </button>
        <button className={style.buttonCancle} onClick={handleDeleteCancle}>
          No
        </button>
      </Modal>
    </>
  );
};

export default Expense;
