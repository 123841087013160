import React from "react";
import { Link } from "react-router-dom";

import styles from "./Header.module.css";

import InnovationMLogo from "../../../../assets/Grievance portal/image-innovationm-logo@2x.png";
import ProfilePicture from "../../../../assets/Grievance portal/image-girl-profile@2x.png";
import { ReactComponent as DownArrow } from "../../../../assets/Grievance portal/icon-arrow-down.svg";

const Header = () => {
    return (
        <div className={styles.header}>
            <Link to="/dashboard" style={{ display: "flex", flexDirection: "row", width: "18em", justifyContent: "center", alignItems: "center" }}>
                <img src={InnovationMLogo} alt="InnovationM Logo" className={styles.dashboardImage} />
            </Link>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <span className={styles.span}> Hi, Crisatine </span>
                    <img src={ProfilePicture} alt="Profile" className={styles.profileImage} />
                </div>
                <div style={{ paddingRight: "2em" }}>
                    <DownArrow fill="#ffffff" className={styles.arrowIcon} />
                </div>
            </div>
        </div>
    );
};

export default Header;
