import React from "react";
import Modal from "react-responsive-modal";
import style from "./Filter.module.css";
import searchIcon from "../../../assets/Icon-search.png";
import { AiOutlineClose } from "react-icons/ai";
import { customSearch } from "../utils/style";
import { useQuery } from "react-query";
import Select from "react-select";
import "./style.css";
import { department, getStatus } from "../../../Utils/axios/InductionApi";
import { filterBox } from "../utils/style";
function Filter({
  setShowFilter,
  showFilter,
  firstCard,
  secondCard,
  thirdCard,
  filterData,
  setFilterData,
  filterUpdateFuc,
  filterClearAllFuc,
}) {
  const { data: Emp } = useQuery(["department"], department);
  const EmpOption = Emp?.map((e) => ({
    value: e.name,
    label: e.name,
  }));
  const { data: Status } = useQuery(["getStatus"], getStatus);
  const StatusOption = Status?.map((e) => ({
    value: e.name,
    label: e.status,
  }));

  function handleInductionStatusChange (e){
    setFilterData((preData) => ({
      ...preData,
      inductionStatusValue: e.target.value,
    }));
  };
  const EmpName = (e) => {
    setFilterData((preData) => ({
      ...preData,
      empId: e.value,
      EmpValue: e.label,
    }));
  };

  const InductionStatus = (e) => {
    setFilterData((preData) => ({
      ...preData,
      inductionStatusValue: e.target.value,
    }));
  };

  return (
    <>
      <Modal
        open={showFilter}
        center
        onClose={() => setShowFilter(false)}
        showCloseIcon={false}
        classNames={{
          modal: "reactmodal",
        }}
      >
        <div className={style.filterModal}>
          <div className={style.filterCards}>
            {firstCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>Department</div>
                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
                  {filterData?.empId === "" ? (
                    <span className={style.cp_serchIcon}>
                      <img src={searchIcon} style={{ marginLeft: "8px" }} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon}
                      style={{
                        marginTop: "13px",
                        position: "absolute",
                        top: "-10px",
                        right: "4px",
                        zIndex: "1",
                      }}
                      onClick={() => {
                        setFilterData({
                          ...filterData,
                          empId: "",
                        });
                        setFilterData({ ...filterData, empId:"" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    // menuPosition="fixed"
                    styles={customSearch}
                    classNamePrefix={"create_position"}
                    value={
                      filterData?.empId
                        ? {
                            value: filterData?.empId,
                            label: filterData?.EmpValue,
                          }
                        : null
                    }
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => EmpName(e)}
                    options={EmpOption}
                  />
                </div>
              </div>
            )}
            {secondCard && (
              <div className={style.firstCard}>
                <div className={style.filte_heading}>Status</div>

                <div
                  style={{
                    width: "93.5%",
                    margin: ".5rem 0.5rem",
                    position: "relative",
                  }}
                >
   <div>
        <input
          type="radio"
          id="done"
          name="status"
          value="true"
          checked={filterData.inductionStatusValue ==="true"}
          onChange={handleInductionStatusChange}
        />
        <label htmlFor="done">Done</label>
      </div>

      <div>
        <input
          type="radio"
          id="inProgress"
          name="status"
          value="false"
          checked={filterData.inductionStatusValue ==="false"}
          onChange={handleInductionStatusChange}
        />
        <label htmlFor="inProgress">In Progress</label>
      </div>

                  {/* {filterData?.inductionStatus === "" ? (
                    <span className={style.cp_serchIcon}>
                      <img src={searchIcon} style={{ marginLeft: "8px" }} />
                    </span>
                  ) : (
                    <span
                      className={style.cp_serchIcon}
                      style={{
                        marginTop: "10px",
                        position: "absolute",
                        top: "-6px",
                        right: "4px",
                        zIndex: "1",
                      }}
                      onClick={() => {
                        setFilterData({
                          ...filterData,
                          inductionStatus: "",
                        });
                        setFilterData({ ...filterData, inductionStatus: "" });
                      }}
                    >
                      <AiOutlineClose />
                    </span>
                  )}
                  <Select
                    styles={filterBox}
                    classNamePrefix={"create_position"}
                    value={
                      filterData?.inductionStatus
                        ? {
                            value: filterData?.inductionStatus,
                            label: filterData?.inductionStatusValue,
                          }
                        : null
                    }
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    menuPlacement={"auto"}
                    onChange={(e) => InductionStatus(e)}
                    options={StatusOption}
                  /> */}
                </div>
              </div>
            )}
       
          </div>
        </div>
        <div className={style.filterBtn}>
          <button
            className={style.cancelBtn}
            onClick={() => {
              filterClearAllFuc();
            }}
          >
            Clear All
          </button>
          <button className={style.createBtn} onClick={() => filterUpdateFuc()}>
            Apply
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Filter;
