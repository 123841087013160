import React from "react";
import AssignTabletComponent from "./component/AssignTabletComponent";
import Header from "../../commonComponent/Header";
import SideBar from "../../commonComponent/SideBar";
import style from "../tablet.module.css";

const AssignTablet = () => {
  return (
    <div>
      <Header />
      <div className={style.mainSection}>
        <SideBar />
        <AssignTabletComponent />
      </div>
    </div>
  );
};

export default AssignTablet;
